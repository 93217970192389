import React, { useRef, useState } from "react";
import {
  bool, func, node, string,
} from "prop-types";
import cx from "classnames";
import searchIcon from "assets/images/search.svg";

import styles from "./styles.module.scss";

const SearchField = ({
  onChange,
  onEnter,
  placeholder,
  tooltipContent,
  value: initialValue,
  fullWidth,
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [value, setValue] = useState(initialValue);
  const searchField = useRef(null);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    if (onChange) {
      onChange(inputValue);
    }
  };

  const handleEnterPress = (e) => {
    e.persist();
    if (e.keyCode === 13) {
      onEnter(value);
    }
  };

  const tooltip = () => {
    if (tooltipContent) {
      return <section className={styles.tooltip}>{tooltipContent}</section>;
    }
    return null;
  };

  return (
    <section
      className={cx(styles.searchField, {
        [styles.searchFieldFullWidth]: fullWidth,
      })}
    >
      <input
        ref={searchField}
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onKeyUp={handleEnterPress}
        onChange={handleInputChange}
      />
      <img
        src={searchIcon}
        alt="concierge search"
        className={styles.icon}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      />
      {isTooltipVisible && tooltip()}
    </section>
  );
};

SearchField.propTypes = {
  onChange: func,
  onEnter: func,
  placeholder: string,
  tooltipContent: node,
  value: string,
  fullWidth: bool,
};

SearchField.defaultProps = {
  onChange: () => {},
  onEnter: () => {},
  placeholder: "",
  tooltipContent: "",
  value: "",
  fullWidth: false,
};

export default SearchField;
