import React from "react";

import AnimationGroup from "components/common/checkin/AnimationGroup";
import Animation from "components/common/checkin/Animation";
import { DELAY_500 } from "shared/constants/delays";

const animationWrapper = (Component, delay = DELAY_500) => function (props) {
  return (
    <AnimationGroup>
      <Animation delay={delay}>
        <Component {...props} />
      </Animation>
    </AnimationGroup>
  );
};

export default animationWrapper;
