import {
  REMOTE_SERVICE_FETCH_DRIVERS_INFO_FAILURE,
  REMOTE_SERVICE_FETCH_DRIVERS_INFO_SUCCESS,
  REMOTE_SERVICE_FETCH_DRIVER_JOBS_FAILURE,
  REMOTE_SERVICE_FETCH_DRIVER_JOBS_SUCCESS,
  REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE,
  REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS,
  REMOTE_SERVICE_LOADING_STATE,
  REMOTE_SERVICE_UPDATE_DRIVER_FAILURE,
  REMOTE_SERVICE_UPDATE_DRIVER_SUCCESS,
  REMOTE_SERVICE_UPDATE_JOB,
  REMOTE_SERVICE_UPDATE_JOB_FAILURE,
  REMOTE_SERVICE_UPDATE_JOB_SUCCESS,
  REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_FAILURE,
  REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_SUCCESS,
} from "store/actions/remote-service-actions";

const initialState = {
  isLoading: false,
  jobs: [],
  activeDrivers: [],
  activeDriversTotal: 0,
  activeMobileTechnicians: [],
  activeMobileTechniciansTotal: 0,
  error: null,
};

const remoteService = (state = initialState, action) => {
  switch (action.type) {
    case REMOTE_SERVICE_LOADING_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case REMOTE_SERVICE_UPDATE_JOB:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case REMOTE_SERVICE_UPDATE_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        jobs: state.jobs.map((job) => {
          if (job.id === action.payload.id) {
            return {
              ...job,
              ...action.payload,
            };
          }

          return job;
        }),
      };
    case REMOTE_SERVICE_UPDATE_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.errors,
      };
    case REMOTE_SERVICE_FETCH_DRIVER_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
        error: null,
        isLoading: false,
      };
    case REMOTE_SERVICE_FETCH_DRIVER_JOBS_FAILURE:
      return {
        ...state,
        jobs: [],
        error: action.payload,
        isLoading: false,
      };
    case REMOTE_SERVICE_FETCH_DRIVERS_INFO_SUCCESS:
      return {
        ...state,
        activeDrivers: action.payload.drivers,
        activeDriversTotal: action.payload.driversTotal,
        error: null,
      };
    case REMOTE_SERVICE_FETCH_DRIVERS_INFO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS:
      return {
        ...state,
        activeMobileTechnicians: action.payload.technicians,
        activeMobileTechniciansTotal: action.payload.techniciansTotal,
        error: null,
      };
    case REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_SUCCESS:
      return {
        ...state,
        error: null,
        activeMobileTechnicians: state.activeMobileTechnicians.map(
          (mobileTechnician) => (
            mobileTechnician.id !== action.payload.id ? mobileTechnician : {
              ...mobileTechnician,
              ...action.payload,
            }
          ),
        ),
      };
    case REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
      };
    case REMOTE_SERVICE_UPDATE_DRIVER_SUCCESS:
      return {
        ...state,
        error: null,
        activeDrivers: state.activeDrivers.map(
          (driver) => (
            driver.id !== action.payload.id ? driver : {
              ...driver,
              ...action.payload,
            }
          ),
        ),
      };
    case REMOTE_SERVICE_UPDATE_DRIVER_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
      };
    default:
      return state;
  }
};

export default remoteService;
