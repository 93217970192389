import { filter, isEmpty, reject } from "ramda";

export const bookingsLoadingStateSelector = (state) => state.bookings.isLoading;
export const bookingsDataSelector = (state) => state.bookings.data;
export const bookingsErrorSelector = (state) => state.bookings.error;
export const bookingsTimelineFiltersSelector = (state) => state.bookings.timelineFilters;
export const bookingsPeriodFiltersSelector = (state) => state.bookings.periodFilters;
export const bookingsSearchStringSelector = (state) => state.bookings.free_text_search;
export const bookingsAdditionalInfoFiltersSelector = (state) => (
  state.bookings.additionalInfoFilters
);
export const bookingsRequestIdSelector = (state) => state.bookings.requestId;
export const bookingsIntervalTimerIdSelector = (state) => state.bookings.intervalTimerId;
export const bookingsSearchParams = (state) => {
  const filterValues = (value) => Boolean(value);
  const {
    bookings: {
      periodFilters,
      free_text_search,
      additionalInfoFilters: {
        bookingStatus,
        jobStatus,
        transportationStatus,
        with_concern,
        client_waiting,
        appraisal_requested,
        with_recall,
        service_advisor,
        appointmentStatus,
      },
    },
  } = state;
  const queryParams = {
    appointment_datetime_gteq: periodFilters.from,
    appointment_datetime_lteq: periodFilters.to,
    arrived_at_gteq: periodFilters.from,
    arrived_at_lteq: periodFilters.to,
    s: state.tableOptions.bookingsSort,
    free_text_search,
    status_in: Object.keys(filter(filterValues, bookingStatus)),
    job_status_in: Object.keys(filter(filterValues, jobStatus)),
    transport_kind_in: Object.keys(filter(filterValues, transportationStatus)),
    service_advisor_in: Object.keys(filter(filterValues, service_advisor)),
    with_concern_true: with_concern,
    client_waiting_true: client_waiting,
    appraisal_requested_true: appraisal_requested,
    with_recall,
    aasm_state_in: Object.keys(filter(filterValues, appointmentStatus)),
  };

  return Object.entries(reject((v) => isEmpty(v), filter(filterValues, queryParams)))
    .reduce((acc, [key, value]) => {
      acc[`q[${key}]`] = value;
      return acc;
    }, {});
};

export const bookingsDealershipHoursCapacitySelector = (state) => (
  state.bookings.dealershipHoursCapacity
);
export const bookingsDealershipAppointmentsCapacitySelector = (state) => (
  state.bookings.dealershipAppointmentsCapacity
);
export const bookingsAllAdvisorsCapacitySelector = (state) => state.bookings.allAdvisorsCapacity;
export const bookingsTeamTagsCapacitySelector = (state) => state.bookings.teamTagsCapacity;
export const bookingsIsCapacityDashboardLoading = (state) => (
  state.bookings.isDealershipCapacityLoading || state.bookings.isTeamTagsCapacityLoading
);
