export const appCableChannelKeySelector = (state) => state.checkinApp.actionCableChannelKey;
export const appCheckinIdSelector = (state) => state.checkinApp.checkinId;
export const appAppointmentGuidSelector = (state) => state.checkinApp.appointmentGuid;
export const appAppointmentSlotsSelector = (state) => state.checkinApp.appointmentSlots;
export const appJobSlotsSelector = (state) => state.checkinApp.jobSlots;
export const appisNoValidAppointmentSelector = (state) => state.checkinApp.isNoValidAppointment;
export const appDealershipSlugSelector = (state) => state.checkinApp.dealershipSlug;
export const appDealershipIdSelector = (state) => state.checkinApp.dealership.id;
export const appDealershipAddressSelector = (state) => state.checkinApp.dealership.address;
export const appDealershipHoursSelector = (state) => state.checkinApp.dealership.workingHours;
export const appDealershipPhoneSelector = (state) => state.checkinApp.dealership.phone;
export const appDealershipNotesSelector = (state) => state.checkinApp.dealership.notes;
export const appDealershipNameSelector = (state) => state.checkinApp.dealership.name;
export const appDealershipLogoSelector = (state) => state.checkinApp.dealership.logo;
export const appDealershipDmsTypeSelector = (state) => state.checkinApp.dealership.dmsType;
export const appDealershipTimeZoneSelector = (state) => state.checkinApp.dealership.timeZone;
