import React, { useEffect, useState } from "react";
import {
  bool, func, node, objectOf, string,
} from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { NotificationContainer, NotificationManager } from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";

import {
  fetchTemplate, importOpCodes, reorderTemplateItems,
} from "store/actions/menu-template-actions";
import { menuTemplatePropType } from "shared/prop-types";
import {
  importedOpCodesSelector, servicesErrorSelector, servicesTemplateSelector,
} from "store/selectors/menu-template-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import { reorderItems } from "shared/utils/common";
import arrowLeft from "assets/images/arrow/left.svg";
import PageHeader from "components/common/PageHeader";
import Button from "components/common/Button";
import AddMenuItemModal from "./AddMenuItemModal";
import MenuItem from "./MenuItem";

import styles from "./styles.module.scss";

const GENERAL_KIND = "general";
const MAINTENANCE = "maintenance";
const CONCERN = "concern";
const EXTENSION = "extension";

const SortableMenuItem = SortableElement(MenuItem);

const SortableList = SortableContainer(({ children }) => (
  <div className={styles.itemsContainer}>{children}</div>
));

const shouldCancelStart = (e) => {
  if (["input", "a", "select", "option", "button"].indexOf(e.target.tagName.toLowerCase()) !== -1) {
    return true;
  }
  return false;
};

const MenuTemplatePage = ({
  dealershipId,
  match: {
    params,
  },
  servicesTemplate,
  error,
  imported,
  retrieveTemplate,
  reorderTemplate,
  getOpCodes,
}) => {
  const [isReorderMode, setIsReorderMode] = useState(false);
  const [isImportMessageShown, setIsImportMessageShown] = useState(false);
  const [template, setTemplate] = useState(null);
  const [modalOptions, setModalOptions] = useState({
    open: false,
    kind: null,
  });

  useEffect(() => {
    retrieveTemplate(params.id);
  }, []);

  useEffect(() => {
    setTemplate(servicesTemplate);
  }, [servicesTemplate]);

  useEffect(() => {
    if (error) {
      NotificationManager.error(error.message, "Error");
    }
  }, [error]);

  useEffect(() => {
    if (imported && isImportMessageShown) {
      NotificationManager.success(
        "It may take several minutes to process.",
        "Import of opcodes started.",
      );
      setIsImportMessageShown(false);
    }
  }, [isImportMessageShown]);

  const title = (name) => (
    <Link
      to={{ pathname: "/templates" }}
      className={styles.goBackButton}
    >
      <img src={arrowLeft} alt="" />
      <h2>{`Menu template  / ${name}`}</h2>
    </Link>
  );

  const onSortEnd = ({ oldIndex, newIndex, collection }) => {
    setTemplate({
      ...template,
      menu_items: {
        ...template.menu_items,
        [collection]:
          reorderItems(template.menu_items[collection], oldIndex, newIndex),
      },
    });
  };

  const sendItemsIds = (items, collection) => {
    const ids = items.map((item) => item.id);

    const data = {
      dealershipId,
      menuTemplateId: template.id,
      menuItemIds: ids,
      itemsKind: collection,
    };

    reorderTemplate(data);
  };

  const newMenuItem = (kind) => () => setModalOptions({
    open: true,
    kind,
  });

  const duplicateItem = (item, kind) => () => setModalOptions({
    kind,
    open: true,
    duplicate: item,
  });

  const editReorder = () => {
    setIsReorderMode(true);
  };

  const saveReorder = () => {
    Object.keys(template.menu_items).forEach((collection) => {
      if (template.menu_items[collection].length !== 0) {
        sendItemsIds(template.menu_items[collection], collection);
      }
    });
    setIsReorderMode(false);
  };

  const cancelEditReorder = () => {
    setTemplate(servicesTemplate);
    setIsReorderMode(false);
  };

  const sectionItem = (key, menuItems, menuTemplate) => (
    <>
      <div className={styles.serviceGroup}>
        <div>
          {key.charAt(0).toUpperCase() + key.slice(1)}
        </div>
        {isReorderMode && (
          <div className={styles.buttons}>
            <Button
              variant="neutral"
              onClick={cancelEditReorder}
            >
              Cancel
            </Button>
            <Button
              variant="brand"
              onClick={saveReorder}
            >
              Save
            </Button>
          </div>
        )}
        {!isReorderMode && (
          <div className={styles.rightSideContent}>
            {key === MAINTENANCE && (
              <Button
                variant="neutral"
                onClick={() => {
                  getOpCodes();
                  setIsImportMessageShown(true);
                }}
              >
                Import Op Codes
              </Button>
            )}
            <Button
              variant="neutral"
              onClick={editReorder}
            >
              Reorder
            </Button>
            <Button
              variant="brand"
              className={styles.addButtonBrand}
              onClick={newMenuItem(key)}
            >
              {`Add new ${key} item`}
            </Button>
          </div>
        )}
      </div>
      <SortableList
        onSortEnd={onSortEnd}
        distance={1}
        lockAxis="y"
        shouldCancelStart={shouldCancelStart}
      >
        {(menuItems[key] || []).map((item, index) => (
          <SortableMenuItem
            disabled={!isReorderMode}
            key={item.id}
            index={index}
            collection={key}
            menuTemplate={menuTemplate}
            menuItem={item}
            onDuplicate={duplicateItem(item, key)}
          />
        ))}
      </SortableList>
    </>
  );

  if (!template?.id) {
    return (
      <section className={styles.menuTemplatePage}>
        <section className={styles.isLoading}>
          <ClipLoader size={50} color="#0bcaf9" />
        </section>
      </section>
    );
  }

  return (
    <section className={styles.menuTemplatePage}>
      <PageHeader title={title(template.name)} />
      <NotificationContainer />
      <div>
        {modalOptions.open && (
          <AddMenuItemModal
            menuTemplateId={template.id}
            kind={modalOptions.kind}
            duplicate={modalOptions.duplicate}
            excludedServices={
              (template.menu_items[modalOptions.kind] || []).map((m) => m.service_id)
            }
            onClose={() => setModalOptions({ open: false })}
          />
        )}
        {template.kind === GENERAL_KIND && (
          <section className={styles.main}>
            {sectionItem(MAINTENANCE, template.menu_items, template)}
            {sectionItem(CONCERN, template.menu_items, template)}
          </section>
        )}
        {template.kind !== GENERAL_KIND && (
          <section className={styles.main}>
            {sectionItem(EXTENSION, template.menu_items, template)}
          </section>
        )}
      </div>
    </section>
  );
};

MenuTemplatePage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  servicesTemplate: menuTemplatePropType,
  retrieveTemplate: func.isRequired,
  reorderTemplate: func.isRequired,
  dealershipId: string,
  error: objectOf(node),
  getOpCodes: func.isRequired,
  imported: bool.isRequired,
};

MenuTemplatePage.defaultProps = {
  dealershipId: null,
  error: null,
  servicesTemplate: null,
};

const mapStateToProps = (state) => {
  return {
    servicesTemplate: servicesTemplateSelector(state),
    dealershipId: dealershipIdSelector(state),
    error: servicesErrorSelector(state),
    imported: importedOpCodesSelector(state),
  };
};

const actions = {
  retrieveTemplate: fetchTemplate,
  reorderTemplate: reorderTemplateItems,
  getOpCodes: importOpCodes,
};

export default connect(mapStateToProps, actions)(MenuTemplatePage);
