import {
  addArriveUser,
  addEmployment,
  addKiosk,
  addRemoteUser,
  addTeamTag,
  addTextMessage,
  addTvScreen,
  advisorBulkUpdate,
  createDriver,
  createEmploymentVacations,
  createMobileTechniciansVacation,
  createVacationsForDriver,
  deleteDriver,
  deleteEmploymentVacations,
  deleteHoliday,
  deleteVacationsForDriver,
  destroyArriveUser,
  destroyKiosk,
  destroyRemoteUser,
  destroyTextMessage,
  destroyTvScreen,
  fecthMobileTechniciansVacations,
  fetchDealershipDrivers,
  fetchDealershipInfo,
  fetchDealershipServiceAdvisors,
  fetchEmploymentVacations,
  fetchMobileTechnicians,
  fetchNonWorkingDays,
  fetchResponsibleTeamTag,
  fetchTeamAdvisors,
  fetchTeamTags,
  fetchTextMessages,
  fetchVacationsForDriver,
  removeMobileTechniciansVacation,
  removeTeamTag,
  setHoliday,
  setStylesheet,
  toggleEmploymentLock,
  updateAdvisorData,
  updateArriveUserData,
  updateDealershipCapacitySettings,
  updateDealershipInfo,
  updateDmsIntegration,
  updateDriverData,
  updateEmploymentData,
  updateEmploymentVacations,
  updateIntegration,
  updateKioskData,
  updateLoanerIntegration,
  updateMobileTechnicianIntegration,
  updateMobileTechnicians,
  updateMobileTechniciansVacation,
  updatePickupIntegration,
  updateRemoteUserData,
  updateTeamSeniority,
  updateTeamTagData,
  updateTextMessageData,
  updateTvScreenData,
  updateVacationsForDriver,
} from "shared/api";

import { settingsTimezoneSelector } from "store/selectors/settings-selectors";

import { authTokenSelector, authUserAdvisorIdSelector } from "store/selectors/auth-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import { dealershipDetailsIdSelector } from "store/selectors/dealership-details-selectors";
import { BOOKINGS_SET_ADDITIONAL_INFO_FILTER, setTimelineFilter } from "./bookings-actions";

export const SETTINGS_SET_LOADING_STATE = "SETTINGS_SET_LOADING_STATE";
export const SETTINGS_FETCH_DEALERSHIP_INFO_SUCCESS = "SETTINGS_FETCH_DEALERSHIP_INFO_SUCCESS";
export const SETTINGS_FETCH_DEALERSHIP_INFO_FAILURE = "SETTINGS_FETCH_DEALERSHIP_INFO_FAILURE";
export const SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_SUCCESS = "SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_SUCCESS";
export const SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_FAILURE = "SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_FAILURE";
export const SETTINGS_FETCH_MOBILE_TECHNICIANS_SUCCESS = "SETTINGS_FETCH_MOBILE_TECHNICIANS_SUCCESS";
export const SETTINGS_FETCH_MOBILE_TECHNICIANS_FAILURE = "SETTINGS_FETCH_MOBILE_TECHNICIANS_FAILURE";
export const SETTINGS_FETCH_DRIVERS_INFO_SUCCESS = "SETTINGS_FETCH_DRIVERS_INFO_SUCCESS";
export const SETTINGS_FETCH_DRIVERS_INFO_FAILURE = "SETTINGS_FETCH_DRIVERS_INFO_FAILURE";
export const SETTINGS_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_LOADING_STATE = "SETTINGS_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_LOADING_STATE";
export const SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_SUCCESS = "SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_SUCCESS";
export const SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_FAILURE = "SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_FAILURE";
export const SETTINGS_UPDATE_DEALERSHIP_INFO_SUCCESS = "SETTINGS_UPDATE_DEALERSHIP_INFO_SUCCESS";
export const SETTINGS_UPDATE_DEALERSHIP_INFO_FAILURE = "SETTINGS_UPDATE_DEALERSHIP_INFO_FAILURE";
export const SETTINGS_UPDATE_INTEGRATION_SUCCESS = "SETTINGS_UPDATE_INTEGRATION_SUCCESS";
export const SETTINGS_UPDATE_INTEGRATION_FAILURE = "SETTINGS_UPDATE_INTEGRATION_FAILURE";
export const SETTINGS_SET_TEAM_TAGS_LOADING_STATE = "SETTINGS_SET_TEAM_TAGS_LOADING_STATE";
export const SETTINGS_FETCH_TEAM_TAG_SUCCESS = "SETTINGS_FETCH_TEAM_TAG_SUCCESS";
export const SETTINGS_FETCH_TEAM_TAG_FAILURE = "SETTINGS_FETCH_TEAM_TAG_FAILURE";
export const SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_SUCCESS = "SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_SUCCESS";
export const SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_ERROR = "SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_ERROR";
export const SETTINGS_CREATE_TEAM_TAG_SUCCESS = "SETTINGS_CREATE_TEAM_TAG_SUCCESS";
export const SETTINGS_CREATE_TEAM_TAG_FAILURE = "SETTINGS_CREATE_TEAM_TAG_FAILURE";
export const SETTINGS_UPDATE_TEAM_TAG_SUCCESS = "SETTINGS_UPDATE_TEAM_TAG_SUCCESS";
export const SETTINGS_UPDATE_TEAM_TAG_FAILURE = "SETTINGS_UPDATE_TEAM_TAG_FAILURE";
export const SETTINGS_UPDATE_TEAM_TAGS_SENIORITY_SUCCESS = "SETTINGS_UPDATE_TEAM_TAGS_SENIORITY_SUCCESS";
export const SETTINGS_DELETE_TEAM_TAG_SUCCESS = "SETTINGS_DELETE_TEAM_TAG_SUCCESS";
export const SETTINGS_DELETE_TEAM_TAG_FAILURE = "SETTINGS_DELETE_TEAM_TAG_FAILURE";
export const SETTINGS_DELETE_TEXT_MESSAGE_SUCCESS = "SETTINGS_DELETE_TEXT_MESSAGE_SUCCESS";
export const SETTINGS_DELETE_TEXT_MESSAGE_FAILURE = "SETTINGS_DELETE_TEXT_MESSAGE_FAILURE";
export const SETTINGS_NON_WORKING_DAYS_LOADING_STATE = "SETTINGS_NON_WORKING_DAYS_LOADING_STATE";
export const SETTINGS_FETCH_NON_WORKING_DAYS_SUCCESS = "SETTINGS_FETCH_NON_WORKING_DAYS_SUCCESS";
export const SETTINGS_FETCH_NON_WORKING_DAYS_FAILURE = "SETTINGS_FETCH_NON_WORKING_DAYS_FAILURE";
export const SETTINGS_ADD_NON_WORKING_DAY_SUCCESS = "SETTINGS_ADD_NON_WORKING_DAY_SUCCESS";
export const SETTINGS_ADD_NON_WORKING_DAY_FAILURE = "SETTINGS_ADD_NON_WORKING_DAY_FAILURE";
export const SETTINGS_DELETE_NON_WORKING_DAY_SUCCESS = "SETTINGS_DELETE_NON_WORKING_DAY_SUCCESS";
export const SETTINGS_DELETE_NON_WORKING_DAY_FAILURE = "SETTINGS_DELETE_NON_WORKING_DAY_FAILURE";

export const SETTINGS_FETCH_TEAM_ADVISORS = "SETTINGS_FETCH_TEAM_ADVISORS";
export const SETTINGS_FETCH_TEAM_ADVISORS_SUCCESS = "SETTINGS_FETCH_TEAM_ADVISORS_SUCCESS";
export const SETTINGS_FETCH_TEAM_ADVISORS_ERROR = "SETTINGS_FETCH_TEAM_ADVISORS_ERROR";

export const SETTINGS_CREATE_TEXT_MESSAGES_SUCCESS = "SETTINGS_CREATE_TEXT_MESSAGES_SUCCESS";
export const SETTINGS_FETCH_TEXT_MESSAGES_SUCCESS = "SETTINGS_FETCH_TEXT_MESSAGES_SUCCESS";
export const SETTINGS_UPDATE_TEXT_MESSAGES_SUCCESS = "SETTINGS_UPDATE_TEXT_MESSAGES_SUCCESS";
export const SETTINGS_CREATE_TEXT_MESSAGES_FAILURE = "SETTINGS_CREATE_TEXT_MESSAGES_FAILURE";
export const SETTINGS_FETCH_TEXT_MESSAGES_FAILURE = "SETTINGS_FETCH_TEXT_MESSAGES_FAILURE";
export const SETTINGS_UPDATE_TEXT_MESSAGES_FAILURE = "SETTINGS_UPDATE_TEXT_MESSAGES_FAILURE";

export const SETTINGS_CREATE_USER_SUCCESS = "SETTINGS_CREATE_USER_SUCCESS";
export const SETTINGS_UPDATE_USER_SUCCESS = "SETTINGS_UPDATE_USER_SUCCESS";
export const SETTINGS_UPDATE_USER_FAILURE = "SETTINGS_UPDATE_USER_FAILURE";
export const SETTINGS_DELETE_USER_SUCCESS = "SETTINGS_DELETE_USER_SUCCESS";
export const SETTINGS_DELETE_USER_FAILURE = "SETTINGS_DELETE_USER_FAILURE";
export const SETTINGS_CREATE_KIOSK_SUCCESS = "SETTINGS_CREATE_KIOSK_SUCCESS";
export const SETTINGS_UPDATE_KIOSK_SUCCESS = "SETTINGS_UPDATE_KIOSK_SUCCESS";
export const SETTINGS_DELETE_KIOSK_SUCCESS = "SETTINGS_DELETE_KIOSK_SUCCESS";
export const SETTINGS_CREATE_TV_SCREEN_SUCCESS = "SETTINGS_CREATE_TV_SCREEN_SUCCESS";
export const SETTINGS_UPDATE_TV_SCREEN_SUCCESS = "SETTINGS_UPDATE_TV_SCREEN_SUCCESS";
export const SETTINGS_DELETE_TV_SCREEN_SUCCESS = "SETTINGS_DELETE_TV_SCREEN_SUCCESS";
export const SETTINGS_CREATE_ARRIVE_USER_SUCCESS = "SETTINGS_CREATE_ARRIVE_USER_SUCCESS";
export const SETTINGS_UPDATE_ARRIVE_USER_SUCCESS = "SETTINGS_UPDATE_ARRIVE_USER_SUCCESS";
export const SETTINGS_DELETE_ARRIVE_USER_SUCCESS = "SETTINGS_DELETE_ARRIVE_USER_SUCCESS";
export const SETTINGS_CREATE_REMOTE_USER_SUCCESS = "SETTINGS_CREATE_REMOTE_USER_SUCCESS";
export const SETTINGS_UPDATE_REMOTE_USER_SUCCESS = "SETTINGS_UPDATE_REMOTE_USER_SUCCESS";
export const SETTINGS_DELETE_REMOTE_USER_SUCCESS = "SETTINGS_DELETE_REMOTE_USER_SUCCESS";
export const SETTINGS_ADD_ENCRYPTED_PASSWORD_TO_USERS = "SETTINGS_ADD_ENCRYPTED_PASSWORD_TO_USERS";
export const SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_SUCCESS = "SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_SUCCESS";
export const SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_FAILURE = "SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_FAILURE";
export const SETTINGS_ADVISOR_LOADING_STATE = "SETTINGS_ADVISOR_LOADING_STATE";
export const SETTINGS_UPDATE_ADVISOR_SUCCESS = "SETTINGS_UPDATE_ADVISOR_SUCCESS";
export const SETTINGS_UPDATE_ADVISOR_FAILURE = "SETTINGS_UPDATE_ADVISOR_FAILURE";
export const SETTINGS_UPDATE_ADVISORS_SUCCESS = "SETTINGS_UPDATE_ADVISORS_SUCCESS";
export const SETTINGS_UPDATE_ADVISORS_FAILURE = "SETTINGS_UPDATE_ADVISORS_FAILURE";
export const SETTINGS_DRIVER_LOADING_STATE = "SETTINGS_DRIVER_LOADING_STATE";
export const SETTINGS_CREATE_DRIVER_SUCCESS = "SETTINGS_CREATE_DRIVER_SUCCESS";
export const SETTINGS_CREATE_DRIVER_FAILURE = "SETTINGS_CREATE_DRIVER_FAILURE";
export const SETTINGS_UPDATE_DRIVER_SUCCESS = "SETTINGS_UPDATE_DRIVER_SUCCESS";
export const SETTINGS_UPDATE_DRIVER_FAILURE = "SETTINGS_UPDATE_DRIVER_FAILURE";
export const SETTINGS_DELETE_DRIVER_SUCCESS = "SETTINGS_DELETE_DRIVER_SUCCESS";
export const SETTINGS_DELETE_DRIVER_FAILURE = "SETTINGS_DELETE_DRIVER_FAILURE";
export const SETTINGS_MOBILE_TECHNICIANS_LOADING_STATE = "SETTINGS_MOBILE_TECHNICIANS_LOADING_STATE";
export const SETTINGS_UPDATE_MOBILE_TECHNICIANS_SUCCESS = "SETTINGS_UPDATE_MOBILE_TECHNICIANS_SUCCESS";
export const SETTINGS_UPDATE_MOBILE_TECHNICIANS_FAILURE = "SETTINGS_UPDATE_MOBILE_TECHNICIANS_FAILURE";
export const SETTINGS_UPDATE_STYLESHEET_SUCCESS = "SETTINGS_UPDATE_STYLESHEET_SUCCESS";
export const SETTINGS_UPDATE_STYLESHEET_FAILURE = "SETTINGS_UPDATE_STYLESHEET_FAILURE";

export const SETTINGS_FETCH_EMPLOYEE_VACATIONS = "SETTINGS_FETCH_EMPLOYEE_VACATIONS";
export const SETTINGS_FETCH_EMPLOYEE_VACATIONS_FAILURE = "SETTINGS_FETCH_EMPLOYEE_VACATIONS_FAILURE";
export const SETTINGS_CREATE_EMPLOYEE_VACATIONS = "SETTINGS_CREATE_EMPLOYEE_VACATIONS";
export const SETTINGS_CREATE_EMPLOYEE_VACATIONS_FAILURE = "SETTINGS_CREATE_EMPLOYEE_VACATIONS_FAILURE";
export const SETTINGS_UPDATE_EMPLOYEE_VACATIONS = "SETTINGS_UPDATE_EMPLOYEE_VACATIONS";
export const SETTINGS_UPDATE_EMPLOYEE_VACATIONS_FAILURE = "SETTINGS_UPDATE_EMPLOYEE_VACATIONS_FAILURE";
export const SETTINGS_DELETE_EMPLOYEE_VACATIONS = "SETTINGS_DELETE_EMPLOYEE_VACATIONS";
export const SETTINGS_DELETE_EMPLOYEE_VACATIONS_FAILURE = "SETTINGS_DELETE_EMPLOYEE_VACATIONS_FAILURE";

export const SETTINGS_FETCH_DRIVER_VACATIONS = "SETTINGS_FETCH_DRIVER_VACATIONS";
export const SETTINGS_FETCH_DRIVER_VACATIONS_FAILURE = "SETTINGS_FETCH_DRIVER_VACATIONS_FAILURE";
export const SETTINGS_CREATE_DRIVER_VACATIONS = "SETTINGS_CREATE_DRIVER_VACATIONS";
export const SETTINGS_CREATE_DRIVER_VACATIONS_FAILURE = "SETTINGS_CREATE_DRIVER_VACATIONS_FAILURE";
export const SETTINGS_UPDATE_DRIVER_VACATIONS = "SETTINGS_UPDATE_DRIVER_VACATIONS";
export const SETTINGS_UPDATE_DRIVER_VACATIONS_FAILURE = "SETTINGS_UPDATE_DRIVER_VACATIONS_FAILURE";
export const SETTINGS_DELETE_DRIVER_VACATIONS = "SETTINGS_DELETE_DRIVER_VACATIONS";
export const SETTINGS_DELETE_DRIVER_VACATIONS_FAILURE = "SETTINGS_DELETE_DRIVER_VACATIONS_FAILURE";

export const SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS = "SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS";
export const SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS_FAILURE = "SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS_FAILURE";
export const SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS = "SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS";
export const SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE = "SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE";
export const SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS = "SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS";
export const SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE = "SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE";
export const SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS = "SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS";
export const SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS_FAILURE = "SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS_FAILURE";

const setLoadingState = () => {
  return {
    type: SETTINGS_SET_LOADING_STATE,
    payload: {},
  };
};

const setTeamTagsLoadingState = () => {
  return {
    type: SETTINGS_SET_TEAM_TAGS_LOADING_STATE,
    payload: {},
  };
};

const addEncryptedPasswordToUsers = () => {
  return {
    type: SETTINGS_ADD_ENCRYPTED_PASSWORD_TO_USERS,
  };
};

export const retrieveDealershipInfo = () => async (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());
  const timezone = settingsTimezoneSelector(getState());

  try {
    const response = await fetchDealershipInfo(dealershipId, token);

    dispatch({
      type: SETTINGS_FETCH_DEALERSHIP_INFO_SUCCESS,
      payload: {
        data: response,
      },
    });

    dispatch(addEncryptedPasswordToUsers());

    if (!timezone) {
      dispatch(setTimelineFilter("today"));
    }
  } catch (error) {
    dispatch({
      type: SETTINGS_FETCH_DEALERSHIP_INFO_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const retrieveDealershipActiveServiceAdvisors = () => async (dispatch, getState) => {
  dispatch({
    type: SETTINGS_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_LOADING_STATE,
    payload: {},
  });

  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());
  const userAdvisorId = authUserAdvisorIdSelector(getState());

  try {
    const response = await fetchDealershipServiceAdvisors(
      dealershipId,
      { active: true },
      token,
    );

    const activeAdvisors = response.data;

    const serviceAdvisorFilter = activeAdvisors.reduce((obj, item) => {
      return {
        ...obj,
        [item.id]: item.id === +userAdvisorId,
      };
    }, {});

    dispatch({
      type: SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_SUCCESS,
      payload: {
        activeAdvisors,
      },
    });

    dispatch({
      type: BOOKINGS_SET_ADDITIONAL_INFO_FILTER,
      payload: {
        service_advisor: serviceAdvisorFilter,
      },
    });
  } catch (error) {
    dispatch({
      type: SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const retrieveDealershipServiceAdvisors = (
  page,
  perPage,
  activeOnly,
  nameContains,
  sort,
) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  dispatch({
    type: SETTINGS_ADVISOR_LOADING_STATE,
    payload: {},
  });

  try {
    const response = await fetchDealershipServiceAdvisors(
      dealershipId,
      {
        page,
        per_page: perPage,
        active: activeOnly,
        name_contains: nameContains,
        sort,
      },
      token,
    );

    dispatch({
      type: SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_SUCCESS,
      payload: {
        serviceAdvisors: response.data,
        serviceAdvisorsTotal: Number(response.headers.total),
      },
    });
  } catch (error) {
    dispatch({
      type: SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const retrieveDealershipDrivers = (
  page,
  perPage,
  activeOnly,
  nameContains,
  sort,
) => async (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  dispatch({
    type: SETTINGS_DRIVER_LOADING_STATE,
    payload: {},
  });

  try {
    const response = await fetchDealershipDrivers(
      dealershipId,
      {
        page,
        per_page: perPage,
        active: activeOnly,
        name_contains: nameContains,
        sort,
      },
      token,
    );

    dispatch({
      type: SETTINGS_FETCH_DRIVERS_INFO_SUCCESS,
      payload: {
        drivers: response.data,
        driversTotal: Number(response.headers.total),
      },
    });
  } catch (error) {
    dispatch({
      type: SETTINGS_FETCH_DRIVERS_INFO_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const retrieveMobileTechnicians = (
  page,
  perPage,
  activeOnly,
  nameContains,
  sort,
) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  dispatch({
    type: SETTINGS_MOBILE_TECHNICIANS_LOADING_STATE,
    payload: {},
  });

  try {
    const response = await fetchMobileTechnicians(
      dealershipId,
      {
        page,
        per_page: perPage,
        active: activeOnly,
        name_contains: nameContains,
        sort,
      },
      token,
    );

    dispatch({
      type: SETTINGS_FETCH_MOBILE_TECHNICIANS_SUCCESS,
      payload: {
        mobileTechnicians: response.data,
        mobileTechniciansTotal: Number(response.headers.total),
      },
    });
  } catch (error) {
    dispatch({
      type: SETTINGS_FETCH_MOBILE_TECHNICIANS_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const changeDealershipInfo = (
  dealershipId,
  changedData,
  setLoading = true,
) => (dispatch, getState) => {
  if (setLoading) {
    dispatch(setLoadingState());
  }
  const token = authTokenSelector(getState());
  return updateDealershipInfo(dealershipId, changedData, token)
    .then((response) => dispatch({
      type: SETTINGS_UPDATE_DEALERSHIP_INFO_SUCCESS,
      payload: response,
    }))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_DEALERSHIP_INFO_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updateIntegrationOptions = (integrationId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipDetailsIdSelector(getState()) || dealershipIdSelector(getState());

  return updateIntegration(
    dealershipId,
    integrationId,
    params,
    token,
  )
    .then(() => (
      dispatch({
        type: SETTINGS_UPDATE_INTEGRATION_SUCCESS,
        payload: {
          id: integrationId,
          ...params,
        },
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_INTEGRATION_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updateDmsIntegrationOptions = (integrationId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipDetailsIdSelector(getState()) || dealershipIdSelector(getState());

  return updateDmsIntegration(
    dealershipId,
    integrationId,
    params,
    token,
  )
    .then(() => (
      dispatch({
        type: SETTINGS_UPDATE_INTEGRATION_SUCCESS,
        payload: {
          id: integrationId,
          ...params,
        },
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_INTEGRATION_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updateLoanerIntegrationOptions = (integrationId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipDetailsIdSelector(getState()) || dealershipIdSelector(getState());

  return updateLoanerIntegration(
    dealershipId,
    integrationId,
    params,
    token,
  )
    .then(() => (
      dispatch({
        type: SETTINGS_UPDATE_INTEGRATION_SUCCESS,
        payload: {
          id: integrationId,
          ...params,
        },
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_INTEGRATION_SUCCESS,
      payload: { error: error.errors || error },
    }));
};

export const updateMobileTechnicianIntegrationOptions = (integrationId, params) => (
  dispatch,
  getState,
) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipDetailsIdSelector(getState()) || dealershipIdSelector(getState());
  return updateMobileTechnicianIntegration(
    dealershipId,
    integrationId,
    params,
    token,
  )
    .then(() => (
      dispatch({
        type: SETTINGS_UPDATE_INTEGRATION_SUCCESS,
        payload: {
          id: integrationId,
          ...params,
          mobile_technician_enabled: params.active,
        },
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_INTEGRATION_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updatePickupIntegrationOptions = (integrationId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipDetailsIdSelector(getState()) || dealershipIdSelector(getState());

  return updatePickupIntegration(
    dealershipId,
    integrationId,
    params,
    token,
  )
    .then(() => (
      dispatch({
        type: SETTINGS_UPDATE_INTEGRATION_SUCCESS,
        payload: {
          id: integrationId,
          ...params,
          remote_enabled: params.active,
        },
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_INTEGRATION_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const createUser = (userData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return addEmployment(dealershipId, userData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_CREATE_USER_SUCCESS,
        payload: response,
      })
    ));
};

export const updateEmployee = (userData) => (dispatch, getState) => {
  dispatch(setLoadingState());
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateEmploymentData(dealershipId, userData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_USER_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_USER_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const retrieveTextMessages = (params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return fetchTextMessages(dealershipId, params, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_FETCH_TEXT_MESSAGES_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_FETCH_TEXT_MESSAGES_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const createTextMessage = (
  changedData,
  setLoading = true,
  params,
) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  if (setLoading) {
    dispatch(setLoadingState());
  }

  return updateDealershipInfo(dealershipId, changedData, token)
    .then((response) => {
      addTextMessage(dealershipId, params, token)
        .then((secondResponse) => {
          dispatch({
            type: SETTINGS_UPDATE_DEALERSHIP_INFO_SUCCESS,
            payload: response,
          });
          dispatch({
            type: SETTINGS_CREATE_TEXT_MESSAGES_SUCCESS,
            payload: secondResponse,
          });
        })
        .catch((error) => dispatch({
          type: SETTINGS_CREATE_TEXT_MESSAGES_FAILURE,
          payload: { error: error.errors || error },
        }));
    })
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_DEALERSHIP_INFO_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const removeTextMessage = (
  changedData,
  setLoading = true,
  dealershipsTextMessageId,
) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  if (setLoading) {
    dispatch(setLoadingState());
  }

  updateDealershipInfo(dealershipId, changedData, token)
    .then((response) => {
      destroyTextMessage(dealershipId, dealershipsTextMessageId, token)
        .then((secondResponce) => {
          dispatch({
            type: SETTINGS_UPDATE_DEALERSHIP_INFO_SUCCESS,
            payload: response,
          });
          dispatch({
            type: SETTINGS_DELETE_TEXT_MESSAGE_SUCCESS,
            payload: secondResponce,
          });
        })
        .catch((error) => dispatch({
          type: SETTINGS_DELETE_TEXT_MESSAGE_FAILURE,
          payload: { error: error.errors || error },
        }));
    })
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_DEALERSHIP_INFO_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updateTextMessage = (
  changedData,
  setLoading = true,
  params,
  dealershipsTextMessageId,
) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  if (setLoading) {
    dispatch(setLoadingState());
  }

  return updateDealershipInfo(dealershipId, changedData, token)
    .then((response) => {
      updateTextMessageData(dealershipId, dealershipsTextMessageId, params, token)
        .then((secondResponce) => {
          dispatch({
            type: SETTINGS_UPDATE_DEALERSHIP_INFO_SUCCESS,
            payload: response,
          });
          dispatch({
            type: SETTINGS_UPDATE_TEXT_MESSAGES_SUCCESS,
            payload: secondResponce,
          });
        })
        .catch((error) => dispatch({
          type: SETTINGS_UPDATE_TEXT_MESSAGES_FAILURE,
          payload: { error: error.errors || error },
        }));
    })
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_DEALERSHIP_INFO_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const retrieveTeamTags = () => async (dispatch, getState) => {
  dispatch(setTeamTagsLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  try {
    const response = await fetchTeamTags(dealershipId, token);
    dispatch({
      type: SETTINGS_FETCH_TEAM_TAG_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: SETTINGS_FETCH_TEAM_TAG_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const createTeamTag = (tagData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return addTeamTag(dealershipId, tagData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_CREATE_TEAM_TAG_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_CREATE_TEAM_TAG_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updateTeamTag = (teamTagId, teamTagData) => (dispatch, getState) => {
  dispatch(setTeamTagsLoadingState());
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateTeamTagData(dealershipId, teamTagId, teamTagData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_TEAM_TAG_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_TEAM_TAG_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updateTeamTagsSeniority = (params) => (dispatch, getState) => {
  dispatch(setTeamTagsLoadingState());
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateTeamSeniority(dealershipId, params, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_TEAM_TAGS_SENIORITY_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_TEAM_TAG_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updateTeamCapacity = (teamTagsHash) => async (dispatch, getState) => {
  dispatch(setTeamTagsLoadingState());
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  try {
    const results = [];

    Object.values(teamTagsHash).forEach((teamTag) => {
      // eslint-disable-next-line no-param-reassign
      delete teamTag.members;
      results.push(updateTeamTagData(dealershipId, teamTag.id, teamTag, token));
    });

    const responses = await Promise.all(results);

    responses.forEach((response) => {
      dispatch({
        type: SETTINGS_UPDATE_TEAM_TAG_SUCCESS,
        payload: response,
      });
    });
  } catch (error) {
    dispatch({
      type: SETTINGS_UPDATE_TEAM_TAG_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const deleteTeamTag = (teamTagId) => (dispatch, getState) => {
  dispatch(setTeamTagsLoadingState());
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return removeTeamTag(dealershipId, teamTagId, token)
    .then((response) => dispatch({
      type: SETTINGS_DELETE_TEAM_TAG_SUCCESS,
      payload: {
        id: teamTagId,
        response,
      },
    }))
    .catch((error) => dispatch({
      type: SETTINGS_DELETE_TEAM_TAG_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const fetchTeamAdvisorsByServices = (
  services,
  recalls,
  vehicle,
  isMobileTechnician,
) => (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());
  const payload = {
    menu_items_ids: services.map((service) => service.id),
    recalls_ids: recalls.map((recall) => recall.recall_id || recall.id),
    vehicle_id: vehicle.id,
  };
  return fetchResponsibleTeamTag(dealershipId, isMobileTechnician, payload, token)
    .then((response) => {
      dispatch({
        type: SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_SUCCESS,
        payload: response,
      });

      return fetchTeamAdvisors(dealershipId, response.id, token)
        .then((res) => dispatch({
          type: SETTINGS_FETCH_TEAM_ADVISORS_SUCCESS,
          payload: res,
        }))
        .catch((err) => dispatch({
          type: SETTINGS_FETCH_TEAM_ADVISORS_ERROR,
          payload: err,
        }));
    })
    .catch((error) => dispatch({
      type: SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_ERROR,
      payload: error,
    }));
};

export const toggleEmployeeAccess = (isLocking, email) => (dispatch, getState) => {
  dispatch(setLoadingState());
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return toggleEmploymentLock(isLocking, dealershipId, email, token)
    .then((response) => {
      dispatch({
        type: SETTINGS_UPDATE_USER_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_USER_FAILURE,
      payload: { error },
    }));
};

export const createKiosk = (kioskData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return addKiosk(dealershipId, kioskData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_CREATE_KIOSK_SUCCESS,
        payload: response,
      })
    ));
};

export const deleteKiosk = (kioskId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return destroyKiosk(dealershipId, kioskId, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_DELETE_KIOSK_SUCCESS,
        payload: {
          id: kioskId,
          response,
        },
      })
    ));
};

export const updateKiosk = (kioskId, kioskData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateKioskData(dealershipId, kioskId, kioskData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_KIOSK_SUCCESS,
        payload: response,
      })
    ));
};

export const createTvScreen = (tvScreenData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return addTvScreen(dealershipId, tvScreenData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_CREATE_TV_SCREEN_SUCCESS,
        payload: response,
      })
    ));
};

export const deleteTvScreen = (tvScreenId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return destroyTvScreen(dealershipId, tvScreenId, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_DELETE_TV_SCREEN_SUCCESS,
        payload: {
          id: tvScreenId,
          response,
        },
      })
    ));
};

export const updateTvScreen = (tvScreenId, tvScreenData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateTvScreenData(dealershipId, tvScreenId, tvScreenData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_TV_SCREEN_SUCCESS,
        payload: response,
      })
    ));
};

export const createArriveUser = (arriveUserData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return addArriveUser(dealershipId, arriveUserData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_CREATE_ARRIVE_USER_SUCCESS,
        payload: response,
      })
    ));
};

export const deleteArriveUser = (arriveUserId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return destroyArriveUser(dealershipId, arriveUserId, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_DELETE_ARRIVE_USER_SUCCESS,
        payload: {
          id: arriveUserId,
          response,
        },
      })
    ));
};

export const updateArriveUser = (arriveUserId, arriveUserData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateArriveUserData(dealershipId, arriveUserId, arriveUserData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_ARRIVE_USER_SUCCESS,
        payload: response,
      })
    ));
};

export const createRemoteUser = (remoteUserData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return addRemoteUser(dealershipId, remoteUserData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_CREATE_REMOTE_USER_SUCCESS,
        payload: response,
      })
    ));
};

export const deleteRemoteUser = (remoteUserId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return destroyRemoteUser(dealershipId, remoteUserId, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_DELETE_REMOTE_USER_SUCCESS,
        payload: {
          id: remoteUserId,
          response,
        },
      })
    ));
};

export const updateRemoteUser = (remoteUserId, remoteUserData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateRemoteUserData(dealershipId, remoteUserId, remoteUserData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_REMOTE_USER_SUCCESS,
        payload: response,
      })
    ));
};

export const updateCapacitySettings = (params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateDealershipCapacitySettings(dealershipId, params, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_SUCCESS,
        payload: response,
      })
    )).catch((error) => dispatch({
      type: SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updateAdvisor = (advisorId, advisorData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  dispatch({
    type: SETTINGS_ADVISOR_LOADING_STATE,
    payload: {},
  });

  return updateAdvisorData(dealershipId, advisorId, advisorData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_ADVISOR_SUCCESS,
        payload: response,
      })
    )).catch((error) => (
      dispatch({
        type: SETTINGS_UPDATE_ADVISOR_FAILURE,
        payload: { data: Object.values(error.response.data)[0] },
      })
    ));
};

export const bulkUpdateAdvisors = (params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  dispatch({
    type: SETTINGS_ADVISOR_LOADING_STATE,
    payload: {},
  });

  return advisorBulkUpdate(dealershipId, params, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_ADVISORS_SUCCESS,
        payload: response,
      })
    )).catch((error) => (
      dispatch({
        type: SETTINGS_UPDATE_ADVISORS_FAILURE,
        payload: { data: Object.values(error.response.data)[0] },
      })
    ));
};

export const addDriver = (driverData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  dispatch({
    type: SETTINGS_DRIVER_LOADING_STATE,
    payload: {},
  });

  return createDriver(dealershipId, driverData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_CREATE_DRIVER_SUCCESS,
        payload: response,
      })
    )).catch((error) => (
      dispatch({
        type: SETTINGS_CREATE_DRIVER_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const updateDriver = (driverId, driverData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  dispatch({
    type: SETTINGS_DRIVER_LOADING_STATE,
    payload: {},
  });

  return updateDriverData(dealershipId, driverId, driverData, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_DRIVER_SUCCESS,
        payload: response,
      })
    )).catch((error) => (
      dispatch({
        type: SETTINGS_UPDATE_DRIVER_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const removeDriver = (driverId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  dispatch({
    type: SETTINGS_DRIVER_LOADING_STATE,
    payload: {},
  });

  return deleteDriver(dealershipId, driverId, token)
    .then((response) => (
      dispatch({
        type: SETTINGS_DELETE_DRIVER_SUCCESS,
        payload: {
          id: driverId,
          response,
        },
      })
    )).catch((error) => (
      dispatch({
        type: SETTINGS_DELETE_DRIVER_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const updateColorScheme = (stylesheet) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  dispatch({ type: SETTINGS_SET_LOADING_STATE });

  return setStylesheet(dealershipId, stylesheet, token)
    .then(() => (
      dispatch({
        type: SETTINGS_UPDATE_STYLESHEET_SUCCESS,
        payload: { stylesheet },
      })
    ))
    .catch((error) => (
      dispatch({
        type: SETTINGS_UPDATE_STYLESHEET_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const getVacations = (employmentId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return fetchEmploymentVacations(dealershipId, employmentId, token)
    .then((response) => {
      dispatch({
        type: SETTINGS_FETCH_EMPLOYEE_VACATIONS,
        payload: {
          employmentId,
          data: response,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_FETCH_EMPLOYEE_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const addVacations = (employmentId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return createEmploymentVacations(dealershipId, employmentId, params, token)
    .then((response) => {
      dispatch({
        type: SETTINGS_CREATE_EMPLOYEE_VACATIONS,
        payload: {
          employmentId,
          data: response,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_CREATE_EMPLOYEE_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const updateVacations = (employmentId, vacationId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateEmploymentVacations(dealershipId, employmentId, vacationId, params, token)
    .then((response) => {
      dispatch({
        type: SETTINGS_UPDATE_EMPLOYEE_VACATIONS,
        payload: {
          employmentId,
          vacationId,
          data: response,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_UPDATE_EMPLOYEE_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const deleteVacations = (employmentId, vacationId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return deleteEmploymentVacations(dealershipId, employmentId, vacationId, token)
    .then(() => {
      dispatch({
        type: SETTINGS_DELETE_EMPLOYEE_VACATIONS,
        payload: {
          employmentId,
          vacationId,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_DELETE_EMPLOYEE_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const getDriverVacations = (driverId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return fetchVacationsForDriver(dealershipId, driverId, token)
    .then((response) => {
      dispatch({
        type: SETTINGS_FETCH_DRIVER_VACATIONS,
        payload: {
          driverId,
          data: response,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_FETCH_DRIVER_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const addDriverVacations = (driverId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return createVacationsForDriver(dealershipId, driverId, params, token)
    .then((response) => {
      dispatch({
        type: SETTINGS_CREATE_DRIVER_VACATIONS,
        payload: {
          driverId,
          data: response,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_CREATE_DRIVER_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const updateDriverVacations = (driverId, vacationId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateVacationsForDriver(dealershipId, driverId, vacationId, params, token)
    .then((response) => {
      dispatch({
        type: SETTINGS_UPDATE_DRIVER_VACATIONS,
        payload: {
          driverId,
          vacationId,
          data: response,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_UPDATE_DRIVER_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const deleteDriverVacations = (driverId, vacationId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return deleteVacationsForDriver(dealershipId, driverId, vacationId, token)
    .then(() => {
      dispatch({
        type: SETTINGS_DELETE_DRIVER_VACATIONS,
        payload: {
          driverId,
          vacationId,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_DELETE_DRIVER_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const updateMobileTechnician = (
  mobileTechnicianId,
  mobileTechnicianData,
) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  dispatch({
    type: SETTINGS_MOBILE_TECHNICIANS_LOADING_STATE,
    payload: {},
  });

  return updateMobileTechnicians(
    dealershipId,
    mobileTechnicianId,
    mobileTechnicianData,
    token,
  )
    .then((response) => (
      dispatch({
        type: SETTINGS_UPDATE_MOBILE_TECHNICIANS_SUCCESS,
        payload: response,
      })
    )).catch((error) => (
      dispatch({
        type: SETTINGS_UPDATE_MOBILE_TECHNICIANS_FAILURE,
        payload: { data: Object.values(error.response.data)[0] },
      })
    ));
};

export const getMobileTechniciansVacations = (
  mobileTechnicianId,
) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return fecthMobileTechniciansVacations(
    dealershipId,
    mobileTechnicianId,
    token,
  )
    .then((response) => {
      dispatch({
        type: SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS,
        payload: {
          mobileTechnicianId,
          data: response,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const addMobileTechniciansVacations = (
  mobileTechnicianId,
  params,
) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return createMobileTechniciansVacation(
    dealershipId,
    mobileTechnicianId,
    params,
    token,
  )
    .then((response) => {
      dispatch({
        type: SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS,
        payload: {
          mobileTechnicianId,
          data: response,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const updateMobileTechniciansVacations = (
  mobileTechnicianId,
  vacationId,
  params,
) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateMobileTechniciansVacation(
    dealershipId,
    mobileTechnicianId,
    vacationId,
    params,
    token,
  )
    .then((response) => {
      dispatch({
        type: SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS,
        payload: {
          mobileTechnicianId,
          vacationId,
          data: response,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const deleteMobileTechniciansVacations = (
  mobileTechnicianId,
  vacationId,
) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return removeMobileTechniciansVacation(
    dealershipId,
    mobileTechnicianId,
    vacationId,
    token,
  )
    .then(() => {
      dispatch({
        type: SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS,
        payload: {
          mobileTechnicianId,
          vacationId,
        },
      });
    }).catch((error) => (
      dispatch({
        type: SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

const setNonWorkingDaysLoadingState = () => {
  return {
    type: SETTINGS_NON_WORKING_DAYS_LOADING_STATE,
    payload: {},
  };
};

export const getNonWorkingDays = (recivedYear) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  dispatch(setNonWorkingDaysLoadingState());

  return fetchNonWorkingDays(
    dealershipId,
    recivedYear,
    token,
  )
    .then((response) => (
      dispatch({
        type: SETTINGS_FETCH_NON_WORKING_DAYS_SUCCESS,
        payload: response,
      })
    )).catch((error) => (
      dispatch({
        type: SETTINGS_FETCH_NON_WORKING_DAYS_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const addNonWorkingDay = (params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  dispatch(setNonWorkingDaysLoadingState());

  return setHoliday(
    dealershipId,
    params,
    token,
  )
    .then((response) => (
      dispatch({
        type: SETTINGS_ADD_NON_WORKING_DAY_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => (
      dispatch({
        type: SETTINGS_ADD_NON_WORKING_DAY_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};

export const deleteNonWorkingDay = (dateId) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  dispatch(setNonWorkingDaysLoadingState());

  return deleteHoliday(
    dealershipId,
    dateId,
    token,
  )
    .then(() => (
      dispatch({
        type: SETTINGS_DELETE_NON_WORKING_DAY_SUCCESS,
        payload: dateId,
      })
    ))
    .catch((error) => (
      dispatch({
        type: SETTINGS_DELETE_NON_WORKING_DAY_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};
