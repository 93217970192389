import React, { useState } from "react";
import {
  bool, func, objectOf, string,
} from "prop-types";
import cx from "classnames";
import ReactTooltip from "react-tooltip";

import { ReactComponent as MobileTech } from "assets/icons/mobile-technician.svg";
import unselectedIcon from "assets/images/add.svg";
import selectedIcon from "assets/images/checkmark.svg";

import styles from "./styles.module.scss";

const RecallService = ({
  mobileTechnicianEnabled,
  service: {
    name,
    description,
    remedy,
    actionType,
    mobile_technician,
  },
  isHighlighted,
  onClick,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <button
      type="button"
      className={cx(styles.recallService, {
        [styles.highlighted]: isHighlighted,
      })}
      onClick={onClick}
    >
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <div className={styles.nameContainer}>
            <div className={styles.name}>
              <span className={styles.label}>
                {(actionType || "").toLowerCase() === "safety"
                  ? "(SAFETY) "
                  : ""}
                {name}
              </span>
              {mobileTechnicianEnabled && mobile_technician && (
                <MobileTech
                  className={styles.icon}
                  data-for="icons-description"
                  data-tip="Mobile technician"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                />
              )}
            </div>
          </div>
          <div className={styles.selection}>
            {isHighlighted && (
              <div className={styles.selectedIcon}>
                <img
                  src={selectedIcon}
                  alt="Selected"
                />
              </div>
            )}
            {!isHighlighted && (
              <div className={styles.unselectedIcon}>
                <img
                  src={unselectedIcon}
                  alt="Unselected"
                />
              </div>
            )}
          </div>
        </div>
        {description && (
          <div className={styles.data}>
            <span className={styles.dataKey}>Description: </span>
            <span className={styles.dataValue}>{description}</span>
          </div>
        )}
        {remedy && (
          <div className={styles.data}>
            <span className={styles.dataKey}>Remedy: </span>
            <span className={styles.dataValue}>{remedy}</span>
          </div>
        )}
        <div className={styles.price}>Free of charge</div>
      </div>
      {showTooltip && (
        <ReactTooltip
          id="icons-description"
          className={styles.tooltip}
          delayShow={100}
          place="bottom"
          backgroundColor="#F9FAFC"
          textColor="black"
          border
          borderColor="#dedee0"
        />
      )}
    </button>
  );
};

RecallService.propTypes = {
  service: objectOf({
    name: string,
    description: string,
    remedy: string,
    actionType: string,
  }).isRequired,
  onClick: func.isRequired,
  isHighlighted: bool,
  mobileTechnicianEnabled: bool.isRequired,
};

RecallService.defaultProps = {
  isHighlighted: false,
};

export default RecallService;
