import React from "react";
import cx from "classnames";
import { func, string } from "prop-types";

import "./styles.scss";

const TextArea = ({
  error,
  label,
  inputClassName,
  onChange,
  ...props
}) => (
  <div className="conciergeTextAreaWrapper">
    <div className="conciergeTextAreaLabel">{label}</div>
    <textarea
      className={cx("conciergeTextArea", inputClassName)}
      onChange={(e) => onChange(e.target.value)}
      {...props}
    />
    <p className="conciergeInputError">{error}</p>
  </div>
);

TextArea.propTypes = {
  error: string,
  label: string,
  inputClassName: string,
  onChange: func.isRequired,
};

TextArea.defaultProps = {
  error: null,
  label: null,
  inputClassName: null,
};

export default TextArea;
