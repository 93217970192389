import React, { useEffect, useState } from "react";
import {
  arrayOf,
  bool,
  func,
  string,
} from "prop-types";
import cx from "classnames";
import { isEmpty, objOf } from "ramda";
import { chosenTimeSlotPropType } from "shared/prop-types";

import RadioButton from "components/common/RadioButton";
import Panel from "components/common/Panel";
import Button from "components/common/Button";
import { BOOKING_STEP_INTERNAL_NOTE, BOOKING_STEP_TRANSPORT } from "shared/constants";

import styles from "./styles.module.scss";

const Transportation = ({
  setIsWaiting,
  setTransport,
  currentStep,
  fetchAvailableTransport,
  chosenTimeSlot: { quarterSlot: chosenQuarterSlot },
  clientWaiting,
  selectedTransport,
  setSchedulingStep,
  availableTransports,
  availableTransportsLoading,
  availableTransportsError,
  waiterAllowed,
  availableLoaners,
  isPickUp,
}) => {
  const [noAppointmentDatetime, setNoAppointmentDatetime] = useState(true);
  const [firstInit, setFirstInit] = useState(true);
  const remainingLoaners = availableLoaners?.remaining > 0 ? availableLoaners.remaining : 0;
  const maxAvailableLoaners = (
    availableLoaners?.max_available > 0 ? availableLoaners.max_available : 0
  );

  useEffect(() => {
    if (
      chosenQuarterSlot
      && !isEmpty(chosenQuarterSlot)
      && currentStep === BOOKING_STEP_TRANSPORT
    ) {
      fetchAvailableTransport();
      setNoAppointmentDatetime(false);
    }
  }, [chosenQuarterSlot, currentStep]);

  useEffect(() => {
    if (!firstInit) {
      setTransport(null);
    } else if (chosenQuarterSlot) {
      setFirstInit(false);
    }
  }, [chosenQuarterSlot]);

  useEffect(() => {
    if (!waiterAllowed) {
      setIsWaiting(false);
    }
  }, [waiterAllowed]);

  if (noAppointmentDatetime) {
    return <Panel className={styles.loading}>Please select appointment datetime first</Panel>;
  }

  return (
    <div>
      {waiterAllowed && !isPickUp && (
        <>
          <header className={styles.question}>
            Will customer be waiting for your car at the dealership?
          </header>
          <div className={styles.quesradioContainertion}>
            <RadioButton
              id="transportationRadioTrue"
              isChecked={clientWaiting}
              labelText="Yes"
              onClick={() => setIsWaiting(true)}
            />
            <RadioButton
              id="transportationRadioFalse"
              isChecked={!clientWaiting}
              labelText="No"
              onClick={() => setIsWaiting(false)}
            />
          </div>
        </>
      )}
      {(!clientWaiting || !waiterAllowed) && (
        <>
          <header className={styles.select}>
            Does the customer need alternative transportation? The following options are available:
          </header>
          <div className={styles.container}>
            {availableTransportsLoading && (
              <Panel className={styles.loading}>Loading...</Panel>
            )}
            {availableTransportsError && (
              <Panel className={styles.loading}>Please try again.</Panel>
            )}
            {!isPickUp && availableTransports.map((transport) => (
              <button
                type="button"
                className={cx(styles.button, {
                  [styles.selected]: selectedTransport === transport,
                })}
                onClick={() => setTransport(selectedTransport === transport ? null : transport)}
              >
                {transport}
              </button>
            ))}
            {isPickUp && !availableTransportsLoading && !availableTransportsError && (
              <button
                type="button"
                className={cx(styles.button, {
                  [styles.selected]: selectedTransport === "loaner",
                })}
                onClick={() => setTransport(selectedTransport === "loaner" ? null : "loaner")}
              >
                Loaner
              </button>
            )}
          </div>
        </>
      )}
      <div className={styles.wrapper}>
        {selectedTransport === "loaner" && !availableTransportsLoading && (
          <div className={styles.wrapperText}>
            {`Loaners availability: ${remainingLoaners} out of ${maxAvailableLoaners}`}
          </div>
        )}
        <div className={styles.wrapperButton}>
          <Button
            variant="brand"
            onClick={() => setSchedulingStep(BOOKING_STEP_INTERNAL_NOTE)}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

Transportation.propTypes = {
  setIsWaiting: func.isRequired,
  setTransport: func.isRequired,
  clientWaiting: bool.isRequired,
  chosenTimeSlot: chosenTimeSlotPropType,
  currentStep: string.isRequired,
  setSchedulingStep: func.isRequired,
  fetchAvailableTransport: func.isRequired,
  selectedTransport: string,
  availableTransports: arrayOf(string),
  availableTransportsLoading: bool.isRequired,
  availableTransportsError: bool.isRequired,
  waiterAllowed: bool.isRequired,
  availableLoaners: objOf(string).isRequired,
  isPickUp: bool.isRequired,
};

Transportation.defaultProps = {
  availableTransports: [],
  selectedTransport: null,
  chosenTimeSlot: null,
};

export default Transportation;
