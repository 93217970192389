import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import cx from "classnames";
import {
  settingsCapacityTimeBufferSelector,
  settingsDrivingTimeBufferSelector,
  settingsTeamTagsLoadingStateSelector,
  settingsTeamTagsSelector,
} from "store/selectors/settings-selectors";
import { updateCapacitySettings, updateTeamCapacity } from "store/actions/settings-actions";
import { makeHashFromArray } from "shared/utils";
import HeaderEdit from "components/common/HeaderEdit";
import Panel from "components/common/Panel";
import ReactTooltip from "react-tooltip";
import tooltipIcon from "assets/images/tooltip.svg";
import Input from "components/common/Input";
import {
  arrayOf, func, node, objectOf,
} from "prop-types";
import Tabs from "components/common/Tabs";
import TeamCapacityInputs from "./TeamCapacityInputs";

import styles from "./styles.module.scss";

const maxHourlyAppointmentsOptions = {
  1: {
    label: "1",
    value: "1",
  },
  2: {
    label: "2",
    value: "2",
  },
  3: {
    label: "3",
    value: "3",
  },
  4: {
    label: "4",
    value: "4",
  },
  12: {
    label: "12",
    value: "12",
  },
};

const DailyTeamCapacity = ({
  teamTags,
  capacityTimeBuffer,
  drivingTimeBuffer,
  onUpdateTeamsCapacity,
  onUpdateDealershipCapacitySettings,
}) => {
  const [teamTagsHash, setTeamTagsHash] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [capacityBuffer, setCapacityBuffer] = useState(capacityTimeBuffer);
  const [drivingBuffer, setDrivingBuffer] = useState(drivingTimeBuffer);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [tabsConfig, setTabsConfig] = useState([]);
  const [isMobileTechnician, setIsMobileTechnician] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const config = [];
    const hashedTags = makeHashFromArray(teamTags);

    Object.values(teamTags).forEach((teamTag) => {
      config.push(
        {
          label: teamTag.name,
          value: teamTag.id,
        },
      );
    });

    setTabsConfig(config);
    setSelectedItemId(config[0]?.value);
    setTeamTagsHash(hashedTags);
  }, [teamTags]);

  useEffect(() => {
    if (selectedItemId) {
      const item = Object.values(teamTagsHash)
        .filter((i) => i.id === selectedItemId);

      setIsMobileTechnician(item[0].mobile);
    }
  }, [selectedItemId]);

  useEffect(() => {
    setCapacityBuffer(capacityTimeBuffer);
    setDrivingBuffer(drivingTimeBuffer);
  }, [capacityTimeBuffer, drivingTimeBuffer]);

  useEffect(() => {
    if (teamTagsHash) {
      const hasEmptyValues = Object.values(teamTagsHash).find((obj) => (
        Number(obj.max_advisor_appointments_per_day) === 0
        || obj.max_advisor_appointments_per_day === ""
        || Object.values(obj.max_team_hours_per_weekday).includes(null)
      ));

      return hasEmptyValues ? setIsSaveDisabled(true) : setIsSaveDisabled(false);
    }
  }, [teamTagsHash]);

  const saveChanges = () => {
    setIsEditing(false);
    onUpdateTeamsCapacity(teamTagsHash);
    onUpdateDealershipCapacitySettings({
      capacity_time_buffer: capacityBuffer,
      driving_time_buffer: drivingBuffer,
    });
  };

  const cancelChanges = () => {
    setTeamTagsHash(makeHashFromArray(teamTags));
    setCapacityBuffer(capacityTimeBuffer);
    setDrivingBuffer(drivingTimeBuffer);
    setIsEditing(false);
  };

  const setTeamCapacity = (teamTagId, setting, value) => {
    setTeamTagsHash((prevTeamTags) => {
      return {
        ...prevTeamTags,
        [teamTagId]: {
          ...prevTeamTags[teamTagId],
          [setting]: value,
        },
      };
    });
  };

  const handleChange = (teamTagId, setting, value, isWaiters) => {
    let data = value;
    if (setting === "max_team_hours_per_weekday" || isWaiters) {
      const preparedData = {};
      Object.keys(value).forEach((key) => {
        if (isWaiters) {
          preparedData[key] = value[key] || value[key] === 0 ? Number(value[key]) : null;
        } else {
          preparedData[key] = value[key] === "" ? null : Number(value[key]);
        }
      });
      data = preparedData;
    }

    setTeamCapacity(teamTagId, setting, data);
  };

  const header = (
    <div className={styles.header}>
      <p className={styles.label}>
        Daily capacity
      </p>
      <HeaderEdit
        isEditing={isEditing}
        handleEdit={() => setIsEditing(true)}
        handleCancel={cancelChanges}
        handleSave={saveChanges}
        isSaveDisabled={isSaveDisabled}
      />
    </div>
  );

  return (
    <Panel
      header={header}
    >
      <div className={styles.navigation}>
        <Tabs
          config={tabsConfig}
          selectedItem={selectedItemId}
          selectTabAction={setSelectedItemId}
          isGeneral
        />
        <div className={styles.inputsContainer}>
          <Input
            label="Capacity time buffer"
            type="number"
            step="0.1"
            min="0"
            max="20"
            disabled={!isEditing}
            value={capacityBuffer}
            onChange={(value) => value >= 0 && value <= 20 && setCapacityBuffer(value)}
          />
          <Input
            label="Driving time buffer"
            type="number"
            step="0.1"
            min="0"
            max="20"
            disabled={!isEditing}
            value={drivingBuffer}
            onChange={(value) => value >= 0 && value <= 20 && setDrivingBuffer(value)}
          />
        </div>
        {!isMobileTechnician && (
          <div className={styles.inputsContainer}>
            {Object.values(teamTagsHash).filter((item) => item.id === selectedItemId)
              .map((teamTag) => (
                <>
                  <div>
                    <div className={styles.label}>
                      <div className={styles.labelTitle}>
                        <span>Max hourly appointments</span>
                        <img
                          className={styles.captionIcon}
                          src={tooltipIcon}
                          alt="info"
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)}
                          data-for="capacity-tooltip"
                          data-tip={`
                            If an advisor is only working part-time on a particular day, their capacity for that day is a proportional fraction of their "regular" capacity
                          `}
                        />
                      </div>
                      <span className={styles.labelSubtitle}>/per advisor</span>
                    </div>
                    <Select
                      className={styles.select}
                      classNamePrefix={styles.selectSelect}
                      options={Object.values(maxHourlyAppointmentsOptions)}
                      value={teamTag.max_advisor_appointments_per_hour}
                      isDisabled={!isEditing}
                      onChange={(value) => handleChange(teamTag.id, "max_advisor_appointments_per_hour", value.value)}
                      placeholder={teamTag.max_advisor_appointments_per_hour}
                    />
                  </div>
                  <Input
                    label="Max daily appointments"
                    subtitle="/per advisor"
                    inputClassName={cx(
                      styles.itemInput,
                      {
                        [styles.invalid]: (
                          Number(teamTag.max_advisor_appointments_per_day) === 0
                        ),
                      },
                    )}
                    type="number"
                    min="0"
                    value={teamTag.max_advisor_appointments_per_day}
                    disabled={!isEditing}
                    onChange={(value) => handleChange(teamTag.id, "max_advisor_appointments_per_day", value)}
                  />
                </>
              ))}
          </div>
        )}
      </div>
      <div className={styles.container}>
        <div />
        <div>
          <div
            data-for="capacity-tooltip"
            data-tip="Based on actual plus upsell"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            className={styles.caption}
          >
            Max team hours
            <img
              className={styles.captionIcon}
              src={tooltipIcon}
              alt=""
            />
          </div>
          <div className={styles.captionSubtitle}>
            /per day
          </div>
        </div>
        <div>
          {!isMobileTechnician && (
            <div>
              <div className={styles.caption}>
                Max waiters
              </div>
              <div className={styles.captionSubtitle}>
                /per hour
              </div>
            </div>
          )}
        </div>
        <div>
          {!isMobileTechnician && (
            <div>
              <div className={styles.caption}>
                Max waiters
              </div>
              <div className={styles.captionSubtitle}>
                /per day
              </div>
            </div>
          )}
        </div>
        {Object.values(teamTagsHash).filter((item) => item.id === selectedItemId)
          .map((teamTag) => (
            <TeamCapacityInputs
              isEditing={isEditing}
              key={teamTag.id}
              teamTag={teamTag}
              onChange={handleChange}
            />
          ))}
      </div>
      {showTooltip && (
        <ReactTooltip
          id="capacity-tooltip"
          className={styles.tooltip}
          place="bottom"
          backgroundColor="#F9FAFC"
          textColor="black"
          border
          borderColor="#dedee0"
          delayShow={100}
        />
      )}
    </Panel>
  );
};

DailyTeamCapacity.propTypes = {
  teamTags: arrayOf(node).isRequired,
  capacityTimeBuffer: objectOf(node).isRequired,
  drivingTimeBuffer: objectOf(node).isRequired,
  onUpdateTeamsCapacity: func.isRequired,
  onUpdateDealershipCapacitySettings: func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isTeamTagsLoading: settingsTeamTagsLoadingStateSelector(state),
    teamTags: settingsTeamTagsSelector(state),
    capacityTimeBuffer: settingsCapacityTimeBufferSelector(state),
    drivingTimeBuffer: settingsDrivingTimeBufferSelector(state),
  };
};

const actions = {
  onUpdateTeamsCapacity: updateTeamCapacity,
  onUpdateDealershipCapacitySettings: updateCapacitySettings,
};

export default connect(mapStateToProps, actions)(DailyTeamCapacity);
