export const periods = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "tomorrow",
    label: "Tomorrow",
  },
  {
    value: "week",
    label: "Week",
  },
  {
    value: "other",
    label: "Other period",
  },
];

export const deliveryTypes = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "pickup",
    label: "Pick-up",
  },
  {
    value: "dropoff",
    label: "Drop-off",
  },
];

export const icons = {
  assigned: "driverAssigned",
  en_route_to_customer: "departure",
  arrived_to_customer: "position",
  en_route_to_dealership: "arrive",
};

export const serviceZone = {
  mon: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
    G: false,
    H: false,
  },
  tue: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
    G: false,
    H: false,
  },
  wed: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
    G: false,
    H: false,
  },
  thu: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
    G: false,
    H: false,
  },
  fri: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
    G: false,
    H: false,
  },
  sat: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
    G: false,
    H: false,
  },
  sun: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
    G: false,
    H: false,
  },
};
