import React, { useCallback, useEffect, useState } from "react";
import {
  arrayOf, bool, func, node, number, objectOf, oneOfType, shape, string,
} from "prop-types";
import { isEmpty, uniq } from "ramda";
import StyledSelect from "components/common/StyledSelect";
import AsyncCreatableSelect from "react-select/async-creatable";
import { customerFieldsPropType } from "shared/prop-types";
import { convertArrayToSelectOptions, isMakeModelValid } from "shared/utils/common";
import { fetchCdkModelCodeSuggestions } from "shared/api";
import {
  resetVehicleSetByVin,
  retrieveCdkModelCodeByVin,
  retrieveVehicleSetByVin,
} from "store/actions/app-actions";
import {
  resetDataEditionState,
} from "store/actions/scheduling-actions";
import { connect } from "react-redux";
import {
  cdkModelCodeByVinSelector,
  dealershipIdSelector,
  vehicleSetByVinErrorSelector,
  vehicleSetByVinLoadingSelector,
  vehicleSetByVinSelector,
} from "store/selectors/app-selectors";
import { dmsTypeSelector } from "store/selectors/settings-selectors";
import { authTokenSelector } from "store/selectors/auth-selectors";
import Modal from "components/common/Modal";
import { renderDataEditionErrors } from "../helpers";

import styles from "../styles.module.scss";

const EditVehicleModal = ({
  onClose,
  title,
  fields,
  initialData,
  defaultMake,
  onSubmit,
  loading,
  error,
  submitButtonText,
  makeModelYearMap,
  cdkModelCodeByVin,
  fetchCdkModelCodeByVin,
  fetchVehicleSetByVin,
  resetVehicleSetByVinAction,
  resetDataEditionStateAction,
  vehicleSetByVin,
  vehicleSetByVinLoading,
  vehicleSetByVinError,
  dmsType,
  dealershipId,
  token,
}) => {
  const [data, setData] = useState(initialData);
  const [vinVerified, setVinVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [validationError, setValidationError] = useState("");

  const reset = () => {
    resetVehicleSetByVinAction();
    resetDataEditionStateAction();
  };

  useEffect(() => {
    if (!initialData.make && defaultMake) {
      setData(
        (items) => {
          return {
            ...items,
            make: defaultMake,
          };
        },
      );
    }
  }, []);

  useEffect(() => {
    if (!vehicleSetByVin) {
      return;
    }
    const { make, model, model_year } = vehicleSetByVin;
    if (submitted && (
      data.make !== make || data.model !== model || data.model_year !== model_year
    )) {
      setValidationError("Data mismatch, please verify vin.");
      setSubmitted(false);
      reset();
      return;
    }
    setVinVerified(true);
    setData(
      (items) => {
        return {
          ...items,
          make,
          model,
          model_year,
        };
      },
    );
  }, [vehicleSetByVin]);

  useEffect(() => {
    if (!cdkModelCodeByVin) {
      return;
    }
    setData(
      (items) => {
        return {
          ...items,
          external_model_code: cdkModelCodeByVin,
        };
      },
    );
  }, [cdkModelCodeByVin]);

  useEffect(() => {
    setValidationError("");
  }, [data]);

  const promisedOptions = (inputValue) => {
    if (inputValue.length < 2) {
      return [];
    }
    return new Promise((resolve) => {
      fetchCdkModelCodeSuggestions(
        dealershipId,
        {
          dealership_id: dealershipId,
          model_description: inputValue,
        },
        token,
      )
        .then((items) => resolve(items.map(({ model_code, model_description }) => {
          return {
            label: model_description,
            value: model_code,
          };
        })))
        .catch((e) => console.warn(e));
    });
  };

  const handleChange = (type, value) => {
    setValidationError("");
    switch (type) {
      case "make": {
        setData(
          (items) => {
            return {
              ...items,
              make: value,
              model: null,
              model_year: null,
            };
          },
        );
        break;
      }
      case "model": {
        setData(
          (items) => {
            return {
              ...items,
              model: value,
              model_year: null,
            };
          },
        );
        break;
      }
      case "model_year": {
        setData(
          (items) => {
            return {
              ...items,
              model_year: value,
            };
          },
        );
        break;
      }
      case "external_model_code": {
        setData(
          (items) => {
            return {
              ...items,
              external_model_code: value,
            };
          },
        );
        break;
      }
      default:
        break;
    }
  };

  const getValues = useCallback(
    (type) => {
      if (!makeModelYearMap || isEmpty(makeModelYearMap)) {
        return [];
      }
      const { make, model, model_year } = data;

      switch (type) {
        case "make":
          return Object.keys(makeModelYearMap);
        case "model": {
          if (!isMakeModelValid(make) || !makeModelYearMap[make]) {
            return [];
          }
          if (isMakeModelValid(make) && model_year) {
            return Object.keys(makeModelYearMap[make] || {})
              .filter((key) => makeModelYearMap[make][key].includes(model_year));
          }
          return Object.keys(makeModelYearMap[make]);
        }
        case "model_year": {
          if ((!isMakeModelValid(make) && !isMakeModelValid(model)) || !makeModelYearMap[make]) {
            return [];
          }
          if (!isMakeModelValid(model)) {
            const allYears = Object.keys(makeModelYearMap[make]).reduce(
              (yearList, modelItem) => [
                ...yearList,
                ...makeModelYearMap[make][modelItem],
              ],
              [],
            );
            return uniq(allYears).sort().reverse();
          }
          return makeModelYearMap[make][model];
        }
        default:
          break;
      }
    },
    [data, makeModelYearMap],
  );

  const handleVinLookup = (vin) => {
    fetchVehicleSetByVin(vin);
    if (dmsType === "cdk") {
      fetchCdkModelCodeByVin(vin);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (submitted && !loading && !error) {
      handleClose();
    }
  }, [loading]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !isMakeModelValid(data.make)
      || !isMakeModelValid(data.model)
      || !data.make
      || !data.model
      || !data.model_year
    ) {
      return setValidationError("Make Model and Model Year are required.");
    }

    setSubmitted(true);
    // check vin before submit
    if (!vinVerified && data.vin) {
      fetchVehicleSetByVin(data.vin);
    }

    if (!loading && !validationError) {
      onSubmit(data);
    }
  };

  const renderVin = (field) => (
    <label
      htmlFor={field.id}
      id={field.id}
      key={field.id}
      className={styles.label}
    >
      <span>{field.label}</span>
      <div className={styles.inputGroup}>
        <input
          {...field}
          className={styles.input}
          name={field.id}
          value={data[field.id]}
          onChange={(e) => {
            setVinVerified(false);
            setValidationError("");
            setData({
              ...data,
              [field.id]: e.target.value.trim(),
            });
          }}
        />
        <button
          type="button"
          className={styles.inputButton}
          onClick={() => handleVinLookup(data[field.id])}
          disabled={!data[field.id] || vehicleSetByVinLoading}
        >
          Verify
        </button>
      </div>
    </label>
  );

  const renderMakeModelYear = (items) => items.map((field) => (
    <label
      htmlFor={field.id}
      id={field.id}
      key={field.id}
      className={styles.label}
    >
      {field.label}
      <StyledSelect
        value={{
          label: data[field.id],
          value: data[field.id],
        }}
        options={convertArrayToSelectOptions(getValues(field.id))}
        className={styles.select}
        onChange={({ value }) => handleChange(field.id, value)}
      />
    </label>
  ));

  const renderCdkModelCode = (field) => (
    <label
      htmlFor={field.id}
      id={field.id}
      key={field.id}
      className={styles.label}
    >
      {field.label}
      <AsyncCreatableSelect
        className={styles.modelCodeSelectContainer}
        classNamePrefix="modelCodeSelect"
        placeholder="Select or create"
        cacheOptions
        value={{
          label: data[field.id],
          value: data[field.id],
        }}
        onChange={({ value }) => handleChange(field.id, value)}
        loadOptions={promisedOptions}
        menuPlacement="top"
      />
    </label>
  );

  return (
    <Modal
      title={title}
      size="small"
      isForm
      loading={loading}
      cancelButtonText="Cancel"
      submitButtonText={submitButtonText}
      onCancel={handleClose}
      onSubmit={handleSubmit}
    >
      <div className={styles.body}>
        {loading ? (
          <span className={styles.loader}>
            Loading...
          </span>
        ) : (
          fields.map((field) => {
            if (field.id === "external_model_code") {
              if (dmsType !== "cdk") {
                return null;
              }
              return renderCdkModelCode(field);
            }
            if (field.id === "vin") {
              return renderVin(field);
            }

            if (field.id === "makeModelYear") {
              return (
                <div className={styles.makeModelYearRow}>
                  {renderMakeModelYear(field.items)}
                </div>
              );
            }

            return (
              <label
                htmlFor={field.id}
                id={field.id}
                key={field.id}
                className={styles.label}
              >
                {field.label}
                <input
                  {...field}
                  className={styles.input}
                  name={field.id}
                  value={data[field.id]}
                  onChange={(e) => {
                    setValidationError("");
                    setData({
                      ...data,
                      [field.id]: e.target.value,
                    });
                  }}
                />
              </label>
            );
          })
        )}
        {error && renderDataEditionErrors(error)}
        {validationError && renderDataEditionErrors(validationError)}
        {vehicleSetByVinError && renderDataEditionErrors(vehicleSetByVinError)}
      </div>
    </Modal>
  );
};

EditVehicleModal.propTypes = {
  handleClose: func.isRequired,
  title: string.isRequired,
  fields: arrayOf(customerFieldsPropType).isRequired,
  initialData: objectOf(node),
  onSubmit: func.isRequired,
  loading: bool.isRequired,
  error: oneOfType([string, arrayOf(string)]),
  submitButtonText: string,
  dmsType: string,
  defaultMake: string,
  makeModelYearMap: oneOfType(shape({ make: func }), func).isRequired,
  cdkModelCodeByVin: string.isRequired,
  fetchCdkModelCodeByVin: func.isRequired,
  fetchVehicleSetByVin: func.isRequired,
  resetVehicleSetByVinAction: func.isRequired,
  resetDataEditionStateAction: func.isRequired,
  vehicleSetByVin: shape({
    make: func,
    model: string,
    model_year: number,
  }),
  vehicleSetByVinLoading: func.isRequired,
  vehicleSetByVinError: oneOfType([string, arrayOf(string)]).isRequired,
  dealershipId: string.isRequired,
  token: string.isRequired,
  onClose: func.isRequired,
};

EditVehicleModal.defaultProps = {
  initialData: {},
  submitButtonText: "Create",
  error: null,
  dmsType: null,
  vehicleSetByVin: null,
  defaultMake: null,
};

const mapStateToProps = (state) => {
  return {
    vehicleSetByVin: vehicleSetByVinSelector(state),
    vehicleSetByVinLoading: vehicleSetByVinLoadingSelector(state),
    vehicleSetByVinError: vehicleSetByVinErrorSelector(state),
    cdkModelCodeByVin: cdkModelCodeByVinSelector(state),
    dmsType: dmsTypeSelector(state),
    dealershipId: dealershipIdSelector(state),
    token: authTokenSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchVehicleSetByVin: retrieveVehicleSetByVin,
  fetchCdkModelCodeByVin: retrieveCdkModelCodeByVin,
  resetVehicleSetByVinAction: resetVehicleSetByVin,
  resetDataEditionStateAction: resetDataEditionState,
})(EditVehicleModal);
