import {
  createMenuTemplate,
  deleteMenuTemplate,
  duplicateMenuTemplate,
  fetchDealershipRecalls,
  fetchMenuTemplates,
  toggleMenuTemplate,
  updateMenuTemplate,
  updateRecall,
} from "shared/api";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import { authTokenSelector } from "store/selectors/auth-selectors";
import { menuTemplatesRequestIdSelector } from "store/selectors/menu-templates-selectors";
import { SET_TABLE_OPTIONS } from "store/reducers/table-options-reducer";

export const MENU_TEMPLATES_SET_LOADING_STATE = "MENU_TEMPLATES_SET_LOADING_STATE";
export const MENU_TEMPLATES_FETCH_SUCCESS = "MENU_TEMPLATES_FETCH_SUCCESS";
export const MENU_TEMPLATES_UPDATE_SUCCESS = "MENU_TEMPLATES_UPDATE_SUCCESS";
export const MENU_TEMPLATES_UPDATE_FAILURE = "MENU_TEMPLATES_UPDATE_FAILURE";
export const MENU_TEMPLATES_DUPLICATE_SUCCESS = "MENU_TEMPLATES_DUPLICATE_SUCCESS";
export const MENU_TEMPLATES_TOGGLE_SUCCESS = "MENU_TEMPLATES_TOGGLE_SUCCESS";
export const MENU_TEMPLATES_DELETE_SUCCESS = "MENU_TEMPLATES_DELETE_SUCCESS";
export const MENU_TEMPLATES_ADD_SUCCESS = "MENU_TEMPLATES_ADD_SUCCESS";
export const MENU_TEMPLATES_CANCEL_CHANGES = "MENU_TEMPLATES_CANCEL_CHANGES";
export const MENU_TEMPLATES_DEFAULT_ERROR_HANDLER = "MENU_TEMPLATES_DEFAULT_ERROR_HANDLER";
export const MENU_TEMPLATES_FETCH_DEALERSHIPS_RECALLS_SUCCESS = "MENU_TEMPLATES_FETCH_DEALERSHIPS_RECALLS_SUCCESS";
export const MENU_TEMPLATES_UPDATE_DEALERSHIPS_RECALL_SUCCESS = "MENU_TEMPLATES_UPDATE_DEALERSHIPS_RECALL_SUCCESS";

const setLoadingState = (requestId) => {
  return {
    type: MENU_TEMPLATES_SET_LOADING_STATE,
    payload: {},
    meta: { requestId },
  };
};

export const setCancelChanges = () => {
  return {
    type: MENU_TEMPLATES_CANCEL_CHANGES,
    payload: {},
  };
};

export const getMenuTemplates = (params = {}) => (dispatch, getState) => {
  const requestId = menuTemplatesRequestIdSelector(getState()) + 1;
  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());

  dispatch(setLoadingState(requestId));

  return fetchMenuTemplates(dealershipId, authToken, params)
    .then((response) => {
      dispatch({
        type: SET_TABLE_OPTIONS,
        payload: Object.assign(response.headers, params),
      });
      dispatch({
        type: MENU_TEMPLATES_FETCH_SUCCESS,
        payload: response.data,
        meta: { requestId },
      });
    })
    .catch((error) => dispatch({
      type: MENU_TEMPLATES_DEFAULT_ERROR_HANDLER,
      payload: { error },
    }));
};

export const updateTemplate = (id, params) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());
  const parsedParams = {
    name: params.templateName,
    ignore_vehicle_mileage: params.ignoreMileage,
    active: params.templateIsActive,
  };

  return updateMenuTemplate(dealershipId, authToken, id, parsedParams)
    .then(() => dispatch({
      type: MENU_TEMPLATES_UPDATE_SUCCESS,
      payload: {
        id,
        params,
      },
    }));
};

export const duplicateTemplate = (id, name) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());
  return duplicateMenuTemplate(dealershipId, authToken, id)
    .then((response) => (
      dispatch(updateTemplate(response.id, name))
        .then(() => dispatch({
          type: MENU_TEMPLATES_DUPLICATE_SUCCESS,
          payload: {
            template: {
              ...response,
              name,
            },
          },
        }))
    ))
    .catch((error) => dispatch({
      type: MENU_TEMPLATES_DEFAULT_ERROR_HANDLER,
      payload: { error },
    }));
};

export const toggleTemplate = (id, active) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());
  return toggleMenuTemplate(dealershipId, authToken, id, active)
    .then(() => dispatch({
      type: MENU_TEMPLATES_TOGGLE_SUCCESS,
      payload: { id },
    }))
    .catch((error) => dispatch({
      type: MENU_TEMPLATES_DEFAULT_ERROR_HANDLER,
      payload: { error },
    }));
};

export const deleteTemplate = (id) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());
  return deleteMenuTemplate(dealershipId, authToken, id)
    .then(() => dispatch({
      type: MENU_TEMPLATES_DELETE_SUCCESS,
      payload: { id },
    }))
    .catch((error) => dispatch({
      type: MENU_TEMPLATES_DEFAULT_ERROR_HANDLER,
      payload: { error },
    }));
};

export const createTemplate = (name, isExtension) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());
  return createMenuTemplate(dealershipId, authToken, name, isExtension)
    .then((response) => dispatch({
      type: MENU_TEMPLATES_ADD_SUCCESS,
      payload: { template: response },
    }));
};

export const getDealershipRecalls = ({ sort, page, per_page }) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());

  return fetchDealershipRecalls(
    dealershipId,
    {
      sort,
      page,
      per_page,
    },
    authToken,
  ).then((response) => {
    dispatch({
      type: MENU_TEMPLATES_FETCH_DEALERSHIPS_RECALLS_SUCCESS,
      payload: {
        totalRecalls: response.headers.total,
        recalls: response.data,
      },
    });
  })
    .catch((error) => dispatch({
      type: MENU_TEMPLATES_DEFAULT_ERROR_HANDLER,
      payload: { error },
    }));
};

export const updateDealershipRecall = (
  recallId,
  vinSpecific,
  allocatedLaborTime,
  actualLaborTime,
  opCodeId,
  availability,
  teamTagIds,
  availableOnWeekends,
) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());

  return updateRecall(
    dealershipId,
    recallId,
    {
      vin_specific: vinSpecific,
      allocated_labor_time: allocatedLaborTime,
      actual_labor_time: actualLaborTime,
      op_code_id: opCodeId,
      team_tag_ids: teamTagIds,
      availability,
      available_on_weekends: availableOnWeekends,
    },
    authToken,
  ).then((response) => {
    dispatch({
      type: MENU_TEMPLATES_UPDATE_DEALERSHIPS_RECALL_SUCCESS,
      payload: response,
    });
  })
    .catch((error) => dispatch({
      type: MENU_TEMPLATES_UPDATE_FAILURE,
      payload: { error },
    }));
};
