import React, { useEffect, useState } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";
import { formatName } from "shared/utils/common";
import { integrationPropType } from "shared/prop-types";
import { updateMobileTechnicianIntegrationOptions } from "store/actions/settings-actions";
import HeaderEdit from "components/common/HeaderEdit";
import Input from "components/common/Input";
import StyledSelect from "components/common/StyledSelect";
import Accordion from "components/common/Accordion";
import ActivitySwitch from "../ActivitySwitch";

import styles from "../styles.module.scss";

const MobileTechnicianIntegration = ({
  integration,
  updateIntegrationHandler,
}) => {
  const {
    active,
    settings,
    id,
  } = integration;
  const [mobileTechnicianSettings, setMobileTechnicianSettings] = useState({});
  const [isActive, setIsActive] = useState(active);
  const [isEditing, setIsEditing] = useState(false);
  const [erros, setErrors] = useState({});

  useEffect(() => {
    setMobileTechnicianSettings(settings);
  }, [settings]);

  const validate = (item) => {
    const result = {
      max_distance: item.max_distance < 1 || item.max_distance === "",
      time_variance: item.time_variance < 1 || item.time_variance === "",
      slots_interval: item.slots_interval < 1 || item.slots_interval === "",
    };
    setErrors(result);

    return !Object.values(result).find((v) => v);
  };

  const save = () => {
    const params = {
      settings: mobileTechnicianSettings,
      active: isActive,
    };
    if (validate(mobileTechnicianSettings)) {
      updateIntegrationHandler(id, params);
      setIsEditing(false);
    }
  };

  const cancelChanges = () => {
    setMobileTechnicianSettings({ ...settings });
    setIsActive(active);
    setErrors({});
    setIsEditing(false);
  };

  const setSegmentsNumber = (value) => {
    setMobileTechnicianSettings({
      ...mobileTechnicianSettings,
      segments_number: Number(value),
    });
  };

  const segmentNumberOptions = [
    {
      label: "4",
      value: "4",
      selectable: true,
    },
    {
      label: "6",
      value: "6",
      selectable: true,
    },
    {
      label: "8",
      value: "8",
      selectable: true,
    },
  ];

  return (
    <Accordion
      title="Mobile Technician"
      customClass={styles.accordion}
    >
      <section className={styles.container}>
        <ActivitySwitch
          isEditing={isEditing}
          active={isActive}
          onChange={() => setIsActive(!isActive)}
        />
        <section className={styles.inputsContainer}>
          {Object.keys(settings).map((keyName) => (
            keyName !== "segments_number"
          ) && (
            <Input
              label={keyName === "max_pickups_per_hour"
                ? "Max Appointments Per Hour"
                : formatName(
                  keyName === "slots_interval"
                    ? "slots_interval_(minutes)"
                    : keyName,
                )}
              value={mobileTechnicianSettings[keyName]}
              onChange={(value) => setMobileTechnicianSettings((prev) => {
                return {
                  ...prev,
                  [keyName]: value,
                };
              })}
              key={keyName}
              type="number"
              min={keyName === "max_distance" || keyName === "time_variance"
                ? 1
                : 0}
              disabled={!isEditing}
              error={erros[keyName] && `${formatName(keyName)} cannot be less than 1`}
            />
          ))}
          <div className={styles.dropdown}>
            <StyledSelect
              key="integration_type"
              label={formatName("segments_number")}
              options={segmentNumberOptions}
              disabled={!isEditing}
              value={{
                label: mobileTechnicianSettings.segments_number,
                value: mobileTechnicianSettings.segments_number,
                selectable: true,
              }}
              onChange={(val) => setSegmentsNumber(val.value)}
            />
          </div>
        </section>
      </section>
      <HeaderEdit
        isEditing={isEditing}
        handleCancel={cancelChanges}
        handleSave={save}
        handleEdit={() => setIsEditing(true)}
      />
    </Accordion>
  );
};

MobileTechnicianIntegration.propTypes = {
  integration: integrationPropType.isRequired,
  updateIntegrationHandler: func.isRequired,
};

const actions = {
  updateIntegrationHandler: updateMobileTechnicianIntegrationOptions,
};

export default connect(null, actions)(MobileTechnicianIntegration);
