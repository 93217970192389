import {
  SCHEDULING_CREATE_CUSTOMER_SUCCESS,
  SCHEDULING_CREATE_VEHICLE_SUCCESS,
  SCHEDULING_CREATE_VEHICLE_SUCCESS_WITHOUT_DMS,
  SCHEDULING_FETCH_CUSTOMERS_FAILURE,
  SCHEDULING_FETCH_CUSTOMERS_SUCCESS,
  SCHEDULING_RESET_DATA_EDITION_STATE,
  SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA,
  SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
  SCHEDULING_SET_CUSTOMERS_LOADING_STATE,
  SCHEDULING_UPDATE_CUSTOMER_SUCCESS,
  SCHEDULING_UPDATE_VEHICLE_SUCCESS,
  SCHEDULING_UPDATE_VEHICLE_SUCCESS_WITHOUT_DMS,
} from "store/actions/scheduling-actions";

const initialState = {
  customers: [],
  customersFetched: false,
  searchPhrase: "",
  error: null,
  isLoading: false,
  dataEditionModalSaving: false,
  dataEditionModalSavingError: "",
  savedWithoutDmsNotification: null,
};

const scheduling = (state = initialState, { type, payload }) => {
  switch (type) {
    case SCHEDULING_SET_CUSTOMERS_LOADING_STATE:
      return {
        ...state,
        searchPhrase: payload.searchPhrase,
        isLoading: true,
        customersFetched: false,
      };
    case SCHEDULING_FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: payload,
        error: null,
        isLoading: false,
        customersFetched: true,
      };
    case SCHEDULING_FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        customers: [],
        error: payload.error,
        isLoading: false,
      };
    case SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA:
      return {
        ...state,
        error: null,
        savingCustomerId: payload.customerId,
        dataEditionModalSaving: true,
        dataEditionModalSavingError: "",
        savedWithoutDmsNotification: null,
      };
    case SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: payload.error.response,
      };
    case SCHEDULING_RESET_DATA_EDITION_STATE:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: "",
        savedWithoutDmsNotification: null,
      };
    case SCHEDULING_CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: "",
        savingCustomerId: null,
        customers: payload,
      };
    case SCHEDULING_CREATE_VEHICLE_SUCCESS_WITHOUT_DMS:
    case SCHEDULING_UPDATE_VEHICLE_SUCCESS_WITHOUT_DMS:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: "",
        savedWithoutDmsNotification: payload,
      };
    case SCHEDULING_UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: "",
        customers: payload,
      };
    case SCHEDULING_UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: "",
        customers: payload,
      };
    case SCHEDULING_CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: "",
        customers: [].concat(payload),
      };
    default:
      return state;
  }
};

export default scheduling;
