import React from "react";
import {
  arrayOf, node, objectOf, oneOfType, shape,
} from "prop-types";
import dateFns from "date-fns";
import { AMERICAN_DATE_FORMAT } from "shared/utils/datetime";

import styles from "../styles.module.scss";

const TeamAllocation = ({ booking }) => {
  const { teams_time_allocations } = booking;
  if (!teams_time_allocations || !teams_time_allocations.length) {
    return null;
  }

  return (
    <>
      <div className={styles.sectionTitle}>Team Allocation</div>
      <div className={styles.sectionBody}>
        {teams_time_allocations.map(({ actual_plus_time, day, team_tag }) => (
          <div className={styles.item}>
            <span className={styles.itemKey}>
              {team_tag.name}
              {" "}
              :
              {" "}
            </span>
            <span className={styles.itemValue}>
              {dateFns.format(day, AMERICAN_DATE_FORMAT)}
              {" "}
              <br />
              {+actual_plus_time}
              {" "}
              hours
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

TeamAllocation.propTypes = {
  booking: shape({
    teams_time_allocations: oneOfType(objectOf(node), arrayOf(node)),
  }).isRequired,
};

export default TeamAllocation;
