import add from "assets/images/addBlack.svg";
import addWhite from "assets/images/addWhite.svg";
import bin from "assets/images/delete.svg";
import close from "assets/images/close.svg";
import checkmark from "assets/images/checkmark.svg";
import edit from "assets/images/edit.svg";
import editGrey from "assets/images/edit-light.svg";
import editWhite from "assets/images/editWhite.svg";
import leftArrow from "assets/images/leftArrowIcon.svg";
import rightArrow from "assets/images/rightArrowIcon.svg";
import position from "assets/images/position.svg";
import departure from "assets/images/departure.svg";
import arrive from "assets/images/arrive.svg";
import clock from "assets/images/clock.svg";
import search from "assets/images/searchBase.svg";
import driverAssigned from "assets/images/driverAssigned.svg";
import driverAssignedGreen from "assets/images/driverAssignedGreen.svg";

export default {
  add,
  addWhite,
  bin,
  close,
  checkmark,
  edit,
  editGrey,
  editWhite,
  leftArrow,
  rightArrow,
  position,
  departure,
  arrive,
  clock,
  search,
  driverAssigned,
  driverAssignedGreen,
};
