import { connect } from "react-redux";
import { settingsDealershipMobileTechnicianEnabledSelector } from "store/selectors/settings-selectors";
import {
  bookingAvailableRecallsSelector,
  bookingDetailsCurrentStepSelector,
  bookingDetailsDealershipMenuItemsSelector,
  bookingDetailsDecisionTreeResultsSelector,
  bookingDetailsIsPickUpSelector,
  bookingDetailsMenuItemsLoadingErrorSelector,
  bookingDetailsMenuItemsLoadingSelector,
  bookingDetailsSelectedServicesSelector,
  bookingDetailsisMobileTechnicianSelector,
  bookingSelectedRecallsSelector,
} from "store/selectors/booking-details-selectors";
import {
  fetchMenuItems,
  fetchRecalls,
  setCurrentStep,
  setServiceAdvisor,
  setServiceSelection,
} from "store/actions/booking-details-actions";

import ChooseServices from "./index";

const mapStateToProps = (state) => {
  return {
    dealershipMenuItems: bookingDetailsDealershipMenuItemsSelector(state),
    selectedServices: bookingDetailsSelectedServicesSelector(state),
    menuItemsLoading: bookingDetailsMenuItemsLoadingSelector(state),
    menuItemsLoadingError: bookingDetailsMenuItemsLoadingErrorSelector(state),
    currentStep: bookingDetailsCurrentStepSelector(state),
    mobileTechnicianEnabled: settingsDealershipMobileTechnicianEnabledSelector(state),
    isPickUp: bookingDetailsIsPickUpSelector(state),
    isMobileTechnician: bookingDetailsisMobileTechnicianSelector(state),
    availableRecalls: bookingAvailableRecallsSelector(state),
    selectedRecalls: bookingSelectedRecallsSelector(state),
    decisionTreeDetails: bookingDetailsDecisionTreeResultsSelector(state),
  };
};

const actions = {
  makeServiceSelection: setServiceSelection,
  setSchedulingStep: setCurrentStep,
  retrieveDealershipMenuItems: fetchMenuItems,
  makeAdvisorSelection: setServiceAdvisor,
  retrieveDealershipRecalls: fetchRecalls,
};

export default connect(mapStateToProps, actions)(ChooseServices);
