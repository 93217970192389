import React, { useState } from "react";
import {
  arrayOf, bool, number, shape, string,
} from "prop-types";
import cx from "classnames";
import uniqid from "uniqid";
import upArrow from "assets/icons/arrow/up.svg";
import downArrow from "assets/icons/arrow/down.svg";
import styles from "./styles.module.scss";

const PackageItem = ({
  items, extras, premiumExtras, isSelected,
}) => {
  const [isShown, setIsShown] = useState(false);

  const showPackages = () => setIsShown((prevIsShown) => !prevIsShown);

  return (
    <div
      className={cx(styles.packageItemsContainer, {
        [styles.isSelected]: isSelected,
      })}
    >
      <div className={styles.header} onClick={showPackages}>
        <span> See operations included</span>
        <img src={isShown ? upArrow : downArrow} alt="" />
      </div>
      <div
        className={cx(styles.packageItems, {
          [styles.shown]: isShown,
        })}
      >
        <div className={styles.body}>
          {premiumExtras.length > 0 && (
            <span className={styles.title}>
              Premium services included:
            </span>
          )}
          {premiumExtras.map((item) => (
            <span
              key={uniqid()}
              className={styles.listItem}
            >
              {item}
            </span>
          ))}
          <span className={styles.title}>
            Services included:
          </span>
          {[...items, ...extras].map((item) => (
            <span
              key={uniqid()}
              className={styles.listItem}
            >
              {item.name || item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

PackageItem.propTypes = {
  items: arrayOf(shape({
    id: number,
    name: string,
    fee: number,
  })).isRequired,
  extras: arrayOf(string),
  premiumExtras: arrayOf(string),
  isSelected: bool,
};

PackageItem.defaultProps = {
  extras: [],
  premiumExtras: [],
  isSelected: false,
};

export default PackageItem;
