import { connect } from "react-redux";
import { settingsDealershipMobileTechnicianEnabledSelector } from "store/selectors/settings-selectors";
import {
  schedulingAvailableRecallsSelector,
  schedulingRecallsLoadingErrorSelector,
  schedulingRecallsLoadingSelector,
  schedulingSelectedRecallsSelector,
} from "store/selectors/scheduling-process-selectors";
import { setRecallsSelection } from "store/actions/scheduling-process-actions";

import RecallsToSelect from "./index";

const mapStateToProps = (state) => {
  return {
    availableRecalls: schedulingAvailableRecallsSelector(state),
    selectedRecalls: schedulingSelectedRecallsSelector(state),
    recallsLoading: schedulingRecallsLoadingSelector(state),
    recallsLoadingError: schedulingRecallsLoadingErrorSelector(state),
    mobileTechnicianEnabled: settingsDealershipMobileTechnicianEnabledSelector(state),
  };
};

const actions = {
  setSelectedRecalls: setRecallsSelection,
};

export default connect(mapStateToProps, actions)(RecallsToSelect);
