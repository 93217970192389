import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import {
  bool, func, number, oneOfType, string,
} from "prop-types";

import {
  endOfMonth, endOfWeek, format, startOfMonth, startOfWeek,
} from "date-fns";

import { retrieveReportsCustomersActivity, retrieveReportsEfficiency, retrieveReportsGeneral } from "store/actions/reporting-actions";
import { showReportsSelector } from "store/selectors/app-selectors";
import { dealershipReportsSelector } from "store/selectors/reporting-selectors";
import { settingsTimezoneSelector } from "store/selectors/settings-selectors";

import {
  BOOKINGS_DATEPICKER_DATE_FORMAT,
  BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP,
  parseBookingsDatePickerDate,
} from "shared/utils/datetime";

import Tabs from "components/common/Tabs";
import DatePicker from "components/common/DatePicker";

import "./styles.scss";

const tabsConfig = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Other period",
    value: "other",
  },
];

const Reports = ({
  showReports,
  reports,
  getReportsEfficiency,
  getReportsGeneral,
  getReportsCustomersActivity,
  timezone,
}) => {
  const [selectedItem, setSelectedItem] = useState("today");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(endOfMonth(new Date()));

  useEffect(() => {
    let startDate;
    let endDate;

    if (selectedItem === "today") {
      startDate = format(new Date(), "YYYY-MM-DD");
      endDate = format(new Date(), "YYYY-MM-DD");
    } else if (selectedItem === "week") {
      startDate = format(startOfWeek(new Date()), "YYYY-MM-DD");
      endDate = format(endOfWeek(new Date()), "YYYY-MM-DD");
    } else if (selectedItem === "month") {
      startDate = format(startOfMonth(new Date()), "YYYY-MM-DD");
      endDate = format(endOfMonth(new Date()), "YYYY-MM-DD");
    } else if (selectedItem === "other") {
      startDate = format(from, "YYYY-MM-DD");
      endDate = format(to, "YYYY-MM-DD");
    }

    getReportsEfficiency(startDate, endDate);
    getReportsGeneral(startDate, endDate);
    getReportsCustomersActivity(startDate, endDate);
  }, [selectedItem]);

  const calculatePercantage = (divisible, divider) => {
    if (!divider) {
      return "0%";
    }

    if (Number(divider) > 0) {
      return `${Math.round((divisible / divider) * 100)}%`;
    }

    return "0%";
  };

  const prepareValue = (value) => {
    if (!value) {
      return 0;
    }

    if (Number.isInteger(Number(value))) {
      return Number(value);
    }

    return Number(Number(value).toFixed(2));
  };

  const reportItems = () => {
    const {
      max_available_time_to_allocate,
      total_allocated_time,
      appointments_average_value_by_customers,
      appointments_average_value_by_dealer,
      appointments_average_value_imported,
      appointments_total_imported,
      appointments_total_made_by_customers,
      appointments_total_made_by_dealer,
      appointments_total,
      appointments_total_appraisals,
      checkins_total_made_by_customers,
      total_expected_upsell,
    } = reports;

    return {
      "Shop appointment efficiency": [
        {
          label: "Hours available",
          value: prepareValue(max_available_time_to_allocate),
        },
        {
          label: "Hours invoiced",
          value: prepareValue(total_allocated_time),
        },
        {
          label: "Expected upsell",
          value: prepareValue(total_expected_upsell),
        },
        {
          label: "Efficiency",
          value: calculatePercantage(
            prepareValue(total_allocated_time) + prepareValue(total_expected_upsell),
            max_available_time_to_allocate,
          ),
        },
      ],
      Appointments: [
        {
          label: "Total",
          value: prepareValue(appointments_total),
        },
        {
          label: "Made by customer",
          value: calculatePercantage(
            appointments_total_made_by_customers,
            appointments_total,
          ),
          additionalValue: prepareValue(appointments_total_made_by_customers),
        },
      ],
      "Self check-in": [
        {
          label: "Made by customer",
          value: calculatePercantage(
            checkins_total_made_by_customers,
            appointments_total,
          ),
          additionalValue: prepareValue(checkins_total_made_by_customers),
        },
      ],
      "Average appointment value": [
        {
          label: "Imported",
          value: `$${prepareValue(appointments_average_value_imported)}`,
          additionalValue: prepareValue(appointments_total_imported),
        },
        {
          label: "By dealer",
          value: `$${prepareValue(appointments_average_value_by_dealer)}`,
          additionalValue: prepareValue(appointments_total_made_by_dealer),
        },
        {
          label: "By customer",
          value: `$${prepareValue(appointments_average_value_by_customers)}`,
          additionalValue: prepareValue(appointments_total_made_by_customers),
        },
      ],
      "Appraisal requests": [
        {
          label: "Percentage",
          value: calculatePercantage(
            appointments_total_appraisals,
            appointments_total_made_by_customers,
          ),
          additionalValue: prepareValue(appointments_total_appraisals),
        },
      ],
    };
  };

  const applyChnages = (starts_at, ends_at) => {
    setFrom(starts_at);
    setTo(ends_at);

    getReportsEfficiency(format(starts_at, "YYYY-MM-DD"), format(ends_at, "YYYY-MM-DD"));
    getReportsGeneral(format(starts_at, "YYYY-MM-DD"), format(ends_at, "YYYY-MM-DD"));
    getReportsCustomersActivity(format(starts_at, "YYYY-MM-DD"), format(ends_at, "YYYY-MM-DD"));
  };

  const previousDates = () => {
    const rawTo = new Date(to);

    return {
      from: format(from, "YYYY-MM-DD"),
      to: format(to, "YYYY-MM-DD"),
      rawTo: format(rawTo.toISOString(), "YYYY-MM-DD"),
    };
  };

  return (
    <div className="reportsContainer">
      {showReports && (
        <div className="reportsContainerCentering">
          <div className="reportsNavigationContainer">
            <Tabs
              config={tabsConfig}
              selectedItem={selectedItem}
              selectTabAction={setSelectedItem}
            />
            <div className="flexContainer">
              {selectedItem === "other" && (
                <DatePicker
                  rangeMode
                  format={BOOKINGS_DATEPICKER_DATE_FORMAT}
                  separator="/"
                  separatorPositions={[2, 5]}
                  formatRegExp={BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP}
                  parseDateInFormat={parseBookingsDatePickerDate}
                  startDateLabel="From"
                  endDateLabel="To"
                  onApplyChanges={applyChnages}
                  previousDates={previousDates()}
                  previousDatesNaming={{
                    startDate: "from",
                    endDate: "to",
                  }}
                  timezone={timezone}
                  hideResetButton
                  darkMode
                />
              )}
              <NavLink
                to={{ pathname: "/reports" }}
                className="additionalReportsLink"
              >
                <p>Additional reports</p>
              </NavLink>
            </div>
          </div>
          <div className="reportsData">
            {Object.entries(reportItems()).map(([title, items]) => (
              <div
                className={cx({
                  reportsDataBlockX1: items.length === 1,
                  reportsDataBlockX2: items.length === 2,
                  reportsDataBlockX3: items.length === 3,
                  reportsDataBlockX4: items.length === 4,
                })}
              >
                <div className="reportsDataBlockTitle">{title}</div>
                <div className="reportsDataBlockItemsContainer">
                  {items.map((item) => (
                    <div className="reportsDataBlockItem">
                      <div className="reportsDataBlockItemValue">
                        {item.value}
                        {(item.additionalValue || item.additionalValue === 0) && (
                          <span className="reportsDataBlockItemValueAdditionalInfo">
                            {item.additionalValue}
                          </span>
                        )}
                      </div>
                      <div className="reportsDataBlockItemLabel">{item.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Reports.propTypes = {
  showReports: bool.isRequired,
  reports: {
    max_available_time_to_allocate: oneOfType([string, number]),
    total_allocated_time: oneOfType([string, number]),
    total_actual_time: oneOfType([string, number]),
    appointments_average_value_by_customers: oneOfType([string, number]),
    appointments_average_value_by_dealer: oneOfType([string, number]),
    appointments_average_value_imported: oneOfType([string, number]),
    appointments_total_imported: oneOfType([string, number]),
    appointments_total_made_by_customers: oneOfType([string, number]),
    appointments_total_made_by_dealer: oneOfType([string, number]),
    appointments_total: oneOfType([string, number]),
    appointments_total_appraisals: oneOfType([string, number]),
    appointments_total_cancelled: oneOfType([string, number]),
    checkins_total: oneOfType([string, number]),
    checkins_total_made_by_customers: oneOfType([string, number]),
    total_expected_upsell: oneOfType([string, number]),
    total_expected_buffer_time: oneOfType([string, number]),
  },
  getReportsEfficiency: func.isRequired,
  getReportsGeneral: func.isRequired,
  getReportsCustomersActivity: func.isRequired,
  timezone: string,
};

Reports.defaultProps = {
  reports: {
    max_available_time_to_allocate: null,
    total_allocated_time: null,
    total_actual_time: null,
    appointments_average_value_by_customers: null,
    appointments_average_value_by_dealer: null,
    appointments_average_value_imported: null,
    appointments_total_imported: null,
    appointments_total_made_by_customers: null,
    appointments_total_made_by_dealer: null,
    appointments_total: null,
    appointments_total_appraisals: null,
    appointments_total_cancelled: null,
    checkins_total: null,
    checkins_total_made_by_customers: null,
    total_expected_upsell: null,
    total_expected_buffer_time: null,
  },
  timezone: null,
};

const mapStateToProps = (state) => {
  return {
    showReports: showReportsSelector(state),
    reports: dealershipReportsSelector(state),
    timezone: settingsTimezoneSelector(state),
  };
};

const actions = {
  getReportsEfficiency: retrieveReportsEfficiency,
  getReportsGeneral: retrieveReportsGeneral,
  getReportsCustomersActivity: retrieveReportsCustomersActivity,
};

export default connect(mapStateToProps, actions)(Reports);
