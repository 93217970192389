import React, { useState } from "react";
import { connect } from "react-redux";
import { bool, func } from "prop-types";
import { formatName } from "shared/utils/common";
import { integrationPropType } from "shared/prop-types";
import { updateIntegrationOptions } from "store/actions/settings-actions";
import Input from "components/common/Input";
import HeaderEdit from "components/common/HeaderEdit";
import Accordion from "components/common/Accordion";
import ActivitySwitch from "../ActivitySwitch";

import styles from "../styles.module.scss";

const Integration = ({
  integration: {
    kind,
    id,
    settings,
    active,
  },
  updateIntegrationHandler,
  isSuperAdmin,
}) => {
  const [isActive, setIsActive] = useState(active);
  const [isEditing, setIsEditing] = useState(false);
  const [integrationSettings, setIntegrationSettings] = useState(settings);
  const DEALERSHIP_ADMIN_EDITABLE_SETTINGS = ["additional_information_for_customers"];

  const save = () => {
    const params = {
      settings: integrationSettings,
      active: isActive,
    };
    updateIntegrationHandler(id, params);
    setIsEditing(false);
  };

  const cancelChanges = () => {
    setIsActive(active);
    setIntegrationSettings(settings);
    setIsEditing(false);
  };

  const KEYS_TO_SKIP = [
    "workorder_copies",
    "workorder_printer",
    "parts_printer",
  ];

  const keysToDisplay = (
    integrationSsettings,
  ) => Object.keys(integrationSsettings).filter((keyName) => (
    !KEYS_TO_SKIP.includes(keyName)));

  const isDisabled = (attribute) => {
    if (DEALERSHIP_ADMIN_EDITABLE_SETTINGS.includes(attribute)) {
      return !isEditing;
    }
    return !isSuperAdmin || !isEditing;
  };
  return (
    <Accordion
      title={formatName(kind)}
      customClass={styles.accordion}
    >
      <section className={styles.container}>
        <ActivitySwitch
          isEditing={isEditing}
          active={isActive}
          onChange={() => setIsActive(!isActive)}
        />
        <section className={styles.inputsContainer}>
          {keysToDisplay(integrationSettings).map((keyName) => (
            <Input
              label={formatName(keyName)}
              value={integrationSettings[keyName]}
              disabled={isDisabled(keyName)}
              onChange={(value) => setIntegrationSettings({
                ...integrationSettings,
                [keyName]: value,
              })}
              key={keyName}
            />
          ))}
          {kind === "fortellis" && !isSuperAdmin && (
            <>
              <Input
                label="Printer configuration"
                value={integrationSettings.workorder_printer}
                onChange={(value) => setIntegrationSettings({
                  ...integrationSettings,
                  workorder_printer: value,
                })}
                disabled={!isEditing}
              />
              <Input
                label="Printer copies"
                value={integrationSettings.workorder_copies}
                onChange={(value) => setIntegrationSettings({
                  ...integrationSettings,
                  workorder_copies: value,
                })}
                disabled={!isEditing}
              />
              <Input
                label="Parts printer"
                value={integrationSettings.parts_printer}
                onChange={(value) => setIntegrationSettings({
                  ...integrationSettings,
                  parts_printer: value,
                })}
                disabled={!isEditing}
              />
            </>
          )}
        </section>
      </section>
      <HeaderEdit
        isEditing={isEditing}
        handleCancel={cancelChanges}
        handleSave={save}
        handleEdit={() => setIsEditing(true)}
      />
    </Accordion>
  );
};

Integration.propTypes = {
  integration: integrationPropType.isRequired,
  updateIntegrationHandler: func.isRequired,
  isSuperAdmin: bool,
};

Integration.defaultProps = {
  isSuperAdmin: false,
};

const actions = {
  updateIntegrationHandler: updateIntegrationOptions,
};

export default connect(null, actions)(Integration);
