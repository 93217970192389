import React, { useRef } from "react";
import { connect } from "react-redux";
import { bool, string } from "prop-types";
import QRCode from "react-qr-code";
import {
  settingsDealershipNameSelector, settingsDealershipPublicIdSelector,
} from "store/selectors/settings-selectors";
import { showQRSelector } from "store/selectors/app-selectors";
import { useReactToPrint } from "react-to-print";

import Button from "components/common/Button";
import QRPrint from "./print";

import styles from "./styles.module.scss";

const QR = ({
  showQR,
  dealershipPublicId,
  dealershipName,
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className={styles.qrContainer}>
      {showQR && (
        <div className={styles.qrContainerCentering}>
          <QRCode
            value={`https://reserve.getcarmen.com/carmenservice?dealershipId=${dealershipPublicId}`}
            size={280}
            fgColor="#FFFFFF"
            bgColor="#0a0c16"
          />
          <div className={styles.qrContainerInfo}>
            <div>{`Download ${dealershipName} Carmen Service App`}</div>
            <div>{`Dealer Code: ${dealershipPublicId}`}</div>
            <Button variant="brand" onClick={handlePrint}>Print</Button>
          </div>
        </div>
      )}
      <QRPrint ref={componentRef} />
    </div>
  );
};

QR.propTypes = {
  showQR: bool.isRequired,
  dealershipName: string.isRequired,
  dealershipPublicId: string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    showQR: showQRSelector(state),
    dealershipName: settingsDealershipNameSelector(state),
    dealershipPublicId: settingsDealershipPublicIdSelector(state),
  };
};

export default connect(mapStateToProps, null)(QR);
