import React from "react";
import {
  arrayOf, bool, func,
} from "prop-types";

import { servicePropType } from "shared/prop-types";

import Panel from "components/common/Panel";
import RecallService from "./RecallService";

import styles from "./styles.module.scss";

const RecallsToSelect = ({
  availableRecalls,
  selectedRecalls,
  mobileTechnicianEnabled,
  recallsLoading,
  recallsLoadingError,
  setSelectedRecalls,
}) => {
  const isRecallSelected = (service) => selectedRecalls.filter(
    (item) => (item.recall_id || item.id) === service.id,
  ).length > 0;

  const toggleRecall = (recall) => () => {
    setSelectedRecalls(
      isRecallSelected(recall)
        ? selectedRecalls.filter((r) => (r.recall_id || r.id) !== recall.id)
        : selectedRecalls.concat(recall),
    );
  };

  if (recallsLoading) {
    return <Panel className={styles.loadingPanel}>Loading...</Panel>;
  }

  return (
    <section className={styles.chooseServicesContainer}>
      {recallsLoadingError ? (
        <Panel className={styles.loadingPanel}>Please try again.</Panel>
      ) : (
        <>
          {availableRecalls.map((recall) => (
            <RecallService
              key={recall.name}
              service={recall}
              onClick={toggleRecall(recall)}
              isHighlighted={isRecallSelected(recall)}
              mobileTechnicianEnabled={mobileTechnicianEnabled}
            />
          ))}
        </>
      )}
    </section>
  );
};

RecallsToSelect.propTypes = {
  availableRecalls: arrayOf(servicePropType),
  selectedRecalls: arrayOf(servicePropType),
  mobileTechnicianEnabled: bool.isRequired,
  recallsLoading: bool.isRequired,
  recallsLoadingError: bool.isRequired,
  setSelectedRecalls: func.isRequired,
};

RecallsToSelect.defaultProps = {
  availableRecalls: [],
  selectedRecalls: [],
};

export default RecallsToSelect;
