import { connect } from "react-redux";

import {
  retrieveDealershipMobileTechnicians,
  setCurrentStep,
  setRemoteJobData,
} from "store/actions/booking-details-actions";
import {
  bookingDetailsAvailableJobEmployee,
  bookingDetailsInitialTimeSelector,
  bookingMobileTechniciansSelector,
  bookingServiceLocationSelector,
} from "store/selectors/booking-details-selectors";

import ChooseMobileTechnician from "./index";

const mapStateToProps = (state) => {
  return {
    mobileTechnicians: bookingMobileTechniciansSelector(state),
    serviceLocation: bookingServiceLocationSelector(state),
    initialTime: bookingDetailsInitialTimeSelector(state),
    availableJobEmployee: bookingDetailsAvailableJobEmployee(state),
  };
};

const actions = {
  retrieveMobileTechnicians: retrieveDealershipMobileTechnicians,
  storeRemoteJobData: setRemoteJobData,
  setSchedulingStep: setCurrentStep,
};

export default connect(mapStateToProps, actions)(ChooseMobileTechnician);
