import React from "react";
import {
  arrayOf, bool, func, string,
} from "prop-types";
import { mobileTechnicianPropType } from "shared/prop-types";

import EmployeeSelectionCarousel from "components/common/EmployeeSelectionCarousel";
import Input from "components/common/Input";

import "./styles.scss";

const MobileTechnicianSelection = ({
  availableMobileTechnicians,
  selectedMobileTechnician,
  setSelectedMobileTechnician,
  notes,
  setNotes,
  isFullForm,
}) => (
  <div className="mobileTechniciansSelectionContainer">
    {availableMobileTechnicians.length > 0 && (
      <>
        {isFullForm && (
          <div
            className="mobileTechniciansSelectionLabel"
          >
            Technician
          </div>
        )}
        <EmployeeSelectionCarousel
          employees={availableMobileTechnicians}
          selectedEmployee={selectedMobileTechnician || {}}
          onClickHandler={(item) => {
            if (item.id === selectedMobileTechnician?.id) {
              setSelectedMobileTechnician({});
            } else {
              setSelectedMobileTechnician(item);
            }
          }}
        />
      </>
    )}
    {availableMobileTechnicians.length > 0 && isFullForm && (
      <>
        <div className="mobileTechniciansSelectionLabel">Notes</div>
        <Input
          placeholder="Additional information"
          type="text"
          inputClassName=""
          value={notes}
          onClick={(e) => e.stopPropagation(e)}
          onChange={setNotes}
        />
      </>
    )}
  </div>
);

MobileTechnicianSelection.propTypes = {
  availableMobileTechnicians: arrayOf(mobileTechnicianPropType),
  selectedMobileTechnician: mobileTechnicianPropType,
  setSelectedMobileTechnician: func.isRequired,
  notes: string,
  setNotes: func,
  isFullForm: bool,
};

MobileTechnicianSelection.defaultProps = {
  availableMobileTechnicians: [],
  selectedMobileTechnician: null,
  notes: "",
  setNotes: () => {},
  isFullForm: true,
};

export default MobileTechnicianSelection;
