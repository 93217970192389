import React, { useState } from "react";
import { func, node } from "prop-types";
import { connect } from "react-redux";
import { history as historyType } from "react-router-prop-types";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { logOut } from "store/actions/auth-actions";
import { clearPeriodicallyFetchInterval } from "store/actions/bookings-actions";

import exitIcon from "assets/icons/exit.svg";
import qaIcon from "assets/icons/help.svg";

import "./styles.scss";

const Navigation = ({
  clearInterval,
  logout,
  history,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onLogout = () => {
    clearInterval();
    logout();
    sessionStorage.clear("CONCIERGE_TOKEN");
    sessionStorage.clear("CONCIERGE_ROLE");
    sessionStorage.clear("CONCIERGE_DEALERSHIP");
    history.push("/login");
  };

  return (
    <nav className="conciergeNavigation">
      <ul className="conciergeNavigationList">
        {children}
      </ul>
      <section className="conciergeNavigationLogoutBlock">
        <a
          href="https://www.getcarmen.com/carmen-academy"
          className="conciergeNavLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            data-for="logout"
            data-tip="Ask Carmen"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            src={qaIcon}
            alt="concierge qa"
            className="conciergeQAButtonImg"
          />
        </a>
      </section>
      <section className="conciergeNavigationLogoutBlock">
        <div
          className="conciergeNavLink"
          onClick={onLogout}
        >
          <img
            data-for="logout"
            data-tip="Logout"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            src={exitIcon}
            alt="concierge exit"
            className="conciergeLogoutButtonImg"
          />
        </div>
      </section>
      {showTooltip && (
        <ReactTooltip
          id="logout"
          className="conciergeNavLinkTooltip"
          delayShow={100}
          place="bottom"
          backgroundColor="#F9FAFC"
          textColor="black"
          border
          borderColor="#dedee0"
        />
      )}
    </nav>
  );
};

Navigation.propTypes = {
  children: node.isRequired,
  logout: func.isRequired,
  clearInterval: func.isRequired,
  history: historyType.isRequired,
};

const actions = {
  logout: logOut,
  clearInterval: clearPeriodicallyFetchInterval,
};

export default withRouter(connect(null, actions)(Navigation));
