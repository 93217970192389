import { connect } from "react-redux";

import {
  settingsEmployeeVacations,
  settingsTimezoneSelector,
  settingsVacationError,
} from "store/selectors/settings-selectors";

import {
  addVacations,
  deleteVacations,
  getVacations,
  updateVacations,
} from "store/actions/settings-actions";

import VacationsModal from "./index";

const mapStateToProps = (state) => {
  return {
    timezone: settingsTimezoneSelector(state),
    vacations: settingsEmployeeVacations(state),
    vacationError: settingsVacationError(state),
  };
};

const actions = {
  getVacationsAction: getVacations,
  addVacationsAction: addVacations,
  updateVacationsAction: updateVacations,
  deleteVacationsAction: deleteVacations,
};

export default connect(mapStateToProps, actions)(VacationsModal);
