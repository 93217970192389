import { connect } from "react-redux";

import {
  retrieveDealershipDrivers,
  setCurrentStep,
  setRemoteJobData,
} from "store/actions/scheduling-process-actions";
import {
  schedulingAvailableDropOffJobEmployee,
  schedulingAvailableJobEmployee,
  schedulingProcessDriversSelector,
  schedulingProcessServiceLocationSelector,
} from "store/selectors/scheduling-process-selectors";
import { settingsDealershipShowCoDriver } from "store/selectors/settings-selectors";

import ChooseDrivers from "./index";

const mapStateToProps = (state) => {
  return {
    drivers: schedulingProcessDriversSelector(state),
    serviceLocation: schedulingProcessServiceLocationSelector(state),
    availableJobEmployee: schedulingAvailableJobEmployee(state),
    availableDropOffJobEmployee: schedulingAvailableDropOffJobEmployee(state),
    showCoDriver: settingsDealershipShowCoDriver(),
  };
};

const actions = {
  retrieveDrivers: retrieveDealershipDrivers,
  storeRemoteJobData: setRemoteJobData,
  setSchedulingStep: setCurrentStep,
};

export default connect(mapStateToProps, actions)(ChooseDrivers);
