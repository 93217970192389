import { connect } from "react-redux";

import {
  settingsDriverVacations,
  settingsTimezoneSelector,
  settingsVacationError,
} from "store/selectors/settings-selectors";

import {
  addDriverVacations,
  deleteDriverVacations,
  getDriverVacations,
  updateDriverVacations,
} from "store/actions/settings-actions";

import VacationsModal from "./index";

const mapStateToProps = (state) => {
  return {
    timezone: settingsTimezoneSelector(state),
    vacations: settingsDriverVacations(state),
    vacationError: settingsVacationError(state),
  };
};

const actions = {
  getVacationsAction: getDriverVacations,
  addVacationsAction: addDriverVacations,
  updateVacationsAction: updateDriverVacations,
  deleteVacationsAction: deleteDriverVacations,
};

export default connect(mapStateToProps, actions)(VacationsModal);
