import React from "react";
import {
  bool, func, number, string,
} from "prop-types";
import cx from "classnames";

import styles from "./styles.module.scss";

const ServiceTypeToggler = ({
  label,
  onClick,
  counter,
  icon,
  isActive,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(
      styles.serviceTogglerButton,
      {
        [styles.serviceTogglerButtonBlueBg]: isActive,
      },
    )}
  >
    {icon && (
      <img
        className={styles.serviceTogglerImage}
        src={icon}
        alt="Service type"
      />
    )}
    <span className={styles.serviceTogglerLabel}>
      {label}
    </span>
    {counter !== 0 && (
      <span className={styles.serviceTogglerCounter}>
        {counter}
      </span>
    )}
  </button>
);

ServiceTypeToggler.propTypes = {
  onClick: func.isRequired,
  label: string.isRequired,
  counter: number,
  icon: string.isRequired,
  isActive: bool.isRequired,
};

ServiceTypeToggler.defaultProps = {
  counter: null,
};

export default ServiceTypeToggler;
