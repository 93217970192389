import React, { useEffect } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import {
  func, number, objectOf, shape, string,
} from "prop-types";
import { connect } from "react-redux";

import LoginPage from "components/pages/LoginPage";
import ResetPasswordPage from "components/pages/ResetPasswordPage";
import BookingsPage from "components/pages/BookingsPage";
import BookingDetailsPage from "components/pages/BookingDetailsPage";
import MenuTemplatesPage from "components/pages/MenuTemplatesPage";
import MenuTemplatePage from "components/pages/MenuTemplatePage";
import SchedulingPage from "components/pages/SchedulingPage";
import SettingsPage from "components/pages/SettingsPage";
import RemoteServicePage from "components/pages/RemoteServicePage";
import DealershipsPage from "components/pages/DealershipsPage";
import DealershipDetailsPage from "components/pages/DealershipDetailsPage";
import SchedulingProcessPage from "components/pages/SchedulingProcessPage";
import SchedulingProcessSummary from "components/pages/SchedulingProcessSummary";
import ReportingPage from "components/pages/ReportingPage";
import { dealershipIdSelector, dealershipMakeModelYearMapSelector } from "store/selectors/app-selectors";
import { authRoleSelector, authTokenSelector } from "store/selectors/auth-selectors";
import { ADMIN_ROLE, ADVISOR_ROLE, BDC_ROLE } from "shared/constants";
import { retrieveMakeModelYearMap } from "store/actions/app-actions";
import {
  retrieveDealershipActiveServiceAdvisors,
  retrieveDealershipInfo,
  retrieveTeamTags,
  retrieveTextMessages,
} from "store/actions/settings-actions";
import { isNil } from "ramda";

const routes = [
  {
    path: "/",
    component: null,
    redirectPath: "/bookings",
    adminRedirectPath: "/dealerships",
  },
  {
    path: "/login",
    component: <LoginPage />,
    redirectPath: "/bookings",
    adminRedirectPath: "/dealerships",
  },
  {
    path: "/reset",
    component: <ResetPasswordPage />,
    redirectPath: "/bookings",
    adminRedirectPath: "/dealerships",
  },
  {
    path: "/scheduling",
    component: (() => (
      <>
        <Route exact path="/scheduling" component={SchedulingPage} />
        <Route path="/scheduling/process" component={SchedulingProcessPage} />
        <Route path="/scheduling/summary" component={SchedulingProcessSummary} />
      </>
    ))(),
    redirectPath: "/login",
  },
  {
    path: "/bookings",
    component: (() => (
      <>
        <Route exact path="/bookings" component={BookingsPage} />
        <Route path="/bookings/:guid/:kind" component={BookingDetailsPage} />
      </>
    ))(),
    redirectPath: "/login",
  },
  {
    path: "/service",
    component: <RemoteServicePage />,
    redirectAdvisorPath: "/bookings",
    redirectPath: "/login",
  },
  {
    path: "/templates",
    component: (() => (
      <>
        <Route exact path="/templates" component={MenuTemplatesPage} />
        <Route path="/templates/:id" component={MenuTemplatePage} />
      </>
    ))(),
    redirectAdvisorPath: "/bookings",
    redirectPath: "/login",
  },
  {
    path: "/settings",
    component: <SettingsPage />,
    redirectAdvisorPath: "/bookings",
    redirectPath: "/login",
  },
  {
    path: "/dealerships",
    component: (() => (
      <>
        <Route exact path="/dealerships" component={DealershipsPage} />
        <Route path="/dealerships/:id" component={DealershipDetailsPage} />
      </>
    ))(),
    redirectPath: "/bookings",
  },
  {
    path: "/reports",
    component: <ReportingPage />,
    redirectPath: "/login",
  },
];

const Routing = ({
  token,
  role,
  makeModelYearMap,
  dealershipId,
  fetchMakeModelYearMap,
  fetchDealershipInfo,
  fetchTextMessages,
  fetchActiveServiceAdvisors,
  fetchTeamTags,
}) => {
  const isLoggedIn = Boolean(token);
  const isAdvisor = role === ADVISOR_ROLE;
  const isBDC = role === BDC_ROLE;
  const isAdmin = role === ADMIN_ROLE;

  useEffect(() => {
    if (
      Boolean(token)
      && !isAdmin
      && dealershipId
      && isNil(makeModelYearMap)
    ) {
      fetchMakeModelYearMap();
      fetchDealershipInfo();
      fetchTextMessages({ kind: "no_show" });
      fetchActiveServiceAdvisors();
      fetchTeamTags();
    }
  }, [token, role, dealershipId, makeModelYearMap]);

  const routesToRender = routes.map((route) => {
    switch (route.path) {
      case "/":
        return (
          <Route
            exact
            path={route.path}
            render={() => (
              <Redirect
                to={isAdmin ? route.adminRedirectPath : { pathname: route.redirectPath }}
              />
            )}
            key={route.path}
          />
        );
      case "/reset":
        return (
          <Route
            exact
            path={route.path}
            render={() => route.component}
            key={route.path}
          />
        );
      case "/login": {
        return (
          <Route
            path={route.path}
            render={() => {
              if (isLoggedIn) {
                return (
                  <Redirect
                    to={isAdmin ? route.adminRedirectPath : { pathname: route.redirectPath }}
                  />
                );
              }
              return route.component;
            }}
            key={route.path}
          />
        );
      }
      case "/dealerships":
        return (
          <Route
            path={route.path}
            render={() => {
              if (isLoggedIn) {
                return isAdmin ? route.component : <Redirect to={route.redirectPath} />;
              }
              return <Redirect to="/login" />;
            }}
            key={route.path}
          />
        );
      default:
        return (
          <Route
            path={route.path}
            render={() => {
              if (isLoggedIn) {
                if (isAdmin) {
                  return <Redirect to="/dealerships" />;
                }
                if ((isBDC || isAdvisor) && ["/templates", "/settings"].includes(route.path)) {
                  return (
                    <Redirect to={{
                      pathname: route.redirectAdvisorPath,
                    }}
                    />
                  );
                }
                return route.component;
              }
              return (
                <Redirect
                  to={{
                    pathname: route.redirectPath,
                  }}
                />
              );
            }}
            key={route.path}
          />
        );
    }
  });
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{routesToRender}</>
  );
};

Routing.propTypes = {
  token: string,
  role: string,
  dealershipId: number.isRequired,
  fetchMakeModelYearMap: func.isRequired,
  fetchDealershipInfo: func.isRequired,
  fetchTextMessages: func.isRequired,
  fetchActiveServiceAdvisors: func.isRequired,
  fetchTeamTags: func.isRequired,
  makeModelYearMap: objectOf(shape),
};
Routing.defaultProps = {
  token: null,
  role: null,
  makeModelYearMap: null,
};

const mapStateToProps = (state) => {
  return {
    token: authTokenSelector(state),
    role: authRoleSelector(state),
    dealershipId: dealershipIdSelector(state),
    makeModelYearMap: dealershipMakeModelYearMapSelector(state),
  };
};

const actions = {
  fetchMakeModelYearMap: retrieveMakeModelYearMap,
  fetchDealershipInfo: retrieveDealershipInfo,
  fetchActiveServiceAdvisors: retrieveDealershipActiveServiceAdvisors,
  fetchTeamTags: retrieveTeamTags,
  fetchTextMessages: retrieveTextMessages,
};

export default withRouter(connect(mapStateToProps, actions)(Routing));
