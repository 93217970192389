import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { bool } from "prop-types";

import { ErrorBoundary, Provider } from "@rollbar/react";
import Header from "components/App/Header";
import Reports from "components/App/Reports";
import QR from "components/App/QR";
import Routes from "components/routing";
import ActionCableAdmin from "components/common/ActionCable/Admin";
import ActionCableCheckin from "components/common/ActionCable/Checkin";
import { isDevelopmentEnv } from "shared/utils";

import { showQRSelector, showReportsSelector } from "store/selectors/app-selectors";

import "./styles.scss";

const rollbarConfig = {
  accessToken: `${process.env.REACT_APP_ROLLBAR_TOKEN}`,
  environment: `${process.env.REACT_APP_ENV}`,
};

if (isDevelopmentEnv()) {
  rollbarConfig.enabled = false;
}

const App = ({ showReports, showQR }) => {
  useEffect(() => {
    const existingGoogleMapsScript = document.querySelector("#googleMapsScript");

    if (!existingGoogleMapsScript) {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
      gmapScriptEl.id = "googleMapsScript";
      document.querySelector("body").insertAdjacentElement("beforeend", gmapScriptEl);
    }
  }, []);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <div
          className={cx("conciergeApp", {
            withReports: showReports,
          })}
        >
          <Header />
          {showReports && <Reports />}
          {showQR && <QR />}
          <Routes />
          <ActionCableAdmin channel="AdminChannel" />
          <ActionCableCheckin channel="CheckinChannel" />
        </div>
      </ErrorBoundary>
    </Provider>
  );
};

App.propTypes = {
  showReports: bool.isRequired,
  showQR: bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    showReports: showReportsSelector(state),
    showQR: showQRSelector(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
