import {
  AUTH_AUTHENTICATION_BY_RESET_TOKEN_FAILURE,
  AUTH_AUTHENTICATION_FAILURE,
  AUTH_AUTHENTICATION_SUCCESS,
  AUTH_LOG_OUT,
  AUTH_RESET_LOGIN_STATE,
  AUTH_RESET_USER_PASSWORD_FAILURE,
  AUTH_RESET_USER_PASSWORD_SUCCESS,
  AUTH_SEND_RESET_LINK_FAILURE,
  AUTH_SEND_RESET_LINK_SUCCESS,
  AUTH_SET_LOADING_STATE,
} from "store/actions/auth-actions";

const initialState = {
  token: sessionStorage.getItem("CONCIERGE_TOKEN"),
  role: sessionStorage.getItem("CONCIERGE_ROLE"),
  channelKey: sessionStorage.getItem("CONCIERGE_CHANNEL_KEY"),
  userAdvisorId: sessionStorage.getItem("CONCIERGE_USER_ADVISOR_ID"),
  error: null,
  isLoading: false,
  isResetLinkSent: false,
  isPasswordReset: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_LOADING_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case AUTH_AUTHENTICATION_SUCCESS: {
      const {
        role, access_token, channel_key, dealershipId, employee_profiles,
      } = action.payload;
      const user = employee_profiles.find(({ dealership_id }) => dealership_id === dealershipId);
      const userAdvisorId = user?.service_advisor_profile?.id;
      return {
        ...state,
        token: access_token,
        role,
        channelKey: channel_key,
        user,
        userAdvisorId,
        isLoading: false,
        error: null,
      };
    }
    case AUTH_RESET_LOGIN_STATE:
      return {
        ...state,
        isResetLinkSent: false,
        isLoading: false,
        error: null,
      };
    case AUTH_SEND_RESET_LINK_SUCCESS:
      return {
        ...state,
        isResetLinkSent: true,
        isLoading: false,
        error: null,
      };
    case AUTH_RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordReset: true,
        isLoading: false,
        error: null,
      };
    case AUTH_AUTHENTICATION_BY_RESET_TOKEN_FAILURE:
    case AUTH_AUTHENTICATION_FAILURE:
    case AUTH_SEND_RESET_LINK_FAILURE:
    case AUTH_RESET_USER_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isLoading: false,
      };
    case AUTH_LOG_OUT:
      return {
        ...state,
        token: null,
        role: null,
        channelKey: null,
        user: null,
        userAdvisorId: null,
        error: null,
        isLoading: false,
        isResetLinkSent: false,
        isPasswordReset: false,
      };
    default:
      return state;
  }
};

export default auth;
