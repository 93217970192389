import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, node, number, objectOf, string,
} from "prop-types";
import { history as historyType } from "react-router-prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import ReactTooltip from "react-tooltip";

import { datesSortingMethod, getAppointmentFormattedDate } from "shared/utils/datetime";
import {
  dealershipsDataSelector,
  dealershipsErrorSelector,
  dealershipsLoadingStateSelector,
} from "store/selectors/dealerships-selectors";
import { authTokenSelector } from "store/selectors/auth-selectors";
import {
  totalRecordsSelector,
} from "store/selectors/table-options-selectors";
import { retrieveDealerships, setAddingInitialState } from "store/actions/dealerships-actions";
import activeIcon from "assets/images/bookings/status/arrived.svg";
import PageHeader from "components/common/PageHeader";
import Pagination from "components/common/ReactTableElements/Pagination";
import Panel from "components/common/Panel";

import {
  ASC,
  DEFAULT_SORT_COLUMN_DEALERSHIP,
  DEFAULT_SORT_DIRECTION,
  DESC,
} from "shared/constants";

import Button from "components/common/Button";
import AddDealershipModal from "./AddDealershipModal";

import styles from "./styles.module.scss";

const columns = [
  {
    Header: "Id",
    accessor: "id",
    width: 100,
  },
  {
    Header: "Public Id",
    accessor: "public_id",
    width: 150,
  },
  {
    Header: "Name",
    accessor: "name",
    width: 300,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    sortMethod: datesSortingMethod,
    width: 150,
  },
  {
    Header: "Mobile tech",
    accessor: "mobile_tech",
    width: 140,
  },
  {
    Header: "Pick-up",
    accessor: "pickup",
    width: 120,
  },
  {
    Header: "DMS Active",
    accessor: "dms_active",
    width: 130,
  },
  {
    Header: "Phantom",
    accessor: "phantom_active",
    width: 130,
  },
];

const DealershipsPage = ({
  dealerships,
  isLoading,
  token,
  error,
  total,
  fetchDealerships,
  history,
  clearState,
}) => {
  const [page, setCurrentPage] = useState(1);
  const [pageSize, onPageSizeChange] = useState(100);
  const [sort, setSort] = useState(`${DEFAULT_SORT_COLUMN_DEALERSHIP} ${DEFAULT_SORT_DIRECTION}`);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  useEffect(() => {
    fetchDealerships(
      token,
      {
        page,
        per_page: pageSize,
        sort,
      },
    );
  }, [page, pageSize, sort]);

  const closeModal = () => {
    setIsModalOpen(false);
    clearState();
  };

  const openModal = () => setIsModalOpen(true);

  const onSortedChange = (newSorted) => {
    const column = newSorted[0].id;
    const direction = newSorted[0].desc ? DESC : ASC;
    setSort(`${column} ${direction}`);
  };

  const showActivity = (isActive) => (
    isActive ? (
      <img
        src={activeIcon}
        alt="Active icon"
      />
    )
      : null
  );

  const showPhantomActivity = (phantomEmployments) => {
    const dataTip = (
      phantomEmployments.length > 0
        ? phantomEmployments.join("\n")
        : "Phantom advisors not selected"
    );

    return (
      <div
        data-for="phantom_advisors"
        data-tip={dataTip}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <img
          src={activeIcon}
          alt="Active icon"
        />
      </div>
    );
  };

  const resolvedData = (data) => data.map(
    (dealership) => {
      const {
        name,
        created_at,
        id,
        public_id,
        integrations,
        phantom_advisor_enabled,
        employments,
      } = dealership;
      const phantomEmployments = employments.filter((e) => (
        e.service_advisor_profile?.is_phantom)).map((e) => e.service_advisor_profile?.dms_name);
      const formattedDate = getAppointmentFormattedDate(created_at);
      const isDMSActive = integrations.some((i) => (
        (i.kind === "cdk" || i.kind === "dealer_track") && i.active === true
      ));
      const isMobileActive = integrations.some((i) => (
        (i.kind === "mobile_technician") && i.active === true
      ));
      const isPickupActive = integrations.some((i) => (
        (i.kind === "pickup") && i.active === true
      ));
      return {
        name,
        created_at: formattedDate,
        id,
        public_id,
        dms_active: showActivity(isDMSActive),
        mobile_tech: showActivity(isMobileActive),
        pickup: showActivity(isPickupActive),
        phantom_active: (
          phantom_advisor_enabled ? showPhantomActivity(phantomEmployments) : null
        ),
      };
    },
  );

  return (
    <section>
      <PageHeader title={<h2>Dealerships</h2>} />
      <section className={styles.mainPage}>
        {error && (
          <Panel className={styles.loadingPanel}>{error}</Panel>
        )}
        {!error && (
          <div>
            <section className={styles.navbar}>
              <Button
                variant="brand"
                onClick={openModal}
              >
                Add new dealership
              </Button>
            </section>
            <div className={styles.table}>
              <ReactTable
                data={dealerships}
                total={total}
                minRows={1}
                resolveData={resolvedData}
                loading={isLoading}
                columns={columns}
                page={page}
                defaultPageSize={pageSize}
                PaginationComponent={Pagination}
                onPageChange={setCurrentPage}
                onPageSizeChange={onPageSizeChange}
                onSortedChange={onSortedChange}
                manual
                defaultSorted={[
                  {
                    id: DEFAULT_SORT_COLUMN_DEALERSHIP,
                    desc: DEFAULT_SORT_DIRECTION === DESC,
                  },
                ]}
                getTrProps={(state, rowInfo) => {
                  return {
                    onClick: () => {
                      const dealershipId = state.data[rowInfo.index].id;
                      history.push(`/dealerships/${dealershipId}`);
                    },
                    className: "conciergeTableRowLink",
                  };
                }}
              />
            </div>
          </div>
        )}
      </section>
      {isModalOpen && (
        <AddDealershipModal onClose={closeModal} />
      )}
      {showTooltip && (
        <ReactTooltip
          id="phantom_advisors"
          className={styles.tooltip}
          place="bottom"
          type="light"
          textColor="black"
          border
          borderColor="#dedee0"
        />
      )}
    </section>
  );
};

DealershipsPage.propTypes = {
  dealerships: arrayOf(string),
  isLoading: bool,
  fetchDealerships: func,
  token: string.isRequired,
  history: historyType,
  error: objectOf(node),
  total: number.isRequired,
  clearState: func.isRequired,
};

DealershipsPage.defaultProps = {
  dealerships: [],
  isLoading: false,
  fetchDealerships: () => {},
  history: null,
  error: null,
};

const mapStateToProps = (state) => {
  return {
    dealerships: dealershipsDataSelector(state),
    isLoading: dealershipsLoadingStateSelector(state),
    token: authTokenSelector(state),
    error: dealershipsErrorSelector(state),
    total: totalRecordsSelector(state),
  };
};

const actions = {
  fetchDealerships: retrieveDealerships,
  clearState: setAddingInitialState,
};

export default connect(mapStateToProps, actions)(DealershipsPage);
