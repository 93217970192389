import {
  DEALERSHIPS_ADD_FAILURE,
  DEALERSHIPS_ADD_SUCCESS,
  DEALERSHIPS_FETCH_FAILURE,
  DEALERSHIPS_FETCH_SUCCESS,
  DEALERSHIPS_SET_ADDING_INITIAL_STATE,
  DEALERSHIPS_SET_ADDING_LOADING_STATE,
  DEALERSHIPS_SET_LOADING_STATE,
} from "store/actions/dealerships-actions";

const initialState = {
  isLoading: false,
  data: [],
  error: null,
  addingError: null,
  addingLoading: false,
};

const dealerships = (state = initialState, action) => {
  switch (action.type) {
    case DEALERSHIPS_SET_LOADING_STATE:
      return {
        ...state,
        isLoading: true,
        additionError: null,
      };
    case DEALERSHIPS_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };
    case DEALERSHIPS_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    case DEALERSHIPS_SET_ADDING_LOADING_STATE:
      return {
        ...state,
        addingLoading: true,
        addingError: null,
      };
    case DEALERSHIPS_SET_ADDING_INITIAL_STATE:
      return {
        ...state,
        addingLoading: false,
        addingError: null,
      };
    case DEALERSHIPS_ADD_SUCCESS:
      return {
        ...state,
        addingLoading: false,
        addingError: null,
        data: [
          ...state.data,
          action.payload,
        ],
      };
    case DEALERSHIPS_ADD_FAILURE:
      return {
        ...state,
        addingError: action.payload.error.response,
        addingLoading: false,
      };
    default:
      return state;
  }
};

export default dealerships;
