import React, { useState } from "react";

import {
  bool, number, objectOf, shape, string,
} from "prop-types";
import ReactTooltip from "react-tooltip";
import { formatName } from "shared/utils/common";

import appraisalIcon from "assets/images/bookings/label.svg";
import recallIcon from "assets/images/bookings/recallIcon.svg";
import vinSpecificRecallIcon from "assets/images/bookings/recallVin.svg";
import customerWaitingIcon from "assets/images/bookings/coffee.svg";
import concernIcon from "assets/images/bookings/car.svg";
import emptyIcon from "assets/images/bookings/status/empty.svg";
import checkedInIcon from "assets/images/bookings/status/checked-in.svg";
import arrivedIcon from "assets/images/bookings/status/arrived.svg";
import driverAssignedIcon from "assets/images/driverAssignedGreen.svg";
import enRouteToCustomerIcon from "assets/images/departure.svg";
import arrivedToLocationIcon from "assets/images/position.svg";
import enRouteToDealershipIcon from "assets/images/arrive.svg";
import lyftIcon from "assets/images/bookings/alternativeTransport/lyft.png";
import loanerIcon from "assets/images/bookings/alternativeTransport/loaner.svg";
import uberIcon from "assets/images/bookings/alternativeTransport/uber.png";
import shuttleIcon from "assets/images/bookings/alternativeTransport/shuttle1.png";

import styles from "./styles.module.scss";

const jobStateIcons = {
  assigned: {
    icon: driverAssignedIcon,
    className: "driverAssignedFilter",
    label: "Driver Assigned",
  },
  en_route_to_customer: {
    icon: enRouteToCustomerIcon,
    className: "enRouteToCustomerFilter",
    label: "En Route To Customer",
  },
  arrived_to_customer: {
    icon: arrivedToLocationIcon,
    className: "arrivedToCustomerFilter",
    label: "Arrived To Customer",
  },
  en_route_to_dealership: {
    icon: enRouteToDealershipIcon,
    className: "enRouteToDealershipFilter",
    label: "En Route To Dealership",
  },
};

const appointmentStatusIcons = {
  not_checked_in: emptyIcon,
  checked_in: checkedInIcon,
  arrived: arrivedIcon,
};

const alternativeTransportOptionIcons = {
  loaner: loanerIcon,
  lyft: lyftIcon,
  uber: uberIcon,
  shuttle: shuttleIcon,
};

const BookingAdditionalInfoCell = ({
  jobState,
  status,
  customerWaiting,
  withConcern,
  withRecall,
  appraisalRequested,
  alternativeTransport,
  rowIndex,
  currentStatus,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const isCanceled = currentStatus === "canceled";

  return (
    <div>
      {isCanceled && (
        <div className={styles.canceled}>
          Canceled
        </div>
      )}
      {!isCanceled && (
        <div
          className={styles.container}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <div className={styles.statusCell}>
            {(jobState && jobStateIcons[jobState]?.icon) && (
              <img
                className={styles[jobStateIcons[jobState].className]}
                src={jobStateIcons[jobState].icon}
                alt="jobState"
                data-for={`icon-description-${rowIndex}`}
                data-tip={jobStateIcons[jobState].label}
              />
            )}
          </div>
          <div className={styles.statusCell}>
            <img
              src={appointmentStatusIcons[status]}
              alt="bookingState"
              data-for={`icon-description-${rowIndex}`}
              data-tip={status !== "not_checked_in" ? formatName(status) : null}
            />
          </div>
          <div className={styles.statusCell}>
            {customerWaiting && (
              <img
                src={customerWaitingIcon}
                alt="customer waiting"
                data-for={`icon-description-${rowIndex}`}
                data-tip="Customer Waiting"
              />
            )}
          </div>
          <div className={styles.statusCell}>
            {
              (alternativeTransport
          && alternativeTransport.kind
          && alternativeTransportOptionIcons[alternativeTransport.kind])
          && (
            <img
              src={alternativeTransportOptionIcons[alternativeTransport.kind]}
              className={styles.alternativeTransport}
              alt={alternativeTransport.kind}
              data-for={`icon-description-${rowIndex}`}
              data-tip={formatName(alternativeTransport.kind)}
            />
          )
            }
          </div>
          <div className={styles.statusCell}>
            {withConcern && (
              <img
                src={concernIcon}
                alt="with concern"
                data-for={`icon-description-${rowIndex}`}
                data-tip="With Concern"
              />
            )}
          </div>
          <div className={styles.statusCell}>
            {appraisalRequested && (
              <img
                src={appraisalIcon}
                alt="appraisal requested"
                data-for={`icon-description-${rowIndex}`}
                data-tip="Appraisal Requested"
              />
            )}
          </div>
          <div className={styles.statusCell}>
            {withRecall.present && (
              <img
                src={withRecall.vin_specific ? vinSpecificRecallIcon : recallIcon}
                alt="jobState"
                data-for={`icon-description-${rowIndex}`}
                data-tip={withRecall.vin_specific ? "VIN Specific Recall" : "With Recall"}
              />
            )}
          </div>
          {showTooltip && (
            <ReactTooltip
              id={`icon-description-${rowIndex}`}
              className={styles.tooltip}
              delayShow={100}
              place="bottom"
              backgroundColor="#F9FAFC"
              textColor="black"
              border
              borderColor="#dedee0"
            />
          )}
        </div>
      )}
    </div>
  );
};

BookingAdditionalInfoCell.propTypes = {
  jobState: bool,
  status: string.isRequired,
  customerWaiting: bool.isRequired,
  withConcern: bool.isRequired,
  withRecall: shape({
    present: bool,
    vin_specific: bool,
  }).isRequired,
  appraisalRequested: bool.isRequired,
  alternativeTransport: objectOf(string),
  rowIndex: number.isRequired,
  currentStatus: string.isRequired,
};

BookingAdditionalInfoCell.defaultProps = {
  jobState: null,
  alternativeTransport: null,
};

export default BookingAdditionalInfoCell;
