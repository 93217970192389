import React, { useState } from "react";
import { connect } from "react-redux";
import {
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types";
import { driverPropType } from "shared/prop-types";

import { updateJob } from "store/actions/remote-service-actions";
import { remoteServiceDriversSelector } from "store/selectors/remote-service-selectors";
import { settingsDealershipShowCoDriver } from "store/selectors/settings-selectors";

import Modal from "components/common/Modal";
import DriversSelection from "components/common/Remote/ChooseDrivers/DriversSelection";

const EditDriversJobModal = ({
  job,
  drivers,
  onClose,
  save,
  showCoDriver,
}) => {
  const [selectedDriver, setSelectedDriver] = useState(job.main_driver);
  const [selectedCoDriver, setSelectedCoDriver] = useState(job.co_driver);
  const [notes, setNotes] = useState(job.notes);

  const availableDrivers = () => drivers.filter(
    (item) => selectedCoDriver?.id !== item.id,
  );

  const availableCoDrivers = () => drivers.filter(
    (item) => selectedDriver?.id !== item.id,
  );

  const submit = () => {
    save({
      ...job,
      main_driver: selectedDriver,
      co_driver: selectedCoDriver,
      notes,
    });

    onClose();
  };

  return (
    <Modal
      title="Edit Job"
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="medium"
      onCancel={onClose}
      onSubmit={submit}
      submitDisabled={
        !selectedDriver
        || Object.keys(selectedDriver).length === 0
      }
    >
      <DriversSelection
        availableDrivers={availableDrivers()}
        selectedDriver={selectedDriver}
        setSelectedDriver={setSelectedDriver}
        availableCoDrivers={availableCoDrivers()}
        selectedCoDriver={selectedCoDriver}
        setSelectedCoDriver={setSelectedCoDriver}
        notes={notes}
        setNotes={setNotes}
        showCoDriver={showCoDriver}
      />
    </Modal>
  );
};

EditDriversJobModal.propTypes = {
  job: objectOf(oneOfType([number, bool, string])).isRequired,
  drivers: arrayOf(driverPropType),
  onClose: func.isRequired,
  save: func.isRequired,
  showCoDriver: bool.isRequired,
};

EditDriversJobModal.defaultProps = {
  drivers: null,
};

const mapStateToProps = (state) => {
  return {
    drivers: remoteServiceDriversSelector(state),
    showCoDriver: settingsDealershipShowCoDriver(),
  };
};

const actions = {
  save: updateJob,
};

export default connect(mapStateToProps, actions)(EditDriversJobModal);
