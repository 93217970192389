import {
  CHECKIN_APP_EXIT,
} from "store/actions/checkin-app-actions";

import {
  CHECKIN_CHAT_START_CHECKIN_SUCCESS,
  CHECKIN_CHAT_STORE_DEALERSHIP_SLUG,
} from "store/actions/checkin-chat-actions";

const initialState = {
  checkinId: null,
  actionCableChannelKey: null,
  dealership: {
    id: 1,
    name: null,
    address: null,
    longitude: null,
    latitude: null,
    welcome_screen: {
      url: null,
    },
    dmsType: "dealer_track",
    hours: null,
    notes: null,
    phone: null,
  },
  isNoValidAppointment: false,
  isNoAppointmentByGuid: false,
  isNoAppointmentByPhoneNumber: false,
  appointmentGuid: null,
  appointmentSlots: [],
  jobSlots: [],
  welcomeScreenType: "checkin",
  dealershipSlug: null,
};

const app = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHECKIN_CHAT_STORE_DEALERSHIP_SLUG: {
      return {
        ...state,
        dealershipSlug: payload,
      };
    }
    case CHECKIN_CHAT_START_CHECKIN_SUCCESS:
      return {
        ...state,
        checkinId: payload.id,
        dealership: {
          ...state.dealership,
          id: payload.dealership.id,
          address: payload.dealership.address,
          name: payload.dealership.name,
          latitude: payload.dealership.latitude,
          longitude: payload.dealership.longitude,
          welcomeScreen: payload.dealership.welcome_screen,
          dmsType: payload.dealership.dms_type,
          workingHours: payload.dealership.working_hours,
          timeZone: payload.dealership.time_zone,
          phone: payload.dealership.phone,
          logo: payload.dealership.logo.url,
          remoteJobsEnabled: payload.dealership.remote_enabled,
        },
        actionCableChannelKey: payload.channel_key,
      };
    case CHECKIN_APP_EXIT:
      return {
        ...initialState,
        checkinId: state.checkinId,
        actionCableChannelKey: state.actionCableChannelKey,
        appointmentGuid: state.appointmentGuid,
        dealershipSlug: state.dealershipSlug,
        dealership: state.dealership,
      };
    default:
      return state;
  }
};

export default app;
