import React from "react";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import { arrayOf, bool, string } from "prop-types";
import { isEmpty } from "ramda";

import {
  schedulingChosenTimeSlotSelector,
  schedulingClientWaitingSelector,
  schedulingCurrentCustomerSelector,
  schedulingCurrentVehicleSelector,
  schedulingSaveAppointmentLoadingErrorSelector,
  schedulingSaveAppointmentLoadingSelector,
  schedulingSelectedRecallsSelector,
  schedulingSelectedServicesSelector,
  schedulingSelectedTransportSelector,
  schedulingisMobileTechnicianSelector,
} from "store/selectors/scheduling-process-selectors";
import {
  settingsCapacityTimeBufferSelector,
  settingsDrivingTimeBufferSelector,
} from "store/selectors/settings-selectors";
import {
  chosenTimeSlotPropType,
  customerPropType,
  servicePropType,
  vehiclePropType,
} from "shared/prop-types";

import PageHeader from "components/common/PageHeader";
import Panel from "components/common/Panel";

import Button from "components/common/Button";
import VehicleClientBlock from "./VehicleClientBlock";
import AppointmentSummary from "./AppointmentSummary";

import styles from "./styles.module.scss";

const PageTitle = () => <h2>Scheduling</h2>;

const SchedulingProcessSummary = ({
  vehicle,
  customer,
  services,
  time,
  clientWaiting,
  selectedTransport,
  saveAppointmentLoading,
  saveAppointmentLoadingError,
  capacityBufferTime,
  driverBufferTime,
  isMobileTech,
}) => {
  if (!services.length || isEmpty(time)) {
    return (
      <Redirect
        to={{
          pathname: "/scheduling/process",
        }}
      />
    );
  }

  if (saveAppointmentLoading) {
    return <Panel className={styles.loading}>Loading...</Panel>;
  }

  return (
    <section className={styles.page}>
      <section className={styles.header}>
        <PageHeader title={<PageTitle />} />
      </section>
      {
        saveAppointmentLoadingError
          ? (
            <>
              <div className={styles.barRed}>
                {!!Array.isArray(saveAppointmentLoadingError) && (
                  saveAppointmentLoadingError.map((error) => error.message)
                )}
                {saveAppointmentLoadingError.message}
              </div>
              <section className={styles.noVehicle}>
                <NavLink
                  to={{
                    pathname: "/scheduling/process",
                  }}
                >
                  <Button
                    variant="brand"
                  >
                    Back
                  </Button>
                </NavLink>
              </section>
            </>
          )
          : (
            <>
              <div className={styles.barGreen}>
                New appointment has been successfully booked!
              </div>
              <section className={styles.container}>
                <section className={styles.choice}>
                  <VehicleClientBlock customer={customer} vehicle={vehicle} time={time} />
                </section>
                <AppointmentSummary
                  services={services}
                  clientWaiting={clientWaiting}
                  selectedTransport={selectedTransport}
                  bufferTime={isMobileTech ? driverBufferTime : capacityBufferTime}
                />
              </section>
            </>
          )
      }
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    vehicle: schedulingCurrentVehicleSelector(state),
    customer: schedulingCurrentCustomerSelector(state),
    services: [
      ...schedulingSelectedServicesSelector(state),
      ...schedulingSelectedRecallsSelector(state),
    ],
    time: schedulingChosenTimeSlotSelector(state),
    clientWaiting: schedulingClientWaitingSelector(state),
    selectedTransport: schedulingSelectedTransportSelector(state),
    saveAppointmentLoading: schedulingSaveAppointmentLoadingSelector(state),
    saveAppointmentLoadingError: schedulingSaveAppointmentLoadingErrorSelector(state),
    isMobileTech: schedulingisMobileTechnicianSelector(state),
    capacityBufferTime: settingsCapacityTimeBufferSelector(state),
    driverBufferTime: settingsDrivingTimeBufferSelector(state),
  };
};

SchedulingProcessSummary.propTypes = {
  vehicle: vehiclePropType.isRequired,
  customer: customerPropType.isRequired,
  services: arrayOf(servicePropType).isRequired,
  time: chosenTimeSlotPropType.isRequired,
  clientWaiting: bool.isRequired,
  selectedTransport: string.isRequired,
  saveAppointmentLoading: bool.isRequired,
  saveAppointmentLoadingError: string.isRequired,
  capacityBufferTime: string.isRequired,
  driverBufferTime: string.isRequired,
  isMobileTech: bool.isRequired,
};

export default connect(mapStateToProps, null)(SchedulingProcessSummary);
