import React, { useEffect, useState } from "react";
import {
  arrayOf, func, number, shape, string,
} from "prop-types";
import { SOURCE_USER } from "shared/constants/text-messages";
import Button from "components/common/Button";

import styles from "./styles.module.scss";

const ConcernDecisionTree = ({
  service: {
    decision_tree,
  },
  decisionTreeDetails,
  onChange,
}) => {
  const [decisionTreeResult, setDecisionTreeResult] = useState({});

  useEffect(() => {
    if (decisionTreeDetails && decisionTreeDetails.length > 0) {
      const results = decisionTreeDetails.reduce((total, { source, text }, treeItemIndex) => {
        if (source === SOURCE_USER) {
          return {
            ...total,
            [decisionTreeDetails[treeItemIndex - 1].text]: text,
          };
        }
        return total;
      }, {});

      setDecisionTreeResult(results);
    }
  }, [decisionTreeDetails]);

  return (
    <div className={styles.conciergeDecisionTree}>
      <div className={styles.conciergeDecisionTreeIntro}>
        {decision_tree.intro.text}
      </div>
      {decision_tree.steps.map((step) => (
        <div
          className={styles.conciergeDecisionTreeStep}
          key={step.text}
        >
          <div className={styles.conciergeDecisionTreeQuestion}>
            {step.text}
          </div>
          <div className={styles.conciergeDecisionTreeAnswers}>
            {(step.answers || []).map(({ text }) => (
              <Button
                type="button"
                key={text}
                variant={decisionTreeResult[step.text] === text ? "brand-outline" : "brand"}
                onClick={() => {
                  const nextState = {
                    ...decisionTreeResult,
                    [step.text]: decisionTreeResult[step.text] === text ? null : text,
                  };
                  setDecisionTreeResult(nextState);
                  onChange(nextState);
                }}
              >
                {text}
              </Button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

ConcernDecisionTree.propTypes = {
  onChange: func.isRequired,
  service: shape({
    decision_tree: arrayOf(string),
  }),
  decisionTreeDetails: arrayOf({
    length: number,
    map: func,
    text: string,
  }),
};

ConcernDecisionTree.defaultProps = {
  service: shape({
    decision_tree: [],
  }),
  decisionTreeDetails: arrayOf({
    length: null,
    map: () => { },
    text: "",
  }),
};

export default ConcernDecisionTree;
