import { leadingZeroHour, timeInUSFormat } from "shared/utils/datetime";
import { isNil } from "ramda";

const FULL_NAME_REGEX = /^([A-Za-z'-]+(?:[\s-][A-Za-z'-]+)*)$/;
const VIN_REGEX = /^([A-Z0-9]){17}$/;
const PHONE_NUMBER_REGEX = /^(\d{7,10}|\({0,1}\d{3}\){0,1}(\s|-)(\d{4}|\d{3}(\s|-)\d{3,4}))$/;
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const DECIMAL_TO_MINUTES = {
  25: "15",
  5: "30",
  75: "45",
};

export const times = (n) => Object.keys([...Array(n)]).map((v) => parseInt(v, 10));

export const formatName = (settingName) => {
  const nameArr = settingName.split("_");

  const pickupIndex = nameArr.indexOf("pickup");
  const pickupsIndex = nameArr.indexOf("pickups");

  if (pickupIndex !== -1) {
    nameArr[pickupIndex] = "pick-up";
  }

  if (pickupsIndex !== -1) {
    nameArr[pickupsIndex] = "pick-ups";
  }

  const capitalizedWords = nameArr.map((word) => (
    `${word.charAt(0).toUpperCase()}${word.slice(1)}`
  ));

  return capitalizedWords.join(" ");
};

export const getAsyncImage = (url) => new Promise(((resolve, reject) => {
  const img = new Image();
  img.onload = () => resolve(img);
  img.onerror = () => reject(img);
  img.src = url;
}));

export const reorderItems = (collection, from, to) => {
  const collectionCopy = collection.slice();
  const movingItem = collectionCopy[from];
  collectionCopy.splice(from, 1);
  collectionCopy.splice(to, 0, movingItem);

  return collectionCopy;
};

export const countSelectedServicesByType = (kind, selectedServices) => (
  selectedServices.filter((service) => service.kind === kind).length
);

export const hourSlots = (day) => {
  const wholeHours = day.map((slot) => (
    parseInt(slot, 10)
  ));

  return [...new Set(wholeHours)];
};

export const totalServiceTime = (selectedServices = []) => (
  selectedServices.reduce((total, service) => (
    total + +service.allocated_labor_time
  ), 0).toFixed(1)
);

export const estimatedServiceTimeForBooking = (allServices) => (
  totalServiceTime(allServices)
);

export const estimatedTotalServiceTimeForBooking = (allServices, upsellTime, bufferTime) => (
  (
    parseFloat(totalServiceTime(allServices))
    + (parseFloat(upsellTime) || 0)
    + (parseFloat(bufferTime) || 0)
  ).toFixed(1)
);

export const smoothScrollToRef = (ref) => ref.current.scrollTo({
  top: 0,
  behavior: "smooth",
});

export const totalCost = (allServices) => (
  allServices.map((service) => (
    service.price || service.fee
  )).reduce((totalFee, nextFee) => (
    Number(totalFee || 0) + Number(nextFee || 0)
  ), 0).toFixed(2)
);

export const totalCostForBooking = (allServices) => (
  allServices.map((service) => service.price || service.fee || 0)
    .reduce((totalFee, nextFee) => (
      Number(totalFee || 0) + Number(nextFee || 0)
    ), 0).toFixed(2)
);

export const extractVinFromString = (vin) => (
  [...vin.match(/^[A-Z0-9]{17}$/)].join("")
);
export const extractFullNameFromString = (fullName) => (
  [...fullName.match(/^[a-z '-]+$/i)].join("")
);
export const extractPhoneNumberFromString = (phoneNumberString) => (
  [...phoneNumberString.match(/\d+/g)].join("")
);
export const extractEmailFromString = (email) => (
  [...email.match(EMAIL_REGEX)].join("")
);

const isVinType = (searchPhrase) => VIN_REGEX.test(searchPhrase);
const isFullNameType = (searchPhrase) => FULL_NAME_REGEX.test(searchPhrase);
export const isPhoneNumberType = (searchPhrase) => PHONE_NUMBER_REGEX.test(searchPhrase);
export const isEmailType = (searchPhrase) => EMAIL_REGEX.test(searchPhrase);

export const determineSearchType = (searchPhrase) => {
  if (isVinType(searchPhrase)) {
    return "vin";
  }
  if (isFullNameType(searchPhrase)) {
    return "full_name";
  }
  if (isPhoneNumberType(searchPhrase)) {
    return "phone_number";
  }
  if (isEmailType(searchPhrase)) {
    return "email";
  }
  return null;
};

export const imageUrl = (image) => (
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_ROOT_URL}${image?.url}`
    : image?.url
);

export const driverWorkingHoursOptions = (workingHours) => {
  const workingHoursCopy = { ...workingHours };
  Object.keys(workingHoursCopy).forEach((dayName) => {
    if (workingHoursCopy[dayName].from === undefined) {
      workingHoursCopy[dayName] = [];
      return;
    }
    let dayHoursOptions = [{
      label: "",
      value: undefined,
    }];
    const startingHour = Math.max(parseInt(workingHoursCopy[dayName].from, 10) - 2, 1);
    const endingHour = Math.min(parseInt(workingHoursCopy[dayName].to, 10) + 2, 23);
    for (let hour = startingHour; hour <= endingHour; hour += 0.25) {
      const minutes = DECIMAL_TO_MINUTES[`${hour}`.substring(`${hour}`.indexOf(".") + 1, `${hour}`.length)];
      const hourAndMinutes = Number.isSafeInteger(hour) ? `${hour}:00` : `${Math.floor(hour)}:${minutes}`;
      const value = Number.isSafeInteger(hour) ? `${leadingZeroHour(hour)}:00` : `${leadingZeroHour(Math.floor(hour))}:${minutes}`;
      dayHoursOptions = [...dayHoursOptions, {
        label: timeInUSFormat(hourAndMinutes),
        value,
      }];
    }
    workingHoursCopy[dayName] = dayHoursOptions;
  });
  return workingHoursCopy;
};

export const isVehicleBookable = (vehicle) => {
  const vehicleObject = {
    vin: null,
    mileage: null,
    vehicle_set: {
      make: null,
      model: null,
      model_year: null,
    },
    ...vehicle,
  };
  return !isNil(vehicleObject.mileage)
    && !isNil(vehicleObject.vehicle_set.make)
    && !isNil(vehicleObject.vehicle_set.model) && !isNil(vehicleObject.vehicle_set.model_year);
};

export const isMakeModelValid = (value) => typeof value === "string" && value.toLowerCase() !== "unknown";

export const toSelectOption = (value) => {
  return {
    label: String(value),
    value,
  };
};

export const toMakeOption = (value) => {
  return {
    id: value,
    name: value,
  };
};

export const convertArrayToSelectOptions = (arr = []) => arr.filter((v) => v).map(toSelectOption);

export const convertArrayToMakeOptions = (arr = []) => {
  const data = Array.isArray(arr) ? arr : arr.split(", ");
  return data.filter((v) => v).map(toMakeOption);
};

export const prepareCustomerPickupAddress = (customer) => {
  if (!customer) {
    return null;
  }
  const { last_address, address } = customer;
  let pickupAddress = "";
  if (last_address) {
    const {
      address_line1: addressLine1,
      address_line2: addressLine2,
      address_city: addressCity,
      address_state: addressState,
      address_zipcode: addressZipcode,
    } = last_address;
    pickupAddress = [addressLine1, addressLine2, addressCity, addressZipcode, addressState].filter((v) => v).join(" ");
  }
  return pickupAddress || address || "";
};

export const prepareCustomerPickupAddressData = (customer) => {
  if (!customer) {
    return null;
  }
  const {
    last_address,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zipcode,
  } = customer;

  if (last_address && Object.values(last_address).filter((v) => !v).length === 0) {
    return last_address;
  }
  return {
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zipcode,
  };
};

export const getMostSeniourTeamTag = (team) => {
  if (!team.length) {
    return;
  }

  return team.sort((a, b) => a.seniority - b.seniority)[0];
};

export const formatInput = (label) => label.replace(/\s+/g, " ").trim();
