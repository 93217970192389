import React from "react";
import { func, oneOf, string } from "prop-types";
import cx from "classnames";

import "./styles.scss";

const Alert = ({
  text, variant, onClick, className,
}) => (
  <div
    className={cx("conciergeAlert", `conciergeAlert_${variant}`, className)}
    onClick={onClick}
  >
    <p>{text}</p>
  </div>
);

Alert.propTypes = {
  text: string.isRequired,
  variant: oneOf([
    "success",
    "brand",
    "brand-outline",
    "error",
    "error-outline",
    "warning",
    "warning-outline",
  ]),
  onClick: func,
  className: string,
};

Alert.defaultProps = {
  variant: "brand-outline",
  onClick: () => {},
  className: null,
};

export default Alert;
