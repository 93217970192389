import React, { useEffect, useRef, useState } from "react";
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  objectOf,
  shape,
  string,
} from "prop-types";
import { history as historyType } from "react-router-prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { identity } from "ramda";
import { format, isToday } from "date-fns";
import { convertToTimeZone } from "date-fns-timezone";
import {
  ADVISOR_ROLE,
  ARRIVE_SOURCE,
  ASC,
  BDC_ROLE,
  CUSTOMER_APP_SOURCE,
  CUSTOMER_SOURCE,
  DEFAULT_SORT_COLUMN_BOOKINGS,
  DEFAULT_SORT_DIRECTION_BOOKINGS,
  DESC,
  IMPORT_SOURCE,
  MANAGER_ROLE,
  PROACTIVE_SOURCE,
  SCHEDULER_SOURCE,
} from "shared/constants";
import {
  bulkTimeCompare,
  convertTime12to24,
  datesSortingMethod,
  getAppointmentFormattedDateTimeFirst,
} from "shared/utils/datetime";
import {
  bookingsDataSelector,
  bookingsErrorSelector,
  bookingsLoadingStateSelector,
  bookingsSearchParams,
  bookingsSearchStringSelector,
  bookingsTimelineFiltersSelector,
} from "store/selectors/bookings-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import { settingsTimezoneSelector } from "store/selectors/settings-selectors";
import {
  bookingsCurrentPageSelector,
  bookingsPerPageSelector,
  bookingsSortSelector,
  bookingsTotalRecordsSelector,
} from "store/selectors/table-options-selectors";
import { retrieveBookings, setSearchString } from "store/actions/bookings-actions";
import Panel from "components/common/Panel";
import PageHeader from "components/common/PageHeader";
import SearchField from "components/common/SearchField";
import Pagination from "components/common/ReactTableElements/Pagination";
import {
  AdvisorCell,
  AppArrivedTimeCell,
  CustomerCell,
  MadeByCell,
  RepairOrderCell,
  TeamCell,
} from "components/common/ReactTableElements/cellRenderers";
import BookingAdditionalInfoCell from "components/common/ReactTableElements/cellRenderers/BookingAdditionalInfoCell";
import { useDebounce, useDeepCompareEffect } from "shared/hooks";
import TimelineFilters from "./TimelineFilters";
import PeriodFilter from "./PeriodFilter";
import AdditionalInfoFilter from "./AdditionalInfoFilter";
import CapacityDashboard from "./CapacityDashboard";
import SelectedServicesTooltip from "./SelectedServicesTooltip";
import "react-table-6/react-table.css";
import "./styles.scss";

const columns = [
  {
    Header: "App time / Arrived",
    accessor: "appointment_datetime",
    sortMethod: datesSortingMethod,
    width: 225,
    Cell: (booking) => (
      <AppArrivedTimeCell
        appTime={booking.original.appointment_datetime}
        arrivedTime={booking.original.arrived_at}
        hasWarning={booking.original.hasMissingInfo}
        isLate={booking.original.isLate}
        currentStatus={booking.original.aasm_state}
      />
    ),
  },
  {
    Header: "Customer",
    accessor: "customer_full_name",
    sort_column: "customer_first_name",
    width: 225,
    Cell: (booking) => (
      <div
        data-for={`booking-details-${booking.original.id}`}
        data-tip
      >
        <CustomerCell
          customer={booking.original.customer_full_name}
          vehicle={
            booking.original.vehicle_set || {
              make: null,
              model: null,
              model_year: null,
            }
          }
          vehicleImgUrl={
            booking.original.vehicle ? booking.original.vehicle.image.url : null
          }
          currentStatus={booking.original.aasm_state}
        />
        <SelectedServicesTooltip
          menuItems={booking.original.bookings_menu_items}
          bookingId={booking.original.id}
        />
      </div>
    ),
  },
  {
    Header: "RO",
    accessor: "repair_order_number",
    width: 125,
    Cell: (booking) => (
      <RepairOrderCell
        repair_order_number={booking.original.repair_order_number}
        repair_order_tag={booking.original.repair_order_tag}
        currentStatus={booking.original.aasm_state}
      />
    ),
  },
  {
    Header: "Team",
    accessor: "appointment_responsible_team",
    sort_column: "appointment_responsible_team_tag_name",
    width: 98,
    Cell: (booking) => (
      <TeamCell
        appointment_responsible_team={booking.original.appointment_responsible_team}
        service_type={booking.original.service_type}
        questionnaire_answers={booking.original.questionnaire_answers}
        currentStatus={booking.original.aasm_state}
      />
    ),
  },
  {
    Header: "Advisor",
    accessor: "service_advisor_name",
    sort_column: "service_advisor_name",
    width: 165,
    Cell: (booking) => (
      <AdvisorCell
        service_advisor_name={booking.original.service_advisor_name}
        currentStatus={booking.original.aasm_state}
      />
    ),
  },
  {
    Header: "Made by",
    accessor: "created_by_name",
    sort_column: "created_by_name",
    width: 165,
    Cell: (booking) => (
      <MadeByCell
        source={booking.original.booking_source}
        made_by_name={booking.original.made_by_name}
        made_by_role={booking.original.made_by_role}
        overcapacity={booking.original.overcapacity}
        currentStatus={booking.original.aasm_state}
      />
    ),
  },
  {
    Header: "Additional Info",
    sortable: false,
    width: 215,
    Cell: (booking) => (
      <BookingAdditionalInfoCell
        jobState={booking.original.job_status}
        status={booking.original.status}
        customerWaiting={booking.original.client_waiting}
        withConcern={booking.original.with_concern}
        withRecall={booking.original.with_recall}
        appraisalRequested={booking.original.appraisal_requested}
        alternativeTransport={booking.original.transport}
        rowIndex={booking.index}
        currentStatus={booking.original.aasm_state}
      />
    ),
  },
];

const BookingsPage = ({
  dealershipId,
  timelineFilters,
  fetchBookings,
  sort,
  currentPage,
  free_text_search,
  searchParams,
  error,
  bookings,
  isLoading,
  history,
  changeSearchString,
  timezone,
  total,
  perPage,
}) => {
  const DEFAULT_SORT = `${DEFAULT_SORT_COLUMN_BOOKINGS} ${DEFAULT_SORT_DIRECTION_BOOKINGS}`;
  const [searchPhrase, setSearchPhrase] = useState(free_text_search);
  const [newPage, setNewPage] = useState(currentPage);
  const [pageSize, setPageSize] = useState(perPage);
  const [newSort, setNewSort] = useState(null);

  const isFirstRun = useRef(true);
  const delay = searchPhrase === "" ? 0 : 500;
  const debouncedSearchPhrase = useDebounce(searchPhrase, delay);

  useEffect(() => {
    setNewPage(currentPage || 1);
  }, []);

  useEffect(() => {
    if (sort) {
      setNewSort(sort);
    }
  }, [sort]);

  useDeepCompareEffect(() => {
    if (
      (
        isFirstRun.current
        && searchParams["q[appointment_datetime_gteq]"]
        && searchParams["q[appointment_datetime_lteq]"]
        && searchParams["q[arrived_at_gteq]"]
        && searchParams["q[arrived_at_lteq]"]
      ) || !isFirstRun.current
    ) {
      isFirstRun.current = false;

      fetchBookings(
        {
          per_page: pageSize,
          page: newPage,
          sort: newSort,
          ...searchParams,
        },
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isFirstRun.current) {
      fetchBookings(
        {
          per_page: pageSize,
          page: newPage,
          sort: newSort,
          ...searchParams,
        },
      );
    }
  }, [newPage, pageSize, newSort]);

  useEffect(() => {
    changeSearchString(debouncedSearchPhrase);
  }, [debouncedSearchPhrase]);

  const onSortedChange = (newSorted, column) => {
    const sortColumn = column.sort_column ? column.sort_column : column.id;
    const direction = newSorted[0].desc ? DESC : ASC;
    setNewSort(`${sortColumn} ${direction}`);
  };

  const prepareSortOptions = (sortOptions) => {
    const [apiSortColumn, sortDirection] = (sortOptions || DEFAULT_SORT).split(" ");
    const sortColumn = columns.find((col) => {
      if (col.sort_column) {
        return col.sort_column === apiSortColumn;
      }
      return col.accessor === apiSortColumn;
    });
    const sortColumnId = sortColumn ? sortColumn.accessor : DEFAULT_SORT_COLUMN_BOOKINGS;
    return [sortColumnId, sortDirection];
  };

  const prepareData = (data) => data
    .filter((booking) => {
      if (booking.formatted) {
        return true;
      }

      if (
        timelineFilters.today
        && booking.arrived_at
        && !isToday(booking.arrived_at)
      ) {
        return false;
      }
      return true;
    })
    .map((booking) => {
      if (booking.formatted) {
        return booking;
      }

      const {
        appointment_datetime,
        customer,
        phone_number,
        service_advisor,
        arrived_at,
        vehicle,
      } = booking;

      const customer_first_name = customer ? customer.first_name : null;
      const customer_last_name = customer ? customer.last_name : null;
      const customer_phone_number = customer ? customer.phone_number : null;
      const customer_address = customer ? customer.address : null;
      const customer_email = customer ? customer.email : null;
      const make = vehicle && vehicle?.vehicle_set ? vehicle.vehicle_set.make : null;
      const model = vehicle && vehicle?.vehicle_set ? vehicle.vehicle_set.model : null;
      const model_year = vehicle && vehicle?.vehicle_set ? vehicle.vehicle_set.model_year : null;
      const mileage = vehicle ? vehicle.mileage : null;
      const vin = vehicle ? vehicle.vin : null;

      const carTitle = make && model && model_year
        ? `${make} ${model} ${model_year}`.toLowerCase()
        : "-";
      const formattedDate = getAppointmentFormattedDateTimeFirst(appointment_datetime);
      const customerFullNameLower = () => {
        if (customer_first_name || customer_last_name) {
          const firstName = customer_first_name ? customer_first_name.toLowerCase() : "";
          const lastName = customer_last_name ? customer_last_name.toLowerCase() : "";
          return `${firstName} ${lastName}`;
        }
        return "-";
      };
      const customerPhoneNumber = () => {
        if (phone_number && phone_number.length > 5) {
          return phone_number;
        }

        if (customer_phone_number && customer_phone_number.length > 5) {
          return customer_phone_number;
        }

        return "-";
      };
      const repairOrderNumber = () => {
        if (booking.kind === "Appointment") {
          return booking.repair_order?.repair_order_number;
        }

        if (booking.kind === "RepairOrder") {
          return booking.external_number;
        }

        return "-";
      };
      const serviceAdvisorName = () => {
        if (service_advisor) {
          return service_advisor.name;
        }
        return "-";
      };
      const source = () => {
        switch (booking.created_by.source) {
          case IMPORT_SOURCE:
            return "Imported";
          case ARRIVE_SOURCE:
            return "Walk-in";
          case CUSTOMER_SOURCE:
            return "Customer";
          case CUSTOMER_APP_SOURCE:
            return "Customer (app)";
          case PROACTIVE_SOURCE:
            return "Customer";
          default:
            return null;
        }
      };

      const madeBy = () => {
        switch (booking.created_by.source) {
          case SCHEDULER_SOURCE:
            if (
              booking.created_by.created_by_role === BDC_ROLE
              || booking.created_by.created_by_role === ADVISOR_ROLE
              || booking.created_by.created_by_role === MANAGER_ROLE
            ) {
              return booking.created_by.created_by_name;
            }

            return null;
          default:
            return null;
        }
      };

      const role = () => {
        if (booking.created_by.created_by_role === BDC_ROLE) {
          return "BDC";
        }
        return null;
      };

      const arrivedAt = () => {
        if (arrived_at) {
          const timezoned_arrived_at = convertToTimeZone(arrived_at, { timeZone: timezone });
          return getAppointmentFormattedDateTimeFirst(timezoned_arrived_at);
        }
        return "-";
      };

      const hasMissingInfo = () => {
        const valid = vin
          && mileage
          && make
          && model
          && model_year
          && customer_address
          && customer_first_name
          && customer_last_name
          && customer_phone_number
          && customer_email;

        return !valid;
      };

      const isLate = () => {
        if (timelineFilters.today && formattedDate && timezone) {
          const timezoneTime = convertToTimeZone(new Date(), { timeZone: timezone });
          const timeLeftStr = format(timezoneTime, "HH:mm");
          const timeRightStr = convertTime12to24(formattedDate);
          const diff = bulkTimeCompare(timeLeftStr, timeRightStr);

          return timelineFilters.today && repairOrderNumber() === "-" && diff >= 1;
        }

        return false;
      };

      const preparedBooking = booking;
      preparedBooking.car_title = carTitle;
      preparedBooking.repair_order_number = repairOrderNumber();
      preparedBooking.customer_full_name = customerFullNameLower();
      preparedBooking.appointment_datetime = formattedDate;
      preparedBooking.phone_number = customerPhoneNumber();
      preparedBooking.service_advisor_name = serviceAdvisorName();
      preparedBooking.arrived_at = arrivedAt();
      preparedBooking.datetime = appointment_datetime;
      preparedBooking.isLate = isLate();
      preparedBooking.hasMissingInfo = hasMissingInfo();
      preparedBooking.formatted = true;
      preparedBooking.made_by_name = madeBy();
      preparedBooking.made_by_role = role();
      preparedBooking.booking_source = source();
      preparedBooking.vehicle_set = vehicle?.vehicle_set;
      return preparedBooking;
    });

  const [sortColumn, sortDirection] = prepareSortOptions(newSort);

  const onPageSizeChange = (size) => {
    setPageSize(size);
    setNewPage(1);
  };

  return (
    <section className="conciergeBookingPage">
      <PageHeader
        title={<h2>Bookings</h2>}
        rightSideContent={(
          <div className="conciergeBookingPageRightSideContent">
            <AdditionalInfoFilter />
            <SearchField
              placeholder="Search for bookings..."
              value={searchPhrase}
              tooltipContent="Please press Enter to perform search"
              onChange={setSearchPhrase}
              onEnter={identity}
            />
          </div>
        )}
      />
      <section className="conciergeBookingPageMain">
        {error && (
          <Panel className="conciergeBookingPageLoadingPanel">
            {error?.errors || error?.error || error}
          </Panel>
        )}
        {!error && (
          <div>
            <section className="conciergeBookingPageFilters">
              <TimelineFilters
                timelineFilters={timelineFilters}
              />
              <div className="row">
                {timelineFilters.all && <PeriodFilter /> }
              </div>
            </section>
            <CapacityDashboard />
            <ReactTable
              data={bookings}
              total={total}
              minRows={1}
              resolveData={prepareData}
              loading={isLoading}
              columns={columns}
              defaultPageSize={pageSize}
              PaginationComponent={Pagination}
              onPageChange={setNewPage}
              onPageSizeChange={onPageSizeChange}
              onSortedChange={onSortedChange}
              page={newPage}
              noDataText="No appointments found"
              className="booking"
              manual
              defaultSorted={[
                {
                  id: sortColumn,
                  desc: sortDirection === DESC,
                },
              ]}
              getTrProps={(state, rowInfo) => {
                return {
                  onClick: () => {
                    history.push(
                      `/bookings/${rowInfo.original.guid}/${rowInfo.original.kind}?dealershipId=${dealershipId}`,
                    );
                  },
                  className: `conciergeTableRowLink
                  ${rowInfo
                    && rowInfo.original.status === "arrived" ? "conciergeTableRowLinkGreen" : ""}
                  ${rowInfo
                    && rowInfo.original.status === "checked_in" ? "conciergeTableRowLinkBlue" : ""}
                  ${rowInfo
                    && rowInfo.original.status === "not_checked_in"
                    && rowInfo.original.aasm_state === "arrived" ? "conciergeTableRowLinkYellow" : ""}
                `,
                };
              }}
              prepareRow
              resizable={false}
            />
          </div>
        )}
      </section>
    </section>
  );
};

BookingsPage.propTypes = {
  history: historyType,
  bookings: arrayOf(node).isRequired,
  isLoading: bool.isRequired,
  timelineFilters: objectOf(bool).isRequired,
  searchParams: objectOf(shape).isRequired,
  free_text_search: string.isRequired,
  dealershipId: number.isRequired,
  currentPage: number.isRequired,
  sort: string.isRequired,
  error: string,
  timezone: string,
  fetchBookings: func.isRequired,
  changeSearchString: func.isRequired,
  total: number.isRequired,
  perPage: number.isRequired,
};

BookingsPage.defaultProps = {
  history: null,
  error: null,
  timezone: null,
};

const mapStateToProps = (state) => {
  return {
    bookings: bookingsDataSelector(state),
    isLoading: bookingsLoadingStateSelector(state),
    timelineFilters: bookingsTimelineFiltersSelector(state),
    searchParams: bookingsSearchParams(state),
    free_text_search: bookingsSearchStringSelector(state),
    dealershipId: dealershipIdSelector(state),
    currentPage: bookingsCurrentPageSelector(state),
    sort: bookingsSortSelector(state),
    error: bookingsErrorSelector(state),
    timezone: settingsTimezoneSelector(state),
    total: bookingsTotalRecordsSelector(state),
    perPage: bookingsPerPageSelector(state),
  };
};

const actions = {
  fetchBookings: retrieveBookings,
  changeSearchString: setSearchString,
};

export default connect(mapStateToProps, actions)(BookingsPage);
