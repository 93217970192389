import React from "react";
import {
  bool, node, objectOf, string,
} from "prop-types";
import Panel from "components/common/Panel";
import SelectedServices from "./SelectedServices";
import EstimatedServiceTimeAndTotalCost from "./EstimatedServiceTimeAndTotalCost";

import styles from "./styles.module.scss";

const AppointmentSummary = ({
  services,
  clientWaiting,
  selectedTransport,
  bufferTime,
}) => (
  <Panel className={styles.container}>
    <SelectedServices services={services} />
    <EstimatedServiceTimeAndTotalCost
      services={services}
      bufferTime={bufferTime}
    />
    <div className={styles.details}>
      <span className={styles.label}>Transportation: </span>
      <span className={styles.value}>
        {clientWaiting ? "Waiting room" : (selectedTransport || "N/A")}
      </span>
    </div>
  </Panel>
);

AppointmentSummary.propTypes = {
  services: objectOf(node),
  clientWaiting: bool,
  selectedTransport: string,
  bufferTime: string.isRequired,
};

AppointmentSummary.defaultProps = {
  services: null,
  clientWaiting: bool,
  selectedTransport: string,
};

export default AppointmentSummary;
