import React from "react";
import {
  bool, func, string,
} from "prop-types";

import checkedIcon from "assets/images/checked.svg";
import uncheckedIcon from "assets/images/unchecked.svg";

import styles from "./styles.module.scss";

const AdditionalInfoFilterDropdownItem = ({
  assets,
  assetsClassName,
  caption,
  isActive,
  handleOnClick,
}) => (
  <div
    className={styles.container}
    onClick={handleOnClick}
  >
    <img
      className={styles.checkbox}
      src={isActive ? checkedIcon : uncheckedIcon}
      alt={isActive ? "checked" : "unchecked"}
    />
    {assets && (
      <img
        className={assetsClassName}
        src={assets}
        alt="img"
      />
    )}
    <span>{caption}</span>
  </div>
);

AdditionalInfoFilterDropdownItem.propTypes = {
  caption: string.isRequired,
  assets: string.isRequired,
  assetsClassName: string,
  isActive: bool.isRequired,
  handleOnClick: func.isRequired,
};

AdditionalInfoFilterDropdownItem.defaultProps = {
  assetsClassName: "",
};

export default AdditionalInfoFilterDropdownItem;
