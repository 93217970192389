import { connect } from "react-redux";

import {
  fetchAvailableDays,
  getAvailableLoaners,
  setChosenDropOffTimeSlot,
  setChosenTimeSlot,
  setCurrentStep,
  setRemoteJobData,
  setServiceAdvisor,
  waiterPossibility,
} from "store/actions/booking-details-actions";
import {
  bookingActiveMobileTechnicianJobSelector,
  bookingActivePickupJobSelector,
  bookingDetailsAvailableDaysSelector,
  bookingDetailsChosenDropOffTimeSlotSelector,
  bookingDetailsChosenTimeSlotSelector,
  bookingDetailsCurrentStepSelector,
  bookingDetailsDropOffInitialTimeSelector,
  bookingDetailsInitialAdvisorIdSelector,
  bookingDetailsInitialTimeSelector,
  bookingDetailsIsPickUpSelector,
  bookingDetailsRemoteJobDataSelector,
  bookingDetailsTimeSlotsLoadingErrorSelector,
  bookingDetailsTimeSlotsLoadingSelector,
  bookingDetailsisMobileTechnicianSelector,
} from "store/selectors/booking-details-selectors";
import { phantomAdvisorAvailableSelector, settingsDataSelector } from "store/selectors/settings-selectors";

import TimeOfArrival from "./index";

const mapStateToProps = (state) => {
  return {
    availableDays: bookingDetailsAvailableDaysSelector(state),
    chosenTimeSlot: bookingDetailsChosenTimeSlotSelector(state),
    chosenDropOffTimeSlot: bookingDetailsChosenDropOffTimeSlotSelector(state),
    timeSlotsLoading: bookingDetailsTimeSlotsLoadingSelector(state),
    timeSlotsLoadingError: bookingDetailsTimeSlotsLoadingErrorSelector(state),
    currentStep: bookingDetailsCurrentStepSelector(state),
    phantomAdvisorAvailable: phantomAdvisorAvailableSelector(state),
    dealershipSettings: settingsDataSelector(state),
    initialTime: bookingDetailsInitialTimeSelector(state),
    dropOffInitialTime: bookingDetailsDropOffInitialTimeSelector(state),
    isPickUp: bookingDetailsIsPickUpSelector(state),
    isMobileTechnician: bookingDetailsisMobileTechnicianSelector(state),
    activePickupJob: bookingActivePickupJobSelector(state),
    activeMobileTechnicianJob: bookingActiveMobileTechnicianJobSelector(state),
    remoteJobData: bookingDetailsRemoteJobDataSelector(state),
    initialAdvisorId: bookingDetailsInitialAdvisorIdSelector(state),
  };
};

const actions = {
  fetchDays: fetchAvailableDays,
  setChosenQuarterSlot: setChosenTimeSlot,
  setChosenDropOffSlot: setChosenDropOffTimeSlot,
  setSchedulingStep: setCurrentStep,
  makeAdvisorSelection: setServiceAdvisor,
  validateWaiterPossibility: waiterPossibility,
  availableLoaners: getAvailableLoaners,
  storeRemoteJobData: setRemoteJobData,
};

export default connect(mapStateToProps, actions)(TimeOfArrival);
