import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import uniqid from "uniqid";
import {
  arrayOf, bool, func, shape,
} from "prop-types";
import { addQuestionnairesAnswers } from "store/actions/booking-details-actions";
import {
  bookingDetailsQuestionnairesLoadingSelector,
  bookingDetailsQuestionnairesSelector,
} from "store/selectors/booking-details-selectors";
import { settingsDealershipPickUpEnabledSelector } from "store/selectors/settings-selectors";
import Bell from "assets/icons/bell.svg";
import Modal from "components/common/Modal";
import Button from "components/common/Button";

import styles from "./styles.module.scss";

const QuestionnaireModal = ({
  onClose,
  isPickUp,
  questionnaires,
  saveAnswers,
  isLoading,
}) => {
  const containerRef = createRef();
  const [questionnairesAnswers, setQuestionnairesAnswers] = useState([]);
  const [stopIndex, setStopIndex] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      const answersAmount = questionnairesAnswers.filter(
        (answer) => typeof answer === "string" && /\S/.test(answer),
      ).length;
      const elementsAmount = containerRef.current.querySelectorAll(
        `[class*="${styles.question}"]`,
      ).length;

      setFormCompleted(
        elementsAmount === answersAmount && !stopIndex,
      );
    }
  }, [containerRef, stopIndex, questionnairesAnswers]);

  useEffect(() => {
    const prepareAnswers = () => {
      const answers = new Array(questionnaires.length).fill(null);

      questionnaires.forEach((item, index) => {
        if (item.default) {
          answers[index] = item.default;
        }
      });

      return answers;
    };

    setQuestionnairesAnswers(prepareAnswers());
  }, [questionnaires]);

  const buttonComponent = (index, value, stop) => {
    const handleChange = (item) => {
      const result = [...questionnairesAnswers];

      setStopIndex(stop === item && result[index] !== item ? index : null);

      if (result[index] !== item) {
        result[index] = item;
      } else {
        result[index] = null;
      }

      for (let i = index + 1; i < result.length; i++) {
        if ("visibility" in questionnaires[i]) {
          if (questionnaires[i].visibility.index === index) {
            result[i] = null;
          }
        }
      }

      setQuestionnairesAnswers(result);
    };

    const color = () => {
      if (questionnairesAnswers[index] === value) {
        if (questionnairesAnswers[index] === stop) {
          return "destructive-outline";
        }
        return "aqua";
      }
      return "";
    };

    return (
      <Button
        variant={color()}
        onClick={() => handleChange(value)}
        fullWidth
      >
        {value}
      </Button>
    );
  };

  const label = (question) => {
    if (question.indexOf("terms and conditions") !== -1) {
      const parts = question.split("terms and conditions");

      return (
        <p>
          {parts[0]}
          <a href="https://getcarmen.com/mobile-service-tc/">
            terms and conditions
          </a>
          {parts[1]}
        </p>
      );
    }

    return <p>{question}</p>;
  };

  const onSave = () => {
    const answers = {};

    questionnairesAnswers.forEach((answer, index) => {
      if (answer) {
        answers[questionnaires[index].question] = answer;
      }
    });

    saveAnswers(answers);
    onClose();
  };

  return (
    <Modal
      title="Mobile service questionnaire"
      size="medium"
      loading={isLoading}
      cancelButtonText="Cancel"
      submitButtonText="Save"
      onCancel={onClose}
      onSubmit={onSave}
      submitDisabled={!formCompleted}
    >
      <div className={styles.header}>
        <p className={styles.subtitle}>
          Complete the questionnaire to confirm the appointment.
        </p>
      </div>
      <div
        className={styles.body}
        ref={containerRef}
      >
        {(questionnaires).map((item, index) => {
          if (stopIndex && stopIndex < index) {
            return null;
          }
          let showItem = false;

          if ("visibility" in item) {
            if (
              questionnairesAnswers[item.visibility.index] === item.visibility.value
            ) {
              showItem = true;
            }
          } else {
            showItem = true;
          }

          if (showItem) {
            if (item.type === "bool") {
              return (
                <div
                  key={uniqid()}
                  className={styles.question}
                >
                  {label(item.question)}
                  <div className={styles.bool}>
                    {buttonComponent(index, item.variants[0], item.stop)}
                    {buttonComponent(index, item.variants[1], item.stop)}
                  </div>
                </div>
              );
            }

            if (item.type === "string") {
              const handleChange = (event) => {
                const result = [...questionnairesAnswers];
                result[index] = event.target.value;

                setQuestionnairesAnswers(result);
              };

              return (
                <div
                  key={`input-${Number(index)}`}
                  className={styles.question}
                >
                  <p>{item.question}</p>
                  <div className={styles.string}>
                    <input
                      type="text"
                      value={questionnairesAnswers[index] || ""}
                      onClick={(event) => event.stopPropagation(event)}
                      onKeyUp={(event) => event.preventDefault()}
                      onChange={(event) => handleChange(event)}
                      maxLength={200}
                    />
                  </div>
                </div>
              );
            }
          }

          return null;
        })}
        {stopIndex && (
          <div className={styles.errorContainer}>
            <div className={styles.icon}>
              <img
                src={Bell}
                alt=""
              />
            </div>
            <div className={styles.error}>
              {
                `Because of your answer, we are unable to offer mobile service. 
                    We can offer ${isPickUp ? "pick-up and drop-off or" : ""} 
                    service at the dealership.`
              }
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

QuestionnaireModal.propTypes = {
  onClose: func.isRequired,
  saveAnswers: func.isRequired,
  isPickUp: bool.isRequired,
  isLoading: bool.isRequired,
  questionnaires: arrayOf(shape).isRequired,
};

const actions = {
  saveAnswers: addQuestionnairesAnswers,
};

const mapStateToProps = (state) => {
  return {
    isPickUp: settingsDealershipPickUpEnabledSelector(state),
    questionnaires: bookingDetailsQuestionnairesSelector(state),
    isLoading: bookingDetailsQuestionnairesLoadingSelector(state),
  };
};

export default connect(mapStateToProps, actions)(QuestionnaireModal);
