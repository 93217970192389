import {
  SCHEDULING_CREATE_CUSTOMER_SUCCESS,
  SCHEDULING_CREATE_VEHICLE_SUCCESS,
  SCHEDULING_CREATE_VEHICLE_SUCCESS_WITHOUT_DMS,
  SCHEDULING_FETCH_CUSTOMERS_FAILURE,
  SCHEDULING_FETCH_CUSTOMERS_SUCCESS,
  SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
  SCHEDULING_UPDATE_CUSTOMER_SUCCESS,
  SCHEDULING_UPDATE_VEHICLE_SUCCESS,
  SCHEDULING_UPDATE_VEHICLE_SUCCESS_WITHOUT_DMS,
} from "store/actions/scheduling-actions";
import {
  BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_FAILURE,
  BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_SUCCESS,
  BOOKING_DETAILS_FETCH_RECALLS_ERROR,
  BOOKING_DETAILS_FETCH_RECALLS_SUCCESS,
  BOOKING_DETAILS_FETCH_VEHICLE_DATA,
  BOOKING_DETAILS_GENERATE_REPAIR_ORDER_FAILURE,
  BOOKING_DETAILS_GENERATE_REPAIR_ORDER_SUCCESS,
  BOOKING_DETAILS_JOB_REACHABLE,
  BOOKING_DETAILS_SET_NEW_DRIVER_JOB_FAILURE,
  BOOKING_DETAILS_SET_NEW_DRIVER_JOB_SUCCESS,
  BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_FAILURE,
  BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_SUCCESS,
  BOOKING_DETAILS_UPDATE_APPOINTMENT_FAILURE,
  BOOKING_DETAILS_UPDATE_APPOINTMENT_SUCCESS,
  BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_FAILURE,
  BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_SUCCESS,
  BOOKING_DETAILS_UPDATE_DRIVER_JOB_FAILURE,
  BOOKING_DETAILS_UPDATE_DRIVER_JOB_SUCCESS,
  BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_FAILURE,
  BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS,
  BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS_WITHOUT_DMS,
  setNewDriverJob,
  setNewMobileTechnicianJob,
} from "store/actions/booking-details-actions";
import {
  REMOTE_SERVICE_UPDATE_JOB_FAILURE,
  REMOTE_SERVICE_UPDATE_JOB_SUCCESS,
} from "store/actions/remote-service-actions";
import {
  schedulingCustomersSelector,
  schedulingSavingCustomerIdSelector,
} from "store/selectors/scheduling-selectors";
import {
  schedulingIsDropOffSelector,
  schedulingIsPickUpSelector,
  schedulingRecallsLoadingSelector,
  schedulingRemoteJobDataSelector,
  schedulingisMobileTechnicianSelector,
} from "store/selectors/scheduling-process-selectors";
import {
  bookingDetailsDriversDropOffDataSelector,
  bookingDetailsDriversJobTypeSelector,
  bookingDetailsDriversPickUpDataSelector,
} from "store/selectors/booking-details-selectors";
import { remoteServiceIsLoading } from "store/selectors/remote-service-selectors";
import { chatCurrentVehicleCustomerNumberSelector, chatCurrentVehicleSelector } from "store/selectors/checkin-chat-selectors";
import { getIntegrationErrorsFromFullErrors, getServerErrorsFromFullErrors } from "shared/utils/errorHandler";

import { SAME_NUMBER_OR_PLATE_ERROR } from "shared/constants";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import {
  fetchCdkModelCode,
  fetchMakeModelYearMap,
  fetchVehicleData,
  fetchVehicleSetByVin,
} from "shared/api";
import {
  authChannelKeySelector,
  authTokenSelector,
} from "store/selectors/auth-selectors";
import {
  SCHEDULING_PROCESS_FETCH_RECALLS_ERROR,
  SCHEDULING_PROCESS_FETCH_RECALLS_SUCCESS,
  SCHEDULING_PROCESS_JOB_REACHABLE,
  SCHEDULING_PROCESS_SAVE_APPOINTMENT_FAILURE,
  SCHEDULING_PROCESS_SAVE_APPOINTMENT_SUCCESS,
} from "./scheduling-process-actions";

export const APP_FETCH_MAKE_MODEL_YEAR_MAP = "APP_FETCH_MAKE_MODEL_YEAR_MAP";
export const APP_FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS = "APP_FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS";
export const APP_FETCH_MAKE_MODEL_YEAR_MAP_FAILURE = "APP_FETCH_MAKE_MODEL_YEAR_MAP_FAILURE";
export const APP_RESET_VEHICLE_SET_BY_VIN = "APP_RESET_VEHICLE_SET_BY_VIN";
export const APP_FETCH_VEHICLE_SET_BY_VIN = "APP_FETCH_VEHICLE_SET_BY_VIN";
export const APP_FETCH_VEHICLE_SET_BY_VIN_SUCCESS = "APP_FETCH_VEHICLE_SET_BY_VIN_SUCCESS";
export const APP_FETCH_VEHICLE_SET_BY_VIN_FAILURE = "APP_FETCH_VEHICLE_SET_BY_VIN_FAILURE";
export const APP_FETCH_CDK_MODEL_CODE_BY_VIN = "APP_FETCH_CDK_MODEL_CODE_BY_VIN";
export const APP_FETCH_CDK_MODEL_CODE_BY_VIN_SUCCESS = "APP_FETCH_CDK_MODEL_CODE_BY_VIN_SUCCESS";
export const APP_FETCH_CDK_MODEL_CODE_BY_VIN_FAILURE = "APP_FETCH_CDK_MODEL_CODE_BY_VIN_FAILURE";
export const APP_CLEAR_REPORTS = "APP_CLEAR_REPORTS";
export const APP_SWITCH_SHOW_REPORTS = "APP_SWITCH_SHOW_REPORTS";
export const APP_SWITCH_SHOW_QR = "APP_SWITCH_SHOW_QR";

const FETCH_CUSTOMERS_RESPONSE_TYPE = "import_customers";
const SAVE_APPOINTMENT_RESPONSE_TYPE = "add_appointment";
const UPDATE_APPOINTMENT_RESPONSE_TYPE = "update_appointment";
const CANCEL_APPOINTMENT_RESPONSE_TYPE = "cancel_appointment";
const CREATE_REPAIR_ORDER_RESPONSE_TYPE = "create_repair_order_from_appointment";
const CREATE_NEW_CUSTOMER_RESPONSE_TYPE = "add_customer";
const CREATE_NEW_VEHICLE_RESPONSE_TYPE = "add_vehicle";
const UPDATE_VEHICLE_RESPONSE_TYPE = "update_vehicle";
const UPDATE_CUSTOMER_RESPONSE_TYPE = "update_customer";
const IMPORT_RECALLS_RESPONSE_TYPE = "import_recalls";
const FETCH_AVAILABLE_DRIVERS_RESPONSE_TYPE = "import_drivers";
const CREATE_NEW_DRIVER_JOB = "add_job";
const UPDATE_DRIVER_JOB = "update_job";
const CHECKIN_SMS_SENT = "send_confirmation_sms";
const UPDATE_SET_FOR_VEHICLE = "update_set_for_vehicle";
const GET_VEHICLE_SET_BY_VIN = "get_vehicle_set_by_vin";
const GET_CDK_MODEL_CODE_BY_VIN = "get_make_model_by_vin";
const IS_JOB_REACHABLE = "is_job_reachable";

const errorHandling = (dispatch, errors, fullErrors, vehicleCustomerNumber) => {
  let message = errors;

  if (fullErrors) {
    if (Array.isArray(fullErrors)) {
      const messages = [];

      fullErrors.forEach((item) => {
        const result = [];

        if ("code" in item) {
          if (item.code[0] === "6" && item.code !== "600.302") {
            if (item.code[4] === "0") {
              result.push("External error");
            } else if (item.code[4] === "1") {
              result.push("DealerTrack error");
            } else if (item.code[4] === "2") {
              result.push("CDK error");
            } else if (item.code[4] === "3") {
              result.push("Fortellis error");
            }
          }
        }

        if ("message" in item) {
          if (!("code" in item)) {
            result.push("Something went wrong.");
          } else if (item.code === "600.302") {
            result.push(`
            Verify vehicle ownership: 
            According to the DMS this vehicle is owned by another customer ${vehicleCustomerNumber}.  
            Please verify vehicle ownership in DMS and adjust where applicable 
            and then return to Carmen to generate Repair Order.
            `);
            // eslint-disable-next-line no-use-before-define
            dispatch(retrieveVehicleData());
          } else if (item.code === "600.309") {
            result.push(`
            Please, make sure the appointment is not open in the DMS. 
            It might block RO creation.
            `);
          } else {
            result.push(item.message);
          }
        }

        messages.push(result.join(": "));
      });

      message = messages.join(", ");
    }
  }

  return message;
};

export const actionCableListener = (message) => (dispatch, getState) => {
  const {
    action, data, errors, full_errors,
  } = message;

  const vehicleCustomerNumber = chatCurrentVehicleCustomerNumberSelector(getState());

  switch (action) {
    case FETCH_CUSTOMERS_RESPONSE_TYPE:
      if (data) {
        dispatch({
          type: SCHEDULING_FETCH_CUSTOMERS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: SCHEDULING_FETCH_CUSTOMERS_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case SAVE_APPOINTMENT_RESPONSE_TYPE:
      if (data) {
        const isPickUp = schedulingIsPickUpSelector(getState());
        const isDropOff = schedulingIsDropOffSelector(getState());
        const isMobileTechnician = schedulingisMobileTechnicianSelector(getState());
        const jobsData = schedulingRemoteJobDataSelector(getState());

        if (isPickUp) {
          dispatch(setNewDriverJob({
            appointmentId: data.id,
            ...jobsData.pickUp,
          }));
        }

        if (isDropOff) {
          dispatch(setNewDriverJob({
            appointmentId: data.id,
            ...jobsData.dropOff,
          }));
        }

        if (isMobileTechnician) {
          dispatch(setNewMobileTechnicianJob({
            appointmentId: data.id,
            ...jobsData.mobileTechnician,
          }));
        }

        dispatch({
          type: SCHEDULING_PROCESS_SAVE_APPOINTMENT_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: SCHEDULING_PROCESS_SAVE_APPOINTMENT_FAILURE,
          payload: { errors: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case CREATE_REPAIR_ORDER_RESPONSE_TYPE:
      if (data) {
        dispatch({
          type: BOOKING_DETAILS_GENERATE_REPAIR_ORDER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: BOOKING_DETAILS_GENERATE_REPAIR_ORDER_FAILURE,
          payload: { errors: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case UPDATE_APPOINTMENT_RESPONSE_TYPE:
    case CANCEL_APPOINTMENT_RESPONSE_TYPE:
      if (data) {
        dispatch({
          type: BOOKING_DETAILS_UPDATE_APPOINTMENT_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: BOOKING_DETAILS_UPDATE_APPOINTMENT_FAILURE,
          payload: { errors: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case CREATE_NEW_CUSTOMER_RESPONSE_TYPE:
      if (data) {
        dispatch({
          type: SCHEDULING_CREATE_CUSTOMER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case CREATE_NEW_VEHICLE_RESPONSE_TYPE:
      if (data) {
        const stateCustomers = schedulingCustomersSelector(getState());
        const savingCustomerId = schedulingSavingCustomerIdSelector(getState());
        const findCustomerById = (customer) => customer.id === savingCustomerId;
        const customerToUpdateIndex = stateCustomers.findIndex(
          findCustomerById,
        );

        const newVehicle = {
          ...data,
          appointment: null,
        };

        const newVehicles = [
          ...stateCustomers[customerToUpdateIndex].vehicles,
          newVehicle,
        ];

        const newCustomer = {
          ...stateCustomers[customerToUpdateIndex],
          vehicles: newVehicles,
        };

        stateCustomers.splice(customerToUpdateIndex, 1, newCustomer);

        dispatch({
          type: SCHEDULING_CREATE_VEHICLE_SUCCESS,
          payload: stateCustomers,
        });
      } else {
        // map the action by the error code from full_errors
        const serverErrors = getServerErrorsFromFullErrors(full_errors);
        const integrationErrors = getIntegrationErrorsFromFullErrors(full_errors);

        if (serverErrors.length > 0) {
          dispatch({
            type: SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
            payload: SAME_NUMBER_OR_PLATE_ERROR,
          });
        } else if (integrationErrors.length > 0) {
          dispatch({
            type: SCHEDULING_CREATE_VEHICLE_SUCCESS_WITHOUT_DMS,
            payload: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber),
          });
        }
      }
      break;
    case UPDATE_VEHICLE_RESPONSE_TYPE:
      if (data) {
        const stateCustomers = schedulingCustomersSelector(getState());
        const savingCustomerId = schedulingSavingCustomerIdSelector(getState());
        const findVehicleById = (vehicle) => vehicle.id === data.id;
        const findCustomerByid = (customer) => customer.id === savingCustomerId;
        const findCustomerByVehicleId = (customer) => customer.vehicles.findIndex(findVehicleById);
        const findCustomerByCustomerNumber = (customer) => (
          customer.customer_number === data.customer_number
        );

        let customerToUpdateIndex = stateCustomers.findIndex(findCustomerByid);
        if (customerToUpdateIndex < 0) {
          customerToUpdateIndex = stateCustomers.findIndex(findCustomerByVehicleId);
        }
        if (customerToUpdateIndex < 0) {
          customerToUpdateIndex = stateCustomers.findIndex(findCustomerByCustomerNumber);
        }

        const vehiclesToUpdate = stateCustomers[customerToUpdateIndex].vehicles;
        const vehicleToUpdateIndex = vehiclesToUpdate.findIndex(
          findVehicleById,
        );

        const newVehicle = {
          ...stateCustomers[customerToUpdateIndex].vehicles[
            vehicleToUpdateIndex
          ],
          mileage: data.mileage,
          vin: data.vin,
          plate_number: data.plate_number,
        };

        vehiclesToUpdate.splice(vehicleToUpdateIndex, 1, newVehicle);

        const newCustomer = {
          ...stateCustomers[customerToUpdateIndex],
          vehicles: vehiclesToUpdate,
        };

        stateCustomers.splice(customerToUpdateIndex, 1, newCustomer);

        dispatch({
          type: SCHEDULING_UPDATE_VEHICLE_SUCCESS,
          payload: stateCustomers,
        });
      } else {
        const serverErrors = getServerErrorsFromFullErrors(full_errors);
        const integrationErrors = getIntegrationErrorsFromFullErrors(full_errors);

        if (serverErrors.length > 0) {
          dispatch({
            type: SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
            payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
          });
        } else if (integrationErrors.length > 0) {
          dispatch({
            type: SCHEDULING_UPDATE_VEHICLE_SUCCESS_WITHOUT_DMS,
            payload: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber),
          });
        }
      }
      break;
    case UPDATE_SET_FOR_VEHICLE:
      if (data) {
        dispatch({
          type: BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS,
          payload: {
            vin: data.vin,
            mileage: data.mileage,
            vehicle_set: data.vehicle_set,
            plate_number: data.plate_number,
          },
        });
      } else {
        const serverErrors = getServerErrorsFromFullErrors(full_errors);
        const integrationErrors = getIntegrationErrorsFromFullErrors(full_errors);

        if (serverErrors.length > 0) {
          dispatch({
            type: BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_FAILURE,
            payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
          });
        } else if (integrationErrors.length > 0) {
          dispatch({
            type: BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS_WITHOUT_DMS,
            payload: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber),
          });
        }
      }
      break;
    case GET_VEHICLE_SET_BY_VIN:
      if (data) {
        dispatch({
          type: APP_FETCH_VEHICLE_SET_BY_VIN_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: APP_FETCH_VEHICLE_SET_BY_VIN_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case GET_CDK_MODEL_CODE_BY_VIN:
      if (data) {
        dispatch({
          type: APP_FETCH_CDK_MODEL_CODE_BY_VIN_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: APP_FETCH_CDK_MODEL_CODE_BY_VIN_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case UPDATE_CUSTOMER_RESPONSE_TYPE:
      if (data) {
        const stateCustomers = schedulingCustomersSelector(getState());
        if (stateCustomers.length > 0) {
          const findCustomerByNumber = (customer) => (
            customer.customer_number === data.customer_number
          );
          const customerToUpdateIndex = stateCustomers.findIndex(
            findCustomerByNumber,
          );

          const newCustomer = {
            ...stateCustomers[customerToUpdateIndex],
            address: data.address,
            address_line1: data.address_line1,
            address_line2: data.address_line2,
            address_state: data.address_state,
            address_zipcode: data.address_zipcode,
            customer_type: data.customer_type,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
            internal_note: data.internal_note,
          };

          stateCustomers.splice(customerToUpdateIndex, 1, newCustomer);
          dispatch({
            type: SCHEDULING_UPDATE_CUSTOMER_SUCCESS,
            payload: stateCustomers,
          });
        }
        dispatch({
          type: BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
        dispatch({
          type: BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case IMPORT_RECALLS_RESPONSE_TYPE:
      if (data) {
        const schedulingRecallsLoading = schedulingRecallsLoadingSelector(getState());

        dispatch({
          type: schedulingRecallsLoading
            ? SCHEDULING_PROCESS_FETCH_RECALLS_SUCCESS
            : BOOKING_DETAILS_FETCH_RECALLS_SUCCESS,
          payload: {
            recalls: data.map((recall) => {
              return {
                ...recall,
                operationCode: recall.operation_code,
                actionType: recall.action_type,
              };
            }),
          },
        });
      } else {
        const schedulingRecallsLoading = schedulingRecallsLoadingSelector(getState());

        dispatch({
          type: schedulingRecallsLoading
            ? SCHEDULING_PROCESS_FETCH_RECALLS_ERROR
            : BOOKING_DETAILS_FETCH_RECALLS_ERROR,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case FETCH_AVAILABLE_DRIVERS_RESPONSE_TYPE: {
      const jobType = bookingDetailsDriversJobTypeSelector(getState());
      const pickUpData = bookingDetailsDriversPickUpDataSelector(getState());
      const dropOffData = bookingDetailsDriversDropOffDataSelector(getState());

      if (data) {
        if (jobType === "pickUp") {
          dispatch({
            type: BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_SUCCESS,
            payload: {
              pickUp: data || [],
              dropOff: dropOffData,
            },
          });
        } else if (jobType === "dropOff") {
          dispatch({
            type: BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_SUCCESS,
            payload: {
              pickUp: pickUpData,
              dropOff: data || [],
            },
          });
        }
      } else {
        dispatch({
          type: BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    }
    case CREATE_NEW_DRIVER_JOB:
      if (data) {
        dispatch({
          type: BOOKING_DETAILS_SET_NEW_DRIVER_JOB_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: BOOKING_DETAILS_SET_NEW_DRIVER_JOB_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case UPDATE_DRIVER_JOB:
      if (data) {
        if (remoteServiceIsLoading(getState())) {
          dispatch({
            type: REMOTE_SERVICE_UPDATE_JOB_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: BOOKING_DETAILS_UPDATE_DRIVER_JOB_SUCCESS,
            payload: data,
          });
        }
      } else if (remoteServiceIsLoading(getState())) {
        dispatch({
          type: REMOTE_SERVICE_UPDATE_JOB_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      } else {
        dispatch({
          type: BOOKING_DETAILS_UPDATE_DRIVER_JOB_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case CHECKIN_SMS_SENT:
      if (data) {
        dispatch({
          type: BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_FAILURE,
          payload: { error: errorHandling(dispatch, errors, full_errors, vehicleCustomerNumber) },
        });
      }
      break;
    case IS_JOB_REACHABLE:
      dispatch({
        type: SCHEDULING_PROCESS_JOB_REACHABLE,
        payload: {
          jobType: data.job_type,
          reachable: data.reachable,
        },
      });
      dispatch({
        type: BOOKING_DETAILS_JOB_REACHABLE,
        payload: {
          jobType: data.job_type,
          reachable: data.reachable,
        },
      });
      break;
    default:
      break;
  }
};

export const retrieveMakeModelYearMap = () => (dispatch) => {
  dispatch({ type: APP_FETCH_MAKE_MODEL_YEAR_MAP });

  return fetchMakeModelYearMap()
    .then((response) => dispatch({
      type: APP_FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS,
      payload: response,
    }))
    .catch((error) => dispatch({
      type: APP_FETCH_MAKE_MODEL_YEAR_MAP_FAILURE,
      payload: error,
    }));
};

export const retrieveVehicleSetByVin = (vin) => (dispatch, getState) => {
  dispatch({ type: APP_FETCH_VEHICLE_SET_BY_VIN });
  const dealership_id = dealershipIdSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const token = authTokenSelector(getState());
  return fetchVehicleSetByVin(
    dealership_id,
    {
      dealership_id,
      channel_key,
      vin,
    },
    token,
  )
    .catch((error) => dispatch({
      type: APP_FETCH_VEHICLE_SET_BY_VIN_FAILURE,
      payload: error,
    }));
};

export const retrieveCdkModelCodeByVin = (vin) => (dispatch, getState) => {
  dispatch({ type: APP_FETCH_CDK_MODEL_CODE_BY_VIN });
  const dealership_id = dealershipIdSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const token = authTokenSelector(getState());
  return fetchCdkModelCode(
    dealership_id,
    {
      dealership_id,
      channel_key,
      vin,
    },
    token,
  )
    .catch((error) => dispatch({
      type: APP_FETCH_CDK_MODEL_CODE_BY_VIN_FAILURE,
      payload: error,
    }));
};

export const resetVehicleSetByVin = () => {
  return { type: APP_RESET_VEHICLE_SET_BY_VIN };
};

export const switchShowReports = () => (dispatch) => {
  dispatch({ type: APP_CLEAR_REPORTS });
  dispatch({ type: APP_SWITCH_SHOW_REPORTS });
};

export const switchShowQR = () => (dispatch) => {
  dispatch({ type: APP_SWITCH_SHOW_QR });
};

export const retrieveVehicleData = () => (dispatch, getState) => {
  const dealership_id = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());
  const { id } = chatCurrentVehicleSelector(getState());

  return fetchVehicleData(
    dealership_id,
    token,
    id,
  ).then((result) => dispatch({
    type: BOOKING_DETAILS_FETCH_VEHICLE_DATA,
    payload: result,
  }));
};
