import React, { useEffect, useRef, useState } from "react";
import {
  arrayOf, func, oneOf, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import { isEmpty, isNil } from "ramda";
import Select from "react-select";
import { dmsTypeSelector, settingsDealershipWorkingHoursSelector, settingsRemoteUsersSelector } from "store/selectors/settings-selectors";
import { workingHoursPropType } from "shared/prop-types";
import { extractPhoneNumberFromString } from "shared/utils/common";
import { WEEK_DAYS } from "shared/utils/datetime";
import { phoneNumberLengthValidator } from "shared/validators";
import ImageInput from "components/common/ImageInput";
import Block from "components/common/Block";
import Modal from "components/common/Modal";
import Button from "components/common/Button";
import ReactTimePicker from "react-time-picker";
import Panel from "components/common/Panel";
import Alert from "components/common/Alert";
import Input from "components/common/Input";
import "./styles.scss";

const AddEmployeeModal = ({
  type,
  label,
  dmsType,
  dealershipWorkingHours,
  onSubmit,
  onClose,
  error,
  remoteUsers,
}) => {
  const [name, setName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [employeePhoto, setEmployeePhoto] = useState(null);
  const [employeeWorkingHours, setEmployeeWorkingHours] = useState({
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  });
  const [photoForUpload, setPhotoForUpload] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(error);
  const [showError, setShowError] = useState(false);
  const [currentDay, setCurrentDay] = useState("mon");
  const [email, setEmail] = useState(null);
  const [options, setSelectOptions] = useState([]);

  const applyForAllDaysRef = useRef(null);

  useEffect(() => {
    if (remoteUsers) {
      const usersType = type === "mobile" ? "mobile_technician" : "driver";
      const filteredUsers = remoteUsers.filter(
        (user) => !user.name && user.role === usersType,
      );
      const preparedOptions = filteredUsers.map(((user) => {
        return {
          label: user.email,
          value: user.id,
          name: user.name,
        };
      }));
      setSelectOptions(preparedOptions);
    }
  }, [remoteUsers]);

  const validateFromBeforeTo = () => {
    let wrongHoursError = null;

    Object.keys(employeeWorkingHours).forEach((dayName) => {
      for (let i = 0; i < employeeWorkingHours[dayName].length; i++) {
        if (dealershipWorkingHours[dayName].from !== dealershipWorkingHours[dayName].to) {
          const fromHourValue = parseInt(employeeWorkingHours[dayName][i].from, 10);
          const toHourValue = parseInt(employeeWorkingHours[dayName][i].to, 10);
          const dealershipWorkingHoursFrom = parseInt(dealershipWorkingHours[dayName].from, 10);
          const dealershipWorkingHoursTo = parseInt(dealershipWorkingHours[dayName].to, 10);

          if (fromHourValue > toHourValue) {
            wrongHoursError = `Employee should start the work before the end of it. (${WEEK_DAYS[dayName]})`;
            setErrorMessage(wrongHoursError);
            setShowError(true);
          }

          if (!employeeWorkingHours[dayName][i].from || !employeeWorkingHours[dayName][i].to) {
            wrongHoursError = `Please check time frames on ${WEEK_DAYS[dayName]}.`;
            setErrorMessage(wrongHoursError);
            setShowError(true);
          }

          if (i > 0) {
            const prevTo = parseInt(employeeWorkingHours[dayName][i - 1].to, 10);

            if (fromHourValue < prevTo) {
              wrongHoursError = `Please check time frames on ${WEEK_DAYS[dayName]}.`;
              setErrorMessage(wrongHoursError);
              setShowError(true);
            }
          }

          if (
            fromHourValue < dealershipWorkingHoursFrom
            || dealershipWorkingHoursTo < toHourValue
          ) {
            wrongHoursError = `Time frames out of for dealership working hours on ${WEEK_DAYS[dayName]}.`;
            setErrorMessage(wrongHoursError);
            setShowError(true);
          }
        }
      }
    });

    return isNil(wrongHoursError);
  };

  const validateNamePresent = () => {
    let nameBlankError = null;
    if (isNil(name) || isEmpty(name)) {
      nameBlankError = "Name should be present";
      setErrorMessage(nameBlankError);
      setShowError(true);
    }

    return isNil(nameBlankError);
  };

  const validatePhoneNumberPresent = () => {
    let phoneNumberBlankError = null;

    if (isNil(phoneNumber) || isEmpty(phoneNumber)) {
      phoneNumberBlankError = "Phone number should be present";
      setErrorMessage(phoneNumberBlankError);
      setShowError(true);
    }

    return isNil(phoneNumberBlankError);
  };

  const validatePhoneNumberFormat = () => {
    let phoneNumberFormatError = null;

    // eslint-disable-next-line no-restricted-globals
    const extractedNumber = !isNaN(phoneNumber)
      ? extractPhoneNumberFromString(phoneNumber)
      : "";

    if (!phoneNumberLengthValidator(extractedNumber, dmsType)) {
      phoneNumberFormatError = "Invalid phone number given";
      setErrorMessage(phoneNumberFormatError);
      setShowError(true);
    }

    return isNil(phoneNumberFormatError);
  };

  const submit = () => {
    setErrorMessage(null);
    setShowError(false);
    setShowSuccess(false);

    if (
      validateFromBeforeTo()
      && validateNamePresent()
      && (
        type === "advisor"
        || (validatePhoneNumberPresent() && validatePhoneNumberFormat())
      )
    ) {
      const employeeData = new FormData();
      employeeData.append("name", name);
      employeeData.append("phone_number", phoneNumber);
      employeeData.append("working_hours", JSON.stringify(employeeWorkingHours));

      if (email) {
        employeeData.append("user_id", email);
      }

      if (photoForUpload) {
        employeeData.append("photo", photoForUpload);
      }

      onSubmit(employeeData);
      onClose();
    }
  };

  const handlePhotoChange = (e) => {
    const newPhoto = e.target.files[0];
    const photoUrl = URL.createObjectURL(newPhoto);
    setPhotoForUpload(newPhoto);
    setEmployeePhoto({ url: photoUrl });
    setShowError(false);
    setShowSuccess(true);
  };

  const capitalizeFirstLetter = (value) => value.charAt(0).toUpperCase() + value.slice(1);

  const workingHoursHeader = (
    <div className="display-flex align-items-center">
      <p>{WEEK_DAYS[currentDay]}</p>
      <div className="addEmployeeModalDayOff">
        <input
          type="checkbox"
          id="dayOff"
          name="dayOff"
          checked={employeeWorkingHours[currentDay].length === 0}
          onClick={() => {
            const employeeWorkingHoursCopy = JSON.parse(JSON.stringify(employeeWorkingHours));

            if (employeeWorkingHours[currentDay].length === 0) {
              employeeWorkingHoursCopy[currentDay] = [{
                from: null,
                to: null,
              }];
            } else {
              employeeWorkingHoursCopy[currentDay] = [];
            }

            setEmployeeWorkingHours({ ...employeeWorkingHoursCopy });
          }}
        />
        <label htmlFor="dayOff">Day off</label>
      </div>
    </div>
  );

  return (
    <Modal
      className="addEmployeeModal"
      title={`Add new ${label}`}
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="large"
      onCancel={onClose}
      onSubmit={submit}
    >
      <div className="capacitySettingsAddEmployeeModalStatusContainer">
        {showSuccess && (
          <Alert
            variant="success"
            text="New photo was successfully uploaded. Don&apos;t forget to save it."
            onClick={() => setShowSuccess(false)}
          />
        )}
        {showError && (
          <Alert variant="error" text={errorMessage} onClick={() => setShowError(false)} />
        )}
      </div>
      <Block
        className="conciergeSettingsPageBlock capacitySettingsAddEmployeeModalPhotoBlock"
      >
        <ImageInput
          isRounded
          onImageChange={handlePhotoChange}
          isEditing
          inputName="employeePhotoInput"
          image={employeePhoto}
          alt="employee photo"
          noImageText="No Photo"
        />
        <div className="capacitySettingsAddEmployeeModalSeparator">&nbsp;</div>
        <Input
          label="Name"
          value={name}
          onChange={setName}
        />
        <Input
          label="Phone number"
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
        {(type === "driver" || type === "mobile") && (
          <div>
            <div className="userProfileTitle">User profile</div>
            <Select
              isClearable
              className="userProfile"
              classNamePrefix="userProfileSelect"
              placeholder="Select or search"
              noOptionsMessage={() => "No emails found."}
              options={options}
              value={email}
              onChange={setEmail}
            />
          </div>
        )}
      </Block>
      <Block title="Typical schedule">
        <div className="addEmployeeModalDaySelector">
          {Object.keys(employeeWorkingHours).map((day) => (
            <Button
              variant={currentDay === day ? "aqua" : "neutral"}
              padding="small"
              onClick={() => setCurrentDay(day)}
              disabled={dealershipWorkingHours[day].from === dealershipWorkingHours[day].to}
            >
              {capitalizeFirstLetter(day)}
            </Button>
          ))}
        </div>
        {employeeWorkingHours && employeeWorkingHours[currentDay]
          && (
            <Panel
              className="addEmployeeModalConciergePanel"
              header={workingHoursHeader}
            >
              <div className="base-margin">
                <div className="display-flex align-items-center">
                  <div>
                    {employeeWorkingHours
                    && Object.values(employeeWorkingHours[currentDay]).map((item, index) => (
                      <div className="display-flex align-items-center addEmployeeModalTimeSelectSection">
                        <ReactTimePicker
                          className="timeFieldInput"
                          value={item.from}
                          disableClock
                          format="hh:mma"
                          clearIcon={null}
                          onChange={(value) => {
                            const employeeWorkingHoursCopy = JSON.parse(
                              JSON.stringify(employeeWorkingHours),
                            );
                            employeeWorkingHoursCopy[currentDay][index].from = value;
                            setEmployeeWorkingHours({ ...employeeWorkingHoursCopy });
                          }}
                          disabled={(
                            dealershipWorkingHours[currentDay].from
                            === dealershipWorkingHours[currentDay].to
                          )}
                        />
                        <hr />
                        <ReactTimePicker
                          className="timeFieldInput"
                          value={item.to}
                          disableClock
                          format="hh:mma"
                          clearIcon={null}
                          onChange={(value) => {
                            const employeeWorkingHoursCopy = JSON.parse(
                              JSON.stringify(employeeWorkingHours),
                            );
                            employeeWorkingHoursCopy[currentDay][index].to = value;
                            setEmployeeWorkingHours({ ...employeeWorkingHoursCopy });
                          }}
                          disabled={(
                            dealershipWorkingHours[currentDay].from
                            === dealershipWorkingHours[currentDay].to
                          )}
                        />
                        <Button
                          leftIcon="close"
                          padding="small"
                          onClick={() => {
                            const employeeWorkingHoursCopy = JSON.parse(
                              JSON.stringify(employeeWorkingHours),
                            );
                            employeeWorkingHoursCopy[currentDay].splice(index, 1);
                            setEmployeeWorkingHours({ ...employeeWorkingHoursCopy });
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  {employeeWorkingHours[currentDay].length > 0 && (
                    <>
                      <div className="capacitySettingsAddEmployeeModalSeparator" />
                      <input
                        ref={applyForAllDaysRef}
                        type="checkbox"
                        id="applyForAllDays"
                        name="applyForAllDays"
                        onClick={() => {
                          if (applyForAllDaysRef.current.checked) {
                            const workingHoursCopy = JSON.parse(
                              JSON.stringify(employeeWorkingHours),
                            );

                            Object.keys(workingHoursCopy).forEach((key) => {
                              if (
                                dealershipWorkingHours[key].from !== dealershipWorkingHours[key].to
                              ) {
                                workingHoursCopy[key] = workingHoursCopy[currentDay];
                              }
                            });

                            setEmployeeWorkingHours({ ...workingHoursCopy });
                          }
                        }}
                      />
                      <label htmlFor="applyForAllDays">Apply for all days</label>
                    </>
                  )}
                </div>
                <div className={employeeWorkingHours[currentDay].length > 0 ? "padding-top" : ""}>
                  {Object.keys(employeeWorkingHours[currentDay]).length < 3 && (
                    <Button
                      className="addEmployeeModalSplitTime"
                      padding="small"
                      onClick={() => {
                        setEmployeeWorkingHours({
                          ...employeeWorkingHours,
                          [currentDay]: [
                            ...employeeWorkingHours[currentDay],
                            {
                              from: undefined,
                              to: undefined,
                            },
                          ],
                        });
                      }}
                    >
                      + Split time
                    </Button>
                  )}
                </div>
              </div>
            </Panel>
          )}
      </Block>
    </Modal>
  );
};

AddEmployeeModal.propTypes = {
  type: oneOf([
    "advisor",
    "driver",
    "mobile",
  ]).isRequired,
  label: string.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  dealershipWorkingHours: workingHoursPropType.isRequired,
  error: string,
  dmsType: string.isRequired,
  remoteUsers: arrayOf(shape),
};

AddEmployeeModal.defaultProps = {
  error: null,
  remoteUsers: [],
};

const mapStateToProps = (state) => {
  return {
    dealershipWorkingHours: settingsDealershipWorkingHoursSelector(state),
    dmsType: dmsTypeSelector(state),
    remoteUsers: settingsRemoteUsersSelector(state),
  };
};

export default connect(mapStateToProps, null)(AddEmployeeModal);
