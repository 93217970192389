import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, node, objectOf, oneOfType, string,
} from "prop-types";
import cx from "classnames";
import StyledSelect from "components/common/StyledSelect";

import { BUSINESS_CUSTOMER_TYPE, INDIVIDUAL_CUSTOMER_TYPE } from "shared/constants";
import { US_STATES } from "shared/constants/us_states";

import { convertArrayToSelectOptions, formatInput } from "shared/utils/common";
import { customerFieldsPropType } from "shared/prop-types";
import { renderDataEditionErrors } from "./helpers";
import Modal from "../Modal";

import styles from "./styles.module.scss";

const DataEditionModal = ({
  onClose,
  title,
  fields,
  initialData,
  onSubmit,
  loading,
  error,
  submitButtonText,
}) => {
  const [data, setData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [fieldsError, setFieldsError] = useState(false);
  const validateField = (value) => /[a-zA-Z0-9]/.test(value);
  const validateState = (value) => /^(?!\s*$)[a-zA-Z]{2}$/.test(value);
  const validateZipCode = (value) => /^[0-9]+$/.test(value);

  useEffect(() => {
    if (!validateState(initialData.address_state)) {
      setData({
        ...initialData,
        address_state: null,
      });
    } else {
      setData(initialData);
    }
  }, []);

  useEffect(() => {
    if (submitted && !loading && !error) {
      onClose();
    }
  }, [loading]);

  const setAddressFields = (field, value) => {
    setData({
      ...data,
      [field.id]: value,
    });
  };

  const isAllFieldsFilledOut = (
    validateField(data.first_name)
    && validateField(data.last_name)
    && validateField(data.company_name)
    && validateField(data.address_city)
    && validateField(data.address_line1)
    && validateZipCode(data.address_zipcode)
    && !!data.address_state
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAllFieldsFilledOut) {
      setFieldsError(false);
      setSubmitted(true);
      if (!loading) {
        const formattedData = Object.keys(data).reduce((acc, key) => {
          return {
            ...acc,
            [key]: data[key] ? formatInput(data[key]) : data[key],
          };
        }, {});
        onSubmit(formattedData);
      }
    } else {
      setFieldsError(true);
    }
  };

  const renderInput = (field) => (
    <label
      htmlFor={field.id}
      id={field.id}
      key={field.id}
      className={cx(styles.label, {
        [styles.labelZipcode]: field.id === "address_zipcode",
        [styles.labelCity]: field.id === "address_city",
      })}
    >
      {field.label}
      <input
        {...field}
        className={styles.input}
        name={field.id}
        value={data[field.id]}
        onChange={(e) => setAddressFields(field, e.target.value)}
      />
    </label>
  );

  const renderSelect = (field) => (
    <label
      htmlFor={field.id}
      id={field.id}
      key={field.id}
      className={styles.label}
    >
      {field.label}
      <StyledSelect
        value={{
          label: data[field.id],
          value: data[field.id],
        }}
        options={field.id === "address_state"
          ? convertArrayToSelectOptions(US_STATES)
          : convertArrayToSelectOptions([BUSINESS_CUSTOMER_TYPE, INDIVIDUAL_CUSTOMER_TYPE])}
        className={styles.select}
        isOptionDisabled={(option) => option.selectable === false}
        withEmptyOption={field.id === "address_state"}
        onChange={(e) => setAddressFields(field, e.value)}
      />
    </label>
  );

  return (
    <Modal
      title={title}
      size="small"
      isForm
      loading={loading}
      cancelButtonText="Cancel"
      submitButtonText={submitButtonText}
      onCancel={onClose}
      onSubmit={handleSubmit}
    >
      {loading ? (
        <span className={styles.loader}>
          Loading...
        </span>
      ) : (
        fields.map((field) => {
          if (field.id === "first_name" || field.id === "last_name") {
            if (data.customer_type === "individual" || !data.customer_type) {
              return renderInput(field);
            }
            return;
          }
          if (field.id === "company_name") {
            if (data.customer_type === "business") {
              return renderInput(field);
            }
            return;
          }
          if (field.id === "customer_type" || field.id === "address_state") {
            return renderSelect(field);
          }
          return renderInput(field);
        })
      )}
      {fieldsError && (
        <div className={styles.error}>
          All fields must be filled out.
        </div>
      )}
      {error && renderDataEditionErrors(error)}
    </Modal>
  );
};

DataEditionModal.propTypes = {
  onClose: func.isRequired,
  title: string.isRequired,
  fields: arrayOf(customerFieldsPropType).isRequired,
  initialData: objectOf(node),
  onSubmit: func.isRequired,
  loading: bool.isRequired,
  error: oneOfType([string, arrayOf(string)]).isRequired,
  submitButtonText: string,
};

DataEditionModal.defaultProps = {
  initialData: {},
  submitButtonText: "Create",
};

export default DataEditionModal;
