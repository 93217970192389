import { partition } from "ramda";
import {
  AVAILABLE_RECALLS_STEP,
  BOOKING_SUMMARY_STEP,
  FINAL_STEP,
} from "shared/constants/checkin-steps";

import {
  chatIsAppointmentFinalizedSelector,
  servicesSelector,
} from "store/selectors/checkin-chat-selectors";

import {
  CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_ERROR,
  CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_SUCCESS,
  CHECKIN_CHAT_FETCH_REPAIR_ORDER_DETAILS,
  CHECKIN_CHAT_SET_AVAILABLE_RECALLS,
  CHECKIN_CHAT_SET_PRESELECTED_RECALLS,
  initializeChat,
  initializeErrorStep,
  initializeStep,
  setLoadingState,
} from "store/actions/checkin-chat-actions";

import { BOOKING_DETAILS_SET_CHECKED_IN_STATUS } from "./booking-details-actions";

export const CHECKIN_APP_EXIT = "CHECKIN_APP_EXIT";
export const CHECKIN_APP_NOT_SYNCED = "not_synced";
export const CHECKIN_APP_BACK_FROM_CHAT = "back_from_chat";

export const CHECKIN_APP_FETCH_APPOINTMENT_RESPONSE_TYPE = "import_appointment";
export const CHECKIN_APP_CONVERT_APPOINTMENT_TO_RO_RESPONSE_TYPE = "add_repair_order";
export const CHECKIN_APP_UPDATE_APPOINTMENT_RESPONSE_TYPE = "update_appointment";
export const CHECKIN_APP_CANCEL_APPOINTMENT_RESPONSE_TYPE = "cancel_appointment";
export const CHECKIN_APP_IMPORT_RECALLS_RESPONSE_TYPE = "import_recalls";

export const actionCableListener = (message) => (dispatch, getState) => {
  if (process.env.NODE_ENV === "development") {
    console.log("Action cable response: ", message); // eslint-disable-line no-console
  }
  if (message.id) {
    dispatch({
      type: CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_SUCCESS,
      payload: message,
    });
  }
  const { action, data, errors } = message;

  switch (action) {
    case CHECKIN_APP_FETCH_APPOINTMENT_RESPONSE_TYPE:
      if (data) {
        dispatch({
          type: CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_SUCCESS,
          payload: data,
        });

        dispatch(initializeChat());
      } else {
        dispatch({
          type: CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_ERROR,
          payload: {
            errors,
          },
        });
      }
      break;
    case CHECKIN_APP_IMPORT_RECALLS_RESPONSE_TYPE:
      dispatch(setLoadingState(false));

      if (data.length > 0) {
        const [preselectedRecalls, uniqueRecalls] = partition(
          (recall) => servicesSelector(getState()).bookingsMenuItems.find(
            (bmi) => bmi.recall_id === recall.id,
          ),
          data,
        );
        dispatch({
          type: CHECKIN_CHAT_SET_PRESELECTED_RECALLS,
          payload: { preselectedRecalls },
        });

        if (uniqueRecalls.length > 0) {
          dispatch({
            type: CHECKIN_CHAT_SET_AVAILABLE_RECALLS,
            payload: { recalls: uniqueRecalls },
          });
          dispatch(initializeStep(AVAILABLE_RECALLS_STEP));
        } else {
          dispatch(initializeStep(BOOKING_SUMMARY_STEP));
        }
      } else {
        dispatch(initializeStep(BOOKING_SUMMARY_STEP));
      }
      break;
    case CHECKIN_APP_CONVERT_APPOINTMENT_TO_RO_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      if (data) {
        dispatch({
          type: CHECKIN_CHAT_FETCH_REPAIR_ORDER_DETAILS,
          payload: data,
        });
        dispatch(initializeStep(FINAL_STEP));
      } else {
        dispatch(initializeErrorStep(errors, true));
      }
      break;

    case CHECKIN_APP_UPDATE_APPOINTMENT_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      if (data) {
        dispatch({
          type: CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_SUCCESS,
          payload: data,
        });
        if (chatIsAppointmentFinalizedSelector(getState())) {
          dispatch(initializeStep(FINAL_STEP));
          dispatch({
            type: BOOKING_DETAILS_SET_CHECKED_IN_STATUS,
            payload: true,
          });
        }
      } else if (chatIsAppointmentFinalizedSelector(getState())) {
        dispatch(initializeErrorStep(errors, true));
      } else {
        console.error(errors);
      }
      break;
    case CHECKIN_APP_CANCEL_APPOINTMENT_RESPONSE_TYPE:
      break;

    default:
      break;
  }
};

export const exit = () => (dispatch) => {
  dispatch({
    type: CHECKIN_APP_EXIT,
    payload: {},
  });
};
