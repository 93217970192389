import React, { useState } from "react";
import {
  bool, func, number, string,
} from "prop-types";
import { connect } from "react-redux";
import { setCancelChanges, updateTemplate } from "store/actions/menu-templates-actions";
import Switch from "react-switch";
import Modal from "components/common/Modal";

import styles from "./styles.module.scss";

const EditMenuTemplateModal = ({
  id,
  name,
  ignoreVehicleMileage,
  isActive,
  onClose,
  onSubmit,
  cancelChanges,
}) => {
  const [templateName, setTemplateName] = useState(name);
  const [templateIsActive, setTemplateIsActive] = useState(isActive);
  const [ignoreMileage, setIgnoreMileage] = useState(ignoreVehicleMileage);
  const [errorMessage, setErrorMessage] = useState([]);

  const submit = () => {
    const params = {
      id,
      templateName,
      templateIsActive,
      ignoreMileage,
    };
    onSubmit(id, params)
      .then(() => onClose())
      .catch(({ response: { data } }) => setErrorMessage({
        name: `Name ${data.name}`,
      }));
  };

  const handleClose = () => {
    cancelChanges();
    onClose();
  };

  return (
    <Modal
      title="Edit"
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="small"
      onCancel={handleClose}
      onSubmit={submit}
      className={styles.editTemplateModal}
    >
      <div className={styles.label}>
        Template name
      </div>
      <input
        className={styles.input}
        value={templateName}
        onChange={(e) => setTemplateName(e.target.value)}
      />
      {errorMessage && (
        <span>
          {errorMessage.name}
        </span>
      )}
      <div className={styles.switch}>
        <div className={styles.label}>
          Active
        </div>
        <Switch
          id="activity-switch"
          onChange={() => setTemplateIsActive(!templateIsActive)}
          checked={templateIsActive}
          onColor="#36af5e"
          offColor="#dedee0"
          activeBoxShadow="0 0 2px 3px #0bcaf9"
          aria-labelledby="activity-label"
        />
      </div>
      <div className={styles.switch}>
        <div className={styles.label}>
          Ignore vehicle mileage and show all maintenance items to advisors
        </div>
        <Switch
          id="ignore-mileage-switch"
          onChange={() => setIgnoreMileage(!ignoreMileage)}
          checked={ignoreMileage}
          onColor="#36af5e"
          offColor="#dedee0"
          activeBoxShadow="0 0 2px 3px #0bcaf9"
          aria-labelledby="ignore-mileage--label"
        />
      </div>
    </Modal>
  );
};

EditMenuTemplateModal.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  ignoreVehicleMileage: bool.isRequired,
  isActive: bool.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  cancelChanges: func.isRequired,
};

const actions = {
  onSubmit: updateTemplate,
  cancelChanges: setCancelChanges,
};

export default connect(null, actions)(EditMenuTemplateModal);
