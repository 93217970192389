import { connect } from "react-redux";
import {
  fetchTransport,
  setClientTransport,
  setClientWaiting,
  setCurrentStep,
} from "store/actions/booking-details-actions";

import {
  bookingAvailableLoanersSelector,
  bookingDetailsAvailableTransportErrorSelector,
  bookingDetailsAvailableTransportLoadingSelector,
  bookingDetailsAvailableTransportSelector,
  bookingDetailsChosenTimeSlotSelector,
  bookingDetailsClientWaitingSelector,
  bookingDetailsCurrentStepSelector,
  bookingDetailsIsPickUpSelector,
  bookingDetailsSelectedTransportSelector,
  bookingDetailsWaiterAllowed,
} from "store/selectors/booking-details-selectors";

import Transportation from "./index";

const mapStateToProps = (state) => {
  return {
    clientWaiting: bookingDetailsClientWaitingSelector(state),
    selectedTransport: bookingDetailsSelectedTransportSelector(state),
    chosenTimeSlot: bookingDetailsChosenTimeSlotSelector(state),
    currentStep: bookingDetailsCurrentStepSelector(state),
    availableTransports: bookingDetailsAvailableTransportSelector(state),
    availableTransportsLoading: bookingDetailsAvailableTransportLoadingSelector(state),
    availableTransportsLoadingError: bookingDetailsAvailableTransportErrorSelector(state),
    waiterAllowed: bookingDetailsWaiterAllowed(state),
    availableLoaners: bookingAvailableLoanersSelector(state),
    isPickUp: bookingDetailsIsPickUpSelector(state),
  };
};

const actions = {
  setIsWaiting: setClientWaiting,
  setTransport: setClientTransport,
  setSchedulingStep: setCurrentStep,
  fetchAvailableTransport: fetchTransport,
};

export default connect(mapStateToProps, actions)(Transportation);
