import { connect } from "react-redux";

import {
  schedulingChosenTimeSlotSelector,
  schedulingCurrentCustomerSelector,
  schedulingCurrentVehicleSelector,
  schedulingInternalNoteSelector,
  schedulingIsDropOffSelector,
  schedulingIsPickUpSelector,
  schedulingProcessContactNumberSelector,
  schedulingProcessJobReachable,
  schedulingProcessSendSmsSelector,
  schedulingRemoteJobDataSelector,
  schedulingSelectedAdvisorSelector,
  schedulingSelectedRecallsSelector,
  schedulingSelectedServicesSelector,
  schedulingisMobileTechnicianSelector,
} from "store/selectors/scheduling-process-selectors";
import { settingsCapacityTimeBufferSelector, settingsDrivingTimeBufferSelector } from "store/selectors/settings-selectors";

import AppointmentDetails from "./index";

const mapStateToProps = (state) => {
  return {
    selectedServices: schedulingSelectedServicesSelector(state),
    selectedRecalls: schedulingSelectedRecallsSelector(state),
    selectedAdvisor: schedulingSelectedAdvisorSelector(state),
    vehicle: schedulingCurrentVehicleSelector(state),
    customer: schedulingCurrentCustomerSelector(state),
    internalNote: schedulingInternalNoteSelector(state),
    chosenTimeSlot: schedulingChosenTimeSlotSelector(state),
    sendSms: schedulingProcessSendSmsSelector(state),
    contactNumber: schedulingProcessContactNumberSelector(state),
    isPickUp: schedulingIsPickUpSelector(state),
    isDropOff: schedulingIsDropOffSelector(state),
    isMobileTechnician: schedulingisMobileTechnicianSelector(state),
    remoteJobData: schedulingRemoteJobDataSelector(state),
    jobReachable: schedulingProcessJobReachable(state),
    capacityBufferTime: settingsCapacityTimeBufferSelector(state),
    driverBufferTime: settingsDrivingTimeBufferSelector(state),
  };
};

export default connect(mapStateToProps)(AppointmentDetails);
