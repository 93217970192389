import { connect } from "react-redux";
import { removeDriver, updateDriver } from "store/actions/settings-actions";
import EmployeeSettingsActionCell from "./index";

const actions = {
  updateEmployee: updateDriver,
  removeEmployee: removeDriver,
};

export default connect(null, actions)(EmployeeSettingsActionCell);
