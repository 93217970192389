import React from "react";
import { bool, func } from "prop-types";
import Button from "components/common/Button";

import "./styles.scss";

const HeaderEdit = ({
  isEditing,
  handleCancel,
  handleSave,
  handleEdit,
  isSaveDisabled,
}) => (
  <div className="buttonsContainer">
    {
      isEditing ? (
        <>
          <Button
            variant="dark-outline"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="brand"
            onClick={handleSave}
            disabled={isSaveDisabled}
            style={{ marginLeft: "16px" }}
          >
            Save
          </Button>
        </>
      )
        : (
          <Button
            variant="dark"
            leftIcon="editWhite"
            onClick={handleEdit}
          >
            Edit
          </Button>
        )
    }
  </div>
);

HeaderEdit.propTypes = {
  isEditing: bool,
  handleCancel: func,
  handleEdit: func,
  handleSave: func,
  isSaveDisabled: bool,
};

HeaderEdit.defaultProps = {
  isEditing: false,
  handleCancel: () => {},
  handleEdit: () => {},
  handleSave: () => {},
  isSaveDisabled: false,
};

export default HeaderEdit;
