import React, { useEffect, useState } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { savePackageItems } from "store/actions/menu-template-actions";
import { menuItemPropType, menuTemplatePropType } from "shared/prop-types";
import Button from "components/common/Button";

import styles from "./styles.module.scss";

const PackageItem = ({
  menuItem,
  menuTemplate,
  saveItems,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [packageItems, setPackageItems] = useState(menuItem.package_items);

  useEffect(() => {
    setPackageItems(menuItem.package_items);
  }, [menuItem]);

  const availablePackageItem = (item) => (
    !packageItems.find((pi) => pi.id === item.id)
    && item.id !== menuItem.id
    && item.package_items.length === 0
  );

  const options = (items) => (
    items
      .filter((i) => availablePackageItem(i))
      .map((pi) => {
        return {
          packageItem: pi,
          value: pi.id,
          label: pi.name,
        };
      })
  );

  const save = () => {
    saveItems(menuItem.id, packageItems);
    setIsEditing(false);
  };

  const handlePackageItemChange = (selectedItems) => {
    setPackageItems(selectedItems.map((vs) => vs.packageItem));
  };

  const { kind } = menuItem;
  const { menu_items: groupedMenuItems } = menuTemplate;
  const menuItems = groupedMenuItems[kind];

  return (
    <div className={styles.packageItem}>
      <div className={styles.input}>
        <span className={styles.label}>Package items</span>
        <Select
          isMulti
          value={packageItems.map((pi) => {
            return {
              packageItem: pi,
              value: pi.id,
              label: pi.name,
            };
          })}
          options={options(menuItems)}
          isDisabled={!isEditing}
          onChange={handlePackageItemChange}
          className={styles.select}
          classNamePrefix="select"
        />
      </div>
      <div className="display-flex flex-end full-width">
        {isEditing && (
          <Button
            variant="brand"
            onClick={save}
          >
            Save
          </Button>
        )}
        {!isEditing && (
          <Button
            variant="dark"
            leftIcon="editWhite"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        )}
      </div>
    </div>
  );
};

PackageItem.propTypes = {
  menuTemplate: menuTemplatePropType.isRequired,
  menuItem: menuItemPropType.isRequired,
  saveItems: func.isRequired,
};

const actions = {
  saveItems: savePackageItems,
};

export default connect(null, actions)(PackageItem);
