import * as moment from "moment-timezone";
import {
  ADDITIONAL_SERVICES_STEP,
  BOOKING_CONFIRMATION_STEP,
  BOOKING_SUMMARY_STEP,
  ERROR_STEP,
  GREETING_STEP,
  SIGNATURE_STEP,
} from "shared/constants/checkin-steps";

import {
  appCheckinIdSelector,
  appDealershipIdSelector,
  appDealershipTimeZoneSelector,
} from "store/selectors/checkin-app-selectors";

import {
  chatAdditionalServicesSelector,
  chatAppointmentIdSelector,
  chatCurrentVehicleSelector,

  chatDecisionTreesSelector,
  chatLastRequestSelector,
  chatOrderSelector,
  chatServiceIdsSelector,
  chatSignatureSelector,
} from "store/selectors/checkin-chat-selectors";

import { DELAY_1000, DELAY_2000, DELAY_500 } from "shared/constants/delays";
import { formatInput } from "shared/utils/common";

import {
  fetchAdditionalServices,
  fetchAppointmentByGuid,
  fetchDecisionTrees,
  fetchRecallsForCheckin,
  fetchServices,
  fetchUpdateAppointment,
  sendSignature,
  startCheckIn,
} from "shared/apiCheckin";

// eslint-disable-next-line import/no-cycle
import { initializeDecisionTree } from "store/actions/checkin-decision-trees-actions";
import { dealershipIdSelector } from "store/selectors/app-selectors";

export const CHECKIN_CHAT_STORE_DEALERSHIP_SLUG = "CHECKIN_CHAT_STORE_DEALERSHIP_SLUG";
export const CHECKIN_CHAT_CHANGE_STEP = "CHECKIN_CHAT_CHANGE_STEP";
export const CHECKIN_CHAT_UPDATE_CURRENT_STEP = "CHECKIN_CHAT_UPDATE_CURRENT_STEP";
export const CHECKIN_CHAT_UPDATE_BOOKING_STEP = "CHECKIN_CHAT_UPDATE_BOOKING_STEP";
export const CHECKIN_CHAT_SET_CHAT_LOADING_STATE = "CHECKIN_CHAT_SET_CHAT_LOADING_STATE";
export const CHECKIN_CHAT_INITIALIZE = "CHECKIN_CHAT_INITIALIZE";
export const CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS = "CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS";
export const CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_SUCCESS = "CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_SUCCESS";
export const CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_ERROR = "CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_ERROR";
export const CHECKIN_CHAT_FETCH_SERVICES_SUCCESS = "CHECKIN_CHAT_FETCH_SERVICES_SUCCESS";
export const CHECKIN_CHAT_REMOVE_SERVICE = "CHECKIN_CHAT_REMOVE_SERVICE";
export const CHECKIN_CHAT_REMOVE_LINE_ITEM = "CHECKIN_CHAT_REMOVE_LINE_ITEM";
export const CHECKIN_CHAT_ADD_SERVICES = "CHECKIN_CHAT_ADD_SERVICES";
export const CHECKIN_CHAT_FETCH_DECISION_TREES = "CHECKIN_CHAT_FETCH_DECISION_TREES";
export const CHECKIN_CHAT_SET_ADDITIONAL_NOTES = "CHECKIN_CHAT_SET_ADDITIONAL_NOTES";
export const CHECKIN_CHAT_SET_ADDITIONAL_SERVICES = "CHECKIN_CHAT_SET_ADDITIONAL_SERVICES";
export const CHECKIN_CHAT_TOGGLE_EXTENSION = "CHECKIN_CHAT_TOGGLE_EXTENSION";
export const CHECKIN_CHAT_SET_AVAILABLE_RECALLS = "CHECKIN_CHAT_SET_AVAILABLE_RECALLS";
export const CHECKIN_CHAT_SET_PRESELECTED_RECALLS = "CHECKIN_CHAT_SET_PRESELECTED_RECALLS";
export const CHECKIN_CHAT_TOGGLE_RECALL = "CHECKIN_CHAT_TOGGLE_RECALL";
export const CHECKIN_CHAT_UPDATE_SIGNATURE = "CHECKIN_CHAT_UPDATE_SIGNATURE";
export const CHECKIN_CHAT_START_CHECKIN_SUCCESS = "CHECKIN_CHAT_START_CHECKIN_SUCCESS";
export const CHECKIN_CHAT_FETCH_REPAIR_ORDER_DETAILS = "CHECKIN_CHAT_FETCH_REPAIR_ORDER_DETAILS";
export const CHECKIN_CHAT_SET_LAST_REQUEST = "CHECKIN_CHAT_SET_LAST_REQUEST";
export const CHECKIN_CHAT_CLEAR_ADDITIONAL_SERVICES = "CHECKIN_CHAT_CLEAR_ADDITIONAL_SERVICES";
export const CHECKIN_CHAT_CLEAR_SELECTED_RECALLS = "CHECKIN_CHAT_CLEAR_SELECTED_RECALLS";
export const CHECKIN_CHAT_FINALIZE_APPOINTMENT = "CHECKIN_CHAT_FINALIZE_APPOINTMENT";

export const setLastRequest = (request) => {
  return {
    type: CHECKIN_CHAT_SET_LAST_REQUEST,
    payload: { request },
  };
};

export const initializeStep = (step, props, inputProps) => {
  return {
    type: CHECKIN_CHAT_CHANGE_STEP,
    payload: {
      step,
      props,
      inputProps,
    },
  };
};

export const setLoadingState = (value = true, delay = DELAY_500) => {
  return {
    type: CHECKIN_CHAT_SET_CHAT_LOADING_STATE,
    payload: {
      value,
      delay,
    },
  };
};

export const replayLastRequest = () => (dispatch, getState) => {
  dispatch(setLoadingState());
  chatLastRequestSelector(getState())();
};

export const updateCurrentStep = (props, inputProps) => {
  return {
    type: CHECKIN_CHAT_UPDATE_CURRENT_STEP,
    payload: {
      props,
      inputProps,
    },
  };
};
export const updateBookingStep = (props, inputProps) => {
  return {
    type: CHECKIN_CHAT_UPDATE_BOOKING_STEP,
    payload: {
      props,
      inputProps,
    },
  };
};

export const initializeErrorStep = (error, canRetry) => (dispatch) => {
  console.error(error);
  const message = error instanceof Object ? error.message : error;
  dispatch(
    initializeStep(ERROR_STEP, {
      error: message,
      canRetry,
    }, { canRetry }),
  );
  dispatch(setLoadingState(false));
};

export const updateAppointment = (state, date) => {
  const { order, decisionTreeResults } = state.checkinChat;
  const orderDate = moment
    .tz(
      date || order.date,
      "dddd, MMMM DD YYYY, h:mm A",
      appDealershipTimeZoneSelector(state),
    )
    .format();

  const menu_item_comments = decisionTreeResults.map(({ serviceId, description }) => {
    return {
      id: serviceId,
      comment: formatInput(description),
    };
  });

  return fetchUpdateAppointment(
    appCheckinIdSelector(state),
    chatAppointmentIdSelector(state),
    appDealershipIdSelector(state),
    {
      ...order,
      date: orderDate,
    },
    [
      ...chatServiceIdsSelector(state),
      ...order.additionalServices.map((s) => s.id),
    ],
    menu_item_comments,
  );
};

const finalizeAppointment = () => (dispatch, getState) => {
  const request = () => updateAppointment(getState())
    .then(() => dispatch({
      type: CHECKIN_CHAT_FINALIZE_APPOINTMENT,
      payload: true,
    }))
    .catch((error) => dispatch(initializeErrorStep(error, true)));
  dispatch(setLastRequest(request));
  request();
};

export const initializeCheckIn = ({ dealershipSlug, guid } = {}) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());

  if (!dealershipSlug && !dealershipId) {
    return console.error(" no dealership_slug or dealership_id provided");
  }
  dispatch({
    type: CHECKIN_CHAT_STORE_DEALERSHIP_SLUG,
    payload: dealershipSlug,
  });
  try {
    const response = await startCheckIn(dealershipSlug, dealershipId, guid);
    dispatch({
      type: CHECKIN_CHAT_START_CHECKIN_SUCCESS,
      payload: response,
    });
  } catch (err) {
    console.error(err);
  }
};

export const importAppointmentByGuid = (guid) => (dispatch, getState) => {
  dispatch(setLoadingState());
  dispatch({ type: CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS });
  fetchAppointmentByGuid(appCheckinIdSelector(getState()), guid);
};

export const initializeChat = () => async (dispatch, getState) => {
  dispatch({
    type: CHECKIN_CHAT_INITIALIZE,
    payload: {},
  });
  dispatch(initializeStep(GREETING_STEP));
  dispatch(setLoadingState(true, DELAY_2000));
  const services = await fetchServices(
    appCheckinIdSelector(getState()),
    appDealershipIdSelector(getState()),
    chatCurrentVehicleSelector(getState()),
  );
  dispatch({
    type: CHECKIN_CHAT_FETCH_SERVICES_SUCCESS,
    payload: services,
  });
  dispatch(setLoadingState(false));
  dispatch(initializeStep(BOOKING_CONFIRMATION_STEP));
};

export const initializeFinalStep = () => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState());
  dispatch(finalizeAppointment());
};

export const editBooking = () => (dispatch) => {
  dispatch(updateCurrentStep({ isEditing: true }));
  dispatch(updateBookingStep({ isEditing: true }));
};
export const undoEditBooking = () => (dispatch) => {
  dispatch(updateCurrentStep({ isEditing: false }));
  dispatch(updateBookingStep({ isEditing: false }));
};
export const openServiceSelector = (kind) => (dispatch) => {
  dispatch(updateCurrentStep({}, { serviceKind: kind }));
  dispatch(updateBookingStep({}, { serviceKind: kind }));
};

export const closeServiceSelector = () => (dispatch) => {
  dispatch(updateCurrentStep({}, { serviceKind: null }));
  dispatch(updateBookingStep({ isEditing: true }, { serviceKind: null }));
};
export const removeService = (id) => {
  return {
    type: CHECKIN_CHAT_REMOVE_SERVICE,
    payload: { id },
  };
};
export const removeLineItem = (id) => {
  return {
    type: CHECKIN_CHAT_REMOVE_LINE_ITEM,
    payload: { id },
  };
};
export const addServices = (newServices) => {
  return {
    type: CHECKIN_CHAT_ADD_SERVICES,
    payload: newServices,
  };
};

export const selectAdditionalServices = (selectedServices) => (
  dispatch,
  getState,
) => {
  if (selectedServices === null) {
    dispatch({ type: CHECKIN_CHAT_CLEAR_ADDITIONAL_SERVICES });
  }

  const checkinId = appCheckinIdSelector(getState());
  const request = () => fetchRecallsForCheckin(checkinId).catch(
    (error) => dispatch(initializeErrorStep(error, true)),
  );

  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState());
  dispatch(setLastRequest(request));
  request();
};

export const goToAdditionalServicesStep = () => (dispatch, getState) => {
  const additionalServices = chatAdditionalServicesSelector(getState());
  if (additionalServices.length) {
    dispatch(
      initializeStep(ADDITIONAL_SERVICES_STEP, { services: additionalServices }),
    );
  } else {
    const request = () => fetchAdditionalServices(
      appCheckinIdSelector(getState()),
      appDealershipIdSelector(getState()),
      chatCurrentVehicleSelector(getState()),
    )
      .then((payload) => {
        dispatch({
          type: CHECKIN_CHAT_SET_ADDITIONAL_SERVICES,
          payload,
        });
        const services = (payload.extension || []).filter(
          (s) => s.fee,
        );
        if (services.length) {
          dispatch(initializeStep(ADDITIONAL_SERVICES_STEP, { services }));
        } else {
          dispatch(selectAdditionalServices());
        }
      })
      .catch((error) => dispatch(initializeErrorStep(error, true)))
      .finally(() => dispatch(setLoadingState(false)));
    dispatch(setLastRequest(request));
    request();
  }
};

export const confirmBooking = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({
    isComplete: true,
    isEditing: false,
  }));
  dispatch(setLoadingState());

  const request = () => fetchDecisionTrees(
    appCheckinIdSelector(getState()),
    chatServiceIdsSelector(getState()),
  )
    .then((response) => {
      if (response.length > 0) {
        dispatch(setLoadingState(false));
        dispatch({
          type: CHECKIN_CHAT_FETCH_DECISION_TREES,
          payload: response,
        });
        const firstTree = chatDecisionTreesSelector(getState())[0];

        const { decisionTreeResults } = chatOrderSelector(getState());
        const isFilled = (decisionTreeResults || []).find(
          (decisionTree) => decisionTree.menu_item_id === firstTree.serviceId,
        );

        setTimeout(
          () => dispatch(
            initializeDecisionTree(chatDecisionTreesSelector(getState())[0], !!isFilled),
          ),
          DELAY_500,
        );
      } else {
        setTimeout(() => dispatch(goToAdditionalServicesStep()), DELAY_1000);
      }
    })
    .catch((error) => dispatch(initializeErrorStep(error, true)));
  dispatch(setLastRequest(request));
  request();
};

export const addNotes = (notes) => (dispatch) => {
  if (notes) {
    dispatch({
      type: CHECKIN_CHAT_SET_ADDITIONAL_NOTES,
      payload: { notes },
    });
  }
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState());
  dispatch(goToAdditionalServicesStep());
};

export const toggleExtension = (service) => {
  return {
    type: CHECKIN_CHAT_TOGGLE_EXTENSION,
    payload: { service },
  };
};

export const toggleRecall = (service) => {
  return {
    type: CHECKIN_CHAT_TOGGLE_RECALL,
    payload: { service },
  };
};

export const selectRecalls = (selectedRecalls) => (dispatch) => {
  if (selectedRecalls === null) {
    dispatch({ type: CHECKIN_CHAT_CLEAR_SELECTED_RECALLS });
  }
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(initializeStep(BOOKING_SUMMARY_STEP));
};

export const confirmOrder = () => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(initializeStep(SIGNATURE_STEP));
};

export const updateSignature = (signature) => {
  return {
    type: CHECKIN_CHAT_UPDATE_SIGNATURE,
    payload: { signature },
  };
};

export const confirmSignature = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({}, { isComplete: true }));
  const request = () => sendSignature(
    appCheckinIdSelector(getState()),
    chatAppointmentIdSelector(getState()),
    chatSignatureSelector(getState()),
  )
    .then(() => {
      dispatch(
        updateCurrentStep({
          isComplete: true,
          signature: chatSignatureSelector(getState()),
        }),
      );
      dispatch(initializeFinalStep());
    })
    .catch((error) => dispatch(initializeErrorStep(error, true)));
  dispatch(setLastRequest(request));
  request();
};
