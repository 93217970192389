import axios from "axios";

const GET = "GET";
const POST = "POST";
const PATCH = "PATCH";
const PUT = "PUT";
const DELETE = "DELETE";

const SESSION_TOKEN_PATH = "/oauth/token";
const ADMIN_API_PATH = "/admin";
const DEALERSHIPS_PATH = "/dealerships";
const DECISION_TREES_PATH = "/decision_trees";
const VEHICLE_SETS_PATH = "/vehicle_sets";

const authorizationHeader = (token) => (token ? { Authorization: `Bearer ${token}` } : {});
const dealershipPath = (dealershipId) => `${DEALERSHIPS_PATH}/${dealershipId}`;
const dealershipCapacityPath = (dealershipId) => `${dealershipPath(dealershipId)}/capacity`;
const dealershipUpdateCapacityPath = (dealershipId) => `${dealershipPath(dealershipId)}/update_capacity_settings`;
const advisorsPath = (dealershipId) => `${dealershipPath(dealershipId)}/service_advisors`;
const advisorsBulkUpdatePath = (dealershipId) => `${dealershipPath(dealershipId)}/service_advisors/bulk_update`;
const employmentsPath = (dealershipId) => `${dealershipPath(dealershipId)}/employments`;
const employmentPath = (dealershipId, employmentId) => `${employmentsPath(dealershipId)}/${employmentId}`;
const employmentsAccessPath = (dealershipId, isLocking) => `${dealershipPath(dealershipId)}/employments/${isLocking ? "lock" : "unlock"}`;
const employmentVacationsPath = (dealershipId, employmentId) => `${employmentPath(dealershipId, employmentId)}/vacations`;
const employmentVacationsUpdatePath = (dealershipId, employmentId, vacationId) => `${employmentVacationsPath(dealershipId, employmentId)}/${vacationId}`;
const kiosksPath = (dealershipId) => `${dealershipPath(dealershipId)}/users/kiosk`;
const kioskPath = (dealershipId, kioskId) => `${kiosksPath(dealershipId)}/${kioskId}`;
const tvScreensPath = (dealershipId) => `${dealershipPath(dealershipId)}/users/tv_screen`;
const tvScreenPath = (dealershipId, tvScreenId) => `${tvScreensPath(dealershipId)}/${tvScreenId}`;
const teamTagsPath = (dealershipId) => `${dealershipPath(dealershipId)}/team_tags`;
const teamTagsSeniorityPath = (dealershipId) => `${teamTagsPath(dealershipId)}/update_seniority`;
const teamTagPath = (dealershipId, tagId) => `${teamTagsPath(dealershipId)}/${tagId}`;
const teamTagCapacityPath = (dealershipId, tagId) => `${teamTagsPath(dealershipId)}/${tagId}/capacity`;
const teamTagDayLockPath = (dealershipId, tagId) => `${teamTagsPath(dealershipId)}/${tagId}/days/lock`;
const teamTagDayUnlockPath = (dealershipId, tagId) => `${teamTagsPath(dealershipId)}/${tagId}/days/unlock`;
const reportsEfficiencyPath = (dealershipId) => `${dealershipPath(dealershipId)}/reports/efficiency`;
const reportsGeneralPath = (dealershipId) => `${dealershipPath(dealershipId)}/reports/general`;
const reportsCustomersActivityPath = (dealershipId) => `${dealershipPath(dealershipId)}/reports/customers_activity`;
const reportsEmployeePath = (dealershipId) => `${dealershipPath(dealershipId)}/reports/employee_efficiency`;
const bookingsPath = (dealershipId) => `${dealershipPath(dealershipId)}/bookings`;
const bookingPath = (dealershipId, bookingId) => `${bookingsPath(dealershipId)}/${bookingId}`;
const checksJobDistancePath = (dealershipId) => `${dealershipPath(dealershipId)}/jobs/is_reachable`;
const servicesPath = (dealershipId) => `${dealershipPath(dealershipId)}/services`;
const menuTemplatesPath = (dealershipId) => `${dealershipPath(dealershipId)}/menu_templates`;
const fetchVehicleSetByVinPath = (dealershipId) => `${dealershipPath(dealershipId)}/vehicle_sets/vin`;
const fetchCdkModelCodePath = (dealershipId) => `${dealershipPath(dealershipId)}/vehicles/model_code_by_vin`;
const fetchCdkModelCodeSuggestionsPath = (dealershipId) => `${dealershipPath(dealershipId)}/vehicles/model_code_suggestions`;
const fetchVehicleDataPath = (dealershipId, id) => `${dealershipPath(dealershipId)}/vehicles/${id}`;
const fetchVehicleROPath = (dealershipId, id) => `${fetchVehicleDataPath(dealershipId, id)}/repair_orders`;
const menuTemplatePath = (dealershipId, id) => `${menuTemplatesPath(dealershipId)}/${id}`;
const duplicateTemplatePath = (dealershipId, id) => `${menuTemplatePath(dealershipId, id)}/duplicate`;
const switchTemplatePath = (dealershipId, id) => `${menuTemplatePath(dealershipId, id)}/switch`;
const integrationsPath = (dealershipId) => `${dealershipPath(dealershipId)}/integrations`;
const createDealershipMaintainerPath = (dealershipId) => `${dealershipPath(dealershipId)}/maintainers`;
const updateDealershipMaintainerPath = (dealershipId, userId) => `${dealershipPath(dealershipId)}/maintainers/${userId}`;

const updateIntegrationPath = (dealershipId, integrationId) => (
  `${dealershipPath(dealershipId)}/integrations/${integrationId}`
);
const updateDmsIntegrationPath = (dealershipId, integrationId) => (
  `${dealershipPath(dealershipId)}/integrations/${integrationId}/dms`
);
const updateLoanerIntegrationPath = (dealershipId, integrationId) => (
  `${dealershipPath(dealershipId)}/integrations/${integrationId}/loaner`
);
const updateMobileTechnicianIntegrationPath = (dealershipId, integrationId) => (
  `${dealershipPath(dealershipId)}/integrations/${integrationId}/mobile_technician`
);
const updatePickupIntegrationPath = (dealershipId, integrationId) => (
  `${dealershipPath(dealershipId)}/integrations/${integrationId}/pickup`
);
const menuItemsPath = (dealershipId) => `${dealershipPath(dealershipId)}/menu_items`;
const menuItemPath = (dealershipId, id) => `${dealershipPath(dealershipId)}/menu_items/${id}`;
const vehicleGroupsPath = (dealershipId) => `${dealershipPath(dealershipId)}/menu_item_targets`;
const vehicleGroupPath = (dealershipId, id) => `${dealershipPath(dealershipId)}/menu_item_targets/${id}`;
const decisionTreesPath = (dealershipId) => `${dealershipPath(dealershipId)}/decision_trees`;
const decisionTreePath = (dealershipId, id) => `${dealershipPath(dealershipId)}/decision_trees/${id}`;
const updateMenuTemplateItemsOrderPath = (dealershipId, id) => `${dealershipPath(dealershipId)}/menu_templates/${id}/reorder_items`;
const customersSearchPath = (dealershipId) => `${dealershipPath(dealershipId)}/customers/search`;
const appointmentSlotsPath = (dealershipId) => `${dealershipPath(dealershipId)}/service_advisors/appointments/available_slots`;
const waiterPossibilityPath = (dealershipId) => `${dealershipPath(dealershipId)}/appointment_slots/waiter_possibility`;
const fetchJobSlotsPath = (dealershipId) => `${dealershipPath(dealershipId)}/drivers/jobs/available_slots`;
const fetchTecnhiciansJobSlotsPath = (dealershipId) => `${dealershipPath(dealershipId)}/technicians/available_slots`;
const setAppointmentPath = (dealershipId) => `${dealershipPath(dealershipId)}/appointments`;
const updateAppointmentPath = (dealershipId, bookingId) => `${dealershipPath(dealershipId)}/appointments/${bookingId}`;
const updateLocalAppointmentPath = (dealershipId, bookingId) => `${updateAppointmentPath(dealershipId, bookingId)}/local`;
const cancelAppointmentPath = (dealershipId, bookingId) => `${dealershipPath(dealershipId)}/appointments/${bookingId}/cancel`;
const sendCheckinSmsPath = (dealershipId, bookingId) => `${dealershipPath(dealershipId)}/appointments/${bookingId}/send_sms`;
const generateROpath = (dealershipId, bookingId) => `${dealershipPath(dealershipId)}/appointments/${bookingId}/generate_repair_order`;
const createCustomerPath = (dealershipId) => `${dealershipPath(dealershipId)}/customers`;
const updateCustomerPath = (dealershipId) => `${dealershipPath(dealershipId)}/customers/update_command`;
const createNewVehiclePath = (dealershipId) => `${dealershipPath(dealershipId)}/vehicles`;
const updateVehiclePath = (dealershipId) => `${dealershipPath(dealershipId)}/vehicles/update_command`;
const updateVehicleSetPath = (dealershipId) => `${dealershipPath(dealershipId)}/vehicles/update_set`;
const updateServicePath = (dealershipId, id) => `${dealershipPath(dealershipId)}/services/${id}`;
const opCodesPath = (dealershipId) => `${dealershipPath(dealershipId)}/op_codes`;
const importOpCodesPath = (dealershipId) => `${dealershipPath(dealershipId)}/op_codes/import`;
const updateAdvisorPath = (dealershipId, id) => `${dealershipPath(dealershipId)}/service_advisors/${id}`;
const fetchTeamAdvisorsPath = (dealershipId, teamTagId) => `${dealershipPath(dealershipId)}/team_tags/${teamTagId}/service_advisors`;
const fetchResponsibleTeamTagPath = (dealershipId) => `${dealershipPath(dealershipId)}/team_tags/find`;
const textMessagesPath = (dealershipId) => `${dealershipPath(dealershipId)}/text_messages`;
const textMessagePath = (dealershipId, dealershipsTextMessageId) => `${dealershipPath(dealershipId)}/text_messages/${dealershipsTextMessageId}`;

const arriveUsersPath = (dealershipId) => `${dealershipPath(dealershipId)}/users/arrive`;
const arriveUserPath = (dealershipId, arriveUserId) => `${arriveUsersPath(dealershipId)}/${arriveUserId}`;
const remoteUsersPath = (dealershipId) => `${dealershipPath(dealershipId)}/users/remote`;
const remoteUserPath = (dealershipId, remoteUserId) => `${remoteUsersPath(dealershipId)}/${remoteUserId}`;
const driversPath = (dealershipId) => `${dealershipPath(dealershipId)}/drivers`;
const driverVacationsPath = (dealershipId, driverId) => `${driversPath(dealershipId, driverId)}/${driverId}/vacations`;
const driverVacationsUpdatePath = (dealershipId, driverId, vacationId) => `${driverVacationsPath(dealershipId, driverId)}/${vacationId}`;
const driverPath = (dealershipId, driverId) => `${driversPath(dealershipId)}/${driverId}`;
const stylesheetPath = (dealershipId) => `${dealershipPath(dealershipId)}/stylesheet`;
const recallsPath = (dealershipId) => `${dealershipPath(dealershipId)}/recalls`;
const updateRecallPath = (dealershipId, recallId) => `${dealershipPath(dealershipId)}/recalls/${recallId}`;
const recallsSearchPath = (dealershipId) => `${dealershipPath(dealershipId)}/recalls/search`;
const driverJobsForTimePeriod = (dealershipId) => `${dealershipPath(dealershipId)}/jobs`;
const createNewDriverJobPath = (dealershipId, driverId) => `${dealershipPath(dealershipId)}/drivers/${driverId}/jobs`;
const updateDriverJobPath = (dealershipId, driverId, jobId) => `${dealershipPath(dealershipId)}/drivers/${driverId}/jobs/${jobId}`;
const cancelDriverJobPath = (dealershipId, driverId, jobId) => `${updateDriverJobPath(dealershipId, driverId, jobId)}/cancel`;
const fetchAvailableTransportPath = (dealershipId) => `${dealershipPath(dealershipId)}/transports`;
const fetchAvailableLoanersPath = (dealershipId, integrationId) => `${dealershipPath(dealershipId)}/integrations/${integrationId}/loaner/capacity`;
const nonWorkingDaysPath = (dealershipId) => `${dealershipPath(dealershipId)}/non_working_days`;
const deleteNonWorkingDaysPath = (dealershipId, dayId) => `${nonWorkingDaysPath(dealershipId)}/${dayId}`;
const updateAppointmentToArrivedPath = (dealershipId, bookingId) => `${updateAppointmentPath(dealershipId, bookingId)}/local/arrive`;
const fetchAvailableQuestionnairesPath = (dealershipId) => `${dealershipPath(dealershipId)}/questionnaires`;
const sendAppointmentQuestionnairesPath = (dealershipId, bookingId) => `${dealershipPath(dealershipId)}/appointments/${bookingId}/questionnaire_answers`;
const fetchAppointmentVideosPath = (dealershipId, appointmentId) => `${dealershipPath(dealershipId)}/appointments/${appointmentId}/videos`;

const mobileTechniciansPath = (dealershipId) => (
  `${dealershipPath(dealershipId)}/technicians`
);
const createNewMobileTechnicianJobPath = (dealershipId, technicianId) => (
  `${mobileTechniciansPath(dealershipId)}/${technicianId}/jobs`
);
const updateMobileTechnicianJobPath = (dealershipId, technicianId, jobId) => (
  `${mobileTechniciansPath(dealershipId)}/${technicianId}/jobs/${jobId}`
);
const cancelMobileTechnicianJobPath = (dealershipId, technicianId, jobId) => (
  `${updateMobileTechnicianJobPath(dealershipId, technicianId, jobId)}/cancel`
);
const updateMobileTechniciansPath = (dealershipId, technicianId) => (
  `${mobileTechniciansPath(dealershipId)}/${technicianId}`
);
const mobileTechniciansVacationsPath = (dealershipId, technicianId) => (
  `${mobileTechniciansPath(dealershipId)}/${technicianId}/vacations`
);
const mobileTechniciansVacationsBulkPath = (dealershipId, technicianId) => (
  `${mobileTechniciansVacationsPath(dealershipId, technicianId)}/bulk`
);
const updateMobileTechniciansVacationsPath = (dealershipId, technicianId, vacationId) => (
  `${mobileTechniciansVacationsPath(dealershipId, technicianId)}/${vacationId}`
);

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_ROOT_URL,
});

const apiCall = (
  path,
  params = {},
  method = GET,
  token,
  withHeaders = false,
  noAdminPrefix = false,
) => axiosInstance.request({
  method,
  headers: authorizationHeader(token),
  url: noAdminPrefix ? path : `${ADMIN_API_PATH}${path}`,
  params: method === GET ? params : undefined,
  data: (method === POST || method === PATCH || method === PUT) ? params : undefined,
}).then((response) => (withHeaders ? response : response.data));

const authCall = (
  path,
  params = {},
  method = POST,
) => axiosAuthInstance.request({
  method,
  url: path,
  params: method === GET ? params : undefined,
  data: (method === POST || method === PATCH || method === PUT) ? params : undefined,
}).then((response) => response.data);

export const fetchAuthToken = (email, password) => authCall(
  SESSION_TOKEN_PATH,
  {
    email,
    password,
    grant_type: "password",
  },
  POST,
);

export const fetchUpdateUserPassword = (params) => authCall("/users/password", params, PUT);

export const sendResetLink = (params) => authCall("/users/password", params, POST);

// #todo connect to api
export const fetchUserByResetToken = (resetToken) => Promise.resolve({
  id: 1,
  first_name: "Bob",
  last_name: "Doe",
  resetToken,
});

export const fetchBooking = (dealershipId, bookingId, kind, token) => (
  apiCall(bookingPath(dealershipId, bookingId), { kind }, GET, token)
);

export const checksJobDistance = (dealershipId, params, token) => (
  apiCall(checksJobDistancePath(dealershipId), params, GET, token)
);

export const cancelBooking = (bookingId, channelKey, dealershipId, sendSms, token) => (
  apiCall(
    cancelAppointmentPath(dealershipId, bookingId),
    {
      appointment_id: bookingId,
      channel_key: channelKey,
      dealership_id: dealershipId,
      send_sms: sendSms,
    },
    PATCH,
    token,
  )
);

export const updateBooking = (dealershipId, appointmentId, params, token) => (
  apiCall(updateAppointmentPath(dealershipId, appointmentId), params, PATCH, token)
);

export const updateLocalAppointment = (dealershipId, bookingId, params, token) => (
  apiCall(updateLocalAppointmentPath(dealershipId, bookingId), params, PATCH, token)
);

export const sendCheckinSms = (dealershipId, appointmentId, params, token) => (
  apiCall(sendCheckinSmsPath(dealershipId, appointmentId), params, POST, token)
);

export const fetchBookings = (dealershipId, params, token) => (
  apiCall(bookingsPath(dealershipId), params, GET, token, true)
);

export const fetchCustomers = (dealershipId, params, token) => (
  apiCall(customersSearchPath(dealershipId), params, GET, token)
);

export const fetchMenuTemplate = (dealershipId, token, id) => (
  apiCall(menuTemplatePath(dealershipId, id), {}, GET, token)
);

export const fetchMenuTemplates = (dealershipId, token, params) => (
  apiCall(menuTemplatesPath(dealershipId), {
    ...params,
    "q[extension]": params.extensions,
    "q[general]": !params.extensions,
    "q[name_cont]": params.search,
    "q[s]": params.sort,
  }, GET, token, true)
);

export const updateMenuTemplate = (dealershipId, token, id, params) => (
  apiCall(menuTemplatePath(dealershipId, id), params, PATCH, token)
);

export const duplicateMenuTemplate = (dealershipId, token, id) => (
  apiCall(duplicateTemplatePath(dealershipId, id), {}, POST, token)
);

export const toggleMenuTemplate = (dealershipId, token, id, active) => (
  apiCall(switchTemplatePath(dealershipId, id), { active }, PATCH, token)
);

export const deleteMenuTemplate = (dealershipId, token, id) => (
  apiCall(menuTemplatePath(dealershipId, id), {}, DELETE, token)
);

export const createMenuTemplate = (dealershipId, token, name, isExtension) => (
  apiCall(menuTemplatesPath(dealershipId), {
    name,
    active: false,
    kind: isExtension ? "extension" : "general",
  }, POST, token)
);

export const destroyMenuItem = (dealershipId, id, token) => (
  apiCall(menuItemPath(dealershipId, id), {}, DELETE, token)
);

export const fetchServices = (dealershipId, token) => (
  apiCall(servicesPath(dealershipId), {}, GET, token)
);

export const createMenuItem = (dealershipId, params, token) => (
  apiCall(menuItemsPath(dealershipId), {
    menu_template_id: params.menuTemplateId,
    service_id: params.serviceId,
    extras: params.extras || [],
    premium_extras: params.premiumextras || [],
    package_item_ids: params.packageItems || [],
    default: params.isDefault,
    default_inhouse_repair_order_item: params.isDefaultRO,
    cutoff_time: params.cutoff_time,
    available_on_weekends: params.isDefaultAvailable,
    publicly_available: params.isDefaultPublic,
  }, POST, token)
);

export const fetchTextMessages = (dealershipId, params, token) => (
  apiCall(textMessagesPath(dealershipId), { kind: params.kind }, GET, token)
);

export const addTextMessage = (dealershipId, params, token) => (
  apiCall(textMessagesPath(dealershipId), params, POST, token)
);

export const updateTextMessageData = (dealershipId, dealershipsTextMessageId, params, token) => (
  apiCall(textMessagePath(dealershipId, dealershipsTextMessageId), params, PATCH, token)
);

export const destroyTextMessage = (dealershipId, dealershipsTextMessageId, token) => (
  apiCall(textMessagePath(dealershipId, dealershipsTextMessageId), {}, DELETE, token)
);

export const fetchVehicleSets = (params) => (
  apiCall(VEHICLE_SETS_PATH, params, GET, null, false, true)
);

export const updateVehicleGroup = (dealershipId, vehicleGroup, token) => {
  const {
    mileage_range,
    price,
    saving,
    exclude_cars,
    vehicle_sets,
    allocated_labor_time,
    actual_labor_time,
    mobile_actual_labor_time,
    expected_upsell_time,
    labor_price,
    parts_price,
  } = vehicleGroup;
  return apiCall(vehicleGroupPath(dealershipId, vehicleGroup.id), {
    menu_item_target: {
      price,
      saving: +saving,
      exclude_cars,
      allocated_labor_time: +allocated_labor_time,
      actual_labor_time: +actual_labor_time,
      mobile_actual_labor_time: +mobile_actual_labor_time,
      expected_upsell_time: +expected_upsell_time,
      mileage_range_start: mileage_range[0],
      mileage_range_end: mileage_range[1],
      vehicle_set_ids: vehicle_sets.map((vs) => vs.id),
      labor_price: +labor_price,
      parts_price: +parts_price,
    },
  }, PATCH, token);
};

export const deleteVehicleGroup = (dealershipId, vehicleGroupId, token) => (
  apiCall(vehicleGroupPath(dealershipId, vehicleGroupId), {}, DELETE, token)
);

export const createVehicleGroup = (dealershipId, menuItemId, vehicleGroup, token) => {
  const {
    mileage_range,
    price,
    saving,
    exclude_cars,
    vehicle_sets,
    allocated_labor_time,
    actual_labor_time,
    mobile_actual_labor_time,
    expected_upsell_time,
    labor_price,
    parts_price,
  } = vehicleGroup;
  return apiCall(vehicleGroupsPath(dealershipId), {
    menu_item_target: {
      price,
      saving: +saving,
      exclude_cars,
      allocated_labor_time: +allocated_labor_time,
      actual_labor_time: +actual_labor_time,
      mobile_actual_labor_time: +mobile_actual_labor_time,
      expected_upsell_time: +expected_upsell_time,
      menu_item_id: menuItemId,
      mileage_range_start: mileage_range[0],
      mileage_range_end: mileage_range[1],
      vehicle_set_ids: vehicle_sets.map((vs) => vs.id),
      labor_price: +labor_price,
      parts_price: +parts_price,
    },
  }, POST, token);
};

export const updateDecisionTree = (dealershipId, decisionTree, token) => (
  apiCall(
    decisionTreePath(dealershipId, decisionTree.id),
    {
      menu_item_id: decisionTree.menuItemId,
      intro: decisionTree.intro,
      steps: decisionTree.steps,
    },
    PATCH,
    token,
  )
);

export const deleteDecisionTree = (dealershipId, decisionTree, token) => (
  apiCall(
    decisionTreePath(dealershipId, decisionTree.id),
    {},
    DELETE,
    token,
  )
);

export const createDecisionTree = (dealershipId, decisionTree, token) => (
  apiCall(
    decisionTreesPath(dealershipId),
    {
      menu_item_id: decisionTree.menuItemId,
      intro: decisionTree.intro,
      steps: decisionTree.steps,
    },
    POST,
    token,
  )
);

export const fetchDealershipInfo = (dealershipId, token) => (
  apiCall(dealershipPath(dealershipId), {}, GET, token)
);

export const fetchDealershipServiceAdvisors = (dealershipId, params, token) => (
  apiCall(advisorsPath(dealershipId), params, GET, token, true)
);

export const fetchDealershipDrivers = (dealershipId, params, token) => (
  apiCall(driversPath(dealershipId), params, GET, token, true)
);

export const fetchDealershipMobileTechnicians = (dealershipId, params, token) => (
  apiCall(mobileTechniciansPath(dealershipId), params, GET, token, true)
);

export const updateDealershipInfo = (dealershipId, params, token) => (
  apiCall(dealershipPath(dealershipId), params, PATCH, token)
);

export const addEmployment = (dealershipId, employmentData, token) => (
  apiCall(employmentsPath(dealershipId), employmentData, POST, token)
);

export const updateEmploymentData = (dealershipId, employmentData, token) => (
  apiCall(employmentsPath(dealershipId), employmentData, PATCH, token)
);

export const deleteEmployment = (dealershipId, employmentId, token) => (
  apiCall(employmentPath(dealershipId, employmentId), {}, DELETE, token)
);

export const toggleEmploymentLock = (isLocking, dealershipId, email, token) => (
  apiCall(employmentsAccessPath(dealershipId, isLocking), { email }, PUT, token)
);

export const addKiosk = (dealershipId, kioskData, token) => (
  apiCall(kiosksPath(dealershipId), kioskData, POST, token)
);

export const updateKioskData = (dealershipId, kioskId, kioskData, token) => (
  apiCall(kioskPath(dealershipId, kioskId), kioskData, PATCH, token)
);

export const destroyKiosk = (dealershipId, kioskId, token) => (
  apiCall(kioskPath(dealershipId, kioskId), {}, DELETE, token)
);

export const addTvScreen = (dealershipId, tvScreenData, token) => (
  apiCall(tvScreensPath(dealershipId), tvScreenData, POST, token)
);

export const updateTvScreenData = (dealershipId, tvScreenId, tvScreenData, token) => (
  apiCall(tvScreenPath(dealershipId, tvScreenId), tvScreenData, PATCH, token)
);

export const destroyTvScreen = (dealershipId, tvScreenId, token) => (
  apiCall(tvScreenPath(dealershipId, tvScreenId), {}, DELETE, token)
);

export const addArriveUser = (dealershipId, arriveUserData, token) => (
  apiCall(arriveUsersPath(dealershipId), arriveUserData, POST, token)
);

export const updateArriveUserData = (dealershipId, arriveUserId, arriveUserData, token) => (
  apiCall(arriveUserPath(dealershipId, arriveUserId), arriveUserData, PATCH, token)
);

export const destroyArriveUser = (dealershipId, arriveUserId, token) => (
  apiCall(arriveUserPath(dealershipId, arriveUserId), {}, DELETE, token)
);

export const addRemoteUser = (dealershipId, remoteUserData, token) => (
  apiCall(remoteUsersPath(dealershipId), remoteUserData, POST, token)
);

export const updateRemoteUserData = (dealershipId, remoteUserId, remoteUserData, token) => (
  apiCall(remoteUserPath(dealershipId, remoteUserId), remoteUserData, PATCH, token)
);

export const destroyRemoteUser = (dealershipId, remoteUserId, token) => (
  apiCall(remoteUserPath(dealershipId, remoteUserId), {}, DELETE, token)
);

export const updateIntegration = (dealershipId, integrationId, params, token) => (
  apiCall(updateIntegrationPath(dealershipId, integrationId), params, PATCH, token)
);

export const updateDmsIntegration = (dealershipId, integrationId, params, token) => (
  apiCall(updateDmsIntegrationPath(dealershipId, integrationId), params, PATCH, token)
);

export const updateLoanerIntegration = (dealershipId, integrationId, params, token) => (
  apiCall(updateLoanerIntegrationPath(dealershipId, integrationId), params, PATCH, token)
);
export const updatePickupIntegration = (dealershipId, integrationId, params, token) => (
  apiCall(updatePickupIntegrationPath(dealershipId, integrationId), params, PATCH, token)
);
export const updateMobileTechnicianIntegration = (dealershipId, integrationId, params, token) => (
  apiCall(updateMobileTechnicianIntegrationPath(dealershipId, integrationId), params, PATCH, token)
);

export const fetchDealerships = (token, params) => apiCall(
  DEALERSHIPS_PATH,
  {
    ...params,
    "q[s]": params.sort,
  },
  GET,
  token,
  true,
);

export const createDealership = (dealershipData, token) => (
  apiCall(DEALERSHIPS_PATH, dealershipData, POST, token)
);

export const createDealershipMaintainer = (dealership_id, email, token) => apiCall(
  createDealershipMaintainerPath(dealership_id),
  {
    dealership_id,
    email,
  },
  POST,
  token,
);

export const updateDealershipMaintainer = (
  dealership_id,
  user_id,
  email,
  token,
) => apiCall(
  updateDealershipMaintainerPath(dealership_id, user_id),
  {
    dealership_id,
    user_id,
    email,
  },
  PATCH,
  token,
);

export const deleteDealershipMaintainer = (dealership_id, user_id, token) => apiCall(
  updateDealershipMaintainerPath(dealership_id, user_id),
  {
    dealership_id,
    user_id,
  },
  DELETE,
  token,
);

export const createIntegration = (dealershipId, integrationData, token) => (
  apiCall(integrationsPath(dealershipId), integrationData, POST, token)
);

export const updateMenuItemAdditionalSettings = (
  dealershipId,
  menuItemId,
  params,
  token,
) => apiCall(
  menuItemPath(dealershipId, menuItemId),
  params,
  PATCH,
  token,
);

export const updateMenuItem = (dealershipId, menuItemId, packageItemIds, token) => apiCall(
  menuItemPath(dealershipId, menuItemId),
  {
    package_item_ids: packageItemIds,
  },
  PATCH,
  token,
);

export const updateMenuItemDefaultForMobileRO = (
  dealershipId,
  menuItemId,
  isDefaultMobileRO,
  token,
) => apiCall(
  menuItemPath(dealershipId, menuItemId),
  {
    default_mobile_repair_order_item: isDefaultMobileRO,
  },
  PATCH,
  token,
);

export const updateMenuItemCutOff = (dealershipId, menuItemId, cutoff_time, token) => (
  apiCall(menuItemPath(dealershipId, menuItemId), { cutoff_time }, PATCH, token));

export const updateMenuItemAvailable = (
  dealershipId,
  menuItemId,
  isDefaultAvailable,
  token,
) => apiCall(
  menuItemPath(dealershipId, menuItemId),
  {
    available_on_weekends: isDefaultAvailable,
  },
  PATCH,
  token,
);

export const updateMenuItemPublic = (dealershipId, menuItemId, isDefaultPublic, token) => apiCall(
  menuItemPath(dealershipId, menuItemId),
  {
    publicly_available: isDefaultPublic,
  },
  PATCH,
  token,
);

export const updateMenuItemExtras = (dealershipId, menuItemId, extras, token) => (
  apiCall(menuItemPath(dealershipId, menuItemId), { extras }, PATCH, token)
);
export const updateMenuItemPremiumExtras = (dealershipId, menuItemId, premium_extras, token) => (
  apiCall(menuItemPath(dealershipId, menuItemId), { premium_extras }, PATCH, token)
);

export const updateMenuItemTeamTags = (dealershipId, menuItemId, team_tag_ids, token) => (
  apiCall(menuItemPath(dealershipId, menuItemId), { team_tag_ids }, PATCH, token)
);

export const reorderMenuItems = ({
  dealershipId,
  menuTemplateId,
  menuItemIds,
  itemsKind,
}, token) => apiCall(
  updateMenuTemplateItemsOrderPath(dealershipId, menuTemplateId),
  {
    menu_item_ids: menuItemIds,
    item_kind: itemsKind,
  },
  PATCH,
  token,
);

export const fetchDealershipCapacity = (dealershipId, params, token) => (
  apiCall(dealershipCapacityPath(dealershipId), params, GET, token)
);

export const updateDealershipCapacitySettings = (dealershipId, params, token) => (
  apiCall(dealershipUpdateCapacityPath(dealershipId), params, PATCH, token)
);

export const fetchDealershipMenuItems = (dealershipId, params, token) => (
  apiCall(menuItemsPath(dealershipId), params, GET, token, false, false)
);

export const fetchTeamAdvisors = (dealershipId, teamTagId, token) => (
  apiCall(fetchTeamAdvisorsPath(dealershipId, teamTagId), {
    dealership_id: dealershipId,
    id: teamTagId,
  }, GET, token)
);

export const fetchResponsibleTeamTag = (dealershipId, isMobileTechnician, params, token) => (
  apiCall(fetchResponsibleTeamTagPath(dealershipId), {
    menu_items_ids: params.menu_items_ids,
    recalls_ids: params.recalls_ids,
    vehicle_id: params.vehicle_id,
    mobile_technician: isMobileTechnician,
  }, GET, token)
);

export const fetchAppointmentSlots = (dealershipId, params, token) => (
  apiCall(appointmentSlotsPath(dealershipId), params, GET, token)
);

export const validateWaiterPossibility = (
  dealershipId,
  params,
  token,
) => (
  apiCall(
    waiterPossibilityPath(dealershipId),
    params,
    GET,
    token,
  )
);

export const fetchJobSlots = (dealershipId, params, token) => (
  apiCall(fetchJobSlotsPath(dealershipId), params, GET, token)
);

export const fetchTecnhiciansJobSlots = (dealershipId, params, token) => (
  apiCall(fetchTecnhiciansJobSlotsPath(dealershipId), params, GET, token)
);

export const setAppointment = (dealershipId, params, token) => (
  apiCall(setAppointmentPath(dealershipId), params, POST, token)
);

export const createNewCustomer = (dealershipId, params, token) => (
  apiCall(createCustomerPath(dealershipId), params, POST, token)
);

export const updateCustomer = (dealershipId, params, token) => (
  apiCall(updateCustomerPath(dealershipId), params, PATCH, token)
);

export const createNewVehicle = (dealershipId, params, token) => (
  apiCall(createNewVehiclePath(dealershipId), params, POST, token)
);

export const updateVehicle = (dealershipId, params, token) => (
  apiCall(updateVehiclePath(dealershipId), params, PATCH, token)
);

export const updateVehicleSet = (dealershipId, params, token) => (
  apiCall(updateVehicleSetPath(dealershipId), params, PATCH, token)
);

export const updateService = (dealershipId, token, serviceId, newName, newOpCodeId) => (
  apiCall(updateServicePath(dealershipId, serviceId), {
    name: newName,
    op_code_id: newOpCodeId,
  }, PATCH, token)
);

export const fetchOpCodes = (dealershipId, params, token) => (
  apiCall(opCodesPath(dealershipId), params, GET, token)
);

export const createService = (dealershipId, params, token) => (
  apiCall(servicesPath(dealershipId), params, POST, token)
);

export const updateAdvisorData = (dealershipId, advisorId, params, token) => (
  apiCall(updateAdvisorPath(dealershipId, advisorId), params, PATCH, token)
);

export const advisorBulkUpdate = (dealershipId, params, token) => (
  apiCall(advisorsBulkUpdatePath(dealershipId), params, PATCH, token)
);

export const fetchDealershipRecalls = (dealershipId, params, token) => (
  apiCall(recallsPath(dealershipId), params, GET, token, true)
);

export const updateRecall = (dealershipId, recallId, params, token) => (
  apiCall(updateRecallPath(dealershipId, recallId), params, PATCH, token)
);

export const fetchVehicleRecalls = (dealershipId, params, token) => (
  apiCall(recallsSearchPath(dealershipId), params, GET, token)
);

export const fetchAvailableTransport = (dealershipId, params, token) => (
  apiCall(fetchAvailableTransportPath(dealershipId), params, GET, token)
);

export const createDriver = (dealershipId, params, token) => (
  apiCall(driversPath(dealershipId), params, POST, token)
);

export const updateDriverData = (dealershipId, driverId, params, token) => (
  apiCall(driverPath(dealershipId, driverId), params, PATCH, token)
);

export const deleteDriver = (dealershipId, driverId, token) => (
  apiCall(driverPath(dealershipId, driverId), {}, DELETE, token)
);

export const setStylesheet = (dealershipId, stylesheet, token) => (
  apiCall(stylesheetPath(dealershipId), { stylesheet }, POST, token)
);

export const fetchDriverJobsForTimePeriod = (dealershipId, params, token) => (
  apiCall(driverJobsForTimePeriod(dealershipId), params, GET, token)
);

export const createNewDriverJob = (dealershipId, driverId, params, token) => (
  apiCall(createNewDriverJobPath(dealershipId, driverId), params, POST, token)
);

export const updateDriverJob = (dealershipId, driverId, jobId, params, token) => (
  apiCall(updateDriverJobPath(dealershipId, driverId, jobId), params, PATCH, token)
);

export const cancelDriverJob = (dealershipId, driverId, jobId, token) => (
  apiCall(cancelDriverJobPath(dealershipId, driverId, jobId), {}, PATCH, token)
);

export const createNewMobileTechnicianJob = (dealershipId, technicianId, params, token) => (
  apiCall(createNewMobileTechnicianJobPath(dealershipId, technicianId), params, POST, token)
);

export const updateMobileTechnicianJob = (dealershipId, technicianId, jobId, params, token) => (
  apiCall(updateMobileTechnicianJobPath(dealershipId, technicianId, jobId), params, PATCH, token)
);

export const cancelMobileTechnicianJob = (dealershipId, technicianId, jobId, token) => (
  apiCall(cancelMobileTechnicianJobPath(dealershipId, technicianId, jobId), {}, PATCH, token)
);

export const fetchMakeModelYearMap = () => apiCall(VEHICLE_SETS_PATH, {}, GET, null, false, true);

export const fetchDecisionTrees = (ids, token) => {
  const params = ids.map((id) => `menu_item_ids[]=${id}`).join("&");
  return axiosInstance.request({
    method: GET,
    headers: authorizationHeader(token),
    url: `${DECISION_TREES_PATH}/?${params}`,
  });
};

export const fetchVehicleSetByVin = (dealershipId, params, token) => (
  apiCall(fetchVehicleSetByVinPath(dealershipId), params, GET, token)
);

export const fetchCdkModelCode = (dealershipId, params, token) => (
  apiCall(fetchCdkModelCodePath(dealershipId), params, GET, token)
);

export const fetchCdkModelCodeSuggestions = (dealershipId, params, token) => (
  apiCall(fetchCdkModelCodeSuggestionsPath(dealershipId), params, GET, token)
);

export const fetchVehicleData = (dealershipId, token, id) => (
  apiCall(fetchVehicleDataPath(dealershipId, id), {}, GET, token)
);

export const fetchVehicleRO = (dealershipId, token, id) => (
  apiCall(fetchVehicleROPath(dealershipId, id), {}, GET, token)
);

export const fetchTeamTags = (dealershipId, token) => (
  apiCall(teamTagsPath(dealershipId), {}, GET, token)
);

export const addTeamTag = (dealershipId, params, token) => (
  apiCall(teamTagsPath(dealershipId), params, POST, token)
);

export const updateTeamTagData = (dealershipId, tagId, params, token) => (
  apiCall(teamTagPath(dealershipId, tagId), params, PATCH, token)
);

export const removeTeamTag = (dealershipId, tagId, token) => (
  apiCall(teamTagPath(dealershipId, tagId), {}, DELETE, token)
);

export const updateTeamSeniority = (dealershipId, params, token) => (
  apiCall(teamTagsSeniorityPath(dealershipId), params, PUT, token)
);

export const fetchTeamTagCapacity = (dealershipId, tagId, params, token) => (
  apiCall(teamTagCapacityPath(dealershipId, tagId), params, GET, token)
);

export const teamTagDayLock = (dealershipId, tagId, params, token) => (
  apiCall(teamTagDayLockPath(dealershipId, tagId), params, PUT, token)
);

export const teamTagDayUnlock = (dealershipId, tagId, params, token) => (
  apiCall(teamTagDayUnlockPath(dealershipId, tagId), params, PUT, token)
);

export const fetchReportsEfficiency = (dealershipId, params, token) => (
  apiCall(reportsEfficiencyPath(dealershipId), params, GET, token)
);

export const fetchReportsGeneral = (dealershipId, params, token) => (
  apiCall(reportsGeneralPath(dealershipId), params, GET, token)
);

export const fetchReportsCustomersActivity = (dealershipId, params, token) => (
  apiCall(reportsCustomersActivityPath(dealershipId), params, GET, token)
);

export const fetchEmployeeReports = (dealershipId, params, token) => (
  apiCall(reportsEmployeePath(dealershipId), params, GET, token)
);

export const generateRepairOrder = (dealershipId, bookingId, channel_key, token) => (
  apiCall(generateROpath(dealershipId, bookingId), {
    dealership_id: dealershipId,
    appointment_id: bookingId,
    channel_key,
  }, POST, token)
);

export const fetchEmploymentVacations = (dealershipId, employmentId, token) => (
  apiCall(employmentVacationsPath(dealershipId, employmentId), {}, GET, token)
);

export const createEmploymentVacations = (dealershipId, employmentId, params, token) => (
  apiCall(employmentVacationsPath(dealershipId, employmentId), params, POST, token)
);

export const updateEmploymentVacations = (
  dealershipId,
  employmentId,
  vacationId,
  params,
  token,
) => (
  apiCall(
    employmentVacationsUpdatePath(dealershipId, employmentId, vacationId),
    params,
    PATCH,
    token,
  )
);

export const deleteEmploymentVacations = (
  dealershipId,
  employmentId,
  vacationId,
  token,
) => (
  apiCall(
    employmentVacationsUpdatePath(dealershipId, employmentId, vacationId),
    {},
    DELETE,
    token,
  )
);

export const fetchVacationsForDriver = (dealershipId, driverId, token) => (
  apiCall(driverVacationsPath(dealershipId, driverId), {}, GET, token)
);

export const createVacationsForDriver = (dealershipId, driverId, params, token) => (
  apiCall(driverVacationsPath(dealershipId, driverId), params, POST, token)
);

export const updateVacationsForDriver = (
  dealershipId,
  driverId,
  vacationId,
  params,
  token,
) => (
  apiCall(
    driverVacationsUpdatePath(dealershipId, driverId, vacationId),
    params,
    PATCH,
    token,
  )
);

export const deleteVacationsForDriver = (
  dealershipId,
  driverId,
  vacationId,
  token,
) => (
  apiCall(
    driverVacationsUpdatePath(dealershipId, driverId, vacationId),
    {},
    DELETE,
    token,
  )
);

export const fetchAvailableLoaners = (dealershipId, integrationId, params, token) => (
  apiCall(fetchAvailableLoanersPath(dealershipId, integrationId), params, GET, token)
);

export const fetchMobileTechnicians = (
  dealershipId,
  params,
  token,
) => (
  apiCall(
    mobileTechniciansPath(dealershipId),
    params,
    GET,
    token,
    true,
  )
);

export const updateMobileTechnicians = (
  dealershipId,
  mobileTechnicianId,
  params,
  token,
) => (
  apiCall(
    updateMobileTechniciansPath(dealershipId, mobileTechnicianId),
    params,
    PATCH,
    token,
  )
);

export const fecthMobileTechniciansVacations = (
  dealershipId,
  mobileTechnicianId,
  token,
) => (
  apiCall(
    mobileTechniciansVacationsPath(dealershipId, mobileTechnicianId),
    {},
    GET,
    token,
  )
);

export const createMobileTechniciansVacation = (
  dealershipId,
  mobileTechnicianId,
  params,
  token,
) => (
  apiCall(
    mobileTechniciansVacationsPath(dealershipId, mobileTechnicianId),
    params,
    POST,
    token,
  )
);

export const createMobileTechniciansVacationsBulk = (
  dealershipId,
  mobileTechnicianId,
  params,
  token,
) => (
  apiCall(
    mobileTechniciansVacationsBulkPath(dealershipId, mobileTechnicianId),
    params,
    POST,
    token,
  )
);

export const removeMobileTechniciansVacation = (
  dealershipId,
  appointmentId,
  vacationId,
  token,
) => (
  apiCall(
    updateMobileTechniciansVacationsPath(dealershipId, appointmentId, vacationId),
    {},
    DELETE,
    token,
  )
);

export const updateMobileTechniciansVacation = (
  dealershipId,
  appointmentId,
  vacationId,
  params,
  token,
) => (
  apiCall(
    updateMobileTechniciansVacationsPath(dealershipId, appointmentId, vacationId),
    params,
    PATCH,
    token,
  )
);

export const fetchNonWorkingDays = (dealershipId, year, token) => (
  apiCall(nonWorkingDaysPath(dealershipId), { year }, GET, token)
);

export const setHoliday = (dealershipId, date, token) => (
  apiCall(nonWorkingDaysPath(dealershipId), { date }, POST, token)
);

export const deleteHoliday = (dealershipId, dateId, token) => (
  apiCall(deleteNonWorkingDaysPath(dealershipId, dateId), {}, DELETE, token)
);

export const updateAppointmentToArrived = (dealershipId, bookingId, token) => (
  apiCall(updateAppointmentToArrivedPath(dealershipId, bookingId), {}, PATCH, token)
);

export const importOpCodesForDealership = (dealershipId, token) => (
  apiCall(importOpCodesPath(dealershipId), {}, GET, token)
);

export const fetchListOfAvailableQuestionnaires = (dealershipId, kind, token) => (
  apiCall(fetchAvailableQuestionnairesPath(dealershipId), { kind }, GET, token)
);

export const createQuestionnairesAnswers = (dealershipId, bookingId, params, token) => (
  apiCall(sendAppointmentQuestionnairesPath(dealershipId, bookingId, params), params, POST, token)
);

export const fetchAppointmentVideos = (dealershipId, appointmentId, token) => (
  apiCall(fetchAppointmentVideosPath(dealershipId, appointmentId), {}, GET, token)
);
