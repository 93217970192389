import * as moment from "moment-timezone";
import { makeFloat, percentageDiscount, sum } from "shared/utils";

export const servicesSelector = (state) => {
  return {
    maintenance: state.checkinChat.order.services.maintenance || [],
    concern: state.checkinChat.order.services.concern || [],
    extensions: state.checkinChat.order.additionalServices || [],
    recalls: state.checkinChat.order.selectedRecalls || [],
    bookingsMenuItems: state.checkinChat.order.bookingsMenuItems || [],
  };
};

export const allServicesSelector = (state) => servicesSelector(state)
  .maintenance.concat(servicesSelector(state).concern)
  .concat(servicesSelector(state).extensions)
  .concat(servicesSelector(state).recalls)
  .concat(servicesSelector(state).bookingsMenuItems);

export const chatLineItemsSelector = (state) => state.checkinChat.order.lineItems;

const filterServices = (services, lineItems) => services.filter(
  (service) => lineItems.every((lineItem) => lineItem.name !== service.name),
);

export const filteredServicesSelector = (state) => {
  const {
    maintenance, concern, extensions, recalls, bookingsMenuItems,
  } = servicesSelector(state);
  const lineItems = chatLineItemsSelector(state);

  return {
    maintenance: filterServices(maintenance, lineItems),
    concern: filterServices(concern, lineItems),
    extensions: filterServices(extensions, lineItems),
    recalls: filterServices(recalls, lineItems),
    bookingsMenuItems: bookingsMenuItems.filter((item) => !item.menu_item_id),
  };
};
export const filteredLineItemsSelector = (state) => {
  const {
    maintenance, concern, extensions, recalls,
  } = servicesSelector(state);

  const lineItems = chatLineItemsSelector(state);
  return filterServices(lineItems, [...maintenance, ...concern, ...extensions, ...recalls]);
};

export const allFilteredServicesSelector = (state) => filteredServicesSelector(state)
  .maintenance.concat(filteredServicesSelector(state).concern)
  .concat(filteredServicesSelector(state).extensions)
  .concat(filteredServicesSelector(state).recalls)
  .concat(filteredServicesSelector(state).bookingsMenuItems);

export const chatOrderSelector = (state) => state.checkinChat.order;
export const chatHistorySelector = (state) => state.checkinChat.history;
export const chatLoadingSelector = (state) => state.checkinChat.isLoading;
export const chatInputSelector = (state) => state.checkinChat.currentInput;
export const chatDecisionTreeResultsSelector = (state) => state.checkinChat.decisionTreeResults;
export const chatCustomerNameSelector = (state) => state.checkinChat.order.customer.name;
export const chatCustomerIdSelector = (state) => state.checkinChat.order.customer.id;
export const chatAdditionalNotesSelector = (state) => state.checkinChat.order.additionalNotes;
export const chatDecisionTreesSelector = (state) => state.checkinChat.decisionTrees;
export const chatAdditionalServicesSelector = (state) => state.checkinChat.additionalServices;
export const chatSelectedExtensionsSelector = (state) => state.checkinChat.order.additionalServices;
export const chatCurrentVehicleSelector = (state) => state.checkinChat.order.vehicle;
export const chatCurrentVehicleCustomerNumberSelector = (state) => (
  state.checkinChat.order.vehicle.customer_number
);
export const chatCurrentCustomerSelector = (state) => state.checkinChat.order.customer;
export const chatAvailableRecallsSelector = (state) => state.checkinChat.availableRecalls;
export const chatSelectedRecallsSelector = (state) => state.checkinChat.order.selectedRecalls;
export const chatSignatureSelector = (state) => state.checkinChat.order.signature;
export const chatPhoneNumberSelector = (state) => state.checkinChat.order.customer.phone_number;
export const chatAvailableTransportsSelector = (state) => state.checkinChat.availableTransports;
export const chatCustomerWaitingSelector = (state) => state.checkinChat.order.isWaiting;
export const chatLoadingStateDelaySelector = (state) => state.checkinChat.loadingStateDelay;
export const chatAppointmentIdSelector = (state) => state.checkinChat.order.appointment.id || "";
export const chatAppointmentNumberSelector = (state) => state.checkinChat.order.appointment.appointment_number || "";
export const chatAppointmentDateSelector = (state) => state.checkinChat.order.date;
export const chatAppointmentQrCodeSelector = (state) => state.checkinChat.order.qrCode;
export const chatMakeModelYearMapSelector = (state) => state.checkinChat.makeModelYearMap;
export const chatLastRequestSelector = (state) => state.checkinChat.lastRequest;
export const chatIsVehicleSyncedSelector = (state) => state.checkinChat.isVehicleSynced;
export const chatIsInitializedSelector = (state) => state.checkinChat.isInitialized;
export const chatIsCustomerPagesInitializedSelector = (state) => (
  state.checkinChat.isCustomerPagesInitialized
);
export const chatIsAppointmentFinalizedSelector = (state) => (
  state.checkinChat.isAppointmentFinalized
);
export const chatVehiclesSelector = (state) => state.checkinChat.vehicles;
export const chatFilteredServicesPriceSelector = (state) => (
  sum(allFilteredServicesSelector(state), "fee") + sum(filteredServicesSelector(state).bookingsMenuItems, "price")
);
export const chatLineItemsPriceSelector = (state) => +chatLineItemsSelector(state)
  .reduce((total, item) => total + parseFloat(item.fee), 0.0)
  .toFixed(2);

export const calcAppointmentTimeHours = (state) => {
  const lineItems = filteredLineItemsSelector(state);
  const totalMenuItemHours = allServicesSelector(state)
    .reduce((total, service) => total + +service.allocated_labor_time, 0)
    .toFixed(1);
  const totalHours = lineItems
    .reduce((total, item) => total + parseFloat(item.labor_hours), +totalMenuItemHours)
    .toFixed(1);
  return totalHours;
};

export const chatBookingSelector = (state) => {
  const { order, isPickupJobCheckin } = state.checkinChat;
  const allServices = allServicesSelector(state);
  const packageServices = allServices.filter((s) => s.package_items);
  const lineItems = chatLineItemsSelector(state);
  const services = filteredServicesSelector(state);
  const totalHours = calcAppointmentTimeHours(state);
  const timeLabel = totalHours[1] > 1 ? "hours" : "hour";
  const { make, model } = order.vehicle;
  const modelYear = order.vehicle.year;
  const vehicle = `${make} ${model}, ${modelYear}`;
  const totalPriceBeforeDiscount = makeFloat(sum(allServices, "fee") + sum(packageServices, "saving"));
  const totalPrice = makeFloat(
    chatLineItemsPriceSelector(state) + chatFilteredServicesPriceSelector(state),
  );
  const discount = percentageDiscount(totalPriceBeforeDiscount, totalPrice);
  const pickupJob = order.jobs.find((job) => job.job_type === "pickup");

  return {
    vehicle,
    services,
    date: order.date,
    isPickupJobCheckin,
    jobDate: pickupJob && moment(pickupJob.collection_time).format("dddd, MMMM DD YYYY, h:mm A"),
    jobLocation: pickupJob && pickupJob.location,
    customer: `${order.customer.name} ${order.customer.lastName}`,
    totalPrice,
    totalPriceBeforeDiscount,
    discount,
    totalTime:
      totalHours[0] !== totalHours[1]
        ? `${totalHours[0]} - ${totalHours[1]} ${timeLabel}`
        : `${totalHours[0]} ${timeLabel}`,
    totalHours: Math.max(...totalHours),
    lineItems,
    advisor: order.advisor,
  };
};

export const chatIsPickupJobCheckinSelector = (state) => state.checkinChat.isPickupJobCheckin;
export const chatAllAvailableServicesSelector = (state) => state.checkinChat.availableServices;
export const chatAvailableServicesSelector = (state) => {
  return {
    maintenance: state.checkinChat.availableServices.maintenance.filter((service) => {
      const selectedMaintenance = (state.checkinChat.order.services || {}).maintenance || [];
      const packageItems = [].concat(...selectedMaintenance.map((s) => s.package_items));

      return !selectedMaintenance
        .concat(packageItems)
        .map((s) => s.id)
        .includes(service.id);
    }),
    concern: state.checkinChat.availableServices.concern.filter((service) => {
      const selectedConcern = (state.checkinChat.order.services || {}).concern || [];
      const packageItems = [].concat(...selectedConcern.map((s) => s.package_items));

      return !selectedConcern
        .concat(packageItems)
        .map((s) => s.id)
        .includes(service.id);
    }),
  };
};

export const chatServiceIdsSelector = (state) => (
  Object.values(state.checkinChat.order.services).reduce((arr, elem) => (
    [...arr, ...elem.map((e) => e.id)]
  ), [])
);

export const checkinAppointmentLoadingSelector = (state) => (
  state.checkinChat.checkinAppointmentLoading
);
export const checkinAppointmentErrorSelector = (state) => (
  state.checkinChat.checkinAppointmentError
);
