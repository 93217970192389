import { connect } from "react-redux";

import {
  bookingDetailsChosenTimeSlotSelector,
  bookingDetailsContactNumberSelector,
  bookingDetailsCustomerSelector,
  bookingDetailsIsDropOffSelector,
  bookingDetailsIsPickUpSelector,
  bookingDetailsRemoteJobDataSelector,
  bookingDetailsSelectedAdvisorSelector,
  bookingDetailsSelectedServicesSelector,
  bookingDetailsSendSmsSelector,
  bookingDetailsVehicleSelector,
  bookingDetailsisMobileTechnicianSelector,
  bookingIgnoreDistanceLimitSelector,
  bookingJobReachable,
  bookingSelectedRecallsSelector,
} from "store/selectors/booking-details-selectors";
import { settingsCapacityTimeBufferSelector, settingsDrivingTimeBufferSelector } from "store/selectors/settings-selectors";
import { setSendSms } from "store/actions/booking-details-actions";

import AppointmentDetails from "./index";

const mapStateToProps = (state) => {
  return {
    selectedServices: bookingDetailsSelectedServicesSelector(state),
    selectedRecalls: bookingSelectedRecallsSelector(state),
    selectedAdvisor: bookingDetailsSelectedAdvisorSelector(state),
    vehicle: bookingDetailsVehicleSelector(state),
    customer: bookingDetailsCustomerSelector(state),
    chosenTimeSlot: bookingDetailsChosenTimeSlotSelector(state),
    sendSms: bookingDetailsSendSmsSelector(state),
    contactNumber: bookingDetailsContactNumberSelector(state),
    isPickUp: bookingDetailsIsPickUpSelector(state),
    isDropOff: bookingDetailsIsDropOffSelector(state),
    isMobileTechnician: bookingDetailsisMobileTechnicianSelector(state),
    remoteJobData: bookingDetailsRemoteJobDataSelector(state),
    jobReachable: bookingJobReachable(state),
    capacityBufferTime: settingsCapacityTimeBufferSelector(state),
    driverBufferTime: settingsDrivingTimeBufferSelector(state),
    ignoreDistance: bookingIgnoreDistanceLimitSelector(state),
  };
};

export default connect(mapStateToProps, {
  setSendTextMessage: setSendSms,
})(AppointmentDetails);
