import maintenanceIcon from "assets/images/bookings/maintenance.svg";
import concernIcon from "assets/images/bookings/concern.svg";
import otherIcon from "assets/images/bookings/other.svg";
import extensionIcon from "assets/images/bookings/service.svg";
import recallIcon from "assets/images/bookings/recall_icon.svg";
import vinSpecificRecallIcon from "assets/images/bookings/recallVin.svg";

export default {
  maintenance: maintenanceIcon,
  concern: concernIcon,
  extension: extensionIcon,
  other: otherIcon,
  recall: recallIcon,
  vinSpecificRecall: vinSpecificRecallIcon,
};
