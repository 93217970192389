import React, { useEffect, useRef, useState } from "react";
import {
  arrayOf, func, oneOf, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import { isEmpty, isNil } from "ramda";
import Select from "react-select";
import {
  dmsTypeSelector,
  settingsDealershipWorkingHoursSelector,
  settingsPrintersSelector,
  settingsRemoteUsersSelector,
  settingsTeamTagsSelector,
} from "store/selectors/settings-selectors";
import { photoPropType, workingHoursPropType } from "shared/prop-types";
import cx from "classnames";
import { extractPhoneNumberFromString, formatInput } from "shared/utils/common";
import { WEEK_DAYS } from "shared/utils/datetime";
import { phoneNumberLengthValidator } from "shared/validators";
import ImageInput from "components/common/ImageInput";
import Input from "components/common/Input";
import Block from "components/common/Block";
import MultiTagSelect from "components/common/MultiTagSelect";
import Modal from "components/common/Modal";
import Button from "components/common/Button";
import ReactTimePicker from "react-time-picker";
import Panel from "components/common/Panel";
import Alert from "components/common/Alert";
import "./styles.scss";

const EditEmployeeModal = ({
  type,
  employeeTeamTags,
  name,
  employeeNumber,
  photo,
  phoneNumber,
  workingHours,
  printerNumber,
  printerCopies,
  availability,
  onSubmit,
  onClose,
  teamTags,
  dealershipWorkingHours,
  dmsType,
  remoteUsers,
  printers,
}) => {
  const [employeeName, setEmployeeName] = useState(name);
  const [employeePhoneNumber, setEmployeePhoneNumber] = useState(phoneNumber);
  const [employeePhoto, setEmployeePhoto] = useState(photo);
  const [employeeWorkingHours, setEmployeeWorkingHours] = useState(workingHours);
  const [photoForUpload, setPhotoForUpload] = useState(null);
  const [deletePhoto, setDeletePhoto] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [employeeAvailability, setEmployeeAvailability] = useState(availability);
  const [currentDay, setCurrentDay] = useState("mon");
  const [employeeTeamTagsValue, setEmployeeTeamTagsValue] = useState(employeeTeamTags);
  const [printerNumberValue, setPrinterNumberValue] = useState(printerNumber);
  const [printerCopiesValue, setPrinterCopiesValue] = useState(printerCopies);
  const [emailItem, setEmailItem] = useState(null);
  const [options, setSelectOptions] = useState([]);
  const [printerOptions, setPrinterOptions] = useState([]);

  useEffect(() => {
    if (remoteUsers) {
      const usersType = type === "mobile" ? "mobile_technician" : "driver";
      const filteredUsers = remoteUsers.filter(
        (user) => (!user.name || user.name === employeeName) && user.role === usersType,
      );
      const preparedOptions = filteredUsers.map(((user) => {
        return {
          label: user.email,
          value: user.id,
          name: user.name,
        };
      }));
      setSelectOptions(preparedOptions);
    }
  }, [remoteUsers]);

  useEffect(() => {
    setEmailItem(options.find((item) => item.name === employeeName));
  }, [options, remoteUsers]);

  useEffect(() => {
    if (printers) {
      setPrinterOptions(printers.map(((pr) => {
        return {
          label: `${pr.printer_id} - ${pr.description}`,
          value: pr.printer_id,
        };
      })));
    }
  }, [printers]);

  useEffect(() => {
    const selectedPrinter = printerOptions.find((item) => item.value === printerNumberValue);
    if (selectedPrinter) {
      setPrinterNumberValue(selectedPrinter);
    } else if (printerNumberValue && dmsType === "dealer_track") {
      setPrinterNumberValue(
        {
          label: printerNumberValue,
          value: printerNumberValue,
        },
      );
    }
  }, [printers]);

  const applyForAllDaysRef = useRef(null);
  const modalWindowRef = useRef(null);

  const validateFromBeforeTo = () => {
    let wrongHoursError = null;

    Object.keys(employeeWorkingHours).forEach((dayName) => {
      for (let i = 0; i < employeeWorkingHours[dayName].length; i++) {
        if (dealershipWorkingHours[dayName].from !== dealershipWorkingHours[dayName].to) {
          const fromHourValue = parseInt(employeeWorkingHours[dayName][i].from, 10);
          const toHourValue = parseInt(employeeWorkingHours[dayName][i].to, 10);
          const dealershipWorkingHoursFrom = parseInt(dealershipWorkingHours[dayName].from, 10);
          const dealershipWorkingHoursTo = parseInt(dealershipWorkingHours[dayName].to, 10);

          if (fromHourValue > toHourValue) {
            wrongHoursError = `Employee should start the work before the end of it. (${WEEK_DAYS[dayName]})`;
            setErrorMessage(wrongHoursError);
            setShowError(true);
          }

          if (!employeeWorkingHours[dayName][i].from || !employeeWorkingHours[dayName][i].to) {
            wrongHoursError = `Please check time frames on ${WEEK_DAYS[dayName]}.`;
            setErrorMessage(wrongHoursError);
            setShowError(true);
          }

          if (i > 0) {
            const prevTo = parseInt(employeeWorkingHours[dayName][i - 1].to, 10);

            if (fromHourValue < prevTo) {
              wrongHoursError = `Please check time frames on ${WEEK_DAYS[dayName]}.`;
              setErrorMessage(wrongHoursError);
              setShowError(true);
            }
          }

          if (
            fromHourValue < dealershipWorkingHoursFrom
            || dealershipWorkingHoursTo < toHourValue
          ) {
            wrongHoursError = `Time frames out of for dealership working hours on ${WEEK_DAYS[dayName]}.`;
            setErrorMessage(wrongHoursError);
            setShowError(true);
          }
        }
      }
    });

    return isNil(wrongHoursError);
  };

  const validateNamePresent = () => {
    let nameBlankError = null;

    if (isNil(employeeName) || isEmpty(employeeName)) {
      nameBlankError = "Name should be present";
      setErrorMessage(nameBlankError);
      setShowError(true);
    }

    return isNil(nameBlankError);
  };

  const validatePhoneNumberPresent = () => {
    let phoneNumberBlankError = null;

    if (isNil(employeePhoneNumber) || isEmpty(employeePhoneNumber)) {
      phoneNumberBlankError = "Phone number should be present";
      setErrorMessage(phoneNumberBlankError);
      setShowError(true);
    }

    return isNil(phoneNumberBlankError);
  };

  const validatePhoneNumberFormat = () => {
    let phoneNumberFormatError = null;

    // eslint-disable-next-line no-restricted-globals
    const extractedNumber = !isNaN(employeePhoneNumber)
      ? extractPhoneNumberFromString(employeePhoneNumber)
      : "";

    if (!phoneNumberLengthValidator(extractedNumber, dmsType)) {
      phoneNumberFormatError = "Invalid phone number given";
      setErrorMessage(phoneNumberFormatError);
      setShowError(true);
    }

    return isNil(phoneNumberFormatError);
  };

  const submit = () => {
    setErrorMessage(null);
    setShowError(false);
    setShowSuccess(false);

    if (
      validateFromBeforeTo()
      && validateNamePresent()
      && (
        type === "advisor"
        || (validatePhoneNumberPresent() && validatePhoneNumberFormat())
      )
    ) {
      onSubmit(
        formatInput(employeeName),
        employeePhoneNumber,
        photoForUpload,
        deletePhoto,
        employeeWorkingHours,
        employeeAvailability,
        employeeTeamTagsValue.map(({ id }) => id),
        printerNumberValue?.value || printerNumberValue,
        printerCopiesValue,
        emailItem,
      );
      onClose();
    }
  };

  const handlePhotoChange = (e) => {
    const newPhoto = e.target.files[0];
    const photoUrl = URL.createObjectURL(newPhoto);
    setPhotoForUpload(newPhoto);
    setEmployeePhoto({ url: photoUrl });
    setDeletePhoto(false);
    setShowError(false);
    setShowSuccess(true);
  };

  const capitalizeFirstLetter = (value) => value.charAt(0).toUpperCase() + value.slice(1);

  const setDropDownPosition = () => {
    const modalWindowRect = modalWindowRef.current.getBoundingClientRect();
    const modalBody = modalWindowRef.current.querySelector(".conciergeModalBody");
    const inputWrappers = modalWindowRef.current.querySelectorAll(".multiTagListInner");

    inputWrappers.forEach((inputWrapper) => {
      const inputWrapperRect = inputWrapper.getBoundingClientRect();
      const element = inputWrapper.querySelector(".multiTagSelectMenu");

      if (
        (inputWrapperRect.bottom + 180) < window.innerHeight
        && modalBody.scrollHeight === modalBody.clientHeight
      ) {
        element.style.position = "fixed";
        element.style.removeProperty("top");
        element.style.removeProperty("bottom");
      } else if ((inputWrapperRect.bottom + 180) < modalWindowRect.bottom) {
        element.style.position = "absolute";
        element.style.top = "35px";
        element.style.removeProperty("bottom");
      } else {
        element.style.position = "absolute";
        element.style.bottom = "35px";
        element.style.removeProperty("top");
      }
    });
  };

  const sizeChangeHandler = (value) => {
    setDropDownPosition();

    if (value) {
      modalWindowRef.current.querySelector(".conciergeModalBody")
        .addEventListener("scroll", setDropDownPosition);
      window.addEventListener("resize", setDropDownPosition);
    } else {
      modalWindowRef.current.querySelector(".conciergeModalBody")
        .removeEventListener("scroll", setDropDownPosition);
      window.removeEventListener("resize", setDropDownPosition);
    }
  };

  const workingHoursHeader = (
    <div className="display-flex align-items-center">
      <p>{WEEK_DAYS[currentDay]}</p>
      <div className="editEmployeeModalDayOff">
        <input
          type="checkbox"
          id="dayOff"
          name="dayOff"
          checked={employeeWorkingHours[currentDay].length === 0}
          onClick={() => {
            const employeeWorkingHoursCopy = JSON.parse(JSON.stringify(employeeWorkingHours));

            if (employeeWorkingHours[currentDay].length === 0) {
              employeeWorkingHoursCopy[currentDay] = [{
                from: null,
                to: null,
              }];
            } else {
              employeeWorkingHoursCopy[currentDay] = [];
            }

            setEmployeeWorkingHours({ ...employeeWorkingHoursCopy });
          }}
        />
        <label htmlFor="dayOff">Day off</label>
      </div>
    </div>
  );

  return (
    <Modal
      ref={modalWindowRef}
      className="editEmployeeModal"
      title={name}
      subtitle={employeeNumber}
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="large"
      onCancel={onClose}
      onSubmit={submit}
    >
      <div className="capacitySettingsEditEmployeeModalStatusContainer">
        {showSuccess && (
          <Alert
            variant="success"
            text="New photo was successfully uploaded. Don&apos;t forget to save it."
            onClick={() => setShowSuccess(false)}
          />
        )}
        {showError && (
          <Alert variant="error" text={errorMessage} onClick={() => setShowError(false)} />
        )}
      </div>
      <Block
        className="conciergeSettingsPageBlock capacitySettingsEditEmployeeModalPhotoBlock"
      >
        <ImageInput
          isRounded
          onImageChange={handlePhotoChange}
          isEditing
          inputName="employeePhotoInput"
          image={employeePhoto}
          alt="concierge employee photo"
          noImageText="No Photo"
        />
        <div className="capacitySettingsEditEmployeeModalSeparator">&nbsp;</div>
        {type === "advisor" && (
          <div className="capacitySettingsEditEmployeeModalAvailability">
            <div className="capacitySettingsEditEmployeeModalAvailabilityTitle">Availability</div>
            <div className="capacitySettingsEditEmployeeModalAvailabilityOptions">
              <button
                type="button"
                className={cx("capacitySettingsEditEmployeeModalAvailabilityOption", {
                  availabilityOptionActive: employeeAvailability === "unavailable",
                })}
                onClick={() => setEmployeeAvailability("unavailable")}
              >
                Not available
              </button>
              <button
                type="button"
                className={cx("capacitySettingsEditEmployeeModalAvailabilityOption", {
                  availabilityOptionActive: employeeAvailability === "publicly_available",
                })}
                onClick={() => setEmployeeAvailability("publicly_available")}
              >
                Public
              </button>
              <button
                type="button"
                className={cx("capacitySettingsEditEmployeeModalAvailabilityOption", {
                  availabilityOptionActive: employeeAvailability === "internally_available",
                })}
                onClick={() => setEmployeeAvailability("internally_available")}
              >
                Internal Only
              </button>
            </div>
          </div>
        )}
        {(type === "driver" || type === "mobile") && (
          <>
            <Input
              label="Name"
              value={employeeName}
              onChange={setEmployeeName}
            />
            <Input
              label="Phone number"
              value={employeePhoneNumber}
              onChange={setEmployeePhoneNumber}
            />
            <div>
              <div className="userProfileTitle">User profile</div>
              <Select
                isClearable
                className="userProfile"
                classNamePrefix="userProfileSelect"
                placeholder="Select or search"
                noOptionsMessage={() => "No emails found."}
                options={options}
                value={emailItem}
                onChange={setEmailItem}
              />
            </div>
          </>
        )}
      </Block>
      {type === "advisor" && (
        <Block
          className="conciergeSettingsPageBlock capacitySettingsEditEmployeeModalPhotoBlock"
        >
          {employeeTeamTags && (
            <MultiTagSelect
              label="Team tags"
              options={teamTags}
              value={employeeTeamTagsValue}
              onChange={(selected) => {
                setEmployeeTeamTagsValue(selected);
              }}
              changeVisibilityAction={sizeChangeHandler}
            />
          )}
        </Block>
      )}
      <Block title="Typical schedule">
        <div className="editEmployeeModalDaySelector">
          {Object.keys(employeeWorkingHours).map((day) => (
            <Button
              variant={currentDay === day ? "aqua" : "neutral"}
              padding="small"
              onClick={() => setCurrentDay(day)}
              disabled={dealershipWorkingHours[day].from === dealershipWorkingHours[day].to}
            >
              {capitalizeFirstLetter(day)}
            </Button>
          ))}
        </div>
        {employeeWorkingHours && employeeWorkingHours[currentDay] && (
          <Panel
            className="editEmployeeModalConciergePanel"
            header={workingHoursHeader}
          >
            <div className="base-margin">
              <div className="display-flex align-items-center">
                <div>
                  {employeeWorkingHours
                    && Object.values(employeeWorkingHours[currentDay]).map((item, index) => (
                      <div className="display-flex align-items-center editEmployeeModalTimeSelectSection">
                        <ReactTimePicker
                          className="timeFieldInput"
                          value={item.from}
                          disableClock
                          format="hh:mma"
                          clearIcon={null}
                          onChange={(value) => {
                            const employeeWorkingHoursCopy = JSON.parse(
                              JSON.stringify(employeeWorkingHours),
                            );
                            employeeWorkingHoursCopy[currentDay][index].from = value;
                            setEmployeeWorkingHours({ ...employeeWorkingHoursCopy });
                          }}
                          disabled={(
                            dealershipWorkingHours[currentDay].from
                            === dealershipWorkingHours[currentDay].to
                          )}
                        />
                        <hr />
                        <ReactTimePicker
                          className="timeFieldInput"
                          value={item.to}
                          disableClock
                          format="hh:mma"
                          clearIcon={null}
                          onChange={(value) => {
                            const employeeWorkingHoursCopy = JSON.parse(
                              JSON.stringify(employeeWorkingHours),
                            );
                            employeeWorkingHoursCopy[currentDay][index].to = value;
                            setEmployeeWorkingHours({ ...employeeWorkingHoursCopy });
                          }}
                          disabled={(
                            dealershipWorkingHours[currentDay].from
                            === dealershipWorkingHours[currentDay].to
                          )}
                        />
                        <Button
                          leftIcon="close"
                          padding="small"
                          onClick={() => {
                            const employeeWorkingHoursCopy = JSON.parse(
                              JSON.stringify(employeeWorkingHours),
                            );
                            employeeWorkingHoursCopy[currentDay].splice(index, 1);
                            setEmployeeWorkingHours({ ...employeeWorkingHoursCopy });
                          }}
                        />
                      </div>
                    ))}
                </div>
                {employeeWorkingHours[currentDay].length > 0 && (
                  <>
                    <div className="capacitySettingsEditEmployeeModalSeparator" />
                    <input
                      ref={applyForAllDaysRef}
                      type="checkbox"
                      id="applyForAllDays"
                      name="applyForAllDays"
                      onClick={() => {
                        if (applyForAllDaysRef.current.checked) {
                          const workingHoursCopy = JSON.parse(
                            JSON.stringify(employeeWorkingHours),
                          );

                          Object.keys(workingHoursCopy).forEach((key) => {
                            if (
                              dealershipWorkingHours[key].from !== dealershipWorkingHours[key].to
                            ) {
                              workingHoursCopy[key] = workingHoursCopy[currentDay];
                            }
                          });

                          setEmployeeWorkingHours({ ...workingHoursCopy });
                        }
                      }}
                    />
                    <label htmlFor="applyForAllDays">Apply for all days</label>
                  </>
                )}
              </div>
              <div className={employeeWorkingHours[currentDay].length > 0 ? "padding-top" : ""}>
                {Object.keys(employeeWorkingHours[currentDay]).length < 3 && (
                  <Button
                    className="editEmployeeModalSplitTime"
                    padding="small"
                    onClick={() => {
                      setEmployeeWorkingHours({
                        ...employeeWorkingHours,
                        [currentDay]: [
                          ...employeeWorkingHours[currentDay],
                          {
                            from: undefined,
                            to: undefined,
                          },
                        ],
                      });
                    }}
                  >
                    + Split time
                  </Button>
                )}
              </div>
            </div>
          </Panel>
        )}
      </Block>
      {type === "advisor" && (
        <Block title="Printer settings">
          <div className="editEmployeeModalPrinterSettingsContainer">
            {dmsType === "dealer_track" && (
              <div>
                <div className="userProfileTitle">Printer number</div>
                <Select
                  className="userProfile"
                  classNamePrefix="userProfileSelect"
                  placeholder="Select or search"
                  noOptionsMessage={() => "No printers found."}
                  options={printerOptions}
                  value={printerNumberValue}
                  onChange={(item) => setPrinterNumberValue(
                    {
                      label: item.label,
                      value: item.value,
                    },
                  )}
                />
              </div>
            )}
            {dmsType === "cdk" && (
              <>
                <Input
                  label="Printer number"
                  value={printerNumberValue}
                  onChange={setPrinterNumberValue}
                />
                <Input
                  label="Printer copies"
                  value={printerCopiesValue}
                  onChange={setPrinterCopiesValue}
                />
              </>
            )}
          </div>
        </Block>
      )}
    </Modal>
  );
};

EditEmployeeModal.propTypes = {
  type: oneOf([
    "employee",
    "driver",
    "mobile",
  ]).isRequired,
  teamTags: arrayOf(shape),
  remoteUsers: arrayOf(shape),
  employeeTeamTags: arrayOf(shape),
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  name: string.isRequired,
  availability: string,
  employeeNumber: string.isRequired,
  printerNumber: string,
  printerCopies: string,
  photo: photoPropType.isRequired,
  phoneNumber: string,
  workingHours: workingHoursPropType,
  dmsType: string,
  dealershipWorkingHours: workingHoursPropType.isRequired,
  printers: arrayOf(shape).isRequired,
};

EditEmployeeModal.defaultProps = {
  teamTags: [],
  remoteUsers: [],
  employeeTeamTags: [],
  workingHours: {},
  printerNumber: null,
  printerCopies: null,
  phoneNumber: "",
  availability: "",
  dmsType: null,
};

const mapStateToProps = (state) => {
  return {
    dealershipWorkingHours: settingsDealershipWorkingHoursSelector(state),
    teamTags: settingsTeamTagsSelector(state),
    dmsType: dmsTypeSelector(state),
    remoteUsers: settingsRemoteUsersSelector(state),
    printers: settingsPrintersSelector(state),
  };
};

export default connect(mapStateToProps, null)(EditEmployeeModal);
