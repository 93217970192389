import React from "react";
import {
  instanceOf, objectOf, shape, string,
} from "prop-types";
import { format } from "date-fns";

import { AMERICAN_DATE_FORMAT } from "shared/utils/datetime";

import Panel from "components/common/Panel";
import VehicleDetailsBlock from "./VehicleDetailsBlock";
import ClientDetailsBlock from "./ClientDetailsBlock";

import styles from "./styles.module.scss";

const VehicleClientBlock = ({ customer, vehicle, time }) => (
  (
    <Panel className={styles.panel}>
      <section className={styles.row}>
        <VehicleDetailsBlock
          vehicle={vehicle}
          time={time}
        />
      </section>
      <section className={styles.row}>
        <ClientDetailsBlock
          customer={customer}
        />
      </section>
    </Panel>
  )
);

VehicleClientBlock.propTypes = {
  customer: objectOf(string),
  vehicle: objectOf(string),
  time: shape({
    day: shape({
      full_date: instanceOf(Date),
    }),
  }),
};

VehicleClientBlock.defaultProps = {
  customer: null,
  vehicle: null,
  time: null,
};

const BookingCreatedAt = ({ time: { day: { full_date } } }) => (
  <section>
    <span className={styles.createdLabel}>Booking created: </span>
    <span className={styles.createdTime}>
      {format(full_date, AMERICAN_DATE_FORMAT)}
    </span>
  </section>
);

BookingCreatedAt.propTypes = {
  full_date: string,
  time: shape({
    day: shape({
      full_date: instanceOf(Date),
    }),
  }),
};

BookingCreatedAt.defaultProps = {
  full_date: null,
  time: null,
};

export default VehicleClientBlock;
