import React, { useEffect, useState } from "react";
import {
  arrayOf, func, string,
} from "prop-types";

import { BOOKING_STEP_TIMESLOT } from "shared/constants";
import {
  serviceAdvisorPropType,
  servicePropType,
} from "shared/prop-types";
import Button from "components/common/Button";

import firstAvailableAdvisorIcon from "assets/images/firstAvailableAdvisor.svg";
import firstAvailableAdvisorActiveIcon from "assets/images/firstAvailableAdvisorActive.svg";
import EmployeeSelectionCarousel from "components/common/EmployeeSelectionCarousel";

import styles from "./styles.module.scss";

const ChooseAdvisor = ({
  teamServiceAdvisors,
  selectedServices,
  selectedRecalls,
  setSchedulingStep,
  makeAdvisorSelection,
  fetchTeamAdvisorsForServices,
  preselectedAdvisor,
  setChosenQuarterSlot,
  clearSlots,
  initialAdvisor,
}) => {
  const [advisors, setAdvisors] = useState([]);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [servicesId, setServicesId] = useState([]);
  const [showFirstAvailable, setShowFirstAvailable] = useState(false);

  useEffect(() => {
    const serviceAdvisors = [...teamServiceAdvisors].sort(
      (a, b) => a.name.localeCompare(b.name),
    );
    const availableAdvisorIds = serviceAdvisors.map((item) => item.id);

    const phantomAdvisorIds = serviceAdvisors
      .filter((item) => item.is_phantom)
      .map((item) => item.id);

    if (serviceAdvisors.length > 1 || phantomAdvisorIds.length > 0) {
      const firstAvailableAdvisor = {
        id: "firstAvailable",
        name: "First Available",
        photo: firstAvailableAdvisorIcon,
        availableAdvisorIds,
        phantomAdvisorIds,
      };

      serviceAdvisors.unshift(firstAvailableAdvisor);
      setShowFirstAvailable(true);
    }

    if (
      preselectedAdvisor
      && !preselectedAdvisor.is_phantom
      && availableAdvisorIds.indexOf(preselectedAdvisor.id) !== -1
    ) {
      setSelectedAdvisor(preselectedAdvisor);
    } else if (availableAdvisorIds.length > 0) {
      setSelectedAdvisor(serviceAdvisors[0]);

      if (serviceAdvisors[0].id === "firstAvailable") {
        serviceAdvisors[0].photo = firstAvailableAdvisorActiveIcon;
      }

      if (preselectedAdvisor?.is_phantom) {
        makeAdvisorSelection(serviceAdvisors[0]);
      }
    } else {
      setSelectedAdvisor(null);
    }

    setAdvisors(serviceAdvisors.filter((advisor) => !advisor.is_phantom));
  }, [preselectedAdvisor, teamServiceAdvisors]);

  useEffect(() => {
    if (advisors.length > 2 && advisors[0].id === "firstAvailable") {
      if (selectedAdvisor.id === "firstAvailable") {
        advisors[0].photo = firstAvailableAdvisorActiveIcon;
      } else {
        advisors[0].photo = firstAvailableAdvisorIcon;
      }
    }
  }, [selectedAdvisor]);

  useEffect(() => {
    const ids = selectedServices.map((item) => item.id)
      .concat(selectedRecalls.map((item) => item.id));
    let needToFetchTeamAdvisors = ids.length !== servicesId.length;

    if (!needToFetchTeamAdvisors) {
      ids.forEach((id) => {
        if (servicesId.indexOf(id) === -1) {
          needToFetchTeamAdvisors = true;
        }
      });
    }

    if (needToFetchTeamAdvisors) {
      fetchTeamAdvisorsForServices();
      setServicesId(ids);
    }
  }, [selectedServices, selectedRecalls]);

  const confirmSelectedAdvisor = () => {
    makeAdvisorSelection(selectedAdvisor);
    setSchedulingStep(BOOKING_STEP_TIMESLOT);
  };

  return (
    <>
      <EmployeeSelectionCarousel
        showFirstAvailable={showFirstAvailable}
        employees={advisors}
        selectedEmployee={selectedAdvisor}
        onClickHandler={(serviceAdvisor) => {
          setChosenQuarterSlot({});
          clearSlots();
          setSelectedAdvisor(serviceAdvisor);
        }}
      />
      <div className={styles.buttons}>
        <div className={styles.current}>
          {initialAdvisor && (
            <span className={styles.currentText}>
              {`Initially selected: ${initialAdvisor}`}
            </span>
          )}
        </div>
        <Button
          variant="brand"
          disabled={!selectedAdvisor}
          onClick={confirmSelectedAdvisor}
        >
          Done
        </Button>
      </div>
    </>
  );
};

ChooseAdvisor.propTypes = {
  teamServiceAdvisors: arrayOf(serviceAdvisorPropType),
  setSchedulingStep: func.isRequired,
  makeAdvisorSelection: func.isRequired,
  selectedServices: arrayOf(servicePropType),
  selectedRecalls: arrayOf(servicePropType),
  fetchTeamAdvisorsForServices: func.isRequired,
  preselectedAdvisor: serviceAdvisorPropType,
  setChosenQuarterSlot: func.isRequired,
  clearSlots: func.isRequired,
  initialAdvisor: string.isRequired,
};

ChooseAdvisor.defaultProps = {
  teamServiceAdvisors: [],
  selectedServices: [],
  selectedRecalls: [],
  preselectedAdvisor: null,
};

export default ChooseAdvisor;
