import { combineReducers } from "redux";

import { AUTH_LOG_OUT } from "store/actions/auth-actions";

import app from "./app-reducer";
import checkinApp from "./checkin-app-reducer";
import checkinChat from "./checkin-chat-reducer";
import auth from "./auth-reducer";
import bookings from "./bookings-reducer";
import booking from "./booking-details-reducer";
import dealerships from "./dealerships-reducer";
import dealership from "./dealership-details-reducer";
import menuTemplates from "./menu-templates-reducer";
import menuTemplate from "./menu-template-reducer";
import scheduling from "./scheduling-reducer";
import schedulingProcess from "./scheduling-process-reducer";
import settings from "./settings-reducer";
import tableOptions from "./table-options-reducer";
import reports from "./reporting-reducer";
import remoteService from "./remote-service-reducer";

const reducers = combineReducers({
  app,
  checkinApp,
  checkinChat,
  auth,
  bookings,
  booking,
  dealerships,
  dealership,
  menuTemplates,
  menuTemplate,
  scheduling,
  schedulingProcess,
  settings,
  tableOptions,
  reports,
  remoteService,
});

const rootReducer = (state, action) => {
  const newState = action.type === AUTH_LOG_OUT ? undefined : state;

  return reducers(newState, action);
};

export default rootReducer;
