import { connect } from "react-redux";

import {
  initChecksJobDistance,
  resetJobReachable,
  setCurrentStep,
  setIsDistanceLimitIgnored,
  setIsDropOff,
  setIsMobileTechnician,
  setIsPickUp,
  setRemoteServiceLocation,
  setServiceAdvisor,
} from "store/actions/booking-details-actions";

import {
  settingsDealershipLatitudeLongitudeSelector,
  settingsMobileTechnicianIntegrationSegmentsNumberSelector,
  settingsPickupIntegrationSegmentsNumberSelector,
  settingsTeamServiceAdvisorsSelector,
} from "store/selectors/settings-selectors";

import {
  bookingIsJobReachableLoading,
  bookingJobDropOffReachable,
  bookingJobReachable,
} from "store/selectors/booking-details-selectors";

import ChooseRemoteService from "./index";

const mapStateToProps = (state) => {
  return {
    isJobReachableLoading: bookingIsJobReachableLoading(state),
    jobReachable: bookingJobReachable(state),
    jobDropOffReachable: bookingJobDropOffReachable(state),
    teamServiceAdvisors: settingsTeamServiceAdvisorsSelector(state),
    dealershipPosition: settingsDealershipLatitudeLongitudeSelector(state),
    segmentsMobileTechnicianNumber:
    settingsMobileTechnicianIntegrationSegmentsNumberSelector(state),
    segmentsNumber: settingsPickupIntegrationSegmentsNumberSelector(state),
  };
};

const actions = {
  setSchedulingStep: setCurrentStep,
  checksJobDistance: initChecksJobDistance,
  resetJobReachableData: resetJobReachable,
  schedulingPickUp: setIsPickUp,
  schedulingDropOff: setIsDropOff,
  schedulingMobileTechnician: setIsMobileTechnician,
  schedulingDistanceLimitIgnored: setIsDistanceLimitIgnored,
  setServiceLocation: setRemoteServiceLocation,
  makeAdvisorSelection: setServiceAdvisor,
};

export default connect(mapStateToProps, actions)(ChooseRemoteService);
