const customerFields = [
  {
    id: "first_name",
    type: "text",
    label: "First name",
    required: true,
  },
  {
    id: "last_name",
    type: "text",
    label: "Last name",
    required: true,
  },
  {
    id: "company_name",
    type: "text",
    label: "Company name",
    required: true,
  },
  {
    id: "email",
    type: "email",
    label: "E-mail",
    required: true,
  },
  {
    id: "address_line1",
    type: "text",
    label: "Address line",
    required: true,
  },
  {
    id: "address_state",
    type: "text",
    label: "State",
    required: true,
  },
  {
    id: "address_zipcode",
    type: "text",
    label: "Zipcode",
    pattern: "[0-9]{5}",
    maxLength: 5,
    required: true,
  },
  {
    id: "address_city",
    type: "text",
    label: "City",
    required: true,
  },
  {
    id: "internal_note",
    type: "text",
    label: "Customer Internal Note",
    required: false,
  },
];

export const newCustomerFields = [
  {
    id: "customer_type",
    type: "text",
    label: "Customer Type",
    required: true,
  },
];

export const customerFieldsCDK = [
  ...customerFields,
  {
    id: "phone_number",
    type: "string",
    label: "Phone number (7 or 10 signs)",
    pattern: "^([0-9]{7}|[0-9]{10})$",
    maxLength: 10,
    required: true,
  },
];

export const addCustomerFieldsCDK = newCustomerFields.concat(customerFieldsCDK);

export const customerFieldsDT = [
  ...customerFields,
  {
    id: "phone_number",
    type: "string",
    label: "Phone number (9 or 10 signs)",
    pattern: "^([0-9]{9}|[0-9]{10})$",
    maxLength: 10,
    required: true,
  },
];

export const vehicleFields = [
  {
    id: "vin",
    type: "text",
    label: "VIN",
    pattern: ".{17}",
    required: false,
  },
  {
    id: "mileage",
    type: "text",
    pattern: "^([0-9]{0,7})$",
    label: "Mileage",
    required: true,
  },
  {
    id: "makeModelYear",
    items: [
      {
        id: "make",
        type: "text",
        label: "Make",
        required: true,
      },
      {
        id: "model",
        type: "text",
        label: "Model",
        required: true,
      },
      {
        id: "model_year",
        type: "text",
        label: "Model year",
        pattern: "^([0-9]{4})$",
        required: true,
      },
    ],
  },
  {
    id: "external_model_code",
    type: "text",
    label: "CDK Model Code",
  },
  {
    id: "plate_number",
    type: "text",
    label: "Plate number (optional)",
    pattern: "^[A-Za-z0-9]+$",
  },
  {
    id: "region",
    type: "text",
    label: "Region (optional)",
  },
];

export const updateLocalVehicleFields = [
  {
    id: "mileage",
    type: "text",
    label: "Mileage",
    pattern: "^([0-9]{0,7})$",
    required: true,
  },
  {
    id: "vin",
    type: "text",
    label: "VIN",
    pattern: ".{17}",
    required: true,
  },
];

export const updateVehicleFields = [
  {
    id: "vin",
    type: "text",
    label: "VIN",
    pattern: ".{17}",
    required: false,
  },
  {
    id: "makeModelYear",
    items: [
      {
        id: "make",
        type: "text",
        label: "Make",
        required: true,
      },
      {
        id: "model",
        type: "text",
        label: "Model",
        required: true,
      },
      {
        id: "model_year",
        type: "text",
        label: "Model year",
        pattern: "^([0-9]{4})$",
        required: true,
      },
    ],
  },
  {
    id: "mileage",
    type: "text",
    pattern: "^([0-9]{0,7})$",
    label: "Mileage",
    required: true,
  },
  {
    id: "external_model_code",
    type: "text",
    label: "CDK Model Code",
  },
  {
    id: "plate_number",
    type: "text",
    label: "Plate number (optional)",
    pattern: "^[A-Za-z0-9]+$",
    required: false,
  },
];

export const dealeshipFields = [
  {
    id: "name",
    type: "text",
    label: "Dealership's name",
    required: true,
  },
  {
    id: "time_zone",
    type: "text",
    label: "Dealership's timezone",
    required: true,
  },
  {
    id: "address_state",
    type: "text",
    label: "Dealership's state",
    required: true,
  },
  {
    id: "address_line",
    type: "text",
    label: "Dealership's address line",
    required: true,
  },
  {
    id: "address_zipcode",
    type: "text",
    label: "Dealership's zipcode",
    pattern: "[0-9]{5}",
    maxLength: 5,
    required: true,
  },
  {
    id: "address_city",
    type: "text",
    label: "Dealership's city",
    required: true,
  },
  {
    id: "email",
    type: "email",
    label: "Dealership admin's email",
    required: true,
  },
];
