import {
  format, isAfter, isBefore, isEqual, isToday, parse,
} from "date-fns";
import { listTimeZones } from "timezone-support";
import { convertToTimeZone, parseFromTimeZone } from "date-fns-timezone";
import moment from "moment-timezone";

export const DEFAULT_TIME_FORMAT_WITHOUT_TIMEZONE = "YYYY-MM-DDTHH:mm:ss";
export const DEFAULT_TIME_FORMAT_WITH_TIMEZONE = "YYYY-MM-DDTHH:mm:ssZ";
export const APPOINTMENT_DATETIME_FORMAT = "MM/DD/YY hh:mm A";
export const APPOINTMENT_TIME_FIRST_FORMAT = "hh:mm A MM/DD/YY";
export const UPCOMING_APPOINTMENT_DATETIME_FORMAT = "hh:mm A";
export const DATE_FORMAT = "YYYY-MM-DD";
export const BOOKINGS_DATEPICKER_DATE_FORMAT = "DD MMM YYYY";
export const BOOKING_CREATED_AT_FORMAT = "D MMMM YYYY";
export const BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3}$/;
export const YEAR_FORMAT = "YYYY";
export const WEEK_DAY_FORMAT = "ddd";
export const MONTH_DAY_FORMAT = "D";
export const MONTH_FORMAT = "MMM";
export const AMERICAN_DATE_FORMAT = "MM/DD/YYYY";
export const AMERICAN_DATETIME_FORMAT = "MM/DD/YYYY hh:mm A";
export const AMERICAN_DATE_FORMAT_REGEXP = /^\s*(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20)\d{2})\s*$/g;

export const getAppointmentFormattedDate = (date) => {
  if (isToday(date)) {
    return format(date, UPCOMING_APPOINTMENT_DATETIME_FORMAT);
  }
  return format(date, APPOINTMENT_DATETIME_FORMAT);
};

export const getAppointmentFormattedDateTimeFirst = (date) => {
  if (isToday(date)) {
    return format(date, UPCOMING_APPOINTMENT_DATETIME_FORMAT);
  }
  return format(date, APPOINTMENT_TIME_FIRST_FORMAT);
};

export const datesSortingMethod = (dateA, dateB) => {
  if ((isAfter(dateA, dateB))) {
    return 1;
  }
  if (isBefore(dateA, dateB)) {
    return -1;
  }
  return 0;
};

export const formatMenuTemplateDate = (date) => {
  if (date) {
    return format(date, APPOINTMENT_DATETIME_FORMAT);
  }
  return date;
};

export const parseBookingsDatePickerDate = (dateString, separator) => {
  const splittedFormat = BOOKINGS_DATEPICKER_DATE_FORMAT.split(separator);
  const splittedDateString = dateString.split(separator);
  const splittedObject = {
    dd: splittedDateString[splittedFormat.indexOf("DD")],
    mmm: splittedDateString[splittedFormat.indexOf("MMM")],
    yyyy: splittedDateString[splittedFormat.indexOf("YYYY")],
  };
  return parse(`${splittedObject.yyyy}-${splittedObject.mmm}-${splittedObject.dd}`);
};

export const parseRemoteDatePickerDate = (dateString, separator) => {
  const splittedFormat = AMERICAN_DATE_FORMAT.split(separator);
  const splittedDateString = dateString.split(separator);
  const splittedObject = {
    dd: splittedDateString[splittedFormat.indexOf("DD")],
    mm: splittedDateString[splittedFormat.indexOf("MM")],
    yyyy: splittedDateString[splittedFormat.indexOf("YYYY")],
  };
  return parse(`${splittedObject.dd}/${splittedObject.mm}/${splittedObject.yyyy}`);
};

export const checkEndDateWithStartDate = (
  parsedEndDate,
  startDate,
  startDateInputValue,
  formatString,
) => {
  if (startDate && format(startDate, formatString) === startDateInputValue) {
    return isAfter(parsedEndDate, startDate)
      || isEqual(parsedEndDate, startDate);
  }
  return true;
};

export const WEEK_DAYS = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

export const weekDaysHumanFormat = (key) => WEEK_DAYS[key];

const validTimezoneParts = [
  "America",
  "Asia",
  "Europe",
  "Pacific",
  "Australia",
  "Indian",
  "Africa",
];
export const availableTimezones = () => (
  listTimeZones()
    .filter((tz) => (
      validTimezoneParts.some((validTz) => (
        tz.includes(validTz)
      ))
    ))
    .sort()
);

export const convertFilterTime = (time, timeZone) => (
  parseFromTimeZone(format(time, DEFAULT_TIME_FORMAT_WITHOUT_TIMEZONE), { timeZone })
);

export const convertPromisedTime = (time, timeZone) => (
  moment.tz(
    time,
    DEFAULT_TIME_FORMAT_WITHOUT_TIMEZONE,
    timeZone,
  ).format()
);

export const changeTimezone = (date, timeZone) => convertToTimeZone(date, { timeZone });

export const convert24HourTo12 = (hour) => hour % 12 || 12;

export const amPmValue = (hour) => (hour < 12 ? "AM" : "PM");

export const leadingZeroHour = (hour) => (hour < 10 ? `0${hour}` : hour);

export const timeInUSFormat = (time) => {
  const [hour, minutes] = time.split(":");

  return `${leadingZeroHour(convert24HourTo12(hour))}:${minutes} ${amPmValue(hour)}`;
};

export const formatTimeInfo = (chosenTimeSlot) => {
  const { day: { day_name, day_of_month }, quarterSlot } = chosenTimeSlot;
  return `${day_name}, ${day_of_month}, ${quarterSlot}`;
};

export const formatTimeInfoUS = (chosenTimeSlot) => {
  if (chosenTimeSlot && !chosenTimeSlot.day) {
    return "";
  }
  const { day: { day_name, day_of_month }, quarterSlot } = chosenTimeSlot;
  const time = timeInUSFormat(quarterSlot);
  return `${day_name}, ${day_of_month}, ${time}`;
};

export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(" ");

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

export const timeToMins = (time) => +time.split(":")[0] * 60 + +time.split(":")[1];
export const fullHoursInMinutes = (minutes) => (minutes / 60) || 0;
export const bulkTimeCompare = (timeLeft, timeRight) => (
  fullHoursInMinutes(timeToMins(timeLeft) - timeToMins(timeRight))
);

export const DEFAULT_TIME_SLOTS = [
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
];

export const THREE_MIN_IN_MILISECONDS = 180000;
