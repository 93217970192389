import {
  createNewCustomer, createNewVehicle, fetchCustomers, updateCustomer, updateVehicle,
} from "shared/api";
import { SCHEDULER_SOURCE } from "shared/constants";
import { authChannelKeySelector, authTokenSelector } from "store/selectors/auth-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";

export const SCHEDULING_SET_CUSTOMERS_LOADING_STATE = "SCHEDULING_SET_CUSTOMERS_LOADING_STATE";
export const SCHEDULING_FETCH_CUSTOMERS_SUCCESS = "SCHEDULING_FETCH_CUSTOMERS_SUCCESS";
export const SCHEDULING_FETCH_CUSTOMERS_FAILURE = "SCHEDULING_FETCH_CUSTOMERS_FAILURE";

export const SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA = "SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA";
export const SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE = "SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE";

export const SCHEDULING_CREATE_VEHICLE_SUCCESS = "SCHEDULING_CREATE_VEHICLE_SUCCESS";
export const SCHEDULING_UPDATE_VEHICLE_SUCCESS = "SCHEDULING_UPDATE_VEHICLE_SUCCESS";
export const SCHEDULING_CREATE_VEHICLE_SUCCESS_WITHOUT_DMS = "SCHEDULING_CREATE_VEHICLE_SUCCESS_WITHOUT_DMS";
export const SCHEDULING_UPDATE_VEHICLE_SUCCESS_WITHOUT_DMS = "SCHEDULING_UPDATE_VEHICLE_SUCCESS_WITHOUT_DMS";

export const SCHEDULING_CREATE_CUSTOMER_SUCCESS = "SCHEDULING_CREATE_CUSTOMER_SUCCESS";
export const SCHEDULING_UPDATE_CUSTOMER_SUCCESS = "SCHEDULING_UPDATE_CUSTOMER_SUCCESS";

export const SCHEDULING_RESET_DATA_EDITION_STATE = "SCHEDULING_RESET_DATA_EDITION_STATE";

const setCustomersLoadingState = (searchPhrase) => {
  return {
    type: SCHEDULING_SET_CUSTOMERS_LOADING_STATE,
    payload: { searchPhrase },
  };
};

export const retrieveCustomers = (searchPhrase, searchType) => (dispatch, getState) => {
  dispatch(setCustomersLoadingState(searchPhrase));

  const token = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealership_id = dealershipIdSelector(getState());

  const params = {
    search_phrase: searchPhrase,
    search_type: searchType,
    channel_key,
  };

  fetchCustomers(dealership_id, params, token);
};

const setSavingState = (customerId) => {
  return {
    type: SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA,
    payload: { customerId },
  };
};

export const sendCustomerData = (data) => (dispatch, getState) => {
  dispatch(setSavingState());

  const token = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealership_id = dealershipIdSelector(getState());

  const params = {
    ...data,
    channel_key,
    dealership_id,
    source: SCHEDULER_SOURCE,
  };
  createNewCustomer(dealership_id, params, token)
    .then((response) => response)
    .catch((error) => {
      dispatch({
        type: SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
        payload: { error: error.errors || error },
      });
    });
};

export const updateCustomerData = (data, customer) => (dispatch, getState) => {
  dispatch(setSavingState(Number(customer.id)));

  const token = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealership_id = dealershipIdSelector(getState());
  const customerData = {};

  Object.keys(data).forEach((key) => {
    customerData[key] = data[key] || null;
  });

  const params = {
    customer: customerData,
    customer_id: customer.id,
    channel_key,
    dealership_id,
  };
  updateCustomer(dealership_id, params, token)
    .then((response) => response)
    .catch((error) => {
      dispatch({
        type: SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
        payload: { error: error.errors || error },
      });
    });
};

export const sendVehicleData = (vehicleData, customerData) => (dispatch, getState) => {
  dispatch(setSavingState(Number(customerData.id)));

  const token = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealership_id = dealershipIdSelector(getState());

  const params = {
    vehicle_set: {
      make: vehicleData.make,
      model: vehicleData.model,
      model_year: Number(vehicleData.model_year),
    },
    vehicle: {
      mileage: Number(vehicleData.mileage),
      customer_id: Number(customerData.id),
      customer_number: customerData.customer_number,
      vin: vehicleData.vin,
      region: vehicleData.region ? vehicleData.region : undefined,
      plate_number: vehicleData.plate_number ? vehicleData.plate_number : undefined,
    },
    external_model_code: {
      model_code: vehicleData.external_model_code,
    },
    channel_key,
    dealership_id,
  };

  createNewVehicle(dealership_id, params, token)
    .then((response) => response)
    .catch((error) => {
      dispatch({
        type: SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
        payload: { error: error.errors || error },
      });
    });
};

export const updateVehicleData = ({
  id,
  mileage,
  vin,
  make,
  model,
  model_year,
  external_model_code,
  plate_number,
}) => (dispatch, getState) => {
  dispatch(setSavingState());

  const token = authTokenSelector(getState());
  const channel_key = authChannelKeySelector(getState());
  const dealership_id = dealershipIdSelector(getState());

  const params = {
    vehicle: {
      mileage,
      vin,
      make,
      model,
      model_year,
      plate_number: plate_number || null,
    },
    external_model_code: {
      model_code: external_model_code,
    },
    id,
    channel_key,
    token,
    dealership_id,
  };

  updateVehicle(dealership_id, params, token)
    .then((response) => response)
    .catch((error) => {
      dispatch({
        type: SCHEDULING_SAVE_CUSTOMER_OR_VEHICLE_DATA_FAILURE,
        payload: { error: error.errors || error },
      });
    });
};

export const resetDataEditionState = () => (dispatch) => {
  dispatch({
    type: SCHEDULING_RESET_DATA_EDITION_STATE,
  });
};
