import React, { useEffect, useRef, useState } from "react";
import uniqid from "uniqid";
import {
  arrayOf, func, node, number, object, oneOfType, string,
} from "prop-types";
import { format } from "date-fns";
import {
  BOOKINGS_DATEPICKER_DATE_FORMAT,
  BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP,
  DATE_FORMAT,
  parseBookingsDatePickerDate,
} from "shared/utils/datetime";

import Modal from "components/common/Modal";
import Button from "components/common/Button";
import Panel from "components/common/Panel";
import DatePicker from "components/common/DatePicker";
import Alert from "components/common/Alert";

import styles from "./styles.module.scss";

const VacationsModal = ({
  name,
  employeeNumber,
  employeeId,
  onClose,
  vacations,
  getVacationsAction,
  addVacationsAction,
  updateVacationsAction,
  deleteVacationsAction,
  timezone,
  vacationError,
}) => {
  const modalWindowRef = useRef(null);

  const [employeePreviousVacations, setEmployeePreviousVacations] = useState([]);
  const [employeeCurrentVacations, setEmployeeCurrentVacations] = useState([]);
  const [employeeFutureVacations, setEmployeeFutureVacations] = useState([]);

  useEffect(() => {
    getVacationsAction(employeeId);
  }, [employeeId, getVacationsAction]);

  useEffect(() => {
    if (vacations && vacations[employeeId]) {
      const previous = [];
      const current = [];
      const future = [];

      vacations[employeeId].forEach((item) => {
        const copyItem = JSON.parse(JSON.stringify(item));

        const start = new Date(`${copyItem.starts_at}T00:00`);
        const end = new Date(`${copyItem.ends_at}T23:59`);

        copyItem.from = copyItem.starts_at;
        copyItem.to = copyItem.ends_at;
        copyItem.rawTo = copyItem.ends_at;

        if (end < new Date()) {
          previous.push(copyItem);
        } else if (start < new Date() && end >= new Date()) {
          current.push(copyItem);
        } else {
          future.push(copyItem);
        }
      });

      setEmployeePreviousVacations(previous);
      setEmployeeCurrentVacations(current);
      setEmployeeFutureVacations(future);
    }
  }, [employeeId, timezone, vacations]);

  const formatDate = (date) => format(date, DATE_FORMAT);

  const applyChnages = (starts_at, ends_at, key, vacationsList) => {
    const start = formatDate(starts_at);
    const end = formatDate(ends_at);

    if (vacationsList[key].id) {
      updateVacationsAction(
        employeeId,
        vacationsList[key].id,
        {
          starts_at: start,
          ends_at: end,
        },
      );
    } else {
      addVacationsAction(employeeId, {
        starts_at: start,
        ends_at: end,
      });
    }
  };

  const handleResetChanges = (vacationId, vacationsList, vacationType) => {
    if (vacationId) {
      deleteVacationsAction(employeeId, vacationId);
    } else {
      const employeeVacations = [...vacationsList];
      employeeVacations.pop();
      if (vacationType === "future") {
        setEmployeeFutureVacations(employeeVacations);
      } else {
        setEmployeeCurrentVacations(employeeVacations);
      }
    }
  };

  const setDropDownPosition = () => {
    const modalWindowRect = modalWindowRef.current.getBoundingClientRect();
    const modalBody = modalWindowRef.current.querySelector(".conciergeModalBody");
    const inputWrappers = modalWindowRef.current.querySelectorAll(".conciergeDatepickerWrapper");

    inputWrappers.forEach((inputWrapper) => {
      const inputWrapperRect = inputWrapper.getBoundingClientRect();
      const element = inputWrapper.querySelector(".conciergeDatepickerCalendarContainer");

      if (
        (inputWrapperRect.bottom + 360) < window.innerHeight
        && modalBody.scrollHeight === modalBody.clientHeight
      ) {
        element.style.position = "fixed";
        element.style.right = "95px";
        element.style.removeProperty("bottom");
      } else if ((inputWrapperRect.bottom + 360) < modalWindowRect.bottom) {
        element.style.position = "absolute";
        element.style.removeProperty("bottom");
        element.style.removeProperty("right");
      } else {
        element.style.position = "absolute";
        element.style.bottom = "50px";
        element.style.removeProperty("right");
      }
    });
  };

  const sizeChangeHandler = (value) => {
    setDropDownPosition();

    if (value) {
      modalWindowRef.current.querySelector(".conciergeModalBody").addEventListener("scroll", setDropDownPosition);
      window.addEventListener("resize", setDropDownPosition);
    } else {
      modalWindowRef.current.querySelector(".conciergeModalBody").removeEventListener("scroll", setDropDownPosition);
      window.removeEventListener("resize", setDropDownPosition);
    }
  };

  return (
    <Modal
      ref={modalWindowRef}
      className={styles.vacationsModal}
      title={name}
      subtitle={employeeNumber}
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="small"
      onCancel={onClose}
      hideFooter
    >
      {vacationError && (
        <Alert
          className={styles.error}
          variant="error"
          text={vacationError?.data?.errors || "Something went wrong"}
        />
      )}
      <Panel
        header="Future vacations"
        className={styles.panel}
      >
        {employeeFutureVacations.map((vacation, key) => (
          <div
            className={styles.periodFilter}
            key={uniqid()}
          >
            <DatePicker
              key={uniqid()}
              rangeMode
              format={BOOKINGS_DATEPICKER_DATE_FORMAT}
              separator="/"
              separatorPositions={[2, 5]}
              formatRegExp={BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP}
              parseDateInFormat={parseBookingsDatePickerDate}
              startDateLabel="From"
              endDateLabel="To"
              onApplyChanges={(starts_at, ends_at) => {
                applyChnages(starts_at, ends_at, key, employeeFutureVacations);
              }}
              onResetChanges={() => {
                handleResetChanges(vacation.id, employeeFutureVacations, "future");
              }}
              previousDates={vacation}
              previousDatesNaming={{
                startDate: "from",
                endDate: "to",
              }}
              timezone={timezone}
              resetButtonLabel="Delete"
              changeVisibilityAction={sizeChangeHandler}
            />
          </div>
        ))}
        <div className={employeeFutureVacations.length > 0 ? "padding-top" : ""}>
          <Button
            className={styles.splitTime}
            padding="small"
            onClick={() => {
              setEmployeeFutureVacations([...employeeFutureVacations, {
                starts_at: null,
                ends_at: null,
              }]);
            }}
          >
            + Add period
          </Button>
        </div>
      </Panel>
      <Panel
        header="Current vacations"
        className={styles.panel}
      >
        {employeeCurrentVacations.length === 0 && (
          <div className={styles.infoBlock}>
            <p>No Current Vacations</p>
          </div>
        )}
        {employeeCurrentVacations.map((vacation, key) => (
          <div
            className={styles.periodFilter}
            key={uniqid()}
          >
            <DatePicker
              key={uniqid()}
              rangeMode
              format={BOOKINGS_DATEPICKER_DATE_FORMAT}
              separator="/"
              separatorPositions={[2, 5]}
              formatRegExp={BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP}
              parseDateInFormat={parseBookingsDatePickerDate}
              startDateLabel="From"
              endDateLabel="To"
              onApplyChanges={(starts_at, ends_at) => {
                applyChnages(starts_at, ends_at, key, employeeCurrentVacations);
              }}
              onResetChanges={() => {
                handleResetChanges(vacation.id, employeeCurrentVacations, "current");
              }}
              previousDates={vacation}
              previousDatesNaming={{
                startDate: "starts_at",
                endDate: "ends_at",
              }}
              timezone={timezone}
              resetButtonLabel="Delete"
              changeVisibilityAction={sizeChangeHandler}
            />
          </div>
        ))}
      </Panel>
      <Panel
        header="Previous vacations"
        className={styles.panel}
      >
        {employeePreviousVacations.length === 0 && (
          <div className={styles.infoBlock}>
            <p>No Previous Vacations</p>
          </div>
        )}
        {employeePreviousVacations.map((vacation) => (
          <div
            className={styles.periodFilter}
            key={uniqid()}
          >
            <DatePicker
              rangeMode
              disabled
              format={BOOKINGS_DATEPICKER_DATE_FORMAT}
              separator="/"
              separatorPositions={[2, 5]}
              formatRegExp={BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP}
              parseDateInFormat={parseBookingsDatePickerDate}
              startDateLabel="From"
              endDateLabel="To"
              previousDates={vacation}
              previousDatesNaming={{
                startDate: "starts_at",
                endDate: "ends_at",
              }}
              timezone={timezone}
              resetButtonLabel="Delete"
              changeVisibilityAction={sizeChangeHandler}
            />
          </div>
        ))}
      </Panel>
    </Modal>
  );
};

VacationsModal.propTypes = {
  onClose: func.isRequired,
  name: string.isRequired,
  employeeNumber: oneOfType([string, number]),
  employeeId: oneOfType([string, number]).isRequired,
  vacations: arrayOf(node).isRequired,
  getVacationsAction: func.isRequired,
  addVacationsAction: func.isRequired,
  updateVacationsAction: func.isRequired,
  deleteVacationsAction: func.isRequired,
  timezone: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  vacationError: object,
};

VacationsModal.defaultProps = {
  employeeNumber: null,
  vacationError: null,
};

export default VacationsModal;
