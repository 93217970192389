import React from "react";
import { bool, func, objectOf } from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

import { resetTimelineFilters, setTimelineFilter } from "store/actions/bookings-actions";

import styles from "./styles.module.scss";

const filtersConfig = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Tomorrow",
    value: "tomorrow",
  },
  {
    label: "This week",
    value: "week",
  },
  {
    label: "All",
    value: "all",
  },
];

const TimelineFilters = ({
  timelineFilters,
  resetTimelineFiltersAction,
  setTimelineFilterAction,
}) => {
  const { today, tomorrow, week } = timelineFilters;
  const isItemActive = ({ label, value }) => (
    label === "All"
      ? !today && !week && !tomorrow
      : Boolean(timelineFilters[value])
  );

  return (
    <ul className={styles.timeline}>
      {
        filtersConfig.map((filter) => (
          <li
            key={filter.value}
            className={cx(
              styles.timelineItem,
              {
                [styles.timelineItemActive]: isItemActive(filter),
              },
            )}
            onClick={() => {
              resetTimelineFiltersAction();
              if (filter.value) {
                setTimelineFilterAction(filter.value);
              }
            }}
          >
            {filter.label}
          </li>
        ))
      }
    </ul>
  );
};

TimelineFilters.propTypes = {
  timelineFilters: objectOf(bool).isRequired,
  setTimelineFilterAction: func.isRequired,
  resetTimelineFiltersAction: func.isRequired,
};

const actions = {
  setTimelineFilterAction: setTimelineFilter,
  resetTimelineFiltersAction: resetTimelineFilters,
};

export default connect(null, actions)(TimelineFilters);
