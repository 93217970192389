import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import {
  bool, func, node, string,
} from "prop-types";

import { ReactComponent as IconArrowLeft } from "assets/icons/arrow/left.svg";
import {
  appDealershipLogoSelector,
  appDealershipNameSelector,
} from "store/selectors/checkin-app-selectors";
import closeIcon from "assets/images/cross.svg";
import styles from "./styles.module.sass";

const Header = (props) => {
  const {
    arrowBack,
    title,
    dealershipLogo,
    dealershipName,
    isFixed,
    onClose,
  } = props;

  const icon = () => {
    if (arrowBack) {
      return (
        <div
          className={styles.goBack}
          onClick={onClose}
        >
          <IconArrowLeft />
          <span>Back</span>
        </div>
      );
    } if (dealershipLogo) {
      return (
        <img
          src={dealershipLogo}
          alt="dealer logo"
          className={styles.dealerLogo}
          style={{ maxHeight: "20px" }}
        />
      );
    }

    return <span className={styles.dealerName}>{dealershipName}</span>;
  };

  return (
    <div className={cx(styles.header, { [styles.fixed]: isFixed })}>
      <div className={styles.headerWrap}>
        {icon()}
        {title ? (
          <>
            <p className={styles.title}>{title}</p>
            <span className={styles.hidden} />
          </>
        ) : null}
        <button
          type="button"
          className={styles.button}
          onClick={onClose}
        >
          <img
            src={closeIcon}
            alt="close"
            className={styles.icon}
          />
        </button>
      </div>
    </div>
  );
};

Header.propTypes = {
  title: string,
  dealershipLogo: node.isRequired,
  arrowBack: node.isRequired,
  dealershipName: string,
  isFixed: bool,
  onClose: func,
};

Header.defaultProps = {
  dealershipName: "",
  title: "",
  isFixed: false,
  onClose: () => {},
};

const mapStateToProps = (state) => {
  return {
    dealershipName: appDealershipNameSelector(state),
    dealershipLogo: appDealershipLogoSelector(state),
  };
};
export default connect(mapStateToProps)(Header);
