import React from "react";

import { serviceAdvisorPropType } from "shared/prop-types";
import { imageUrl } from "shared/utils/common";

import styles from "../styles.module.scss";

const AdvisorInformation = ({ advisor }) => (
  <div className={styles.advisorSection}>
    {advisor.photo?.url && (
      <img
        className={styles.advisorPhoto}
        src={imageUrl(advisor.photo)}
        alt=""
      />
    )}
    <div className={styles.advisorInfo}>
      <span className={styles.advisorText}>
        Selected advisor:
      </span>
      <span className={styles.advisorName}>
        {advisor.name}
      </span>
    </div>
  </div>
);

AdvisorInformation.propTypes = {
  advisor: serviceAdvisorPropType,
};

AdvisorInformation.defaultProps = {
  advisor: null,
};

export default AdvisorInformation;
