import React from "react";

import "react-notifications/lib/notifications.css";

import EmployeesPanelAdvisors from "./EmployeesPanel/EmployeesPanelAdvisors";
import EmployeesPanelDriver from "./EmployeesPanel/EmployeesPanelDriver";
import EmployeesPanelMobileTechnicians from "./EmployeesPanel/EmployeesPanelMobileTechnicians";

const CapacityTab = () => (
  <>
    <EmployeesPanelAdvisors type="advisor" />
    <EmployeesPanelDriver type="driver" />
    <EmployeesPanelMobileTechnicians type="mobile" />
  </>
);

export default CapacityTab;
