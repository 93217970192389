import React, { useState } from "react";
import cx from "classnames";
import { bool, number } from "prop-types";

import { workingHoursPropType } from "shared/prop-types";
import { timeInUSFormat } from "shared/utils/datetime";

import "./styles.scss";

const WEEKDAYS = [
  {
    label: "M",
    value: "mon",
  },
  {
    label: "T",
    value: "tue",
  },
  {
    label: "W",
    value: "wed",
  },
  {
    label: "T",
    value: "thu",
  },
  {
    label: "F",
    value: "fri",
  },
  {
    label: "S",
    value: "sat",
  },
  {
    label: "S",
    value: "sun",
  },
];

const WorkingHoursCell = ({
  value: workingHours,
  isActive,
  employeeId,
}) => {
  const [workingHoursText, setWorkingHoursText] = useState(null);

  const updateWorkingHoursText = (selectedDay) => {
    const matchingDay = workingHours[selectedDay];

    if (Array.isArray(matchingDay) && matchingDay.length > 0) {
      let result = "";
      Object.values(matchingDay).forEach((item) => {
        result += ` ${timeInUSFormat(item.from)}-${timeInUSFormat(item.to)},`;
      });

      setWorkingHoursText(result.slice(0, -1));
    } else if (
      !Array.isArray(matchingDay)
      && matchingDay !== undefined
      && matchingDay.from !== undefined
    ) {
      setWorkingHoursText(`${timeInUSFormat(matchingDay.from)} - ${timeInUSFormat(matchingDay.to)}`);
    } else {
      setWorkingHoursText("No working hours");
    }
  };

  const clearWorkingHoursText = () => setWorkingHoursText(null);

  return (
    <div className="conciergeTableWorkingHoursCell">
      {!isActive && (
        <p className="conciergeTableWorkingHours__inactiveAdvisorInfo">
          Not reservable by customers
        </p>
      )}
      {isActive && (
        <>
          <div className="conciergeTableWorkingHours__daysContainer">
            {WEEKDAYS.map((weekday) => (
              <span
                key={`${employeeId}-${weekday.value}`}
                className={cx(
                  "conciergeTableWorkingHours__dayValue",
                  {
                    "conciergeTableWorkingHours__dayValue--active":
                    workingHours[weekday.value]
                    && workingHours[weekday.value][0]
                    && workingHours[weekday.value][0].from
                    && workingHours[weekday.value][0].to,
                  },
                )}
                onMouseEnter={() => updateWorkingHoursText(weekday.value)}
                onMouseLeave={() => clearWorkingHoursText()}
              >
                {weekday.label}
              </span>
            ))}
          </div>
          <div>
            <div className="conciergeTableWorkingHours__hoursLabel">
              Hours:
            </div>
            <div className="conciergeTableWorkingHours__hoursValue">
              {workingHoursText}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

WorkingHoursCell.propTypes = {
  value: workingHoursPropType,
  isActive: bool.isRequired,
  employeeId: number.isRequired,
};

WorkingHoursCell.defaultProps = {
  value: {},
};

export default WorkingHoursCell;
