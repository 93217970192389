export const totalRecordsSelector = (state) => (
  // eslint-disable-next-line no-restricted-globals
  isNaN(state.tableOptions.total)
    ? 0
    : Number(state.tableOptions.total)
);
export const perPageSelector = (state, defaultValue) => (
  // eslint-disable-next-line no-restricted-globals
  isNaN(state.tableOptions.perPage) || state.tableOptions.perPage === null
    ? defaultValue
    : Number(state.tableOptions.perPage)
);
export const currentPageSelector = (state) => (
  // eslint-disable-next-line no-restricted-globals
  isNaN(state.tableOptions.currentPage)
    ? 1
    : Number(state.tableOptions.currentPage)
);
export const sortSelector = (state) => state.tableOptions.sort;

export const bookingsTotalRecordsSelector = (state) => (
  // eslint-disable-next-line no-restricted-globals
  isNaN(state.tableOptions.bookingsTotal)
    ? 0
    : Number(state.tableOptions.bookingsTotal)
);
export const bookingsPerPageSelector = (state) => (
  // eslint-disable-next-line no-restricted-globals
  isNaN(state.tableOptions.bookingsPerPage) || state.tableOptions.bookingsPerPage === null
    ? 100
    : Number(state.tableOptions.bookingsPerPage)
);
export const bookingsCurrentPageSelector = (state) => (
  // eslint-disable-next-line no-restricted-globals
  isNaN(state.tableOptions.bookingsCurrentPage)
    ? 1
    : state.tableOptions.bookingsCurrentPage
);
export const bookingsSortSelector = (state) => state.tableOptions.bookingsSort;
