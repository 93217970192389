import React from "react";
import { arrayOf, func } from "prop-types";
import { dealershipInfoPropType, textMessagePropType } from "shared/prop-types";
import Accordion from "components/common/Accordion";

import GeneralPanel from "./GeneralPanel";
import ArriveUsersPanel from "./ArriveUsersPanel";
import DealershipSettingsPanel from "./DealershipSettingsPanel";
import RemoteUsersPanel from "./RemoteUsersPanel";
import IntegrationsPanel from "./IntegrationsPanel";
import TextMessagesPanel from "./TextMessagesPanel";
import HolidaysPanel from "./HolidaysPanel";

const GeneralTab = ({
  textMessages,
  dealershipInfo,
  dealershipInfo: {
    id,
    arrive_users,
    remote_users,
    integrations,
  },
  updateDmsIntegration,
}) => {
  const renderWidgetCodeLines = () => (
    [`<div id="concierge-dealership-id" dealership-id="${id}" make="${dealershipInfo?.additional_settings?.default_make}" style="display: none;"></div>`,
      "<div id=\"concierge-root\" style=\"position: fixed; bottom: 24px; right: 24px; z-index: 999999;\"></div>",
      "<script async src=\"https://reserve.getcarmen.com/widget/embed.js\" type=\"text/javascript\"></script>",
      "<link href=\"https://reserve.getcarmen.com/widget/stylesheet.css\" rel=\"stylesheet\" type=\"text/css\" />"]
  );

  const renderInlineWidgetCodeLines = (noBg) => (
    [`<div id="concierge-dealership-id" dealership-id="${id}" make="${dealershipInfo?.additional_settings?.default_make}" flat-mode ${noBg ? "no-bg-welcome-screen" : ""}></div>`,
      "<div id=\"concierge-root\" style=\"width: 100%; height: 100%;\"></div>",
      "<script async src=\"https://reserve.getcarmen.com/widget/embed.js\" type=\"text/javascript\"></script>",
      "<link href=\"https://reserve.getcarmen.com/widget/stylesheet.css\" rel=\"stylesheet\" type=\"text/css\" />"]
  );

  const renderMobileSchedulerURL = () => (
    [`https://reserve.getcarmen.com/?dealershipId=${id}`]
  );

  const renderDealershipServiceAppURL = () => (
    ["https://reserve.getcarmen.com/carmenservice"]
  );

  const header = (<p className="conciergeSettingsPagePanelHeader">Info</p>);

  return (
    <>
      <GeneralPanel dealershipInfo={dealershipInfo} />
      <DealershipSettingsPanel dealershipInfo={dealershipInfo} />
      <HolidaysPanel />
      <ArriveUsersPanel users={arrive_users} />
      <RemoteUsersPanel users={remote_users} />
      <TextMessagesPanel dealershipInfo={dealershipInfo} textMessages={textMessages} />
      <IntegrationsPanel integrations={integrations} updateDmsIntegration={updateDmsIntegration} />
      <Accordion
        title={header}
        customClass="settingsAccordion"
      >
        <div className="conciergeSettingsPageInfo">
          In order to embed the Widget on a third party website the following
          snippet needs to be inserted in the website&apos;s body.
          <h3>ChatBot Widget:</h3>
          <div className="conciergeSettingsPageInfoCodeContainer">
            {renderWidgetCodeLines().map((line) => <code>{line}</code>)}
          </div>
          <h3>Inline Widget:</h3>
          <div className="conciergeSettingsPageInfoCodeContainer">
            {renderInlineWidgetCodeLines().map((line) => <code>{line}</code>)}
          </div>

          <h3>Inline Widget without background on welcome screen:</h3>
          <div className="conciergeSettingsPageInfoCodeContainer">
            {renderInlineWidgetCodeLines(true).map((line) => <code>{line}</code>)}
          </div>
          <h3>Mobile scheduler URL:</h3>
          <div className="conciergeSettingsPageInfoCodeContainer">
            {renderMobileSchedulerURL(true).map((line) => <code>{line}</code>)}
          </div>
          <h3>Dealership service app URL:</h3>
          <div className="conciergeSettingsPageInfoCodeContainer">
            {renderDealershipServiceAppURL(true).map((line) => <code>{line}</code>)}
          </div>

        </div>
      </Accordion>
    </>
  );
};

GeneralTab.propTypes = {
  dealershipInfo: dealershipInfoPropType,
  textMessages: arrayOf(textMessagePropType).isRequired,
  updateDmsIntegration: func.isRequired,
};

GeneralTab.defaultProps = {
  dealershipInfo: null,
};

export default GeneralTab;
