import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  bool, func, oneOfType, shape, string,
} from "prop-types";

import Block from "components/common/Block";
import Panel from "components/common/Panel";
import { customerPropType } from "shared/prop-types";
import { dmsTypeSelector, settingsDefaultMakeSelector } from "store/selectors/settings-selectors";
import {
  customerFieldsCDK,
  customerFieldsDT,
  vehicleFields,
} from "shared/fields";
import {
  resetDataEditionState,
  sendVehicleData,
  updateCustomerData,
} from "store/actions/scheduling-actions";
import {
  schedulingCustomerSavingErrorSelector,
  schedulingCustomerSavingSelector,
} from "store/selectors/scheduling-selectors";

import EditVehicleModal from "components/common/DataEditionModal/EditVehicleModal";
import DataEditionModal from "components/common/DataEditionModal";
import Button from "components/common/Button";
import { dealershipMakeModelYearMapSelector } from "store/selectors/app-selectors";
import { prepareCustomerPickupAddress } from "shared/utils/common";
import VehicleDetails from "../VehicleDetails";

import styles from "../styles.module.scss";

const CustomerDetails = ({
  customer,
  resetDataEditionModalState,
  dataEditionModalSaving,
  dataEditionModalSavingError,
  saveEditedCustomerData,
  saveVehicleData,
  dmsType,
  makeModelYearMap,
  defaultMake,
  focusOnInputField,
}) => {
  const [customerEditorVisible, setCustomerEditorVisible] = useState(false);
  const [vehicleEditorVisible, setVehicleEditorVisible] = useState(false);
  const [dataPassedToCustomerEditor, setDataPassedToCustomerEditor] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address_line1: "",
    address_zipcode: "",
    address_city: "",
    address_state: "",
    customer_type: "",
    company_name: "",
    internal_note: "",
  });

  const vehiclesList = () => customer.vehicles.map((v) => (
    <VehicleDetails vehicle={v} customer={customer} key={v.id} />
  ));

  const vehiclesHeader = () => customer.vehicles.length > 0 && (
    <p className={styles.header}>Vehicles</p>
  );

  useEffect(() => {
    const {
      first_name,
      last_name,
      phone_number,
      email,
      address_line1,
      address_zipcode,
      address_city,
      address_state,
      customer_type,
      company_name,
      internal_note,
    } = customer;
    setDataPassedToCustomerEditor({
      first_name,
      last_name,
      phone_number,
      email,
      address_line1,
      address_zipcode,
      address_city,
      address_state,
      customer_type,
      company_name,
      internal_note,
    });
  }, [customer]);

  const hideDataEditionModal = (modalType) => {
    resetDataEditionModalState();

    switch (modalType) {
      case "addVehicle":
        setVehicleEditorVisible(false);
        break;
      case "editCustomer":
        setCustomerEditorVisible(false);
        break;
      default:
        break;
    }
  };

  const {
    address,
    address_line1,
    email,
    id,
    first_name,
    last_address,
    last_name,
    phone_number,
    internal_note,
    customer_number,
  } = customer;

  return (
    <Panel
      key={id}
      className={styles.panel}
      header="Customer Details"
    >
      <p className={styles.header}>Personal Data</p>
      <Block className={styles.block}>
        <div className={styles.personal}>
          <div className={styles.personalContainer}>
            <section className={styles.personalSection}>
              <p className={styles.label}>
                Full name
              </p>
              <p>
                {first_name || last_name ? `${first_name || ""} ${last_name || ""}` : "—"}
              </p>
            </section>
            <section className={styles.personalSection}>
              <p className={styles.label}>
                Email
              </p>
              <p>{email}</p>
            </section>
          </div>
          <div className={styles.personalContainer}>
            <section className={styles.personalSection}>
              <p className={styles.label}>Phone number</p>
              <p>{phone_number}</p>
            </section>
            <section className={styles.personalSection}>
              <p className={styles.label}>DMS Customer nr</p>
              <p className={styles.uneditable}>{customer_number}</p>
            </section>
            <Button
              withoutBorder
              variant="base-grey"
              leftIcon="editGrey"
              onClick={() => setCustomerEditorVisible(true)}
            >
              Edit
            </Button>
          </div>
        </div>
        <div className={styles.personal}>
          <div className={styles.personalContainer}>
            <section className={styles.personalSection}>
              <p className={styles.label}>
                Address
              </p>
              <p>{address}</p>
            </section>
          </div>
        </div>
        {!!last_address && !!address_line1 && (
          <div className={styles.personal}>
            <div className={styles.personalContainer}>
              <section className={styles.personalSection}>
                <p className={styles.label}>
                  Pick-up Address
                </p>
                <p>{prepareCustomerPickupAddress(customer)}</p>
              </section>
            </div>
          </div>
        )}
        {internal_note && (
          <div className={styles.personal}>
            <div className={styles.personalContainer}>
              <section className={styles.personalSection}>
                <p className={styles.label}>
                  Customer Internal Note
                </p>
                <p>{internal_note}</p>
              </section>
            </div>
          </div>
        )}
      </Block>
      {customer.vehicles && vehiclesHeader()}
      {customer.vehicles && vehiclesList()}
      <section className={styles.confirmVehicle}>
        <p>
          {
            `Vehicle not on the list? Please confirm with the customer if the
            vehicle could be linked to a different phone number.`
          }
        </p>
        <div className={styles.buttonGroup}>
          <Button
            variant="brand"
            onClick={() => focusOnInputField()}
          >
            <p>Yes</p>
          </Button>
          <Button
            variant="dark-outline"
            onClick={() => setVehicleEditorVisible(true)}
          >
            <p>No</p>
          </Button>
        </div>
      </section>
      {customerEditorVisible && (
        <DataEditionModal
          onClose={() => hideDataEditionModal("editCustomer")}
          title="Edit customer"
          fields={
            dmsType === "dealer_track" ? customerFieldsDT : customerFieldsCDK
          }
          onSubmit={(data) => saveEditedCustomerData(data, customer)}
          initialData={dataPassedToCustomerEditor}
          submitButtonText="Save"
          loading={dataEditionModalSaving}
          error={dataEditionModalSavingError}
        />
      )}
      {vehicleEditorVisible && (
        <EditVehicleModal
          onClose={() => hideDataEditionModal("addVehicle")}
          title="Add new vehicle"
          fields={vehicleFields}
          onSubmit={(data) => saveVehicleData(data, customer)}
          submitButtonText="Create"
          loading={dataEditionModalSaving}
          error={dataEditionModalSavingError}
          makeModelYearMap={makeModelYearMap}
          defaultMake={defaultMake}
        />
      )}
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    dataEditionModalSaving: schedulingCustomerSavingSelector(state),
    dataEditionModalSavingError: schedulingCustomerSavingErrorSelector(state),
    dmsType: dmsTypeSelector(state),
    makeModelYearMap: dealershipMakeModelYearMapSelector(state),
    defaultMake: settingsDefaultMakeSelector(state),
  };
};

const actions = {
  resetDataEditionModalState: resetDataEditionState,
  saveEditedCustomerData: updateCustomerData,
  saveVehicleData: sendVehicleData,
};

CustomerDetails.propTypes = {
  customer: customerPropType,
  resetDataEditionModalState: func.isRequired,
  dataEditionModalSaving: bool.isRequired,
  dataEditionModalSavingError: string.isRequired,
  saveEditedCustomerData: func.isRequired,
  saveVehicleData: func.isRequired,
  dmsType: string.isRequired,
  makeModelYearMap: oneOfType(shape({ make: func }), func).isRequired,
  defaultMake: func,
  focusOnInputField: func,
};

CustomerDetails.defaultProps = {
  customer: null,
  defaultMake: () => {},
  focusOnInputField: () => {},
};

export default connect(mapStateToProps, actions)(CustomerDetails);
