import React, { useEffect, useState } from "react";
import {
  bool,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types";
import { DirectionsRenderer } from "@react-google-maps/api";

const Directions = ({
  selectedJob,
  dealershipPosition,
}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const service = new window.google.maps.DirectionsService();

    service.route({
      destination: selectedJob.aasm_state !== "en_route_to_dealership"
        ? selectedJob.target_geolocation
        : dealershipPosition,
      origin: selectedJob.current_geolocation,
      travelMode: "DRIVING",
    }, (response, status) => {
      if (status === "OK") {
        setData(response);
      }
    });
  }, [selectedJob]);

  const directionsPathColor = () => {
    if (selectedJob.aasm_state === "assigned") {
      return "#36AF5E";
    } if (selectedJob.aasm_state === "en_route_to_customer") {
      return "#0BCAF9";
    } if (selectedJob.aasm_state === "arrived_to_customer") {
      return "#FE3E7F";
    } if (selectedJob.aasm_state === "en_route_to_dealership") {
      return "#6B46FF";
    }
  };

  const direction = () => {
    if (data) {
      return (
        <DirectionsRenderer
          options={{
            directions: data,
            suppressMarkers: true,
            polylineOptions: new window.google.maps.Polyline({
              strokeColor: directionsPathColor(),
              strokeOpacity: 1,
              strokeWeight: 4,
            }),
          }}
        />
      );
    }

    return null;
  };

  return (
    direction()
  );
};

Directions.propTypes = {
  selectedJob: objectOf(oneOfType([number, bool, string])).isRequired,
  dealershipPosition: {
    lat: number,
    lng: number,
  }.isRequired,
};

export default Directions;
