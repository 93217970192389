import { connect } from "react-redux";

import {
  initChecksJobDistance,
  resetJobReachable,
  setCurrentStep,
  setIsDistanceLimitIgnored,
  setIsDropOff,
  setIsMobileTechnician,
  setIsPickUp,
  setRemoteServiceLocation,
  setServiceAdvisor,
} from "store/actions/scheduling-process-actions";

import {
  settingsDealershipLatitudeLongitudeSelector,
  settingsMobileTechnicianIntegrationSegmentsNumberSelector,
  settingsPickupIntegrationSegmentsNumberSelector,
  settingsTeamServiceAdvisorsSelector,
} from "store/selectors/settings-selectors";

import {
  schedulingProcessIsJobReachableLoading,
  schedulingProcessJobDropOffReachable,
  schedulingProcessJobReachable,
} from "store/selectors/scheduling-process-selectors";

import ChooseRemoteService from "./index";

const mapStateToProps = (state) => {
  return {
    isJobReachableLoading: schedulingProcessIsJobReachableLoading(state),
    jobReachable: schedulingProcessJobReachable(state),
    jobDropOffReachable: schedulingProcessJobDropOffReachable(state),
    teamServiceAdvisors: settingsTeamServiceAdvisorsSelector(state),
    dealershipPosition: settingsDealershipLatitudeLongitudeSelector(state),
    segmentsMobileTechnicianNumber:
    settingsMobileTechnicianIntegrationSegmentsNumberSelector(state),
    segmentsNumber: settingsPickupIntegrationSegmentsNumberSelector(state),
  };
};

const actions = {
  setSchedulingStep: setCurrentStep,
  checksJobDistance: initChecksJobDistance,
  resetJobReachableData: resetJobReachable,
  schedulingPickUp: setIsPickUp,
  schedulingDropOff: setIsDropOff,
  schedulingMobileTechnician: setIsMobileTechnician,
  schedulingDistanceLimitIgnored: setIsDistanceLimitIgnored,
  setServiceLocation: setRemoteServiceLocation,
  makeAdvisorSelection: setServiceAdvisor,
};

export default connect(mapStateToProps, actions)(ChooseRemoteService);
