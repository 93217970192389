import React from "react";
import { shape, string } from "prop-types";

import VideoPlayer from "components/common/VideoPlayer";

import { getAppointmentFormattedDate } from "shared/utils/datetime";
import Alert from "components/common/Alert";

const RemotePanelVideosItem = ({ item }) => (
  <div className="RemotePanelVideosItem">
    <div className="RemotePanelVideosItemPlayer">
      {(!item.aasm_state || item.aasm_state === "uploaded") && (
        <VideoPlayer
          autoplay={false}
          controls
          sources={[
            {
              src: item?.videoURL || item.url,
              type: "video/mp4",
            },
          ]}
        />
      )}
      {item?.aasm_state && item.aasm_state !== "uploaded" && (
        <Alert
          variant="warning"
          text="The video was not fully uploaded. Please open Carmen Remote App to finish uploading."
        />
      )}
    </div>
    <div
      className="RemotePanelVideosItemDescription"
    >
      <p className="videosDate">
        {getAppointmentFormattedDate(item?.date || item.created_at)}
      </p>
    </div>
  </div>
);

RemotePanelVideosItem.propTypes = {
  item: shape({
    date: string,
    videoURL: string,
  }).isRequired,
};

export default RemotePanelVideosItem;
