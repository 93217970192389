import {
  fetchAuthToken, fetchUpdateUserPassword, fetchUserByResetToken, sendResetLink,
} from "shared/api";

export const AUTH_SET_LOADING_STATE = "AUTH_SET_LOADING_STATE";
export const AUTH_AUTHENTICATION_SUCCESS = "AUTH_AUTHENTICATION_SUCCESS";
export const AUTH_AUTHENTICATION_FAILURE = "AUTH_AUTHENTICATION_FAILURE";
export const AUTH_AUTHENTICATION_BY_RESET_TOKEN_SUCCESS = "AUTH_AUTHENTICATION_BY_RESET_TOKEN_SUCCESS";
export const AUTH_AUTHENTICATION_BY_RESET_TOKEN_FAILURE = "AUTH_AUTHENTICATION_BY_RESET_TOKEN_FAILURE";
export const AUTH_SEND_RESET_LINK_SUCCESS = "AUTH_SEND_RESET_LINK_SUCCESS";
export const AUTH_SEND_RESET_LINK_FAILURE = "AUTH_SEND_RESET_LINK_FAILURE";
export const AUTH_RESET_USER_PASSWORD_SUCCESS = "AUTH_RESET_USER_PASSWORD_SUCCESS";
export const AUTH_RESET_USER_PASSWORD_FAILURE = "AUTH_RESET_USER_PASSWORD_FAILURE";
export const AUTH_RESET_LOGIN_STATE = "AUTH_RESET_LOGIN_STATE";
export const AUTH_LOG_OUT = "AUTH_LOG_OUT";

const setLoadingState = () => {
  return {
    type: AUTH_SET_LOADING_STATE,
    payload: {},
  };
};

export const authenticateUser = (email, password) => (dispatch) => {
  dispatch(setLoadingState());

  return fetchAuthToken(email, password)
    .then((response) => (
      dispatch({
        type: AUTH_AUTHENTICATION_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: AUTH_AUTHENTICATION_FAILURE,
      payload: { error },
    }));
};

export const authenticateUserByResetToken = (resetToken) => (dispatch) => {
  dispatch(setLoadingState());

  return fetchUserByResetToken(resetToken)
    .then((response) => (
      dispatch({
        type: AUTH_AUTHENTICATION_BY_RESET_TOKEN_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: AUTH_AUTHENTICATION_BY_RESET_TOKEN_FAILURE,
      payload: { error },
    }));
};

export const sendResetLinkToEmail = (email) => (dispatch) => {
  dispatch(setLoadingState());

  return sendResetLink({ user: { email } })
    .then((response) => (
      dispatch({
        type: AUTH_SEND_RESET_LINK_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: AUTH_SEND_RESET_LINK_FAILURE,
      payload: { error },
    }));
};

export const resetLoginData = () => (dispatch) => dispatch({
  type: AUTH_RESET_LOGIN_STATE,
});

export const updateUserPassword = (userData) => (dispatch) => {
  dispatch(setLoadingState());

  return fetchUpdateUserPassword({ user: { ...userData } })
    .then((response) => (
      dispatch({
        type: AUTH_RESET_USER_PASSWORD_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: AUTH_RESET_USER_PASSWORD_FAILURE,
      payload: { error },
    }));
};

export const logOut = () => (dispatch) => {
  dispatch({
    type: AUTH_LOG_OUT,
    payload: {},
  });
};
