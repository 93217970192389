import {
  useEffect, useMemo, useRef, useState,
} from "react";
import { useLocation } from "react-router-dom";
import { equals } from "ramda";

export function useDeepCompareEffect(effectFunction, dependencies) {
  const firstRun = useRef(true);
  const previousDependencies = useRef(dependencies);

  useEffect(() => {
    const isEqual = previousDependencies.current.every((object, index) => (
      equals(object, dependencies[index])
    ));

    if (firstRun.current || !isEqual) {
      effectFunction();
    }

    firstRun.current = false;
    previousDependencies.current = dependencies;
  }, [dependencies]);
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const delayedUpdate = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(delayedUpdate);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useOutsideClick(ref, onClick) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickingOutside = ref.current && !ref.current.contains(event.target);

      if (isClickingOutside) {
        onClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
  }, inputs);
}
