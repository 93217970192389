export const getServerErrorsFromFullErrors = (full_errors) => full_errors.filter((error) => error.code.slice(0, 3) === "500");

export const getIntegrationErrorsFromFullErrors = (full_errors) => full_errors.filter((error) => error.code.slice(0, 3) === "600");

export const mapErrorToAction = () => {};

export const extractErrorMessage = (error) => {
  if (Array.isArray(error)) {
    return error.map((err) => err.message).join(" ");
  }
  if (typeof error === "object") {
    return error.message
            || error.error
            || error.errors
            || (error.data && (error.data.errors || error.data.error || error.data))
            || ((error.error && error.error?.error && error.error?.error?.message) || "");
  }
  if (typeof error === "string") {
    return error;
  }
  return "";
};
