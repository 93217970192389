import React, { useState } from "react";
import {
  arrayOf, bool, node, string,
} from "prop-types";
import ReactTable from "react-table-6";

import { settingsUserProptype } from "shared/prop-types";
import { formatName } from "shared/utils/common";
import Accordion from "components/common/Accordion";
import Button from "components/common/Button";

import styles from "./styles.module.scss";

const UsersPanel = ({
  title,
  users,
  addUserModal: AddUserModal,
  userSettingsActionCell: UserSettingsActionCell,
  showUserName,
}) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [openedPopupId, setOpenedPopupId] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);

  const userSettingsCell = (props) => {
    const updatedProps = {
      ...props,
      openPopup: (popupId) => setOpenedPopupId(openedPopupId === popupId ? null : popupId),
      closePopup: () => setOpenedPopupId(null),
      openedPopupId,
    };
    return <UserSettingsActionCell {...updatedProps} />;
  };

  const columns = [
    {
      Header: showUserName ? "USERNAME" : "EMAIL",
      accessor: "username",
      width: 300,
    },
    {
      Header: "PASSWORD",
      accessor: "password",
      width: 300,
    },
    {
      Header: title === "Remote" ? "Type" : "",
      accessor: "type",
      width: 200,
    },
    {
      Header: title === "Remote" ? "Name" : "",
      accessor: "name",
      width: 200,
    },
    {
      Header: "",
      accessor: "id",
      Cell: userSettingsCell,
      width: 160,
    },
  ];

  const preparedData = (data) => data.map(
    (info) => {
      const {
        username,
        email,
        decrypted_password,
        encrypted_password,
        role,
        name,
      } = info;

      const formatedData = info;
      formatedData.username = showUserName ? username : email;
      formatedData.password = passwordShown ? decrypted_password : encrypted_password;
      formatedData.type = title === "Remote" ? formatName(role) : null;
      formatedData.name = title === "Remote" ? name : null;

      return formatedData;
    },
  );

  return (
    <Accordion
      title={`${title} Users`}
      customClass="settingsAccordion"
    >
      <div className={styles.usersPanel}>
        <ReactTable
          columns={columns}
          getTdProps={(state, rowInfo, { Header }) => {
            return {
              onClick: () => {
                if (Header === "PASSWORD") {
                  setPasswordShown(!passwordShown);
                }
              },
              style: {
                cursor: Header === "PASSWORD" ? "pointer" : "inherit",
              },
            };
          }}
          data={users}
          resolveData={preparedData}
          showPagination={false}
          minRows={1}
        />
      </div>
      <div className={styles.button}>
        <Button
          variant="dark"
          onClick={() => setCreateModalOpen(true)}
        >
          {`+ Add new ${title.toLowerCase()} user`}
        </Button>
      </div>
      {createModalOpen && (
        <AddUserModal onClose={() => setCreateModalOpen(false)} />
      )}
    </Accordion>
  );
};

UsersPanel.propTypes = {
  title: string,
  users: arrayOf(settingsUserProptype),
  addUserModal: node.isRequired,
  userSettingsActionCell: node.isRequired,
  showUserName: bool,
};

UsersPanel.defaultProps = {
  title: "",
  users: [],
  showUserName: false,
};

export default UsersPanel;
