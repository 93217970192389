import {
  instanceOf, object, oneOfType, shape, string,
} from "prop-types";

export const remoteAdditionalInfoJobPropType = shape({
  coDriver: oneOfType([object, string]),
  date: oneOfType([string, instanceOf(Date)]),
  driver: oneOfType([object, string]),
  location: string,
  notes: string,
  time: string,
  collectionTime: oneOfType([string, instanceOf(Date)]),
});
