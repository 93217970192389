import React from "react";
import { arrayOf, number, shape } from "prop-types";
import { totalCostForBooking } from "shared/utils/common";
import ReactTooltip from "react-tooltip";
import serviceIconsMap from "shared/serviceIconsMap";
import "./styles.scss";

const SelectedServicesTooltip = ({ menuItems, bookingId }) => {
  const getSelectedServices = () => (
    <>
      {menuItems.map((item) => {
        let { kind } = item;
        const { vin_specific, name, price } = item;

        if (kind === "recall" && vin_specific) {
          kind = "vinSpecificRecall";
        }

        return (
          <section>
            <div className="bookingsDetails">
              <img
                src={serviceIconsMap[kind]}
                className="bookingsDetailsServiceIcon"
                alt=""
              />
              <div className="bookingsDetailsService">
                <span className="bookingsDetailsServiceName">{name}</span>
                <div className="bookingsDetailsServicePrice">
                  <span className="bookingsDetailsServicePriceLabel">Price:</span>
                  <span className="bookingsDetailsServicePriceValue">{`$${Number(price)}`}</span>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </>
  );

  return (
    <ReactTooltip
      id={`booking-details-${bookingId}`}
      place="bottom"
      className="tooltipContainer"
      effect="float"
      delayShow={200}
      type="light"
      border
      borderColor="#dedee0"
    >
      {menuItems.length
        ? (
          <div>
            {getSelectedServices()}
            <div className="tooltipContent">
              Total: $
              {totalCostForBooking(menuItems)}
            </div>
          </div>
        )
        : <span>No services specified</span>}
    </ReactTooltip>
  );
};

SelectedServicesTooltip.propTypes = {
  menuItems: arrayOf(shape).isRequired,
  bookingId: number.isRequired,
};

export default SelectedServicesTooltip;
