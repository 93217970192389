import React from "react";
import {
  arrayOf, bool, func, string,
} from "prop-types";
import cx from "classnames";

import "./styles.scss";

const Tabs = ({
  config, selectedItem, selectTabAction, isGeneral,
}) => (
  <ul className={cx("tabsContainer", {
    tabsGeneral: isGeneral,
  })}
  >
    {
      config.map((item) => (
        <li
          key={item.value}
          className={
            cx(
              "tabsItem",
              {
                tabsItemActive: item.value === selectedItem,
              },
            )
          }
          onClick={() => selectTabAction(item.value)}
        >
          {item.label}
          {(item.sup || item.sup === 0) && <sup>{`${item.sup}`}</sup>}
        </li>
      ))
    }
  </ul>
);

Tabs.propTypes = {
  config: arrayOf({
    label: string,
    value: string,
  }),
  selectedItem: string,
  selectTabAction: func.isRequired,
  isGeneral: bool,
};

Tabs.defaultProps = {
  config: {},
  selectedItem: null,
  isGeneral: false,
};

export default Tabs;
