import React, { useState } from "react";
import { connect } from "react-redux";
import {
  bool, func, number, shape, string,
} from "prop-types";

import editIcon from "assets/images/edit.svg";
import deleteIcon from "assets/images/delete.svg";
import { deleteTemplate } from "store/actions/menu-templates-actions";
import Modal from "components/common/Modal";
import EditMenuTemplateModal from "./EditMenuTemplateModal";
import "./styles.scss";

const ActionsCell = ({
  closePopup,
  value: {
    id, name, isActive, ignoreVehicleMileage,
  },
  onDeleteTemplate,
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onEdit = () => {
    setEditModalOpen(true);
    closePopup();
  };

  const onDelete = () => {
    setDeleteModalOpen(true);
    closePopup();
  };

  const deleteMenuTemplate = () => {
    setDeleteModalOpen(false);
    onDeleteTemplate(id);
  };

  return (
    <div className="conciergeTableActionsCell">
      <button type="button" className="conciergeTableActionsCellAction" onClick={onEdit}>
        <img alt="edit" src={editIcon} />
        <span>Edit</span>
      </button>
      <button
        type="button"
        className="conciergeTableActionsCellAction conciergeTableActionsCellDelete"
        onClick={onDelete}
      >
        <img alt="delete" src={deleteIcon} />
        <span>Delete</span>
      </button>
      {editModalOpen && (
        <EditMenuTemplateModal
          id={id}
          isActive={isActive}
          name={name}
          ignoreVehicleMileage={ignoreVehicleMileage}
          onClose={() => setEditModalOpen(false)}
        />
      )}
      {deleteModalOpen && (
        <Modal
          title="Wait. Are you sure?"
          text={`
                  Deleting template means that is will be no longer used in conversation.
                  Please note that this process cannot be undone.`}
          cancelButtonText="No"
          submitButtonText="Yes"
          submitButtonVariant="destructive"
          cancelButtonVariant="dark"
          size="small"
          onSubmit={() => deleteMenuTemplate()}
          onCancel={() => setDeleteModalOpen(false)}
        />
      )}
    </div>
  );
};

ActionsCell.propTypes = {
  value: shape({
    id: number.isRequired,
    isActive: bool.isRequired,
    name: string.isRequired,
  }).isRequired,
  closePopup: func.isRequired,
  onDeleteTemplate: func.isRequired,
};

const actions = {
  onDeleteTemplate: deleteTemplate,
};

const ActionsCellContainer = connect(null, actions)(ActionsCell);

export default ActionsCellContainer;
