import React, { useRef, useState } from "react";
import {
  arrayOf,
  bool,
  element,
  func,
  number,
  objectOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { isEmpty } from "ramda";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { useOutsideClick } from "shared/hooks";

import selectedIcon from "assets/images/selected.svg";
import uncheckedIcon from "assets/images/unchecked.svg";
import caretMintIcon from "assets/images/bookings/caret-mint.svg";

import AdditionalInfoFilterDropdownItem from "./AdditionalInfoFilterDropdownItem";

import styles from "./styles.module.scss";

const filterLabels = {
  service_advisor: "Advisors",
  jobStatus: "Remote Status",
  bookingStatus: "Checkin",
  transportationStatus: "Transport",
  appointmentStatus: "Appointment Status",
};

const AdditionalInfoFilterDropdown = ({
  dropdownKey,
  filters,
  dropdownConfig,
  handleOnClick,
  toggleAllItems,
  dropdownIcon,
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpenDropdown(false));

  const isAllSelected = () => {
    if (isEmpty(filters)) {
      return false;
    }

    const valuesArr = Object.values(filters);
    return valuesArr.length === valuesArr.filter((value) => value).length;
  };

  const isSomeSelected = () => Object.values(filters).some((value) => value);

  const tooltip = (filterKey) => (<p>{filterLabels[filterKey]}</p>);

  return (
    <>
      <div
        key={dropdownKey}
        className={cx(styles.dropdown, {
          [styles.dropdownActive]: isSomeSelected(),
        })}
        onClick={() => setIsOpenDropdown(!isOpenDropdown)}
        data-for="dropdown-filter-tooltip"
        data-tip={dropdownKey}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <img
          className={styles.circle}
          src={dropdownIcon}
          alt="img"
        />
        <img
          className={cx(styles.caret, {
            [styles.caretOpen]: isOpenDropdown,
          })}
          src={caretMintIcon}
          alt="caret"
        />
      </div>
      {isOpenDropdown && (
        <div
          className={styles.content}
          ref={wrapperRef}
        >
          <div className={styles.contentHead}>
            <div
              className={styles.checkbox}
              onClick={() => toggleAllItems({
                dropdownKey,
                itemsValue: !isAllSelected(),
              })}
            >
              <img
                src={isAllSelected() ? selectedIcon : uncheckedIcon}
                alt="checkbox"
              />
              <span className={styles.label}>
                Select All
              </span>
            </div>
          </div>
          <hr />
          <ul>
            {dropdownConfig.map(({
              key, icon, assetsClassName, caption,
            }) => (
              <AdditionalInfoFilterDropdownItem
                key={key}
                assets={icon?.type || icon}
                assetsClassName={assetsClassName}
                isActive={filters[key]}
                caption={caption}
                handleOnClick={() => handleOnClick({
                  itemKey: key,
                  dropdownKey,
                })}
              />
            ))}
          </ul>
        </div>
      )}
      {showTooltip && (
        <ReactTooltip
          id="dropdown-filter-tooltip"
          className={styles.label}
          place="bottom"
          backgroundColor="#F9FAFC"
          textColor="black"
          border
          borderColor="#dedee0"
          globalEventOff="click"
          getContent={tooltip}
        />
      )}
    </>
  );
};

AdditionalInfoFilterDropdown.propTypes = {
  dropdownKey: string.isRequired,
  filters: objectOf(bool).isRequired,
  dropdownConfig: arrayOf(
    shape({
      key: oneOfType(number, string),
      assets: element,
      assetsClassName: string,
      caption: string,
    }),
  ).isRequired,
  dropdownIcon: string.isRequired,
  handleOnClick: func.isRequired,
  toggleAllItems: func.isRequired,
};

export default AdditionalInfoFilterDropdown;
