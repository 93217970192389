import React, { useEffect, useState } from "react";
import {
  arrayOf, func, number, string,
} from "prop-types";
import {
  chosenTimeSlotPropType,
  mobileTechnicianPropType,
  serviceAdvisorPropType,
  serviceLocationPropType,
} from "shared/prop-types";
import dateFns from "date-fns";
import ClipLoader from "react-spinners/ClipLoader";
import { BOOKING_STEP_INTERNAL_NOTE } from "shared/constants";

import Panel from "components/common/Panel";
import Button from "components/common/Button";
import Alert from "components/common/Alert";
import MobileTechnicianSelection from "./MobileTechnicianSelection";

import styles from "./styles.module.scss";

const ChooseMobileTechnician = ({
  availableJobEmployee,
  chosenTimeSlot,
  initialTime,
  retrieveMobileTechnicians,
  storeRemoteJobData,
  setSchedulingStep,
  selectedAdvisor,
  mobileTechnicians,
  serviceLocation,
  mobileTechnician,
  prevNotes,
  jobId,
}) => {
  const [selectedMobileTechnician, setSelectedMobileTechnician] = useState(mobileTechnician);
  const [notes, setNotes] = useState(prevNotes);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    retrieveMobileTechnicians();
  }, []);

  useEffect(() => {
    if (mobileTechnicians && selectedAdvisor) {
      setIsLoading(false);
    }
  }, [mobileTechnicians, selectedAdvisor]);

  useEffect(() => {
    const initialFullDate = dateFns.format(initialTime, "YYYY-MM-DD");
    const initialQuarterSlot = dateFns.format(initialTime, "HH:mm");
    const { quarterSlot, day } = chosenTimeSlot;

    if (quarterSlot) {
      const fullDate = day.full_date;

      if (initialFullDate !== fullDate || initialQuarterSlot !== quarterSlot) {
        setSelectedMobileTechnician({});
      }
    }
  }, [chosenTimeSlot]);

  const employees = () => {
    const result = [...availableJobEmployee];

    if (initialTime) {
      const initialFullDate = dateFns.format(initialTime, "YYYY-MM-DD");
      const initialQuarterSlot = dateFns.format(initialTime, "HH:mm");
      const { quarterSlot, day } = chosenTimeSlot;

      if (quarterSlot) {
        const fullDate = day.full_date;

        if (initialFullDate === fullDate && initialQuarterSlot === quarterSlot) {
          if (mobileTechnician && mobileTechnician.id) {
            result.push(mobileTechnician.id);
          }
        }
      }
    }

    return [...new Set(result)];
  };

  const availableMobileTechnicians = () => {
    let result = mobileTechnicians.filter(
      (item) => employees().indexOf(item.id) !== -1,
    );

    if (result.length === 0) {
      result = mobileTechnicians;
    }

    return result;
  };

  const storeRemoteJobDataHandle = () => {
    const {
      address, addressData, zone, geolocation, distance, distanceLimitIgnored,
    } = serviceLocation.pickUp;

    storeRemoteJobData({
      mobileTechnician: {
        jobId,
        address,
        addressData,
        collectionTime: {
          date: chosenTimeSlot.day.full_date,
          time: chosenTimeSlot.quarterSlot,
        },
        jobType: "mobile_technician",
        technicianId: selectedMobileTechnician.id,
        notes,
        distance_limit_ignored: distanceLimitIgnored,
        location_area: zone,
        geolocation,
        distance,
        overcapacity: chosenTimeSlot.overcapacity,
      },
    });

    setSchedulingStep(BOOKING_STEP_INTERNAL_NOTE);
  };

  if (!chosenTimeSlot) {
    return <Panel className={styles.loading}>Please select time slot first</Panel>;
  }

  return (
    <div className={styles.carousel}>
      {isLoading || (
        <>
          <MobileTechnicianSelection
            availableMobileTechnicians={availableMobileTechnicians()}
            selectedMobileTechnician={selectedMobileTechnician}
            setSelectedMobileTechnician={setSelectedMobileTechnician}
            notes={notes}
            setNotes={setNotes}
          />
          {mobileTechnicians.length === 0 && (
            <Alert variant="warning" text="Please select date and time first" />
          )}
          <div className={styles.submit}>
            <Button
              variant="brand"
              onClick={storeRemoteJobDataHandle}
              disabled={!selectedMobileTechnician.id}
            >
              Done
            </Button>
          </div>
        </>
      )}
      {isLoading && (
        <div className={styles.spinner}>
          <ClipLoader size={60} color="#0bcaf9" />
        </div>
      )}
    </div>
  );
};

ChooseMobileTechnician.propTypes = {
  availableJobEmployee: arrayOf(number),
  chosenTimeSlot: chosenTimeSlotPropType,
  initialTime: string,
  selectedAdvisor: serviceAdvisorPropType,
  retrieveMobileTechnicians: func.isRequired,
  storeRemoteJobData: func.isRequired,
  setSchedulingStep: func.isRequired,
  mobileTechnicians: arrayOf(mobileTechnicianPropType),
  serviceLocation: serviceLocationPropType.isRequired,
  mobileTechnician: mobileTechnicianPropType,
  prevNotes: string,
  jobId: number,
};

ChooseMobileTechnician.defaultProps = {
  availableJobEmployee: [],
  chosenTimeSlot: null,
  initialTime: null,
  selectedAdvisor: null,
  mobileTechnicians: null,
  mobileTechnician: {},
  prevNotes: "",
  jobId: null,
};

export default ChooseMobileTechnician;
