import React, { useEffect, useRef, useState } from "react";
import {
  arrayOf, bool, func, node, number, objectOf, string,
} from "prop-types";
import dateFns from "date-fns";
import { chosenTimeSlotPropType, daySlotPropType, serviceAdvisorPropType } from "shared/prop-types";
import { timeInUSFormat } from "shared/utils/datetime";
import Panel from "components/common/Panel";
import Button from "components/common/Button";
import ClipLoader from "react-spinners/ClipLoader";

import Picker from "./Picker";

import styles from "./styles.module.scss";

const TimeOfArrival = ({
  fetchDays,
  availableLoaners,
  dealershipSettings,
  validateWaiterPossibility,
  availableDays,
  setChosenQuarterSlot,
  setChosenDropOffSlot,
  chosenTimeSlot,
  chosenTimeSlot: { quarterSlot: chosenQuarterSlot },
  chosenDropOffTimeSlot,
  chosenDropOffTimeSlot: { quarterSlot: chosenDropOffQuarterSlot },
  setSchedulingStep,
  timeSlotsLoading,
  timeSlotsLoadingError,
  initialAdvisorId,
  selectedAdvisor,
  makeAdvisorSelection,
  nextStep,
  currentStep,
  isScheduling,
  phantomAdvisorAvailable,
  initialTime,
  dropOffInitialTime,
  isPickUp,
  isMobileTechnician,
  activePickupJob,
  activeMobileTechnicianJob,
  remoteJobData,
  storeRemoteJobData,
  dropOffType,
  ignoreStep,
}) => {
  const [bookableDays, setBookableDays] = useState([]);
  const [appointmentSlotsLoading, setAppointmentSlotsLoading] = useState(false);
  const [noAdvisor, setNoAdvisor] = useState(true);
  const [firstInit, setFirstInit] = useState(true);
  const containerRef = useRef(null);

  const resetChosenSlot = () => {
    if (dropOffType) {
      if (!ignoreStep) {
        setChosenDropOffSlot({});
      }
    } else {
      setChosenQuarterSlot({});
    }
  };

  useEffect(() => {
    if (firstInit) {
      if (selectedAdvisor && Object.keys(selectedAdvisor).length > 0) {
        setAppointmentSlotsLoading(true);
        setNoAdvisor(false);
        resetChosenSlot();

        const date = dateFns.parse((
          dropOffType && dropOffInitialTime && Object.keys(dropOffInitialTime).length > 0
            ? dropOffInitialTime
            : initialTime
        ) || new Date(), "YYYY-MM-DD");

        setFirstInit(false);
        fetchDays(
          (
            selectedAdvisor.availableAdvisorIds
            || selectedAdvisor.service_advisor_id
            || selectedAdvisor.id
          ),
          date.getMonth() + 1,
          date.getFullYear(),
          true,
          dropOffType,
        );
      } else if (!selectedAdvisor || Object.keys(selectedAdvisor).length === 0) {
        setNoAdvisor(true);
        resetChosenSlot();
      }
    } else if (!selectedAdvisor || Object.keys(selectedAdvisor).length === 0) {
      setNoAdvisor(true);
      resetChosenSlot();
    }
  }, [selectedAdvisor]);

  useEffect(() => {
    if (
      (
        (currentStep === "BOOKING_STEP_TIMESLOT" && !dropOffType)
        || (currentStep === "BOOKING_STEP_DROP_OFF_TIMESLOT" && dropOffType)
      )
      && !firstInit
    ) {
      if (selectedAdvisor && Object.keys(selectedAdvisor).length > 0) {
        setAppointmentSlotsLoading(true);
        setNoAdvisor(false);

        let date;

        const chosenSlot = dropOffType ? chosenDropOffTimeSlot : chosenTimeSlot;

        if (chosenSlot?.day?.full_date) {
          date = dateFns.parse(chosenSlot?.day?.full_date || new Date(), "YYYY-MM-DD");
        } else if (dropOffType) {
          date = dateFns.parse(chosenTimeSlot?.day?.full_date || new Date(), "YYYY-MM-DD");
        } else {
          resetChosenSlot();
          date = dateFns.parse(new Date(), "YYYY-MM-DD");
        }

        fetchDays(
          (
            selectedAdvisor.availableAdvisorIds
            || selectedAdvisor.service_advisor_id
            || selectedAdvisor.id
          ),
          date.getMonth() + 1,
          date.getFullYear(),
          false,
          dropOffType,
        );
      } else if (!selectedAdvisor || Object.keys(selectedAdvisor).length === 0) {
        setNoAdvisor(true);
        resetChosenSlot();
      }
    }
  }, [currentStep]);

  useEffect(() => {
    setBookableDays(availableDays);
    setAppointmentSlotsLoading(!appointmentSlotsLoading);
  }, [availableDays]);

  const handleQuarterSlotChoice = (quarterSlot, day, overcapacity = false, walkin = false) => {
    const payload = {
      quarterSlot,
      day,
      overcapacity,
      walkin,
    };

    if (dropOffType) {
      setChosenDropOffSlot(payload);
    } else {
      setChosenQuarterSlot(payload);
      setChosenDropOffSlot({});
    }

    const index = (new Date(`${day.full_date}T00:00`)).getDate() - 1;

    const serviceAdvisors = availableDays[index].slots[quarterSlot];
    let validServiceAdvisors = [];

    if (serviceAdvisors && phantomAdvisorAvailable && selectedAdvisor.phantomAdvisorIds) {
      validServiceAdvisors = serviceAdvisors.filter(
        (id) => selectedAdvisor.phantomAdvisorIds.indexOf(id) !== -1,
      );
    }

    if (validServiceAdvisors.length === 0) {
      validServiceAdvisors = serviceAdvisors;
    }

    const capacityValid = quarterSlot in availableDays[index].slots;

    if (capacityValid) {
      const advisors = availableDays[index].slots[quarterSlot].filter(
        (advisorId) => validServiceAdvisors.indexOf(advisorId) !== -1,
      );

      makeAdvisorSelection({
        ...selectedAdvisor,
        service_advisor_id: advisors[Math.floor(Math.random() * advisors.length)],
      });
    }

    if (!isMobileTechnician) {
      if (!isPickUp) {
        validateWaiterPossibility();
      }

      const isLoanerActive = dealershipSettings.integrations?.find((s) => s.kind === "loaner")?.active;
      if (isLoanerActive) {
        availableLoaners();
      }
    }
  };

  const formatTimeInfo = () => {
    const chosenSlot = dropOffType ? chosenDropOffTimeSlot : chosenTimeSlot;

    if (chosenSlot && !chosenSlot.day) {
      return "";
    }

    const { day: { full_date }, quarterSlot } = chosenSlot;
    const time = timeInUSFormat(quarterSlot);
    return `${dateFns.format(full_date, "dddd, MMMM D, YYYY")}, ${time}`;
  };

  const formatInitialTime = () => `${dateFns.format(
    dropOffType ? dropOffInitialTime : initialTime,
    "dddd, MMMM D, YYYY, hh:mm A",
  )}`;

  if (noAdvisor) {
    if (isPickUp || isMobileTechnician) {
      return <Panel className={styles.loading}>Please select a location</Panel>;
    }

    return <Panel className={styles.loading}>Please select service advisor first</Panel>;
  }

  if (timeSlotsLoadingError) {
    return <Panel className={styles.loading}>Please try again.</Panel>;
  }

  const isLoading = () => {
    const chosenSlot = dropOffType ? chosenDropOffTimeSlot : chosenTimeSlot;
    const step = dropOffType ? "BOOKING_STEP_DROP_OFF_TIMESLOT" : "BOOKING_STEP_TIMESLOT";

    return !!bookableDays.length && chosenSlot && (currentStep === step || ignoreStep);
  };

  return (
    <div ref={containerRef} className={styles.container}>
      {isLoading() && (
        <>
          <Picker
            timeSlotsLoading={timeSlotsLoading}
            days={bookableDays}
            chosenTimeSlot={dropOffType ? chosenDropOffTimeSlot : chosenTimeSlot}
            baseTimeSlot={chosenTimeSlot}
            onSetTimeSlot={handleQuarterSlotChoice}
            onReset={resetChosenSlot}
            fetchDays={fetchDays}
            initialAdvisorId={initialAdvisorId}
            selectedAdvisor={selectedAdvisor}
            isScheduling={isScheduling}
            isPickUp={isPickUp}
            isMobileTechnician={isMobileTechnician}
            activePickupJob={activePickupJob}
            activeMobileTechnicianJob={activeMobileTechnicianJob}
            initialTime={dropOffType ? dropOffInitialTime : initialTime}
            remoteJobData={remoteJobData}
            storeRemoteJobData={storeRemoteJobData}
            dropOffType={dropOffType}
          />
          <div className={styles.buttons}>
            <div className={styles.current}>
              {formatTimeInfo()}
              {(dropOffType ? dropOffInitialTime : initialTime) && (
                <span className={styles.currentText}>
                  {`Initially selected: ${formatInitialTime()}`}
                </span>
              )}
            </div>
            {!ignoreStep && (
              <Button
                variant="brand"
                disabled={dropOffType ? !chosenDropOffQuarterSlot : !chosenQuarterSlot}
                onClick={() => {
                  setSchedulingStep(nextStep);
                }}
              >
                Done
              </Button>
            )}
          </div>
        </>
      )}
      {!isLoading() && (
        <section className={styles.loading}>
          <ClipLoader size={40} color="#0bcaf9" />
        </section>
      )}
    </div>
  );
};

TimeOfArrival.propTypes = {
  fetchDays: func.isRequired,
  availableDays: arrayOf(daySlotPropType),
  setChosenQuarterSlot: func.isRequired,
  setChosenDropOffSlot: func.isRequired,
  chosenTimeSlot: chosenTimeSlotPropType.isRequired,
  chosenDropOffTimeSlot: chosenTimeSlotPropType,
  setSchedulingStep: func.isRequired,
  makeAdvisorSelection: func.isRequired,
  timeSlotsLoading: bool.isRequired,
  timeSlotsLoadingError: bool.isRequired,
  initialAdvisorId: number,
  selectedAdvisor: serviceAdvisorPropType,
  nextStep: string,
  currentStep: string.isRequired,
  validateWaiterPossibility: func.isRequired,
  isScheduling: bool,
  phantomAdvisorAvailable: bool.isRequired,
  availableLoaners: func.isRequired,
  dealershipSettings: arrayOf(string).isRequired,
  initialTime: string,
  dropOffInitialTime: string,
  isPickUp: bool.isRequired,
  isMobileTechnician: bool.isRequired,
  activePickupJob: objectOf(node),
  activeMobileTechnicianJob: objectOf(node),
  remoteJobData: objectOf(node),
  storeRemoteJobData: func.isRequired,
  dropOffType: bool,
  ignoreStep: bool,
};

TimeOfArrival.defaultProps = {
  availableDays: [],
  initialAdvisorId: null,
  selectedAdvisor: null,
  nextStep: "",
  isScheduling: false,
  initialTime: null,
  dropOffInitialTime: null,
  remoteJobData: {},
  activePickupJob: null,
  activeMobileTechnicianJob: null,
  dropOffType: false,
  chosenDropOffTimeSlot: {},
  ignoreStep: false,
};

export default TimeOfArrival;
