import {
  SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT,
  SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_FAILURE,
  SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_SUCCESS,
  SCHEDULING_PROCESS_FETCH_DAYS,
  SCHEDULING_PROCESS_FETCH_DAYS_ERROR,
  SCHEDULING_PROCESS_FETCH_DAYS_SUCCESS,
  SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS,
  SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_ERROR,
  SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS,
  SCHEDULING_PROCESS_FETCH_DECISION_TREES_SUCCESS,
  SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_FAILURE,
  SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_SUCCESS,
  SCHEDULING_PROCESS_FETCH_JOB_SLOTS_SUCCESS,
  SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE,
  SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS,
  SCHEDULING_PROCESS_FETCH_RECALLS,
  SCHEDULING_PROCESS_FETCH_RECALLS_ERROR,
  SCHEDULING_PROCESS_FETCH_RECALLS_SUCCESS,
  SCHEDULING_PROCESS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS,
  SCHEDULING_PROCESS_JOB_REACHABLE,
  SCHEDULING_PROCESS_JOB_REACHABLE_LOADING,
  SCHEDULING_PROCESS_SAVE_APPOINTMENT,
  SCHEDULING_PROCESS_SAVE_APPOINTMENT_FAILURE,
  SCHEDULING_PROCESS_SAVE_APPOINTMENT_SUCCESS,
  SCHEDULING_PROCESS_SET_CHOSEN_DROP_OFF_TIMESLOT,
  SCHEDULING_PROCESS_SET_CHOSEN_TIMESLOT,
  SCHEDULING_PROCESS_SET_CLIENT_TRANSPORT,
  SCHEDULING_PROCESS_SET_CLIENT_WAITING,
  SCHEDULING_PROCESS_SET_COMMUNICATION,
  SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_CUSTOMER,
  SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_VEHICLE,
  SCHEDULING_PROCESS_SET_CURRENT_STEP,
  SCHEDULING_PROCESS_SET_INITIAL_STATE,
  SCHEDULING_PROCESS_SET_INTERNAL_NOTE,
  SCHEDULING_PROCESS_SET_IS_DISTANCE_LIMIT_IGNORED,
  SCHEDULING_PROCESS_SET_IS_DROP_OFF,
  SCHEDULING_PROCESS_SET_IS_MOBILE_TECHNICIAN,
  SCHEDULING_PROCESS_SET_IS_PICK_UP,
  SCHEDULING_PROCESS_SET_RECALLS_SELECTION,
  SCHEDULING_PROCESS_SET_REMOTE_JOB_DATA,
  SCHEDULING_PROCESS_SET_REMOTE_SERVICE_LOCATION,
  SCHEDULING_PROCESS_SET_SEND_SMS,
  SCHEDULING_PROCESS_SET_SERVICE_ADVISOR,
  SCHEDULING_PROCESS_SET_SERVICE_SELECTION,
  SCHEDULING_PROCESS_UPDATE_AVAILABLE_LOANERS,
  SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED,
  SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED_FAILURE,
} from "store/actions/scheduling-process-actions";
import { BOOKING_STEP_SERVICES, FIRST_AVAILABLE_ADVISOR_ID } from "shared/constants";

const initialState = {
  currentVehicle: {},
  currentCustomer: {},
  dealershipMenuItems: {
    maintenance: [],
    concern: [],
  },
  selectedAdvisor: null,
  selectedServices: [],
  availableSlots: [],
  availableJobSlots: [],
  availableTechiciansJobSlots: [],
  decisionTrees: [],
  chosenTimeSlot: {},
  chosenDropOffTimeSlot: {},
  sendSms: true,
  overcapacity: false,
  walkin: false,
  contactNumber: null,
  clientWaiting: false,
  availableTransport: [],
  selectedTransport: null,
  currentStep: BOOKING_STEP_SERVICES,
  menuItemsLoading: false,
  menuItemsLoadingError: false,
  timeSlotsLoading: false,
  timeSlotsLoadingError: false,
  saveAppointmentLoading: false,
  saveAppointmentLoadingError: false,
  isPickUp: false,
  isDropOff: false,
  isMobileTechnician: false,
  bookedAppointment: {},
  remoteJobData: null,
  teamServiceAdvisors: [],
  waiterAllowed: false,
  waiterAllowedError: null,
  jobReachable: null,
  jobDropOffReachable: null,
  isJobReachableLoading: false,
  error: null,
  activeDrivers: [],
  activeDriversTotal: 0,
  activeMobileTechnicians: [],
  activeMobileTechniciansTotal: 0,
  serviceLocation: {
    pickUp: {
      address: "",
      addressData: {
        address_line1: "",
        address_line2: "",
        address_city: "",
        address_state: "",
        address_zipcode: "",
      },
    },
    dropOff: {
      address: "",
      addressData: {
        address_line1: "",
        address_line2: "",
        address_city: "",
        address_state: "",
        address_zipcode: "",
      },
    },
    isSameLocation: true,
  },
  recallsLoading: false,
  availableRecalls: [],
  recallsLoadingError: null,
  decisionTreeDetails: [],
};

const schedulingProcess = (state = initialState, { type, payload }) => {
  switch (type) {
    case SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_VEHICLE:
      return {
        ...state,
        currentVehicle: payload,
      };
    case SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_CUSTOMER:
      return {
        ...state,
        currentCustomer: payload,
        contactNumber: payload.phone_number,
      };
    case SCHEDULING_PROCESS_SET_INTERNAL_NOTE:
      return {
        ...state,
        internalNote: payload,
      };
    case SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS:
      return {
        ...state,
        menuItemsLoading: true,
      };
    case SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        dealershipMenuItems: {
          ...state.dealershipMenuItems,
          maintenance: payload.maintenance || [],
          concern: payload.concern || [],
          extension: payload.extension || [],
        },
        menuItemsLoading: false,
        menuItemsLoadingError: false,
      };
    case SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_ERROR:
      return {
        ...state,
        menuItemsLoading: false,
        menuItemsLoadingError: payload,
      };
    case SCHEDULING_PROCESS_FETCH_DECISION_TREES_SUCCESS:
      return {
        ...state,
        decisionTrees: payload.map((decisionTree) => {
          return {
            ...decisionTree,
            serviceId: decisionTree.menu_item.id,
          };
        }),
      };
    case SCHEDULING_PROCESS_SET_SERVICE_SELECTION:
      if (payload.data.length > 0) {
        return {
          ...state,
          selectedServices: [...payload.data],
          decisionTreeResults: payload.decisionTreeResults,
        };
      }
      return {
        ...state,
        selectedServices: [],
        selectedAdvisor: null,
        firstAvailable: false,
      };
    case SCHEDULING_PROCESS_FETCH_RECALLS:
      return {
        ...state,
        recallsLoading: true,
      };
    case SCHEDULING_PROCESS_FETCH_RECALLS_SUCCESS:
      return {
        ...state,
        availableRecalls: payload.recalls.map(
          (recall) => {
            return {
              ...recall,
              package_items: [],
              fee: 0,
            };
          },
        ),
        recallsLoading: false,
        recallsLoadingError: null,
      };
    case SCHEDULING_PROCESS_FETCH_RECALLS_ERROR:
      return {
        ...state,
        recallsLoading: false,
        recallsLoadingError: payload.error,
      };
    case SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT:
      return {
        ...state,
        availableTransportLoading: true,
        availableTransport: [],
        availableTransportError: null,
      };
    case SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_SUCCESS:
      return {
        ...state,
        availableTransport: payload.transports,
        availableTransportLoading: false,
        availableTransportError: null,
      };
    case SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_FAILURE:
      return {
        ...state,
        availableTransportLoading: false,
        availableTransport: [],
        availableTransportError: true,
      };
    case SCHEDULING_PROCESS_SET_RECALLS_SELECTION:
      return {
        ...state,
        selectedRecalls: payload,
      };
    case SCHEDULING_PROCESS_SET_SERVICE_ADVISOR:
      return {
        ...state,
        selectedAdvisor: payload,
        firstAvailable: payload.id === FIRST_AVAILABLE_ADVISOR_ID,
      };
    case SCHEDULING_PROCESS_FETCH_DAYS:
      return {
        ...state,
        timeSlotsLoading: true,
      };
    case SCHEDULING_PROCESS_FETCH_DAYS_SUCCESS:
      return {
        ...state,
        availableSlots: payload,
        availableJobSlots: [],
        availableTechiciansJobSlots: [],
        timeSlotsLoading: false,
        timeSlotsLoadingError: false,
      };
    case SCHEDULING_PROCESS_FETCH_JOB_SLOTS_SUCCESS:
      return {
        ...state,
        availableSlots: [],
        availableJobSlots: payload,
        availableTechiciansJobSlots: [],
        timeSlotsLoading: false,
        timeSlotsLoadingError: false,
      };
    case SCHEDULING_PROCESS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS:
      return {
        ...state,
        availableSlots: [],
        availableJobSlots: [],
        availableTechiciansJobSlots: payload,
        timeSlotsLoading: false,
        timeSlotsLoadingError: false,
      };
    case SCHEDULING_PROCESS_FETCH_DAYS_ERROR:
      return {
        ...state,
        availableSlots: payload,
        timeSlotsLoading: false,
        timeSlotsLoadingError: true,
      };
    case SCHEDULING_PROCESS_SET_CHOSEN_TIMESLOT: {
      const { overcapacity, walkin } = payload;
      return {
        ...state,
        chosenTimeSlot: payload,
        overcapacity,
        walkin,
      };
    }
    case SCHEDULING_PROCESS_SET_CHOSEN_DROP_OFF_TIMESLOT: {
      return {
        ...state,
        chosenDropOffTimeSlot: payload,
      };
    }
    case SCHEDULING_PROCESS_SET_SEND_SMS:
      return {
        ...state,
        sendSms: payload,
      };
    case SCHEDULING_PROCESS_SET_COMMUNICATION:
      return {
        ...state,
        contactNumber: payload,
      };
    case SCHEDULING_PROCESS_SET_CLIENT_WAITING:
      return {
        ...state,
        clientWaiting: payload,
      };
    case SCHEDULING_PROCESS_SET_CLIENT_TRANSPORT:
      return {
        ...state,
        selectedTransport: payload,
      };
    case SCHEDULING_PROCESS_SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: payload,
      };
    case SCHEDULING_PROCESS_SET_IS_PICK_UP:
      return {
        ...state,
        isPickUp: payload,
      };
    case SCHEDULING_PROCESS_SET_IS_DROP_OFF:
      return {
        ...state,
        isDropOff: payload,
      };
    case SCHEDULING_PROCESS_SET_IS_MOBILE_TECHNICIAN:
      return {
        ...state,
        isMobileTechnician: payload,
      };
    case SCHEDULING_PROCESS_SET_IS_DISTANCE_LIMIT_IGNORED:
      return {
        ...state,
        isDistanceLimitIgnored: payload,
      };
    case SCHEDULING_PROCESS_SET_REMOTE_JOB_DATA: {
      return {
        ...state,
        remoteJobData: {
          ...(state.remoteJobData || {}),
          ...payload,
        },
      };
    }
    case SCHEDULING_PROCESS_SAVE_APPOINTMENT:
      return {
        ...state,
        saveAppointmentLoading: true,
      };
    case SCHEDULING_PROCESS_SAVE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        bookedAppointment: payload,
        saveAppointmentLoading: false,
        saveAppointmentLoadingError: false,
      };
    case SCHEDULING_PROCESS_SAVE_APPOINTMENT_FAILURE:
      return {
        ...state,
        saveAppointmentLoadingError: payload.errors.error || { message: payload.errors },
        saveAppointmentLoading: false,
      };
    case SCHEDULING_PROCESS_SET_INITIAL_STATE:
      return {
        ...initialState,
        selectedServices: [],
      };
    case SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED:
      return {
        ...state,
        waiterAllowed: payload,
      };
    case SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED_FAILURE:
      return {
        ...state,
        waiterAllowedError: payload,
      };
    case SCHEDULING_PROCESS_UPDATE_AVAILABLE_LOANERS:
      return {
        ...state,
        availableLoaners: payload,
      };
    case SCHEDULING_PROCESS_JOB_REACHABLE_LOADING: {
      return {
        ...state,
        isJobReachableLoading: true,
        jobReachable: payload === "dropoff" ? state.jobReachable : null,
        jobDropOffReachable: payload === "dropoff" ? null : state.jobDropOffReachable,
      };
    }
    case SCHEDULING_PROCESS_JOB_REACHABLE:
      return {
        ...state,
        jobReachable: payload.jobType === "dropoff" ? state.jobReachable : payload.reachable,
        jobDropOffReachable: payload.jobType === "dropoff" ? payload.reachable : state.jobDropOffReachable,
        isJobReachableLoading: false,
      };
    case SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_SUCCESS:
      return {
        ...state,
        activeDrivers: payload.drivers,
        activeDriversTotal: payload.driversTotal,
        error: null,
      };
    case SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_FAILURE:
      return {
        ...state,
        error: payload.error,
      };
    case SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS:
      return {
        ...state,
        activeMobileTechnicians: payload.technicians,
        activeMobileTechniciansTotal: payload.techniciansTotal,
        error: null,
      };
    case SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE:
      return {
        ...state,
        error: payload.error,
      };
    case SCHEDULING_PROCESS_SET_REMOTE_SERVICE_LOCATION:
      return {
        ...state,
        serviceLocation: payload,
      };
    default:
      return state;
  }
};

export default schedulingProcess;
