import React, { useEffect, useRef, useState } from "react";
import { useDeepCompareEffect } from "shared/hooks";
import { connect } from "react-redux";
import cx from "classnames";
import Carousel from "nuka-carousel";
import {
  settingsTeamTagsSelector,
  settingsTimezoneSelector,
} from "store/selectors/settings-selectors";
import {
  bookingsAllAdvisorsCapacitySelector,
  bookingsDealershipAppointmentsCapacitySelector,
  bookingsDealershipHoursCapacitySelector,
  bookingsIsCapacityDashboardLoading,
  bookingsPeriodFiltersSelector,
  bookingsTeamTagsCapacitySelector,
} from "store/selectors/bookings-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import {
  retrieveDealershipTeamsCapacity,
  setAdditionalInfoFilterMultipleItems,
} from "store/actions/bookings-actions";
import { makeHashFromArray } from "shared/utils";
import ArrowButton from "components/common/CarouselNavigation/ArrowButton";
import {
  arrayOf, bool, func, instanceOf, shape, string,
} from "prop-types";
import * as moment from "moment-timezone";
import { authRoleSelector } from "store/selectors/auth-selectors";
import { MANAGER_ROLE } from "shared/constants";
import CapacityDashboardItem from "./CapacityDashboardItem";

const CapacityDashboard = ({
  teamTags,
  periodFilters,
  teamTagsCapacity,
  dealershipHoursCapacity,
  dealershipAppointmentsCapacity,
  allAdvisorsCapacity,
  fetchDealershipTeamsCapacity,
  changeAdditionalInfoFilterMultipleItems,
  isCapacityDashboardLoading,
  dealershipTimezone,
  role,
}) => {
  const [teamTagsHash, setTeamTagsHash] = useState(makeHashFromArray(teamTags));
  const [teamTagsCapacityHash, setTeamTagsCapacity] = useState(makeHashFromArray(teamTagsCapacity, "team_tag_id"));
  const [teamsCarouselIndex, setTeamsCarouselIndex] = useState(0);
  const [membersCarouselIndex, setMembersCarouselIndex] = useState(0);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [isAllCapacityActive, setIsAllCapacityActive] = useState(false);
  const previousPeriodFilters = useRef(null);
  const [allCapacityLabel, setAllCapacityLabel] = useState(null);
  const [allCapacityProgress, setAllCapacityProgress] = useState(null);
  const [allowBlock, setAllowBlock] = useState(false);

  useEffect(() => {
    if (role === MANAGER_ROLE) {
      setAllowBlock(
        new Date(periodFilters.from).getTime() === new Date(periodFilters.rawTo).getTime(),
      );
    }
  }, [periodFilters, role]);

  const prepareHoursCapacityProgressData = ({
    allocated_time,
    max_actual_labor_time,
    max_overcapacity_time,
    ...rest
  }) => {
    return {
      capacityHoursLabel: `${allocated_time || 0}/${max_actual_labor_time || 0}h`,
      capacityHoursProgress: (
        (allocated_time * 100) / (max_actual_labor_time || allocated_time || 1)
      ),
      ...rest,
    };
  };

  const updateAdvisors = (selectedTeam, advisors, itemsValue, isAllCapacity) => {
    setSelectedTeamId(selectedTeam);
    changeAdditionalInfoFilterMultipleItems({
      itemsKeys: advisors.map(
        ({ service_advisor_id }) => service_advisor_id,
      ).filter((i) => i),
      itemsValue,
      dropdownKey: "service_advisor",
    });
    setIsAllCapacityActive(isAllCapacity);
  };

  useEffect(() => {
    if (!isAllCapacityActive && !selectedTeamId) {
      updateAdvisors(null, allAdvisorsCapacity, false, true);
    }
  }, [allAdvisorsCapacity]);

  useDeepCompareEffect(() => {
    let fetchAllowed = false;

    if (previousPeriodFilters.current === null) {
      fetchAllowed = true;
    } else {
      Object.keys(periodFilters).forEach((key) => {
        if (!fetchAllowed) {
          const previousTime = new Date(previousPeriodFilters.current[key]).getTime();
          const currentTime = new Date(periodFilters[key]).getTime();
          fetchAllowed = previousTime !== currentTime;
        }
      });
    }

    if (fetchAllowed) {
      fetchDealershipTeamsCapacity();
    }

    previousPeriodFilters.current = periodFilters;
  }, [periodFilters]);

  useDeepCompareEffect(() => {
    if (teamTags && teamTags.length > 0) {
      setTeamTagsHash(makeHashFromArray(teamTags));
    }
  }, [teamTags]);

  useDeepCompareEffect(() => {
    if (teamTagsCapacity && teamTagsCapacity.length > 0) {
      setTeamTagsCapacity(makeHashFromArray(teamTagsCapacity, "team_tag_id"));
    }
  }, [teamTagsCapacity]);

  useEffect(() => {
    const hoursCapacity = prepareHoursCapacityProgressData(dealershipHoursCapacity);
    setAllCapacityLabel(hoursCapacity.capacityHoursLabel);
    setAllCapacityProgress(hoursCapacity.capacityHoursProgress);
  }, [dealershipHoursCapacity]);

  const handleTeamClick = (id) => {
    const selectedTeam = id === selectedTeamId ? null : id;
    const advisors = selectedTeam ? teamTagsHash[id].members : allAdvisorsCapacity;
    const itemsValue = id !== selectedTeamId;

    updateAdvisors(selectedTeam, advisors, itemsValue, id === selectedTeamId);
  };

  const prepareAppointmentsCapacityProgressData = ({
    allocated_appointments,
    max_advisor_appointments_per_day,
    ...rest
  }) => {
    return {
      capacityAppointmentsLabel: `${allocated_appointments || 0}/${max_advisor_appointments_per_day || 0}`,
      capacityAppointmentsProgress: (
        (allocated_appointments * 100) / (max_advisor_appointments_per_day || 1)
      ),
      ...rest,
    };
  };

  const {
    dealership_appointments_amount: allCapacityAppointmentsLabel,
    max_dealership_appointments_per_day: allCapacityAppointmentsProgress,
  } = dealershipAppointmentsCapacity;

  const canSlideLeft = (carouselIndex) => carouselIndex > 0;
  const canSlideRight = (carouselIndex, slidesToShow, collectionLength) => (
    (carouselIndex + slidesToShow) < collectionLength
  );
  const isTeamsNavigationActive = Object.values(teamTagsHash).length > 4;
  const isMembersNavigationActive = (
    (selectedTeamId && teamTagsHash[selectedTeamId].members.length > 4)
    || (isAllCapacityActive && allAdvisorsCapacity.length > 4)
  );

  return (
    <div className="conciergeBookingPageCapacityDashboard capacityDashboard">
      {isCapacityDashboardLoading && (
        <div className="capacityDashboardLoadingOverlay">Loading ...</div>
      )}
      <div className="capacityDashboardRow">
        <button
          type="button"
          className={cx("capacityDashboardRowItem capacityDashboardRowItemPinned", {
            capacityDashboardRowItemPale: isAllCapacityActive,
          })}
          onClick={() => updateAdvisors(null, allAdvisorsCapacity, false, true)}
        >
          <div className="capacityDashboardRowItemTop">
            <div className="capacityDashboardRowItemTitle">All hours</div>
            <div className="capacityDashboardRowItemCounter">{allCapacityLabel}</div>
          </div>
          <div className="capacityDashboardProgress">
            <div className="capacityDashboardProgressBar">
              <div
                className="capacityDashboardProgressBarValue"
                style={{ width: `${allCapacityProgress}%` }}
              />
            </div>
          </div>
        </button>
        <div className="capacityDashboardCarouselOuter">
          {isTeamsNavigationActive && (
            <ArrowButton
              isLeft
              onClick={() => setTeamsCarouselIndex(teamsCarouselIndex - 1)}
              disabled={!canSlideLeft(teamsCarouselIndex)}
            />
          )}
          <div className={`capacityDashboardCarouselWrapper${isTeamsNavigationActive ? "" : "NoButtons"}`}>
            <Carousel
              slidesToShow={4}
              swiping={false}
              dragging={false}
              slideIndex={teamsCarouselIndex}
              withoutControls
              className="capacityDashboardCarousel"
            >
              {!isCapacityDashboardLoading && Object.values(teamTagsHash)
                .map((item) => prepareHoursCapacityProgressData({
                  ...item,
                  ...teamTagsCapacityHash[item.id],
                }))
                .map(({
                  id, name, capacityHoursLabel, capacityHoursProgress,
                }) => (
                  <CapacityDashboardItem
                    key={id}
                    isSelected={id === selectedTeamId}
                    isDropdown
                    allowBlock={allowBlock}
                    locked={teamTagsCapacityHash[id]?.locked || false}
                    day={moment.tz(
                      periodFilters.from,
                      "YYYY-MM-DD",
                      dealershipTimezone,
                    ).format().split("T")[0]}
                    name={name}
                    teamTagId={id}
                    capacityLabel={capacityHoursLabel}
                    capacityProgress={capacityHoursProgress}
                    onToggleClick={() => handleTeamClick(id)}
                  />
                ))}
            </Carousel>
          </div>
          {isTeamsNavigationActive && (
            <ArrowButton
              isRight
              onClick={() => setTeamsCarouselIndex(teamsCarouselIndex + 1)}
              disabled={!canSlideRight(
                teamsCarouselIndex,
                4,
                Object.values(teamTagsCapacityHash).length,
              )}
            />
          )}
        </div>
      </div>
      <div className="capacityDashboardRow capacityDashboardRowPale">
        <button
          type="button"
          className={cx("capacityDashboardRowItem capacityDashboardRowItemPinned", {
            capacityDashboardRowItemPale: isAllCapacityActive,
          })}
          onClick={() => updateAdvisors(null, allAdvisorsCapacity, false, true)}
        >
          <div className="capacityDashboardRowItemTop">
            <div className="capacityDashboardRowItemTitle">All appointments</div>
            <div className="capacityDashboardRowItemCounter">{allCapacityAppointmentsLabel}</div>
          </div>
          <div className="capacityDashboardProgress">
            <div className="capacityDashboardProgressBar">
              <div
                className="capacityDashboardProgressBarValue"
                style={{ width: `${allCapacityAppointmentsProgress}%` }}
              />
            </div>
          </div>
        </button>
        <div className="capacityDashboardCarouselOuter">
          {isMembersNavigationActive && (
            <ArrowButton
              isLeft
              onClick={() => setMembersCarouselIndex(membersCarouselIndex - 1)}
              disabled={!canSlideLeft(membersCarouselIndex)}
            />
          )}
          <div className={`capacityDashboardCarouselWrapper${isMembersNavigationActive ? "" : "NoButtons"}`}>
            <Carousel
              slidesToShow={4}
              swiping={false}
              dragging={false}
              slideIndex={membersCarouselIndex}
              withoutControls
              className="capacityDashboardCarousel capacityDashboardCarouselAdvisors"
            >
              {(selectedTeamId ? teamTagsHash[selectedTeamId].members : allAdvisorsCapacity)
                .map(({
                  id, name, service_advisor_name, service_advisor_id, photo,
                }) => {
                  const advisorCapacity = allAdvisorsCapacity
                    .find(
                      ({ service_advisor_id: advisor_id }) => advisor_id === service_advisor_id,
                    );

                  const {
                    capacityAppointmentsLabel,
                    capacityAppointmentsProgress,
                  } = prepareAppointmentsCapacityProgressData(advisorCapacity || {});

                  const lockedAdvisor = () => {
                    const teamIds = Object.values(teamTagsCapacityHash).filter(
                      (item) => item?.locked && item.team_tag_id === selectedTeamId,
                    ).map((item) => item.team_tag_id);

                    const advisorIds = new Set();

                    Object.values(teamTagsCapacity).forEach((team) => {
                      Object.values(team.service_advisors_capacities).forEach((advisor) => {
                        if (advisor.on_vacation) {
                          advisorIds.add(advisor.service_advisor_id);
                        }
                      });
                    });

                    Object.values(teamTagsHash)
                      .filter((item) => teamIds.indexOf(item.id) !== -1)
                      .forEach(
                        (item) => {
                          Object.values(item.members).map(
                            (memeber) => memeber.service_advisor_id,
                          ).forEach((advisorId) => advisorIds.add(advisorId));
                        },
                      );

                    return advisorIds.has(service_advisor_id);
                  };

                  return (
                    <CapacityDashboardItem
                      key={service_advisor_id || id}
                      teamTagId={id}
                      name={service_advisor_name || name}
                      avatarSrc={photo?.url}
                      capacityLabel={capacityAppointmentsLabel}
                      capacityProgress={capacityAppointmentsProgress}
                      locked={lockedAdvisor()}
                    />
                  );
                })}
            </Carousel>
          </div>
          {isMembersNavigationActive && (
            <ArrowButton
              isRight
              onClick={() => setMembersCarouselIndex(membersCarouselIndex + 1)}
              disabled={!canSlideRight(
                membersCarouselIndex,
                4,
                (selectedTeamId && teamTagsHash[selectedTeamId].members.length)
              || allAdvisorsCapacity.length,
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CapacityDashboard.propTypes = {
  teamTags: arrayOf(shape).isRequired,
  periodFilters: shape({
    from: instanceOf(Date),
    to: instanceOf(Date),
  }),
  teamTagsCapacity: arrayOf(shape).isRequired,
  dealershipHoursCapacity: shape.isRequired,
  dealershipAppointmentsCapacity: shape.isRequired,
  allAdvisorsCapacity: arrayOf(shape).isRequired,
  fetchDealershipTeamsCapacity: func.isRequired,
  changeAdditionalInfoFilterMultipleItems: func,
  isCapacityDashboardLoading: bool,
  dealershipTimezone: string.isRequired,
  role: string.isRequired,
};

CapacityDashboard.defaultProps = {
  periodFilters: null,
  changeAdditionalInfoFilterMultipleItems: () => {},
  isCapacityDashboardLoading: null,
};

const mapStateToProps = (state) => {
  return {
    periodFilters: bookingsPeriodFiltersSelector(state),
    teamTags: settingsTeamTagsSelector(state),
    teamTagsCapacity: bookingsTeamTagsCapacitySelector(state),
    dealershipHoursCapacity: bookingsDealershipHoursCapacitySelector(state),
    dealershipAppointmentsCapacity: bookingsDealershipAppointmentsCapacitySelector(state),
    isCapacityDashboardLoading: bookingsIsCapacityDashboardLoading(state),
    allAdvisorsCapacity: bookingsAllAdvisorsCapacitySelector(state),
    dealershipId: dealershipIdSelector(state),
    dealershipTimezone: settingsTimezoneSelector(state),
    role: authRoleSelector(state),
  };
};

const actions = {
  changeAdditionalInfoFilterMultipleItems: setAdditionalInfoFilterMultipleItems,
  fetchDealershipTeamsCapacity: retrieveDealershipTeamsCapacity,
};

export default connect(mapStateToProps, actions)(CapacityDashboard);
