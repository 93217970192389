import React from "react";
import {
  func, instanceOf, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import DatePicker from "components/common/DatePicker";
import {
  BOOKINGS_DATEPICKER_DATE_FORMAT,
  BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP,
  convertFilterTime,
  parseBookingsDatePickerDate,
} from "shared/utils/datetime";
import {
  resetTimelineFilters,
  setPeriodFilter,
  setTimelineFilter,
} from "store/actions/bookings-actions";
import { settingsTimezoneSelector } from "store/selectors/settings-selectors";
import { bookingsPeriodFiltersSelector } from "store/selectors/bookings-selectors";

import "./styles.scss";

const PeriodFilter = ({
  setFilter,
  setTimelineFilterAction,
  resetTimelineFiltersAction,
  timezone,
  periodFilters,
}) => {
  const applyChanges = (from, to) => {
    const rawTo = new Date(to);
    rawTo.setHours(0, 0, 0);

    setFilter("from", convertFilterTime(from, timezone));
    setFilter("to", convertFilterTime(to, timezone));
    setFilter("rawTo", convertFilterTime(rawTo, timezone));
  };

  const handleResetChanges = () => {
    resetTimelineFiltersAction();
    setTimelineFilterAction("all");
  };

  return (
    <section className="conciergeBookingsPeriodFilter">
      <DatePicker
        rangeMode
        format={BOOKINGS_DATEPICKER_DATE_FORMAT}
        separator="/"
        separatorPositions={[2, 5]}
        formatRegExp={BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP}
        parseDateInFormat={parseBookingsDatePickerDate}
        startDateLabel="From"
        endDateLabel="To"
        onApplyChanges={applyChanges}
        onResetChanges={handleResetChanges}
        previousDates={periodFilters}
        previousDatesNaming={{
          startDate: "from",
          endDate: "to",
        }}
        timezone={timezone}
      />
    </section>
  );
};

PeriodFilter.propTypes = {
  timezone: string,
  setFilter: func,
  setTimelineFilterAction: func,
  resetTimelineFiltersAction: func,
  periodFilters: shape({
    from: instanceOf(Date),
    to: instanceOf(Date),
  }),
};

PeriodFilter.defaultProps = {
  setFilter: () => {},
  setTimelineFilterAction: () => {},
  resetTimelineFiltersAction: () => {},
  periodFilters: null,
  timezone: "",
};

const mapStateToProps = (state) => {
  return {
    periodFilters: bookingsPeriodFiltersSelector(state),
    timezone: settingsTimezoneSelector(state),
  };
};

const actions = {
  setFilter: setPeriodFilter,
  setTimelineFilterAction: setTimelineFilter,
  resetTimelineFiltersAction: resetTimelineFilters,
};

export default connect(mapStateToProps, actions)(PeriodFilter);
