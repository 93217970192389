import React, { useState } from "react";
import cx from "classnames";
import {
  arrayOf, bool, func, node, number, objectOf, string,
} from "prop-types";
import OvercapacityPopup from "./OvercapacityPopup";
import TimeSlot from "./TimeSlot";

import "./styles.scss";

const TimePicker = ({
  slots,
  currentDate,
  timeArr,
  time,
  setTime,
  height,
  firstAvailable,
  isPickUp,
  isMobileTechnician,
}) => {
  const [overcapacityTimeSlot, setOvercapacityTimeSlot] = useState(null);

  const handleTimeSlotClick = (timeSlot) => {
    if (timeArr.find((t) => t === timeSlot)) {
      setTime(timeSlot);
    } else if (!firstAvailable) {
      setOvercapacityTimeSlot(timeSlot);
    }
  };

  return (
    <div className="timePickerContainer">
      <div
        className={cx("timePicker", { isEmpty: slots.length === 0 })}
        style={{ height: `${height}px` }}
      >
        <div className="timePickerView">
          {slots.length === 0 && !currentDate && (
            <div className="textContainer">
              <p>Please select a date</p>
            </div>
          )}
          {slots.length === 0 && currentDate && (
            <div className="textContainer">
              <p>Time slots are not available for this date.</p>
            </div>
          )}
          {slots.map((timeSlot) => (
            <TimeSlot
              key={timeSlot}
              timeSlot={timeSlot}
              isActive={timeSlot === time || timeSlot === overcapacityTimeSlot}
              disabled={!timeArr.find((t) => t === timeSlot)}
              onClick={() => handleTimeSlotClick(timeSlot)}
            />
          ))}
        </div>
      </div>
      {overcapacityTimeSlot && (
        <div className="overcapacityPopupContainer">
          <OvercapacityPopup
            onClose={() => setOvercapacityTimeSlot(null)}
            onSubmit={(overcapacity, walkin) => {
              setTime(overcapacityTimeSlot, overcapacity, walkin);
            }}
            isPickUp={isPickUp}
            isMobileTechnician={isMobileTechnician}
          />
        </div>
      )}
    </div>
  );
};

TimePicker.propTypes = {
  slots: arrayOf(string).isRequired,
  currentDate: objectOf(node),
  timeArr: arrayOf(string).isRequired,
  time: string,
  setTime: func.isRequired,
  height: number.isRequired,
  firstAvailable: bool.isRequired,
  isPickUp: bool,
  isMobileTechnician: bool,
};

TimePicker.defaultProps = {
  currentDate: null,
  time: null,
  isPickUp: false,
  isMobileTechnician: false,
};

export default TimePicker;
