import React from "react";
import { node, string } from "prop-types";
import cx from "classnames";

import "./styles.scss";
import { connect } from "react-redux";
import { settingsPublicAlertSelector } from "store/selectors/settings-selectors";
import Alert from "../Alert";

const PageHeader = ({
  title, rightSideContent, className, errorMessage,
}) => (
  <>
    <section className={cx("conciergePageHeader", className)}>
      <section className="conciergePageHeaderCentering">
        <div className="conciergePageHeaderTitle">{title}</div>
        {
          rightSideContent
            ? <div className="conciergePageHeaderRightContent">{rightSideContent}</div>
            : null
        }
      </section>
    </section>
    {errorMessage && <Alert variant="error" text={errorMessage} />}
  </>
);

PageHeader.propTypes = {
  title: node.isRequired,
  rightSideContent: node,
  className: string,
  errorMessage: string,
};

PageHeader.defaultProps = {
  rightSideContent: null,
  className: null,
  errorMessage: null,
};

const mapStateToProps = (state) => {
  return {
    errorMessage: settingsPublicAlertSelector(state),
  };
};

export default connect(mapStateToProps, null)(PageHeader);
