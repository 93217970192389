import React, { useEffect, useState } from "react";
import { match as matchType } from "react-router-prop-types";
import {
  arrayOf, bool, func, node, number, objectOf, string,
} from "prop-types";
import { connect } from "react-redux";
import { isEmpty, isNil } from "ramda";
import { Link } from "react-router-dom";
import { NotificationContainer, NotificationManager } from "react-notifications";

import { convertArrayToSelectOptions } from "shared/utils/common";
import { extractErrorMessage } from "shared/utils/errorHandler";

import { US_STATES } from "shared/constants/us_states";

import {
  dealershipDetailsDataSelector,
  dealershipDetailsErrorSelector,
  dealershipDetailsLoadingStateSelector,
  dealershipDetailsMaintainersSelector,
} from "store/selectors/dealership-details-selectors";
import { fetchDealershipTeamTags, retrieveDealershipDetails, updateDmsIntegrationOptions } from "store/actions/dealership-details-actions";
import { changeDealershipInfo } from "store/actions/settings-actions";
import { retrieveMakeModelYearMap } from "store/actions/app-actions";

import { availableTimezones } from "shared/utils/datetime";
import ArrowLeft from "assets/images/arrow/left.svg";

import StyledSelect from "components/common/StyledSelect";
import PageHeader from "components/common/PageHeader";
import Panel from "components/common/Panel";
import Input from "components/common/Input";
import HeaderEdit from "components/common/HeaderEdit";
import AddIntegrationModal from "./AddIntegrationModal";
import DealershipMaintaniers from "./DealershipMaintaners";
import IntegrationsPanel from "../SettingsPage/GeneralTab/IntegrationsPanel";

import styles from "./styles.module.scss";

const DealershipDetailsPage = ({
  fetchDealershipDetails,
  fetchTeamTags,
  match,
  dealership,
  dealership: { integrations },
  isLoading,
  error,
  updateDealershipInfo,
  maintainers,
  fetchMakeModelYearMap,
  updateDmsIntegration,
}) => {
  const [dealershipId, setDealershipId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [dealershipName, setDealershipName] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [addressZipcode, setAddressZipcode] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [teamTags, setTeamTags] = useState([]);

  useEffect(() => {
    if (!isNil(dealership) && dealership.id !== dealershipId) {
      const {
        id, name, address_line, address_zipcode, address_city,
        address_state, time_zone, phone, team_tags,
      } = dealership;
      setDealershipId(id);
      setDealershipName(name);
      setAddressLine(address_line);
      setAddressZipcode(address_zipcode);
      setAddressCity(address_city);
      setAddressState(address_state);
      setPhoneNumber(phone);
      setTimeZone(time_zone);
      setTeamTags(team_tags);
    }
    if (
      isNil(dealership)
      || isEmpty(dealership)
      || (!isEmpty(dealership)
        && !isNil(dealership)
        && dealership.id
        && dealership.id !== match.params.id)
    ) {
      fetchDealershipDetails(match.params.id);
      fetchTeamTags(match.params.id);
      fetchMakeModelYearMap();
    }
  }, []);

  useEffect(() => {
    if (!isNil(dealership)) {
      const {
        id, name, address_line, address_zipcode, address_state,
        address_city, time_zone, phone, team_tags,
      } = dealership;
      setDealershipId(id);
      setDealershipName(name);
      setAddressLine(address_line);
      setAddressZipcode(address_zipcode);
      setAddressCity(address_city);
      setAddressState(address_state);
      setPhoneNumber(phone);
      setTimeZone(time_zone);
      setTeamTags(team_tags);
    }
  }, [dealership]);

  useEffect(() => {
    if (error?.data) {
      Object.keys(error.data).forEach((key) => {
        NotificationManager.error(
          `${key} ${error.data[key]}`,
          "Error",
          3000,
        );
      });
    }
  }, [error]);

  const title = (value) => (
    <Link
      to={{ pathname: "/dealerships" }}
      className={styles.goBackButton}
    >
      <img src={ArrowLeft} alt="" />
      <h2>Dealerships / </h2>
      <span>{value}</span>
    </Link>
  );

  const cancelChanges = () => {
    const {
      name, address_line, address_zipcode, address_state, address_city, time_zone, phone,
    } = dealership;
    setDealershipName(name);
    setAddressLine(address_line);
    setAddressZipcode(address_zipcode);
    setAddressCity(address_city);
    setAddressState(address_state);
    setPhoneNumber(phone);
    setTimeZone(time_zone);
    setIsEditing(false);
  };

  const saveChanges = () => {
    const dataToSend = new FormData();
    dataToSend.append("name", dealershipName);
    dataToSend.append("address_line", addressLine);
    dataToSend.append("address_zipcode", addressZipcode);
    dataToSend.append("address_city", addressCity);
    dataToSend.append("address_state", addressState);
    dataToSend.append("phone", phoneNumber);
    dataToSend.append("time_zone", timeZone);

    updateDealershipInfo(dealershipId, dataToSend);
    setIsEditing(false);
  };

  const renderContent = () => {
    const preparedTimezoneOptions = availableTimezones().map((availableTimezone) => {
      return {
        label: availableTimezone,
        value: availableTimezone,
      };
    });
    const defaultOption = {
      label: "",
      value: "",
    };
    const selectedTimezone = preparedTimezoneOptions.find((timezoneOption) => (
      timezoneOption.value === timeZone
    ));
    const timezoneOptions = selectedTimezone
      ? preparedTimezoneOptions : [defaultOption, ...preparedTimezoneOptions];

    if (isLoading) {
      return (
        <Panel className={styles.loadingPanel}>
          Loading...
        </Panel>
      );
    }

    if (error) {
      return (
        <Panel className={styles.loadingPanel}>
          {extractErrorMessage(error)}
        </Panel>
      );
    }

    const header = (
      <>
        <p>Dealership details</p>
        <HeaderEdit
          isEditing={isEditing}
          handleCancel={cancelChanges}
          handleEdit={() => setIsEditing(true)}
          handleSave={saveChanges}
        />
      </>
    );

    return (
      <>
        <Panel
          header={header}
          className={styles.panel}
        >
          <Input
            label="Name"
            type="text"
            value={dealershipName}
            disabled={!isEditing}
            onChange={(value) => setDealershipName(value)}
          />
          <Input
            label="Address line"
            type="text"
            value={addressLine}
            disabled={!isEditing}
            onChange={(value) => setAddressLine(value)}
          />
          <Input
            label="Zipcode"
            type="text"
            value={addressZipcode}
            disabled={!isEditing}
            maxLength={5}
            onChange={(value) => setAddressZipcode(value)}
          />
          <Input
            label="City"
            type="text"
            value={addressCity}
            disabled={!isEditing}
            onChange={(value) => setAddressCity(value)}
          />
          <div className={styles.inputWrapper}>
            <StyledSelect
              label="State"
              value={{
                label: addressState,
                value: addressState,
              }}
              disabled={!isEditing}
              options={convertArrayToSelectOptions(US_STATES)}
              onChange={(state) => setAddressState(state.value)}
              isOptionDisabled={(option) => option.selectable === false}
              className={styles.select}
            />
          </div>
          <Input
            label="Phone"
            type="text"
            value={phoneNumber}
            disabled={!isEditing}
            onChange={(value) => setPhoneNumber(value)}
          />
          <div className={styles.inputWrapper}>
            <StyledSelect
              label="Dealership's  timezone"
              key="dealership_timezone"
              options={timezoneOptions}
              disabled={!isEditing}
              value={selectedTimezone || defaultOption}
              onChange={({ value }) => setTimeZone(value)}
              className={styles.select}
            />
          </div>
        </Panel>
        <DealershipMaintaniers maintainers={maintainers} />
        <IntegrationsPanel
          integrations={integrations}
          dealership={dealership}
          updateDmsIntegration={updateDmsIntegration}
          isSuperAdmin
          onAddIntegrationClick={() => setIsModalOpen(true)}
        />
      </>
    );
  };

  return (
    <section className={styles.page}>
      <PageHeader
        title={title(dealership.name)}
      />
      <NotificationContainer />
      <section className={styles.main}>
        {renderContent()}
      </section>
      {isModalOpen && (
        <AddIntegrationModal
          excludedIntegrations={dealership.integrations.map(
            (i) => i.kind,
          )}
          dealershipId={dealershipId}
          teamTags={teamTags}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </section>
  );
};

DealershipDetailsPage.propTypes = {
  fetchDealershipDetails: func,
  fetchTeamTags: func,
  match: matchType,
  isLoading: bool,
  maintainers: arrayOf({
    id: number,
    email: string,
  }).isRequired,
  dealership: objectOf(string),
  error: objectOf(node),
  updateDealershipInfo: func,
  fetchMakeModelYearMap: func.isRequired,
  updateDmsIntegration: func.isRequired,
};

DealershipDetailsPage.defaultProps = {
  fetchDealershipDetails: () => {},
  fetchTeamTags: () => {},
  isLoading: false,
  match: null,
  dealership: null,
  error: null,
  updateDealershipInfo: () => {},
};

const mapStateToProps = (state) => {
  return {
    isLoading: dealershipDetailsLoadingStateSelector(state),
    dealership: dealershipDetailsDataSelector(state),
    maintainers: dealershipDetailsMaintainersSelector(state),
    error: dealershipDetailsErrorSelector(state),
  };
};

const actions = {
  fetchDealershipDetails: retrieveDealershipDetails,
  fetchTeamTags: fetchDealershipTeamTags,
  updateDealershipInfo: changeDealershipInfo,
  fetchMakeModelYearMap: retrieveMakeModelYearMap,
  updateDmsIntegration: updateDmsIntegrationOptions,
};

export default connect(mapStateToProps, actions)(DealershipDetailsPage);
