import React from "react";
import { node, objectOf } from "prop-types";
import Panel from "components/common/Panel";

import SelectedServices from "./SelectedServices";
import TeamAllocation from "./TeamAllocation";
import EstimatedServiceTimeAndTotalCost from "./EstimatedServiceTimeAndTotalCost";

import styles from "./styles.module.scss";

const BookingDetailsSummary = ({ booking }) => (
  <Panel className={styles.panel}>
    <SelectedServices booking={booking} />
    <EstimatedServiceTimeAndTotalCost booking={booking} />
    <TeamAllocation booking={booking} />
  </Panel>
);

BookingDetailsSummary.propTypes = {
  booking: objectOf(node),
};

BookingDetailsSummary.defaultProps = {
  booking: null,
};

export default BookingDetailsSummary;
