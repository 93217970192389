import React from "react";
import {
  arrayOf, bool, func, number, oneOfType, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import { customerFieldsCDK, customerFieldsDT } from "shared/fields";
import { BUSINESS_CUSTOMER_TYPE, INDIVIDUAL_CUSTOMER_TYPE } from "shared/constants";
import {
  bookingDetailsDataModalSavingErrorSelector,
  bookingDetailsDataModalSavingSelector,
} from "store/selectors/booking-details-selectors";
import { createCustomer, updateCustomerData } from "store/actions/booking-details-actions";
import { dmsTypeSelector } from "store/selectors/settings-selectors";
import Button from "components/common/Button";
import DataEditionModal from "components/common/DataEditionModal";

import styles from "./styles.module.scss";

const ClientDetailsBlock = ({
  booking,
  booking: {
    customer,
  },
  dmsType,
  isEditing,
  dataEditionModalSaving,
  dataEditionModalSavingError,
  handleFieldEdit,
  saveEditedCustomerData,
}) => (
  <section className={styles.container}>
    <div className={styles.row}>
      <div className={styles.item}>
        <div className={styles.label}>
          Customer
        </div>
        <div className={styles.value}>
          {customer?.first_name || customer?.last_name
            ? `${customer?.first_name || ""} ${customer?.last_name || ""}`
            : "-"}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>
          Phone
        </div>
        <div className={styles.value}>
          {customer?.phone_number || "-"}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>
          Email
        </div>
        <div className={styles.value}>
          {customer?.email || "-"}
        </div>
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.item}>
        <div className={styles.label}>
          Address
        </div>
        <div className={styles.value}>
          {customer?.address || "-"}
        </div>
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.item}>
        <div className={styles.label}>
          Customer Internal Note
        </div>
        <div className={styles.value}>
          {customer?.internal_note && <p className={styles.note}>{customer?.internal_note}</p> }
          {!customer?.internal_note && <p>-</p> }
        </div>
      </div>
      <div className={styles.item}>
        <Button
          onClick={() => handleFieldEdit(true)}
          withoutBorder
          variant="base-grey"
          leftIcon="editGrey"
        >
          Edit
        </Button>
      </div>
    </div>
    {isEditing && (
      <DataEditionModal
        onClose={() => handleFieldEdit(false)}
        title="Add customer data"
        fields={
          dmsType === "dealer_track" ? customerFieldsDT : customerFieldsCDK
        }
        onSubmit={(data) => (
          !customer
            ? createCustomer(data, booking.id)
            : saveEditedCustomerData(data, booking.customer)
        )}
        initialData={{
          first_name: (
            customer?.customer_type === INDIVIDUAL_CUSTOMER_TYPE
              ? customer?.first_name
              : null
          ),
          last_name: (
            customer?.customer_type === INDIVIDUAL_CUSTOMER_TYPE
              ? customer?.last_name
              : null
          ),
          phone_number: customer?.phone_number,
          email: customer?.email,
          address_line1: customer?.address_line1,
          address_state: customer?.address_state,
          address_zipcode: customer?.address_zipcode,
          address_city: customer?.address_city,
          customer_type: customer?.customer_type || INDIVIDUAL_CUSTOMER_TYPE,
          company_name: (
            customer?.customer_type === BUSINESS_CUSTOMER_TYPE
              ? customer?.company_name
              : null
          ),
          internal_note: customer?.internal_note,
        }}
        submitButtonText="Save"
        loading={dataEditionModalSaving}
        error={dataEditionModalSavingError}
      />
    )}
  </section>
);

ClientDetailsBlock.propTypes = {
  booking: shape({
    id: number,
    customer: shape({
      first_name: string,
      last_name: string,
      phone_number: number,
      email: string,
      address: string,
    }),
  }),
  isEditing: bool,
  handleFieldEdit: func,
  dmsType: string,
  dataEditionModalSaving: bool,
  dataEditionModalSavingError: oneOfType([string, arrayOf(string)]).isRequired,
  saveEditedCustomerData: func,
};

ClientDetailsBlock.defaultProps = {
  booking: null,
  isEditing: null,
  handleFieldEdit: () => {},
  dmsType: "",
  dataEditionModalSaving: false,
  saveEditedCustomerData: () => {},
};

const mapStateToProps = (state) => {
  return {
    dataEditionModalSaving: bookingDetailsDataModalSavingSelector(state),
    dataEditionModalSavingError: bookingDetailsDataModalSavingErrorSelector(
      state,
    ),
    dmsType: dmsTypeSelector(state),
  };
};

export default connect(mapStateToProps, {
  saveEditedCustomerData: updateCustomerData,
  createCustomerAction: createCustomer,
})(ClientDetailsBlock);
