import React, { useEffect, useState } from "react";
import {
  bool, func, objectOf, string,
} from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import {
  dealershipsAddingErrorSelector, dealershipsAddingLoadingSelector,
} from "store/selectors/dealerships-selectors";
import { addDealership } from "store/actions/dealerships-actions";
import { dealeshipFields } from "shared/fields";
import { US_STATES } from "shared/constants/us_states";
import { availableTimezones } from "shared/utils/datetime";
import { convertArrayToSelectOptions, formatInput } from "shared/utils/common";
import { renderDataEditionErrors } from "components/common/DataEditionModal/helpers";
import StyledSelect from "components/common/StyledSelect";
import Modal from "components/common/Modal";

import styles from "./styles.module.scss";

const AddDealershipModal = ({
  onClose,
  onSubmit,
  loading,
  error,
}) => {
  const [data, setData] = useState({});
  const [fieldsError, setFieldsError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const validateField = (value) => /[a-zA-Z0-9]/.test(value);
  const validateZipCode = (value) => /^[0-9]+$/.test(value);

  useEffect(() => {
    if (submitted && !loading && !error) {
      onClose();
    }
  }, [loading]);

  const setAddressFields = (field, value) => {
    setData({
      ...data,
      [field.id]: value,
    });
  };

  const isAllFieldsFilledOut = (
    validateField(data.name)
    && !!data.time_zone
    && validateField(data.address_line)
    && validateZipCode(data.address_zipcode)
    && validateField(data.address_city)
    && !!data.address_state
    && validateField(data.email)
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAllFieldsFilledOut) {
      setFieldsError(false);
      setSubmitted(true);
      const formattedData = Object.keys(data).reduce((acc, key) => {
        return {
          ...acc,
          [key]: data[key] ? formatInput(data[key]) : data[key],
        };
      }, {});
      const dealershipParams = {
        dealership: formattedData,
        dealership_admin: { email: formattedData.email },
      };
      onSubmit(dealershipParams);
    } else {
      setFieldsError(true);
    }
  };

  const renderInput = (field) => (
    <label
      htmlFor={field.id}
      id={field.id}
      key={field.id}
      className={cx(styles.label, {
        [styles.labelHalfWidth]: field.id === "address_zipcode" || field.id === "address_city",
      })}
    >
      {field.label}
      <input
        {...field}
        className={styles.input}
        name={field.id}
        value={data[field.id]}
        onChange={(e) => setAddressFields(field, e.target.value)}
      />
    </label>
  );

  const renderSelect = (field) => {
    const preparedTimezoneOptions = availableTimezones().map((availableTimezone) => {
      return {
        label: availableTimezone,
        value: availableTimezone,
      };
    });

    return (
      <label
        htmlFor={field.id}
        id={field.id}
        key={field.id}
        className={styles.select}
      >
        {field.label}
        <StyledSelect
          value={{
            label: data[field.id],
            value: data[field.id],
          }}
          options={field.id === "address_state"
            ? convertArrayToSelectOptions(US_STATES)
            : preparedTimezoneOptions}
          isOptionDisabled={(option) => option.selectable === false}
          onChange={(e) => setAddressFields(field, e.value)}
        />
      </label>
    );
  };

  return (
    <Modal
      isForm
      title="New dealership"
      cancelButtonText="Cancel"
      submitButtonText="Create"
      size="small"
      onCancel={onClose}
      onSubmit={handleSubmit}
      loading={loading}
    >
      <div className={styles.container}>
        {loading && (
          <span className={styles.loader}>
            Loading...
          </span>
        )}
        {!loading && dealeshipFields.map((field) => {
          if (field.id === "time_zone" || field.id === "address_state") {
            return renderSelect(field);
          }
          return renderInput(field);
        })}
        {fieldsError && renderDataEditionErrors("All fields must be filled out")}
        {error && renderDataEditionErrors(error)}
      </div>
    </Modal>
  );
};

AddDealershipModal.propTypes = {
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  token: string.isRequired,
  error: objectOf(string),
  loading: bool.isRequired,

};

AddDealershipModal.defaultProps = {
  error: {},
};

const mapStateToProps = (state) => {
  return {
    error: dealershipsAddingErrorSelector(state),
    loading: dealershipsAddingLoadingSelector(state),
  };
};

const actions = {
  onSubmit: addDealership,
};

export default connect(mapStateToProps, actions)(AddDealershipModal);
