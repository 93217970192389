import { connect } from "react-redux";
import {
  resyncDriversSelector,
  settingsDriversLoadingSelector,
  settingsDriversSelector,
  totalDriversSelector,
} from "store/selectors/settings-selectors";
import { addDriver, retrieveDealershipDrivers } from "store/actions/settings-actions";
import EmployeesPanel from "./index";

const mapStateToProps = (state) => {
  return {
    employees: settingsDriversSelector(state),
    total: totalDriversSelector(state),
    needToResyncDriversData: resyncDriversSelector(state),
    isLoading: settingsDriversLoadingSelector(state),
  };
};

const actions = {
  fetchEmployees: retrieveDealershipDrivers,
  addNewEmployee: addDriver,
};

export default connect(mapStateToProps, actions)(EmployeesPanel);
