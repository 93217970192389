import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { isNil } from "ramda";
import Switch from "react-switch";

import arrowDownIcon from "assets/images/down.svg";
import arrowUpIcon from "assets/images/up.svg";
import unlockIcon from "assets/images/unlock.svg";
import blockIcon from "assets/images/block.svg";

import "../styles.scss";
import {
  bool, func, node, number, string,
} from "prop-types";

import { lockDay, unlockDay } from "store/actions/bookings-actions";

const CapacityDashboardItem = ({
  isDropdown,
  isSelected,
  allowBlock,
  locked,
  day,
  teamTagId,
  name,
  avatarSrc,
  capacityLabel,
  capacityProgress,
  onToggleClick,
  teamLockDay,
  teamUnlockDay,
}) => {
  const [block, setBlock] = useState(locked);
  const [disableToggle, setDisableToggle] = useState(false);

  useEffect(() => {
    setBlock(locked);
  }, [locked]);

  const getUnlockIcon = () => (
    <img
      className=""
      style={{
        width: "18px",
        height: "18px",
        display: "block",
      }}
      src={unlockIcon}
      alt=""
    />
  );

  const getBlockIcon = () => (
    <img
      className=""
      style={{
        width: "14px",
        height: "14px",
        display: "block",
        margin: "2px",
      }}
      src={blockIcon}
      alt=""
    />
  );

  return (
    <button
      type="button"
      className={cx("capacityDashboardRowItem", {
        capacityDashboardRowItemPale: isDropdown && isSelected,
        capacityDashboardRowItemSelected: !isDropdown && isSelected,
      })}
      onClick={() => {
        if (disableToggle) {
          setDisableToggle(false);
        } else {
          onToggleClick();
        }
      }}
    >
      <div className="capacityDashboardRowItemTop">
        <div className="capacityDashboardRowItemContainer">
          {avatarSrc && (
            <img className="capacityDashboardRowItemAvatar" src={avatarSrc} alt="" />
          )}
          <div
            className={cx({
              capacityDashboardRowItemTitle: !avatarSrc,
              "capacityDashboardRowItemTitle--padding-left": avatarSrc,
            })}
            title={name}
          >
            {name}
          </div>
        </div>
        {isDropdown && (
          <div
            className="capacityDashboardRowItemToggle"
          >
            <img src={isSelected ? arrowUpIcon : arrowDownIcon} alt="toggle" />
          </div>
        )}
        {capacityLabel && (
          <div className="capacityDashboardRowItemCounter">{capacityLabel}</div>
        )}
      </div>
      <div className="capacityDashboardProgress">
        {!isNil(capacityProgress) && (
          <div className={cx("capacityDashboardProgressBar", {
            capacityDashboardProgressBarBlocked: block,
          })}
          >
            <div
              className="capacityDashboardProgressBarValue"
              style={{ width: `${capacityProgress}%` }}
            />
          </div>
        )}
        {allowBlock && (
          <Switch
            id="block-activity-switch"
            className="blockActivitySwitch"
            onChange={(value, event) => {
              event.stopPropagation();
              setBlock(!block);
              setDisableToggle(true);

              if (value) {
                teamLockDay(teamTagId, day);
              } else {
                teamUnlockDay(teamTagId, day);
              }
            }}
            checked={block}
            height={20}
            width={38}
            handleDiameter={18}
            uncheckedIcon={false}
            checkedIcon={false}
            uncheckedHandleIcon={getUnlockIcon()}
            checkedHandleIcon={getBlockIcon()}
            onColor="#FE3E7F"
            offColor="#dedee0"
            activeBoxShadow="0 0 2px 3px #FE3E7F"
            aria-labelledby="block-activity-label"
          />
        )}
      </div>
    </button>
  );
};

CapacityDashboardItem.propTypes = {
  isDropdown: bool,
  isSelected: bool,
  allowBlock: bool,
  name: string.isRequired,
  avatarSrc: node,
  capacityLabel: node,
  capacityProgress: number,
  onToggleClick: func.isRequired,
  locked: bool.isRequired,
  day: string,
  teamTagId: number.isRequired,
  teamLockDay: func.isRequired,
  teamUnlockDay: func.isRequired,
};

CapacityDashboardItem.defaultProps = {
  isDropdown: false,
  isSelected: false,
  allowBlock: false,
  avatarSrc: null,
  capacityLabel: null,
  capacityProgress: 0,
  day: null,
};

const actions = {
  teamLockDay: lockDay,
  teamUnlockDay: unlockDay,
};

export default connect(null, actions)(CapacityDashboardItem);
