import React, { useState } from "react";
import {
  bool, func, number, oneOfType, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import Switch from "react-switch";
import cx from "classnames";
import { uniq, uniqBy } from "ramda";
import Button from "components/common/Button";
import {
  getVehicleSets,
  removeVehicleGroup,
  saveVehicleGroup,
  setVehicleGroupsClipboard,
} from "store/actions/menu-template-actions";
import { menuItemTargetPropType } from "shared/prop-types";
import { servicesVehicleGroupsClipboardSelector } from "store/selectors/menu-template-selectors";
import { dealershipMakeModelYearMapSelector } from "store/selectors/app-selectors";
import { convertArrayToSelectOptions } from "shared/utils/common";

import "./styles.scss";

const ALL_MODELS = "All Models";

const VehicleGroup = ({
  vehicleGroup,
  forDefault,
  clipboardContent,
  makeModelYearMap,
  menuItemKind,
  menuItemId,
  menuItemIsMobile,
  hasPackageItems,
  fetchVehicleSets,
  copyVehicleGroup,
  saveVehicleGroupAction,
  removeVehicleGroupAction,
}) => {
  const [vehicleGroupData, setVehicleGroupData] = useState(vehicleGroup);
  const [isEditing, setIsEditing] = useState(!vehicleGroup.id);
  const [makeFilter, setMakeFilter] = useState(null);
  const [modelFilter, setModelFilter] = useState(null);
  const [fromYearFilter, setFromYearFilter] = useState(null);
  const [toYearFilter, setToYearFilter] = useState(null);
  const [errors, setErrors] = useState({});
  const [copied, setCopied] = useState(false);
  const [isLoadingSets, setIsLoadingSets] = useState(false);

  const resetState = () => {
    setVehicleGroupData(vehicleGroup);
    setIsEditing(!vehicleGroup.id);
    setMakeFilter(null);
    setModelFilter(null);
    setFromYearFilter(null);
    setToYearFilter(null);
    setErrors({});
    setCopied(false);
    setIsLoadingSets(false);
  };

  const makeOptions = () => convertArrayToSelectOptions(Object.keys(makeModelYearMap));

  const modelOptions = (make) => (
    make
      ? convertArrayToSelectOptions([ALL_MODELS, ...Object.keys(makeModelYearMap[make] || {})])
      : []
  );

  const yearOptions = (make, model) => {
    if (!make && !model) {
      return [];
    }
    if (!model || model === ALL_MODELS) {
      const allYears = Object.keys(makeModelYearMap[make]).reduce(
        (yearList, modelItem) => [
          ...yearList,
          ...makeModelYearMap[make][modelItem],
        ],
        [],
      );
      return convertArrayToSelectOptions(uniq(allYears).sort());
    }
    return convertArrayToSelectOptions(makeModelYearMap[make][model]);
  };

  const updateVehicleGroupState = (values) => setVehicleGroupData(
    (prevVehicleGroupData) => {
      return {
        ...prevVehicleGroupData,
        ...values,
      };
    },
  );

  const quickAdd = async () => {
    if (!makeFilter || !modelFilter) {
      return;
    }

    setIsLoadingSets(true);
    try {
      const vehicleSets = await fetchVehicleSets({
        make: makeFilter.value,
        model: modelFilter.value === ALL_MODELS ? null : modelFilter.value,
        model_year_from: fromYearFilter && fromYearFilter.value,
        model_year_to: (toYearFilter && toYearFilter.value)
          || (fromYearFilter && fromYearFilter.value),
        with_id: true,
      });

      updateVehicleGroupState({
        vehicle_sets: uniqBy(({ id }) => id, [
          ...(vehicleGroupData.vehicle_sets || []),
          ...(vehicleSets || []),
        ]),
      });
      setIsLoadingSets(false);
    } catch (error) {
      setIsLoadingSets(false);
    }
  };

  const handleVehicleSelect = (values) => {
    updateVehicleGroupState({ vehicle_sets: values.map(({ vehicleSet }) => vehicleSet) });
  };

  const edit = () => {
    setIsEditing(true);
    setCopied(false);
  };

  const validate = (item) => {
    const result = {
      laborPriceInvalid: item.labor_price < 0 || item.labor_price === "",
      partsPriceInvalid: item.parts_price < 0 || item.parts_price === "",
      savingInvalid: item.saving < 0 || item.saving === "",
      mileageRangeFromInvalid: !forDefault && (
        Number.isNaN(item.mileage_range[0])
        || item.mileage_range[0] === null
        || item.mileage_range[0] < 0
      ),
      mileageRangeToInvalid: !forDefault && (
        !item.mileage_range[1]
        || item.mileage_range[1] <= 0
        || item.mileage_range[1] < item.mileage_range[0]
      ),
      allocatedLaborTimeError: (
        item.allocated_labor_time < 0
        || item.allocated_labor_time === ""
      ),
      actualLaborTimeError: (
        item.actual_labor_time < 0
        || item.actual_labor_time === ""
      ),
      mobileActualLaborTimeError: item.mobile_actual_labor_time < 0,
    };
    setErrors(result);

    return !Object.values(result).find((v) => v);
  };

  const errorMessage = (message) => (
    <span className="vehicleGroupErrorLabel">
      {message === "value" ? "Invalid value" : "Can't be empty"}
    </span>
  );

  const save = () => {
    if (validate(vehicleGroupData)) {
      saveVehicleGroupAction(menuItemId, {
        ...vehicleGroupData,
      });
      setIsEditing(false);
    }
  };

  const handleMileageFromChange = (event) => updateVehicleGroupState({
    mileage_range: [
      parseInt(event.target.value, 10),
      vehicleGroupData.mileage_range[1],
    ],
  });

  const handleMileageToChange = (event) => updateVehicleGroupState({
    mileage_range: [
      vehicleGroupData.mileage_range[0],
      parseInt(event.target.value, 10),
    ],
  });

  const handlePriceChange = (field, value) => updateVehicleGroupState({
    [field]: value !== "" ? Math.round(value * 100) / 100 : "",
  });

  const handleExcludeChange = (checked) => updateVehicleGroupState({
    exclude_cars: checked,
  });

  const mapForSelect = (vs) => {
    return {
      vehicleSet: vs,
      value: vs.id,
      label: `${vs.make} - ${vs.model} - ${vs.model_year}`,
    };
  };

  const copy = () => {
    setCopied(true);
    copyVehicleGroup({
      ...vehicleGroupData,
      id: undefined,
    });
  };

  const paste = () => setVehicleGroupData({
    ...clipboardContent,
    id: vehicleGroup.id,
  });

  const quickAddFields = [
    {
      label: "Make",
      value: makeFilter,
      options: makeOptions(),
      onChange: (value) => setMakeFilter(value),
    },
    {
      label: "Model",
      value: modelFilter,
      options: modelOptions((makeFilter || {}).value),
      onChange: (value) => {
        setModelFilter(value);
        setFromYearFilter(null);
        setToYearFilter(null);
      },
    },
    {
      label: "Year from",
      value: fromYearFilter,
      options: yearOptions((makeFilter || {}).value, (modelFilter || {}).value),
      onChange: (value) => setFromYearFilter(value),
    },
    {
      label: "Year to",
      value: toYearFilter || fromYearFilter,
      options: yearOptions((makeFilter || {}).value, (modelFilter || {}).value),
      onChange: (value) => setToYearFilter(value),
    },
  ];

  const basicInputFields = [
    {
      type: "price",
      id: "labor_price",
      label: "Labor price (in USD)",
      value: vehicleGroupData.labor_price,
      error: errors.laborPriceInvalid,
    },
    {
      type: "price",
      id: "parts_price",
      label: "Parts price (in USD)",
      value: vehicleGroupData.parts_price,
      error: errors.partsPriceInvalid,
    },
    {
      type: "price",
      id: "total",
      label: "Total (in USD)",
      value: (
        Number(vehicleGroupData.labor_price || 0) + Number(vehicleGroupData.parts_price || 0)
      ).toFixed(2),
    },
    {
      type: "discount",
      id: "saving",
      label: "Saving (in USD)",
      value: vehicleGroupData.saving,
      error: errors.savingInvalid,
    },
    {
      type: "info",
      id: "mileage_range_from",
      label: "Mileage (in miles) from:",
      value: vehicleGroupData.mileage_range[0],
      error: errors.mileageRangeFromInvalid,
      onChange: handleMileageFromChange,
    },
    {
      type: "info",
      id: "mileage_range_to",
      label: "Mileage (in miles) to:",
      value: vehicleGroupData.mileage_range[1],
      error: errors.mileageRangeToInvalid,
      onChange: handleMileageToChange,
    },
    {
      type: "info",
      id: "allocated_labor_time",
      label: "Allocated time",
      value: vehicleGroupData.allocated_labor_time,
      error: errors.allocatedLaborTimeError,
      onChange: ({ target: { value } }) => (
        +value >= 0 && updateVehicleGroupState({ allocated_labor_time: value })
      ),
    },
    {
      type: "info",
      id: "actual_labor_time",
      label: "Flag time",
      value: vehicleGroupData.actual_labor_time,
      error: errors.actualLaborTimeError,
      onChange: ({ target: { value } }) => (
        +value >= 0 && updateVehicleGroupState({ actual_labor_time: value })
      ),
    },
  ];
  const mobileTechnicianInputFields = [
    {
      type: "info",
      id: "mobile_actual_labor_time",
      label: "Mobile technician flag time",
      value: vehicleGroupData.mobile_actual_labor_time,
      error: errors.mobileActualLaborTimeError,
      onChange: ({ target: { value } }) => (
        +value >= 0 && updateVehicleGroupState({ mobile_actual_labor_time: value })
      ),
    },
  ];
  const inputFields = menuItemIsMobile
    ? basicInputFields.concat(mobileTechnicianInputFields)
    : basicInputFields;

  const renderSelect = (field) => (
    <Select
      value={field.value}
      options={field.options}
      onChange={field.onChange}
      isDisabled={!isEditing || isLoadingSets}
      className="vehicleGroupInputDropdown"
      classNamePrefix="vehicleGroupInputDropdown"
      placeholder={cx(`${field.label}`)}
    />
  );

  const renderInputs = (field) => (
    <div className="vehicleGroupInputContainer">
      <span className="vehicleGroupInputLabel">
        {field.label}
      </span>
      <input
        type="number"
        className={cx("vehicleGroupInputTextInput", { error: field.error })}
        disabled={!isEditing || forDefault || field.id === "total"}
        value={field.value}
        onChange={
          field.type === "info"
            ? field.onChange
            : (e) => handlePriceChange(cx(`${field.id}`), e.target.value)
        }
        min={0}
      />
      <div className="vehicleGroupError">
        {field.error && errorMessage("value")}
      </div>
    </div>
  );

  const groupedVehicleSets = () => {
    const result = [];
    const groupedItems = {};

    vehicleGroupData.vehicle_sets.forEach((vehicleSet) => {
      if (!(`${vehicleSet.make} - ${vehicleSet.model}:` in groupedItems)) {
        groupedItems[`${vehicleSet.make} - ${vehicleSet.model}:`] = [];
      }

      groupedItems[`${vehicleSet.make} - ${vehicleSet.model}:`].push(vehicleSet.model_year);
    });

    Object.keys(groupedItems).forEach((key) => {
      const groupedItem = groupedItems[key].sort();
      const groupedYears = [];

      let pointer = 0;

      for (let i = 0; i < groupedItem.length; i++) {
        if (groupedItem[pointer] !== groupedItem[i] - i + pointer) {
          if (pointer === i || pointer === i - 1) {
            groupedYears.push(`${groupedItem[pointer]}`);
          } else {
            groupedYears.push(`${groupedItem[pointer]} - ${groupedItem[i - 1]}`);
          }

          pointer = i;
        }
      }

      if (pointer === groupedItem.length - 1) {
        groupedYears.push(`${groupedItem[pointer]}`);
      } else {
        groupedYears.push(`${groupedItem[pointer]} - ${groupedItem[groupedItem.length - 1]}`);
      }

      result.push({
        key,
        value: groupedYears.join(", "),
      });
    });

    return result;
  };

  return (
    <div className="vehicleGroup">
      <div className="vehicleGroupExcludeCarsSwitchContainer">
        <label
          className="vehicleGroupInputLabel"
          htmlFor="exclude-cars-switch"
        >
          Exclude cars
        </label>
        <Switch
          id="exclude-cars-switch"
          className="vehicleGroupExcludeCarsSwitch"
          onChange={handleExcludeChange}
          disabled={!isEditing || isLoadingSets}
          checked={vehicleGroupData.exclude_cars}
          onColor="#36af5e"
          offColor="#dedee0"
          activeBoxShadow="0 0 2px 3px #0bcaf9"
          aria-labelledby="exclude-cars-label"
        />
      </div>
      <div className="vehicleGroupInputLabel vehicleGroupQuickAddLabel">
        Quick Add
      </div>
      <div className="vehicleGroupQuickAddContainer">
        <div className="vehicleGroupQuickAddFilters">
          {quickAddFields.map((field) => renderSelect(field))}
          <Button
            className="vehicleGroupButton"
            variant="brand"
            onClick={quickAdd}
            disabled={!isEditing || isLoadingSets}
          >
            Add
          </Button>
        </div>
      </div>
      <div className="vehicleGroupInputContainer vehicleGroupCars">
        <span className="vehicleGroupInputLabel">
          Cars
        </span>
        {isEditing && (
          <Select
            isMulti
            value={vehicleGroupData.vehicle_sets.map(mapForSelect)}
            isSearchable={false}
            isDisabled={!isEditing || isLoadingSets}
            onChange={handleVehicleSelect}
            className={cx("vehicleGroupInputDropdown", "menuHidden", { error: errors.vehicleSetsInvalid })}
            classNamePrefix="vehicleGroupInputDropdown"
          />
        )}
        {!isEditing && (
          <div className="vehicleGroupCarsContainer">
            {groupedVehicleSets().map((item) => (
              <div className="vehicleGroupCarsContainerItem">
                <p>
                  {item.key}
                </p>
                <p>
                  {item.value}
                </p>
              </div>
            ))}
          </div>
        )}
        <div className="vehicleGroupError">
          {errors.vehicleSetsInvalid && errorMessage("empty")}
        </div>
      </div>
      <div className="vehicleGroupInputWrapper">
        {inputFields
          .filter((price) => price.type === "info")
          .map((field) => renderInputs(field))}
      </div>
      <div className="vehicleGroupInputWrapper">
        {inputFields
          .filter((price) => price.type === "price")
          .map((field) => renderInputs(field))}
        {hasPackageItems && (
          inputFields
            .filter((price) => price.type === "discount")
            .map((field) => renderInputs(field))
        )}
      </div>

      {isEditing ? (
        <div className="vehicleGroupActionButtons">
          <div className="vehicleGroupActionButtons">
            <Button
              className="vehicleGroupActionButton"
              disabled={!vehicleGroupData.id}
              onClick={() => (
                removeVehicleGroupAction(menuItemKind, menuItemId, vehicleGroupData.id)
              )}
            >
              Remove
            </Button>
            <Button
              className="vehicleGroupActionButton"
              disabled={copied}
              onClick={copy}
            >
              <p>{copied ? "Copied" : "Copy"}</p>
            </Button>
            {clipboardContent && (
              <Button
                className="vehicleGroupActionButton"
                onClick={paste}
              >
                Paste
              </Button>
            )}
          </div>
          <div className="vehicleGroupActionButtonsRightAlignment">
            <Button
              variant="dark-outline"
              onClick={resetState}
            >
              Cancel
            </Button>
            <Button
              variant="brand"
              onClick={save}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className="vehicleGroupActionButtonsRightAlignment">
          <Button
            variant="dark"
            icon="editWhite"
            onClick={edit}
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

VehicleGroup.propTypes = {
  menuItemId: number.isRequired,
  menuItemKind: string.isRequired,
  menuItemIsMobile: bool.isRequired,
  forDefault: bool.isRequired,
  vehicleGroup: menuItemTargetPropType.isRequired,
  fetchVehicleSets: func.isRequired,
  saveVehicleGroupAction: func.isRequired,
  removeVehicleGroupAction: func.isRequired,
  copyVehicleGroup: func.isRequired,
  clipboardContent: menuItemTargetPropType,
  hasPackageItems: bool.isRequired,
  makeModelYearMap: oneOfType(shape({ make: func }), func).isRequired,
};

VehicleGroup.defaultProps = {
  clipboardContent: null,
};

const mapStateToProps = (state) => {
  return {
    makeModelYearMap: dealershipMakeModelYearMapSelector(state),
    clipboardContent: servicesVehicleGroupsClipboardSelector(state),
  };
};

const actions = {
  copyVehicleGroup: setVehicleGroupsClipboard,
  fetchVehicleSets: getVehicleSets,
  saveVehicleGroupAction: saveVehicleGroup,
  removeVehicleGroupAction: removeVehicleGroup,
};

const VehicleGroupContainer = connect(mapStateToProps, actions)(VehicleGroup);

export default VehicleGroupContainer;
