import React from "react";
import {
  arrayOf, func, number, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import uniqid from "uniqid";
import cx from "classnames";
import InlineSVG from "react-inlinesvg";

import { availableServicesPropType, servicesPropType } from "shared/prop-types";
import { chatAvailableServicesSelector } from "store/selectors/checkin-chat-selectors";
import { makeFloat } from "shared/utils";

import Button from "components/common/checkin/Button";
import Header from "components/common/checkin/Header";
import PackageItems from "components/common/checkin/PackageItems";
import selectedIcon from "assets/icons/services/service-checked.svg";
import unselectedIcon from "assets/icons/services/service-plus.svg";

import styles from "./styles.module.scss";

const MAINTENANCE_TAB = "MAINTENANCE";

const MAINTENANCE_HEADER = "Maintenance";
const CONCERN_HEADER = "Concern";

const ServiceSelector = ({
  addServices,
  closeModal,
  disabledPackageItems,
  availableServices,
  selectedMaintenance,
  selectedConcern,
  toggleMaintenance,
  toggleConcern,
  serviceKind,
}) => {
  const isDisabled = (service) => disabledPackageItems.map(
    (item) => item.id,
  ).includes(service.id);

  const renderSelector = (header, services, selectedServices, toggleService) => (
    <>
      <Header title={header} onClose={() => closeModal()} />
      <div className={styles.servicesContainer}>
        {services.map((service) => {
          const {
            package_items, name, extras, fee, saving, premium_extras,
          } = service;
          const isSelected = !!selectedServices.find(({ id }) => id === service.id);

          return (
            <div
              key={uniqid()}
              className={cx(styles.service, {
                [styles.highlighted]: isSelected,
                [styles.disabled]: isDisabled(service),
              })}
              disabled={isDisabled(service)}
            >
              <button
                type="button"
                onClick={toggleService(service)}
                className={styles.serviceBody}
              >
                <span className={styles.serviceName}>
                  {name}
                </span>
                {isSelected && (
                  <div className={styles.selectedIcon}>
                    <InlineSVG src={selectedIcon} />
                  </div>
                )}
                {!isSelected && (
                  <div className={styles.unselectedIcon}>
                    <InlineSVG src={unselectedIcon} />
                  </div>
                )}
                <div className={styles.servicePriceWrap}>
                  <div className={styles.servicePriceTag}>
                    $
                    {makeFloat(fee || 0)}
                  </div>
                  {package_items.length > 0 && saving > 0 && (
                    <div className={styles.serviceSaveTag}>
                      Includes package savings of $
                      {makeFloat(saving)}
                    </div>
                  )}
                </div>
              </button>
              {package_items.length > 0 && (
                <PackageItems
                  items={package_items}
                  extras={extras}
                  premiumExtras={premium_extras}
                  isSelected={isSelected}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.chatInput}>
        <Button
          caption={selectedServices.length === 0 ? "Back" : "Save"}
          onClick={() => addServices()}
          isWide
        />
      </div>
    </>
  );

  const renderServices = () => {
    const { maintenance, concern } = availableServices;

    return serviceKind === MAINTENANCE_TAB
      ? renderSelector(MAINTENANCE_HEADER, maintenance, selectedMaintenance, toggleMaintenance)
      : renderSelector(CONCERN_HEADER, concern, selectedConcern, toggleConcern);
  };

  return renderServices();
};

ServiceSelector.propTypes = {
  availableServices: availableServicesPropType.isRequired,
  selectedMaintenance: servicesPropType.isRequired,
  selectedConcern: servicesPropType.isRequired,
  toggleMaintenance: func.isRequired,
  toggleConcern: func.isRequired,
  addServices: func.isRequired,
  closeModal: func.isRequired,
  serviceKind: string,
  disabledPackageItems: arrayOf(shape({
    id: number,
    name: string,
  })),
};

ServiceSelector.defaultProps = {
  disabledPackageItems: [],
  serviceKind: "",
};

const mapStateToProps = (state) => {
  return {
    availableServices: chatAvailableServicesSelector(state),
  };
};

export default connect(mapStateToProps)(ServiceSelector);
