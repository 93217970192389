import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, number, shape,
} from "prop-types";
import ReactTable from "react-table-6";
import { format } from "date-fns";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { AMERICAN_DATE_FORMAT } from "shared/utils/datetime";
import {
  bookingDetailsRepairOrdersErrorSelector,
  bookingDetailsRepairOrdersLoadingSelector,
  bookingDetailsRepairOrdersSelector,
} from "store/selectors/booking-details-selectors";
import { retrieveRepairOrders } from "store/actions/booking-details-actions";

import Modal from "components/common/Modal";
import HistoryDetails from "./HistoryDetails";
import { renderDataEditionErrors } from "../DataEditionModal/helpers";

import styles from "./styles.module.scss";

const columns = [
  {
    Header: "Date",
    accessor: "date",
    width: 100,
  },
  {
    Header: "Mileage",
    accessor: "mileage",
    width: 120,
  },
  {
    Header: "RO number",
    accessor: "repair_order_number",
    width: 130,
  },
  {
    Header: "Advisor",
    accessor: "service_advisor",
    width: 150,
  },
];

const HistoryModal = ({
  onClose,
  getVehicleRepairOrders,
  vehicleId,
  history,
  isLoading,
  error,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);

  useEffect(() => {
    getVehicleRepairOrders(vehicleId);
  }, []);

  const resolvedData = (data) => data.map(
    (vehicleData) => {
      const {
        appointment_datetime,
        service_advisor,
        mileage,
        promised_datetime,
      } = vehicleData;

      const formattedDate = () => {
        if (appointment_datetime) {
          return format(appointment_datetime, AMERICAN_DATE_FORMAT);
        }
        if (promised_datetime) {
          return format(promised_datetime, AMERICAN_DATE_FORMAT);
        }
        return "—";
      };

      return {
        ...vehicleData,
        date: formattedDate(),
        service_advisor: service_advisor?.name || "—",
        mileage: mileage || "—",
      };
    },
  );

  return (
    <Modal
      title="Vechicle history"
      size="small"
      hideFooter
      onCancel={onClose}
    >
      {isLoading && (
        <section className={styles.loading}>
          <ClipLoader size={40} color="#0bcaf9" />
        </section>
      )}
      {!isLoading && !error && (
        <div className={styles.table}>
          <ReactTable
            data={history}
            resolveData={resolvedData}
            columns={columns}
            showPagination={false}
            minRows={1}
            noDataText="No history found"
            getTrProps={(state, rowInfo) => {
              return {
                onClick: () => {
                  if (rowInfo.original) {
                    setShowInfo(true);
                    setCurrentBooking(rowInfo.original);
                  }
                },
                style: {
                  background: rowInfo && rowInfo.original === currentBooking && showInfo ? "#DEDEE0" : "",
                },
              };
            }}
          />
        </div>
      )}
      {showInfo && (
        <HistoryDetails
          history={currentBooking}
          onClose={() => setShowInfo(false)}
        />
      )}
      {error && renderDataEditionErrors(error)}
    </Modal>
  );
};

HistoryModal.propTypes = {
  onClose: func.isRequired,
  getVehicleRepairOrders: func.isRequired,
  history: arrayOf(shape).isRequired,
  error: shape.isRequired,
  isLoading: bool,
  vehicleId: number.isRequired,
};

HistoryModal.defaultProps = {
  isLoading: true,
};

const mapStateToProps = (state) => {
  return {
    history: bookingDetailsRepairOrdersSelector(state),
    isLoading: bookingDetailsRepairOrdersLoadingSelector(state),
    error: bookingDetailsRepairOrdersErrorSelector(state),
  };
};

const actions = {
  getVehicleRepairOrders: retrieveRepairOrders,
};

export default connect(mapStateToProps, actions)(HistoryModal);
