import React from "react";
import cx from "classnames";
import {
  bool,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types";
import { OverlayView } from "@react-google-maps/api";
import serviceButtonIcons from "shared/serviceButtonIcons";

import "./styles.scss";

const Marker = ({ job, size, dealershipPosition }) => {
  const icon = () => {
    if (job.aasm_state === "assigned") {
      return serviceButtonIcons.driverAssigned;
    } if (job.aasm_state === "en_route_to_customer") {
      return serviceButtonIcons.departure;
    } if (job.aasm_state === "arrived_to_customer") {
      return serviceButtonIcons.position;
    } if (job.aasm_state === "en_route_to_dealership") {
      return serviceButtonIcons.arrive;
    }
  };

  const mainIcon = () => {
    if (!job.technician && job?.main_driver?.photo.url) {
      return (
        <img
          className="mapMarkerImg"
          src={process.env.NODE_ENV === "development"
            ? `${process.env.REACT_APP_ROOT_URL}${job.main_driver.photo.url}`
            : job.main_driver.photo.url}
          alt=""
        />
      );
    }

    if (!job.main_driver && job?.technician?.photo.url) {
      return (
        <img
          className="mapMarkerImg"
          src={process.env.NODE_ENV === "development"
            ? `${process.env.REACT_APP_ROOT_URL}${job.technician.photo.url}`
            : job.technician.photo.url}
          alt=""
        />
      );
    }

    return <div className="mapMarkerImg" />;
  };

  return (
    <OverlayView
      position={job.aasm_state !== "arrived_to_dealership" ? job.current_geolocation : dealershipPosition}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div className={
        cx("mapMarker", {
          driverAssigned: job.aasm_state === "assigned" || job.aasm_state === "arrived_to_dealership",
          enRouteToCustomer: job.aasm_state === "en_route_to_customer",
          arrivedToCustomer: job.aasm_state === "arrived_to_customer",
          enRouteToDealership: job.aasm_state === "en_route_to_dealership",
        })
      }
      >
        <div className="mapMarkerBorder">
          {size > 1 && (
            <div className="mapMarkerSize">
              <p>{size}</p>
            </div>
          )}
          {size === 1 && (
            <>
              {mainIcon()}
              {job.co_driver && (
                <div className="mapMarkerTopSection">
                  <img
                    src={(process.env.NODE_ENV === "development"
                      ? `${process.env.REACT_APP_ROOT_URL}${job.co_driver.photo.url}`
                      : job.co_driver.photo.url)}
                    alt=""
                  />
                </div>
              )}
              <div className="mapMarkerBottomSection">
                <img
                  src={icon()}
                  alt=""
                />
              </div>
            </>
          )}
        </div>
      </div>
    </OverlayView>
  );
};

Marker.propTypes = {
  job: objectOf(oneOfType([number, bool, string])).isRequired,
  size: number.isRequired,
  dealershipPosition: {
    lat: number,
    lng: number,
  }.isRequired,
};

export default Marker;
