export const servicesSelector = (state) => state.menuTemplate.services;
export const vehicleSetsSelector = (state) => state.menuTemplate.vehicleSets;
export const servicesErrorSelector = (state) => state.menuTemplate.error;
export const opCodesSelector = (state) => state.menuTemplate.opCodes;
export const servicesExtrasClipboardSelector = (state) => state.menuTemplate.extrasClipboard;
export const servicesPremiumExtrasClipboardSelector = (state) => (
  state.menuTemplate.premiumExtrasClipboard
);
export const servicesVehicleGroupsClipboardSelector = (state) => (
  state.menuTemplate.vehicleGroupsClipboard
);
export const servicesTemplateSelector = (state) => state.menuTemplate.template;
export const importedOpCodesSelector = (state) => state.menuTemplate.imported;
