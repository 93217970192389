import React from "react";
import { func, node, objectOf } from "prop-types";
import { totalCostForBooking } from "shared/utils/common";
import { format } from "date-fns";
import { AMERICAN_DATE_FORMAT } from "shared/utils/datetime";

import Button from "components/common/Button";
import Panel from "components/common/Panel";
import SelectedServices from "components/pages/BookingDetailsPage/BookingDetailsSummary/SelectedServices";

import styles from "./styles.module.scss";

const HistoryDetails = ({
  history,
  history: {
    appointment_datetime,
    bookings_menu_items,
    service_advisor,
    internal_note,
    repair_order_number,
    mileage,
    appointment,
    source,
    promised_datetime,
  },
  onClose,
}) => {
  const formattedDate = () => {
    if (appointment_datetime) {
      return format(appointment_datetime, AMERICAN_DATE_FORMAT);
    }
    if (promised_datetime) {
      return format(promised_datetime, AMERICAN_DATE_FORMAT);
    }
    return "—";
  };
  return (
    <div className={styles.panel}>
      <Panel>
        <div className={styles.infoRow}>
          <div>
            <div className={styles.itemContainer}>
              <span className={styles.itemLabel}>RO number: </span>
              <span>{repair_order_number || "—"}</span>
            </div>
            <div className={styles.itemContainer}>
              <span className={styles.itemLabel}>Mileage: </span>
              <span>{mileage}</span>
            </div>
          </div>
          <div>
            <div className={styles.itemContainer}>
              <span className={styles.itemLabel}>Appointment date: </span>
              <span>{formattedDate()}</span>
            </div>
            <div className={styles.itemContainer}>
              <span className={styles.itemLabel}>Appointment number: </span>
              <span>{appointment?.appointment_number || "—"}</span>
            </div>
          </div>
          <Button
            leftIcon="close"
            padding="small"
            onClick={() => onClose()}
            className={styles.button}
          />
        </div>
        <div className={styles.info}>
          <div className={styles.itemContainer}>
            <span className={styles.itemLabel}>Service advisor: </span>
            <span>{service_advisor}</span>
          </div>
          <div className={styles.itemContainer}>
            <span className={styles.itemLabel}>Source: </span>
            <span>{source || "—"}</span>
          </div>
          {internal_note && (
            <div className={styles.itemContainer}>
              <span className={styles.itemLabel}>Note: </span>
              <span>{internal_note}</span>
            </div>
          )}
        </div>
        <SelectedServices
          booking={history}
          showAdditionalInfo
        />
        <div className={styles.total}>
          Total
        </div>
        <div className={styles.totalValue}>
          {bookings_menu_items && `$${totalCostForBooking(bookings_menu_items)}`}
        </div>
      </Panel>
    </div>
  );
};

HistoryDetails.propTypes = {
  history: objectOf(node),
  onClose: func.isRequired,
};

HistoryDetails.defaultProps = {
  history: null,
};

export default HistoryDetails;
