import React, { useState } from "react";
import { PropTypes, func } from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";

import { formatInput } from "shared/utils/common";
import { updateLocalBooking } from "store/actions/booking-details-actions";
import { internalNoteSelector } from "store/selectors/booking-details-selectors";

import Panel from "components/common/Panel";
import TextArea from "components/common/TextArea";
import Button from "components/common/Button";

import styles from "./styles.module.scss";

const InternalNote = ({ booking, updateInternalNote }) => {
  const { internal_note } = booking;
  const [isNoteEditing, setIsNoteEditing] = useState(false);
  const [internalNote, setInternalNote] = useState(internal_note);

  const saveNote = () => {
    updateInternalNote({ payload: { internal_note: formatInput(internalNote) } });
    setIsNoteEditing(false);
  };

  const handleChange = (value) => {
    setInternalNote(value);
  };

  return (
    <Panel className={styles.container}>
      <div className={cx(styles.section, { [styles.sectionEditing]: isNoteEditing })}>
        {
          isNoteEditing ? (
            <>
              <TextArea
                type="string"
                value={internalNote}
                onChange={(value) => handleChange(value)}
              />
              <span
                className={styles.saveButton}
                onClick={() => saveNote()}
              >
                Save
              </span>
            </>
          ) : (
            <>
              <div>
                <span className={styles.blockText}>
                  {internalNote || "Appointment note..."}
                </span>
              </div>
              <Button
                withoutBorder
                variant="base-grey"
                leftIcon="editGrey"
                onClick={() => setIsNoteEditing(true)}
                className={styles.editButton}
              >
                Edit
              </Button>
            </>
          )
        }
      </div>
    </Panel>
  );
};

InternalNote.propTypes = {
  booking: PropTypes.shape({
    internal_note: PropTypes.string.isRequired,
  }).isRequired,
  updateInternalNote: func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    internal_note: internalNoteSelector(state),
  };
};

const actions = {
  updateInternalNote: updateLocalBooking,
};

export default connect(mapStateToProps, actions)(InternalNote);
