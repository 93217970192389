import { uniqBy } from "ramda";
import { convertToTimeZone } from "date-fns-timezone";
import {
  fetchBookings, fetchDealershipCapacity, teamTagDayLock, teamTagDayUnlock,
} from "shared/api";
import {
  bookingsAdditionalInfoFiltersSelector,
  bookingsIntervalTimerIdSelector,
  bookingsPeriodFiltersSelector,
  bookingsRequestIdSelector,
} from "store/selectors/bookings-selectors";
import { SET_BOOKINGS_TABLE_OPTIONS } from "store/reducers/table-options-reducer";
import { settingsTimezoneSelector } from "store/selectors/settings-selectors";
import { authTokenSelector, authUserAdvisorIdSelector } from "store/selectors/auth-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import {
  THREE_MIN_IN_MILISECONDS,
  convertFilterTime,
} from "shared/utils/datetime";
import { safeDecimalAdd } from "shared/utils/index";

export const BOOKINGS_SET_LOADING_STATE = "BOOKINGS_SET_LOADING_STATE";
export const BOOKINGS_SET_TIMELINE_FILTER = "BOOKINGS_SET_TIMELINE_FILTER";
export const BOOKINGS_RESET_TIMELINE_FILTERS = "BOOKINGS_RESET_TIMELINE_FILTERS";
export const BOOKINGS_SET_PERIOD_FILTER = "BOOKINGS_SET_PERIOD_FILTER";
export const BOOKINGS_SET_PERIOD_AND_TIMELINE_FILTERS = "BOOKINGS_SET_PERIOD_AND_TIMELINE_FILTERS";
export const BOOKINGS_SET_SEARCH_STRING = "BOOKINGS_SET_SEARCH_STRING";
export const BOOKINGS_FETCH_SUCCESS = "BOOKINGS_FETCH_SUCCESS";
export const BOOKINGS_FETCH_FAILURE = "BOOKINGS_FETCH_FAILURE";
export const BOOKINGS_SET_ADDITIONAL_INFO_FILTER = "BOOKINGS_SET_ADDITIONAL_INFO_FILTER";

export const BOOKINGS_SET_FETCH_INTERVAL = "BOOKINGS_SET_FETCH_INTERVAL";
export const BOOKINGS_CLEAR_FETCH_INTERVAL = "BOOKINGS_CLEAR_FETCH_INTERVAL";

export const BOOKINGS_FETCH_DEALERSHIP_CAPACITY = "BOOKINGS_FETCH_DEALERSHIP_CAPACITY";
export const BOOKINGS_FETCH_DEALERSHIP_CAPACITY_SUCCESS = "BOOKINGS_FETCH_DEALERSHIP_CAPACITY_SUCCESS";
export const BOOKINGS_FETCH_DEALERSHIP_CAPACITY_FAILURE = "BOOKINGS_FETCH_DEALERSHIP_CAPACITY_FAILURE";
export const BOOKINGS_FETCH_TEAM_TAGS_CAPACITY_SUCCESS = "BOOKINGS_FETCH_TEAM_TAGS_CAPACITY_SUCCESS";

export const BOOKINGS_LOCK_DAY_SUCCESS = "BOOKINGS_LOCK_DAY_SUCCESS";
export const BOOKINGS_LOCK_DAY_FAILURE = "BOOKINGS_LOCK_DAY_FAILURE";
export const BOOKINGS_UNLOCK_DAY_SUCCESS = "BOOKINGS_UNLOCK_DAY_SUCCESS";
export const BOOKINGS_UNLOCK_DAY__FAILURE = "BOOKINGS_UNLOCK_DAY__FAILURE";

const setLoadingState = (requestId) => {
  return {
    type: BOOKINGS_SET_LOADING_STATE,
    payload: {},
    meta: { requestId },
  };
};

export const setAdditionalInfoFilter = ({
  itemKey, dropdownKey,
}) => (dispatch, getState) => {
  const additionalInfoFilters = bookingsAdditionalInfoFiltersSelector(getState());

  const payload = dropdownKey ? {
    [dropdownKey]: {
      ...additionalInfoFilters[dropdownKey],
      [itemKey]: !additionalInfoFilters[dropdownKey][itemKey] || null,
    },
  } : { [itemKey]: !additionalInfoFilters[itemKey] || null };

  dispatch({
    type: BOOKINGS_SET_ADDITIONAL_INFO_FILTER,
    payload,
  });
};

export const setAdditionalInfoFilterMultipleItems = ({
  itemsKeys, itemsValue, dropdownKey,
}) => (dispatch, getState) => {
  const userAdvisorId = authUserAdvisorIdSelector(getState());
  const additionalInfoFilters = bookingsAdditionalInfoFiltersSelector(getState());

  if (!dropdownKey) {
    return;
  }

  const items = itemsKeys.reduce((obj, key) => {
    return {
      ...obj,
      [key]: key === Number(userAdvisorId) ? true : itemsValue,
    };
  }, {});

  dispatch({
    type: BOOKINGS_SET_ADDITIONAL_INFO_FILTER,
    payload: {
      [dropdownKey]: {
        ...items,
        ...additionalInfoFilters?.service_advisor,
      },
    },
  });
};

export const setAdditionalInfoFilterAllDropdownItems = ({
  dropdownKey, itemsValue,
}) => (dispatch, getState) => {
  const additionalInfoFilters = bookingsAdditionalInfoFiltersSelector(getState());
  const newDropdown = Object.keys(additionalInfoFilters[dropdownKey]).reduce((acc, key) => {
    return {
      ...acc,
      [key]: itemsValue,
    };
  }, {});

  dispatch({
    type: BOOKINGS_SET_ADDITIONAL_INFO_FILTER,
    payload: { [dropdownKey]: newDropdown },
  });
};

export const setPeriodFilter = (key, value) => (dispatch) => {
  dispatch({
    type: BOOKINGS_SET_PERIOD_FILTER,
    payload: { [key]: value },
  });
};

export const setPeriodAndTimelineFilters = (value) => (dispatch) => {
  dispatch({
    type: BOOKINGS_SET_PERIOD_AND_TIMELINE_FILTERS,
    payload: value,
  });
};

export const setTimelineFilter = (key) => (dispatch, getState) => {
  const timezone = settingsTimezoneSelector(getState());

  if (!timezone) {
    return;
  }

  switch (key) {
    case "today":
    case "tomorrow": {
      const day = convertToTimeZone(new Date(), { timeZone: timezone }).getDate()
        + (key === "tomorrow" ? 1 : 0);
      const dayFrom = new Date();
      const dayTo = new Date();
      dayFrom.setDate(day);
      dayFrom.setHours(0, 0, 0, 0);
      dayTo.setDate(day);
      dayTo.setHours(23, 59, 59, 59);

      const from = convertFilterTime(dayFrom, timezone);
      const to = convertFilterTime(dayTo, timezone);

      dispatch(setPeriodAndTimelineFilters(
        {
          from,
          to,
          rawTo: from,
          timelineFilters: { [key]: true },
        },
      ));

      break;
    }
    case "week": {
      const curr = convertToTimeZone(new Date(), { timeZone: timezone });
      const first = curr.getDate() - curr.getDay();
      const firstDay = new Date(curr.setDate(first));
      const lastDay = new Date(curr.setDate(firstDay.getDate() + 6));
      firstDay.setHours(0, 0, 0);
      lastDay.setHours(23, 59, 59);
      const from = convertFilterTime(firstDay, timezone);
      const to = convertFilterTime(lastDay, timezone);
      const rawLastDay = new Date(lastDay);
      rawLastDay.setHours(0, 0, 0);

      dispatch(setPeriodAndTimelineFilters(
        {
          from,
          to,
          rawTo: convertFilterTime(rawLastDay, timezone),
          timelineFilters: { [key]: true },
        },
      ));

      break;
    }
    case "all": {
      const firstDay = new Date();
      const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
      firstDay.setHours(0, 0, 0);
      lastDay.setHours(23, 59, 59);
      const from = convertFilterTime(firstDay, timezone);
      const to = convertFilterTime(lastDay, timezone);
      const rawLastDay = new Date(lastDay);
      rawLastDay.setHours(0, 0, 0);
      const rawTo = convertFilterTime(rawLastDay, timezone);

      dispatch(setPeriodAndTimelineFilters(
        {
          from,
          to,
          rawTo,
          timelineFilters: { [key]: true },
        },
      ));

      break;
    }
    default: {
      dispatch({
        type: BOOKINGS_SET_TIMELINE_FILTER,
        payload: {
          [key]: true,
        },
      });
    }
  }
};

export const resetTimelineFilters = () => (dispatch) => {
  dispatch({
    type: BOOKINGS_RESET_TIMELINE_FILTERS,
    payload: {},
  });
};

export const setSearchString = (searchString) => (dispatch) => {
  dispatch({
    type: BOOKINGS_SET_SEARCH_STRING,
    payload: searchString,
  });
};

export const clearPeriodicallyFetchInterval = () => (dispatch, getState) => {
  const intervalTimerId = bookingsIntervalTimerIdSelector(getState());

  if (intervalTimerId) {
    clearInterval(intervalTimerId);
    dispatch({ type: BOOKINGS_CLEAR_FETCH_INTERVAL });
  }
};

export const retrieveBookings = (params) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  dispatch(clearPeriodicallyFetchInterval());

  const periodicallyFetch = async () => {
    const requestId = bookingsRequestIdSelector(getState()) + 1;
    dispatch(setLoadingState(requestId));
    try {
      const response = await fetchBookings(dealershipId, params, token);
      dispatch({
        type: SET_BOOKINGS_TABLE_OPTIONS,
        payload: Object.assign(response.headers, params),
      });
      dispatch({
        type: BOOKINGS_FETCH_SUCCESS,
        payload: response.data,
        meta: { requestId },
      });
    } catch (error) {
      dispatch(clearPeriodicallyFetchInterval());
      dispatch({
        type: BOOKINGS_FETCH_FAILURE,
        payload: { error },
      });
    }
  };

  const intervalId = setInterval(periodicallyFetch, THREE_MIN_IN_MILISECONDS);

  dispatch({
    type: BOOKINGS_SET_FETCH_INTERVAL,
    payload: intervalId,
  });

  periodicallyFetch();
};

export const retrieveDealershipTeamsCapacity = (dealershipId) => async (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const id = dealershipId || dealershipIdSelector(getState());
  const { from, rawTo } = bookingsPeriodFiltersSelector(getState());
  if (!from || !rawTo) {
    return;
  }
  dispatch({ type: BOOKINGS_FETCH_DEALERSHIP_CAPACITY });
  try {
    const capacities = await fetchDealershipCapacity(id, {
      start_date: from.toISOString().split("T")[0],
      end_date: rawTo.toISOString().split("T")[0],
      dealership_id: id,
    }, token);

    const allDealershipHoursCapacity = capacities.reduce((obj, item) => {
      return {
        allocated_time: safeDecimalAdd(obj.allocated_time, item.allocated_time),
        max_actual_labor_time: safeDecimalAdd(
          obj.max_actual_labor_time,
          item.max_actual_labor_time,
        ),
        max_over_capacity_time: safeDecimalAdd(
          obj.max_over_capacity_time,
          item.max_over_capacity_time,
        ),
        dealership_appointments_amount: (
          obj.dealership_appointments_amount
          + item.dealership_appointments_amount
        ),
      };
    }, {
      allocated_time: 0,
      max_actual_labor_time: 0,
      max_over_capacity_time: 0,
      dealership_appointments_amount: 0,
    });

    const allServiceAdvisorsCapacities = uniqBy(
      ({ service_advisor_id }) => service_advisor_id,
      capacities.reduce((arr, item) => ([
        ...arr,
        ...(item.service_advisors_capacities || []),
      ]), []),
    );

    const allDealershipAppointmentsCapacity = {
      allocated_appointments: allDealershipHoursCapacity.dealership_appointments_amount,
      max_advisor_appointments_per_day: allServiceAdvisorsCapacities
        .reduce((total, item) => total + item.max_advisor_appointments_per_day, 0),
    };

    dispatch({
      type: BOOKINGS_FETCH_DEALERSHIP_CAPACITY_SUCCESS,
      payload: {
        dealershipHoursCapacity: allDealershipHoursCapacity,
        dealershipAppointmentsCapacity: allDealershipAppointmentsCapacity,
        advisors: allServiceAdvisorsCapacities,
      },
    });
    dispatch({
      type: BOOKINGS_FETCH_TEAM_TAGS_CAPACITY_SUCCESS,
      payload: capacities,
    });
  } catch (error) {
    dispatch({
      type: BOOKINGS_FETCH_DEALERSHIP_CAPACITY_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const lockDay = (tagId, date) => async (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  await teamTagDayLock(dealershipId, tagId, {
    date,
    dealership_id: dealershipId,
    team_tag_id: tagId,
  }, token)
    .then((response) => (
      dispatch({
        type: BOOKINGS_LOCK_DAY_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: BOOKINGS_LOCK_DAY_FAILURE,
      payload: { error },
    }));
};

export const unlockDay = (tagId, date) => async (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  await teamTagDayUnlock(dealershipId, tagId, {
    date,
    dealership_id: dealershipId,
    team_tag_id: tagId,
  }, token)
    .then((response) => (
      dispatch({
        type: BOOKINGS_UNLOCK_DAY_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: BOOKINGS_UNLOCK_DAY__FAILURE,
      payload: { error },
    }));
};
