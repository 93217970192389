import React from "react";
import { shape, string } from "prop-types";

import Panel from "components/common/Panel";

import styles from "./styles.module.scss";

const AlternativeTransportationPanel = ({ booking: { transport } }) => {
  const statusLabels = {
    loaner: "Loaner",
    lyft: "Lyft",
    uber: "Uber",
    shuttle: "Shuttle",
  };

  return (
    <Panel
      className={styles.panel}
      isToggle={false}
    >
      <div className={styles.section}>
        <span>
          Alternative transportation
        </span>
        <span className={styles.selectedOption}>
          {statusLabels[transport.kind]}
        </span>
      </div>
    </Panel>
  );
};

AlternativeTransportationPanel.propTypes = {
  booking: shape({ transport: string }),
};

AlternativeTransportationPanel.defaultProps = {
  booking: null,
};

export default AlternativeTransportationPanel;
