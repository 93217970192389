import React, { useCallback, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  arrayOf, bool, func, objectOf, shape,
} from "prop-types";
import { connect } from "react-redux";

import { setAdditionalInfoFilter, setAdditionalInfoFilterAllDropdownItems } from "store/actions/bookings-actions";
import { bookingsAdditionalInfoFiltersSelector } from "store/selectors/bookings-selectors";
import { activeAdvisorsSelector } from "store/selectors/settings-selectors";

import ServiceAdvisorIcon from "assets/images/bookings/service_advisor.svg";
import AdditionalInfoFilterItem from "./AdditionalInfoFilterItem";
import AdditionalInfoFilterDropdown from "./AdditionalInfoFilterDropdown";

import { filtersConfig, toFilterOption } from "./utils";

import styles from "./styles.module.scss";

const filterLabels = {
  client_waiting: "Customer waiting",
  with_concern: "Has concern",
  appraisal_requested: "Appraisal",
  with_recall: "Has recall",
};

const AdditionalInfoFilter = ({
  activeAdvisors,
  additionalInfoFilters,
  changeAdditionalInfoFilter,
  toggleAllDropdownItems,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleOnClick = useCallback(({ itemKey, dropdownKey }) => {
    changeAdditionalInfoFilter({
      itemKey,
      dropdownKey,
    });
  }, []);

  const toggleAllItems = useCallback(({ dropdownKey, itemsValue }) => {
    toggleAllDropdownItems({
      dropdownKey,
      itemsValue,
    });
  }, []);

  const advisorOptions = activeAdvisors.map(
    (item) => toFilterOption(item, item.photo?.url || <ServiceAdvisorIcon key={item.id} />),
  );

  const tooltip = (filterKey) => (<p>{filterLabels[filterKey]}</p>);

  return (
    <div className={styles.wrapper}>
      <ul className={styles.filter}>
        {filtersConfig.map(({
          key, options, icon,
        }) => {
          if (options || key === "service_advisor") {
            return (
              <div key={key}>
                <AdditionalInfoFilterDropdown
                  dropdownKey={key}
                  label={key}
                  dropdownConfig={
                    key === "service_advisor"
                      ? advisorOptions
                      : options
                  }
                  filters={additionalInfoFilters[key]}
                  dropdownIcon={icon?.type}
                  handleOnClick={handleOnClick}
                  toggleAllItems={toggleAllItems}
                />
              </div>
            );
          }
          return (
            <div
              key={key}
              data-for="additional-info-filter-tooltip"
              data-tip={key}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <AdditionalInfoFilterItem
                assets={icon.type}
                label={key}
                isActive={additionalInfoFilters[key]}
                handleOnClick={() => handleOnClick({ itemKey: key })}
              />
            </div>
          );
        })}
      </ul>
      {showTooltip && (
        <ReactTooltip
          id="additional-info-filter-tooltip"
          className={styles.label}
          place="bottom"
          backgroundColor="#F9FAFC"
          textColor="black"
          border
          borderColor="#dedee0"
          globalEventOff="click"
          getContent={tooltip}
        />
      )}
    </div>
  );
};

AdditionalInfoFilter.propTypes = {
  additionalInfoFilters: shape({
    jobStatus: objectOf(bool),
    bookingStatus: objectOf(bool),
    with_concern: bool,
    client_waiting: bool,
    appraisal_requested: bool,
    with_recall: bool,
  }),
  changeAdditionalInfoFilter: func,
  toggleAllDropdownItems: func,
  activeAdvisors: arrayOf(shape),
};

AdditionalInfoFilter.defaultProps = {
  additionalInfoFilters: null,
  changeAdditionalInfoFilter: () => {},
  toggleAllDropdownItems: () => {},
  activeAdvisors: [],
};

const mapStateToProps = (state) => {
  return {
    additionalInfoFilters: bookingsAdditionalInfoFiltersSelector(state),
    activeAdvisors: activeAdvisorsSelector(state),
  };
};

const actions = {
  changeAdditionalInfoFilter: setAdditionalInfoFilter,
  toggleAllDropdownItems: setAdditionalInfoFilterAllDropdownItems,
};

export default connect(mapStateToProps, actions)(AdditionalInfoFilter);
