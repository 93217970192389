import React from "react";
import {
  arrayOf, number, shape, string,
} from "prop-types";

import {
  estimatedServiceTimeForBooking,
  estimatedTotalServiceTimeForBooking,
  totalCostForBooking,
} from "shared/utils/common";

import styles from "../styles.module.scss";

const EstimatedServiceTimeAndTotalCost = ({
  booking: {
    appointment_expected_upsell_time,
    appointment_expected_buffer_time,
    bookings_menu_items,
    service_advisor,
    historical_service_advisor_name,
  },
}) => {
  const showBreakdown = () => (
    (appointment_expected_upsell_time && appointment_expected_upsell_time !== "0.0")
    || (appointment_expected_buffer_time && appointment_expected_buffer_time !== "0.0")
  );

  return (
    <>
      <div className={styles.sectionTitle}>
        Total
      </div>
      <div className={styles.sectionContent}>
        {bookings_menu_items && `$${totalCostForBooking(bookings_menu_items)}`}
      </div>
      <div className={styles.itemContainer}>
        <div className={styles.item}>
          <span className={styles.itemLabel}>Estimated service time: </span>
          <span className={styles.itemValue}>
            {`${estimatedTotalServiceTimeForBooking(
              bookings_menu_items,
              appointment_expected_upsell_time,
              appointment_expected_buffer_time,
            )} hours`}
          </span>
        </div>
        {showBreakdown() && (
          <div>
            <span className={styles.itemDetail}>
              {`${estimatedServiceTimeForBooking(bookings_menu_items)} hours estimated time`}
            </span>
          </div>
        )}
        {appointment_expected_upsell_time && appointment_expected_upsell_time !== "0.0" && (
          <div>
            <span className={styles.itemDetail}>
              {`${appointment_expected_upsell_time} hours expected upsell time`}
            </span>
          </div>
        )}
        {appointment_expected_buffer_time && appointment_expected_buffer_time !== "0.0" && (
          <div>
            <span className={styles.itemDetail}>
              {`${appointment_expected_buffer_time} hours estimated buffer time`}
            </span>
          </div>
        )}
        { (service_advisor || historical_service_advisor_name)
          && (
            <div className={styles.item}>
              <span className={styles.itemLabel}>Service advisor: </span>
              <span className={styles.itemValue}>
                {service_advisor?.name || historical_service_advisor_name}
              </span>
            </div>
          )}
      </div>
    </>
  );
};

EstimatedServiceTimeAndTotalCost.propTypes = {
  booking: shape({
    menu_items: arrayOf(number).isRequired,
    service_advisor: shape({ name: string }).isRequired,
  }).isRequired,
};

export default EstimatedServiceTimeAndTotalCost;
