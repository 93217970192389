import React from "react";
import {
  bool, func,
} from "prop-types";
import Switch from "react-switch";

import styles from "./styles.module.scss";

const ActivitySwitch = ({
  active,
  isEditing,
  onChange,
}) => (
  <div className={styles.activity}>
    <label
      className={styles.label}
      htmlFor="activity-switch"
    >
      Is Active
    </label>
    <Switch
      id="activity-switch"
      className={styles.switch}
      onChange={onChange}
      checked={active}
      disabled={!isEditing}
      onColor="#36af5e"
      offColor="#dedee0"
      activeBoxShadow="0 0 2px 3px #0bcaf9"
      aria-labelledby="activity-label"
    />
  </div>

);

ActivitySwitch.propTypes = {
  active: bool,
  isEditing: bool,
  onChange: func.isRequired,
};

ActivitySwitch.defaultProps = {
  active: false,
  isEditing: false,
};

export default ActivitySwitch;
