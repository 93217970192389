import React from "react";
import {
  arrayOf, shape, string,
} from "prop-types";

import Block from "components/common/Block";
import RemotePanelVideosItem from "./RemotePanelVideosItem";

import "./styles.scss";

const RemotePanelVideos = ({ videos }) => (
  <Block className="conciergeBookingDetailsPanel conciergeBookingDetailsPanelVideos">
    {videos && videos.length
      ? videos.map((item) => (
        <RemotePanelVideosItem
          item={item}
          key={item.videoURL}
        />
      ))
      : <div className="RemotePanelVideosItem">No available videos</div>}
  </Block>
);

RemotePanelVideos.propTypes = {
  videos: arrayOf(shape({
    videoURL: string,
    date: string,
  })),
};

RemotePanelVideos.defaultProps = {
  videos: [],
};

export default RemotePanelVideos;
