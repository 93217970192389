export const remoteServiceIsLoading = (state) => state.remoteService.isLoading;
export const remoteServiceJobsSelector = (state) => state.remoteService.jobs;
export const remoteServiceDriversSelector = (state) => state.remoteService.activeDrivers;
export const remoteServiceTotalDriversSelector = (state) => (
  state.remoteService.activeDriversTotal
);
export const remoteServiceMobileTechniciansSelector = (state) => (
  state.remoteService.activeMobileTechnicians
);
export const remoteServiceTotalMobileTechniciansSelector = (state) => (
  state.remoteService.activeMobileTechniciansTotal
);
export const remoteServiceErrorSelector = (state) => state.remoteService.error;
