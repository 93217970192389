/* eslint-disable import/no-import-module-exports */
import { AppContainer } from "react-hot-loader";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store";
import "core-js";
import "normalize.css";
import "styles/reset.scss";

import App from "components/App";
import { unregister } from "./registerServiceWorker";

function render(Component) {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <Router>
          <Component />
        </Router>
      </Provider>
    </AppContainer>,
    document.getElementById("root"),
  );
}

render(App);

if (module.hot) {
  module.hot.accept("./components/App", () => {
    // eslint-disable-next-line global-require
    const nextApp = require("./components/App").default;
    render(nextApp);
  });
}

unregister();
