import React, { useEffect } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import {
  arrayOf, bool, func, objectOf, shape, string,
} from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { isEmpty } from "ramda";

import Accordion from "components/common/Accordion/SchedulingAccordion";
import {
  schedulingChosenDropOffTimeSlotSelector,
  schedulingChosenTimeSlotSelector,
  schedulingCurrentCustomerSelector,
  schedulingCurrentVehicleSelector,
  schedulingIsDropOffSelector,
  schedulingIsPickUpSelector,
  schedulingProcessContactNumberSelector,
  schedulingProcessJobReachable,
  schedulingProcessSendSmsSelector,
  schedulingSelectedAdvisorSelector,
  schedulingSelectedRecallsSelector,
  schedulingSelectedServicesSelector,
  schedulingisMobileTechnicianSelector,
} from "store/selectors/scheduling-process-selectors";

import {
  settingsDataSelector,
  settingsDealershipMobileTechnicianEnabledSelector,
  settingsDealershipRemoteJobsEnabledSelector,
  settingsDealershipShowCoDriver,
} from "store/selectors/settings-selectors";

import { bookAppointment, setCurrentStep } from "store/actions/scheduling-process-actions";

import {
  countSelectedServicesByType,
  prepareCustomerPickupAddress,
  prepareCustomerPickupAddressData,
} from "shared/utils/common";
import {
  chosenTimeSlotPropType,
  serviceAdvisorPropType,
  servicePropType,
  vehiclePropType,
} from "shared/prop-types";
import {
  BOOKING_STEP_ADVISOR,
  BOOKING_STEP_DROP_OFF_DRIVER_CO_DRIVER,
  BOOKING_STEP_DROP_OFF_TIMESLOT,
  BOOKING_STEP_INTERNAL_NOTE,
  BOOKING_STEP_MOBILE_TECHNICIAN,
  BOOKING_STEP_PICK_UP_DRIVER_CO_DRIVER,
  BOOKING_STEP_REMOTE_SERVICE,
  BOOKING_STEP_SERVICES,
  BOOKING_STEP_TIMESLOT,
  BOOKING_STEP_TRANSPORT,
} from "shared/constants";
import { formatTimeInfoUS } from "shared/utils/datetime";

import AppointmentDetails from "components/common/AppointmentDetails/AppointmentDetailsScheduling";
import ChooseServices from "components/common/BookingSteps/ChooseServices/ChooseServicesScheduling";
import TimeOfArrival from "components/common/BookingSteps/TimeOfArrival/TimeOfArrivalScheduling";
import ChooseAdvisor from "components/common/BookingSteps/ChooseAdvisor/ChooseAdvisorScheduling";
import Transportation from "components/common/BookingSteps/Transportation/TransportationScheduling";
import PageHeader from "components/common/PageHeader";
import ChooseRemoteService from "components/common/Remote/ChooseRemoteService/ChooseRemoteServiceScheduling";
import ChooseDrivers from "components/common/Remote/ChooseDrivers/ChooseDriversScheduling";
import ChooseMobileTechnician from "components/common/Remote/ChooseMobileTechnician/ChooseMobileTechnicianScheduling";
import Button from "components/common/Button";
import LastAdvisor from "../BookingDetailsPage/BookingDetailsSummary/LastAdvisor";
import InternalNote from "./InternalNote";

import styles from "./styles.module.scss";

const PageTitle = () => <h2>Scheduling</h2>;

const SchedulingProcessPage = ({
  vehicle: { vehicle_set },
  selectedServices,
  selectedRecalls,
  chosenTimeSlot,
  chosenDropOffTimeSlot,
  selectedAdvisor,
  isPickUp,
  isDropOff,
  isMobileTechnician,
  history,
  currentCustomer,
  jobReachable,
  remoteJobsEnabled,
  mobileTechnicianEnabled,
  showCoDriver,
  bookNewAppointment,
  dealershipSettings,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dynamicSection = () => {
    const isLoanerActive = () => dealershipSettings.integrations?.find((s) => s.kind === "loaner")?.active;
    if (isPickUp) {
      return (
        <>
          <Accordion
            title={`Pick-up Driver ${showCoDriver ? "/ Co-Driver" : ""}`}
            customClass={styles.accordion}
            step={BOOKING_STEP_PICK_UP_DRIVER_CO_DRIVER}
          >
            <ChooseDrivers
              selectedAdvisor={selectedAdvisor}
              chosenTimeSlot={chosenTimeSlot}
              nextStep={isDropOff ? BOOKING_STEP_DROP_OFF_TIMESLOT : BOOKING_STEP_TRANSPORT}
            />
          </Accordion>
          {isDropOff && (
            <>
              <Accordion
                title="Drop-off Time"
                customClass={styles.accordion}
                selectedValue={
                  !isEmpty(chosenDropOffTimeSlot)
                  && formatTimeInfoUS(chosenDropOffTimeSlot)
                }
                step={BOOKING_STEP_DROP_OFF_TIMESLOT}
              >
                <TimeOfArrival
                  selectedAdvisor={selectedAdvisor}
                  nextStep={BOOKING_STEP_DROP_OFF_DRIVER_CO_DRIVER}
                  isScheduling
                  dropOffType
                />
              </Accordion>
              <Accordion
                title={`Drop-off Driver ${showCoDriver ? "/ Co-Driver" : ""}`}
                customClass={styles.accordion}
                step={BOOKING_STEP_DROP_OFF_DRIVER_CO_DRIVER}
              >
                <ChooseDrivers
                  selectedAdvisor={selectedAdvisor}
                  chosenTimeSlot={chosenDropOffTimeSlot}
                  nextStep={isLoanerActive() ? BOOKING_STEP_TRANSPORT : BOOKING_STEP_INTERNAL_NOTE}
                  dropOffType
                />
              </Accordion>
            </>
          )}
          {isLoanerActive() && (
            <Accordion
              title="Transportation"
              customClass={styles.accordion}
              step={BOOKING_STEP_TRANSPORT}
            >
              <Transportation />
            </Accordion>
          )}
        </>
      );
    } if (isMobileTechnician) {
      return (
        <Accordion
          title="Mobile Technician"
          customClass={styles.accordion}
          step={BOOKING_STEP_MOBILE_TECHNICIAN}
        >
          <ChooseMobileTechnician
            selectedAdvisor={selectedAdvisor}
            chosenTimeSlot={chosenTimeSlot}
          />
        </Accordion>
      );
    }

    return (
      <Accordion
        title="Transportation"
        customClass={styles.accordion}
        step={BOOKING_STEP_TRANSPORT}
        expandable
      >
        <Transportation />
      </Accordion>
    );
  };

  const dynamicStep = () => {
    if (isPickUp) {
      return BOOKING_STEP_PICK_UP_DRIVER_CO_DRIVER;
    } if (isMobileTechnician) {
      return BOOKING_STEP_MOBILE_TECHNICIAN;
    }

    return BOOKING_STEP_TRANSPORT;
  };

  const allServicesIsMobile = () => {
    let result = true;

    selectedServices.forEach((service) => {
      if (result) {
        result = service.team_tags
          ? service.team_tags.filter((team) => team.mobile).length > 0
          : false;
      }
    });

    selectedRecalls.forEach((recall) => {
      if (result) {
        result = recall.mobile_technician;
      }
    });

    return result;
  };

  const saveButtonLabel = () => {
    if (isPickUp) {
      if (isDropOff) {
        return "Book a Pick-up & Drop-off";
      }

      return "Book a Pick-up";
    }

    return "Book appointment";
  };

  return (
    <section className={styles.page}>
      <section className={styles.header}>
        <PageHeader title={<PageTitle />} />
      </section>
      {vehicle_set && (
        <section className={styles.container}>
          <section className={styles.choice}>
            <Accordion
              title="Choose services"
              concernCount={countSelectedServicesByType("concern", selectedServices)}
              maintenanceCount={countSelectedServicesByType("maintenance", selectedServices)}
              customClass={styles.accordion}
              step={BOOKING_STEP_SERVICES}
            >
              <ChooseServices
                nextStep={
                  (remoteJobsEnabled || mobileTechnicianEnabled)
                    ? BOOKING_STEP_REMOTE_SERVICE
                    : BOOKING_STEP_ADVISOR
                }
                isScheduler
              />
            </Accordion>
            {(remoteJobsEnabled || mobileTechnicianEnabled) && (
              <Accordion
                title="Customer requests remote service"
                customClass={cx(styles.accordion, {
                  [styles.borderColorRed]: (jobReachable !== null && !jobReachable)
                    || (isMobileTechnician && !allServicesIsMobile()),
                })}
                step={BOOKING_STEP_REMOTE_SERVICE}
              >
                <ChooseRemoteService
                  isPickUp={isPickUp}
                  isMobileTechnician={isMobileTechnician}
                  isDropOff={isDropOff}
                  remoteJobsEnabled={remoteJobsEnabled}
                  mobileTechnicianEnabled={mobileTechnicianEnabled}
                  allServicesIsMobile={allServicesIsMobile()}
                  customerPickupAddress={prepareCustomerPickupAddress(currentCustomer)}
                  customerPickupAddressData={prepareCustomerPickupAddressData(currentCustomer)}
                />
              </Accordion>
            )}
            {!(isPickUp && isMobileTechnician) && (
              <Accordion
                title="Service Advisor"
                step={BOOKING_STEP_ADVISOR}
                selectedValue={selectedAdvisor && selectedAdvisor.name}
                customClass={cx(styles.accordion, {
                  [styles.hide]: isPickUp || isMobileTechnician,
                })}
              >
                <ChooseAdvisor />
              </Accordion>
            )}
            <Accordion
              title={`${isPickUp ? "Pick-up Time" : "Time of Arrival"}`}
              customClass={styles.accordion}
              selectedValue={!isEmpty(chosenTimeSlot) && formatTimeInfoUS(chosenTimeSlot)}
              step={BOOKING_STEP_TIMESLOT}
            >
              <TimeOfArrival
                selectedAdvisor={selectedAdvisor}
                nextStep={dynamicStep()}
                isScheduling
              />
            </Accordion>
            {dynamicSection()}
            <Accordion
              title="Appointment note"
              step={BOOKING_STEP_INTERNAL_NOTE}
              expandable
            >
              <InternalNote />
            </Accordion>
          </section>
          <div>
            <Accordion
              expandable={false}
              title={isPickUp ? "Pick-up details" : "Appointment details"}
              customClass={styles.detailsAccordion}
            >
              <AppointmentDetails
                saveButtonLabel={saveButtonLabel()}
                onSave={() => {
                  bookNewAppointment();
                  history.push("/scheduling/summary");
                }}
              />
            </Accordion>
            <div className={styles.lastAdvisor}>
              {currentCustomer.last_visit_advisor && currentCustomer.last_visited_at && (
                <LastAdvisor customer={currentCustomer} />
              )}
            </div>
          </div>
        </section>
      )}
      {!vehicle_set && (
        <>
          <div className={styles.bar}>
            Please choose vehicle again
          </div>
          <section className={styles.button}>
            <NavLink
              to={{
                pathname: "/scheduling",
              }}
            >
              <Button
                variant="brand"
              >
                Back
              </Button>
            </NavLink>
          </section>
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    vehicle: schedulingCurrentVehicleSelector(state),
    selectedServices: schedulingSelectedServicesSelector(state),
    selectedRecalls: schedulingSelectedRecallsSelector(state),
    chosenTimeSlot: schedulingChosenTimeSlotSelector(state),
    chosenDropOffTimeSlot: schedulingChosenDropOffTimeSlotSelector(state),
    selectedAdvisor: schedulingSelectedAdvisorSelector(state),
    smsConsent: schedulingProcessSendSmsSelector(state),
    contactNumber: schedulingProcessContactNumberSelector(state),
    currentCustomer: schedulingCurrentCustomerSelector(state),
    isPickUp: schedulingIsPickUpSelector(state),
    isDropOff: schedulingIsDropOffSelector(state),
    isMobileTechnician: schedulingisMobileTechnicianSelector(state),
    jobReachable: schedulingProcessJobReachable(state),
    remoteJobsEnabled: settingsDealershipRemoteJobsEnabledSelector(state),
    mobileTechnicianEnabled: settingsDealershipMobileTechnicianEnabledSelector(state),
    showCoDriver: settingsDealershipShowCoDriver(),
    dealershipSettings: settingsDataSelector(state),
  };
};

const actions = {
  setSchedulingStep: setCurrentStep,
  bookNewAppointment: bookAppointment,
};

SchedulingProcessPage.propTypes = {
  vehicle: vehiclePropType,
  selectedServices: arrayOf(servicePropType),
  chosenTimeSlot: chosenTimeSlotPropType,
  chosenDropOffTimeSlot: chosenTimeSlotPropType,
  selectedAdvisor: serviceAdvisorPropType,
  isPickUp: bool.isRequired,
  isDropOff: bool.isRequired,
  isMobileTechnician: bool.isRequired,
  history: arrayOf(string).isRequired,
  selectedRecalls: arrayOf(servicePropType),
  currentCustomer: objectOf(string).isRequired,
  jobReachable: bool,
  remoteJobsEnabled: bool.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  showCoDriver: bool.isRequired,
  bookNewAppointment: func.isRequired,
  dealershipSettings: shape.isRequired,
};

SchedulingProcessPage.defaultProps = {
  vehicle: {},
  selectedServices: [],
  chosenTimeSlot: {},
  chosenDropOffTimeSlot: {},
  selectedAdvisor: {},
  selectedRecalls: [],
  jobReachable: null,
};

const SchedulingProcessPageWithRouter = withRouter(SchedulingProcessPage);

export default connect(mapStateToProps, actions)(SchedulingProcessPageWithRouter);
