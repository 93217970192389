import { connect } from "react-redux";

import {
  settingsTeamServiceAdvisorsSelector,
} from "store/selectors/settings-selectors";
import {
  dealershipIdSelector,
} from "store/selectors/app-selectors";
import {
  clearTimeSlots,
  fetchAdvisors,
  setChosenTimeSlot,
  setCurrentStep,
  setServiceAdvisor,
} from "store/actions/booking-details-actions";
import {
  bookingDetailsInitialAdvisorSelector,
  bookingDetailsSelectedServicesSelector,
  bookingSelectedRecallsSelector,
} from "store/selectors/booking-details-selectors";

import ChooseAdvisor from "./index";

const mapStateToProps = (state) => {
  return {
    selectedRecalls: bookingSelectedRecallsSelector(state),
    selectedServices: bookingDetailsSelectedServicesSelector(state),
    teamServiceAdvisors: settingsTeamServiceAdvisorsSelector(state),
    dealershipId: dealershipIdSelector(state),
    initialAdvisor: bookingDetailsInitialAdvisorSelector(state),
  };
};

const actions = {
  setSchedulingStep: setCurrentStep,
  makeAdvisorSelection: setServiceAdvisor,
  fetchTeamAdvisorsForServices: fetchAdvisors,
  setChosenQuarterSlot: setChosenTimeSlot,
  clearSlots: clearTimeSlots,
};
export default connect(mapStateToProps, actions)(ChooseAdvisor);
