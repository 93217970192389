import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, string,
} from "prop-types";
import {
  BOOKING_STEP_SERVICES,
} from "shared/constants";

import maintenanceIcon from "assets/images/bookings/maintenance.svg";
import concernIcon from "assets/images/bookings/concern.svg";
import otherIcon from "assets/images/bookings/other.svg";
import extensionIcon from "assets/images/bookings/service.svg";
import recallIcon from "assets/images/bookings/recall_icon.svg";
import { countSelectedServicesByType } from "shared/utils/common";
import { dealershipMenuItemsPropType, servicePropType } from "shared/prop-types";

import Panel from "components/common/Panel";
import Button from "components/common/Button";
import ServiceTypeToggler from "./ServiceTypeToggler";
import ServicesToSelect from "./ServicesToSelect";
import RecallsToSelectScheduling from "./RecallsToSelect/RecallsToSelectScheduling";
import RecallsToSelectBooking from "./RecallsToSelect/RecallsToSelectBooking";

import styles from "./styles.module.scss";

const ChooseServices = ({
  dealershipMenuItems: {
    maintenance,
    concern,
    extension,
    other,
  },
  availableRecalls,
  retrieveDealershipMenuItems,
  retrieveDealershipRecalls,
  selectedServices,
  selectedRecalls,
  setSchedulingStep,
  decisionTrees,
  menuItemsLoading,
  menuItemsLoadingError,
  makeServiceSelection,
  makeAdvisorSelection,
  nextStep,
  isScheduler,
  currentStep,
  mobileTechnicianEnabled,
  isPickUp,
  isMobileTechnician,
  decisionTreeDetails,
}) => {
  const [services, setServices] = useState([]);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    retrieveDealershipMenuItems();
    retrieveDealershipRecalls();
  }, []);

  if (menuItemsLoading) {
    return <Panel className={styles.loading}>Loading...</Panel>;
  }

  const disableNextStep = () => {
    if (!selectedServices.length && !selectedRecalls.length) {
      return true;
    }

    let disabled = false;

    selectedServices.forEach((service) => {
      if (
        !disabled
        && service.kind === "concern"
        && !service.decision_tree
        && (!service.additionalInfo || service.additionalInfo.length === 0)
      ) {
        disabled = true;
      }
    });

    return disabled;
  };

  return (
    <section className={styles.chooseServicesContainer}>
      {menuItemsLoadingError && (
        <Panel className={styles.loading}>Please try again.</Panel>
      )}
      {!menuItemsLoadingError && (
        <>
          <div className={styles.chooseServicesTogglers}>
            <ServiceTypeToggler
              icon={maintenanceIcon}
              label="Maintenance"
              onClick={() => {
                setServices(maintenance);
                setActiveTab("maintenance");
              }}
              counter={countSelectedServicesByType("maintenance", selectedServices)}
              isActive={activeTab === "maintenance"}
              isScheduler={isScheduler}
            />
            <ServiceTypeToggler
              icon={concernIcon}
              label="Concern"
              onClick={() => {
                setServices(concern);
                setActiveTab("concern");
              }}
              counter={countSelectedServicesByType("concern", selectedServices)}
              isActive={activeTab === "concern"}
              isScheduler={isScheduler}
            />
            <ServiceTypeToggler
              icon={recallIcon}
              label="Recall"
              onClick={() => {
                setServices(availableRecalls);
                setActiveTab("recall");
              }}
              counter={selectedRecalls.length}
              isActive={activeTab === "recall"}
            />
            {!isScheduler && (
              <>
                <ServiceTypeToggler
                  icon={extensionIcon}
                  label="Extension"
                  onClick={() => {
                    setServices(extension);
                    setActiveTab("extension");
                  }}
                  counter={countSelectedServicesByType("extension", selectedServices)}
                  isActive={activeTab === "extension"}
                />
                <ServiceTypeToggler
                  icon={otherIcon}
                  label="Other"
                  onClick={() => {
                    setServices(other);
                    setActiveTab("other");
                  }}
                  counter={countSelectedServicesByType("other", selectedServices)}
                  isActive={activeTab === "other"}
                />
              </>
            )}
          </div>
          {currentStep === BOOKING_STEP_SERVICES && activeTab !== "recall" && (
            <ServicesToSelect
              servicesToShow={services}
              selectedServices={selectedServices}
              makeServiceSelection={makeServiceSelection}
              decisionTrees={decisionTrees}
              makeAdvisorSelection={makeAdvisorSelection}
              mobileTechnicianEnabled={mobileTechnicianEnabled}
              isPickUp={isPickUp}
              isMobileTechnician={isMobileTechnician}
              decisionTreeDetails={decisionTreeDetails}
            />
          )}
          {currentStep === BOOKING_STEP_SERVICES && activeTab === "recall" && isScheduler && (
            <RecallsToSelectScheduling />
          )}
          {currentStep === BOOKING_STEP_SERVICES && activeTab === "recall" && !isScheduler && (
            <RecallsToSelectBooking />
          )}
          <div className={styles.submit}>
            <Button
              variant="brand"
              disabled={disableNextStep()}
              onClick={() => setSchedulingStep(nextStep)}
            >
              Done
            </Button>
          </div>
        </>
      )}
    </section>
  );
};

ChooseServices.propTypes = {
  dealershipMenuItems: dealershipMenuItemsPropType,
  availableRecalls: arrayOf(servicePropType),
  selectedServices: arrayOf(servicePropType),
  selectedRecalls: arrayOf(servicePropType),
  makeServiceSelection: func.isRequired,
  makeAdvisorSelection: func.isRequired,
  setSchedulingStep: func.isRequired,
  retrieveDealershipMenuItems: func.isRequired,
  retrieveDealershipRecalls: func.isRequired,
  menuItemsLoading: bool.isRequired,
  menuItemsLoadingError: bool.isRequired,
  nextStep: string.isRequired,
  isScheduler: bool,
  decisionTrees: arrayOf(string),
  decisionTreeDetails: arrayOf(string),
  currentStep: string.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  isPickUp: bool.isRequired,
  isMobileTechnician: bool.isRequired,
};

ChooseServices.defaultProps = {
  dealershipMenuItems: {},
  availableRecalls: [],
  selectedServices: [],
  selectedRecalls: [],
  isScheduler: false,
  decisionTrees: [],
  decisionTreeDetails: [],
};

export default ChooseServices;
