import React from "react";
import cx from "classnames";
import {
  arrayOf, bool, func, oneOfType,
} from "prop-types";
import uniqid from "uniqid";
import { imageUrl } from "shared/utils/common";

import { driverPropType, serviceAdvisorPropType } from "shared/prop-types";
import firstAvailableAdvisorIcon from "assets/images/firstAvailableAdvisor.svg";
import firstAvailableAdvisorActiveIcon from "assets/images/firstAvailableAdvisorActive.svg";
import checked from "assets/images/services/service-checked.svg";

import "./styles.scss";

const EmployeeSelectionCarousel = ({
  employees,
  selectedEmployee,
  onClickHandler,
  showFirstAvailable,
}) => (
  <div className="employeeListContainer">
    {showFirstAvailable && (
      <div
        className={cx(
          "employeeCard",
          "employeeCardWithSeparator",
          { employeeCardIsActive: selectedEmployee?.id === "firstAvailable" },
        )}
        onClick={() => onClickHandler(employees[0])}
      >
        <div className="employeeCardImageContainer">
          <img
            src={
              selectedEmployee?.id === "firstAvailable"
                ? firstAvailableAdvisorActiveIcon
                : firstAvailableAdvisorIcon
            }
            alt=""
            className="employeeCardIcon"
          />
          {selectedEmployee?.id !== "firstAvailable" && <span className="employeeCardStatus" />}
          {selectedEmployee?.id === "firstAvailable" && (
            <img
              src={checked}
              alt=""
              className="employeeCardStatusIsChecked"
            />
          )}
        </div>
        <span className="employeeName">First Available</span>
      </div>
    )}
    <div className="employeeListItemsContainer">
      {employees.map((employee) => {
        if (employee.id !== "firstAvailable") {
          return (
            <div
              key={uniqid()}
              className={cx(
                "employeeCard",
                { employeeCardIsActive: selectedEmployee?.id === employee.id },
              )}
              onClick={() => onClickHandler(employee)}
            >
              <div className="employeeCardImageContainer">
                {employee?.photo?.url && (
                  <img
                    src={imageUrl(employee.photo)}
                    alt=""
                    className="employeeCardImage"
                  />
                )}
                {selectedEmployee?.id !== employee.id && <span className="employeeCardStatus" />}
                {selectedEmployee?.id === employee.id && (
                  <img
                    src={checked}
                    alt=""
                    className="employeeCardStatusIsChecked"
                  />
                )}
              </div>
              <span className="employeeName">{employee.name}</span>
            </div>
          );
        }
        return null;
      })}
    </div>
  </div>
);

EmployeeSelectionCarousel.propTypes = {
  employees: arrayOf(oneOfType([serviceAdvisorPropType, driverPropType])),
  selectedEmployee: oneOfType([serviceAdvisorPropType, driverPropType]),
  onClickHandler: func.isRequired,
  showFirstAvailable: bool,
};

EmployeeSelectionCarousel.defaultProps = {
  employees: [],
  selectedEmployee: {},
  showFirstAvailable: false,
};

export default EmployeeSelectionCarousel;
