import { connect } from "react-redux";

import {
  retrieveDealershipDrivers,
  setCurrentStep,
  setRemoteJobData,
} from "store/actions/booking-details-actions";
import {
  bookingDetailsAvailableDropOffJobEmployee,
  bookingDetailsAvailableJobEmployee,
  bookingDetailsDropOffInitialTimeSelector,
  bookingDetailsInitialTimeSelector,
  bookingDriversSelector,
  bookingServiceLocationSelector,
} from "store/selectors/booking-details-selectors";
import { settingsDealershipShowCoDriver } from "store/selectors/settings-selectors";

import ChooseDrivers from "./index";

const mapStateToProps = (state) => {
  return {
    drivers: bookingDriversSelector(state),
    serviceLocation: bookingServiceLocationSelector(state),
    initialTime: bookingDetailsInitialTimeSelector(state),
    dropOffInitialTime: bookingDetailsDropOffInitialTimeSelector(state),
    availableJobEmployee: bookingDetailsAvailableJobEmployee(state),
    availableDropOffJobEmployee: bookingDetailsAvailableDropOffJobEmployee(state),
    showCoDriver: settingsDealershipShowCoDriver(),
  };
};

const actions = {
  retrieveDrivers: retrieveDealershipDrivers,
  storeRemoteJobData: setRemoteJobData,
  setSchedulingStep: setCurrentStep,
};

export default connect(mapStateToProps, actions)(ChooseDrivers);
