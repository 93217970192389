import { settingsTeamServiceAdvisorsSelector } from "store/selectors/settings-selectors";

export const schedulingCurrentVehicleSelector = (state) => state.schedulingProcess.currentVehicle;
export const schedulingCurrentCustomerSelector = (state) => state.schedulingProcess.currentCustomer;
export const schedulingDealershipMenuItemsSelector = (state) => (
  state.schedulingProcess.dealershipMenuItems
);

export const schedulingIsPickUpSelector = (state) => state.schedulingProcess.isPickUp;
export const schedulingIsDropOffSelector = (state) => (
  state.schedulingProcess.isDropOff
);
export const schedulingisMobileTechnicianSelector = (state) => (
  state.schedulingProcess.isMobileTechnician
);
export const schedulingSelectedServicesSelector = (state) => {
  const selectedServiceIds = state.schedulingProcess.selectedServices.map((service) => service.id);
  const menuItems = schedulingDealershipMenuItemsSelector(state);
  const isMobileTechnician = schedulingisMobileTechnicianSelector(state);
  const isPickUp = schedulingIsPickUpSelector(state);
  const menuItemsRO = [];

  Object.values(menuItems).forEach((group) => {
    group.forEach((menuItem) => {
      if (selectedServiceIds.indexOf(menuItem.id) === -1) {
        if (
          (isMobileTechnician && menuItem.default_mobile_repair_order_item)
          || (isPickUp && menuItem.default_pickup_repair_order_item)
          || (!isMobileTechnician && !isPickUp && menuItem.default_inhouse_repair_order_item)
        ) {
          menuItemsRO.push(menuItem);
        }
      }
    });
  });

  return [...state.schedulingProcess.selectedServices, ...menuItemsRO];
};
export const schedulingInternalNoteSelector = (state) => (
  state.schedulingProcess.internalNote
);
export const schedulingChosenTimeSlotSelector = (state) => (
  state.schedulingProcess.chosenTimeSlot
);
export const schedulingChosenDropOffTimeSlotSelector = (state) => (
  state.schedulingProcess.chosenDropOffTimeSlot
);

export const schedulingClientWaitingSelector = (state) => state.schedulingProcess.clientWaiting;
export const schedulingSelectedTransportSelector = (state) => (
  state.schedulingProcess.selectedTransport
);
export const schedulingCurrentStepSelector = (state) => state.schedulingProcess.currentStep;
export const schedulingMenuItemsLoadingSelector = (state) => (
  state.schedulingProcess.menuItemsLoading
);
export const schedulingMenuItemsLoadingErrorSelector = (state) => (
  state.schedulingProcess.menuItemsLoadingError
);
export const schedulingRecallsLoadingSelector = (state) => (
  state.schedulingProcess.recallsLoading
);
export const schedulingAvailableRecallsSelector = (state) => (
  state.schedulingProcess.availableRecalls || []
);
export const schedulingSelectedRecallsSelector = (state) => (
  state.schedulingProcess.selectedRecalls || []
);
export const schedulingRecallsLoadingErrorSelector = (state) => (
  state.schedulingProcess.recallsLoadingError
);
export const schedulingTimeSlotsLoadingSelector = (state) => (
  state.schedulingProcess.timeSlotsLoading
);
export const schedulingTimeSlotsLoadingErrorSelector = (state) => (
  state.schedulingProcess.timeSlotsLoadingError
);
export const schedulingSaveAppointmentLoadingSelector = (state) => (
  state.schedulingProcess.saveAppointmentLoading
);
export const schedulingSaveAppointmentLoadingErrorSelector = (state) => (
  state.schedulingProcess.saveAppointmentLoadingError
);
export const schedulingProcessSelector = (state) => state.schedulingProcess;
export const schedulingSelectedAdvisorSelector = (state) => state.schedulingProcess.selectedAdvisor;
export const schedulingProcessSendSmsSelector = (state) => state.schedulingProcess.sendSms;
export const schedulingProcessContactNumberSelector = (state) => (
  state.schedulingProcess.contactNumber
);

export const schedulingAvailableTransportSelector = (state) => (
  state.schedulingProcess.availableTransport
);

export const schedulingAvailableTransportLoadingSelector = (state) => (
  state.schedulingProcess.availableTransportLoading
);
export const schedulingAvailableTransportErrorSelector = (state) => (
  state.schedulingProcess.availableTransportError
);

export const teamServiceAdvisorsSelector = (state) => state.schedulingProcess.teamServiceAdvisors;

export const schedulingAvailableDaysSelector = (state) => {
  if (
    schedulingIsPickUpSelector(state)
    || schedulingisMobileTechnicianSelector(state)
  ) {
    const jobSlots = schedulingIsPickUpSelector(state)
      ? JSON.parse(JSON.stringify(state.schedulingProcess.availableJobSlots))
      : JSON.parse(JSON.stringify(state.schedulingProcess.availableTechiciansJobSlots));

    for (let i = 0; i < jobSlots.length; i++) {
      const relatedJobSlots = jobSlots[i].slots;
      const slots = {};
      const employeeJobSlots = {};

      Object.keys(relatedJobSlots).forEach((key) => {
        if (relatedJobSlots[key].length > 0) {
          slots[key] = [...settingsTeamServiceAdvisorsSelector(state).map((advisor) => advisor.id)];
          employeeJobSlots[key] = relatedJobSlots[key];
        } else {
          slots[key] = [];
          employeeJobSlots[key] = relatedJobSlots[key];
        }
      });

      jobSlots[i].slots = slots;
      jobSlots[i].employeeJobSlots = employeeJobSlots;
    }

    return jobSlots;
  }

  return state.schedulingProcess.availableSlots;
};

export const schedulingAvailableJobEmployee = (state) => {
  const availableDays = schedulingAvailableDaysSelector(state);
  const chosenTimeSlot = schedulingChosenTimeSlotSelector(state);
  const { quarterSlot, day } = chosenTimeSlot;

  if (quarterSlot) {
    const fullDate = day.full_date;

    return availableDays.filter(
      (item) => item.full_date === fullDate,
    )[0]?.employeeJobSlots[quarterSlot];
  }

  return [];
};

export const schedulingAvailableDropOffJobEmployee = (state) => {
  const availableDays = schedulingAvailableDaysSelector(state);
  const chosenTimeSlot = schedulingChosenDropOffTimeSlotSelector(state);
  const { quarterSlot, day } = chosenTimeSlot;

  if (quarterSlot) {
    const fullDate = day.full_date;

    return availableDays.filter(
      (item) => item.full_date === fullDate,
    )[0]?.employeeJobSlots[quarterSlot];
  }

  return [];
};

export const schedulingBookedAppointmentSelector = (state) => (
  state.schedulingProcess.bookedAppointment
);

export const schedulingRemoteJobDataSelector = (state) => (
  state.schedulingProcess.remoteJobData
);

export const schedulingDecisionTreesSelector = (state) => (
  state.schedulingProcess.decisionTrees
);

export const schedulingDecisionTreeResultsSelector = (state) => (
  state.schedulingProcess.decisionTreeResults
);

export const schedulingProcessWaiterAllowed = (state) => (
  state.schedulingProcess.waiterAllowed
);

export const schedulingProcessAvailableLoaners = (state) => (
  state.schedulingProcess.availableLoaners
);

export const schedulingProcessIsJobReachableLoading = (state) => (
  state.schedulingProcess.isJobReachableLoading
);

export const schedulingProcessJobReachable = (state) => (
  state.schedulingProcess.jobReachable
);

export const schedulingProcessJobDropOffReachable = (state) => (
  state.schedulingProcess.jobDropOffReachable
);

export const schedulingProcessDriversSelector = (state) => state.schedulingProcess.activeDrivers;
export const schedulingProcessTotalDriversSelector = (state) => (
  state.schedulingProcess.activeDriversTotal
);

export const schedulingProcessMobileTechniciansSelector = (state) => (
  state.schedulingProcess.activeMobileTechnicians
);
export const schedulingProcessTotalMobileTechniciansSelector = (state) => (
  state.schedulingProcess.activeMobileTechniciansTotal
);

export const schedulingProcessServiceLocationSelector = (state) => (
  state.schedulingProcess.serviceLocation
);

export const schedulingProcessAvailableSlotsSelector = (state) => (
  state.schedulingProcess.availableSlots
);
