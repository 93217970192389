import React, { useCallback, useEffect, useState } from "react";
import {
  arrayOf, node, number, shape, string,
} from "prop-types";
import ReactTable from "react-table-6";
import Panel from "components/common/Panel";
import Button from "components/common/Button";
import Pagination from "components/common/ReactTableElements/Pagination";
import UserSettingsActionCell from "components/common/ReactTableElements/cellRenderers/UserSettingsActionCell";
import searchIcon from "assets/images/search.svg";
import AddUserModal from "./AddEmployeeModal";
import "./styles.scss";

const EmployeesPanel = ({
  employments,
}) => {
  const [page, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [openPopupId, setOpenPopupId] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  const handleSearch = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const filteredUsers = useCallback(() => {
    if (!search) {
      return employments;
    }
    return employments.filter(
      (employment) => (
        employment.name
        && employment.name.toLowerCase().includes(search.toLowerCase())
      ),
    );
  }, [employments, search]);

  const lockedAtCell = (props) => {
    if (props.value) {
      return "Yes";
    }

    return "No";
  };

  const userSettingsActionCell = (props) => {
    const updatedProps = {
      ...props,
      isLocked: props.original.locked_at,
      email: props.original.email,
      openPopup: (popupId) => setOpenPopupId(openPopupId !== popupId ? popupId : null),
      closePopup: () => setOpenPopupId(false),
      openedPopupId: openPopupId,
    };
    return <UserSettingsActionCell {...updatedProps} />;
  };

  const columns = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "EMPLOYEE NO.",
      accessor: "employee_number",
    },
    {
      Header: "EMAIL",
      accessor: "email",
      minWidth: 160,
    },
    {
      Header: "ROLE",
      accessor: "role",
    },
    {
      Header: "LOCKED",
      accessor: "locked_at",
      Cell: lockedAtCell,
    },
    {
      Header: "",
      accessor: "id",
      Cell: userSettingsActionCell,
    },
  ];

  const header = (
    <div className="conciergeSettingsPagePanelHeader">
      <p>Users</p>
      <div className="conciergeSettingsPagePanelHeaderControls">
        <form className="usersSearch">
          <input
            className="usersSearchInput"
            placeholder="Search for users..."
            value={search}
            onChange={handleSearch}
          />
          <img
            alt="search"
            className="usersSearchIcon"
            src={searchIcon}
          />
        </form>
        <div className="conciergeSettingsPagePanelHeaderSeparator">&nbsp;</div>
        <Button
          variant="dark"
          style={{ width: "210px" }}
          onClick={() => setIsCreateModalOpen(true)}
        >
          + Add new user
        </Button>
      </div>
    </div>
  );

  const employee = () => {
    const data = filteredUsers();
    const increase = (page - 1) * pageSize;
    return data ? data.slice(increase, increase + pageSize) : [];
  };

  return (
    <Panel
      header={header}
      className="conciergeSettingsPageUsersPanel"
    >
      <ReactTable
        columns={columns}
        data={employee()}
        defaultPageSize={pageSize}
        PaginationComponent={Pagination}
        page={page}
        onPageChange={setCurrentPage}
        onPageSizeChange={setPageSize}
        minRows={1}
        manual
        total={filteredUsers().length}
      />
      {isCreateModalOpen && (
        <AddUserModal onClose={() => setIsCreateModalOpen(false)} />
      )}
    </Panel>
  );
};

EmployeesPanel.propTypes = {
  employments: arrayOf(shape({
    id: number,
    name: string,
  })).isRequired,
  original: shape({
    locked_at: node,
    email: string,
  }).isRequired,
  value: node.isRequired,
};

export default EmployeesPanel;
