import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = process.env.NODE_ENV === "production" ? [thunk] : [thunk, createLogger()];
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares)),
);

store.subscribe(() => {
  const {
    token, role, channelKey, userAdvisorId,
  } = store.getState().auth;
  const { dealershipId } = store.getState().app;
  if (token && role) {
    sessionStorage.setItem("CONCIERGE_TOKEN", token);
    sessionStorage.setItem("CONCIERGE_ROLE", role);
  }
  if (channelKey && dealershipId) {
    sessionStorage.setItem("CONCIERGE_CHANNEL_KEY", channelKey);
    sessionStorage.setItem("CONCIERGE_DEALERSHIP", dealershipId);
  }
  if (userAdvisorId) {
    sessionStorage.setItem("CONCIERGE_USER_ADVISOR_ID", userAdvisorId);
  }
});

export default store;
