import {
  createDealershipMaintainer,
  createIntegration,
  deleteDealershipMaintainer,
  fetchDealershipInfo,
  fetchTeamTags,
  updateDealershipMaintainer,
  updateDmsIntegration,
  updateIntegration,
  updateLoanerIntegration,
} from "shared/api";
import { dealershipDetailsIdSelector } from "store/selectors/dealership-details-selectors";
import { authTokenSelector } from "store/selectors/auth-selectors";

import {
  SETTINGS_UPDATE_INTEGRATION_FAILURE,
  SETTINGS_UPDATE_INTEGRATION_SUCCESS,
} from "store/actions/settings-actions";

export const DEALERSHIP_DETAILS_SET_LOADING_STATE = "DEALERSHIP_DETAILS_SET_LOADING_STATE";
export const DEALERSHIP_DETAILS_FETCH_SUCCESS = "DEALERSHIP_DETAILS_FETCH_SUCCESS";
export const DEALERSHIP_DETAILS_FETCH_FAILURE = "DEALERSHIP_DETAILS_FETCH_FAILURE";
export const DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_SUCCESS = "DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_SUCCESS";
export const DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_FAILURE = "DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_FAILURE";
export const DEALERSHIP_DETAILS_ADD_INTEGRATION_SUCCESS = "DEALERSHIP_DETAILS_ADD_INTEGRATION_SUCCESS";
export const DEALERSHIP_DETAILS_ADD_INTEGRATION_FAILURE = "DEALERSHIP_DETAILS_ADD_INTEGRATION_FAILURE";

export const DEALERSHIP_DETAILS_ADD_MAINTAINER_SUCCESS = "DEALERSHIP_DETAILS_ADD_MAINTAINER_SUCCESS";
export const DEALERSHIP_DETAILS_ADD_MAINTAINER_FAILURE = "DEALERSHIP_DETAILS_ADD_MAINTAINER_FAILURE";

export const DEALERSHIP_DETAILS_UPDATE_MAINTAINER_SUCCESS = "DEALERSHIP_DETAILS_UPDATE_MAINTAINER_SUCCESS";
export const DEALERSHIP_DETAILS_UPDATE_MAINTAINER_FAILURE = "DEALERSHIP_DETAILS_UPDATE_MAINTAINER_FAILURE";

export const DEALERSHIP_DETAILS_DELETE_MAINTAINER_SUCCESS = "DEALERSHIP_DETAILS_DELETE_MAINTAINER_SUCCESS";
export const DEALERSHIP_DETAILS_DELETE_MAINTAINER_FAILURE = "DEALERSHIP_DETAILS_DELETE_MAINTAINER_FAILURE";

export const DEALERSHIP_DETAILS_LOCK_DAY_SUCCESS = "DEALERSHIP_DETAILS_LOCK_DAY_SUCCESS";
export const DEALERSHIP_DETAILS_LOCK_DAY_FAILURE = "DEALERSHIP_DETAILS_LOCK_DAY_FAILURE";
export const DEALERSHIP_DETAILS_UNLOCK_DAY_SUCCESS = "DEALERSHIP_DETAILS_UNLOCK_DAY_SUCCESS";
export const DEALERSHIP_DETAILS_UNLOCK_DAY__FAILURE = "DEALERSHIP_DETAILS_UNLOCK_DAY__FAILURE";

export const DEALERSHIP_DETAILS_UPDATE_INTEGRATION_SUCCESS = "DEALERSHIP_DETAILS_UPDATE_INTEGRATION_SUCCESS";
export const DEALERSHIP_DETAILS_UPDATE_INTEGRATION_FAILURE = "DEALERSHIP_DETAILS_UPDATE_INTEGRATION_FAILURE";

const setLoadingState = () => {
  return {
    type: DEALERSHIP_DETAILS_SET_LOADING_STATE,
    payload: {},
  };
};

export const retrieveDealershipDetails = (dealershipId) => async (dispatch, getState) => {
  dispatch(setLoadingState());
  const token = authTokenSelector(getState());

  await fetchDealershipInfo(dealershipId, token)
    .then((response) => (
      dispatch({
        type: DEALERSHIP_DETAILS_FETCH_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: DEALERSHIP_DETAILS_FETCH_FAILURE,
      payload: { error },
    }));
};

export const fetchDealershipTeamTags = (dealershipId) => async (dispatch, getState) => {
  dispatch(setLoadingState());
  const token = authTokenSelector(getState());

  await fetchTeamTags(dealershipId, token)
    .then((response) => (
      dispatch({
        type: DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => dispatch({
      type: DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_FAILURE,
      payload: { error },
    }));
};

export const addIntegration = (dealershipId, integrationData) => (dispatch, getState) => {
  dispatch(setLoadingState());
  const token = authTokenSelector(getState());

  return createIntegration(dealershipId, integrationData, token)
    .then((response) => dispatch({
      type: DEALERSHIP_DETAILS_ADD_INTEGRATION_SUCCESS,
      payload: response,
    }))
    .catch((error) => dispatch({
      type: DEALERSHIP_DETAILS_ADD_INTEGRATION_FAILURE,
      payload: { error },
    }));
};

export const createMaintainer = (email) => (dispatch, getState) => {
  dispatch(setLoadingState());
  const dealershipId = dealershipDetailsIdSelector(getState());
  const token = authTokenSelector(getState());
  return createDealershipMaintainer(dealershipId, email, token)
    .then((response) => (
      dispatch({
        type: DEALERSHIP_DETAILS_ADD_MAINTAINER_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => (
      dispatch({
        type: DEALERSHIP_DETAILS_ADD_MAINTAINER_FAILURE,
        payload: { error },
      })
    ));
};

export const deleteMaintainer = (userId) => (dispatch, getState) => {
  dispatch(setLoadingState());
  const dealershipId = dealershipDetailsIdSelector(getState());
  const token = authTokenSelector(getState());
  return deleteDealershipMaintainer(dealershipId, userId, token)
    .then((response) => (
      dispatch({
        type: DEALERSHIP_DETAILS_DELETE_MAINTAINER_SUCCESS,
        payload: {
          id: userId,
          response,
        },
      })
    ))
    .catch((error) => (
      dispatch({
        type: DEALERSHIP_DETAILS_DELETE_MAINTAINER_FAILURE,
        payload: { error },
      })
    ));
};

export const updateMaintainer = (userId, email) => (dispatch, getState) => {
  dispatch(setLoadingState());
  const dealershipId = dealershipDetailsIdSelector(getState());
  const token = authTokenSelector(getState());
  return updateDealershipMaintainer(
    dealershipId,
    userId,
    email,
    token,
  )
    .then((response) => (
      dispatch({
        type: DEALERSHIP_DETAILS_UPDATE_MAINTAINER_SUCCESS,
        payload: {
          id: userId,
          email,
          response,
        },
      })
    ))
    .catch((error) => (
      dispatch({
        type: DEALERSHIP_DETAILS_UPDATE_MAINTAINER_FAILURE,
        payload: { error },
      })
    ));
};

export const updateIntegrationOptions = (integrationId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipDetailsIdSelector(getState());

  return updateIntegration(
    dealershipId,
    integrationId,
    params,
    token,
  )
    .then(() => (
      dispatch({
        type: SETTINGS_UPDATE_INTEGRATION_SUCCESS,
        payload: {
          id: integrationId,
          ...params,
        },
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_INTEGRATION_FAILURE,
      payload: { error },
    }));
};

export const updateDmsIntegrationOptions = (integrationId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipDetailsIdSelector(getState());

  return updateDmsIntegration(
    dealershipId,
    integrationId,
    params,
    token,
  )
    .then(() => (
      dispatch({
        type: DEALERSHIP_DETAILS_UPDATE_INTEGRATION_SUCCESS,
        payload: {
          id: integrationId,
          ...params,
        },
      })
    ))
    .catch((error) => dispatch({
      type: DEALERSHIP_DETAILS_UPDATE_INTEGRATION_FAILURE,
      payload: { error: error.errors || error },
    }));
};

export const updateLoanerIntegrationOptions = (integrationId, params) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipDetailsIdSelector(getState());

  return updateLoanerIntegration(
    dealershipId,
    integrationId,
    params,
    token,
  )
    .then(() => (
      dispatch({
        type: SETTINGS_UPDATE_INTEGRATION_SUCCESS,
        payload: {
          id: integrationId,
          ...params,
        },
      })
    ))
    .catch((error) => dispatch({
      type: SETTINGS_UPDATE_INTEGRATION_SUCCESS,
      payload: { error },
    }));
};
