import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, shape,
} from "prop-types";
import cx from "classnames";
import uniqid from "uniqid";
import ReactTooltip from "react-tooltip";
import unselectedIcon from "assets/images/add.svg";
import selectedIcon from "assets/images/checkmark.svg";
import down from "assets/images/down.svg";
import up from "assets/images/up.svg";
import { ReactComponent as MobileTech } from "assets/icons/mobile-technician.svg";
import { menuItemPropType, servicePropType } from "shared/prop-types";
import Input from "components/common/Input";
import {
  createPackageNamesList,
  doesServiceHasPackageIncluded,
  isServiceAlreadySelected,
} from "./utils";
import ConcernDecisionTree from "./ConcernDecisionTree";

import styles from "./styles.module.scss";

const initButtonsMap = (services = []) => {
  const items = {};

  services.forEach((service) => {
    items[service.id] = false;
  });

  return items;
};

const ServicesToSelect = ({
  servicesToShow,
  selectedServices,
  decisionTreeDetails,
  makeServiceSelection,
  makeAdvisorSelection,
  mobileTechnicianEnabled,
  isPickUp,
  isMobileTechnician,
}) => {
  const [disabledServices, setDisabledServices] = useState([]);
  const [servicesButtons, setServicesButtons] = useState(initButtonsMap(servicesToShow));
  const [showTooltip, setShowTooltip] = useState(false);

  const isServiceDefault = (service) => {
    if (
      (isMobileTechnician && service.default_mobile_repair_order_item)
      || (isPickUp && service.default_pickup_repair_order_item)
      || (!isMobileTechnician && !isPickUp && service.default_inhouse_repair_order_item)
    ) {
      return true;
    }

    return false;
  };

  const handleServiceButtonToShow = (service = {}) => {
    const updatedButtons = { ...servicesButtons };
    updatedButtons[service.id] = !updatedButtons[service.id];
    setServicesButtons(updatedButtons);
  };

  const setSelectionList = (service) => {
    const packageItems = createPackageNamesList(service);

    let newSelectedList = selectedServices.filter((item) => !isServiceDefault(item));
    let newDisabledList = disabledServices;

    if (isServiceAlreadySelected(selectedServices, service)) {
      newSelectedList = newSelectedList.filter(
        (item) => (item.menu_item_id || item.id) !== service.id,
      );
      newDisabledList = newDisabledList.filter(
        (packageItem) => (
          !packageItems.includes(packageItem)
        ),
      );
    } else {
      newSelectedList.push(service);

      if (doesServiceHasPackageIncluded(service)) {
        newDisabledList.push(...packageItems);
        newSelectedList = newSelectedList.filter(
          (item) => !packageItems.includes(item.name),
        );
      }
    }

    setDisabledServices(newDisabledList);
    makeServiceSelection(newSelectedList);
    makeAdvisorSelection(null);
  };

  const addToService = (service, key, value) => {
    let currentService = selectedServices.find(
      (element) => element.name === service.name,
    );
    const indexOfCurrentService = selectedServices.indexOf(currentService);
    const decisionValue = Object
      .fromEntries(Object.entries(value).filter((answer) => answer[1] !== null));

    if (key === "additionalInfo") {
      currentService = {
        ...currentService,
        [key]: value,
      };
    } else {
      currentService = {
        ...currentService,
        [key]: decisionValue,
      };
    }

    const newSelectedServices = selectedServices;
    newSelectedServices.splice(indexOfCurrentService, 1, currentService);

    makeServiceSelection(newSelectedServices);
  };

  const isServiceSelected = (service) => {
    if (isServiceDefault(service)) {
      return true;
    }

    return selectedServices.filter((item) => {
      const isPackageItem = (
        item.kind !== "concern"
        && item.package_items
        && item.package_items.filter(
          (packageItem) => packageItem.name === service.name,
        ).length > 0
      );

      return item.name === service.name || isPackageItem;
    }).length > 0;
  };

  useEffect(() => {
    const newDisabledList = disabledServices;

    selectedServices.map((selectedService) => {
      if (doesServiceHasPackageIncluded(selectedService)) {
        const packageItems = createPackageNamesList(selectedService);
        newDisabledList.push(...packageItems);
      }

      return selectedService;
    });

    setDisabledServices(newDisabledList);
  }, []);

  const selectedServiceValue = (service) => selectedServices.find(
    (selected) => selected.name === service.name,
  ).additionalInfo || "";

  const isMobileTech = (service) => (
    mobileTechnicianEnabled && service.team_tags && service.team_tags.filter(
      (team) => team.mobile,
    ).length > 0);

  return (
    <div className={styles.servicesToSelect}>
      {
        servicesToShow && servicesToShow.map((service) => (
          <div
            key={service.id}
            className={cx(styles.service, {
              [styles.selected]: isServiceSelected(service),
              [styles.disabled]: disabledServices.includes(service.name)
              || isServiceDefault(service),
            })}
          >
            <button
              className={styles.header}
              type="button"
              onClick={() => setSelectionList(service)}
              disabled={
                disabledServices.includes(service.name)
                || isServiceDefault(service)
              }
            >
              <div className={styles.headerContainer}>
                <div className={styles.info}>
                  <div className={styles.label}>
                    <span className={styles.caption}>
                      {service.name}
                    </span>
                    {isServiceDefault(service) && (
                      <span className={styles.captionDefaultService}>
                        (Default service)
                      </span>
                    )}
                    {isMobileTech(service) && (
                      <MobileTech
                        className={styles.icon}
                        data-for="icons-description"
                        data-tip="Mobile technician"
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.info}>
                  {isServiceSelected(service) && (
                    <div className={styles.selectedIcon}>
                      <img
                        src={selectedIcon}
                        alt="Selected"
                      />
                    </div>
                  )}
                  {!isServiceSelected(service) && (
                    <div className={styles.unselectedIcon}>
                      <img
                        src={unselectedIcon}
                        alt="Unselected"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.price}>
                {`$${Number(service.price || service.fee)}`}
              </div>
            </button>
            {(isServiceSelected(service) && service.kind === "concern") && (
              <>
                {service.decision_tree && (
                  <ConcernDecisionTree
                    service={service}
                    decisionTreeDetails={
                      (decisionTreeDetails.find(
                        (tree) => tree.menu_item_id === service.id,
                      )?.details || [])
                    }
                    onChange={(value) => addToService(service, "decisionTreeResult", value)}
                  />
                )}
                <Input
                  placeholder="Please describe your concern"
                  type="text"
                  inputClassName=""
                  value={selectedServiceValue(service)}
                  onClick={(e) => e.stopPropagation(e)}
                  onChange={(value) => addToService(service, "additionalInfo", value)}
                  maxLength={service.decision_tree ? 50 : 200}
                  required={!service.decision_tree}
                  additionalInfo={`${selectedServiceValue(service).length}/${service.decision_tree ? 50 : 200}`}
                />
              </>
            )}
            <div>
              {service.package_items?.length > 0 && (
                <div>
                  <div
                    className={styles.dropdown}
                    onClick={() => handleServiceButtonToShow(service)}
                  >
                    See operations included
                    <img
                      className={styles.dropdownIcon}
                      src={servicesButtons[service.id] ? up : down}
                      alt=""
                    />
                  </div>
                  {servicesButtons[service.id] && (
                    <div>
                      <div className={styles.included}>
                        {service.premium_extras.length > 0 && (
                          <span className={styles.includedTitle}>
                            Premium services included:
                          </span>
                        )}
                        {service.premium_extras.map((item) => (
                          <div className={styles.premiumItem}>
                            <span key={uniqid()}>
                              {item}
                            </span>
                          </div>
                        ))}
                        <span className={styles.includedTitle}>
                          Services included:
                        </span>
                        {[...service.package_items].map((item) => (
                          <span
                            key={uniqid()}
                            className={styles.included}
                          >
                            {item.name}
                          </span>
                        ))}
                        {service.extras?.length > 0 && (
                          <div>
                            {service.extras.map((item) => (
                              <span
                                key={uniqid()}
                                className={styles.included}
                              >
                                {item}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {showTooltip && (
              <ReactTooltip
                id="icons-description"
                className={styles.tooltip}
                delayShow={100}
                place="bottom"
                backgroundColor="#F9FAFC"
                textColor="black"
                border
                borderColor="#dedee0"
              />
            )}
          </div>
        ))
      }
    </div>
  );
};

ServicesToSelect.propTypes = {
  servicesToShow: menuItemPropType.isRequired,
  selectedServices: arrayOf(servicePropType),
  decisionTreeDetails: arrayOf(shape),
  makeServiceSelection: func.isRequired,
  makeAdvisorSelection: func.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  isPickUp: bool.isRequired,
  isMobileTechnician: bool.isRequired,
};

ServicesToSelect.defaultProps = {
  selectedServices: [],
  decisionTreeDetails: [],
};

export default ServicesToSelect;
