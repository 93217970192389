import React, { useState } from "react";
import { bool, func } from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import { createTemplate, setCancelChanges } from "store/actions/menu-templates-actions";
import Modal from "components/common/Modal";

import styles from "./styles.module.scss";

const AddTemplateModal = ({
  onClose,
  onSubmit,
  isExtension,
  cancelChanges,
}) => {
  const [name, setName] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);

  const submit = () => {
    onSubmit(name, isExtension)
      .then(() => onClose())
      .catch(({ response: { data: errors } }) => setErrorMessages(errors.errors));
  };

  const handleChange = (event) => setName(event.target.value);

  const handleClose = () => {
    cancelChanges();
    onClose();
  };

  return (
    <Modal
      title="New template"
      cancelButtonText="Cancel"
      submitButtonText="Create"
      size="small"
      onCancel={handleClose}
      onSubmit={submit}
      className={styles.addTemplateModal}
    >
      <div className={styles.label}>
        Template name
      </div>
      <input
        className={cx(styles.input, { [styles.error]: errorMessages.length })}
        value={name}
        onChange={handleChange}
      />
      {errorMessages.map((error) => (
        <span key={error}>
          {error}
        </span>
      ))}
    </Modal>
  );
};

AddTemplateModal.propTypes = {
  isExtension: bool.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  cancelChanges: func.isRequired,
};

const actions = {
  onSubmit: createTemplate,
  cancelChanges: setCancelChanges,
};

export default connect(null, actions)(AddTemplateModal);
