import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, number, oneOfType, string,
} from "prop-types";
import {
  chosenTimeSlotPropType, driverPropType, serviceAdvisorPropType, serviceLocationPropType,
} from "shared/prop-types";
import dateFns from "date-fns";
import ClipLoader from "react-spinners/ClipLoader";
import { isEmpty } from "ramda";

import Panel from "components/common/Panel";
import Button from "components/common/Button";
import Alert from "components/common/Alert";
import DriversSelection from "./DriversSelection";

import styles from "./styles.module.scss";

const ChooseDrivers = ({
  availableJobEmployee,
  availableDropOffJobEmployee,
  chosenTimeSlot,
  initialTime,
  dropOffInitialTime,
  retrieveDrivers,
  storeRemoteJobData,
  setSchedulingStep,
  selectedAdvisor,
  jobId,
  drivers,
  serviceLocation,
  driver,
  coDriver,
  prevNotes,
  nextStep,
  dropOffType,
  showCoDriver,
}) => {
  const [selectedDriver, setSelectedDriver] = useState(driver);
  const [selectedCoDriver, setSelectedCoDriver] = useState(coDriver);
  const [notes, setNotes] = useState(prevNotes);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    retrieveDrivers();
  }, []);

  useEffect(() => {
    if (drivers && selectedAdvisor) {
      setIsLoading(false);
    }
  }, [drivers, selectedAdvisor]);

  useEffect(() => {
    const time = dropOffType ? dropOffInitialTime : initialTime;
    const initialFullDate = dateFns.format(time, "YYYY-MM-DD");
    const initialQuarterSlot = dateFns.format(time, "HH:mm");
    const { quarterSlot, day } = chosenTimeSlot;

    if (quarterSlot) {
      const fullDate = day.full_date;

      if (initialFullDate !== fullDate || initialQuarterSlot !== quarterSlot) {
        setSelectedDriver({});
        setSelectedCoDriver({});
      }
    }
  }, [chosenTimeSlot]);

  const employees = () => {
    const result = dropOffType ? [...availableDropOffJobEmployee] : [...availableJobEmployee];
    const time = dropOffType ? dropOffInitialTime : initialTime;

    if (time) {
      const initialFullDate = dateFns.format(time, "YYYY-MM-DD");
      const initialQuarterSlot = dateFns.format(time, "HH:mm");
      const { quarterSlot, day } = chosenTimeSlot;

      if (quarterSlot) {
        const fullDate = day.full_date;

        if (initialFullDate === fullDate && initialQuarterSlot === quarterSlot) {
          if (driver && driver.id) {
            result.push(driver.id);
          }

          if (coDriver && coDriver.id) {
            result.push(coDriver.id);
          }
        }
      }
    }

    return [...new Set(result)];
  };

  const availableDrivers = () => {
    let result = drivers.filter(
      (item) => employees().indexOf(item.id)
              !== -1 && selectedCoDriver?.id !== item.id,
    );

    if (result.length === 0) {
      result = drivers;
    }

    return result;
  };

  const availableCoDrivers = () => {
    let result = drivers.filter(
      (item) => employees().indexOf(item.id)
              !== -1 && selectedDriver?.id !== item.id,
    );

    if (result.length === 0) {
      result = drivers;
    }

    return result;
  };

  const storeRemoteJobDataHandle = () => {
    const {
      address, addressData, geolocation, zone, distance,
    } = dropOffType
      ? serviceLocation.dropOff
      : serviceLocation.pickUp;

    const data = {
      jobId,
      address,
      addressData,
      collectionTime: {
        date: chosenTimeSlot.day.full_date,
        time: chosenTimeSlot.quarterSlot,
      },
      jobType: dropOffType ? "dropoff" : "pickup",
      mainDriverId: selectedDriver.id,
      coDriverId: selectedCoDriver.id,
      notes,
      geolocation,
      location_area: zone,
      distance,
      distance_limit_ignored: dropOffType
        ? serviceLocation.dropOff.distanceLimitIgnored
        : serviceLocation.pickUp.distanceLimitIgnored,
      overcapacity: chosenTimeSlot.overcapacity,
    };

    if (dropOffType) {
      storeRemoteJobData({
        dropOff: data,
      });
    } else {
      storeRemoteJobData({
        pickUp: data,
      });
    }

    setSchedulingStep(nextStep);
  };

  if (isEmpty(chosenTimeSlot)) {
    return <Panel className={styles.loading}>Please select time slot first</Panel>;
  }

  return (
    <div className={styles.carousel}>
      {isLoading || (
        <>
          <DriversSelection
            availableDrivers={availableDrivers()}
            selectedDriver={selectedDriver}
            setSelectedDriver={setSelectedDriver}
            availableCoDrivers={availableCoDrivers()}
            selectedCoDriver={selectedCoDriver}
            setSelectedCoDriver={setSelectedCoDriver}
            notes={notes}
            setNotes={setNotes}
            showCoDriver={showCoDriver}
          />
          {availableDrivers().length === 0 && (
            <Alert variant="warning" text="Please select date and time first" />
          )}
          <div className={styles.submit}>
            <Button
              variant="brand"
              onClick={storeRemoteJobDataHandle}
              disabled={!selectedDriver.id}
            >
              Done
            </Button>
          </div>
        </>
      )}
      {isLoading && (
        <div className={styles.spinner}>
          <ClipLoader size={60} color="#0bcaf9" />
        </div>
      )}
    </div>
  );
};

ChooseDrivers.propTypes = {
  availableJobEmployee: arrayOf(number),
  availableDropOffJobEmployee: arrayOf(number),
  chosenTimeSlot: chosenTimeSlotPropType,
  initialTime: string,
  dropOffInitialTime: string,
  selectedAdvisor: serviceAdvisorPropType,
  retrieveDrivers: func.isRequired,
  storeRemoteJobData: func.isRequired,
  setSchedulingStep: func.isRequired,
  drivers: arrayOf(driverPropType),
  serviceLocation: serviceLocationPropType.isRequired,
  jobId: oneOfType([number, string]),
  driver: driverPropType,
  coDriver: driverPropType,
  prevNotes: string,
  nextStep: string.isRequired,
  dropOffType: bool,
  showCoDriver: bool.isRequired,
};

ChooseDrivers.defaultProps = {
  availableJobEmployee: [],
  availableDropOffJobEmployee: [],
  chosenTimeSlot: null,
  initialTime: null,
  dropOffInitialTime: null,
  selectedAdvisor: null,
  jobId: null,
  drivers: null,
  driver: {},
  coDriver: {},
  prevNotes: "",
  dropOffType: false,
};

export default ChooseDrivers;
