import { connect } from "react-redux";

import {
  fetchAvailableDays,
  getAvailableLoaners,
  setChosenDropOffTimeSlot,
  setChosenTimeSlot,
  setCurrentStep,
  setRemoteJobData,
  setServiceAdvisor,
  waiterPossibility,
} from "store/actions/scheduling-process-actions";
import {
  schedulingAvailableDaysSelector,
  schedulingChosenDropOffTimeSlotSelector,
  schedulingChosenTimeSlotSelector,
  schedulingCurrentStepSelector,
  schedulingIsPickUpSelector,
  schedulingRemoteJobDataSelector,
  schedulingTimeSlotsLoadingErrorSelector,
  schedulingTimeSlotsLoadingSelector,
  schedulingisMobileTechnicianSelector,
} from "store/selectors/scheduling-process-selectors";
import { phantomAdvisorAvailableSelector, settingsDataSelector } from "store/selectors/settings-selectors";

import TimeOfArrival from "./index";

const mapStateToProps = (state) => {
  return {
    availableDays: schedulingAvailableDaysSelector(state),
    chosenTimeSlot: schedulingChosenTimeSlotSelector(state),
    chosenDropOffTimeSlot: schedulingChosenDropOffTimeSlotSelector(state),
    timeSlotsLoading: schedulingTimeSlotsLoadingSelector(state),
    timeSlotsLoadingError: schedulingTimeSlotsLoadingErrorSelector(state),
    currentStep: schedulingCurrentStepSelector(state),
    phantomAdvisorAvailable: phantomAdvisorAvailableSelector(state),
    dealershipSettings: settingsDataSelector(state),
    isPickUp: schedulingIsPickUpSelector(state),
    isMobileTechnician: schedulingisMobileTechnicianSelector(state),
    remoteJobData: schedulingRemoteJobDataSelector(state),
  };
};

const actions = {
  fetchDays: fetchAvailableDays,
  setChosenQuarterSlot: setChosenTimeSlot,
  setChosenDropOffSlot: setChosenDropOffTimeSlot,
  setSchedulingStep: setCurrentStep,
  makeAdvisorSelection: setServiceAdvisor,
  validateWaiterPossibility: waiterPossibility,
  availableLoaners: getAvailableLoaners,
  storeRemoteJobData: setRemoteJobData,
};

export default connect(mapStateToProps, actions)(TimeOfArrival);
