import React, { useEffect, useState } from "react";
import cx from "classnames";
import {
  bool,
  func,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types";
import {
  addSeconds,
  format,
} from "date-fns";

import serviceButtonIcons from "shared/serviceButtonIcons";
import Button from "components/common/Button";
import EditDriversJobModal from "./EditDriversJobModal";
import EditMobileTechnicianJobModal from "./EditMobileTechnicianJobModal";

import "./styles.scss";

const JobCard = ({
  job,
  selectedJob,
  setSelectedJob,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [eta, setEta] = useState("");

  useEffect(() => {
    const service = new window.google.maps.DistanceMatrixService();

    service.getDistanceMatrix({
      destinations: [job.target_geolocation],
      origins: [job.current_geolocation],
      travelMode: "DRIVING",
    }, (response, status) => {
      if (status === "OK") {
        const { value } = response.rows[0].elements[0].duration;

        const collectionTime = format(job.collection_time, "YYYY-MM-DD HH:mm");
        const time = addSeconds(new Date(collectionTime), value);
        setEta(format(time, "MM/DD/YYYY hh:mm A"));
      }
    });
  }, [job]);

  const cardConfiguration = {
    assigned: {
      label: format(job.collection_time, "hh:mm A | YYYY/MM/DD"),
      icon: "driverAssignedGreen",
      class: "driverAssigned",
    },
    en_route_to_customer: {
      label: "En route to customer",
      icon: "departure",
      class: "enRoutToCustomer",
    },
    arrived_to_customer: {
      label: "Arrived to customer",
      icon: "position",
      class: "arrivedToCustomer",
    },
    en_route_to_dealership: {
      label: "En route to dealership",
      icon: "arrive",
      class: "enRouteToDealership",
    },
    arrived_to_dealership: {
      label: "Arrived to dealership",
      icon: "arrive",
      class: "arrivedToDealership",
    },
  };

  const yearMakeModel = () => {
    const details = job?.vehicle_set;

    if (details) {
      return `${details?.model_year} ${details?.make} ${details?.model}`;
    }

    return null;
  };

  const driverImg = (url) => (
    <img
      className="driverImg"
      src={(process.env.NODE_ENV === "development"
        ? `${process.env.REACT_APP_ROOT_URL}${url}`
        : url)}
      alt=""
    />
  );

  return (
    <>
      <div
        className={cx("jobCard", {
          jobCardActive: job.id === selectedJob?.id,
          jobCardDisabled: job.aasm_state === "arrived_to_dealership",
        })}
        key={job.id}
        onClick={() => {
          if (job.aasm_state !== "arrived_to_dealership") {
            if (job.id === selectedJob?.id) {
              setSelectedJob(null);
            } else {
              setSelectedJob(job);
            }
          }
        }}
      >
        <div className="jobCardHeader">
          <div className="display-flex align-items-center">
            <div>
              <div className={`jobCardHeaderStatus ${cardConfiguration[job.aasm_state]?.class}`}>
                <img
                  src={serviceButtonIcons[cardConfiguration[job.aasm_state]?.icon]}
                  alt=""
                />
                <p>{cardConfiguration[job.aasm_state].label}</p>
              </div>
              <div className="jobCardHeaderRO">
                {`RO: ${job.repair_order_number || "-"}`}
              </div>
            </div>
            <div className="jobCardHeaderCarImage">
              <img
                src={job.vehicle.image.url}
                alt=""
              />
            </div>
          </div>
          <div>
            {job.job_type === "pickup" && <div className="jobCardHeaderType">Pick-up</div>}
            {job.job_type === "dropoff" && <div className="jobCardHeaderType">Drop-off</div>}
          </div>
        </div>
        <div className="jobCardAppointmentInfo">
          <p>{yearMakeModel()}</p>
          <div className="vl" />
          <div>
            <p>{job.customer_name}</p>
            <p>{job.contact_number}</p>
          </div>
          <div className="vl" />
          <p>{job.address}</p>
        </div>
        <div className="jobCardDriversFooter">
          <div className="jobCardDriversFooterInfo">
            {job.technician && (
              <>
                {driverImg(job.technician.photo.url)}
                <div className="jobCardDriversFooterInfoDriver">
                  <p className="title">Technician</p>
                  <p className="name">{job.technician.name}</p>
                </div>
              </>
            )}
            {job.main_driver && (
              <>
                {driverImg(job.main_driver.photo.url)}
                <div className="jobCardDriversFooterInfoDriver">
                  <p className="title">Driver</p>
                  <p className="name">{job.main_driver.name}</p>
                </div>
              </>
            )}
            {job.co_driver && (
              <>
                {driverImg(job.co_driver.photo.url)}
                <div className="jobCardDriversFooterInfoDriver">
                  <p className="title">Co-Driver</p>
                  <p className="name">{job.co_driver.name}</p>
                </div>
              </>
            )}
            {!job.technician && (
              <div className="jobCardDriversFooterInfoAdditional">
                <p className="dealership">ETA to Dealership</p>
                <p className="time">{eta}</p>
              </div>
            )}
            {job?.distance_in_miles && (
              <div className="jobCardDriversFooterInfoAdditional">
                <p className="dealership">Distance</p>
                <p className="time">{job.distance_in_miles}</p>
              </div>
            )}
          </div>
          <Button
            variant="dark-outline"
            onClick={(event) => {
              event.stopPropagation();
              setShowModal(true);
            }}
            disabled={job.aasm_state !== "assigned"}
          >
            Edit job
          </Button>
        </div>
      </div>
      {showModal && (job.job_type === "pickup" || job.job_type === "dropoff") && (
        <EditDriversJobModal
          job={job}
          onClose={() => setShowModal(false)}
        />
      )}
      {showModal && job.job_type === "mobile_technician" && (
        <EditMobileTechnicianJobModal
          job={job}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

JobCard.propTypes = {
  job: objectOf(oneOfType([number, bool, string])).isRequired,
  selectedJob: objectOf(oneOfType([number, bool, string])),
  setSelectedJob: func.isRequired,
};

JobCard.defaultProps = {
  selectedJob: null,
};

export default JobCard;
