import { DEFAULT_SORT_COLUMN_BOOKINGS, DEFAULT_SORT_DIRECTION_BOOKINGS } from "shared/constants";

export const SET_TABLE_OPTIONS = "SET_TABLE_OPTIONS";
export const SET_BOOKINGS_TABLE_OPTIONS = "SET_BOOKINGS_TABLE_OPTIONS";

const DEFAULT_SORT = `${DEFAULT_SORT_COLUMN_BOOKINGS} ${DEFAULT_SORT_DIRECTION_BOOKINGS}`;

const initialState = {
  total: 0,
  links: "",
  perPage: null,
  currentPage: 1,
  sort: DEFAULT_SORT,
};

const tableOptions = (state = initialState, action) => {
  switch (action.type) {
    case SET_TABLE_OPTIONS:
      return {
        ...state,
        total: action.payload.total,
        links: action.payload.links,
        perPage: action.payload["per-page"],
        currentPage: action.payload.page,
        sort: action.payload.sort,
      };
    case SET_BOOKINGS_TABLE_OPTIONS:
      return {
        ...state,
        bookingsTotal: action.payload.total,
        bookingsPerPage: action.payload["per-page"],
        bookingsCurrentPage: action.payload.page,
        bookingsSort: action.payload.sort || action.payload["q[s]"] || DEFAULT_SORT,
      };
    default:
      return state;
  }
};

export default tableOptions;
