export const dealershipIdSelector = (state) => Number(state.app.dealershipId);
export const dealershipMakeModelYearMapSelector = (state) => state.app.makeModelYearMap;
export const dealershipMakeModelYearMapLoadingSelector = (state) => (
  state.app.makeModelYearMapLoading
);
export const delershipOpCodeMapSelector = (state) => state.app.opCodeMap;
export const vehicleSetByVinSelector = (state) => state.app.vehicleSetByVin;
export const vehicleSetByVinLoadingSelector = (state) => state.app.vehicleSetByVinLoading;
export const vehicleSetByVinErrorSelector = (state) => state.app.vehicleSetByVinError;
export const cdkModelCodeByVinSelector = (state) => state.app.cdkModelCodeByVin;
export const showQRSelector = (state) => state.app.showQR;
export const showReportsSelector = (state) => state.app.showReports;
