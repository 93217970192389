import React from "react";
import { node, objectOf, string } from "prop-types";
import {
  estimatedServiceTimeForBooking,
  estimatedTotalServiceTimeForBooking,
  totalCost,
} from "shared/utils/common";

import styles from "../styles.module.scss";

const EstimatedServiceTimeAndTotalCost = ({
  services,
  bufferTime,
}) => (
  <>
    <div className={styles.header}>
      Total
    </div>
    <div className={styles.total}>
      {services && `$${totalCost(services)}`}
    </div>
    <div className={styles.details}>
      <div className={styles.item}>
        <span className={styles.label}>Estimated services time: </span>
        <span className={styles.value}>
          {`${estimatedTotalServiceTimeForBooking(
            services,
            bufferTime,
          )} hours + upsell time`}
        </span>
      </div>
      {bufferTime !== "0.0" && (
        <div className={styles.container}>
          <span className={styles.value}>
            {`${estimatedServiceTimeForBooking(services)} hours estimated time`}
          </span>
          <span className={styles.value}>
            {`${bufferTime} hours estimated buffer time`}
          </span>
        </div>
      )}
    </div>
  </>
);

EstimatedServiceTimeAndTotalCost.propTypes = {
  services: objectOf(node),
  bufferTime: string.isRequired,
};

EstimatedServiceTimeAndTotalCost.defaultProps = {
  services: null,
};

export default EstimatedServiceTimeAndTotalCost;
