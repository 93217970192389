import React, { useEffect, useState } from "react";
import {
  arrayOf,
  bool,
  func,
} from "prop-types";
import { isEmpty, isNil } from "ramda";
import { connect } from "react-redux";
import { integrationPropType } from "shared/prop-types";
import { integrationSettingsKeys } from "shared/constants";
import { settingsTeamTagsSelector } from "store/selectors/settings-selectors";
import Accordion from "components/common/Accordion";
import Button from "components/common/Button";

import Integration from "./Integration";
import DmsIntegration from "./DmsIntegration";
import LoanerIntegration from "./LoanerIntegration";
import PickUpIntegration from "./PickUpIntegration";
import MobileTechnicianIntegration from "./MobileTechnicianIntegration";

import styles from "./styles.module.scss";

const IntegrationsPanel = ({
  integrations,
  isSuperAdmin,
  onAddIntegrationClick,
  updateDmsIntegration,
}) => {
  const [integrationsState, setIntegrationsState] = useState([]);

  useEffect(() => {
    setIntegrationsState(integrations);
  }, [integrations]);

  const updatedIntegrations = integrationsState
    .filter((integration) => integration.kind)
    .map((integration) => {
      const integrationSettings = integrationSettingsKeys.find(
        (el) => el.integrationType === integration.kind,
      );
      if (!integration.settings) {
        return {
          ...integration,
          settings: integrationSettings.settings,
        };
      }

      const newSettings = Object.keys(integrationSettings.settings).reduce(
        (acc, settingKey) => {
          return {
            ...acc,
            [settingKey]: integration.settings[settingKey]
              ? integration.settings[settingKey]
              : integrationSettings.settings[settingKey],
          };
        },
        {},
      );

      return {
        ...integration,
        settings: newSettings,
      };
    });

  const dmsIntegration = updatedIntegrations.filter(
    (el) => el.kind === "dealer_track" || el.kind === "cdk",
  );
  const loanerIntegration = updatedIntegrations.find(
    (el) => el.kind === "loaner",
  );
  const pickUpIntegration = updatedIntegrations.find(
    (el) => el.kind === "pickup",
  );
  const mobileTechnicianIntegration = updatedIntegrations.find(
    (el) => el.kind === "mobile_technician",
  );
  const regularIntegrations = updatedIntegrations.filter(
    (el) => !["dealer_track", "cdk", "loaner", "pickup", "mobile_technician"].includes(el.kind),
  );

  return (
    <Accordion
      title="Integrations"
      customClass={styles.accordion}
      isInitiallyExpanded={isSuperAdmin}
    >
      {(isEmpty(integrationsState) || isNil(integrationsState)) && (
        <p>No integrations found</p>
      )}
      {(dmsIntegration || regularIntegrations) && (
        <>
          {dmsIntegration && dmsIntegration.map((integration) => (
            <DmsIntegration
              integration={integration}
              isSuperAdmin={isSuperAdmin}
              updateIntegrationHandler={updateDmsIntegration}
            />
          ))}
          {loanerIntegration && (
            <LoanerIntegration
              integration={loanerIntegration}
              isSuperAdmin={isSuperAdmin}
            />
          )}
          {pickUpIntegration && (
            <PickUpIntegration
              integration={pickUpIntegration}
            />
          )}
          {mobileTechnicianIntegration && (
            <MobileTechnicianIntegration
              integration={mobileTechnicianIntegration}
            />
          )}
          {regularIntegrations.map((integration) => (
            <Integration
              integration={integration}
              isSuperAdmin={isSuperAdmin}
            />
          ))}
        </>
      )}
      {isSuperAdmin && (
        <section className={styles.addButton}>
          <Button
            variant="dark"
            onClick={onAddIntegrationClick}
          >
            + Add new integration
          </Button>
        </section>
      )}
    </Accordion>
  );
};

IntegrationsPanel.propTypes = {
  integrations: arrayOf(integrationPropType),
  isSuperAdmin: bool,
  onAddIntegrationClick: func.isRequired,
  updateDmsIntegration: func.isRequired,
};

IntegrationsPanel.defaultProps = {
  integrations: [],
  isSuperAdmin: false,
};

const mapStateToProps = (state) => {
  return {
    teamTags: settingsTeamTagsSelector(state),
  };
};

const actions = {};

export default connect(mapStateToProps, actions)(IntegrationsPanel);
