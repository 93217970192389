import React, { useState } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";

import { formatInput } from "shared/utils/common";
import { setCurrentStep, setNote } from "store/actions/scheduling-process-actions";
import { schedulingInternalNoteSelector } from "store/selectors/scheduling-process-selectors";
import TextArea from "components/common/TextArea";
import Button from "components/common/Button";

import "./styles.scss";

const InternalNote = ({ setSchedulingStep, saveInternalNote }) => {
  const [internalNote, setInternalNote] = useState("");

  const saveNote = () => {
    saveInternalNote(formatInput(internalNote));
    setSchedulingStep("");
  };

  const handleChange = (value) => {
    setInternalNote(value);
  };

  return (
    <section className="addNoteContainer">
      <TextArea
        inputClassName="addNoteTextArea"
        type="string"
        value={internalNote}
        onChange={(value) => handleChange(value)}
      />
      <Button
        variant="brand"
        onClick={(saveNote)}
      >
        Done
      </Button>
    </section>
  );
};

InternalNote.propTypes = {
  setSchedulingStep: func.isRequired,
  saveInternalNote: func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    internalNote: schedulingInternalNoteSelector(state),
  };
};

const actions = {
  setSchedulingStep: setCurrentStep,
  saveInternalNote: setNote,
};

export default connect(mapStateToProps, actions)(InternalNote);
