import {
  SETTINGS_ADD_ENCRYPTED_PASSWORD_TO_USERS,
  SETTINGS_ADD_NON_WORKING_DAY_FAILURE,
  SETTINGS_ADD_NON_WORKING_DAY_SUCCESS,
  SETTINGS_ADVISOR_LOADING_STATE,
  SETTINGS_CREATE_ARRIVE_USER_SUCCESS,
  SETTINGS_CREATE_DRIVER_FAILURE,
  SETTINGS_CREATE_DRIVER_SUCCESS,
  SETTINGS_CREATE_DRIVER_VACATIONS,
  SETTINGS_CREATE_DRIVER_VACATIONS_FAILURE,
  SETTINGS_CREATE_EMPLOYEE_VACATIONS,
  SETTINGS_CREATE_EMPLOYEE_VACATIONS_FAILURE,
  SETTINGS_CREATE_KIOSK_SUCCESS,
  SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS,
  SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE,
  SETTINGS_CREATE_REMOTE_USER_SUCCESS,
  SETTINGS_CREATE_TEAM_TAG_FAILURE,
  SETTINGS_CREATE_TEAM_TAG_SUCCESS,
  SETTINGS_CREATE_TEXT_MESSAGES_SUCCESS,
  SETTINGS_CREATE_TV_SCREEN_SUCCESS,
  SETTINGS_CREATE_USER_SUCCESS,
  SETTINGS_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_LOADING_STATE,
  SETTINGS_DELETE_ARRIVE_USER_SUCCESS,
  SETTINGS_DELETE_DRIVER_FAILURE,
  SETTINGS_DELETE_DRIVER_SUCCESS,
  SETTINGS_DELETE_DRIVER_VACATIONS,
  SETTINGS_DELETE_DRIVER_VACATIONS_FAILURE,
  SETTINGS_DELETE_EMPLOYEE_VACATIONS,
  SETTINGS_DELETE_EMPLOYEE_VACATIONS_FAILURE,
  SETTINGS_DELETE_KIOSK_SUCCESS,
  SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS,
  SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS_FAILURE,
  SETTINGS_DELETE_NON_WORKING_DAY_FAILURE,
  SETTINGS_DELETE_NON_WORKING_DAY_SUCCESS,
  SETTINGS_DELETE_REMOTE_USER_SUCCESS,
  SETTINGS_DELETE_TEAM_TAG_FAILURE,
  SETTINGS_DELETE_TEAM_TAG_SUCCESS,
  SETTINGS_DELETE_TEXT_MESSAGE_SUCCESS,
  SETTINGS_DELETE_TV_SCREEN_SUCCESS,
  SETTINGS_DELETE_USER_FAILURE,
  SETTINGS_DELETE_USER_SUCCESS,
  SETTINGS_DRIVER_LOADING_STATE,
  SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_FAILURE,
  SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_SUCCESS,
  SETTINGS_FETCH_DEALERSHIP_INFO_FAILURE,
  SETTINGS_FETCH_DEALERSHIP_INFO_SUCCESS,
  SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_FAILURE,
  SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_SUCCESS,
  SETTINGS_FETCH_DRIVERS_INFO_FAILURE,
  SETTINGS_FETCH_DRIVERS_INFO_SUCCESS,
  SETTINGS_FETCH_DRIVER_VACATIONS,
  SETTINGS_FETCH_DRIVER_VACATIONS_FAILURE,
  SETTINGS_FETCH_EMPLOYEE_VACATIONS,
  SETTINGS_FETCH_EMPLOYEE_VACATIONS_FAILURE,
  SETTINGS_FETCH_MOBILE_TECHNICIANS_FAILURE,
  SETTINGS_FETCH_MOBILE_TECHNICIANS_SUCCESS,
  SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS,
  SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS_FAILURE,
  SETTINGS_FETCH_NON_WORKING_DAYS_FAILURE,
  SETTINGS_FETCH_NON_WORKING_DAYS_SUCCESS,
  SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_ERROR,
  SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_SUCCESS,
  SETTINGS_FETCH_TEAM_ADVISORS,
  SETTINGS_FETCH_TEAM_ADVISORS_ERROR,
  SETTINGS_FETCH_TEAM_ADVISORS_SUCCESS,
  SETTINGS_FETCH_TEAM_TAG_FAILURE,
  SETTINGS_FETCH_TEAM_TAG_SUCCESS,
  SETTINGS_FETCH_TEXT_MESSAGES_SUCCESS,
  SETTINGS_MOBILE_TECHNICIANS_LOADING_STATE,
  SETTINGS_NON_WORKING_DAYS_LOADING_STATE,
  SETTINGS_SET_LOADING_STATE,
  SETTINGS_SET_TEAM_TAGS_LOADING_STATE,
  SETTINGS_UPDATE_ADVISORS_FAILURE,
  SETTINGS_UPDATE_ADVISORS_SUCCESS,
  SETTINGS_UPDATE_ADVISOR_FAILURE,
  SETTINGS_UPDATE_ADVISOR_SUCCESS,
  SETTINGS_UPDATE_ARRIVE_USER_SUCCESS,
  SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_FAILURE,
  SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_SUCCESS,
  SETTINGS_UPDATE_DEALERSHIP_INFO_FAILURE,
  SETTINGS_UPDATE_DEALERSHIP_INFO_SUCCESS,
  SETTINGS_UPDATE_DRIVER_FAILURE,
  SETTINGS_UPDATE_DRIVER_SUCCESS,
  SETTINGS_UPDATE_DRIVER_VACATIONS,
  SETTINGS_UPDATE_DRIVER_VACATIONS_FAILURE,
  SETTINGS_UPDATE_EMPLOYEE_VACATIONS,
  SETTINGS_UPDATE_EMPLOYEE_VACATIONS_FAILURE,
  SETTINGS_UPDATE_INTEGRATION_FAILURE,
  SETTINGS_UPDATE_INTEGRATION_SUCCESS,
  SETTINGS_UPDATE_KIOSK_SUCCESS,
  SETTINGS_UPDATE_MOBILE_TECHNICIANS_FAILURE,
  SETTINGS_UPDATE_MOBILE_TECHNICIANS_SUCCESS,
  SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS,
  SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE,
  SETTINGS_UPDATE_REMOTE_USER_SUCCESS,
  SETTINGS_UPDATE_STYLESHEET_FAILURE,
  SETTINGS_UPDATE_STYLESHEET_SUCCESS,
  SETTINGS_UPDATE_TEAM_TAGS_SENIORITY_SUCCESS,
  SETTINGS_UPDATE_TEAM_TAG_FAILURE,
  SETTINGS_UPDATE_TEAM_TAG_SUCCESS,
  SETTINGS_UPDATE_TEXT_MESSAGES_FAILURE,
  SETTINGS_UPDATE_TEXT_MESSAGES_SUCCESS,
  SETTINGS_UPDATE_TV_SCREEN_SUCCESS,
  SETTINGS_UPDATE_USER_FAILURE,
  SETTINGS_UPDATE_USER_SUCCESS,
} from "store/actions/settings-actions";

const initialState = {
  isLoading: false,
  isTeamTagsLoading: false,
  data: {
    arrive_users: [],
    employments: [],
    dispatch_codes: [],
    integrations: [],
    labor_types: [],
    mobile_technician_enabled: false,
    remote_enabled: false,
    remote_users: [],
  },
  drivers: [],
  driversTotal: 0,
  resyncDrivers: false,
  serviceAdvisors: [],
  serviceAdvisorsTotal: 0,
  mobileTechnicians: [],
  mobileTechniciansTotal: 0,
  activeAdvisors: [],
  teamServiceAdvisors: [],
  teamMembers: [],
  teamMembersLoading: false,
  teamMembersError: null,
  teamTags: [],
  employeeVacations: {},
  driverVacations: {},
  mobileTechniciansVacations: {},
  error: null,
  vacationError: null,
  textMessages: [],
  responsibleTeamTag: null,
  nonWorkingDays: [],
  employeeError: null,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_SET_LOADING_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case SETTINGS_FETCH_DEALERSHIP_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        error: null,
      };
    case SETTINGS_FETCH_DEALERSHIP_INFO_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isLoading: false,
      };
    case SETTINGS_FETCH_DRIVERS_INFO_SUCCESS:
      return {
        ...state,
        drivers: action.payload.drivers,
        driversTotal: action.payload.driversTotal,
        resyncDrivers: false,
        isDriversLoading: false,
        error: null,
      };
    case SETTINGS_FETCH_DRIVERS_INFO_FAILURE:
      return {
        ...state,
        resyncDrivers: false,
        error: action.payload.error,
        isDriversLoading: false,
      };
    case SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_SUCCESS:
      return {
        ...state,
        serviceAdvisors: action.payload.serviceAdvisors,
        serviceAdvisorsTotal: action.payload.serviceAdvisorsTotal,
        isAdvisorsLoading: false,
        error: null,
      };
    case SETTINGS_FETCH_DEALERSHIP_SERVICE_ADVISORS_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isAdvisorsLoading: false,
      };
    case SETTINGS_FETCH_MOBILE_TECHNICIANS_SUCCESS:
      return {
        ...state,
        mobileTechnicians: action.payload.mobileTechnicians,
        mobileTechniciansTotal: action.payload.mobileTechniciansTotal,
        isMobileTechniciansLoading: false,
        error: null,
      };
    case SETTINGS_FETCH_MOBILE_TECHNICIANS_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isMobileTechniciansLoading: false,
      };
    case SETTINGS_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_LOADING_STATE:
      return {
        ...state,
        isActiveServiceAdvisorsLoading: true,
      };
    case SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_SUCCESS:
      return {
        ...state,
        activeAdvisors: action.payload.activeAdvisors,
        isActiveServiceAdvisorsLoading: false,
        error: null,
      };
    case SETTINGS_FETCH_DEALERSHIP_ACTIVE_SERVICE_ADVISORS_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isActiveServiceAdvisorsLoading: false,
      };
    case SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };
    case SETTINGS_UPDATE_DEALERSHIP_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };
    case SETTINGS_UPDATE_DEALERSHIP_INFO_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isLoading: false,
      };
    case SETTINGS_UPDATE_INTEGRATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          integrations: (state.data.integrations).map((integration) => (
            integration.id !== action.payload.id ? integration : {
              ...integration,
              ...action.payload,
              settings: {
                ...integration.settings,
                ...action.payload.settings,
              },
            }
          )),
          remote_enabled: "remote_enabled" in action.payload
            ? action.payload.remote_enabled
            : state.data.remote_enabled,
          mobile_technician_enabled: "mobile_technician_enabled" in action.payload
            ? action.payload.mobile_technician_enabled
            : state.data.mobile_technician_enabled,
        },
        error: null,
      };
    case SETTINGS_UPDATE_INTEGRATION_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
      };
    case SETTINGS_CREATE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          employments: [
            ...state.data.employments,
            action.payload,
          ],
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_UPDATE_USER_SUCCESS: {
      const index = state.data.employments.findIndex(
        ({ id }) => id === action.payload.id,
      );
      const updated = [...state.data.employments];
      updated.splice(index, 1, action.payload);
      return {
        ...state,
        data: {
          ...state.data,
          employments: updated,
        },
        isLoading: false,
        error: null,
      };
    }
    case SETTINGS_UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isLoading: false,
      };
    case SETTINGS_DELETE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          employments: state.data.employments.filter(
            (employment) => employment.id !== action.payload.id,
          ),
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_DELETE_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isLoading: false,
      };
    case SETTINGS_CREATE_TEXT_MESSAGES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          no_show_text_message: action.payload,
        },
      };
    case SETTINGS_UPDATE_TEXT_MESSAGES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          no_show_text_message: action.payload,
        },
      };
    case SETTINGS_UPDATE_TEXT_MESSAGES_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
      };
    case SETTINGS_DELETE_TEXT_MESSAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          no_show_text_message: null,
        },
      };
    case SETTINGS_SET_TEAM_TAGS_LOADING_STATE:
      return {
        ...state,
        isTeamTagsLoading: true,
      };
    case SETTINGS_FETCH_TEXT_MESSAGES_SUCCESS:
      return {
        ...state,
        textMessages: action.payload,
        error: null,
      };
    case SETTINGS_NON_WORKING_DAYS_LOADING_STATE:
      return {
        ...state,
        isNonWorkingDaysLoading: true,
      };
    case SETTINGS_FETCH_NON_WORKING_DAYS_SUCCESS:
      return {
        ...state,
        nonWorkingDays: action.payload,
        isNonWorkingDaysLoading: false,
      };
    case SETTINGS_FETCH_NON_WORKING_DAYS_FAILURE:
      return {
        ...state,
        isNonWorkingDaysLoading: false,
        error: action.payload.error.response,
      };
    case SETTINGS_ADD_NON_WORKING_DAY_SUCCESS:
      return {
        ...state,
        nonWorkingDays: [
          ...state.nonWorkingDays,
          action.payload,
        ],
        isNonWorkingDaysLoading: false,
        error: null,
      };
    case SETTINGS_ADD_NON_WORKING_DAY_FAILURE:
      return {
        ...state,
        isNonWorkingDaysLoading: false,
        error: action.payload.error.response,
      };
    case SETTINGS_DELETE_NON_WORKING_DAY_SUCCESS:
      return {
        ...state,
        nonWorkingDays: state.nonWorkingDays.filter(
          (day) => day.id !== action.payload,
        ),
        isNonWorkingDaysLoading: false,
        error: null,
      };
    case SETTINGS_DELETE_NON_WORKING_DAY_FAILURE:
      return {
        ...state,
        isNonWorkingDaysLoading: false,
        error: action.payload.error.response,
      };
    case SETTINGS_FETCH_TEAM_TAG_SUCCESS:
      return {
        ...state,
        teamTags: action.payload,
        isTeamTagsLoading: false,
        error: null,
      };
    case SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_SUCCESS:
      return {
        ...state,
        responsibleTeamTag: action.payload,
        teamMembersError: false,
      };
    case SETTINGS_FETCH_RESPONSIBLE_TEAM_TAG_ERROR:
      return {
        ...state,
        teamMembersError: true,
        responsibleTeamTag: null,
      };
    case SETTINGS_CREATE_TEAM_TAG_SUCCESS:
      return {
        ...state,
        teamTags: [...state.teamTags, action.payload],
        isTeamTagsLoading: false,
        error: null,
      };
    case SETTINGS_UPDATE_TEAM_TAG_SUCCESS:
      return {
        ...state,
        teamTags: state.teamTags.map(
          (tag) => (tag.id === action.payload.id ? action.payload : tag),
        ),
        isTeamTagsLoading: false,
        error: null,
      };
    case SETTINGS_UPDATE_TEAM_TAGS_SENIORITY_SUCCESS:
      return {
        ...state,
        teamTags: action.payload,
        isTeamTagsLoading: false,
        error: null,
      };
    case SETTINGS_DELETE_TEAM_TAG_SUCCESS: {
      return {
        ...state,
        teamTags: state.teamTags.filter(
          (tag) => tag.id !== action.payload.id,
        ),
        isTeamTagsLoading: false,
        error: null,
      };
    }
    case SETTINGS_FETCH_TEAM_TAG_FAILURE:
    case SETTINGS_CREATE_TEAM_TAG_FAILURE:
    case SETTINGS_UPDATE_TEAM_TAG_FAILURE:
    case SETTINGS_DELETE_TEAM_TAG_FAILURE:
    case SETTINGS_UPDATE_DEALERSHIP_CAPACITY_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isTeamTagsLoading: false,
      };
    case SETTINGS_FETCH_TEAM_ADVISORS:
      return {
        ...state,
        teamMembersLoading: true,
        teamMembersError: null,
      };
    case SETTINGS_FETCH_TEAM_ADVISORS_SUCCESS:
      return {
        ...state,
        teamServiceAdvisors: action.payload.map(
          ({ service_advisor_profile }) => service_advisor_profile,
        ),
        teamMembers: action.payload,
        teamMembersLoading: false,
        teamMembersError: null,
      };
    case SETTINGS_FETCH_TEAM_ADVISORS_ERROR:
      return {
        ...state,
        teamMembersLoading: false,
        teamMembersError: true,
      };
    case SETTINGS_CREATE_KIOSK_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          kiosks: [
            ...state.data.kiosks,
            action.payload,
          ],
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_UPDATE_KIOSK_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          kiosks: [
            ...state.data.kiosks.filter(
              (kiosk) => kiosk.id !== action.payload.id,
            ),
            action.payload,
          ],
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_DELETE_KIOSK_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          kiosks: state.data.kiosks.filter(
            (kiosk) => kiosk.id !== action.payload.id,
          ),
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_ADD_ENCRYPTED_PASSWORD_TO_USERS:
      return {
        ...state,
        data: {
          ...state.data,
          kiosks: state.data.kiosks.map(
            (kiosk) => {
              return {
                ...kiosk,
                encrypted_password: "******",
              };
            },
          ),
          tv_screens: state.data.tv_screens.map(
            (tv) => {
              return {
                ...tv,
                encrypted_password: "******",
              };
            },
          ),
          arrive_users: state.data.arrive_users.map(
            (arriveUser) => {
              return {
                ...arriveUser,
                encrypted_password: "******",
              };
            },
          ),
          remote_users: state.data.remote_users.map(
            (remoteUser) => {
              return {
                ...remoteUser,
                encrypted_password: "******",
              };
            },
          ),
        },
      };
    case SETTINGS_CREATE_TV_SCREEN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          tv_screens: [
            ...state.data.tv_screens,
            action.payload,
          ],
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_UPDATE_TV_SCREEN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          tv_screens: [
            ...state.data.tv_screens.filter(
              (tvScreen) => tvScreen.id !== action.payload.id,
            ),
            action.payload,
          ],
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_DELETE_TV_SCREEN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          tv_screens: state.data.tv_screens.filter(
            (tvScreen) => tvScreen.id !== action.payload.id,
          ),
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_CREATE_ARRIVE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          arrive_users: [
            ...state.data.arrive_users,
            {
              ...action.payload,
              encrypted_password: "******",
            },
          ],
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_UPDATE_ARRIVE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          arrive_users: [
            ...state.data.arrive_users.filter(
              (arriveUser) => arriveUser.id !== action.payload.id,
            ),
            {
              ...action.payload,
              encrypted_password: "******",
            },
          ],
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_DELETE_ARRIVE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          arrive_users: state.data.arrive_users.filter(
            (arriveUser) => (
              arriveUser.id !== action.payload.id
            ),
          ),
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_CREATE_REMOTE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          remote_users: [
            ...state.data.remote_users,
            {
              ...action.payload,
              encrypted_password: "******",
            },
          ],
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_UPDATE_REMOTE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          remote_users: [
            ...state.data.remote_users.filter(
              (remoteUser) => remoteUser.id !== action.payload.id,
            ),
            {
              ...action.payload,
              encrypted_password: "******",
            },
          ],
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_DELETE_REMOTE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          remote_users: state.data.remote_users.filter(
            (remoteUser) => (
              remoteUser.id !== action.payload.id
            ),
          ),
        },
        isLoading: false,
        error: null,
      };
    case SETTINGS_ADVISOR_LOADING_STATE:
      return {
        ...state,
        isAdvisorsLoading: true,
      };
    case SETTINGS_UPDATE_ADVISOR_SUCCESS:
      return {
        ...state,
        error: null,
        serviceAdvisors: state.serviceAdvisors.map(
          (advisor) => (
            advisor.id !== action.payload.id ? advisor : {
              ...advisor,
              ...action.payload,
            }
          ),
        ),
        isAdvisorsLoading: false,
      };
    case SETTINGS_UPDATE_ADVISOR_FAILURE:
      return {
        ...state,
        isAdvisorsLoading: false,
        error: action.payload,
      };
    case SETTINGS_UPDATE_ADVISORS_SUCCESS:
      return {
        ...state,
        error: null,
        activeAdvisors: action.payload,
        isAdvisorsLoading: false,
      };
    case SETTINGS_UPDATE_ADVISORS_FAILURE:
      return {
        ...state,
        isAdvisorsLoading: false,
        error: action.payload,
      };
    case SETTINGS_DRIVER_LOADING_STATE:
      return {
        ...state,
        isDriversLoading: true,
      };
    case SETTINGS_CREATE_DRIVER_SUCCESS:
      return {
        ...state,
        employeeError: null,
        isDriversLoading: false,
        resyncDrivers: true,
      };
    case SETTINGS_CREATE_DRIVER_FAILURE:
      return {
        ...state,
        isDriversLoading: false,
        employeeError: action.payload.error.response,
      };
    case SETTINGS_UPDATE_DRIVER_SUCCESS:
      return {
        ...state,
        isDriversLoading: false,
        employeeError: null,
        drivers: state.drivers.map(
          (driver) => (
            driver.id !== action.payload.id ? driver : {
              ...driver,
              ...action.payload,
            }
          ),
        ),
        data: {
          ...state.data,
          remote_users: action.payload.user_id === null
            ? state.data.remote_users.map(
              (user) => (
                user.name !== action.payload.name ? user : {
                  ...user,
                  name: null,
                }
              ),
            )
            : state.data.remote_users.map(
              (user) => (
                user.id !== action.payload.user_id ? user : {
                  ...user,
                  name: action.payload.name,
                }
              ),
            ),
        },
      };
    case SETTINGS_UPDATE_DRIVER_FAILURE:
      return {
        ...state,
        isDriversLoading: false,
        employeeError: action.payload.error.response,
      };
    case SETTINGS_DELETE_DRIVER_SUCCESS:
      return {
        ...state,
        isDriversLoading: false,
        employeeError: null,
        resyncDrivers: true,
      };
    case SETTINGS_DELETE_DRIVER_FAILURE:
      return {
        ...state,
        isDriversLoading: false,
        employeeError: action.payload.error.response,
      };
    case SETTINGS_MOBILE_TECHNICIANS_LOADING_STATE:
      return {
        ...state,
        isMobileTechniciansLoading: true,
      };
    case SETTINGS_UPDATE_MOBILE_TECHNICIANS_SUCCESS:
      return {
        ...state,
        isMobileTechniciansLoading: false,
        employeeError: null,
        mobileTechnicians: state.mobileTechnicians.map(
          (mobileTechnician) => (
            mobileTechnician.id !== action.payload.id ? mobileTechnician : {
              ...mobileTechnician,
              ...action.payload,
            }
          ),
        ),
        data: {
          ...state.data,
          remote_users: action.payload.user_id === null
            ? state.data.remote_users.map(
              (user) => (
                user.name !== action.payload.name ? user : {
                  ...user,
                  name: null,
                }
              ),
            )
            : state.data.remote_users.map(
              (user) => (
                user.id !== action.payload.user_id ? user : {
                  ...user,
                  name: action.payload.name,
                }
              ),
            ),
        },
      };
    case SETTINGS_UPDATE_MOBILE_TECHNICIANS_FAILURE:
      return {
        ...state,
        isMobileTechniciansLoading: false,
        employeeError: action?.payload?.error?.response || action?.payload?.data,
      };
    case SETTINGS_UPDATE_STYLESHEET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          stylesheet: action.payload.stylesheet,
        },
      };
    case SETTINGS_UPDATE_STYLESHEET_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error.response,
      };
    case SETTINGS_FETCH_EMPLOYEE_VACATIONS:
      return {
        ...state,
        isLoading: false,
        employeeVacations: {
          ...state.employeeVacations,
          [action.payload.employmentId]: action.payload.data,
        },
        vacationError: null,
      };
    case SETTINGS_FETCH_EMPLOYEE_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_CREATE_EMPLOYEE_VACATIONS:
      return {
        ...state,
        isLoading: false,
        employeeVacations: {
          ...state.employeeVacations,
          [action.payload.employmentId]: [
            ...state.employeeVacations[action.payload.employmentId],
            action.payload.data,
          ],
        },
        vacationError: null,
      };
    case SETTINGS_CREATE_EMPLOYEE_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_UPDATE_EMPLOYEE_VACATIONS:
      return {
        ...state,
        isLoading: false,
        employeeVacations: {
          ...state.employeeVacations,
          [action.payload.employmentId]: [
            ...state.employeeVacations[action.payload.employmentId].filter(
              (item) => item.id !== action.payload.vacationId,
            ),
            action.payload.data,
          ],
        },
        vacationError: null,
      };
    case SETTINGS_UPDATE_EMPLOYEE_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_DELETE_EMPLOYEE_VACATIONS:
      return {
        ...state,
        isLoading: false,
        employeeVacations: {
          [action.payload.employmentId]: [
            ...state.employeeVacations[action.payload.employmentId].filter(
              (item) => item.id !== action.payload.vacationId,
            ),
          ],
        },
        vacationError: null,
      };
    case SETTINGS_DELETE_EMPLOYEE_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_FETCH_DRIVER_VACATIONS:
      return {
        ...state,
        isLoading: false,
        driverVacations: {
          ...state.driverVacations,
          [action.payload.driverId]: action.payload.data,
        },
        vacationError: null,
      };
    case SETTINGS_FETCH_DRIVER_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_CREATE_DRIVER_VACATIONS:
      return {
        ...state,
        isLoading: false,
        driverVacations: {
          ...state.driverVacations,
          [action.payload.driverId]: [
            ...state.driverVacations[action.payload.driverId],
            action.payload.data,
          ],
        },
        vacationError: null,
      };
    case SETTINGS_CREATE_DRIVER_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_UPDATE_DRIVER_VACATIONS:
      return {
        ...state,
        isLoading: false,
        driverVacations: {
          ...state.driverVacations,
          [action.payload.driverId]: [
            ...state.driverVacations[action.payload.driverId].filter(
              (item) => item.id !== action.payload.vacationId,
            ),
            action.payload.data,
          ],
        },
        vacationError: null,
      };
    case SETTINGS_UPDATE_DRIVER_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_DELETE_DRIVER_VACATIONS:
      return {
        ...state,
        isLoading: false,
        driverVacations: {
          [action.payload.driverId]: [
            ...state.driverVacations[action.payload.driverId].filter(
              (item) => item.id !== action.payload.vacationId,
            ),
          ],
        },
        vacationError: null,
      };
    case SETTINGS_DELETE_DRIVER_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS:
      return {
        ...state,
        isLoading: false,
        mobileTechniciansVacations: {
          ...state.mobileTechniciansVacations,
          [action.payload.mobileTechnicianId]: action.payload.data,
        },
        vacationError: null,
      };
    case SETTINGS_FETCH_MOBILE_TECHNICIANS_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS:
      return {
        ...state,
        isLoading: false,
        mobileTechniciansVacations: {
          ...state.mobileTechniciansVacations,
          [action.payload.mobileTechnicianId]: [
            ...state.mobileTechniciansVacations[action.payload.mobileTechnicianId],
            action.payload.data,
          ],
        },
        vacationError: null,
      };
    case SETTINGS_CREATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS:
      return {
        ...state,
        isLoading: false,
        mobileTechniciansVacations: {
          ...state.mobileTechniciansVacations,
          [action.payload.mobileTechnicianId]: [
            ...state.mobileTechniciansVacations[action.payload.mobileTechnicianId].filter(
              (item) => item.id !== action.payload.vacationId,
            ),
            action.payload.data,
          ],
        },
        vacationError: null,
      };
    case SETTINGS_UPDATE_MOBILE_TECHNICIANS_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    case SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS:
      return {
        ...state,
        isLoading: false,
        mobileTechniciansVacations: {
          [action.payload.mobileTechnicianId]: [
            ...state.mobileTechniciansVacations[action.payload.mobileTechnicianId].filter(
              (item) => item.id !== action.payload.vacationId,
            ),
          ],
        },
        vacationError: null,
      };
    case SETTINGS_DELETE_MOBILE_TECHNICIANS_VACATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        vacationError: action.payload.error.response,
      };
    default:
      return state;
  }
};

export default settings;
