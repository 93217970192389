import React, { useState } from "react";
import {
  arrayOf, bool, number, shape,
} from "prop-types";
import { decisionTreePropType } from "shared/prop-types";
import { isEmpty, last, transpose } from "ramda";
import Block from "components/common/Block";
import downIcon from "assets/images/down.svg";
import upIcon from "assets/images/up.svg";
import serviceIconsMap from "shared/serviceIconsMap";
import uniqid from "uniqid";

import styles from "../styles.module.scss";

const SelectedServices = ({
  booking,
  showAdditionalInfo,
}) => {
  const [openItemIndex, setOpenItemIndex] = useState(null);

  const setOpenItem = (index) => {
    setOpenItemIndex(openItemIndex === index ? null : index);
  };

  const getAccordionWithData = () => {
    const { decision_tree_results, bookings_menu_items } = booking;
    return (
      <>
        {(bookings_menu_items || []).map((menuItem, index) => {
          const isCurrentlySelected = openItemIndex === index;
          const correspondingDecisionTree = last((decision_tree_results || [])
            .filter((tree) => tree.menu_item_id === menuItem.menu_item_id)) || {};
          const answerIndexes = (
            correspondingDecisionTree.details || [])
            .map((treeItem, treeItemIndex) => (treeItem.source === "SOURCE_USER" ? treeItemIndex : null))
            .filter((treeItem) => treeItem !== null);
          const questionIndexes = answerIndexes.map((answerIndex) => answerIndex - 1);
          const dialog = transpose([questionIndexes, answerIndexes]);
          let { kind } = menuItem;
          const { vin_specific } = menuItem;
          const isEmptyContent = !isEmpty(dialog) || menuItem.additional_note;

          if (kind === "recall" && vin_specific) {
            kind = "vinSpecificRecall";
          }

          return (
            <section key={menuItem.id}>
              <div className={styles.section}>
                <div className={styles.service}>
                  <img
                    src={serviceIconsMap[kind]}
                    className={styles.icon}
                    alt=""
                  />
                </div>
                <div className={styles.service}>
                  <span className={styles.name}>{menuItem.name}</span>
                  {showAdditionalInfo && (
                    <>
                      <div className={styles.info}>
                        <span className={styles.infoLabel}>Opcode:</span>
                        <span className={styles.infoValue}>{menuItem.op_code}</span>
                      </div>
                      <div className={styles.info}>
                        <span className={styles.infoLabel}>Allocated time:</span>
                        <span className={styles.infoValue}>{menuItem.allocated_labor_time}</span>
                      </div>
                    </>
                  )}
                  <div className={styles.info}>
                    <span className={styles.infoLabel}>Price:</span>
                    <span className={styles.infoValue}>{`$${Number(menuItem.price)}`}</span>
                  </div>
                  {showAdditionalInfo && menuItem.comments && (
                    <div className={styles.info}>
                      <span className={styles.infoLabel}>Comment:</span>
                      <span className={styles.infoValue}>{menuItem.comments}</span>
                    </div>
                  )}
                </div>
                {isEmptyContent && (
                  <div className={styles.toggle}>
                    <img
                      src={isCurrentlySelected ? upIcon : downIcon}
                      alt="concierge toggle"
                      onClick={() => setOpenItem(index)}
                    />
                  </div>
                )}
              </div>
              {isCurrentlySelected
                && (
                  <section className={styles.accordionItemContent}>
                    {dialog.map((indexPair) => (
                      <section className={styles.questionBlock} key={uniqid()}>
                        <span className={styles.questionBlockValue}>
                          {`${correspondingDecisionTree.details[indexPair[0]].text} /`}
                        </span>
                        <span className={styles.questionBlockAnswer}>
                          {correspondingDecisionTree.details[indexPair[1]].text}
                        </span>
                      </section>
                    ))}
                    {menuItem.additional_note && (
                      <section className={styles.questionBlock}>
                        <span className={styles.questionBlockValue}>
                          Customer states:
                        </span>
                        <span className={styles.questionBlockAnswer}>
                          {menuItem.additional_note}
                        </span>
                      </section>
                    )}
                  </section>
                )}
            </section>
          );
        })}
      </>
    );
  };

  return (
    <section>
      {isEmpty(booking.bookings_menu_items) && (
        <Block>
          No services specified
        </Block>
      )}
      {!isEmpty(booking.bookings_menu_items) && (getAccordionWithData())}
    </section>
  );
};

SelectedServices.propTypes = {
  booking: shape({
    menu_items: arrayOf(number).isRequired,
    decision_tree_results: decisionTreePropType,
  }).isRequired,
  showAdditionalInfo: bool,
};

SelectedServices.defaultProps = {
  showAdditionalInfo: false,
};

export default SelectedServices;
