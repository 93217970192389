import React, {
  useEffect, useMemo, useRef, useState,
} from "react";
import {
  arrayOf, bool, func, number, shape, string,
} from "prop-types";
import { useOutsideClick } from "shared/hooks";
import cx from "classnames";
import upIcon from "assets/images/up.svg";
import downIcon from "assets/images/down.svg";
import checkedIcon from "assets/images/checked.svg";
import uncheckedIcon from "assets/images/unchecked.svg";
import addIcon from "assets/images/addWhite.svg";
import "./styles.scss";

const MultiTagSelect = ({
  label,
  value,
  options,
  onChange,
  changeVisibilityAction,
  isTeams,
  disabled,
  isMake,
}) => {
  const [optionsByHash, setOptionsByHash] = useState(
    options.reduce((obj, option) => {
      return {
        ...obj,
        [option.id]: {
          ...option,
          selected: false,
        },
      };
    }, {}),
  );
  const [isAddSelectActive, setIsAddSelectActive] = useState(false);
  const [selectInput, setSelectInput] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => {
    setIsSelectOpen(false);
    changeVisibilityAction(false);
    setIsAddSelectActive(false);
  });

  useEffect(() => {
    const result = Object.keys(optionsByHash).reduce((acc, optionId) => {
      const preparedId = isMake ? optionId : Number(optionId);
      const isSelected = value.some((option) => option.id === preparedId);
      acc[optionId] = {
        ...optionsByHash[optionId],
        selected: isSelected,
      };
      return acc;
    }, {});

    setOptionsByHash(result);
  }, [value]);

  const searchedOptions = useMemo(
    () => Object
      .values(optionsByHash)
      .filter(({ name, role }) => {
        if (!name) {
          return false;
        }

        return isTeams
          ? name.toLowerCase().includes(selectInput.toLowerCase()) && role === "advisor"
          : name.toLowerCase().includes(selectInput.toLowerCase());
      }),
    [optionsByHash, selectInput],
  );

  const isAllSelected = useMemo(
    () => searchedOptions.every(({ selected }) => selected),
    [optionsByHash, selectInput],
  );

  const toggleOption = (optionId) => {
    const newOptionsByHash = {
      ...optionsByHash,
      [optionId]: {
        ...optionsByHash[optionId],
        selected: !optionsByHash[optionId].selected,
      },
    };
    setOptionsByHash(newOptionsByHash);
    const selectedOptions = Object.values(newOptionsByHash).filter(
      ({ selected }) => selected,
    );
    onChange(selectedOptions);
  };

  const handleSelectOpen = (isOpen) => {
    setIsSelectOpen(isOpen);
    changeVisibilityAction(isOpen);
  };

  const toggleSelectAllOptions = () => {
    let newOptionsByHash = { ...optionsByHash };
    searchedOptions.forEach((option) => {
      newOptionsByHash = {
        ...newOptionsByHash,
        [option.id]: {
          ...option,
          selected: !isAllSelected,
        },
      };
    });
    setOptionsByHash(newOptionsByHash);
    const selectedOptions = Object.values(newOptionsByHash).filter(
      ({ selected }) => selected,
    );
    onChange(selectedOptions);
    setIsAddSelectActive(false);
  };

  const renderSelect = () => (
    <div className={cx("multiTagList", {
      multiTagListOutlined: label !== undefined,
    })}
    >
      <div className="multiTagListInner">
        {Object.values(optionsByHash)
          .map(({
            id, name, selected, isPhantom, role,
          }) => (selected ? (
            <div
              key={id}
              className={cx("multiTagListItem multiTagListItemGap", {
                isPhantom,
                notAdvisor: isTeams && role !== "advisor",
                multiTagListItemMake: isMake,
              })}
            >
              <div className="multiTagListItemName">{name}</div>
              {!disabled && (
                <button
                  type="button"
                  className="multiTagListItemRemove"
                  onClick={() => toggleOption(id)}
                >
                  <span>+</span>
                </button>
              )}
            </div>
          ) : null))}
        {isAddSelectActive && (
          <div
            className="multiTagSelect multiTagListItemGap"
            ref={wrapperRef}
          >
            <div className="multiTagSelectControl">
              <input
                type="text"
                className="multiTagSelectInput"
                value={selectInput}
                onFocus={() => handleSelectOpen(true)}
                onChange={(e) => setSelectInput(e.target.value)}
              />
              <button
                type="button"
                className="multiTagSelectIndicator"
                onClick={() => handleSelectOpen(!isSelectOpen)}
              >
                <img
                  src={isSelectOpen ? upIcon : downIcon}
                  alt="toggle menu"
                />
              </button>
            </div>
            <div
              className={cx("multiTagSelectMenu", {
                multiTagSelectMenuOpen: isSelectOpen,
              })}
            >
              {isSelectOpen && (
                <>
                  {!isMake && (
                    <>
                      <button
                        type="button"
                        className={cx("multiTagSelectMenuItem multiTagSelectMenuSelectAll", {
                          multiTagSelectMenuItemSelected: isAllSelected,
                        })}
                        onClick={toggleSelectAllOptions}
                      >
                        <img
                          className="multiTagSelectMenuItemCheckbox"
                          src={isAllSelected ? checkedIcon : uncheckedIcon}
                          alt=""
                        />
                        <div className="multiTagSelectMenuItemLabel">SELECT ALL</div>
                      </button>
                      <div className="multiTagSelectMenuItemSeparator">&nbsp;</div>
                    </>
                  )}
                  <div className="multiTagSelectMenuList">
                    {searchedOptions.map(({
                      id, name, selected, isPhantom,
                    }) => (
                      <button
                        type="button"
                        key={id}
                        className={cx("multiTagSelectMenuItem", {
                          multiTagSelectMenuItemSelected: selected,
                          isPhantom,
                        })}
                        onClick={() => {
                          toggleOption(id);
                          setIsAddSelectActive(false);
                        }}
                      >
                        <img
                          className="multiTagSelectMenuItemCheckbox"
                          src={selected ? checkedIcon : uncheckedIcon}
                          alt=""
                        />
                        <div className="multiTagSelectMenuItemLabel">{name}</div>
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {!isAddSelectActive && !disabled && (
          <button
            type="button"
            className="multiTagSelectActionButton multiTagListItemGap"
            onClick={() => {
              setIsAddSelectActive(true);
              setIsSelectOpen(false);
            }}
          >
            <img alt="add" src={addIcon} />
          </button>
        )}
      </div>
    </div>
  );

  if (!label) {
    renderSelect();
  }

  return (
    <div className="multiTagListOuter">
      <div className="multiTagListLabel">{label}</div>
      {renderSelect()}
    </div>
  );
};

MultiTagSelect.propTypes = {
  label: string,
  onChange: func.isRequired,
  value: arrayOf(shape({
    name: string,
    id: number,
  })),
  options: arrayOf(shape({
    name: string,
    id: number,
  })),
  changeVisibilityAction: func,
  isTeams: bool,
  disabled: bool,
  isMake: bool,
};

MultiTagSelect.defaultProps = {
  label: null,
  options: [],
  value: [],
  isTeams: false,
  disabled: false,
  isMake: false,
  changeVisibilityAction: () => {},
};

export default MultiTagSelect;
