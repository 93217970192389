import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import ClipLoader from "react-spinners/ClipLoader";
import {
  arrayOf, bool, func, node, number, objectOf, string,
} from "prop-types";
import dateFns from "date-fns";
import { chosenTimeSlotPropType, daySlotPropType, serviceAdvisorPropType } from "shared/prop-types";
import { ReactComponent as IconArrowLeft } from "assets/images/arrow/left.svg";
import { ReactComponent as IconArrowRight } from "assets/images/arrow/right.svg";
import Alert from "components/common/Alert";
import TimePicker from "./TimePicker";

import "./styles.scss";

const TimeOfArrivalPicker = ({
  timeSlotsLoading,
  fetchDays,
  initialAdvisorId,
  selectedAdvisor,
  days,
  chosenTimeSlot,
  baseTimeSlot,
  onSetTimeSlot,
  onReset,
  isPickUp,
  isMobileTechnician,
  activePickupJob,
  activeMobileTechnicianJob,
  isScheduling,
  initialTime,
  remoteJobData,
  storeRemoteJobData,
  dropOffType,
}) => {
  const [firstInit, setFirstInit] = useState(true);
  const [dateInit, setDateInit] = useState(false);
  const [allowResync, setAllowResync] = useState(isScheduling);
  const [currentDate, setCurrentDate] = useState(
    dateFns.parse(chosenTimeSlot?.day?.full_date || new Date(), "YYYY-MM-DD"),
  );
  const [time, setTime] = useState(chosenTimeSlot.quarterSlot);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate] = useState(new Date(
    minDate.getFullYear() + 3,
    minDate.getMonth(),
    minDate.getDate(),
  ));
  const [timePickerHeight, setTimePickerHeight] = useState(0);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    if (!dateInit) {
      if (dropOffType && (baseTimeSlot?.day?.full_date || initialTime)) {
        if (!chosenTimeSlot?.day?.full_date) {
          if (baseTimeSlot?.day?.full_date) {
            setCurrentDate(dateFns.parse(baseTimeSlot?.day?.full_date, "YYYY-MM-DD"));
          } else if (initialTime) {
            setCurrentDate(dateFns.parse(dateFns.format(initialTime, "YYYY-MM-DD")));
          }
        } else {
          setCurrentDate(dateFns.parse(chosenTimeSlot?.day?.full_date, "YYYY-MM-DD"));
        }

        if (baseTimeSlot?.day?.full_date) {
          setMinDate(dateFns.parse(baseTimeSlot?.day?.full_date, "YYYY-MM-DD"));
        } else if (initialTime) {
          setMinDate(dateFns.parse(dateFns.format(initialTime, "YYYY-MM-DD")));
        }
      } else {
        setMinDate(new Date());

        if (!initialTime && !chosenTimeSlot?.day?.full_date) {
          setCurrentDate(dateFns.parse(dateFns.parse(new Date(), "YYYY-MM-DD")));
        } else if (chosenTimeSlot?.day?.full_date) {
          setCurrentDate(dateFns.parse(chosenTimeSlot?.day?.full_date, "YYYY-MM-DD"));
        } else {
          setCurrentDate(dateFns.parse(dateFns.format(initialTime, "YYYY-MM-DD")));
        }
      }
      setDateInit(true);
    }
  }, [dropOffType, baseTimeSlot, chosenTimeSlot, initialTime]);

  const getDayByDate = (activeStartDate, date, view) => {
    if (view && view !== "month") {
      return true;
    }

    const day = days.find((el) => el.full_date === dateFns.format(date, "YYYY-MM-DD"));

    if (day) {
      const slots = Object.entries(day.slots).reduce((result, entry) => {
        if (entry[1].length > 0) {
          return result.concat(entry[0]);
        }

        return result;
      }, []);

      if (
        initialTime && (
          (initialAdvisorId === selectedAdvisor.id && !isPickUp && !isMobileTechnician) || (
            (isPickUp && activePickupJob) || (isMobileTechnician && activeMobileTechnicianJob)
          )
        )
      ) {
        const initialFullDate = dateFns.format(initialTime, "YYYY-MM-DD");
        const initialQuarterSlot = dateFns.format(initialTime, "HH:mm");

        if (initialFullDate === day.full_date && slots.indexOf(initialQuarterSlot) === -1) {
          slots.push(initialQuarterSlot);
        }
      }

      if (slots.length > 0) {
        return {
          ...day,
          slots: Object.entries(day.slots).reduce((result, entry) => {
            if (entry[1].length > 0) {
              return result.concat(entry[0]);
            }

            return result;
          }, []),
        };
      }
    }

    return {
      id: +date,
      short_day_name: dateFns.format(date, "ddd").toLocaleLowerCase(),
      day_name: dateFns.format(date, "dddd"),
      day_of_month: dateFns.format(date, "MMMM, D"),
      full_date: dateFns.format(date, "YYYY-MM-DD"),
      slots: [],
      overcapacity: true,
    };
  };

  const timeSlots = () => {
    let result = [];

    if (currentDate) {
      const day = days.find((el) => el.full_date === dateFns.format(currentDate, "YYYY-MM-DD"));
      result = day ? Object.keys(day.slots) : [];
    }

    if (dropOffType) {
      const baselFullDate = baseTimeSlot?.day?.full_date;
      const baseQuarterSlot = baseTimeSlot?.quarterSlot;
      const selectedDate = dateFns.format(currentDate, "YYYY-MM-DD");

      if (baselFullDate === selectedDate) {
        const filteredResult = [];
        const baseTimeParts = baseQuarterSlot.split(":");
        const baseTimeInMinutes = Number(baseTimeParts[0]) * 60 + Number(baseTimeParts[1]);

        result.forEach((item) => {
          const itemTimeParts = item.split(":");
          const itemTimeInMinutes = Number(itemTimeParts[0]) * 60 + Number(itemTimeParts[1]);
          const diff = itemTimeInMinutes - baseTimeInMinutes - 240;

          if (diff >= 0) {
            filteredResult.push(item);
          }
        });

        result = filteredResult;
      }
    }

    if (
      initialTime && (
        (initialAdvisorId === selectedAdvisor.id && !isPickUp && !isMobileTechnician) || (
          (isPickUp && activePickupJob) || (isMobileTechnician && activeMobileTechnicianJob)
        )
      )
    ) {
      const initialFullDate = dateFns.format(initialTime, "YYYY-MM-DD");
      const initialQuarterSlot = dateFns.format(initialTime, "HH:mm");
      const selectedDate = dateFns.format(currentDate, "YYYY-MM-DD");

      if (initialFullDate === selectedDate && result.indexOf(initialQuarterSlot) === -1) {
        result.push(initialQuarterSlot);
      }
    }

    return result.sort();
  };

  useEffect(() => {
    if (firstInit) {
      if (chosenTimeSlot.day) {
        const slots = timeSlots();
        const {
          day,
          overcapacity,
          quarterSlot,
          walkin,
        } = chosenTimeSlot;
        const capacityValid = slots.indexOf(quarterSlot) !== -1 || isScheduling;
        const selectedDate = dateFns.parse(day.full_date, "YYYY-MM-DD");

        setCurrentDate(selectedDate);
        setTime(quarterSlot);

        if (capacityValid) {
          onSetTimeSlot(quarterSlot, getDayByDate(null, selectedDate, null), overcapacity, walkin);
        } else {
          if (slots.length > 0) {
            setShowWarningMessage(true);
          }
          onReset();
        }

        setFirstInit(false);
      } else {
        let selectedDate = dropOffType
          ? new Date(
            baseTimeSlot?.day?.full_date
              ? `${baseTimeSlot?.day?.full_date}T00:00`
              : dateFns.format(initialTime, "YYYY-MM-DD"),
          )
          : null;
        let selectedQuarterSlot = null;

        if (isScheduling) {
          const slotsByDates = {};
          const isCurrentDate = () => baseTimeSlot?.day?.full_date === dateFns.format(currentDate, "YYYY-MM-DD");

          days.forEach((day) => {
            const entries = Object.entries(day.slots);

            slotsByDates[day.full_date] = entries.filter(([, value]) => (
              value.length > 0
            ));

            if (!selectedQuarterSlot) {
              if (slotsByDates[day.full_date].length > 0 && !selectedDate) {
                selectedDate = new Date(`${day.full_date}T00:00`);
                // eslint-disable-next-line prefer-destructuring
                selectedQuarterSlot = slotsByDates[day.full_date][0][0];
              } else if (slotsByDates[day.full_date].length > 0 && selectedDate && !dropOffType) {
                // eslint-disable-next-line prefer-destructuring
                selectedQuarterSlot = slotsByDates[day.full_date][0][0];
              } else if (isCurrentDate() && timeSlots().length > 0 && dropOffType) {
                // eslint-disable-next-line prefer-destructuring
                selectedQuarterSlot = timeSlots()[0];
              }
            }
          });

          if (!selectedDate && !selectedQuarterSlot && allowResync) {
            let month = new Date().getMonth() + 2;
            let year = new Date().getFullYear();

            if (month === 13) {
              month = 1;
              year += 1;
            }

            fetchDays(
              (
                selectedAdvisor.availableAdvisorIds
              || selectedAdvisor.service_advisor_id
              || selectedAdvisor.id
              ),
              month,
              year,
              false,
              dropOffType,
            );
          }
        }

        setCurrentDate(selectedDate);
        setTime(selectedQuarterSlot);

        if (selectedDate && selectedQuarterSlot) {
          onSetTimeSlot(selectedQuarterSlot, getDayByDate(null, selectedDate, null));
        } else {
          setAllowResync(false);
        }
      }
    }
  }, [chosenTimeSlot, days]);

  useEffect(() => {
    setTimePickerHeight(calendarRef.current.clientHeight);
  }, []);

  const onActiveStartDateChange = ({ activeStartDate, view }) => {
    if (view !== "year") {
      const date = new Date(activeStartDate);
      const selectedMonth = date.getMonth() + 1;
      const selectedYear = date.getFullYear();

      setFirstInit(false);

      fetchDays(
        (
          selectedAdvisor.availableAdvisorIds
          || selectedAdvisor.service_advisor_id
          || selectedAdvisor.id
        ),
        selectedMonth,
        selectedYear,
        false,
        dropOffType,
      );

      onReset();
      setCurrentDate(null);
      setTime(null);
      setTimePickerHeight(calendarRef.current.clientHeight);
    }
  };

  const onChangeDate = (changeDate) => {
    setCurrentDate(changeDate);
    setTime(null);
    setShowWarningMessage(false);
  };

  const onSetTime = (t, overcapacity = false, walkin = false) => {
    if (t === time) {
      setTime(null);
    } else {
      setTime(t);
      onSetTimeSlot(t, getDayByDate(null, currentDate, null), overcapacity, walkin);
    }

    if (dropOffType) {
      storeRemoteJobData({
        ...remoteJobData,
        dropOff: {
          ...(remoteJobData?.dropOff || {}),
          mainDriverId: null,
          coDriverId: null,
        },
        mobileTechnician: {
          ...(remoteJobData?.mobileTechnician || {}),
          technician: null,
        },
      });
    } else {
      storeRemoteJobData({
        ...remoteJobData,
        pickUp: {
          ...(remoteJobData?.pickUp || {}),
          mainDriverId: null,
          coDriverId: null,
        },
        dropOff: {
          ...(remoteJobData?.dropOff || {}),
          mainDriverId: null,
          coDriverId: null,
        },
        mobileTechnician: {
          ...(remoteJobData?.mobileTechnician || {}),
          technician: null,
        },
      });
    }
  };

  const availableTimeSlots = () => {
    let result = [];

    if (currentDate) {
      const day = days.find((el) => el.full_date === dateFns.format(currentDate, "YYYY-MM-DD"));

      result = day
        ? Object.entries(day.slots).reduce((items, entry) => {
          if (entry[1].length > 0) {
            return items.concat(entry[0]);
          }

          return items;
        }, [])
        : [];
    }

    if (
      initialTime && (
        (initialAdvisorId === selectedAdvisor.id && !isPickUp && !isMobileTechnician) || (
          (isPickUp && activePickupJob) || (isMobileTechnician && activeMobileTechnicianJob)
        )
      )
    ) {
      const initialFullDate = dateFns.format(initialTime, "YYYY-MM-DD");
      const initialQuarterSlot = dateFns.format(initialTime, "HH:mm");
      const selectedDate = dateFns.format(currentDate, "YYYY-MM-DD");

      if (initialFullDate === selectedDate && result.indexOf(initialQuarterSlot) === -1) {
        result.push(initialQuarterSlot);
      }
    }

    return result;
  };

  return (
    <>
      {showWarningMessage && <Alert className="appointmentError" variant="error" text="Picked slot is not available." />}
      <div className="pickerWrapper">
        <div className="pickerColumn">
          <div className="pickerColumnTitle">Pick the date</div>
          <Calendar
            inputRef={calendarRef}
            onActiveStartDateChange={onActiveStartDateChange}
            onChange={onChangeDate}
            value={currentDate}
            minDate={minDate}
            maxDate={maxDate}
            locale="en-US"
            calendarType="US"
            className="customCalendar"
            defaultValue={currentDate}
            minDetail="year"
            prevLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            formatShortWeekday={(locale, d) => dateFns.format(d, "dd")}
            formatMonthYear={(locale, d) => dateFns.format(d, "MMMM, YYYY")}
            tileDisabled={({ activeStartDate, date, view }) => (
              !getDayByDate(activeStartDate, date, view)
            )}
            tileClassName={({ activeStartDate, date, view }) => (
              getDayByDate(activeStartDate, date, view).overcapacity ? "tileOvercapacity" : ""
            )}
          />
        </div>
        <div className="pickerColumn">
          <div className="pickerColumnTitle">Available hours</div>
          <TimePicker
            slots={timeSlots()}
            currentDate={currentDate}
            timeArr={!getDayByDate(null, currentDate, null) ? [] : availableTimeSlots()}
            time={(selectedAdvisor?.service_advisor_id || selectedAdvisor?.id !== "firstAvailable") ? time : null}
            setTime={onSetTime}
            height={timePickerHeight}
            firstAvailable={selectedAdvisor?.id === "firstAvailable"}
            isPickUp={isPickUp}
            isMobileTechnician={isMobileTechnician}
          />
        </div>
        {timeSlotsLoading && (
          <div className="loadingContainer">
            <span className="dataEditionModalLoader font-ibm">
              <ClipLoader size={40} color="#0bcaf9" />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

TimeOfArrivalPicker.propTypes = {
  timeSlotsLoading: bool.isRequired,
  fetchDays: func.isRequired,
  initialAdvisorId: number,
  selectedAdvisor: serviceAdvisorPropType,
  days: arrayOf(daySlotPropType).isRequired,
  chosenTimeSlot: chosenTimeSlotPropType.isRequired,
  baseTimeSlot: chosenTimeSlotPropType.isRequired,
  onSetTimeSlot: func.isRequired,
  onReset: func.isRequired,
  isPickUp: bool.isRequired,
  isMobileTechnician: bool.isRequired,
  isScheduling: bool.isRequired,
  initialTime: string,
  remoteJobData: objectOf(node),
  storeRemoteJobData: func.isRequired,
  activePickupJob: objectOf(node),
  activeMobileTechnicianJob: objectOf(node),
  dropOffType: bool,
};

TimeOfArrivalPicker.defaultProps = {
  initialAdvisorId: null,
  selectedAdvisor: null,
  initialTime: null,
  remoteJobData: {},
  activePickupJob: null,
  activeMobileTechnicianJob: null,
  dropOffType: false,
};

export default TimeOfArrivalPicker;
