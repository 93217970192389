import { connect } from "react-redux";

import {
  settingsMobileTechniciansVacations,
  settingsTimezoneSelector,
  settingsVacationError,
} from "store/selectors/settings-selectors";

import {
  addMobileTechniciansVacations,
  deleteMobileTechniciansVacations,
  getMobileTechniciansVacations,
  updateMobileTechniciansVacations,
} from "store/actions/settings-actions";

import VacationsModal from "./index";

const mapStateToProps = (state) => {
  return {
    timezone: settingsTimezoneSelector(state),
    vacations: settingsMobileTechniciansVacations(state),
    vacationError: settingsVacationError(state),
  };
};

const actions = {
  getVacationsAction: getMobileTechniciansVacations,
  addVacationsAction: addMobileTechniciansVacations,
  updateVacationsAction: updateMobileTechniciansVacations,
  deleteVacationsAction: deleteMobileTechniciansVacations,
};

export default connect(mapStateToProps, actions)(VacationsModal);
