import React, { useEffect, useState } from "react";
import cx from "classnames";
import {
  arrayOf,
  func,
  number,
  shape,
  string,
} from "prop-types";
import { connect } from "react-redux";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
import {
  deleteMenuItem,
  toggleMenuItemPublic,
} from "store/actions/menu-template-actions";
import { settingsIntegrationsSelector } from "store/selectors/settings-selectors";
import { menuItemPropType, menuTemplatePropType } from "shared/prop-types";
import menuIcon from "assets/images/menu.svg";
import editIcon from "assets/images/edit.svg";
import deleteIcon from "assets/images/delete.svg";
import duplicateIcon from "assets/images/duplicate.svg";
import { ReactComponent as AutoAddRO } from "assets/icons/auto_ro.svg";
import { ReactComponent as AutoAddMobileRO } from "assets/icons/mobile-technician.svg";
import { ReactComponent as AutoAddScheduled } from "assets/icons/pro-active.svg";
import Modal from "components/common/Modal";
import Panel from "components/common/Panel";
import MenuItemDetails from "./MenuItemDetails";
import EditModal from "./EditModal";

import style from "./styles.module.scss";

const MenuItem = ({
  onDeleteMenuItem,
  onDuplicate,
  menuTemplate,
  menuItem,
  onTogglePublic,
  integrations,
}) => {
  const [actionsPopupOpen, setActionsPopupOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addToEveryInhouseRO, setAddToEveryInhouseRO] = useState(false);
  const [addToEveryPickupRO, setAddToEveryPickupRO] = useState(false);
  const [addToEveryMobileRO, setAddToEveryMobileRO] = useState(false);
  const [isDefaultPublic, setIsDefaultPublic] = useState(false);
  const [enableToggleAction, setEnableToggleAction] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [availableForProactive, setAvailableForProactive] = useState(false);

  useEffect(() => {
    setAddToEveryInhouseRO(menuItem.default_inhouse_repair_order_item);
    setAddToEveryMobileRO(menuItem.default_mobile_repair_order_item);
    setAddToEveryPickupRO(menuItem.default_pickup_repair_order_item);
    setIsDefaultPublic(menuItem.publicly_available);
    setAvailableForProactive(menuItem.available_for_proactive);
  }, [menuItem]);

  const mobileTechnicianIntegrationPresent = () => (
    integrations.filter((integration) => integration.kind === "mobile_technician").length > 0
  );

  const isMobileMenuItem = () => (
    (menuItem.team_tags
      ? menuItem.team_tags.filter((team) => team.mobile).length > 0
      : false) && mobileTechnicianIntegrationPresent()
  );

  const isAddedToEveryRO = () => (
    addToEveryInhouseRO || addToEveryPickupRO || addToEveryMobileRO
  );

  const headerElement = () => (
    <div className={style.header}>
      <div className={style.title}>{`${menuItem.op_code || ""} - ${menuItem.name || ""}`}</div>
      <div className={style.actionsContainer}>
        {isMobileMenuItem() && (
          <AutoAddMobileRO
            className={style.icon}
            data-for="icons-description"
            data-tip="Mobile technician"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
        )}
        {isAddedToEveryRO() && (
          <AutoAddRO
            className={style.icon}
            data-for="icons-description"
            data-tip="Added to every RO"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
        )}
        {availableForProactive && (
          <AutoAddScheduled
            className={style.icon}
            data-for="icons-description"
            data-tip="Available for Proactive Scheduling"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
        )}
        <div className={style.switch}>
          <label
            className={style.switchLabel}
            htmlFor="add-publicly"
          >
            Public
          </label>
          <div
            className={style.switchContainer}
            onMouseEnter={() => setEnableToggleAction(false)}
            onMouseLeave={() => setEnableToggleAction(true)}
          >
            <Switch
              id="publicly-switch"
              onChange={() => {
                onTogglePublic(menuItem.id, !isDefaultPublic);
                setIsDefaultPublic(!isDefaultPublic);
              }}
              checked={isDefaultPublic}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="add-publicly"
            />
          </div>
        </div>
        <button
          type="button"
          className={style.actionsButton}
          onMouseEnter={() => setEnableToggleAction(false)}
          onMouseLeave={() => setEnableToggleAction(true)}
          onClick={() => setActionsPopupOpen(!actionsPopupOpen)}
        >
          <img alt="actions" src={menuIcon} />
        </button>
        {showTooltip && (
          <ReactTooltip
            id="icons-description"
            className={style.tooltip}
            delayShow={100}
            place="bottom"
            backgroundColor="#F9FAFC"
            textColor="black"
            border
            borderColor="#dedee0"
          />
        )}
      </div>
    </div>
  );

  return (
    <div className={style.menuItem}>
      <Panel
        className={cx({
          [style.menuItemRed]: menuItem?.op_code && menuItem.op_code.toLowerCase().indexOf("carmen") !== -1,
        })}
        isToggle
        enableToggleAction={enableToggleAction}
        header={headerElement()}
      >
        <MenuItemDetails
          menuItem={menuItem}
          menuTemplate={menuTemplate}
        />
      </Panel>
      {actionsPopupOpen && (
        <>
          <div className={style.popup}>
            <button
              type="button"
              className={style.action}
              onClick={() => {
                setEditModalOpen(true);
                setActionsPopupOpen(false);
              }}
            >
              <img alt="edit" src={editIcon} />
              Edit
            </button>
            <button
              type="button"
              className={style.action}
              onClick={() => {
                setActionsPopupOpen(!actionsPopupOpen);
                onDuplicate();
              }}
            >
              <img alt="duplicate" src={duplicateIcon} />
              Duplicate
            </button>
            <button
              type="button"
              className={style.delete}
              onClick={() => {
                setDeleteModalOpen(true);
                setActionsPopupOpen(false);
              }}
            >
              <img alt="delete" src={deleteIcon} />
              Delete
            </button>
          </div>
          <button
            type="button"
            className={style.outsideOverlay}
            onClick={() => {
              setEnableToggleAction(false);
              setActionsPopupOpen(false);
            }}
          />
        </>
      )}
      {editModalOpen && (
        <EditModal
          id={menuItem.service_id}
          menuItemId={menuItem.id}
          menuTemplateId={menuTemplate.id}
          kind={menuItem.kind}
          name={menuItem.name}
          opCode={menuItem.op_code}
          onClose={() => setEditModalOpen(false)}
        />
      )}
      {deleteModalOpen && (
        <Modal
          title="Wait. Are you sure?"
          text="Are you sure you want to delete this item and all of its vehicle groups / decision trees?"
          cancelButtonText="No"
          submitButtonText="Yes"
          submitButtonVariant="destructive"
          cancelButtonVariant="dark"
          size="small"
          onSubmit={() => onDeleteMenuItem(menuTemplate.id, menuItem.id)}
          onCancel={() => setDeleteModalOpen(false)}
        />
      )}
    </div>
  );
};

MenuItem.propTypes = {
  menuTemplate: menuTemplatePropType.isRequired,
  menuItem: menuItemPropType.isRequired,
  onDuplicate: func.isRequired,
  onDeleteMenuItem: func.isRequired,
  onTogglePublic: func.isRequired,
  integrations: arrayOf(shape({
    id: number.isRequired,
    kind: string.isRequired,
  })),
};

MenuItem.defaultProps = {
  integrations: [],
};

const mapStateToProps = (state) => {
  return {
    integrations: settingsIntegrationsSelector(state),
  };
};

const actions = {
  onDeleteMenuItem: deleteMenuItem,
  onTogglePublic: toggleMenuItemPublic,
};

const MenuItemContainer = connect(mapStateToProps, actions)(MenuItem);

export default MenuItemContainer;
