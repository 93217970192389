import {
  MENU_TEMPLATE_ADD_MENU_ITEM_SUCCESS,
  MENU_TEMPLATE_ADD_NEW_VEHICLE_GROUP,
  MENU_TEMPLATE_ADD_SERVICE_SUCCESS,
  MENU_TEMPLATE_CREATE_DECISION_TREE_SUCCESS,
  MENU_TEMPLATE_CREATE_VEHICLE_GROUP_SUCCESS,
  MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
  MENU_TEMPLATE_DELETE_MENU_ITEM_SUCCESS,
  MENU_TEMPLATE_DELETE_VEHICLE_GROUP_SUCCESS,
  MENU_TEMPLATE_FETCH_OP_CODES_SUCCESS,
  MENU_TEMPLATE_FETCH_SERVICES_SUCCESS,
  MENU_TEMPLATE_FETCH_SUCCESS,
  MENU_TEMPLATE_FETCH_VEHICLE_SETS_SUCCESS,
  MENU_TEMPLATE_IMPORT_OP_CODES,
  MENU_TEMPLATE_REORDER_ITEMS_SUCCESS,
  MENU_TEMPLATE_SET_EXTRAS_CLIPBOARD,
  MENU_TEMPLATE_SET_PREMIUM_EXTRAS_CLIPBOARD,
  MENU_TEMPLATE_SET_VEHICLE_GROUPS_CLIPBOARD,
  MENU_TEMPLATE_UPDATE_DECISION_TREE_SUCCESS,
  MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
  MENU_TEMPLATE_UPDATE_SERVICE_SUCCESS,
  MENU_TEMPLATE_UPDATE_VEHICLE_GROUP_SUCCESS,
} from "store/actions/menu-template-actions";

import { MENU_TEMPLATES_FETCH_SUCCESS } from "store/actions/menu-templates-actions";

const initialState = {
  services: [],
  vehicleSets: [],
  opCodes: [],
  error: null,
  vehicleGroupsClipboard: null,
  extrasClipboard: [],
  premiumExtrasClipboard: [],
  template: {
    menu_items: [],
  },
  imported: false,
};

const deleteService = (services, id) => services
  .filter((i) => i.id !== id)
  .map((i) => (i.package_items.length === 0
    ? i
    : {
      ...i,
      package_items: i.package_items.filter((p) => p.id !== id),
    }));

const app = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MENU_TEMPLATE_FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        error: null,
        services: payload.services,
      };
    case MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER:
      return {
        ...state,
        error: payload.error,
      };
    case MENU_TEMPLATE_FETCH_VEHICLE_SETS_SUCCESS:
      return {
        ...state,
        error: null,
        vehicleSets: payload.vehicleSets,
      };
    case MENU_TEMPLATES_FETCH_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case MENU_TEMPLATE_FETCH_OP_CODES_SUCCESS:
      return {
        ...state,
        error: null,
        opCodes: payload,
      };
    case MENU_TEMPLATE_IMPORT_OP_CODES:
      return {
        ...state,
        imported: true,
      };
    case MENU_TEMPLATE_ADD_SERVICE_SUCCESS:
      return {
        ...state,
        error: null,
        services: [...state.services, payload],
      };
    case MENU_TEMPLATE_SET_VEHICLE_GROUPS_CLIPBOARD:
      return {
        ...state,
        error: null,
        vehicleGroupsClipboard: payload.value,
      };
    case MENU_TEMPLATE_SET_EXTRAS_CLIPBOARD:
      return {
        ...state,
        error: null,
        extrasClipboard: payload.value,
      };
    case MENU_TEMPLATE_SET_PREMIUM_EXTRAS_CLIPBOARD:
      return {
        ...state,
        error: null,
        premiumExtrasClipboard: payload.value,
      };
    case MENU_TEMPLATE_ADD_MENU_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [payload.menuItem.kind]: [
              ...(state.template.menu_items[payload.menuItem.kind] || []),
              payload.menuItem,
            ],
          },
        },
      };
    case MENU_TEMPLATE_DELETE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        services: payload.services,
        template: {
          ...state.template,
          menu_items: {
            extension: deleteService(state.template.menu_items.extension || [], payload.id),
            maintenance: deleteService(state.template.menu_items.maintenance || [], payload.id),
            concern: deleteService(state.template.menu_items.concern || [], payload.id),
          },
        },
      };
    case MENU_TEMPLATE_REORDER_ITEMS_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [payload.itemsKind]: state.template.menu_items[
              payload.itemsKind
            ].sort(
              (a, b) => payload.menuItemIds.indexOf(a.id)
                - payload.menuItemIds.indexOf(b.id),
            ),
          },
        },
      };
    case MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [payload.kind]: (state.template.menu_items[payload.kind] || []).map(
              (menuItem) => (menuItem.id !== payload.id
                ? menuItem
                : {
                  ...menuItem,
                  default_inhouse_repair_order_item: payload.default_inhouse_repair_order_item,
                  default_mobile_repair_order_item: payload.default_mobile_repair_order_item,
                  default_pickup_repair_order_item: payload.default_pickup_repair_order_item,
                  package_items: [...payload.package_items],
                  extras: payload.extras,
                  premium_extras: payload.premium_extras,
                  cutoff_time: payload.cutoff_time,
                  team_tags: payload.team_tags,
                  available_on_weekends: payload.available_on_weekends,
                  publicly_available: payload.publicly_available,
                  available_for_proactive: payload.available_for_proactive,
                }),
            ),
          },
        },
      };
    case MENU_TEMPLATE_UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        error: null,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [payload.kind]: (state.template.menu_items[payload.kind] || []).map(
              (menuItem) => (menuItem.id === payload.menuItemId
                ? {
                  ...menuItem,
                  name: payload.name,
                  op_code: payload.opCodeName,
                }
                : {
                  ...menuItem,
                  package_items: menuItem.package_items.map((packageItem) => (
                    packageItem.id === payload.menuItemId
                      ? {
                        ...packageItem,
                        name: payload.name,
                        op_code: payload.opCodeName,
                      }
                      : packageItem)),
                }),
            ),
          },
        },
      };
    case MENU_TEMPLATE_ADD_NEW_VEHICLE_GROUP:
      return {
        ...state,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [payload.kind]: (state.template.menu_items[payload.kind] || []).map(
              (menuItem) => (menuItem.id !== payload.menuItemId
                ? menuItem
                : {
                  ...menuItem,
                  menu_item_targets: [
                    ...menuItem.menu_item_targets,
                    {
                      id: null,
                      price: 0,
                      saving: 0,
                      allocated_labor_time: 0,
                      actual_labor_time: 0,
                      mobile_actual_labor_time: 0,
                      expected_upsell_time: 0,
                      mileage_range: [0, 0],
                      exclude_cars: false,
                      vehicle_sets: [],
                      totalPrice: 0,
                      totalTime: 0,
                      parts_price: 0,
                      labor_price: 0,
                    },
                  ],
                }),
            ),
          },
        },
      };
    case MENU_TEMPLATE_CREATE_VEHICLE_GROUP_SUCCESS: {
      const {
        menu_item,
        id,
        price,
        saving,
        mileage_range,
        exclude_cars,
        vehicle_sets,
        allocated_labor_time,
        actual_labor_time,
        mobile_actual_labor_time,
        expected_upsell_time,
        parts_price,
        labor_price,
      } = payload;
      return {
        ...state,
        error: null,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [menu_item.kind]: (
              state.template.menu_items[menu_item.kind] || []
            ).map((menuItem) => (menuItem.id !== menu_item.id
              ? menuItem
              : {
                ...menuItem,
                menu_item_targets: [
                  ...menuItem.menu_item_targets.filter(
                    (vehicleGroup) => vehicleGroup.id !== null,
                  ),
                  {
                    id,
                    price,
                    saving,
                    mileage_range,
                    exclude_cars,
                    vehicle_sets,
                    allocated_labor_time,
                    actual_labor_time,
                    mobile_actual_labor_time,
                    expected_upsell_time,
                    parts_price,
                    labor_price,
                  },
                ],
              })),
          },
        },
      };
    }
    case MENU_TEMPLATE_UPDATE_VEHICLE_GROUP_SUCCESS: {
      const {
        menu_item,
        id,
        price,
        saving,
        mileage_range,
        exclude_cars,
        vehicle_sets,
        allocated_labor_time,
        actual_labor_time,
        mobile_actual_labor_time,
        expected_upsell_time,
        parts_price,
        labor_price,
      } = payload;
      return {
        ...state,
        error: null,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [menu_item.kind]: (
              state.template.menu_items[menu_item.kind] || []
            ).map((menuItem) => (menuItem.id !== payload.menu_item.id
              ? menuItem
              : {
                ...menuItem,
                menu_item_targets: menuItem.menu_item_targets.map(
                  (vehicleGroup) => (vehicleGroup.id !== payload.id
                    ? vehicleGroup
                    : {
                      id,
                      price,
                      saving,
                      mileage_range,
                      exclude_cars,
                      vehicle_sets,
                      allocated_labor_time,
                      actual_labor_time,
                      mobile_actual_labor_time,
                      expected_upsell_time,
                      parts_price,
                      labor_price,
                    }),
                ),
              })),
          },
        },
      };
    }
    case MENU_TEMPLATE_DELETE_VEHICLE_GROUP_SUCCESS: {
      const { menuItemKind, menuItemId, vehicleGroupId } = payload;
      return {
        ...state,
        error: null,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [menuItemKind]: (
              state.template.menu_items[menuItemKind] || []
            ).map((menuItem) => (menuItem.id !== menuItemId
              ? menuItem
              : {
                ...menuItem,
                menu_item_targets: menuItem.menu_item_targets.map(
                  (vehicleGroup) => (vehicleGroup.id !== vehicleGroupId ? vehicleGroup : null),
                ).filter((v) => v),
              })),
          },
        },
      };
    }
    case MENU_TEMPLATE_CREATE_DECISION_TREE_SUCCESS:
      return {
        ...state,
        error: null,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [payload.menu_item.kind]: (
              state.template.menu_items[payload.menu_item.kind] || []
            ).map((menuItem) => (menuItem.id !== payload.menu_item.id
              ? menuItem
              : {
                ...menuItem,
                decision_tree: payload,
              })),
          },
        },
      };
    case MENU_TEMPLATE_UPDATE_DECISION_TREE_SUCCESS:
      return {
        ...state,
        error: null,
        template: {
          ...state.template,
          menu_items: {
            ...state.template.menu_items,
            [payload.menu_item.kind]: (
              state.template.menu_items[payload.menu_item.kind] || []
            ).map((menuItem) => (menuItem.id !== payload.menu_item.id
              ? menuItem
              : {
                ...menuItem,
                decision_tree: payload?.menu_item?.decision_tree,
              })),
          },
        },
      };
    case MENU_TEMPLATE_FETCH_SUCCESS:
      return {
        ...state,
        error: null,
        template: payload,
      };
    default:
      return state;
  }
};

export default app;
