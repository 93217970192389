import React from "react";
import { shape, string } from "prop-types";
import { format } from "date-fns";

import { APPOINTMENT_DATETIME_FORMAT } from "shared/utils/datetime";

import styles from "../styles.module.scss";

const PickupInformation = ({
  address,
  collectionTime,
}) => {
  const dateTime = () => (
    collectionTime.date
      ? `${collectionTime.date}T${collectionTime.time}`
      : collectionTime
  );

  return (
    <>
      <div className={styles.data}>
        <div className={styles.item}>
          <div className={styles.title}>
            Location
          </div>
          <div className={styles.content}>
            {address}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>
            Collection Time
          </div>
          <div className={styles.content}>
            {format(dateTime(), APPOINTMENT_DATETIME_FORMAT)}
          </div>
        </div>
      </div>
      <div className={styles.divider} />
    </>
  );
};

PickupInformation.propTypes = {
  address: string.isRequired,
  collectionTime: shape({
    date: string.isRequired,
    time: string.isRequired,
  }).isRequired,
};

export default PickupInformation;
