import React from "react";
import {
  bool, node, oneOf, string,
} from "prop-types";
import cx from "classnames";
import serviceButtonIcons from "shared/serviceButtonIcons";

import "./styles.scss";

const Button = ({
  className,
  children,
  type,
  variant,
  padding,
  fullWidth,
  leftIcon,
  rightIcon,
  withoutBorder,
  isSquare,
  isBooked,
  ...props
}) => {
  const buttonClasses = cx(
    "conciergeButton",
    className,
    {
      conciergeButtonBaseGrey: variant === "base-grey",
      conciergeButtonNeutral: variant === "neutral",
      conciergeButtonAqua: variant === "aqua",
      conciergeButtonBrand: variant === "brand",
      conciergeButtonBrandOutline: variant === "brand-outline",
      conciergeButtonDark: variant === "dark",
      conciergeButtonDarkOutline: variant === "dark-outline",
      conciergeButtonSuccess: variant === "success",
      conciergeButtonDestructive: variant === "destructive",
      conciergeButtonDestructiveOutline: variant === "destructive-outline",
      conciergeButtonPrimaryOutline: variant === "primary-outline",
    },
    {
      conciergeButtonSmallPadding: padding === "small",
      conciergeButtonMediumPadding: padding === "medium",
      conciergeButtonLargePadding: padding === "large",
    },
    {
      conciergeButtonFullWidth: fullWidth,
    },
    {
      conciergeButtonWithoutBorder: withoutBorder,
    },
    {
      conciergeButtonSquare: isSquare,
    },
    {
      conciergeButtonBooked: isBooked,
    },
  );

  return (
    <button
      type={type === "button" ? "button" : "submit"}
      className={buttonClasses}
      {...props}
    >
      {leftIcon && (
        <img
          className="leftIcon"
          src={serviceButtonIcons[leftIcon]}
          alt=""
        />
      )}
      {children && (
        <p>
          {children}
        </p>
      )}
      {rightIcon && (
        <img
          className="rightIcon"
          src={serviceButtonIcons[rightIcon]}
          alt=""
        />
      )}
      {isBooked && (
        <p className="bookedCounter">1</p>
      )}
    </button>
  );
};

Button.propTypes = {
  children: node,
  className: string,
  type: oneOf(["button", "submit"]),
  variant: oneOf([
    "base",
    "base-grey",
    "neutral",
    "aqua",
    "brand",
    "brand-outline",
    "dark",
    "dark-outline",
    "success",
    "destructive",
    "destructive-outline",
  ]),
  padding: oneOf(["small", "medium", "large"]),
  fullWidth: bool,
  leftIcon: oneOf([
    "add",
    "addWhite",
    "bin",
    "close",
    "checkmark",
    "edit",
    "editGrey",
    "editWhite",
    "leftArrow",
    "rightArrow",
    "position",
    "departure",
    "arrive",
    "clock",
    "search",
    null,
  ]),
  rightIcon: oneOf([
    "add",
    "addWhite",
    "bin",
    "close",
    "checkmark",
    "edit",
    "editGrey",
    "editWhite",
    "leftArrow",
    "rightArrow",
    "position",
    "departure",
    "arrive",
    "clock",
    "search",
    null,
  ]),
  withoutBorder: bool,
  isSquare: bool,
  isBooked: bool,
};

Button.defaultProps = {
  children: null,
  className: null,
  type: "button",
  variant: "base",
  padding: "large",
  fullWidth: false,
  leftIcon: null,
  rightIcon: null,
  withoutBorder: false,
  isSquare: false,
  isBooked: false,
};

export default Button;
