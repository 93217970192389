import React, { useEffect, useState } from "react";
import {
  arrayOf, func, number, string,
} from "prop-types";
import {
  identity, isEmpty, isNil, reject,
} from "ramda";
import { connect } from "react-redux";
import Switch from "react-switch";
import { integrationSettingsKeys, segmentNumberOptions } from "shared/constants";
import { addIntegration } from "store/actions/dealership-details-actions";
import { formatName } from "shared/utils/common";
import StyledSelect from "components/common/StyledSelect";
import Input from "components/common/Input";
import Modal from "components/common/Modal";
import uniqid from "uniqid";

import styles from "./styles.module.scss";

const optionalFields = ["both_conditions_required", "shared_account", "additional_information_for_customers", "imported_makes"];
const optionsSwitch = ["both_conditions_required", "shared_account"];

const AddIntegrationModal = ({
  dealershipId,
  onSubmit,
  onClose,
  excludedIntegrations,
}) => {
  const [dealershipIdValue, setDealershipIdValue] = useState(null);
  const [integrationType, setIntegrationType] = useState(null);
  const [settings, setSettings] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setDealershipIdValue(dealershipId);
  }, [dealershipId]);

  const validate = () => {
    if (isNil(integrationType) || isEmpty(integrationType)) {
      setErrors({
        ...errors,
        integrationType: "Integration type can't be blank",
      });
      return false;
    }

    const integrationKeys = integrationSettingsKeys.find(
      (integration) => integration.integrationType === integrationType,
    );

    const integrationErrors = [];

    Object.keys(integrationKeys.settings).forEach((integrationKey) => {
      if (!settings[integrationKey]) {
        const error = isNil(settings[integrationKey]) && !optionalFields.includes(integrationKey)
          ? `${formatName(integrationKey)} can't be blank.`
          : null;

        integrationErrors.push({
          key: integrationKey,
          error,
        });
      }
    });

    setErrors({
      ...errors,
      [integrationType]: integrationErrors.reduce((acc, curr) => {
        if (curr.error) {
          acc[curr.key] = curr.error;
        }
        return acc;
      }, {}),
    });

    return isEmpty(reject(isNil, Object.values(errors[integrationType])));
  };

  const updateIntegrationType = (newIntegrationType) => {
    if (integrationType !== newIntegrationType.value) {
      setIntegrationType(newIntegrationType.value);
      setSettings({});
      setErrors({});
    }
  };

  const handleSettingsChange = (settingValue, settingsKey) => {
    const newErrors = {
      ...errors,
      [integrationType]: {
        ...errors[integrationType],
        [settingsKey]: null,
      },
    };
    setErrors(newErrors);
    setSettings({
      ...settings,
      [settingsKey]: settingValue,
    });
  };

  const integrationParams = () => {
    const defaultSettings = integrationSettingsKeys.find(
      (item) => item.integrationType === integrationType,
    ).settings;
    Object.keys(defaultSettings).forEach((field) => {
      if (!settings[field]) {
        settings[field] = defaultSettings[field];
      }
    });
    return {
      kind: integrationType,
      settings,
    };
  };

  const submit = () => {
    if (validate()) {
      onSubmit(dealershipIdValue, integrationParams());
      onClose();
    }
  };

  const prepareIntegrationTypesOptions = () => (
    integrationSettingsKeys
      .map((integration) => {
        return {
          label: formatName(integration.integrationType),
          value: integration.integrationType,
          selectable: Object.keys(integration.settings).length > 0,
        };
      })
      .filter((el) => !excludedIntegrations.includes(el.value))
  );

  const prepareSettingsKeys = () => {
    const integration = integrationSettingsKeys.find(
      (integrations) => integrations.integrationType === integrationType,
    );
    return isNil(integrationType) || isEmpty(integrationType)
      ? [] : Object.keys(integration.settings).filter((key) => key !== "imported_makes");
  };

  const prepareIntegrationsDropdown = (error) => {
    const integrationTypesOptions = prepareIntegrationTypesOptions();
    const emptyOption = {
      label: "",
      value: "",
      selectable: true,
    };

    if (!isEmpty(integrationTypesOptions)) {
      const integrationOptions = [emptyOption, ...integrationTypesOptions];
      const selectedOption = integrationOptions.find((integrationTypeOption) => (
        integrationTypeOption.value === integrationType
      )) || emptyOption;

      return (
        <StyledSelect
          key="integration_type"
          options={integrationOptions}
          value={selectedOption}
          onChange={updateIntegrationType}
          error={error.integrationType}
          isOptionDisabled={(option) => option.selectable === false}
        />
      );
    }

    return (
      <StyledSelect
        key="integration_type"
        options={[emptyOption]}
        value={emptyOption}
        onChange={identity}
        error={errors.integrationType}
      />
    );
  };

  return (
    <Modal
      title="New integration"
      cancelButtonText="Cancel"
      submitButtonText="Create"
      size="medium"
      onCancel={onClose}
      onSubmit={submit}
    >
      <div className={styles.dropdownContainer}>
        <div className={styles.selectWrapper}>
          <div className={styles.label}>
            Integration type
          </div>
          {prepareIntegrationsDropdown(errors)}
        </div>
      </div>
      <div className={styles.container}>
        {prepareSettingsKeys().map((keyName) => {
          const { [integrationType]: { [keyName]: keyNameError } = {} } = errors;
          if (optionsSwitch.includes(keyName)) {
            return (
              <div
                key={uniqid()}
                className={styles.switch}
              >
                <label
                  className={styles.switchLabel}
                  htmlFor="remote-requirements-switch"
                >
                  {formatName(keyName)}
                </label>
                <Switch
                  id="remote-requirements-switch"
                  onChange={() => handleSettingsChange(!settings[keyName], keyName)}
                  checked={settings[keyName]}
                  onColor="#36af5e"
                  offColor="#dedee0"
                  activeBoxShadow="0 0 2px 3px #0bcaf9"
                  aria-labelledby="remote-requirements-label"
                />
              </div>
            );
          }
          if (keyName === "segments_number") {
            return (
              <div
                key={uniqid()}
                className={styles.selectWrapper}
              >
                <label
                  className={styles.label}
                  htmlFor="segments-number-switch"
                >
                  {formatName(keyName)}
                </label>
                <StyledSelect
                  key="integration_type"
                  options={segmentNumberOptions}
                  value={{
                    label: settings.segments_number,
                    value: settings.segments_number,
                    selectable: true,
                  }}
                  onChange={(val) => handleSettingsChange(Number(val.value), "segments_number")}
                  error={errors[integrationType] && errors[integrationType].segments_number}
                />
              </div>
            );
          }

          return (
            <div
              className={styles.inputContainer}
              key={keyName}
            >
              <Input
                label={formatName(keyName)}
                key={keyName}
                className={styles.input}
                value={settings[keyName] || ""}
                onChange={(value) => handleSettingsChange(value, keyName)}
                error={keyNameError}
              />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

AddIntegrationModal.propTypes = {
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  dealershipId: number,
  excludedIntegrations: arrayOf(string),
};

AddIntegrationModal.defaultProps = {
  dealershipId: null,
  excludedIntegrations: [],
};

const actions = {
  onSubmit: addIntegration,
};

export default connect(null, actions)(AddIntegrationModal);
