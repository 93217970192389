import React from "react";
import { boolean, func } from "prop-types";
import cx from "classnames";

import leftArrowIcon from "assets/images/leftArrowIcon.svg";
import rightArrowIcon from "assets/images/rightArrowIcon.svg";

import "../styles.scss";

const ArrowButton = ({
  isLeft,
  isRight,
  disabled,
  onClick,
}) => {
  const src = () => {
    if (isLeft) {
      return leftArrowIcon;
    } if (isRight) {
      return rightArrowIcon;
    }

    return null;
  };

  const alt = () => {
    if (isLeft) {
      return "leftArrowIcon";
    } if (isRight) {
      return "rightArrowIcon";
    }

    return "arrowIcon";
  };

  return (
    <button
      type="button"
      className="carouselArrowButton"
      disabled={disabled}
      onClick={onClick}
    >
      <img
        className={cx("carouselArrowIcon", { carouselArrowIconDisabled: disabled })}
        src={src()}
        alt={alt()}
      />
    </button>
  );
};

ArrowButton.propTypes = {
  isLeft: boolean,
  isRight: boolean,
  onClick: func.isRequired,
  disabled: boolean,
};

ArrowButton.defaultProps = {
  isLeft: false,
  isRight: false,
  disabled: false,
};

export default ArrowButton;
