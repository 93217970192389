import React, { useEffect, useState } from "react";
import Accordion from "components/common/Accordion";
import { arrayOf, func } from "prop-types";
import { connect } from "react-redux";
import Switch from "react-switch";

import { dealershipInfoPropType, textMessagePropType } from "shared/prop-types";
import {
  changeDealershipInfo,
  createTextMessage,
  removeTextMessage,
  updateTextMessage,
} from "store/actions/settings-actions";

import HeaderEdit from "components/common/HeaderEdit";
import Input from "components/common/Input";
import StyledSelect from "components/common/StyledSelect";

import styles from "./styles.module.scss";

const TextMessagesPanel = ({
  dealershipInfo,
  dealershipInfo: {
    no_show_text_message,
    text_message_no_show_tolerance_in_minutes,
  },
  textMessages,
  addTextMessage,
  destroyTextMessage,
  updateTextMessageData,
  updateDealership,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [timeError, setTimeError] = useState("");

  const [dealershipsTextMessageId, setDealershipsTextMessageId] = useState(null);
  const [noShowTextMessageId, setNoShowTextMessageId] = useState(null);
  const [noShowTextMessageEnabled, setNoShowTextMessageEnabled] = useState(false);
  const [noShowTextMessageContent, setNoShowTextMessageContent] = useState("");
  const [noShowTolerance, setNoShowTolerance] = useState(null);

  useEffect(() => {
    setDealershipsTextMessageId(no_show_text_message?.id);
    setNoShowTextMessageContent(no_show_text_message?.text_message.content);
    setNoShowTextMessageEnabled(no_show_text_message?.enabled);
    setNoShowTextMessageId(no_show_text_message?.text_message.id);
    setNoShowTolerance(text_message_no_show_tolerance_in_minutes);
  }, [dealershipInfo]);

  const noShowTextMessages = () => (
    textMessages.filter((textMessage) => textMessage.kind === "no_show")
  );

  const selectOptions = noShowTextMessages().map(
    (textMessage) => {
      return {
        value: textMessage.id,
        label: textMessage.content,
      };
    },
  );

  const handleNoShowTolerance = (value) => {
    if (noShowTextMessageEnabled && (value === "" || parseInt(value, 10) === 0)) {
      setTimeError("Set no-show tolerance before enabling no-show SMS");
      setNoShowTextMessageEnabled(false);
    } else {
      setTimeError(null);
    }

    setNoShowTolerance(value);
  };

  const handleChange = (option) => {
    setNoShowTextMessageId(option.value);
    setNoShowTextMessageContent(option.label);
  };

  const cancelChanges = () => {
    setDealershipsTextMessageId(no_show_text_message?.id);
    setNoShowTextMessageContent(no_show_text_message?.text_message.content);
    setNoShowTextMessageEnabled(no_show_text_message?.enabled);
    setNoShowTextMessageId(no_show_text_message?.text_message.id);
    setNoShowTolerance(text_message_no_show_tolerance_in_minutes);
    setIsEditing(false);
  };

  const handleSave = () => {
    const messageParams = {
      text_message_id: noShowTextMessageId,
      enabled: noShowTextMessageEnabled,
    };

    if (noShowTextMessageId === "") {
      destroyTextMessage(
        { text_message_no_show_tolerance_in_minutes: parseInt(noShowTolerance, 10) },
        false,
        dealershipsTextMessageId,
      );
    } else if (!noShowTextMessageId) {
      updateDealership(
        dealershipInfo.id,
        { text_message_no_show_tolerance_in_minutes: parseInt(noShowTolerance, 10) },
        false,
      );
    } else if (!no_show_text_message) {
      addTextMessage(
        { text_message_no_show_tolerance_in_minutes: parseInt(noShowTolerance, 10) },
        false,
        messageParams,
      );
    } else {
      updateTextMessageData(
        { text_message_no_show_tolerance_in_minutes: parseInt(noShowTolerance, 10) },
        false,
        messageParams,
        dealershipsTextMessageId,
      );
    }

    setIsEditing(false);
  };

  return (
    <Accordion
      title="Text Messages"
      customClass={styles.accordion}
    >
      <section className={styles.container}>
        <section className={styles.title}>
          <span>
            No-show text message
          </span>
          <HeaderEdit
            isEditing={isEditing}
            handleCancel={cancelChanges}
            handleSave={handleSave}
            handleEdit={() => setIsEditing(true)}
          />
        </section>
        <section className={styles.block}>
          <div className={styles.switch}>
            <label
              className={styles.label}
              htmlFor="activity-switch"
            >
              Is Active
            </label>
            <Switch
              id="activity-switch"
              onChange={() => setNoShowTextMessageEnabled(!noShowTextMessageEnabled)}
              checked={noShowTextMessageEnabled}
              disabled={!isEditing || noShowTolerance === 0}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="activity-label"
            />
            <Input
              disabled={!isEditing}
              type="number"
              label="No-show tolerance in minutes"
              value={noShowTolerance}
              onChange={(value) => handleNoShowTolerance(value)}
              error={timeError}
            />
          </div>
          <section>
            <StyledSelect
              options={selectOptions}
              value={{
                label: noShowTextMessageContent,
                value: noShowTextMessageId,
              }}
              disabled={!isEditing}
              isOptionDisabled={(option) => option.selectable === false}
              onChange={(value) => handleChange(value)}
              withEmptyOption
            />
          </section>
        </section>
      </section>
    </Accordion>
  );
};

TextMessagesPanel.propTypes = {
  dealershipInfo: dealershipInfoPropType,
  textMessages: arrayOf(textMessagePropType).isRequired,
  addTextMessage: func.isRequired,
  destroyTextMessage: func.isRequired,
  updateDealership: func.isRequired,
  updateTextMessageData: func.isRequired,
};

TextMessagesPanel.defaultProps = {
  dealershipInfo: null,
};

const actions = {
  addTextMessage: createTextMessage,
  updateDealership: changeDealershipInfo,
  destroyTextMessage: removeTextMessage,
  updateTextMessageData: updateTextMessage,
};

export default connect(null, actions)(TextMessagesPanel);
