import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { func, shape, string } from "prop-types";
import { format, parse, setSeconds } from "date-fns";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { NotificationManager } from "react-notifications";

import {
  AMERICAN_DATETIME_FORMAT,
  DEFAULT_TIME_FORMAT_WITHOUT_TIMEZONE,
  changeTimezone,
  convertPromisedTime,
} from "shared/utils/datetime";
import {
  ARRIVE_SOURCE,
  CUSTOMER_APP_SOURCE,
  CUSTOMER_SOURCE,
  IMPORT_SOURCE,
  PROACTIVE_SOURCE,
  SCHEDULER_SOURCE,
} from "shared/constants";
import { updateLocalBooking } from "store/actions/booking-details-actions";
import { roTagNumberSelector } from "store/selectors/booking-details-selectors";
import { settingsTimezoneSelector } from "store/selectors/settings-selectors";

import Panel from "components/common/Panel";
import Button from "components/common/Button";
import Input from "components/common/Input";
import HistoryModal from "components/common/HistoryModal";
import ClientDetailsBlock from "./ClientDetailsBlock";
import VehicleDetailsBlock from "./VehicleDetailsBlock";

import styles from "./styles.module.scss";

const BookingDetailsPanel = ({
  booking,
  booking: {
    appointment_datetime,
    promised_datetime,
    repair_order_tag,
    repair_order_number,
    repair_order,
    vehicle,
    aasm_state,
  },
  updateBooking,
  timezone,
}) => {
  const [isRoTagEditing, setIsRoTagEditing] = useState(false);
  const [isPromisedTimeEditing, setIsPromisedTimeEditing] = useState(false);
  const [roTagNumber, setRoTagNumber] = useState(repair_order_tag);
  const [promisedTime, setPromisedTime] = useState(null);
  const [isVehicleEditing, setIsVehicleEditing] = useState(false);
  const [isCustomerEditing, setIsCustomerEditing] = useState(false);
  const [isRoCreated, setIsRoCreated] = useState(false);
  const [appointmentDateTime, setAppointmentDateTime] = useState(null);
  const [errors, setErrors] = useState({});
  const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
  const isVehicleEditable = () => !isRoCreated && aasm_state !== "not_shown" && aasm_state !== "canceled";

  useEffect(() => {
    setIsRoCreated(!!repair_order_number);
    setAppointmentDateTime(parse(appointment_datetime));
    if (promised_datetime && timezone) {
      setPromisedTime(changeTimezone(promised_datetime, timezone));
    }
  }, [repair_order_number, appointment_datetime, promised_datetime, timezone]);

  const saveRoTagNumber = () => {
    updateBooking({ payload: { repair_order_tag: roTagNumber } });
    setIsRoTagEditing(false);
  };

  const validate = () => {
    const currentTime = new Date();
    const validationErrors = {};
    if (parse(promisedTime) < appointmentDateTime) {
      validationErrors.lessThanAppointmentTime = (
        "Promised time cannot be less than the time of the appointment."
      );
    } else if (parse(promisedTime) < currentTime) {
      validationErrors.lessThanCurrentTime = (
        "Promised time cannot be less than the current time."
      );
    }
    setErrors(validationErrors);
    return !Object.values(validationErrors).find((v) => v);
  };

  const savePromisedTime = () => {
    if (validate() || !promisedTime) {
      updateBooking({
        payload: {
          promised_datetime: !promisedTime
            ? null
            : convertPromisedTime(promisedTime, timezone),
        },
      });
      setIsPromisedTimeEditing(false);
    } else {
      Object.keys(errors).forEach((key) => {
        NotificationManager.error(
          errors[key],
          "Error",
          2000,
        );
      });
    }
  };

  const handleChangeRO = (value) => {
    const allowedRoTagNumberRegex = /^[A-Za-z0-9]*$/;
    if (value.length > 5 || !allowedRoTagNumberRegex.test(value)) {
      return;
    }
    setRoTagNumber(value);
  };

  const handleChangeTime = (date) => {
    if (date) {
      const formattedDate = format(setSeconds(date, 0), DEFAULT_TIME_FORMAT_WITHOUT_TIMEZONE);
      return setPromisedTime(formattedDate);
    }
    setPromisedTime(null);
  };

  return (
    <Panel className={styles.panelContainer}>
      <section className={styles.blockContent}>
        <VehicleDetailsBlock
          booking={booking}
          isEditing={isVehicleEditing}
          handleFieldEdit={setIsVehicleEditing}
        />
      </section>

      <section className={styles.repairOrderDetails}>
        <section className={styles.repairOrderTag}>
          <span className={styles.repairOrderLabel}>
            Promised time:
          </span>
          {isPromisedTimeEditing && (
            <>
              <div className={styles.datePicker}>
                <DatePicker
                  value={promisedTime ? parse(promisedTime) : null}
                  format="MM/DD/YYYY hh:mm A"
                  plugins={[
                    <TimePicker
                      position="top"
                      hideSeconds
                    />,
                  ]}
                  shadow={false}
                  arrow={false}
                  minDate={
                    new Date() > appointmentDateTime
                      ? new Date()
                      : appointmentDateTime
                  }
                  onChange={handleChangeTime}
                />
              </div>
              <Button
                withoutBorder
                onClick={() => savePromisedTime()}
                className={styles.saveButton}
                padding="small"
              >
                Save
              </Button>
            </>
          )}
          {!isPromisedTimeEditing && (
            <div className={styles.repairOrderTag}>
              <span className={styles.repairOrderText}>
                {promisedTime ? format(promisedTime, AMERICAN_DATETIME_FORMAT) : "-"}
              </span>
              <Button
                onClick={() => setIsPromisedTimeEditing(true)}
                withoutBorder
                variant="base-grey"
                padding="small"
                leftIcon="editGrey"
              >
                Edit
              </Button>
            </div>
          )}
        </section>
        {repair_order_number && (
          <section>
            <span className={styles.repairOrderLabel}>
              {`RO ${repair_order_number}`}
            </span>
            {(repair_order?.source) && (
              <span className={styles.repairOrderLabel}>
                {`(${repair_order.source === "import" ? "imported" : repair_order.source})`}
              </span>
            )}
          </section>
        )}
        <section className={styles.repairOrderTag}>
          <span className={styles.repairOrderLabel}>
            Tag number:
          </span>
          {isRoTagEditing && (
            <>
              <Input
                inputClassName={styles.repairOrderInput}
                type="string"
                value={roTagNumber}
                onChange={(value) => handleChangeRO(value)}
              />
              <Button
                withoutBorder
                onClick={() => saveRoTagNumber()}
                className={styles.saveButton}
                padding="small"
              >
                Save
              </Button>
            </>
          )}
          {!isRoTagEditing && (
            <div className={styles.repairOrderTag}>
              <span
                className={styles.repairOrderText}
              >
                {roTagNumber || "-"}
              </span>
              <Button
                onClick={() => setIsRoTagEditing(true)}
                withoutBorder
                variant="base-grey"
                padding="small"
                leftIcon="editGrey"
              >
                Edit
              </Button>
            </div>
          )}
        </section>
      </section>
      <section className={styles.blockContent}>
        <section className={styles.vehicleDetails}>
          <section className={styles.creationInfo}>
            <div>
              {booking.created_by && (
                <BookingCreatedBy
                  source={booking.created_by.source}
                  creator_name={booking.created_by.created_by_name}
                />
              )}
            </div>
            <div>
              {booking.creation_datetime && (
                <BookingCreatedAt
                  creation_datetime={booking.creation_datetime}
                  source={booking.created_by.source}
                />
              )}
            </div>
          </section>
          <div className={styles.buttonsContainer}>
            {isVehicleEditable() && (
              <>
                <Button
                  withoutBorder
                  onClick={() => setIsVehicleEditing(!isVehicleEditing)}
                  variant="base-grey"
                  leftIcon="editGrey"
                  padding="small"
                >
                  Edit
                </Button>
                {vehicle?.vin && (
                  <hr />
                )}
              </>
            )}
            {vehicle?.vin && (
              <Button
                withoutBorder
                onClick={() => setHistoryModalOpen(true)}
                variant="primary-outline"
                padding="small"
              >
                View history
              </Button>
            )}
          </div>
        </section>
      </section>
      <section className={styles.blockContent}>
        <ClientDetailsBlock
          booking={booking}
          isEditing={isCustomerEditing}
          handleFieldEdit={setIsCustomerEditing}
        />
      </section>
      {isHistoryModalOpen && (
        <HistoryModal
          onClose={() => setHistoryModalOpen(false)}
          vehicleId={vehicle.id}
        />
      )}
    </Panel>
  );
};

BookingDetailsPanel.propTypes = {
  booking: shape({
    repair_order_tag: string,
    repair_order_number: string,
  }).isRequired,
  updateBooking: func.isRequired,
  timezone: string.isRequired,
};

const createdByLabel = (source, creator_name) => {
  switch (source) {
    case CUSTOMER_SOURCE:
      return "Made by customer";
    case IMPORT_SOURCE:
      return "Imported";
    case ARRIVE_SOURCE:
      return "Walk-In";
    case CUSTOMER_APP_SOURCE:
      return "Made by customer (App)";
    case PROACTIVE_SOURCE:
      return "Made by customer";
    case SCHEDULER_SOURCE:
      if (creator_name !== null) {
        return `Made by ${creator_name}`;
      }
      return "Made in scheduler";

    default:
      return null;
  }
};

const BookingCreatedBy = ({ source, creator_name }) => (
  <section>
    <span>
      {createdByLabel(source, creator_name)}
    </span>
  </section>
);

const BookingCreatedAt = ({ creation_datetime, source }) => (
  <section className={styles.creationInfoDate}>
    <span>
      { source === IMPORT_SOURCE ? "imported: " : "created: " }
    </span>
    <span>
      {format(creation_datetime, AMERICAN_DATETIME_FORMAT)}
    </span>
  </section>
);

BookingCreatedAt.propTypes = {
  creation_datetime: string,
  source: string,
};

BookingCreatedBy.propTypes = {
  source: string,
  creator_name: string,
};

BookingCreatedAt.defaultProps = {
  creation_datetime: null,
  source: string,
};

BookingCreatedBy.defaultProps = {
  source: null,
  creator_name: null,
};

const mapStateToProps = (state) => {
  return {
    roTagNumber: roTagNumberSelector(state),
    timezone: settingsTimezoneSelector(state),
  };
};

const actions = {
  updateBooking: updateLocalBooking,
};

export default connect(mapStateToProps, actions)(BookingDetailsPanel);
