export const schedulingSearchStringSelector = (state) => state.scheduling.searchPhrase;
export const schedulingCustomersSelector = (state) => state.scheduling.customers;
export const schedulingCustomersFetchedSelector = (state) => state.scheduling.customersFetched;
export const schedulingErrorSelector = (state) => state.scheduling.error;
export const schedulingLoadingStateSelector = (state) => state.scheduling.isLoading;
export const schedulingCustomerSavingSelector = (state) => state.scheduling.dataEditionModalSaving;
export const schedulingCustomerSavingErrorSelector = (state) => (
  state.scheduling.dataEditionModalSavingError
);

export const schedulingSavedWithoutDmsNotificationSelector = (state) => (
  state.scheduling.savedWithoutDmsNotification
);
export const schedulingSavingCustomerIdSelector = (state) => (
  state.scheduling.savingCustomerId
);
