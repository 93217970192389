import { connect } from "react-redux";
import { settingsDealershipMobileTechnicianEnabledSelector } from "store/selectors/settings-selectors";
import {
  bookingAvailableRecallsSelector,
  bookingRecallsLoadingErrorSelector,
  bookingRecallsLoadingSelector,
  bookingSelectedRecallsSelector,
} from "store/selectors/booking-details-selectors";
import { setRecallsSelection } from "store/actions/booking-details-actions";

import RecallsToSelect from "./index";

const mapStateToProps = (state) => {
  return {
    availableRecalls: bookingAvailableRecallsSelector(state),
    selectedRecalls: bookingSelectedRecallsSelector(state),
    recallsLoading: bookingRecallsLoadingSelector(state),
    recallsLoadingError: bookingRecallsLoadingErrorSelector(state),
    mobileTechnicianEnabled: settingsDealershipMobileTechnicianEnabledSelector(state),
  };
};

const actions = {
  setSelectedRecalls: setRecallsSelection,
};

export default connect(mapStateToProps, actions)(RecallsToSelect);
