import React, {
  useEffect, useRef, useState,
} from "react";
import cx from "classnames";
import uniqid from "uniqid";
import { number } from "prop-types";
import {
  OverlayView, Polygon, useGoogleMap,
} from "@react-google-maps/api";

import "./styles.scss";

const colors = ["#0BCAF9", "#6a45ff"];

const labels = {
  4: {
    90: {
      firstLabel: "B",
      secondLabel: "A",
    },
    270: {
      firstLabel: "D",
      secondLabel: "C",
    },
  },
  6: {
    90: {
      firstLabel: "B",
    },
    180: {
      firstLabel: "D",
      secondLabel: "C",
    },
    270: {
      secondLabel: "E",
    },
    360: {
      firstLabel: "F",
      secondLabel: "A",
    },
  },
  8: {
    90: {
      firstLabel: "B",
      secondLabel: "C",
    },
    180: {
      firstLabel: "D",
      secondLabel: "E",
    },
    270: {
      firstLabel: "F",
      secondLabel: "G",
    },
    360: {
      firstLabel: "H",
      secondLabel: "A",
    },
  },
};

const options = {
  strokeColor: "#0BCAF9",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

const Areas = ({
  center,
  miles,
  segmentsNumber,
}) => {
  const map = useGoogleMap();
  const elementsRefs = useRef([]);

  const [sections, setSections] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [lines, setLines] = useState([]);
  const [bounds, setBounds] = useState([]);
  const [sectionsInit, setSectionsInit] = useState(false);
  const [linesInit, setLinesInit] = useState(false);

  const computeOffset = (from, distance, heading) => {
    // eslint-disable-next-line new-cap
    const result = new window.google.maps.geometry.spherical.computeOffset(from, distance, heading);
    return JSON.parse(JSON.stringify(result));
  };

  const getArcPath = (position, radiusMeters, startAngle, endAngle) => {
    let currentAngle = startAngle;
    let point;
    const points = [];

    while (true) {
      point = computeOffset(position, radiusMeters, currentAngle);
      points.push(point);

      if (currentAngle === endAngle) {
        break;
      }

      currentAngle++;

      if (currentAngle > 360) {
        currentAngle = 1;
      }
    }

    return points;
  };

  const milesToMeters = (value) => value * 1.609344 * 1000;

  const prepareSections = (radiusMeters) => {
    const result = [];
    const step = 360 / segmentsNumber;

    for (let i = 0; i < segmentsNumber; i++) {
      const path = getArcPath(center, radiusMeters, i * step, (i + 1) * step);

      path.unshift(center);
      path.push(center);

      result.push({
        path: JSON.parse(JSON.stringify(path)),
        color: colors[i % 2],
      });
    }

    setSections(result);
  };

  const prepareLines = (radiusMeters) => {
    const result = [];
    const step = 360 / segmentsNumber;

    for (let i = 0; i < segmentsNumber; i++) {
      const angle = (i + 1) * step;

      if (angle === 90 || angle === 180 || angle === 270 || angle === 360) {
        result.push([
          center,
          computeOffset(
            center,
            radiusMeters,
            angle,
          ),
        ]);
      } else {
        const sqrRadius = radiusMeters * radiusMeters;
        let length = Math.sqrt(2 * sqrRadius);

        if (segmentsNumber === 6) {
          const sideA = Math.sqrt(2 * sqrRadius - 2 * sqrRadius * Math.cos((30 * Math.PI) / 180));
          const sideB = (sideA / Math.sin((60 * Math.PI) / 180)) * Math.sin((15 * Math.PI) / 180);
          length = radiusMeters + sideB;
        }
        result.push([
          center,
          computeOffset(
            center,
            length,
            angle,
          ),
        ]);
      }
    }

    setLines(result);
  };

  const prepareMarkers = (radiusMeters) => {
    const result = [];
    const items = labels[segmentsNumber];

    Object.keys(items).forEach((key) => {
      result.push({
        position: computeOffset(center, radiusMeters, key),
        angle: key,
      });
    });

    setMarkers(result);
  };

  const prepareBounds = (radiusMeters) => {
    const north = computeOffset(center, radiusMeters, 0);
    const south = computeOffset(center, radiusMeters, 180);
    const northEast = computeOffset(north, radiusMeters, 90);
    const northWest = computeOffset(north, radiusMeters, -90);
    const southEast = computeOffset(south, radiusMeters, 90);
    const southWest = computeOffset(south, radiusMeters, -90);

    setBounds([northEast, northWest, southWest, southEast]);
  };

  useEffect(() => {
    if (center.lat && center.lng) {
      const radiusMeters = milesToMeters(miles);

      setSections([]);
      setMarkers([]);
      setLines([]);
      setBounds([]);

      prepareSections(radiusMeters);
      prepareLines(radiusMeters);
      prepareBounds(radiusMeters);
      prepareMarkers(radiusMeters);
    }
  }, [center]);

  useEffect(() => {
    if (sections.length > 0 && !sectionsInit) {
      sections.forEach((section) => {
        const polygon = new window.google.maps.Polygon({
          paths: section.path,
          ...options,
          fillColor: section.color,
          strokeWeight: 0,
        });

        polygon.setMap(map);
        elementsRefs.current.push(polygon);
      });

      setSectionsInit(true);
    }
  }, [sections]);

  useEffect(() => {
    if (lines.length > 0 && !linesInit) {
      lines.forEach((line) => {
        const polygon = new window.google.maps.Polygon({
          paths: line,
          ...options,
        });

        polygon.setMap(map);
        elementsRefs.current.push(polygon);
      });

      setLinesInit(true);
    }
  }, [lines]);

  useEffect(() => () => {
    elementsRefs.current.forEach((element) => {
      element.setMap(null);
    });
  }, []);

  return (
    <>
      {markers.map((marker) => (
        <OverlayView
          key={uniqid()}
          position={marker.position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div
            className={
              cx("areasMarkersContainer", `areasMarkersContainer-${marker.angle}`, {
                segmentsFour: segmentsNumber === 4,
                segmentsSix: segmentsNumber === 6,
              })
            }
          >
            {labels[segmentsNumber][marker.angle].firstLabel && (
              <p className="leftMarker">
                {labels[segmentsNumber][marker.angle].firstLabel}
              </p>
            )}
            {labels[segmentsNumber][marker.angle].secondLabel && (
              <p className="rightMarker">
                {labels[segmentsNumber][marker.angle].secondLabel}
              </p>
            )}
          </div>
        </OverlayView>
      ))}
      <Polygon
        paths={bounds}
        options={options}
      />
    </>
  );
};

Areas.propTypes = {
  center: {
    lat: number,
    lng: number,
  }.isRequired,
  miles: number.isRequired,
  segmentsNumber: number.isRequired,
};

export default Areas;
