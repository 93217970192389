import axios from "axios";

import { SCHEDULER_SOURCE } from "shared/constants";

const GET = "get";
const POST = "post";
const PATCH = "patch";

const CHECKINS_PATH = "/checkins";

const fetchServicesPath = (checkinId, dealershipId) => `checkins/${checkinId}/dealerships/${dealershipId}/menu_items`;
const fetchAppointmentPath = (checkinId) => `/checkins/${checkinId}/appointments`;
const fetchAppointmentSlotsPath = (checkinId) => `/checkins/${checkinId}/appointment_slots`;
const fetchJobSlotsPath = (checkinId) => `/checkins/${checkinId}/jobs/available_slots`;
const fetchRecallsPath = (checkinId) => `/checkins/${checkinId}/recalls`;

const updateAppointmentPath = (checkinId, appointmentId) => `/checkins/${checkinId}/appointments/${appointmentId}`;
const updateJobPath = (checkinId, jobId) => `/checkins/${checkinId}/jobs/${jobId}`;
const cancelJobPath = (checkinId, jobId) => `/checkins/${checkinId}/jobs/${jobId}/cancel`;
const cancelAppointmentPath = (checkinId, appointmentId) => `/checkins/${checkinId}/appointments/${appointmentId}/cancel`;
const decisionTreesPath = (checkinId) => `/checkins/${checkinId}/decision_trees`;
const decisionTreeResultsPath = (checkinId, appointmentId) => `/checkins/${checkinId}/appointments/${appointmentId}/decision_tree_results`;
const fetchTransportPath = (checkinId) => `/checkins/${checkinId}/transports`;

const sendSignaturePath = (checkinId, appointmentId) => `/checkins/${checkinId}/appointments/${appointmentId}/signature`;

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

const apiCall = (path, params = {}, method = GET) => axiosInstance
  .request({
    method,
    url: path,
    params: method === GET ? params : undefined,
    data: method === POST || PATCH ? params : undefined,
  })
  .then((response) => response.data);

export const startCheckIn = (dealership_slug, dealership_id, guid) => apiCall(CHECKINS_PATH, {
  dealership_slug,
  dealership_id,
  guid,
  source: SCHEDULER_SOURCE,
}, POST);

export const fetchAppointmentByPhoneNumber = (checkin_id, phone_number) => (
  apiCall(fetchAppointmentPath(checkin_id), { phone_number }, GET)
);

export const fetchAppointmentByGuid = (checkin_id, guid) => (
  apiCall(fetchAppointmentPath(checkin_id), { guid }, GET)
);

export const fetchUpdateAppointment = (
  checkin_id,
  appointment_id,
  dealership_id,
  order,
  service_ids,
  menu_item_comments,
) => {
  const bookingsMenuItemsIds = order.bookingsMenuItems.map((bmi) => bmi.id);
  return apiCall(
    updateAppointmentPath(checkin_id, appointment_id),
    {
      checkin_id,
      appointment_id,
      dealership_id,
      client_waiting: order.client_waiting,
      phone_number: order.customer.phone_number,
      vehicle_id: order.vehicle.id,
      customer_id: order.customer.id,
      appointment_datetime: order.date,
      menu_item_comments,
      menu_item_ids: service_ids,
      line_item_ids: order.lineItems.map((li) => li.id),
      recall_ids: [...order.selectedRecalls, ...order.preselectedRecalls].map((r) => r.id),
      general_additional_note: order.additionalNotes,
      appraisal_requested: order.appraisalRequested,
      ...(bookingsMenuItemsIds.length > 0 && { booking_menu_item_ids: bookingsMenuItemsIds }),
      ...(menu_item_comments.length > 0 && { menu_item_comments }),
      source: order.source,
    },
    PATCH,
  );
};

export const fetchUpdateJob = (checkin_id, job_id, collection_time) => apiCall(
  updateJobPath(checkin_id, job_id),
  {
    checkin_id,
    job_id,
    collection_time,
  },
  PATCH,
);

export const fetchCancelAppointment = (checkin_id, appointment_id) => (
  apiCall(cancelAppointmentPath(checkin_id, appointment_id), {
    checkin_id,
    appointment_id,
  }, PATCH)
);

export const fetchCancelJob = (checkin_id, job_id) => apiCall(cancelJobPath(checkin_id, job_id), {
  checkin_id,
  job_id,
}, PATCH);

export const fetchServices = (checkinId, dealershipId, vehicle) => apiCall(
  fetchServicesPath(checkinId, dealershipId),
  {
    "vehicle_set[make]": vehicle.make,
    "vehicle_set[model]": vehicle.model,
    "vehicle_set[model_year]": vehicle.year,
    mileage: vehicle.mileage || 0,
  },
);

export const fetchDecisionTrees = (checkin_id, ids) => {
  const params = ids.map((id) => `menu_item_ids[]=${id}`).join("&");
  return axiosInstance.get(`${decisionTreesPath(checkin_id)}/?${params}`).then((res) => res.data);
};

export const sendDecisionTreeResult = (checkin_id, appointment_id, decisionTreeResult) => apiCall(
  decisionTreeResultsPath(checkin_id, appointment_id),
  {
    checkin_id,
    appointment_id,
    menu_item_id: decisionTreeResult.serviceId,
    details: decisionTreeResult.conversation,
    additional_note: decisionTreeResult.description,
  },
  POST,
);

export const fetchAdditionalServices = (checkinId, dealershipId, vehicle) => apiCall(
  fetchServicesPath(checkinId, dealershipId),
  {
    "vehicle_set[make]": vehicle.make,
    "vehicle_set[model]": vehicle.model,
    "vehicle_set[model_year]": vehicle.year,
    mileage: vehicle.mileage,
    only_extension: true,
  },
  GET,
);

export const fetchRecallsForCheckin = (checkin_id) => (
  apiCall(fetchRecallsPath(checkin_id), { checkin_id }, GET)
);

export const sendSignature = (checkin_id, appointment_id, signature) => (
  apiCall(sendSignaturePath(checkin_id, appointment_id), {
    checkin_id,
    appointment_id,
    signature,
  }, PATCH)
);

export const fetchAvailableTransports = (checkin_id, money_spent, appointment_duration) => apiCall(
  fetchTransportPath(checkin_id),
  {
    checkin_id,
    money_spent,
    appointment_duration,
  },
  GET,
);

export const addTransport = (checkinId, appointmentId, kind) => apiCall(
  fetchTransportPath(checkinId),
  {
    checkinId,
    appointment_id: appointmentId,
    kind,
  },
  POST,
);

export const fetchAppointmentSlots = (checkin_id) => (
  apiCall(fetchAppointmentSlotsPath(checkin_id), { checkin_id }, GET)
);

export const fetchJobSlots = (checkin_id) => (
  apiCall(fetchJobSlotsPath(checkin_id), { checkin_id }, GET)
);
