import { connect } from "react-redux";
import {
  serviceAdvisorsSelector,
  settingsAdvisorsLoadingSelector,
  totalAdvisorsSelector,
} from "store/selectors/settings-selectors";
import { retrieveDealershipServiceAdvisors } from "store/actions/settings-actions";
import EmployeesPanel from "./index";

const mapStateToProps = (state) => {
  return {
    employees: serviceAdvisorsSelector(state),
    total: totalAdvisorsSelector(state),
    isLoading: settingsAdvisorsLoadingSelector(state),
  };
};

const actions = {
  fetchEmployees: retrieveDealershipServiceAdvisors,
};

export default connect(mapStateToProps, actions)(EmployeesPanel);
