import {
  MENU_TEMPLATES_ADD_SUCCESS,
  MENU_TEMPLATES_CANCEL_CHANGES,
  MENU_TEMPLATES_DEFAULT_ERROR_HANDLER,
  MENU_TEMPLATES_DELETE_SUCCESS,
  MENU_TEMPLATES_DUPLICATE_SUCCESS,
  MENU_TEMPLATES_FETCH_DEALERSHIPS_RECALLS_SUCCESS,
  MENU_TEMPLATES_FETCH_SUCCESS,
  MENU_TEMPLATES_SET_LOADING_STATE,
  MENU_TEMPLATES_TOGGLE_SUCCESS,
  MENU_TEMPLATES_UPDATE_DEALERSHIPS_RECALL_SUCCESS,
  MENU_TEMPLATES_UPDATE_FAILURE,
  MENU_TEMPLATES_UPDATE_SUCCESS,
} from "store/actions/menu-templates-actions";

const initialState = {
  isLoading: false,
  requestId: 0,
  data: [],
  recalls: [],
  totalRecalls: 0,
  error: null,
  updateTemplatesError: null,
};

const menuTemplates = (state = initialState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case MENU_TEMPLATES_DEFAULT_ERROR_HANDLER:
      return {
        ...state,
        error: payload.error.response,
        isLoading: false,
      };
    case MENU_TEMPLATES_SET_LOADING_STATE:
      return {
        ...state,
        requestId: meta.requestId ? meta.requestId : state.requestId,
        isLoading: true,
        updateTemplatesError: null,
        error: null,
      };
    case MENU_TEMPLATES_CANCEL_CHANGES:
      return {
        ...state,
        isLoading: false,
      };
    case MENU_TEMPLATES_FETCH_SUCCESS:
      if (meta.requestId !== state.requestId) {
        return state;
      }
      return {
        ...state,
        error: null,
        data: payload,
        isLoading: false,
      };

    case MENU_TEMPLATES_UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map((t) => (t.id === payload.id ? {
          ...t,
          name: payload.params.templateName,
          ignore_vehicle_mileage: payload.params.ignoreMileage,
          active: payload.params.templateIsActive,
        } : t)),
        updateTemplatesError: null,
        isLoading: false,
      };
    case MENU_TEMPLATES_UPDATE_FAILURE:
      return {
        ...state,
        updateTemplatesError: payload.error.response,
        isLoading: false,
      };
    case MENU_TEMPLATES_DUPLICATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [
          ...state.data,
          payload.template,
        ],
      };
    case MENU_TEMPLATES_TOGGLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.map((t) => (t.id === payload.id ? {
          ...t,
          active: !t.active,
        } : t)),
      };
    case MENU_TEMPLATES_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter((t) => t.id !== payload.id),
      };
    case MENU_TEMPLATES_ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [
          payload.template,
          ...state.data,
        ],
      };
    case MENU_TEMPLATES_FETCH_DEALERSHIPS_RECALLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recalls: payload.recalls,
        totalRecalls: Number(payload.totalRecalls || 0),
      };
    case MENU_TEMPLATES_UPDATE_DEALERSHIPS_RECALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recalls: state.recalls.map((recall) => {
          if (recall.id === payload.id) {
            return payload;
          }

          return recall;
        }),
      };
    default:
      return state;
  }
};

export default menuTemplates;
