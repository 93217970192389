import React, { useState } from "react";
import { func, string } from "prop-types";
import { connect } from "react-redux";
import { editService } from "store/actions/menu-template-actions";
import OpCodesSelect from "components/common/OpCodesSelect";
import Modal from "components/common/Modal";
import { formatInput } from "shared/utils/common";

import "./styles.scss";

const EditModal = (props) => {
  const [name, setName] = useState(props.name);
  const [opCode, setOpCode] = useState(props.opCode);

  const submit = () => {
    if (opCode) {
      props.onSubmit({
        ...props,
        name: formatInput(name),
        opCodeId: opCode.value,
        opCodeName: opCode.code,
      });
    } else {
      props.onSubmit({
        ...props,
        name: formatInput(name),
      });
    }
    props.onClose();
  };

  return (
    <Modal
      className="menuTemplateEditModal"
      title="Edit"
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="small"
      onCancel={props.onClose}
      onSubmit={submit}
    >
      <div className="editModalLabel">
        Service name
      </div>
      <input
        className="editModalInput"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <div className="editModalLabel editModalLabelTopMargin">
        Op code
      </div>
      <OpCodesSelect
        showAssigned
        opCode={opCode}
        onChange={setOpCode}
      />
    </Modal>
  );
};

EditModal.propTypes = {
  opCode: string.isRequired,
  name: string.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
};

const actions = {
  onSubmit: editService,
};

const EditModalContainer = connect(null, actions)(EditModal);

export default EditModalContainer;
