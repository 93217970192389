import React, { useCallback, useEffect, useState } from "react";
import {
  bool, func, number, shape,
} from "prop-types";
import cx from "classnames";
import { remoteAdditionalInfoJobPropType } from "components/common/Remote/propTypes";

import PlacesAutocomplete, {
  geocodeByAddress, getLatLng,
} from "react-places-autocomplete";

import "./styles.scss";

const zone = ["A", "B", "C", "D", "E", "F", "G", "H"];

const LocationInput = ({
  isPickUp,
  isDropOff,
  isSameLocation,
  additionalInfo,
  additionalInfo: { pickUp, dropOff },
  onChange,
  disabled,
  dealershipPosition,
  segmentsNumber,
}) => {
  const [address, setAddress] = useState("");

  const onChangeLocationData = useCallback((data) => {
    if (isSameLocation) {
      onChange({
        pickUp: {
          ...pickUp,
          ...data,
        },
        dropOff: {
          ...dropOff,
          ...data,
        },
      });
    } else if (isPickUp) {
      onChange({
        pickUp: {
          ...pickUp,
          ...data,
        },
      });
    } else if (isDropOff) {
      onChange({
        dropOff: {
          ...dropOff,
          ...data,
        },
      });
    }
  }, [onChange, isSameLocation]);

  useEffect(() => {
    if (isSameLocation && isDropOff) {
      setAddress(pickUp.address);
    }
  }, [isSameLocation, additionalInfo]);

  useEffect(() => {
    if (isSameLocation) {
      setAddress(pickUp.address);
      // eslint-disable-next-line no-use-before-define
      handleSelect(pickUp.address);
    } else if (isPickUp) {
      setAddress(pickUp.address);
      // eslint-disable-next-line no-use-before-define
      handleSelect(pickUp.address);
    } else if (isDropOff) {
      setAddress(dropOff.address);
      // eslint-disable-next-line no-use-before-define
      handleSelect(dropOff.address);
    }
  }, []);

  const labelLocation = () => {
    if (isPickUp) {
      return "Pick-up Location";
    }
    if (isDropOff) {
      return "Drop-off Location";
    }
    return "";
  };

  const handleSelect = async (value) => {
    if (value) {
      const results = await geocodeByAddress(value);
      const geolocation = await getLatLng(results[0]);

      let heading = window.google.maps.geometry.spherical.computeHeading(
        dealershipPosition,
        geolocation,
      );

      const service = new window.google.maps.DistanceMatrixService();
      let distance;

      await service.getDistanceMatrix({
        destinations: [geolocation],
        origins: [dealershipPosition],
        travelMode: "DRIVING",
        // eslint-disable-next-line no-undef
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      }, (response, status) => {
        if (status === "OK") {
          distance = response.rows[0].elements[0].distance.value * 0.000621371;
        }
      });

      heading = heading > 0 ? heading : heading + 360;
      const angle = 360 / segmentsNumber;
      const index = Math.ceil(heading / angle) > 0 ? Math.ceil(heading / angle) - 1 : 0;

      const formattedAddress = results[0].formatted_address;
      const addressComponents = results[0].address_components;

      const getAddressComponentByType = (components, type) => {
        for (let i = 0; i < components.length; i++) {
          if (components[i].types.indexOf(type) > -1) {
            return components[i].long_name;
          }
        }

        return null;
      };

      const formated = {
        address: formattedAddress,
        addressData: {
          address_line1: getAddressComponentByType(addressComponents, "street_number"),
          address_line2: getAddressComponentByType(addressComponents, "route"),
          address_city: getAddressComponentByType(addressComponents, "locality") || getAddressComponentByType(addressComponents, "sublocality"),
          address_state: getAddressComponentByType(addressComponents, "administrative_area_level_1"),
          address_zipcode: getAddressComponentByType(addressComponents, "postal_code"),
        },
        zone: zone[index],
        geolocation,
        distance,
      };

      setAddress(formattedAddress);
      onChangeLocationData(formated);
    } else {
      onChange(additionalInfo);
    }
  };

  const handleChange = (value) => {
    setAddress(value);

    if (value.length === 0) {
      onChangeLocationData({
        address: value,
        addressData: null,
      });
    }
  };

  return (
    <div className="additionalInfoItemColumn fullWidth">
      <div className="conciergeInputLabel">
        {labelLocation()}
      </div>
      <PlacesAutocomplete
        value={address}
        onChange={isSameLocation && isDropOff ? null : handleChange}
        onSelect={handleSelect}
        searchOptions={{
          types: ["address"],
          componentRestrictions: {
            country: ["us", "ca"],
          },
        }}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <div className="additionalInfoItemLocationWrapper">
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: cx("additionalInfoItemLocationInput", {
                  additionalInfoItemLocationInputDisabled: isSameLocation,
                }),
              })}
              disabled={disabled || (isSameLocation && isDropOff)}
            />
            {suggestions.length > 0 && (
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item suggestion-item--active"
                    : "suggestion-item";
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

LocationInput.propTypes = {
  isPickUp: bool,
  isDropOff: bool,
  isSameLocation: bool,
  onChange: func.isRequired,
  additionalInfo: shape({
    pickUp: remoteAdditionalInfoJobPropType,
    dropOff: remoteAdditionalInfoJobPropType,
    isSameLocation: bool,
  }),
  disabled: bool,
  dealershipPosition: {
    lat: number,
    lng: number,
  }.isRequired,
  segmentsNumber: number.isRequired,
};

LocationInput.defaultProps = {
  isPickUp: false,
  isDropOff: false,
  isSameLocation: true,
  additionalInfo: {},
  disabled: false,
};

export default LocationInput;
