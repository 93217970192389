import { toFixedPrecision } from "shared/utils";
import { SCHEDULER_SOURCE } from "shared/constants";

import {
  CHECKIN_CHAT_ADD_SERVICES,
  CHECKIN_CHAT_CHANGE_STEP,
  CHECKIN_CHAT_CLEAR_ADDITIONAL_SERVICES,
  CHECKIN_CHAT_CLEAR_SELECTED_RECALLS,
  CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS,
  CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_ERROR,
  CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_SUCCESS,
  CHECKIN_CHAT_FETCH_DECISION_TREES,
  CHECKIN_CHAT_FETCH_SERVICES_SUCCESS,
  CHECKIN_CHAT_FINALIZE_APPOINTMENT,
  CHECKIN_CHAT_INITIALIZE,
  CHECKIN_CHAT_REMOVE_LINE_ITEM,
  CHECKIN_CHAT_REMOVE_SERVICE,
  CHECKIN_CHAT_SET_ADDITIONAL_SERVICES,
  CHECKIN_CHAT_SET_AVAILABLE_RECALLS,
  CHECKIN_CHAT_SET_CHAT_LOADING_STATE,
  CHECKIN_CHAT_SET_LAST_REQUEST,
  CHECKIN_CHAT_SET_PRESELECTED_RECALLS,
  CHECKIN_CHAT_TOGGLE_EXTENSION,
  CHECKIN_CHAT_TOGGLE_RECALL,
  CHECKIN_CHAT_UPDATE_CURRENT_STEP,
  CHECKIN_CHAT_UPDATE_SIGNATURE,
} from "store/actions/checkin-chat-actions";

import {
  CHECKIN_DECISION_TREES_INITIALIZE,
  CHECKIN_DECISION_TREES_SET_DESCRIPTION,
  CHECKIN_DECISION_TREES_UPDATE,
} from "store/actions/checkin-decision-trees-actions";

import { CHECKIN_APP_BACK_FROM_CHAT, CHECKIN_APP_EXIT, CHECKIN_APP_NOT_SYNCED } from "store/actions/checkin-app-actions";
import STEPS, { MAX_PROGRESS } from "components/steps/index";
import { SOURCE_CONCIERGE } from "shared/constants/text-messages";

const removeService = (collection = [], id) => collection.filter(
  (service) => service.id !== id && service.menu_item_id !== id,
);

const toggleService = (collection, service) => (collection.find(({ id }) => service.id === id)
  ? collection.filter(({ id }) => id !== service.id)
  : collection.concat(service));

const removeCorrespondingServices = (services, lineItems, id) => {
  const lineItem = lineItems.find((item) => item.id === id);

  return {
    maintenance: services.maintenance.filter(({ name }) => name !== lineItem.name),
    concern: services.concern.filter(({ name }) => name !== lineItem.name),
  };
};

const initialState = {
  lastId: 0,
  isLoading: false,
  history: [],
  order: {
    services: {},
    jobs: [],
    lineItems: [],
    preselectedRecalls: [],
    selectedRecalls: [],
    customer: {
      first_name: "Test",
      last_name: "Aga",
      email: "aga@a.pl",
      phone_number: "123123123",
      customer_number: "1027657",
      state: "created",
    },
    vehicle: {
      id: 2,
      plate_number: "DEF-123",
      region: null,
      vin: "TESTVINVWGOLF2016",
      state: "created",
      customer_number: "1027657",
      mileage: 49002,
    },
    advisor: {
      name: "",
      photo: null,
      phone: null,
    },
    date: null,
    additionalServices: [],
    source: SCHEDULER_SOURCE,
  },
  uber: {},
  availableServices: {},
  availableRecalls: [],
  additionalServices: [],
  decisionTrees: [],
  decisionTreeResults: [],
  progress: 0,
  lastRequest: null,
  isVehicleSynced: true,
  isInitialized: false,
  isCustomerPagesInitialized: false,
  customerProvidedPhoneNumber: false,
  isAppointmentFinalized: false,
  isRescheduleLoading: false,
  bookingStepProps: null,
  bookingStepInputProps: null,
};

/* eslint-disable no-case-declarations */

const chat = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHECKIN_APP_EXIT:
      return {
        ...initialState,
        order: state.order,
      };
    case CHECKIN_APP_BACK_FROM_CHAT:
      return {
        ...state,
        isInitialized: false,
      };
    case CHECKIN_CHAT_SET_LAST_REQUEST:
      return {
        ...state,
        lastRequest: payload.request,
      };
    case CHECKIN_CHAT_CHANGE_STEP:
      const step = {
        step: payload.step,
        component: STEPS[payload.step].step,
        props: payload.props || {},
        id: state.lastId + 1,
      };

      return {
        ...state,
        history: [...state.history, step],
        currentInput: STEPS[payload.step].input && {
          component: STEPS[payload.step].input,
          props: {
            stepId: state.lastId + 1,
            ...payload.inputProps,
          },
        },
        progress: toFixedPrecision(
          (STEPS[payload.step].progress * 100) / MAX_PROGRESS,
          2,
        ) || 0,
        lastId: state.lastId + 1,
      };
    case CHECKIN_CHAT_UPDATE_CURRENT_STEP:
      if (!state.isInitialized) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        history: state.history.slice(0, -1).concat({
          ...state.history[state.history.length - 1],
          props: {
            ...state.history[state.history.length - 1].props,
            ...payload.props,
          },
        }),
        currentInput: state.currentInput && {
          ...state.currentInput,
          props: {
            ...state.currentInput.props,
            ...payload.inputProps,
          },
        },
      };
    case CHECKIN_CHAT_SET_CHAT_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.value,
        loadingStateDelay: payload.delay,
      };
    case CHECKIN_CHAT_INITIALIZE:
      return {
        ...state,
        isInitialized: true,
        history: [],
        currentInput: null,
        lastId: 0,

      };

    case CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_SUCCESS:
      const {
        menu_items: menuItems,
        bookings_menu_items: bookingsMenuItems,
        decision_tree_results: decisionTreeResults,
        client_waiting,
      } = payload;
      const { maintenance = [], concern = [] } = menuItems || {};

      return {
        ...state,
        checkinAppointmentLoading: false,
        checkinAppointmentError: null,
        isVehicleSynced: payload.vehicle.state !== CHECKIN_APP_NOT_SYNCED,
        isPickupJobCheckin:
          payload.jobs.length
          && !!payload.jobs.find((job) => job.job_type === "pickup"),
        order: {
          ...state.order,
          appointment: {
            ...payload,
          },
          date: payload.appointment_datetime,
          client_waiting,
          services: {
            maintenance,
            concern,
          },
          bookingsMenuItems,
          decisionTreeResults,
          customer: {
            id: payload.customer.id,
            name: payload.customer.first_name,
            lastName: payload.customer.last_name,
            phone_number: [
              state.order.customer && state.order.customer.phoneNumber,
              payload.customer.phone_number,
            ]
              .filter((number) => number !== "0")
              .pop(),
            customer_number: payload.customer.customer_number,
          },
          vehicle: {
            ...payload.vehicle,
            make: payload.vehicle_set.make,
            model: payload.vehicle_set.model,
            year: payload.vehicle_set.model_year,
            mileage: payload.vehicle.mileage,
          },
          jobs: payload.jobs,
          lineItems: payload.line_items || [],
          advisor: payload.service_advisor && {
            name: payload.service_advisor.name,
            photo: payload.service_advisor.photo.url,
            phone: "",
          },
          qrCode: payload.qr_code && payload.qr_code.url,
        },
      };
    case CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS: {
      return {
        ...state,
        checkinAppointmentLoading: true,
        checkinAppointmentError: null,
      };
    }
    case CHECKIN_CHAT_FETCH_APPOINTMENT_DETAILS_ERROR: {
      return {
        ...state,
        checkinAppointmentLoading: false,
        checkinAppointmentError: payload,
      };
    }

    case CHECKIN_CHAT_FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        availableServices: {
          maintenance: payload.maintenance || [],
          concern: payload.concern || [],
        },
      };
    case CHECKIN_CHAT_REMOVE_SERVICE:
      return {
        ...state,
        order: {
          ...state.order,
          services: {
            maintenance: removeService(state.order.services.maintenance, payload.id),
            concern: removeService(state.order.services.concern, payload.id),
          },
          bookingsMenuItems: removeService(state.order.bookingsMenuItems, payload.id),
          additionalServices: removeService(state.order.additionalServices, payload.id),
          selectedRecalls: removeService(state.order.selectedRecalls, payload.id),
        },
      };
    case CHECKIN_CHAT_REMOVE_LINE_ITEM:
      return {
        ...state,
        order: {
          ...state.order,
          services: removeCorrespondingServices(
            state.order.services,
            state.order.lineItems,
            payload.id,
          ),
          lineItems: removeService(state.order.lineItems, payload.id),
        },
      };
    case CHECKIN_CHAT_ADD_SERVICES:
      return {
        ...state,
        order: {
          ...state.order,
          services: {
            maintenance: (
              (state.order.services || {}).maintenance || []
            ).concat(payload.maintenance),
            concern: (
              (state.order.services || {}).concern || []
            ).concat(payload.concern),
          },
        },
      };
    case CHECKIN_CHAT_FETCH_DECISION_TREES:
      return {
        ...state,
        decisionTrees: payload.map((decisionTree) => {
          return {
            ...decisionTree,
            serviceId: decisionTree.menu_item.id,
          };
        }),
      };
    case CHECKIN_DECISION_TREES_INITIALIZE:
      return {
        ...state,
        decisionTreeResults: state.decisionTreeResults.concat({
          serviceId: payload.serviceId,
          conversation: [{
            text: payload.intro,
            source: SOURCE_CONCIERGE,
          }],
        }),
      };
    case CHECKIN_DECISION_TREES_UPDATE:
      return {
        ...state,
        decisionTreeResults: [
          ...state.decisionTreeResults.slice(0, -1),
          {
            ...state.decisionTreeResults[state.decisionTreeResults.length - 1],
            conversation: [
              ...state.decisionTreeResults[state.decisionTreeResults.length - 1].conversation,
              {
                text: payload.text,
                source: payload.source,
              },
            ],
          },
        ],
      };
    case CHECKIN_DECISION_TREES_SET_DESCRIPTION:
      return {
        ...state,
        decisionTreeResults: [
          ...state.decisionTreeResults.slice(0, -1),
          {
            ...state.decisionTreeResults[state.decisionTreeResults.length - 1],
            description: payload.description,
          },
        ],
      };
    case CHECKIN_CHAT_SET_ADDITIONAL_SERVICES:
      return {
        ...state,
        additionalServices: payload && payload.extension,
        order: {
          ...state.order,
          additionalServices: [],
        },
      };
    case CHECKIN_CHAT_CLEAR_ADDITIONAL_SERVICES:
      return {
        ...state,
        order: {
          ...state.order,
          additionalServices: [],
        },
      };
    case CHECKIN_CHAT_CLEAR_SELECTED_RECALLS:
      return {
        ...state,
        order: {
          ...state.order,
          selectedRecalls: [],
        },
      };
    case CHECKIN_CHAT_TOGGLE_EXTENSION:
      return {
        ...state,
        order: {
          ...state.order,
          additionalServices: toggleService(state.order.additionalServices, payload.service),
        },
      };
    case CHECKIN_CHAT_SET_PRESELECTED_RECALLS: {
      const { preselectedRecalls } = payload;

      return {
        ...state,
        order: {
          ...state.order,
          bookingsMenuItems: state.order.bookingsMenuItems.filter(
            (bmi) => preselectedRecalls.find(
              (recall) => bmi.recall_id === recall.id,
            ),
          ),
          preselectedRecalls,
        },
      };
    }
    case CHECKIN_CHAT_SET_AVAILABLE_RECALLS: {
      return {
        ...state,
        availableRecalls: payload.recalls,
        order: {
          ...state.order,
          selectedRecalls: [],
        },
      };
    }
    case CHECKIN_CHAT_TOGGLE_RECALL:
      return {
        ...state,
        order: {
          ...state.order,
          selectedRecalls: toggleService(state.order.selectedRecalls, payload.service),
        },
      };
    case CHECKIN_CHAT_UPDATE_SIGNATURE:
      return {
        ...state,
        order: {
          ...state.order,
          signature: payload.signature,
        },
      };
    case CHECKIN_CHAT_FINALIZE_APPOINTMENT: {
      return {
        ...state,
        isAppointmentFinalized: payload,
      };
    }
    default:
      return state;
  }
};

export default chat;
