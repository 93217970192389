import {
  DEALERSHIP_DETAILS_ADD_INTEGRATION_FAILURE,
  DEALERSHIP_DETAILS_ADD_INTEGRATION_SUCCESS,
  DEALERSHIP_DETAILS_ADD_MAINTAINER_FAILURE,
  DEALERSHIP_DETAILS_ADD_MAINTAINER_SUCCESS,
  DEALERSHIP_DETAILS_DELETE_MAINTAINER_FAILURE,
  DEALERSHIP_DETAILS_DELETE_MAINTAINER_SUCCESS,
  DEALERSHIP_DETAILS_FETCH_FAILURE,
  DEALERSHIP_DETAILS_FETCH_SUCCESS,
  DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_FAILURE,
  DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_SUCCESS,
  DEALERSHIP_DETAILS_SET_LOADING_STATE,
  DEALERSHIP_DETAILS_UPDATE_INTEGRATION_FAILURE,
  DEALERSHIP_DETAILS_UPDATE_INTEGRATION_SUCCESS,
  DEALERSHIP_DETAILS_UPDATE_MAINTAINER_FAILURE,
  DEALERSHIP_DETAILS_UPDATE_MAINTAINER_SUCCESS,
} from "store/actions/dealership-details-actions";

const initialState = {
  isLoading: false,
  data: {},
  blockedDays: [],
  error: null,
};

const dealership = (state = initialState, action) => {
  switch (action.type) {
    case DEALERSHIP_DETAILS_SET_LOADING_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case DEALERSHIP_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: null,
        isLoading: false,
      };
    case DEALERSHIP_DETAILS_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isLoading: false,
      };
    case DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          team_tags: action.payload,
        },
        error: null,
        isLoading: false,
      };
    case DEALERSHIP_DETAILS_FETCH_TEAM_TAGS_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isLoading: false,
      };
    case DEALERSHIP_DETAILS_DELETE_MAINTAINER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          dealership_admin_email: state.data.dealership_admin_email.filter((user) => (
            user.id !== action.payload.id
          )),
        },
        isLoading: false,
      };
    case DEALERSHIP_DETAILS_UPDATE_MAINTAINER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          dealership_admin_email: state.data.dealership_admin_email.map((user) => (
            user.id === action.payload.id ? {
              ...user,
              email: action.payload.email,
            } : user
          )),
        },
        isLoading: false,
      };
    case DEALERSHIP_DETAILS_ADD_MAINTAINER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          dealership_admin_email: [
            ...state.data.dealership_admin_email,
            action.payload,
          ],
        },
        isLoading: false,
      };
    case DEALERSHIP_DETAILS_ADD_INTEGRATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          integrations: [
            ...state.data.integrations,
            action.payload,
          ],
        },
        isLoading: false,
        error: null,
      };
    case DEALERSHIP_DETAILS_UPDATE_INTEGRATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          integrations: (state.data.integrations).map((integration) => (
            integration.id !== action.payload.id ? integration : {
              ...integration,
              ...action.payload,
              settings: {
                ...integration.settings,
                ...action.payload.settings,
              },
            }
          )),
          remote_enabled: "remote_enabled" in action.payload
            ? action.payload.remote_enabled
            : state.data.remote_enabled,
          mobile_technician_enabled: "mobile_technician_enabled" in action.payload
            ? action.payload.mobile_technician_enabled
            : state.data.mobile_technician_enabled,
        },
        error: null,
      };
    case DEALERSHIP_DETAILS_UPDATE_INTEGRATION_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
      };
    case DEALERSHIP_DETAILS_DELETE_MAINTAINER_FAILURE:
    case DEALERSHIP_DETAILS_ADD_MAINTAINER_FAILURE:
    case DEALERSHIP_DETAILS_UPDATE_MAINTAINER_FAILURE:
    case DEALERSHIP_DETAILS_ADD_INTEGRATION_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default dealership;
