import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, node,
} from "prop-types";
import { connect } from "react-redux";
import { format } from "date-fns";
import { Calendar } from "react-multi-date-picker";
import ClipLoader from "react-spinners/ClipLoader";
import {
  addNonWorkingDay,
  deleteNonWorkingDay,
  getNonWorkingDays,
} from "store/actions/settings-actions";
import { settingsNonWorkingDaysLoadingSelector, settingsNonWorkingDaysSelector } from "store/selectors/settings-selectors";
import { DATE_FORMAT } from "shared/utils/datetime";
import Accordion from "components/common/Accordion";
import Button from "components/common/Button";
import "./styles.scss";

const HolidaysPanel = ({
  nonWorkingDays,
  addHoliday,
  deleteHoliday,
  fetchNonWorkingDays,
  isLoading,
}) => {
  const currentYear = new Date().getFullYear();
  const [holidays, setHolidays] = useState([]);
  const [isRequesting, setIsRequesting] = useState(false);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    const receivedDates = nonWorkingDays.map((i) => i.date);
    setHolidays(receivedDates);
  }, [nonWorkingDays]);

  useEffect(() => {
    fetchNonWorkingDays(selectedYear);
  }, [selectedYear]);

  useEffect(() => {
    if (isRequesting) {
      const currentDates = holidays.map((date) => format(date, DATE_FORMAT));

      if (nonWorkingDays.length > currentDates.length) {
        const item = Object.values(nonWorkingDays)
          .filter((x) => !currentDates.includes(x.date));
        deleteHoliday(item[0].id);
      } else {
        const item = currentDates.at(-1);
        addHoliday(item);
      }

      setIsRequesting(false);
    }
  }, [isRequesting]);

  const handleYearChange = (direction) => {
    setSelectedYear(
      direction === "right"
        ? selectedYear + 1
        : selectedYear - 1,
    );
  };

  const navigationButton = (direction, handleClick) => (
    <Button
      variant="base-grey"
      padding="small"
      leftIcon={direction === "left" ? "leftArrow" : ""}
      rightIcon={direction === "right" ? "rightArrow" : ""}
      withoutBorder
      onClick={() => {
        handleClick();
        handleYearChange(direction);
      }}
    />
  );

  return (
    <Accordion
      title="Dealership Holidays"
      customClass="settingsAccordion"
    >
      <div className="holidaysCalendar">
        <Calendar
          value={holidays}
          fullYear
          multiple
          shadow={false}
          renderButton={navigationButton}
          onChange={setHolidays}
          onPropsChange={() => {
            setIsRequesting(true);
          }}
        />
        {isLoading && (
          <div className="holidaysLoading">
            <ClipLoader size={60} color="#0bcaf9" />
          </div>
        )}
      </div>
    </Accordion>
  );
};

HolidaysPanel.propTypes = {
  nonWorkingDays: arrayOf(node),
  addHoliday: func.isRequired,
  deleteHoliday: func.isRequired,
  fetchNonWorkingDays: func.isRequired,
  isLoading: bool.isRequired,
};

HolidaysPanel.defaultProps = {
  nonWorkingDays: [],
};

const mapStateToProps = (state) => {
  return {
    nonWorkingDays: settingsNonWorkingDaysSelector(state),
    isLoading: settingsNonWorkingDaysLoadingSelector(state),
  };
};

const actions = {
  addHoliday: addNonWorkingDay,
  deleteHoliday: deleteNonWorkingDay,
  fetchNonWorkingDays: getNonWorkingDays,
};

export default connect(mapStateToProps, actions)(HolidaysPanel);
