import React from "react";
import { connect } from "react-redux";
import {
  bool, func,
} from "prop-types";

import { DELAY_1500 } from "shared/constants/delays";
import { exit } from "store/actions/checkin-app-actions";
import { appDealershipSlugSelector } from "store/selectors/checkin-app-selectors";

import animationWrapper from "components/common/checkin/animationWrapper";
import Button from "components/common/checkin/Button";

import styles from "./styles.module.scss";

const FinalStepInput = ({ onExit, isComplete }) => (
  isComplete && (
    <div className={styles.chatInput}>
      <Button delay={DELAY_1500} caption="Thanks" onClick={() => onExit()} />
    </div>
  )
);

FinalStepInput.propTypes = {
  onExit: func.isRequired,
  isComplete: bool,
};

FinalStepInput.defaultProps = {
  isComplete: false,
};

const mapStateToProps = (state) => {
  return { dealershipSlug: appDealershipSlugSelector(state) };
};

const actions = {
  onExit: exit,
};

const FinalStepInputContainer = connect(mapStateToProps, actions)(FinalStepInput);

export default animationWrapper(FinalStepInputContainer, DELAY_1500);
