import React, { useState } from "react";
import uniqid from "uniqid";
import { node, objectOf } from "prop-types";
import { isEmpty } from "ramda";
import Block from "components/common/Block";
import serviceIconsMap from "shared/serviceIconsMap";
import downIcon from "assets/images/down.svg";
import upIcon from "assets/images/up.svg";

import styles from "../styles.module.scss";

const SelectedServices = ({ services }) => {
  const [openItemIndex, setOpenItemIndex] = useState(null);

  if (isEmpty(services)) {
    return (
      <section>
        <Block>
          No services specified
        </Block>
      </section>
    );
  }

  return (
    <section>
      {services.map((menuItem) => {
        if (typeof (menuItem.vin_specific) === "boolean") {
          const kind = menuItem.vin_specific ? "vinSpecificRecall" : "recall";
          return {
            ...menuItem,
            kind,
          };
        }
        return menuItem;
      }).map((menuItem, index) => {
        const {
          additionalInfo,
          decisionTreeResult,
          fee,
          id,
          kind,
          name,
        } = menuItem;
        const isCurrentlySelected = openItemIndex === index;
        const isEmptyContent = decisionTreeResult || additionalInfo;

        return (
          <section key={id}>
            <div className={styles.section}>
              <div className={styles.service}>
                <img
                  src={serviceIconsMap[kind]}
                  className={styles.icon}
                  alt=""
                />
              </div>
              <div className={styles.service}>
                <span className={styles.name}>{name}</span>
                <div className={styles.price}>
                  <span className={styles.label}>Price:</span>
                  <span className={styles.value}>{`$${fee}`}</span>
                </div>
              </div>
              {isEmptyContent && (
                <div className={styles.toggle}>
                  <img
                    src={isCurrentlySelected ? upIcon : downIcon}
                    alt="concierge toggle"
                    onClick={() => setOpenItemIndex(openItemIndex === index ? null : index)}
                  />
                </div>
              )}
            </div>
            {isCurrentlySelected && (
              <section className={styles.accordionContent}>
                {decisionTreeResult && Object.entries(decisionTreeResult).map(
                  ([key, value], decisionTreeResultIndex) => (
                    <section
                      className={styles.accordionBlock}
                      key={uniqid()}
                    >
                      <span className={styles.accordionBlockLabel}>
                        {decisionTreeResultIndex + 1}
                        {". "}
                      </span>
                      <span className={styles.accordionBlockValue}>
                        {`${key} /`}
                      </span>
                      <span className={styles.accordionBlockAnswer}>
                        {value}
                      </span>
                    </section>
                  ),
                )}
                {additionalInfo && (
                  <section className={styles.accordionBlock}>
                    <span className={styles.accordionBlockValue}>
                      Customer States:
                    </span>
                    <span className={styles.accordionBlockAnswer}>
                      {additionalInfo}
                    </span>
                  </section>
                )}
              </section>
            )}
          </section>
        );
      })}
    </section>
  );
};

SelectedServices.propTypes = {
  services: objectOf(node),
};

SelectedServices.defaultProps = {
  services: null,
};

export default SelectedServices;
