import React, { useState } from "react";
import { arrayOf, string } from "prop-types";
import uniqid from "uniqid";
import down from "assets/images/down.svg";
import up from "assets/images/up.svg";

import styles from "./styles.module.scss";

const PackageItems = ({ packageItems, extras, premium_extras }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div
        className={styles.title}
        onClick={() => setIsOpen(!isOpen)}
      >
        See operations included
        <img
          className={styles.icon}
          src={isOpen ? up : down}
          alt=""
        />
      </div>
      {isOpen && (
        <div className={styles.list}>
          {premium_extras.length > 0 && (
            <span className={styles.includedTitle}>
              Premium services included:
            </span>
          )}
          {premium_extras.map((item) => (
            <span
              key={uniqid()}
              className={styles.listItem}
            >
              {item}
            </span>
          ))}
          <span className={styles.includedTitle}>
            Services included:
          </span>
          {[...packageItems, ...extras].map((item) => (
            <span
              key={uniqid()}
              className={styles.listItem}
            >
              {item.name || item}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

PackageItems.propTypes = {
  packageItems: arrayOf({
    name: string,
  }).isRequired,
  extras: arrayOf(string),
  premium_extras: arrayOf(string),
};

PackageItems.defaultProps = {
  extras: [],
  premium_extras: [],
};

export default PackageItems;
