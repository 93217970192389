import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, node, objectOf, string,
} from "prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import {
  endOfMonth, endOfWeek, format, startOfMonth, startOfWeek,
} from "date-fns";
import {
  BOOKINGS_DATEPICKER_DATE_FORMAT,
  BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP,
  parseBookingsDatePickerDate,
} from "shared/utils/datetime";
import { settingsTimezoneSelector } from "store/selectors/settings-selectors";
import Tabs from "components/common/Tabs";
import { retrieveEmployeeReports } from "store/actions/reporting-actions";
import Panel from "components/common/Panel";
import PageHeader from "components/common/PageHeader";
import DatePicker from "components/common/DatePicker";

import "react-table-6/react-table.css";
import "./styles.scss";
import {
  employeeReportsSelector,
  reportsErrorSelector,
  reportsLoadingStateSelector,
} from "store/selectors/reporting-selectors";
import { reportsPropType } from "shared/prop-types";
import { ReportingssCell } from "components/common/ReactTableElements/cellRenderers";

const tabsConfig = [
  {
    label: "Team Performance",
    value: "teamPerformance",
  },
];

const daysColums = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Other period",
    value: "other",
  },
];

const columns = [
  {
    Header: "Name",
    accessor: "created_by_name",
    width: 180,
  },
  {
    Header: "Role",
    accessor: "created_by_role",
    width: 88,
  },
  {
    Header: "Total Appointments",
    accessor: "appointments_total",
    width: 130,
  },
  {
    Header: "Total appointments with premium",
    accessor: "appointments_total_showed_with_premium",
    width: 150,
  },
  {
    Header: "Attended appointments",
    accessor: "appointments_total_showed_formatted",
    width: 130,
    Cell: (reports) => (
      <ReportingssCell
        total_formatted={reports?.original?.appointments_total_showed_formatted}
        total={reports?.original?.appointments_total_showed}
        readOnly
      />
    ),
  },
  {
    Header: "Canceled appointments",
    accessor: "appointments_total_canceled_formatted",
    width: 130,
    Cell: (reports) => (
      <ReportingssCell
        total_formatted={reports?.original?.appointments_total_canceled_formatted}
        total={reports?.original?.appointments_total_canceled}
        readOnly
      />
    ),
  },
  {
    Header: "No show appointments",
    accessor: "appointments_total_not_showed_formatted",
    width: 130,
    Cell: (reports) => (
      <ReportingssCell
        total_formatted={reports?.original?.appointments_total_not_showed_formatted}
        total={reports?.original?.appointments_total_not_showed}
        readOnly
      />
    ),
  },
  {
    Header: "Average Value",
    accessor: "appointments_average_value",
    width: 100,
    Cell: (reports) => (
      <div>{`$${reports.original.appointments_average_value}`}</div>
    ),
  },
  {
    Header: "Average value for attended appointments",
    accessor: "appointments_average_value_showed",
    width: 150,
    Cell: (reports) => (
      <div>{`$${reports.original.appointments_average_value_showed}`}</div>
    ),
  },
];

const PageTitle = () => <h2>Reports</h2>;

const ReportingPage = ({
  fetchReports,
  error,
  reports,
  isLoading,
  timezone,
}) => {
  const [selectedItem, setSelectedItem] = useState("teamPerformance");
  const [selectedTime, setSelectedTime] = useState("today");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(endOfMonth(new Date()));
  const schedulerReports = reports.filter((report) => report.source === "scheduler");
  const customerReports = reports.filter((report) => report.source === "customer" || report.source === "import");

  useEffect(() => {
    let startDate;
    let endDate;

    if (selectedTime === "today") {
      startDate = format(new Date(), "YYYY-MM-DD");
      endDate = format(new Date(), "YYYY-MM-DD");
    } else if (selectedTime === "week") {
      startDate = format(startOfWeek(new Date()), "YYYY-MM-DD");
      endDate = format(endOfWeek(new Date()), "YYYY-MM-DD");
    } else if (selectedTime === "month") {
      startDate = format(startOfMonth(new Date()), "YYYY-MM-DD");
      endDate = format(endOfMonth(new Date()), "YYYY-MM-DD");
    } else if (selectedTime === "other") {
      startDate = format(from, "YYYY-MM-DD");
      endDate = format(to, "YYYY-MM-DD");
    }

    fetchReports(
      startDate,
      endDate,
    );
  }, [selectedTime]);

  const applyChnages = (starts_at, ends_at) => {
    setFrom(starts_at);
    setTo(ends_at);

    fetchReports(format(starts_at, "YYYY-MM-DD"), format(ends_at, "YYYY-MM-DD"));
  };

  const previousDates = () => {
    const rawTo = new Date(to);

    return {
      from: format(from, "YYYY-MM-DD"),
      to: format(to, "YYYY-MM-DD"),
      rawTo: format(rawTo.toISOString(), "YYYY-MM-DD"),
    };
  };

  const preparedData = (data) => data.map(
    (reportings) => {
      const {
        appointments_total,
        appointments_average_value,
        appointments_total_showed,
        appointments_average_value_showed,
        appointments_total_not_showed,
        appointments_average_value_not_showed,
        appointments_total_canceled,
        appointments_average_value_canceled,
        created_by_role,
        created_by_name,
        source,
      } = reportings;

      const formattedCreatedByName = () => {
        let formated = created_by_name;
        if (source === "customer") {
          formated = "Customers";
        } else if (source === "import") {
          formated = "Import";
        }
        return formated;
      };

      const formatedReports = reportings;
      formatedReports.appointments_total = appointments_total;
      formatedReports.appointments_total_canceled = appointments_total_canceled;
      formatedReports.appointments_total_not_showed = appointments_total_not_showed;
      formatedReports.appointments_average_value_canceled = appointments_average_value_canceled;
      formatedReports.created_by_name = formattedCreatedByName();
      formatedReports.created_by_role = created_by_role === "dealership_admin" ? "admin" : created_by_role;
      formatedReports.appointments_total_showed = appointments_total_showed;
      formatedReports.appointments_average_value_not_showed = appointments_average_value_not_showed;
      formatedReports.appointments_average_value_showed = (
        Math.round(appointments_average_value_showed)
      );
      formatedReports.appointments_average_value = Math.round(appointments_average_value);
      formatedReports.appointments_total_showed_formatted = (
        Math.round((appointments_total_showed / appointments_total) * 100)
      );
      formatedReports.appointments_total_not_showed_formatted = (
        Math.round((appointments_total_not_showed / appointments_total) * 100)
      );
      formatedReports.appointments_total_canceled_formatted = (
        Math.round((appointments_total_canceled / appointments_total) * 100)
      );

      return formatedReports;
    },
  );

  return (
    <div className="conciergeReportsPage">
      <PageHeader title={<PageTitle />} />
      <div className="conciergeReportsPageMain">
        {error ? (
          <Panel className="conciergeReportsPageLoadingPanel">{error.data}</Panel>
        ) : (
          <div>
            <div className="reportingNavigationContainer">
              <Tabs
                config={tabsConfig}
                selectedItem={selectedItem}
                selectTabAction={setSelectedItem}
                isGeneral
              />
            </div>
            <div className="reportingTimesNavigationContainer">
              <Tabs
                config={daysColums}
                selectedItem={selectedTime}
                selectTabAction={setSelectedTime}
              />
              <div className="row">
                {selectedTime === "other" && (
                  <DatePicker
                    rangeMode
                    format={BOOKINGS_DATEPICKER_DATE_FORMAT}
                    separator="/"
                    separatorPositions={[2, 5]}
                    formatRegExp={BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP}
                    parseDateInFormat={parseBookingsDatePickerDate}
                    startDateLabel="From"
                    endDateLabel="To"
                    onApplyChanges={applyChnages}
                    previousDates={previousDates()}
                    previousDatesNaming={{
                      startDate: "from",
                      endDate: "to",
                    }}
                    timezone={timezone}
                    hideResetButton
                  />
                )}
              </div>
            </div>
            <ReactTable
              data={schedulerReports}
              defaultPageSize={schedulerReports.length >= 100 ? schedulerReports.length : 100}
              minRows={1}
              resolveData={preparedData}
              loading={isLoading}
              columns={columns}
              noDataText="No reports found"
              showPagination={false}
              resizable={false}
              className="conciergeReportsTable"
            />
            <div className="conciergeReportsTableContainer">
              <ReactTable
                data={customerReports}
                minRows={1}
                resolveData={preparedData}
                loading={isLoading}
                columns={columns}
                noDataText="No reports found"
                showPagination={false}
                resizable={false}
                className="conciergeReportsTable conciergeReportsTableNoHeader"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ReportingPage.propTypes = {
  reports: arrayOf(reportsPropType).isRequired,
  isLoading: bool,
  fetchReports: func,
  error: objectOf(node),
  timezone: string,
};

ReportingPage.defaultProps = {
  isLoading: false,
  fetchReports: () => {},
  error: null,
  timezone: null,
};

const mapStateToProps = (state) => {
  return {
    reports: employeeReportsSelector(state),
    isLoading: reportsLoadingStateSelector(state),
    error: reportsErrorSelector(state),
    timezone: settingsTimezoneSelector(state),
  };
};

const actions = {
  fetchReports: retrieveEmployeeReports,
};

export default connect(mapStateToProps, actions)(ReportingPage);
