import React from "react";
import {
  bool, func, node, number, oneOf, oneOfType, string,
} from "prop-types";
import cx from "classnames";
import Button from "../Button";

import "./styles.scss";

const Modal = React.forwardRef(({
  title,
  text,
  subtitle,
  cancelButtonText,
  submitButtonText,
  cancelButtonVariant,
  submitButtonVariant,
  submitDisabled,
  size,
  isForm,
  loading,
  hideFooter,
  children,
  onCancel,
  onSubmit,
  className,
}, ref) => (
  <>
    <div
      ref={ref}
      className={cx(className, "conciergeModal", {
        conciergeModalSmall: size === "small",
        conciergeModalMedium: size === "medium",
        conciergeModalLarge: size === "large",
      })}
    >
      <div className="conciergeModalHeader">
        <div className="display-flex">
          <p>{title}</p>
          {subtitle && (
            <>
              <i className="conciergeModalHeaderSeparator" />
              <p className="conciergeModalHeaderSubtitle">{subtitle}</p>
            </>
          )}
        </div>
        <Button
          leftIcon="close"
          padding="small"
          onClick={loading ? null : () => onCancel()}
        />
      </div>
      {isForm && (
        <form onSubmit={(e) => onSubmit(e)} className="conciergeModalContainer">
          <div className="conciergeModalBody">
            {text && (
              <p className="conciergeModalBodyLabel">
                {text}
              </p>
            )}
            {children}
          </div>
          {!loading && (
            <div className="conciergeModalFooter">
              <Button
                variant="dark-outline"
                type="button"
                onClick={onCancel}
              >
                {cancelButtonText}
              </Button>
              <Button
                variant="brand"
                type="submit"
                disabled={submitDisabled}
              >
                {submitButtonText}
              </Button>
            </div>
          )}
        </form>
      )}
      {!isForm && (
        <>
          <div className="conciergeModalBody">
            {text
            && (
              <p className="conciergeModalBodyLabel">
                {text}
              </p>
            )}
            {children}
          </div>
          {!hideFooter && (
            <div className="conciergeModalFooter">
              <Button
                variant={cancelButtonVariant}
                onClick={onCancel}
              >
                {cancelButtonText}
              </Button>
              <Button
                variant={submitButtonVariant}
                onClick={onSubmit}
                disabled={submitDisabled}
              >
                {submitButtonText}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
    <section className="conciergeModalOverlay" />
  </>
));

Modal.propTypes = {
  title: oneOfType([string, number]),
  text: string,
  subtitle: oneOfType([string, number]),
  cancelButtonText: string,
  submitButtonText: string,
  cancelButtonVariant: oneOf([
    "base",
    "neutral",
    "aqua",
    "brand",
    "brand-outline",
    "dark",
    "dark-outline",
    "success",
    "destructive",
  ]),
  submitButtonVariant: oneOf([
    "base",
    "neutral",
    "aqua",
    "brand",
    "brand-outline",
    "dark",
    "dark-outline",
    "success",
    "destructive",
  ]),
  submitDisabled: bool,
  size: oneOf(["small", "medium", "large"]),
  isForm: bool,
  loading: bool,
  hideFooter: bool,
  children: node,
  onCancel: func,
  onSubmit: func,
  className: string,
};

Modal.defaultProps = {
  title: "",
  text: "",
  subtitle: "",
  cancelButtonText: "Cancel",
  submitButtonText: "Submit",
  cancelButtonVariant: "dark-outline",
  submitButtonVariant: "brand",
  submitDisabled: false,
  size: "medium",
  isForm: false,
  loading: false,
  hideFooter: false,
  children: null,
  onCancel: () => {},
  onSubmit: () => {},
  className: "",
};

export default Modal;
