import React, { Component } from "react";
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  node,
  number,
  objectOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import dateFns from "date-fns";
import cx from "classnames";
import { equals } from "ramda";
import enhanceWithClickOutside from "react-click-outside";
import Calendar from "components/common/DatePicker/Calendar";
import Panel from "components/common/Panel";
import Button from "components/common/Button";

import DatePickerInput from "./DatePickerInput";

import "./styles.scss";

const HOURS = 23;
const MINUTES = 59;
const SECONDS = 59;

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutsideWrapper = this.handleClickOutsideWrapper.bind(this);

    const { from, rawTo } = this.props.previousDates;
    let startDateFrom = this.props.defaultStartDate;
    let endDateFrom = this.props.defaultEndDate;

    if (!this.props.defaultStartDate) {
      if (from || rawTo) {
        startDateFrom = dateFns.format(from, "YYYY-MM-DD");
        endDateFrom = dateFns.format(rawTo, "YYYY-MM-DD");
      }
    }

    this.state = {
      initialRange: this.props.previousDates,
      startDate: startDateFrom,
      endDate: endDateFrom,
      startDateInputValue: this.props.defaultStartDate
        ? dateFns.format(new Date(this.props.defaultStartDate).toISOString().split("T")[0], this.props.format)
        : "",
      endDateInputValue: this.props.defaultEndDate
        ? dateFns.format(new Date(this.props.defaultEndDate).toISOString().split("T")[0], this.props.format)
        : "",
      isCalendarOpen: false,
      isSelectingRange: false,
      isDateChanged: false,
      startDateError: null,
      endDateError: null,
      allowClose: true,
    };
  }

  componentDidMount() {
    const { from, rawTo } = this.props.previousDates;

    if (from || rawTo) {
      this.handleStartSelectFromCalendar(from);
      this.handleEndSelectFromCalendar(rawTo);
    }

    document.addEventListener("mousedown", this.handleClickOutsideWrapper);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideWrapper);
  }

  handleClickOutsideWrapper(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this.state.isCalendarOpen && this.state.allowClose) {
        this.cancelChanges();
      }
    }
  }

  handleStartSelectFromCalendar = (date) => {
    const startDateInputValue = dateFns.format(typeof date.getMonth === "function" ? date.toISOString().split("T")[0] : date, this.props.format);

    this.setState((prevState) => {
      return {
        startDate: date,
        startDateInputValue,
        startDateError: null,
        isDateChanged: prevState.isCalendarOpen,
      };
    });

    if (this.props.onSelectChange) {
      this.props.onSelectChange(date);
    }

    if (this.props.rangeMode) {
      this.setState({ isSelectingRange: true });
    }
  };

  handleEndSelectFromCalendar = (date) => {
    if (!this.props.isSingleDateSelect) {
      const endDateInputValue = date ? dateFns.format(typeof date.getMonth === "function" ? date.toISOString().split("T")[0] : date, this.props.format) : "";
      const endDatePeriodValue = date ? dateFns.format(typeof date.getMonth === "function" ? date.toISOString().split("T")[0] : date, "YYYY-MM-DD") : "";

      let cloneDate;

      if (endDatePeriodValue) {
        cloneDate = `${endDatePeriodValue}T${HOURS}:${MINUTES}`;
      }

      this.setState((prevState) => {
        return {
          endDate: cloneDate,
          endDateInputValue,
          isSelectingRange: !date,
          isDateChanged: prevState.isCalendarOpen,
          endDateError: null,
        };
      });
    }
  };

  openCalendar = () => {
    this.setState({ isCalendarOpen: true });
    this.props.changeVisibilityAction(true);
  };

  closeCalendar = () => {
    this.setState({ isCalendarOpen: false });
    this.props.changeVisibilityAction(false);
  };

  checkIfDatesCanBeApplied = () => {
    const { previousDates, previousDatesNaming, rangeMode } = this.props;
    const { startDate, endDate } = this.state;
    const isStartEquals = equals(previousDates[previousDatesNaming.startDate], startDate);
    const isEndEquals = rangeMode && equals(previousDates[previousDatesNaming.endDate], endDate);

    return rangeMode ? !isStartEquals || !isEndEquals : !isStartEquals;
  };

  applyChanges = () => {
    const { startDate, endDate } = this.state;

    if (!this.checkIfDatesCanBeApplied()) {
      return;
    }

    let end;

    if (startDate && !endDate) {
      // we make copy of date object
      end = new Date(startDate);
      this.handleEndSelectFromCalendar(end.toISOString());

      end.setHours(HOURS);
      end.setMinutes(MINUTES);
      end.setSeconds(SECONDS);
    }

    this.props.onApplyChanges(startDate, endDate || end);
    this.closeCalendar();
    this.setState({ isDateChanged: false });
  };

  resetChanges = () => {
    const {
      rangeMode,
      format,
      onResetChanges,
    } = this.props;

    const { initialRange } = this.state;

    this.setState({
      startDate: initialRange.from,
      startDateInputValue: dateFns.format(new Date(initialRange.from).toISOString().split("T")[0], format),
    });

    if (rangeMode) {
      this.setState({
        endDate: initialRange.rawTo,
        endDateInputValue: dateFns.format(new Date(initialRange.rawTo).toISOString().split("T")[0], format),
      });
    }

    onResetChanges();
    this.closeCalendar();
    this.setState({ isDateChanged: false });
  };

  cancelChanges = () => {
    if (this.state.allowClose) {
      const {
        previousDates,
        previousDatesNaming,
        rangeMode,
        format,
      } = this.props;

      if (this.state.isDateChanged) {
        if (!this.checkIfDatesCanBeApplied()) {
          return;
        }

        this.setState({
          startDate: previousDates[previousDatesNaming.startDate],
          startDateInputValue:
            previousDates[previousDatesNaming.startDate]
              ? dateFns.format(new Date(previousDates[previousDatesNaming.startDate]).toISOString().split("T")[0], format) : "",
        });

        if (rangeMode) {
          this.setState({
            endDate: previousDates[previousDatesNaming.endDate],
            endDateInputValue:
              previousDates[previousDatesNaming.endDate]
                ? dateFns.format(new Date(previousDates.rawTo).toISOString().split("T")[0], format) : "",
          });
        }
      }

      this.closeCalendar();
      this.setState({ isDateChanged: false });
    }
  };

  render() {
    const {
      startDateLabel,
      endDateLabel,
      disabled,
      rangeMode,
      calendarProps,
      resetButtonLabel,
      hideResetButton,
      darkMode,
    } = this.props;
    const {
      startDateInputValue,
      endDateInputValue,
      isCalendarOpen,
      startDate,
      endDate,
      isSelectingRange,
      isDateChanged,
      startDateError,
      endDateError,
    } = this.state;

    return (
      <section
        ref={this.wrapperRef}
        className={
          cx("conciergeDatepickerWrapper", {
            conciergeDatepickerDarkMode: darkMode,
          })
        }
      >
        <section className="conciergeDatepickerInputWrapper">
          <DatePickerInput
            label={startDateLabel}
            value={startDateInputValue}
            disabled={disabled}
            onFocus={this.openCalendar}
            onBlur={this.cancelChanges}
            error={startDateError}
          />
          {rangeMode ? (
            <DatePickerInput
              value={endDateInputValue}
              label={endDateLabel}
              disabled={disabled}
              onBlur={this.cancelChanges}
              error={endDateError}
            />
          ) : null}
        </section>
        <Panel
          className={
            cx(
              "conciergeDatepickerCalendarContainer",
              {
                "conciergeDatepickerCalendarContainer-open": isCalendarOpen,
              },
            )
          }
          onMouseEnter={() => this.setState({ allowClose: false })}
          onMouseLeave={() => this.setState({ allowClose: true })}
        >
          <Calendar
            onStartDateSelect={this.handleStartSelectFromCalendar}
            onEndDateSelect={this.handleEndSelectFromCalendar}
            selectedStartDate={startDate}
            selectedEndDate={endDate}
            isSelectingRange={isSelectingRange}
            rangeMode={rangeMode}
            timezone={this.props.timezone}
            {...calendarProps}
          />
          {this.props.isButtonShown && (
            <section className="conciergeDatepickerButtonsWrapper">
              {!hideResetButton
              && (
                <Button
                  variant="destructive"
                  padding="medium"
                  fullWidth
                  onClick={this.resetChanges}
                >
                  {resetButtonLabel}
                </Button>
              )}
              <Button
                variant="neutral"
                padding="medium"
                fullWidth
                onClick={() => {
                  this.setState(
                    { allowClose: true },
                    () => this.cancelChanges(),
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                variant={darkMode ? "dark" : "success"}
                padding="medium"
                fullWidth
                onClick={this.applyChanges}
                disabled={!isDateChanged}
              >
                Apply
              </Button>
            </section>
          )}
        </Panel>
      </section>
    );
  }
}

DatePicker.propTypes = {
  defaultStartDate: oneOfType([instanceOf(Date), string]),
  defaultEndDate: instanceOf(Date),
  previousDates: oneOfType([instanceOf(Date), string]),
  previousDatesNaming: shape({
    startDate: string,
    endDate: string,
  }),
  rangeMode: bool,
  disabled: bool,
  format: string.isRequired,
  separator: string.isRequired,
  formatRegExp: instanceOf(RegExp).isRequired,
  separatorPositions: arrayOf(number).isRequired,
  parseDateInFormat: func.isRequired,
  startDateLabel: string,
  endDateLabel: string,
  onResetChanges: func,
  onApplyChanges: func,
  calendarProps: objectOf(node),
  dateValidationError: string,
  endDateValidationError: string,
  isSingleDateSelect: bool,
  onSelectChange: func,
  isButtonShown: bool,
  timezone: string,
  resetButtonLabel: string,
  hideResetButton: bool,
  darkMode: bool,
  changeVisibilityAction: func,
};

DatePicker.defaultProps = {
  defaultStartDate: null,
  defaultEndDate: null,
  previousDates: null,
  previousDatesNaming: null,
  rangeMode: false,
  disabled: false,
  startDateLabel: "Start Date",
  endDateLabel: "End Date",
  onResetChanges: () => {},
  onApplyChanges: () => {},
  calendarProps: {},
  dateValidationError: "Invalid date",
  endDateValidationError: "End date should be after start date",
  isSingleDateSelect: false,
  onSelectChange: () => {},
  isButtonShown: true,
  timezone: "",
  resetButtonLabel: "Reset",
  hideResetButton: false,
  darkMode: false,
  changeVisibilityAction: () => {},
};

export default enhanceWithClickOutside(DatePicker);
