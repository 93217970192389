import { connect } from "react-redux";
import {
  fetchTransport,
  setClientTransport,
  setClientWaiting,
  setCurrentStep,
} from "store/actions/scheduling-process-actions";

import {
  schedulingAvailableTransportErrorSelector,
  schedulingAvailableTransportLoadingSelector,
  schedulingAvailableTransportSelector,
  schedulingChosenTimeSlotSelector,
  schedulingClientWaitingSelector,
  schedulingCurrentStepSelector,
  schedulingIsPickUpSelector,
  schedulingProcessAvailableLoaners,
  schedulingProcessWaiterAllowed,
  schedulingSelectedTransportSelector,
} from "store/selectors/scheduling-process-selectors";

import Transportation from "./index";

const mapStateToProps = (state) => {
  return {
    clientWaiting: schedulingClientWaitingSelector(state),
    selectedTransport: schedulingSelectedTransportSelector(state),
    chosenTimeSlot: schedulingChosenTimeSlotSelector(state),
    currentStep: schedulingCurrentStepSelector(state),
    availableTransports: schedulingAvailableTransportSelector(state),
    availableTransportsLoading: schedulingAvailableTransportLoadingSelector(state),
    availableTransportsLoadingError: schedulingAvailableTransportErrorSelector(state),
    waiterAllowed: schedulingProcessWaiterAllowed(state),
    availableLoaners: schedulingProcessAvailableLoaners(state),
    isPickUp: schedulingIsPickUpSelector(state),
  };
};

const actions = {
  setIsWaiting: setClientWaiting,
  setTransport: setClientTransport,
  setSchedulingStep: setCurrentStep,
  fetchAvailableTransport: fetchTransport,
};

export default connect(mapStateToProps, actions)(Transportation);
