import { isEmpty } from "ramda";
import { prepareCustomerPickupAddress } from "shared/utils/common";
import { getAppointmentFormattedDate } from "shared/utils/datetime";
import { settingsTeamServiceAdvisorsSelector } from "store/selectors/settings-selectors";

export const bookingDetailsLoadingStateSelector = (state) => state.booking.isLoading;
export const bookingDetailsDataSelector = (state) => {
  const { vehicle_set, appointment_datetime, customer } = state.booking.data;
  const transformedBooking = { ...state.booking.data };
  const make = vehicle_set ? vehicle_set.make : "";
  const model = vehicle_set ? vehicle_set.model : "";
  const model_year = vehicle_set ? vehicle_set.model_year : "";
  transformedBooking.car_name = make && model && model_year ? `${model_year} ${make} ${model}` : "-";
  transformedBooking.formatted_eta = getAppointmentFormattedDate(
    appointment_datetime,
  );

  if (state.booking.selectedRecalls) {
    transformedBooking.recall_ids = state.booking.selectedRecalls.map(
      (selectedRecall) => selectedRecall.recall_id || selectedRecall.id,
    );
  }

  transformedBooking.customerPickupAddress = prepareCustomerPickupAddress(customer);
  transformedBooking.customerPickupAddressData = (
    customer
    && !isEmpty(customer.last_address)
    && customer.last_address
  );
  transformedBooking.overcapacity = state.booking.overcapacity;
  transformedBooking.walkin = state.booking.walkin;

  return transformedBooking;
};

export const bookingDetailsInitialTimeSelector = (state) => state.booking.data.appointment_datetime;
export const bookingDetailsDropOffInitialTimeSelector = (state) => state.booking.dropOffDateTime;
export const bookingDetailsInitialAdvisorSelector = (state) => (
  state.booking.data.service_advisor.dms_name
);
export const bookingDetailsInitialAdvisorIdSelector = (state) => (
  state.booking.data.service_advisor.id
);

export const bookingDetailsErrorSelector = (state) => state.booking.error;
export const bookingDetailsStatusSelector = (state) => state.booking.data.aasm_state;
export const bookingDetailsGuidSelector = (state) => state.booking.data.guid;
export const bookingDetailsIsNotCheckedInSelector = (state) => state.booking.data.status === "not_checked_in";

export const bookingDetailsDriversLoadingPickUpStateSelector = (state) => (
  state.booking.drivers.isLoadingPickUp
);
export const bookingDetailsDriversLoadingDropOffStateSelector = (state) => (
  state.booking.drivers.isLoadingDropOff
);
export const bookingDetailsDriversPickUpDataSelector = (state) => state.booking.drivers.pickUpData;
export const bookingDetailsDriversDropOffDataSelector = (state) => (
  state.booking.drivers.dropOffData
);
export const bookingDetailsDriversJobTypeSelector = (state) => state.booking.drivers.jobType;
export const bookingDetailsVideosLoadingStateSelector = (state) => state.booking.videos.isLoading;
export const bookingDetailsVideosPickUpSelector = (state) => state.booking.videos.pickUp;
export const bookingDetailsVideosDropOffSelector = (state) => state.booking.videos.dropOff;
export const bookingDetailsVideosMobileTechnicianSelector = (state) => (
  state.booking.videos.mobileTechnician
);
export const bookingDetailsVideosWalkaroundSelector = (state) => state.booking.videos.walkaround;

export const bookingDetailsSelector = (state) => state.booking;
export const bookingDetailsDealershipMenuItemsSelector = (state) => (
  state.booking.dealershipMenuItems
);

export const bookingDetailsIsPickUpSelector = (state) => (
  state.booking.isPickUp
);
export const bookingDetailsIsDropOffSelector = (state) => (
  state.booking.isDropOff
);
export const bookingDetailsisMobileTechnicianSelector = (state) => (
  state.booking.isMobileTechnician
);

export const bookingDetailsSelectedServicesSelector = (state) => {
  const selectedServiceIds = state.booking.selectedServices.map((service) => service.id);
  const menuItems = bookingDetailsDealershipMenuItemsSelector(state);
  const isMobileTechnician = bookingDetailsisMobileTechnicianSelector(state);
  const isPickUp = bookingDetailsIsPickUpSelector(state);
  const menuItemsRO = [];

  if (menuItems) {
    Object.values(menuItems).forEach((group) => {
      group.forEach((menuItem) => {
        if (selectedServiceIds.indexOf(menuItem.id) === -1) {
          if (
            (isMobileTechnician && menuItem.default_mobile_repair_order_item)
            || (isPickUp && menuItem.default_pickup_repair_order_item)
            || (!isMobileTechnician && !isPickUp && menuItem.default_inhouse_repair_order_item)
          ) {
            menuItemsRO.push(menuItem);
          }
        }
      });
    });
  }

  return [...state.booking.selectedServices, ...menuItemsRO];
};

export const bookingDetailsMenuItemsLoadingSelector = (state) => (
  state.booking.menuItemsLoading
);
export const bookingDetailsMenuItemsLoadingErrorSelector = (state) => (
  state.booking.menuItemsLoadingError
);
export const bookingDetailsCurrentStepSelector = (state) => (
  state.booking.currentStep
);
export const bookingDetailsIsCheckedInSelector = (state) => (
  state.booking.isCheckedIn
);
export const bookingSelectedRecallsSelector = (state) => (
  state.booking.selectedRecalls || []
);
export const bookingDetailsChosenTimeSlotSelector = (state) => (
  state.booking.chosenTimeSlot
);
export const bookingDetailsChosenDropOffTimeSlotSelector = (state) => (
  state.booking.chosenDropOffTimeSlot
);
export const bookingDetailsTimeSlotsLoadingSelector = (state) => (
  state.booking.timeSlotsLoading
);
export const bookingDetailsTimeSlotsLoadingErrorSelector = (state) => (
  state.booking.timeSlotsLoadingError
);
export const bookingDetailsSelectedAdvisorSelector = (state) => (
  state.booking.selectedAdvisor
);
export const bookingDetailsVehicleSelector = (state) => {
  return {
    ...state.booking.data.vehicle,
    vehicle_set: {
      ...state.booking.data.vehicle_set,
    },
  };
};
export const bookingDetailsCustomerSelector = (state) => state.booking.data.customer;
export const bookingDetailsContactNumberSelector = (state) => (
  state.booking.data.customer && state.booking.data.customer.phone_number
);
export const bookingDetailsSendSmsSelector = (state) => state.booking.sendSms;
export const bookingDetailsAvailableJobSlotsSelector = (state) => state.booking.availableJobSlots;
export const bookingDetailsAvailableDaysSelector = (state) => {
  if (
    bookingDetailsIsPickUpSelector(state)
    || bookingDetailsisMobileTechnicianSelector(state)
  ) {
    const jobSlots = bookingDetailsIsPickUpSelector(state)
      ? JSON.parse(JSON.stringify(state.booking.availableJobSlots))
      : JSON.parse(JSON.stringify(state.booking.availableTechiciansJobSlots));

    for (let i = 0; i < jobSlots.length; i++) {
      const relatedJobSlots = jobSlots[i].slots;
      const slots = {};
      const employeeJobSlots = {};

      Object.keys(relatedJobSlots).forEach((key) => {
        if (relatedJobSlots[key].length > 0) {
          slots[key] = [...settingsTeamServiceAdvisorsSelector(state).map((advisor) => advisor.id)];
          employeeJobSlots[key] = relatedJobSlots[key];
        } else {
          slots[key] = [];
          employeeJobSlots[key] = relatedJobSlots[key];
        }
      });

      jobSlots[i].slots = slots;
      jobSlots[i].employeeJobSlots = employeeJobSlots;
    }

    return jobSlots;
  }

  return state.booking.availableSlots;
};

export const bookingDetailsAvailableJobEmployee = (state) => {
  const availableDays = bookingDetailsAvailableDaysSelector(state);
  const chosenTimeSlot = bookingDetailsChosenTimeSlotSelector(state);
  const { quarterSlot, day } = chosenTimeSlot;

  if (quarterSlot) {
    const fullDate = day.full_date;

    return availableDays.filter(
      (item) => item.full_date === fullDate,
    )[0]?.employeeJobSlots[quarterSlot];
  }

  return [];
};

export const bookingDetailsAvailableDropOffJobEmployee = (state) => {
  const availableDays = bookingDetailsAvailableDaysSelector(state);
  const chosenTimeSlot = bookingDetailsChosenDropOffTimeSlotSelector(state);
  const { quarterSlot, day } = chosenTimeSlot;

  if (quarterSlot) {
    const fullDate = day.full_date;

    return availableDays.filter(
      (item) => item.full_date === fullDate,
    )[0]?.employeeJobSlots[quarterSlot];
  }

  return [];
};

export const bookingDetailsClientWaitingSelector = (state) => state.booking.clientWaiting;
export const bookingDetailsDecisionTreeResultsSelector = (state) => (
  state.booking.decisionTreeResults
);
export const bookingDetailsSelectedTransportSelector = (state) => (
  state.booking.selectedTransport
);
export const bookingDetailsAvailableTransportSelector = (state) => (
  state.booking.availableTransport
);

export const bookingDetailsAvailableTransportLoadingSelector = (state) => (
  state.booking.availableTransportLoading
);
export const bookingDetailsAvailableTransportErrorSelector = (state) => (
  state.booking.availableTransportError
);
export const bookingDetailsSaveAppointmentLoadingSelector = (state) => (
  state.booking.saveAppointmentLoading
);
export const bookingDetailsSaveAppointmentErrorSelector = (state) => (
  state.booking.saveAppointmentError
);

export const bookingDetailsRemoteJobDataSelector = (state) => state.booking.remoteJobData;

export const bookingDetailsIsTriggerSmsLoadingSelector = (state) => (
  state.booking.isTriggerSmsLoading
);
export const bookingDetailsIsSmsSentSelector = (state) => state.booking.isSmsSent;
export const roTagNumberSelector = (state) => state.booking.roTagNumber;
export const internalNoteSelector = (state) => state.booking.internal_note;
export const bookingDetailsTriggerSmsErrorSelector = (state) => state.booking.triggerSmsError;

export const bookingDetailsDataModalSavingSelector = (state) => (
  state.booking.dataEditionModalSaving
);
export const bookingDetailsDataModalSavingErrorSelector = (state) => (
  state.booking.dataEditionModalSavingError
);
export const bookingDetailsUpdatedWithoutDmsNotificationSelector = (state) => (
  state.booking.updatedWithoutDmsNotification
);
export const bookingDetailsIsROGeneratedSelector = (state) => (
  state.booking.isROGenerated
);

export const bookingDetailsWaiterAllowed = (state) => (
  state.booking.waiterAllowed
);

export const bookingAvailableLoanersSelector = (state) => (
  state.booking.availableLoaners
);

export const bookingVehicleData = (state) => (
  state.booking.vehicleData
);

export const bookingIsJobReachableLoading = (state) => (
  state.booking.isJobReachableLoading
);

export const bookingJobReachable = (state) => (
  state.booking.jobReachable
);

export const bookingJobDropOffReachable = (state) => (
  state.booking.jobDropOffReachable
);

export const bookingDriversSelector = (state) => state.booking.activeDrivers;
export const bookingTotalDriversSelector = (state) => (
  state.booking.activeDriversTotal
);

export const bookingMobileTechniciansSelector = (state) => (
  state.booking.activeMobileTechnicians
);
export const bookingTotalMobileTechniciansSelector = (state) => (
  state.booking.activeMobileTechniciansTotal
);

export const bookingServiceLocationSelector = (state) => (
  state.booking.serviceLocation
);

export const bookingAvailableSlotsSelector = (state) => (
  state.booking.availableSlots
);

export const bookingActivePickupJobSelector = (state) => state.booking.data.jobs.find(
  (job) => job.job_type === "pickup" && job.aasm_state !== "cancelled",
);

export const bookingActiveDropOffJobSelector = (state) => state.booking.data.jobs.find(
  (job) => job.job_type === "dropoff" && job.aasm_state !== "cancelled",
);

export const bookingActiveMobileTechnicianJobSelector = (state) => state.booking.data.jobs.find(
  (job) => job.job_type === "mobile_technician" && job.aasm_state !== "cancelled",
);

export const bookingRecallsLoadingSelector = (state) => (
  state.booking.recallsLoading
);

export const bookingAvailableRecallsSelector = (state) => (
  state.booking.availableRecalls || []
);

export const bookingRecallsLoadingErrorSelector = (state) => (
  state.booking.recallsLoadingError
);

export const bookingDetailsIsArrivedSelector = (state) => (
  state.booking.isArrived
);

export const bookingDetailsQuestionnairesSelector = (state) => state.booking.questionnaires;

export const bookingDetailsQuestionnairesIdSelector = (state) => state.booking.questionnairesId;

export const bookingDetailsQuestionnairesLoadingSelector = (state) => (
  state.booking.questionnairesLoading
);

export const bookingDetailsRepairOrdersSelector = (state) => (
  state.booking.repairOrders
);

export const bookingDetailsRepairOrdersLoadingSelector = (state) => (
  state.booking.repairOrdersLoading
);

export const bookingDetailsRepairOrdersErrorSelector = (state) => (
  state.booking.repairOrdersError
);

export const bookingIgnoreDistanceLimitSelector = (state) => (
  state.booking.isDistanceLimitIgnored
);
