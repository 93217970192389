import {
  BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_ERROR,
  BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_SUCCSESS,
  BOOKING_DETAILS_CLEAN_DATA,
  BOOKING_DETAILS_CREATE_CUSTOMER,
  BOOKING_DETAILS_CREATE_CUSTOMER_FAILURE,
  BOOKING_DETAILS_CREATE_CUSTOMER_SUCCESS,
  BOOKING_DETAILS_DELETE_BOOKING,
  BOOKING_DETAILS_DELETE_BOOKING_FAILURE,
  BOOKING_DETAILS_DELETE_BOOKING_SUCCESS,
  BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_FAILURE,
  BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_SUCCESS,
  BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT,
  BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_FAILURE,
  BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_SUCCESS,
  BOOKING_DETAILS_FETCH_DAYS,
  BOOKING_DETAILS_FETCH_DAYS_FAILURE,
  BOOKING_DETAILS_FETCH_DAYS_SUCCESS,
  BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS,
  BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_FAILURE,
  BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS,
  BOOKING_DETAILS_FETCH_DRIVERS_INFO_FAILURE,
  BOOKING_DETAILS_FETCH_DRIVERS_INFO_SUCCESS,
  BOOKING_DETAILS_FETCH_FAILURE,
  BOOKING_DETAILS_FETCH_JOB_SLOTS_SUCCESS,
  BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE,
  BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS,
  BOOKING_DETAILS_FETCH_QUESTIONNAIRES,
  BOOKING_DETAILS_FETCH_QUESTIONNAIRES_ERROR,
  BOOKING_DETAILS_FETCH_QUESTIONNAIRES_SUCCSESS,
  BOOKING_DETAILS_FETCH_RECALLS,
  BOOKING_DETAILS_FETCH_RECALLS_ERROR,
  BOOKING_DETAILS_FETCH_RECALLS_SUCCESS,
  BOOKING_DETAILS_FETCH_SUCCESS,
  BOOKING_DETAILS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS,
  BOOKING_DETAILS_FETCH_VEHICLE_DATA,
  BOOKING_DETAILS_FETCH_VEHICLE_RO,
  BOOKING_DETAILS_FETCH_VEHICLE_RO_FAILURE,
  BOOKING_DETAILS_FETCH_VEHICLE_RO_SUCCESS,
  BOOKING_DETAILS_FETCH_VIDEOS,
  BOOKING_DETAILS_FETCH_VIDEOS_FAILURE,
  BOOKING_DETAILS_FETCH_VIDEOS_SUCCESS,
  BOOKING_DETAILS_GENERATE_REPAIR_ORDER_FAILURE,
  BOOKING_DETAILS_GENERATE_REPAIR_ORDER_SUCCESS,
  BOOKING_DETAILS_JOB_REACHABLE,
  BOOKING_DETAILS_JOB_REACHABLE_LOADING,
  BOOKING_DETAILS_RESET_STATE,
  BOOKING_DETAILS_SET_ARRIVED,
  BOOKING_DETAILS_SET_BOOKING_REMOTE_JOB_DATA,
  BOOKING_DETAILS_SET_CHECKED_IN_STATUS,
  BOOKING_DETAILS_SET_CHOSEN_DROP_OFF_TIMESLOT,
  BOOKING_DETAILS_SET_CHOSEN_TIMESLOT,
  BOOKING_DETAILS_SET_CLIENT_TRANSPORT,
  BOOKING_DETAILS_SET_CLIENT_WAITING,
  BOOKING_DETAILS_SET_CURRENT_STEP,
  BOOKING_DETAILS_SET_IS_DISTANCE_LIMIT_IGNORED,
  BOOKING_DETAILS_SET_IS_DROP_OFF,
  BOOKING_DETAILS_SET_IS_MOBILE_TECHNICIAN,
  BOOKING_DETAILS_SET_IS_PICK_UP,
  BOOKING_DETAILS_SET_LOADING_STATE,
  BOOKING_DETAILS_SET_NEW_DRIVER_JOB,
  BOOKING_DETAILS_SET_NEW_DRIVER_JOB_FAILURE,
  BOOKING_DETAILS_SET_NEW_DRIVER_JOB_SUCCESS,
  BOOKING_DETAILS_SET_NEW_MOBILE_TECHNICIAN_JOB,
  BOOKING_DETAILS_SET_RECALLS_SELECTION,
  BOOKING_DETAILS_SET_REMOTE_JOB_COLLECTION_TIME,
  BOOKING_DETAILS_SET_REMOTE_SERVICE_LOCATION,
  BOOKING_DETAILS_SET_SEND_SMS,
  BOOKING_DETAILS_SET_SERVICE_ADVISOR,
  BOOKING_DETAILS_SET_SERVICE_SELECTION,
  BOOKING_DETAILS_TRIGGER_CHECKIN_SMS,
  BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_FAILURE,
  BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_SUCCESS,
  BOOKING_DETAILS_UPDATE_APPOINTMENT,
  BOOKING_DETAILS_UPDATE_APPOINTMENT_FAILURE,
  BOOKING_DETAILS_UPDATE_APPOINTMENT_SUCCESS,
  BOOKING_DETAILS_UPDATE_AVAILABLE_LOANERS,
  BOOKING_DETAILS_UPDATE_CUSTOMER_DATA,
  BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_FAILURE,
  BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_SUCCESS,
  BOOKING_DETAILS_UPDATE_DRIVER_JOB,
  BOOKING_DETAILS_UPDATE_DRIVER_JOB_FAILURE,
  BOOKING_DETAILS_UPDATE_DRIVER_JOB_SUCCESS,
  BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB,
  BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB_FAILURE,
  BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB_SUCCESS,
  BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA,
  BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_FAILURE,
  BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS,
  BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS_WITHOUT_DMS,
  BOOKING_DETAILS_UPDATE_WAITER_ALLOWED,
  BOOKING_DETAILS_UPDATE_WAITER_ALLOWED_FAILURE,
} from "store/actions/booking-details-actions";
import { BOOKING_STEP_SERVICES, FIRST_AVAILABLE_ADVISOR_ID } from "shared/constants";
import { APP_RESET_VEHICLE_SET_BY_VIN } from "store/actions/app-actions";
import { SOURCE_USER } from "shared/constants/text-messages";

const initialState = {
  isLoading: false,
  data: {
    jobs: [],
  },
  error: null,
  drivers: {
    pickUpData: [],
    isLoadingPickUp: false,
    dropOffData: [],
    isLoadingDropOff: false,
    error: null,
  },
  videos: {
    isLoading: false,
    pickUp: [],
    dropOff: [],
    mobileTechnician: [],
    walkaround: [],
    error: null,
  },
  menuItemsLoading: false,
  menuItemsLoadingError: false,
  dealershipMenuItems: {
    maintenance: [],
    concern: [],
  },
  selectedServices: [],
  selectedAdvisor: null,
  availableSlots: [],
  availableJobSlots: [],
  availableTechiciansJobSlots: [],
  chosenTimeSlot: {},
  chosenDropOffTimeSlot: {},
  clientWaiting: null,
  availableTransport: [],
  selectedTransport: null,
  overcapacity: false,
  walkin: false,
  sendSms: null,
  currentStep: BOOKING_STEP_SERVICES,
  isPickUp: false,
  isDropOff: false,
  isMobileTechnician: false,
  isCheckedIn: false,
  isROGenerated: false,
  remoteJobData: {},
  saveAppointmentLoading: false,
  saveAppointmentError: false,
  isTriggerSmsLoading: false,
  isSmsSent: false,
  triggerSmsError: null,
  dataEditionModalSaving: false,
  dataEditionModalSavingError: "",
  waiterAllowed: false,
  timeSlotsLoading: false,
  timeSlotsLoadingError: false,
  vehicleData: {},
  availableLoaners: {},
  jobReachable: null,
  jobDropOffReachable: null,
  isJobReachableLoading: false,
  activeDrivers: [],
  activeDriversTotal: 0,
  activeMobileTechnicians: [],
  activeMobileTechniciansTotal: 0,
  serviceLocation: {
    pickUp: {
      address: "",
      addressData: {
        address_line1: "",
        address_line2: "",
        address_city: "",
        address_state: "",
        address_zipcode: "",
      },
      geolocation: {},
    },
    dropOff: {
      address: "",
      addressData: {
        address_line1: "",
        address_line2: "",
        address_city: "",
        address_state: "",
        address_zipcode: "",
      },
      geolocation: {},
    },
    isSameLocation: true,
  },
  recallsLoading: false,
  availableRecalls: [],
  recallsLoadingError: null,
  isArrived: false,
  questionnaires: [],
  questionnairesError: null,
  questionnaireAnswers: [],
  questionnairesAnswersError: null,
  questionnairesLoading: false,
  decisionTreeDetails: [],
};

const preselectServices = (
  menuItems = [],
  bookedServices = [],
  decisionTreeResults = [],
) => menuItems.map((menuItem) => {
  const foundService = bookedServices.find((service) => service.menu_item_id === menuItem.id);
  const decisionTree = decisionTreeResults.find((dtr) => dtr.menu_item_id === menuItem.id);
  let decisionTreeResult = null;

  if (decisionTree && decisionTree.details.length > 0) {
    const answerIndexes = decisionTree.details
      .map(({ source }, treeItemIndex) => (source === SOURCE_USER ? treeItemIndex : null))
      .filter((treeItem) => treeItem !== null);
    const results = answerIndexes.reduce((total, answerIndex) => {
      return {
        ...total,
        [decisionTree.details[answerIndex - 1].text]: decisionTree.details[answerIndex].text,
      };
    }, {});
    decisionTreeResult = results;
  }
  if (!foundService) {
    return null;
  }
  if (foundService.kind === "concern") {
    return ({
      ...menuItem,
      additionalInfo: foundService.additional_note,
      decisionTreeDetails: decisionTree ? [...decisionTree.details] : null,
      decisionTreeResult,
    });
  }

  return menuItem;
}).filter((i) => i);

const booking = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_DETAILS_RESET_STATE: return initialState;
    case BOOKING_DETAILS_SET_LOADING_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case BOOKING_DETAILS_CLEAN_DATA:
      return {
        ...state,
        data: {
          jobs: [],
        },
      };
    case BOOKING_DETAILS_FETCH_SUCCESS: {
      const pickup = action.payload.jobs.find(
        (job) => job.job_type === "pickup" && job.aasm_state !== "cancelled",
      );

      const dropOff = action.payload.jobs.find(
        (job) => job.job_type === "dropoff" && job.aasm_state !== "cancelled",
      );

      const mobileTechnician = action.payload.jobs.find(
        (job) => job.job_type === "mobile_technician" && job.aasm_state !== "cancelled",
      );

      let isPickUp = false;
      let isDropOff = false;
      let isMobileTechnician = false;
      let dropOffDateTime;

      const remoteJobData = {
        pickUp: {},
        dropOff: {},
        mobileTechnician: {},
      };

      if (pickup) {
        isPickUp = true;

        const {
          address,
          address_city,
          address_line1,
          address_line2,
          address_state,
          address_zipcode,
          collection_time: collectionTime,
          main_driver,
          co_driver,
          notes,
          id: jobId,
          geolocation,
          location_area,
          distance_in_miles,
          overcapacity,
        } = pickup;

        remoteJobData.pickUp = {
          address,
          addressData: {
            address_city,
            address_line1,
            address_line2,
            address_state,
            address_zipcode,
          },
          collectionTime,
          mainDriverId: main_driver?.id || null,
          coDriverId: co_driver && co_driver.id,
          notes,
          jobId,
          geolocation,
          location_area,
          distance: distance_in_miles,
          overcapacity,
        };

        if (dropOff) {
          isDropOff = true;

          const {
            address: dropOffAddress,
            address_city: dropOffAddressCity,
            address_line1: dropOffAddressLine1,
            address_line2: dropOffAddressLine2,
            address_state: dropOffAddressState,
            address_zipcode: dropOffAddressZipcode,
            collection_time: dropOffCollectionTime,
            main_driver: dropOffMainDriver,
            co_driver: dropOffCoDriver,
            notes: dropOffNotes,
            id: dropOffJobId,
            geolocation: dropOffGeolocation,
            location_area: dropOffLocationArea,
            distance_in_miles: dropOffDistance,
            overcapacity: dropOffOvercapacity,
          } = dropOff;

          remoteJobData.dropOff = {
            address: dropOffAddress,
            addressData: {
              address_city: dropOffAddressCity,
              address_line1: dropOffAddressLine1,
              address_line2: dropOffAddressLine2,
              address_state: dropOffAddressState,
              address_zipcode: dropOffAddressZipcode,
            },
            collectionTime: dropOffCollectionTime,
            mainDriverId: dropOffMainDriver?.id || null,
            coDriverId: dropOffCoDriver && dropOffCoDriver.id,
            notes: dropOffNotes,
            jobId: dropOffJobId,
            geolocation: dropOffGeolocation,
            location_area: dropOffLocationArea,
            distance: dropOffDistance,
            overcapacity: dropOffOvercapacity,
          };

          dropOffDateTime = dropOffCollectionTime;
        }
      }

      if (mobileTechnician) {
        isMobileTechnician = true;

        const {
          address,
          address_city,
          address_line1,
          address_line2,
          address_state,
          address_zipcode,
          location_area,
          collection_time: collectionTime,
          technician,
          notes,
          id: jobId,
          geolocation,
          distance_in_miles,
          overcapacity,
        } = mobileTechnician;

        remoteJobData.mobileTechnician = {
          address,
          addressData: {
            address_city,
            address_line1,
            address_line2,
            address_state,
            address_zipcode,
          },
          collectionTime,
          technicianId: technician?.id || null,
          notes,
          jobId,
          location_area,
          geolocation,
          distance: distance_in_miles,
          overcapacity,
        };
      }

      const bookingMenuItems = action.payload.menu_items.map((item) => (item.menu_item_id ? item : {
        ...item,
        package_items: [],
        extras: [],
        premium_extras: [],
      }));

      return {
        ...state,
        data: {
          ...action.payload,
          menu_items: [...bookingMenuItems.filter((item) => item.kind !== "recall")],
        },
        selectedRecalls: [...bookingMenuItems.filter((item) => item.kind === "recall")],
        videos: {
          isLoading: false,
          pickUp: [],
          dropOff: [],
          mobileTechnician: [],
          walkaround: [],
          error: null,
        },
        error: null,
        isLoading: false,
        decisionTreeResults: action.payload.decision_tree_results,
        clientWaiting: action.payload.client_waiting,
        selectedAdvisor: action.payload.service_advisor,
        overcapacity: action.payload.overcapacity,
        walkin: action.payload.walkin,
        selectedTransport:
          action.payload.transport && action.payload.transport.kind,
        isPickUp,
        isDropOff,
        isMobileTechnician,
        remoteJobData,
        currentStep: BOOKING_STEP_SERVICES,
        saveAppointmentLoading: false,
        saveAppointmentError: false,
        chosenTimeSlot: {},
        sendSms: action.payload.send_sms,
        vehicleData: {},
        jobReachable: isPickUp || isMobileTechnician ? true : null,
        dropOffDateTime,
      };
    }
    case BOOKING_DETAILS_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload.error.response,
        isLoading: false,
      };
    case BOOKING_DETAILS_SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS:
      return {
        ...state,
        menuItemsLoading: true,
      };
    case BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        dealershipMenuItems: {
          ...state.dealershipMenuItems,
          maintenance: action.payload.maintenance || [],
          concern: action.payload.concern || [],
          extension: action.payload.extension || [],
          other: [...state.data.menu_items.filter((item) => item.kind === "other")] || [],
        },
        menuItemsLoading: false,
        menuItemsLoadingError: false,
        selectedServices: [
          ...preselectServices(
            action.payload.maintenance,
            state.data.menu_items,
          ),
          ...preselectServices(
            action.payload.concern,
            state.data.menu_items,
            state.decisionTreeResults,
          ),
          ...preselectServices(
            action.payload.extension,
            state.data.menu_items,
          ),
          ...state.data.menu_items.filter((item) => !item.menu_item_id),
        ],
      };
    case BOOKING_DETAILS_FETCH_DEALERSHIP_MENU_ITEMS_FAILURE:
      return {
        ...state,
        menuItemsLoading: false,
        menuItemsLoadingError: true,
      };
    case BOOKING_DETAILS_SET_SERVICE_SELECTION:
      if (action.payload.data.length > 0) {
        return {
          ...state,
          selectedServices: [...action.payload.data],
          decisionTreeResults: action.payload.decisionTreeResults,
          selectedTransport: null,
        };
      }
      return {
        ...state,
        selectedServices: [],
        selectedAdvisor: null,
        firstAvailable: false,
        selectedTransport: null,
      };
    case BOOKING_DETAILS_SET_SERVICE_ADVISOR:
      return {
        ...state,
        selectedAdvisor: action.payload,
        firstAvailable: action.payload ? action.payload.id === FIRST_AVAILABLE_ADVISOR_ID : false,
      };
    case BOOKING_DETAILS_FETCH_DAYS:
      return {
        ...state,
        timeSlotsLoading: true,
      };
    case BOOKING_DETAILS_FETCH_JOB_SLOTS_SUCCESS:
      return {
        ...state,
        availableJobSlots: action.payload,
        availableTechiciansJobSlots: [],
        availableSlots: [],
        timeSlotsLoading: false,
        timeSlotsLoadingError: false,
      };
    case BOOKING_DETAILS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS:
      return {
        ...state,
        availableJobSlots: [],
        availableTechiciansJobSlots: action.payload,
        availableSlots: [],
        timeSlotsLoading: false,
        timeSlotsLoadingError: false,
      };
    case BOOKING_DETAILS_FETCH_DAYS_SUCCESS:
      return {
        ...state,
        availableJobSlots: [],
        availableTechiciansJobSlots: [],
        availableSlots: action.payload,
        timeSlotsLoading: false,
        timeSlotsLoadingError: false,
      };
    case BOOKING_DETAILS_FETCH_DAYS_FAILURE:
      return {
        ...state,
        timeSlotsLoading: false,
        timeSlotsLoadingError: true,
      };
    case BOOKING_DETAILS_SET_CHOSEN_TIMESLOT: {
      if (Object.keys(action.payload).length > 0) {
        const { overcapacity, walkin } = action.payload;
        return {
          ...state,
          chosenTimeSlot: action.payload,
          overcapacity,
          walkin,
        };
      }
      return {
        ...state,
        chosenTimeSlot: action.payload,
        overcapacity: state.overcapacity,
        walkin: state.walkin,
      };
    }
    case BOOKING_DETAILS_SET_CHOSEN_DROP_OFF_TIMESLOT: {
      return {
        ...state,
        chosenDropOffTimeSlot: action.payload,
      };
    }
    case BOOKING_DETAILS_SET_REMOTE_JOB_COLLECTION_TIME: {
      return {
        ...state,
        remoteJobData: {
          ...state.remoteJobData,
          collectionTime: action.payload,
        },
      };
    }
    case BOOKING_DETAILS_SET_CLIENT_WAITING:
      return {
        ...state,
        clientWaiting: action.payload,
      };
    case BOOKING_DETAILS_SET_CLIENT_TRANSPORT:
      return {
        ...state,
        selectedTransport: action.payload,
      };
    case BOOKING_DETAILS_SET_SEND_SMS: {
      return {
        ...state,
        sendSms: action.payload,
      };
    }
    case BOOKING_DETAILS_SET_CHECKED_IN_STATUS: {
      return {
        ...state,
        isCheckedIn: action.payload,
      };
    }
    case BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT:
      return {
        ...state,
        availableTransportLoading: true,
        availableTransport: [],
        availableTransportError: null,
      };
    case BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_SUCCESS:
      return {
        ...state,
        availableTransport: action.payload.transports,
        availableTransportLoading: false,
        availableTransportError: null,
      };
    case BOOKING_DETAILS_UPDATE_APPOINTMENT:
      return {
        ...state,
        saveAppointmentLoading: true,
      };
    case BOOKING_DETAILS_UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        decisionTreeResults: action.payload.decision_tree_results,
        error: null,
        isLoading: false,
        client_waiting: action.payload.client_waiting,
        selectedAdvisor: action.payload.service_advisor,
        selectedTransport:
          action.payload.transport && action.payload.transport.kind,
        isPickUp: !!action.payload.jobs.find(
          (job) => job.job_type === "pickup" && job.aasm_state !== "cancelled",
        ),
        isMobileTechnician: !!action.payload.jobs.find(
          (job) => job.job_type === "mobile_technician" && job.aasm_state !== "cancelled",
        ),
        saveAppointmentLoading: false,
        saveAppointmentError: false,
        chosenTimeSlot: {},
        currentStep: BOOKING_STEP_SERVICES,
      };
    case BOOKING_DETAILS_UPDATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        saveAppointmentLoading: false,
        saveAppointmentError: action.payload.errors.error || { message: action.payload.errors },
      };
    case BOOKING_DETAILS_FETCH_AVAILABLE_TRANSPORT_FAILURE:
      return {
        ...state,
        availableTransportLoading: false,
        availableTransport: [],
        availableTransportError: true,
      };
    case BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          pickUpData: action.payload.pickUp,
          dropOffData: action.payload.dropOff,
          isLoadingPickUp: false,
          isLoadingDropOff: false,
          error: null,
        },
      };
    case BOOKING_DETAILS_FETCH_AVAILABLE_DRIVERS_FAILURE:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          error: action.payload.errors,
          pickUpData: action.payload.pickUp,
          dropOffData: action.payload.dropOff,
          isLoadingPickUp: false,
          isLoadingDropOff: false,
        },
      };
    case BOOKING_DETAILS_SET_RECALLS_SELECTION:
      return {
        ...state,
        selectedRecalls: action.payload,
      };
    case BOOKING_DETAILS_SET_NEW_DRIVER_JOB:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case BOOKING_DETAILS_SET_NEW_DRIVER_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: {
          ...state.data,
          jobs: [...state.data.jobs, action.payload],
        },
      };
    case BOOKING_DETAILS_SET_NEW_DRIVER_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.errors,
      };
    case BOOKING_DETAILS_SET_NEW_MOBILE_TECHNICIAN_JOB:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case BOOKING_DETAILS_FETCH_VIDEOS:
      return {
        ...state,
        videos: {
          isLoading: true,
          pickUp: [],
          dropOff: [],
          mobileTechnician: [],
          walkaround: [],
          error: null,
        },
      };
    case BOOKING_DETAILS_FETCH_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: {
          isLoading: false,
          pickUp: action.payload.pickup,
          dropOff: action.payload.dropoff,
          mobileTechnician: action.payload.mobileTechnician,
          walkaround: action.payload.walkaround,
          error: null,
        },
      };
    case BOOKING_DETAILS_FETCH_VIDEOS_FAILURE:
      return {
        ...state,
        videos: {
          isLoading: false,
          pickUp: [],
          dropOff: [],
          mobileTechnician: [],
          walkaround: [],
          error: action.payload.errors,
        },
      };
    case BOOKING_DETAILS_UPDATE_DRIVER_JOB:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case BOOKING_DETAILS_UPDATE_DRIVER_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: {
          ...state.data,
          jobs: state.data.jobs.map((job) => (
            job.id === action.payload.id ? action.payload : job
          )),
        },
      };
    case BOOKING_DETAILS_UPDATE_DRIVER_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.errors,
      };
    case BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: {
          ...state.data,
          jobs: state.data.jobs.map((job) => (
            job.id === action.payload.id ? action.payload : job
          )),
        },
      };
    case BOOKING_DETAILS_UPDATE_MOBILE_TECHNICIAN_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.errors,
      };
    case BOOKING_DETAILS_DELETE_BOOKING:
      return {
        ...state,
        saveAppointmentLoading: true,
        saveAppointmentError: null,
      };
    case BOOKING_DETAILS_DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case BOOKING_DETAILS_GENERATE_REPAIR_ORDER_SUCCESS:
      return {
        ...state,
        data: {
          jobs: [],
        },
        isROGenerated: true,
      };
    case BOOKING_DETAILS_SET_ARRIVED:
      return {
        ...state,
        isLoading: true,
        isArrived: true,
      };
    case BOOKING_DETAILS_DELETE_BOOKING_FAILURE:
    case BOOKING_DETAILS_GENERATE_REPAIR_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.errors,
      };
    case BOOKING_DETAILS_TRIGGER_CHECKIN_SMS:
      return {
        ...state,
        isTriggerSmsLoading: true,
        triggerSmsError: null,
      };
    case BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_SUCCESS:
      return {
        ...state,
        isTriggerSmsLoading: false,
        isSmsSent: true,
        triggerSmsError: null,
      };
    case BOOKING_DETAILS_TRIGGER_CHECKIN_SMS_FAILURE:
      return {
        ...state,
        isTriggerSmsLoading: false,
        triggerSmsError: action.payload.error,
      };

    case BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA:
    case BOOKING_DETAILS_UPDATE_CUSTOMER_DATA:
    case BOOKING_DETAILS_CREATE_CUSTOMER:
      return {
        ...state,
        dataEditionModalSaving: true,
        dataEditionModalSavingError: null,
      };
    case BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          vehicle: {
            ...state.data.vehicle,
            mileage: action.payload.mileage,
            vin: action.payload.vin,
            external_model_code: action.payload.external_model_code,
            plate_number: action.payload.plate_number,
          },
          vehicle_set: action.payload.vehicle_set,
        },
        dataEditionModalSaving: false,
        dataEditionModalSavingError: null,
      };
    case BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_SUCCESS_WITHOUT_DMS: {
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: null,
        updatedWithoutDmsNotification: action.payload,
      };
    }
    case BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_SUCCESS:
    case BOOKING_DETAILS_CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: "",
        data: {
          ...state.data,
          customer: {
            ...state.data.customer,
            ...action.payload,
          },
        },
      };
    case BOOKING_DETAILS_UPDATE_VEHICLE_SET_DATA_FAILURE:
    case BOOKING_DETAILS_UPDATE_CUSTOMER_DATA_FAILURE:
    case BOOKING_DETAILS_CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: action.payload,
      };
    case APP_RESET_VEHICLE_SET_BY_VIN:
      return {
        ...state,
        dataEditionModalSaving: false,
        dataEditionModalSavingError: null,
      };
    case BOOKING_DETAILS_UPDATE_WAITER_ALLOWED:
      return {
        ...state,
        waiterAllowed: action.payload,
      };
    case BOOKING_DETAILS_UPDATE_WAITER_ALLOWED_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case BOOKING_DETAILS_FETCH_VEHICLE_DATA:
      return {
        ...state,
        vehicleData: action.payload,
      };
    case BOOKING_DETAILS_FETCH_VEHICLE_RO:
      return {
        ...state,
        repairOrdersLoading: true,
        repairOrdersError: null,
      };
    case BOOKING_DETAILS_FETCH_VEHICLE_RO_SUCCESS:
      return {
        ...state,
        repairOrdersLoading: false,
        repairOrders: action.payload,
        repairOrdersError: null,
      };
    case BOOKING_DETAILS_FETCH_VEHICLE_RO_FAILURE:
      return {
        ...state,
        repairOrdersLoading: false,
        repairOrdersError: action.payload.response,
      };
    case BOOKING_DETAILS_UPDATE_AVAILABLE_LOANERS:
      return {
        ...state,
        availableLoaners: action.payload,
      };
    case BOOKING_DETAILS_SET_IS_PICK_UP:
      return {
        ...state,
        isPickUp: action.payload,
      };
    case BOOKING_DETAILS_SET_IS_DROP_OFF:
      return {
        ...state,
        isDropOff: action.payload,
      };
    case BOOKING_DETAILS_SET_IS_MOBILE_TECHNICIAN:
      return {
        ...state,
        isMobileTechnician: action.payload,
      };
    case BOOKING_DETAILS_SET_IS_DISTANCE_LIMIT_IGNORED:
      return {
        ...state,
        isDistanceLimitIgnored: action.payload,
      };
    case BOOKING_DETAILS_SET_BOOKING_REMOTE_JOB_DATA: {
      return {
        ...state,
        remoteJobData: {
          ...state.remoteJobData,
          ...action.payload,
        },
      };
    }
    case BOOKING_DETAILS_JOB_REACHABLE_LOADING: {
      return {
        ...state,
        isJobReachableLoading: true,
        jobReachable: action.payload === "dropoff" ? state.jobReachable : null,
        jobDropOffReachable: action.payload === "dropoff" ? null : state.jobDropOffReachable,
      };
    }
    case BOOKING_DETAILS_JOB_REACHABLE:
      return {
        ...state,
        jobReachable: action.payload.jobType === "dropoff" ? state.jobReachable : action.payload.reachable,
        jobDropOffReachable: action.payload.jobType === "dropoff" ? action.payload.reachable : state.jobDropOffReachable,
        isJobReachableLoading: false,
      };
    case BOOKING_DETAILS_FETCH_DRIVERS_INFO_SUCCESS:
      return {
        ...state,
        activeDrivers: action.payload.drivers,
        activeDriversTotal: action.payload.driversTotal,
        error: null,
      };
    case BOOKING_DETAILS_FETCH_DRIVERS_INFO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS:
      return {
        ...state,
        activeMobileTechnicians: action.payload.technicians,
        activeMobileTechniciansTotal: action.payload.techniciansTotal,
        error: null,
      };
    case BOOKING_DETAILS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case BOOKING_DETAILS_SET_REMOTE_SERVICE_LOCATION:
      return {
        ...state,
        serviceLocation: action.payload,
      };
    case BOOKING_DETAILS_FETCH_RECALLS:
      return {
        ...state,
        recallsLoading: true,
      };
    case BOOKING_DETAILS_FETCH_RECALLS_SUCCESS:
      return {
        ...state,
        availableRecalls: action.payload.recalls.map(
          (recall) => {
            return {
              ...recall,
              package_items: [],
              fee: 0,
            };
          },
        ),
        recallsLoading: false,
        recallsLoadingError: null,
      };
    case BOOKING_DETAILS_FETCH_RECALLS_ERROR:
      return {
        ...state,
        recallsLoading: false,
        recallsLoadingError: action.payload.error,
      };
    case BOOKING_DETAILS_FETCH_QUESTIONNAIRES:
      return {
        ...state,
        questionnairesLoading: true,
      };
    case BOOKING_DETAILS_FETCH_QUESTIONNAIRES_SUCCSESS:
      return {
        ...state,
        questionnairesId: action.payload.id,
        questionnaires: action.payload.data,
        questionnairesLoading: false,
        questionnairesError: null,
      };
    case BOOKING_DETAILS_FETCH_QUESTIONNAIRES_ERROR:
      return {
        ...state,
        questionnairesId: null,
        questionnaires: {},
        questionnairesLoading: false,
        questionnairesError: action.payload,
      };
    case BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_SUCCSESS:
      return {
        ...state,
        data: {
          ...state.data,
          questionnaire_answers: [action.payload],
        },
        questionnairesAnswersError: null,
      };
    case BOOKING_DETAILS_ADD_QUESTIONNAIRES_ANSWERS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          questionnaire_answers: [],
        },
        questionnairesAnswersError: action.payload,
      };
    default:
      return state;
  }
};

export default booking;
