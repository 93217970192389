import React from "react";
import { node, number } from "prop-types";

const Animation = ({
  delay, children,
}) => (
  <div className={`animation transition-delay-${delay}`}>{children}</div>
);

Animation.propTypes = {
  delay: number,
  children: node.isRequired,
};

Animation.defaultProps = {
  delay: 0,
};

export default Animation;
