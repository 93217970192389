import React, { useEffect, useState } from "react";
import cx from "classnames";
import {
  arrayOf, func, number, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import { emailValidator } from "shared/validators";
import Input from "components/common/Input";
import Modal from "components/common/Modal";
import { settingsRemoteUsersSelector } from "store/selectors/settings-selectors";
import { updateRemoteUser } from "store/actions/settings-actions";
import StyledSelect from "components/common/StyledSelect";

const roleOptions = {
  driver: {
    label: "Driver",
    value: "driver",
  },
  mobile_technician: {
    label: "Mobile Technician",
    value: "mobile_technician",
  },
};

const EditArriveUserModal = ({
  id,
  remoteUsers,
  changeArriveUser,
  onClose,
}) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [role, setRole] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const currentRemoteUser = remoteUsers.find((user) => user.id === id);
    if (currentRemoteUser) {
      setEmail(currentRemoteUser.email);
      setPassword(currentRemoteUser.decrypted_password);
      setRole(roleOptions[currentRemoteUser.role] || roleOptions.driver);
    }
  }, [remoteUsers]);

  const validate = () => {
    const validationErrors = {
      email: !emailValidator(email) && "Email must be present.",
      password: !password && "Password must be present.",
    };
    setErrors(validationErrors);
    return !Object.values(validationErrors).find((v) => v);
  };

  const submit = () => {
    if (validate()) {
      changeArriveUser(id, {
        email,
        password,
        role: role.value,
      })
        .then(() => onClose())
        .catch(({ response: { data } }) => setErrors({
          email: `Email ${data.email}`,
          password: data.password,
        }));
    }
  };

  return (
    <Modal
      title="Edit remote user"
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="small"
      onCancel={onClose}
      onSubmit={submit}
    >
      <Input
        inputClassName={cx({ error: !!errors.email })}
        label="Email"
        value={email}
        error={errors.email}
        onChange={setEmail}
      />
      <Input
        inputClassName={cx({ error: !!errors.password })}
        label="Password"
        value={password}
        error={errors.password}
        onChange={setPassword}
      />
      <StyledSelect
        value={role}
        options={Object.values(roleOptions)}
        onChange={setRole}
        menuPlacement="top"
      />
    </Modal>
  );
};

EditArriveUserModal.propTypes = {
  id: number.isRequired,
  onClose: func.isRequired,
  changeArriveUser: func.isRequired,
  remoteUsers: arrayOf(shape({
    email: string.isRequired,
    password: string.isRequired,
  })),
};

EditArriveUserModal.defaultProps = {
  remoteUsers: [],
};

const mapStateToProps = (state) => {
  return {
    remoteUsers: settingsRemoteUsersSelector(state),
  };
};

const actions = {
  changeArriveUser: updateRemoteUser,
};

export default connect(mapStateToProps, actions)(EditArriveUserModal);
