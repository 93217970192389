import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, node, number, objectOf, string,
} from "prop-types";
import cx from "classnames";
import uniqid from "uniqid";
import Button from "components/common/Button";
import { filterPages, getPages, getVisiblePages } from "./utils";

import "./styles.scss";

const Pagination = ({
  className,
  style,
  total,
  page,
  pageSize,
  rowsSelectorText,
  onPageChange,
  onPageSizeChange,
  showPageSizeOptions,
  pageSizeOptions,
  PreviousComponent,
  NextComponent,
}) => {
  const [visiblePages, setVisiblePages] = useState(getVisiblePages(null, total, pageSize));

  useEffect(() => {
    setVisiblePages(getVisiblePages(null, total, pageSize));
  }, [total, pageSize]);

  const getSafePage = (value) => (Number.isNaN(value) ? page : value);

  const changePage = (value) => {
    const safePage = getSafePage(value);
    const pages = getPages(total, pageSize);

    setVisiblePages(filterPages(getVisiblePages(safePage, total, pageSize), pages));

    if (page !== safePage) {
      onPageChange(safePage);
    }
  };

  const canNext = () => {
    const pages = getPages(total, pageSize);
    const currentPage = page + 1;
    return currentPage <= pages;
  };

  const canPrevious = () => page !== 1;

  return (
    <div className={cx(className, "-pagination")} style={style}>
      <section className="conciergeTablePaginationButtonsWrapper">
        <div className="-previous">
          <PreviousComponent
            variant="neutral"
            leftIcon="leftArrow"
            padding="small"
            onClick={() => {
              if (!canPrevious()) {
                return;
              }
              changePage(page - 1);
            }}
            disabled={!canPrevious()}
          />
        </div>
        <div className="-center">
          {visiblePages.map((pageNumber, index, array) => (
            <Button
              key={pageNumber}
              className={
                page === pageNumber
                  ? "conciergeTablePaginationPageButtonActive"
                  : "conciergeTablePaginationPageButton"
              }
              padding="small"
              onClick={() => changePage(pageNumber)}
            >
              {array[index - 1] + 2 < pageNumber
                && <span className="conciergeTablePaginationPageButtonDots">...</span>}
              {pageNumber}
            </Button>
          ))}
        </div>
        <div className="-next">
          <NextComponent
            onClick={() => {
              if (!canNext()) {
                return;
              }
              changePage(page + 1);
            }}
            disabled={!canNext()}
            variant="neutral"
            rightIcon="rightArrow"
            padding="small"
          />
        </div>
      </section>
      {showPageSizeOptions && (
        <span className="select-wrap -pageSizeOptions">
          <span>Show records: </span>
          <select
            className="conciergeTablePaginationPageSizeSelect"
            aria-label={rowsSelectorText}
            onChange={(event) => {
              onPageSizeChange(Number(event.target.value));
            }}
            value={pageSize}
          >
            {pageSizeOptions.map((option) => (
              <option key={uniqid()} value={option}>
                {`${option}`}
              </option>
            ))}
          </select>
        </span>
      )}
    </div>
  );
};

Pagination.propTypes = {
  page: number.isRequired,
  onPageChange: func.isRequired,
  showPageSizeOptions: bool,
  pageSizeOptions: arrayOf(number).isRequired,
  pageSize: number,
  onPageSizeChange: func,
  className: string,
  style: objectOf(string),
  PreviousComponent: objectOf(node),
  NextComponent: objectOf(node),
  rowsSelectorText: string,
  total: number,
};

Pagination.defaultProps = {
  showPageSizeOptions: true,
  pageSize: 0,
  onPageSizeChange: () => {},
  className: null,
  style: null,
  PreviousComponent: Button,
  NextComponent: Button,
  rowsSelectorText: null,
  total: 0,
};

export default Pagination;
