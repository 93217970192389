import {
  APP_CLEAR_REPORTS,
} from "store/actions/app-actions";
import {
  REPORTING_FETCH_EMPLOYEE_REPORTING_FAILURE,
  REPORTING_FETCH_EMPLOYEE_REPORTING_SUCCESS,
  REPORTING_FETCH_REPORTS_CUSTOMER_ACTIVITY_SUCCESS,
  REPORTING_FETCH_REPORTS_EFFICIENCY_SUCCESS,
  REPORTING_FETCH_REPORTS_GENERAL_SUCCESS,
  REPORTING_SET_LOADING_STATE,
} from "store/actions/reporting-actions";

const initialState = {
  dealership: {
    max_available_time_to_allocate: null,
    total_allocated_time: null,
    total_actual_time: null,
    appointments_average_value_by_customers: null,
    appointments_average_value_by_dealer: null,
    appointments_average_value_imported: null,
    appointments_total_imported: null,
    appointments_total_made_by_customers: null,
    appointments_total_made_by_dealer: null,
    appointments_total: null,
    appointments_total_appraisals: null,
    appointments_total_cancelled: null,
    checkins_total: null,
    checkins_total_made_by_customers: null,
    total_expected_upsell: null,
    total_expected_buffer_time: null,
    customer_app_registered: null,
    customer_app_unregistered: null,
    appointments_total_showed_with_premium: null,
  },
  employee: [],
  isLoading: false,
  error: null,
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case APP_CLEAR_REPORTS:
      return {
        ...state,
        dealership: {
          max_available_time_to_allocate: null,
          total_allocated_time: null,
          total_actual_time: null,
          appointments_average_value_by_customers: null,
          appointments_average_value_by_dealer: null,
          appointments_average_value_imported: null,
          appointments_total_imported: null,
          appointments_total_made_by_customers: null,
          appointments_total_made_by_dealer: null,
          appointments_total: null,
          appointments_total_appraisals: null,
          appointments_total_cancelled: null,
          checkins_total: null,
          checkins_total_made_by_customers: null,
          total_expected_upsell: null,
          total_expected_buffer_time: null,
        },
      };
    case REPORTING_FETCH_REPORTS_EFFICIENCY_SUCCESS:
      return {
        ...state,
        dealership: {
          ...state.dealership,
          ...action.payload,
        },
      };
    case REPORTING_FETCH_REPORTS_GENERAL_SUCCESS:
      return {
        ...state,
        dealership: {
          ...state.dealership,
          ...action.payload,
        },
      };
    case REPORTING_FETCH_REPORTS_CUSTOMER_ACTIVITY_SUCCESS:
      return {
        ...state,
        dealership: {
          ...state.dealership,
          ...action.payload,
        },
      };
    case REPORTING_SET_LOADING_STATE:
      return {
        ...state,
        isLoading: true,
      };
    case REPORTING_FETCH_EMPLOYEE_REPORTING_SUCCESS:
      return {
        ...state,
        employee: [...action.payload],
        isLoading: false,
      };
    case REPORTING_FETCH_EMPLOYEE_REPORTING_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reports;
