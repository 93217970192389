import React, { useEffect, useState } from "react";
import { bool, node, number } from "prop-types";
import { GoogleMap } from "@react-google-maps/api";

import "./styles.scss";

const Map = ({
  center,
  zoom,
  maxZoom,
  minZoom,
  zoomControl,
  mapTypeControl,
  scaleControl,
  streetViewControl,
  rotateControl,
  fullscreenControl,
  children,
}) => {
  const [map, setMap] = useState();

  useEffect(() => {
    if (map) {
      map.setCenter(center);
    }
  }, [zoom]);

  return (
    <GoogleMap
      onLoad={(item) => setMap(item)}
      mapContainerClassName="googleMap"
      center={center}
      zoom={zoom}
      options={{
        maxZoom,
        minZoom,
        zoomControl,
        mapTypeControl,
        scaleControl,
        streetViewControl,
        rotateControl,
        fullscreenControl,
        styles: [
          {
            elementType: "geometry",
            stylers: [{ color: "#0f1114" }],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#0f1114" }],
          },
          {
            elementType: "labels.text.fill",
            stylers: [{ color: "#787878" }],
          },
          {
            featureType: "administrative.country",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9e9e9e" }],
          },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#bdbdbd" }],
          },
          {
            featureType: "administrative.neighborhood",
            elementType: "labels.text.fill",
            stylers: [{ color: "#565b67" }],
          },
          {
            featureType: "administrative.neighborhood",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#000000" }],
          },
          {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#101e1e" }],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#292f38" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#0f1114" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#5d6674" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#000000" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#293b51" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#0b0b0f" }],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [{ color: "#5d6674" }],
          },
          {
            featureType: "transit",
            elementType: "labels.text.fill",
            stylers: [{ color: "#666666" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#000000" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#354245" }],
          },
        ],
      }}
    >
      {children}
    </GoogleMap>
  );
};

Map.propTypes = {
  center: {
    lat: number,
    lng: number,
  }.isRequired,
  zoom: number,
  maxZoom: number,
  minZoom: number,
  zoomControl: bool,
  mapTypeControl: bool,
  scaleControl: bool,
  streetViewControl: bool,
  rotateControl: bool,
  fullscreenControl: bool,
  children: node,
};

Map.defaultProps = {
  zoom: 12,
  maxZoom: null,
  minZoom: null,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  children: null,
};

export default Map;
