import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bool, func } from "prop-types";

import qrIcon from "assets/images/qr.svg";
import bookingsIcon from "assets/images/baseline-receipt-24px.svg";
import schedulingIcon from "assets/images/scheduling.svg";
import remoteService from "assets/images/remoteService.svg";
import Navigation from "components/common/Navigation";

import { clearPeriodicallyFetchInterval } from "store/actions/bookings-actions";
import { switchShowQR } from "store/actions/app-actions";
import { showQRSelector } from "store/selectors/app-selectors";
import {
  settingsDealershipMobileTechnicianEnabledSelector,
  settingsDealershipRemoteJobsEnabledSelector,
} from "store/selectors/settings-selectors";

const DealershipAdvisorNavigation = ({
  showQR,
  setShowQR,
  clearInterval,
  remoteJobsEnabled,
  mobileTechnicianEnabled,
}) => (
  <Navigation>
    <li>
      <NavLink
        to={{ pathname: "/scheduling" }}
        activeClassName="conciergeNavLinkActive"
        className="conciergeNavLink"
        onClick={() => clearInterval()}
      >
        <img src={schedulingIcon} alt="concierge scheduling" className="conciergeNavLinkImg" />
        <p className="conciergeNavLinkText">Scheduling</p>
      </NavLink>
    </li>
    <li className="conciergeNavigationItem">
      <NavLink
        to={{ pathname: "/bookings" }}
        activeClassName="conciergeNavLinkActive"
        className="conciergeNavLink"
        onClick={() => clearInterval()}
      >
        <img src={bookingsIcon} alt="concierge bookings" className="conciergeNavLinkImg" />
        <p className="conciergeNavLinkText">Bookings</p>
      </NavLink>
    </li>
    {(remoteJobsEnabled || mobileTechnicianEnabled) && (
      <li className="conciergeNavigationItem">
        <NavLink
          to={{ pathname: "/service" }}
          activeClassName="conciergeNavLinkActive"
          className="conciergeNavLink"
          onClick={() => clearInterval()}
        >
          <img
            src={remoteService}
            alt="concierge remote service"
            className="conciergeNavLinkImg"
          />
          <p className="conciergeNavLinkText">Remote Service</p>
        </NavLink>
      </li>
    )}
    <li>
      <div
        className={cx("conciergeQRButton conciergeNavLink", {
          conciergeNavLinkActive: showQR,
        })}
        onClick={setShowQR}
      >
        <img src={qrIcon} alt="concierge settings" className="conciergeQRButtonImg" />
      </div>
    </li>
  </Navigation>
);

DealershipAdvisorNavigation.propTypes = {
  showQR: bool.isRequired,
  setShowQR: func.isRequired,
  clearInterval: func.isRequired,
  remoteJobsEnabled: bool.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    showQR: showQRSelector(state),
    remoteJobsEnabled: settingsDealershipRemoteJobsEnabledSelector(state),
    mobileTechnicianEnabled: settingsDealershipMobileTechnicianEnabledSelector(state),
  };
};

const actions = {
  clearInterval: clearPeriodicallyFetchInterval,
  setShowQR: switchShowQR,
};

export default connect(mapStateToProps, actions)(DealershipAdvisorNavigation);
