import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bool, func } from "prop-types";
import cx from "classnames";

import bookingsIcon from "assets/images/baseline-receipt-24px.svg";
import templatesIcon from "assets/images/baseline-forum-24px.svg";
import settingsIcon from "assets/images/baseline-settings-20px.svg";
import schedulingIcon from "assets/images/scheduling.svg";
import reportsIcon from "assets/images/reports.svg";
import qrIcon from "assets/images/qr.svg";
import Navigation from "components/common/Navigation";
import remoteService from "assets/images/remoteService.svg";

import { clearPeriodicallyFetchInterval } from "store/actions/bookings-actions";
import { switchShowQR, switchShowReports } from "store/actions/app-actions";
import { showQRSelector, showReportsSelector } from "store/selectors/app-selectors";
import {
  settingsDealershipMobileTechnicianEnabledSelector,
  settingsDealershipRemoteJobsEnabledSelector,
} from "store/selectors/settings-selectors";
import ReactTooltip from "react-tooltip";

const DealershipAdminNavigation = ({
  showReports,
  setShowReports,
  showQR,
  setShowQR,
  clearInterval,
  remoteJobsEnabled,
  mobileTechnicianEnabled,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Navigation>
      <li>
        <NavLink
          to={{ pathname: "/scheduling" }}
          activeClassName="conciergeNavLinkActive"
          className="conciergeNavLink"
          onClick={() => clearInterval()}
        >
          <img
            src={schedulingIcon}
            alt="concierge scheduling"
            className="conciergeNavLinkImg"
          />
          <p className="conciergeNavLinkText">Scheduling</p>
        </NavLink>
      </li>
      <li className="conciergeNavigationItem">
        <NavLink
          to={{ pathname: "/bookings" }}
          activeClassName="conciergeNavLinkActive"
          className="conciergeNavLink"
          onClick={() => clearInterval()}
        >
          <img
            src={bookingsIcon}
            alt="concierge bookings"
            className="conciergeNavLinkImg"
          />
          <p className="conciergeNavLinkText">Bookings</p>
        </NavLink>
      </li>
      {(remoteJobsEnabled || mobileTechnicianEnabled) && (
        <li className="conciergeNavigationItem">
          <NavLink
            to={{ pathname: "/service" }}
            activeClassName="conciergeNavLinkActive"
            className="conciergeNavLink"
            onClick={() => clearInterval()}
          >
            <img
              src={remoteService}
              alt="concierge remote service"
              className="conciergeNavLinkImg"
            />
            <p className="conciergeNavLinkText">Remote Service</p>
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          to={{ pathname: "/templates" }}
          activeClassName="conciergeNavLinkActive"
          className="conciergeNavLink"
          onClick={() => clearInterval()}
        >
          <img
            src={templatesIcon}
            alt="concierge templates"
            className="conciergeNavLinkImg"
          />
          <p className="conciergeNavLinkText">Menu templates</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          to={{ pathname: "/settings" }}
          activeClassName="conciergeNavLinkActive"
          className="conciergeNavLink"
          onClick={() => clearInterval()}
        >
          <img
            src={settingsIcon}
            alt="concierge settings"
            className="conciergeNavLinkImg"
          />
          <p className="conciergeNavLinkText">Settings</p>
        </NavLink>
      </li>
      <li>
        <div
          className={cx("conciergeReportsButton conciergeNavLink", {
            conciergeNavLinkActive: showReports,
          })}
          onClick={setShowReports}
        >
          <img
            data-for="navigation"
            data-tip="Reports"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            src={reportsIcon}
            alt="concierge settings"
            className="conciergeReportsButtonImg"
          />
        </div>
      </li>
      <li>
        <div
          className={cx("conciergeQRButton conciergeNavLink", {
            conciergeNavLinkActive: showQR,
          })}
          onClick={setShowQR}
        >
          <img
            data-for="navigation"
            data-tip="Service App"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            src={qrIcon}
            alt="concierge settings"
            className="conciergeQRButtonImg"
          />
        </div>
      </li>
      {showTooltip && (
        <ReactTooltip
          id="navigation"
          className="conciergeNavLinkTooltip"
          delayShow={100}
          place="bottom"
          backgroundColor="#F9FAFC"
          textColor="black"
          border
          borderColor="#dedee0"
        />
      )}
    </Navigation>
  );
};

DealershipAdminNavigation.propTypes = {
  showReports: bool.isRequired,
  setShowReports: func.isRequired,
  showQR: bool.isRequired,
  setShowQR: func.isRequired,
  clearInterval: func.isRequired,
  remoteJobsEnabled: bool.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    showReports: showReportsSelector(state),
    showQR: showQRSelector(state),
    remoteJobsEnabled: settingsDealershipRemoteJobsEnabledSelector(state),
    mobileTechnicianEnabled: settingsDealershipMobileTechnicianEnabledSelector(state),
  };
};

const actions = {
  clearInterval: clearPeriodicallyFetchInterval,
  setShowReports: switchShowReports,
  setShowQR: switchShowQR,
};

export default connect(mapStateToProps, actions)(DealershipAdminNavigation);
