/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import {
  bool, func, oneOfType, shape, string,
} from "prop-types";

import Block from "components/common/Block";
import { customerPropType, vehiclePropType } from "shared/prop-types";
import {
  setCurrentCustomer,
  setCurrentVehicle,
} from "store/actions/scheduling-process-actions";
import {
  schedulingCustomerSavingErrorSelector,
  schedulingCustomerSavingSelector,
} from "store/selectors/scheduling-selectors";
import {
  resetDataEditionState,
  updateVehicleData,
} from "store/actions/scheduling-actions";
import { updateVehicleFields } from "shared/fields";
import { imageUrl, isVehicleBookable } from "shared/utils/common";

import EditVehicleModal from "components/common/DataEditionModal/EditVehicleModal";
import Button from "components/common/Button";
import { dealershipMakeModelYearMapSelector } from "store/selectors/app-selectors";
import { settingsDefaultMakeSelector } from "store/selectors/settings-selectors";
import HistoryModal from "components/common/HistoryModal";
import { NotificationContainer, NotificationManager } from "react-notifications";
import styles from "../styles.module.scss";

const VehicleDetails = ({
  vehicle,
  dispatchCurrentVehicle,
  customer,
  dispatchCurrentCustomer,
  dataEditionModalSaving,
  dataEditionModalSavingError,
  updateVehicle,
  resetDataEditionModalState,
  makeModelYearMap,
  defaultMake,
}) => {
  const [vehicleEditorVisible, setVehicleEditorVisible] = useState(false);
  const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
  const isVehicleNotInDms = () => !vehicle.on_dms && vehicle.vin;

  const dispatchCurrentInformation = () => {
    dispatchCurrentVehicle(vehicle);
    dispatchCurrentCustomer(customer);

    if (!customer.phone_number) {
      NotificationManager.error(
        "Please provide the customer's phone number.",
        "Error",
        5000,
      );
    }
  };

  const hideDataEditionModal = () => {
    resetDataEditionModalState();

    setVehicleEditorVisible(false);
  };

  const sendUpdateVehicleData = (data) => {
    const dataToSend = {
      ...data,
      id: vehicle.id,
    };

    updateVehicle(dataToSend);
  };

  return (
    <Block
      key={vehicle.id}
      className={cx(styles.block, {
        [styles.blockInactive]: isVehicleNotInDms(),
      })}
    >
      <section className={styles.vehicleHeader}>
        <p>
          {vehicle?.vehicle_set?.model_year}
          {", "}
          {vehicle?.vehicle_set?.make}
          {" "}
          {vehicle?.vehicle_set?.model}
        </p>
        <div className={styles.buttonsContainer}>
          <Button
            withoutBorder
            variant="base-grey"
            leftIcon="editGrey"
            padding="small"
            onClick={() => setVehicleEditorVisible(true)}
          >
            Edit
          </Button>
          {vehicle?.vin && (
            <>
              <hr />
              <Button
                withoutBorder
                variant="primary-outline"
                padding="small"
                onClick={() => setHistoryModalOpen(true)}
              >
                View history
              </Button>

            </>
          )}
        </div>
      </section>
      <div className={styles.vehicle}>
        <div
          className={cx(styles.vehicleContainer, {
            [styles.imageContainer]: isVehicleNotInDms(),
          })}
        >
          {vehicle?.image?.url && (
            <img
              className={styles.vehicleImage}
              src={imageUrl(vehicle.image)}
              alt=""
            />
          )}
          {!vehicle?.image?.url && (
            <p className={styles.vehicleNoImage}>
              No vehicle image
            </p>
          )}
          {isVehicleNotInDms() && (
            <div className={styles.inactive}>
              <span>
                WARNING:
              </span>
              <span>
                THIS VEHICLE IS LISTED UNDER ANOTHER OWNER
              </span>
              <span>
                PLEASE COPY VIN, THEN RE-START SCHEDULING PROCESS  BY PASTING VIN
              </span>
            </div>
          )}
        </div>
        <div className={styles.vehicleContainer}>
          <div className={styles.vehicleSectionsContainer}>
            <section className={styles.vehicleColumn}>
              <section className={styles.vehicleSection}>
                <p className={styles.label}>
                  Make
                </p>
                <p>{vehicle?.vehicle_set?.make}</p>
              </section>
              <section className={styles.vehicleSection}>
                <p className={styles.label}>
                  Year
                </p>
                <p>{vehicle?.vehicle_set?.model_year}</p>
              </section>
              <section className={styles.vehicleSection}>
                <p className={styles.label}>
                  Model
                </p>
                <p>{vehicle?.vehicle_set?.model}</p>
                {vehicle.appointment && (
                  <section className={styles.buttonSection}>
                    <NavLink
                      to={{
                        pathname: `/bookings/${vehicle.appointment.guid}/${vehicle.appointment.kind}`,
                        search: `?dealershipId=${vehicle.appointment.dealership_id}`,
                      }}
                    >
                      <Button
                        variant={isVehicleNotInDms() ? "base-grey" : "dark-outline"}
                        isBooked
                      >
                        {vehicle.appointment.appointment_datetime}
                      </Button>
                    </NavLink>
                  </section>
                )}
              </section>
            </section>
          </div>
          <div className={styles.vehicleSectionsContainer}>
            <section className={styles.vehicleColumn}>
              <section className={styles.vehicleSection}>
                <p className={styles.label}>
                  VIN
                </p>
                <p>{vehicle.vin}</p>
              </section>
              <section className={styles.vehicleSection}>
                <p className={styles.label}>
                  Plate
                </p>
                <p>{vehicle.plate_number}</p>
              </section>
              <section className={styles.vehicleSection}>
                <p className={styles.label}>
                  Mileage
                </p>
                <p>{vehicle.mileage}</p>
                <section className={styles.buttonSection}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to={{ pathname: customer?.phone_number ? "/scheduling/process" : "/scheduling" }}
                  >
                    <Button
                      variant={isVehicleNotInDms() ? "base-grey" : "brand"}
                      fullWidth
                      onClick={() => dispatchCurrentInformation()}
                      disabled={!isVehicleBookable(vehicle) || isVehicleNotInDms()}
                    >
                      Book an appointment
                    </Button>
                  </NavLink>
                </section>
              </section>
            </section>
          </div>
        </div>
      </div>
      {vehicleEditorVisible && (
        <EditVehicleModal
          initialData={{
            mileage: vehicle.mileage,
            vin: vehicle.vin,
            external_model_code: vehicle.external_model_code,
            plate_number: vehicle.plate_number,
            ...vehicle.vehicle_set,
          }}
          onClose={() => hideDataEditionModal()}
          title="Update vehicle"
          fields={updateVehicleFields}
          onSubmit={(data) => sendUpdateVehicleData(data)}
          submitButtonText="Update"
          loading={dataEditionModalSaving}
          error={dataEditionModalSavingError}
          makeModelYearMap={makeModelYearMap}
          defaultMake={defaultMake}
        />
      )}
      {isHistoryModalOpen && (
        <HistoryModal
          onClose={() => setHistoryModalOpen(false)}
          vehicleId={vehicle.id}
        />
      )}
      <NotificationContainer />
    </Block>
  );
};

VehicleDetails.propTypes = {
  vehicle: vehiclePropType,
  customer: customerPropType,
  dispatchCurrentVehicle: func,
  dispatchCurrentCustomer: func,
  dataEditionModalSaving: bool.isRequired,
  dataEditionModalSavingError: string.isRequired,
  updateVehicle: func.isRequired,
  resetDataEditionModalState: func.isRequired,
  defaultMake: string,
  makeModelYearMap: oneOfType(shape({ make: func }), func).isRequired,
};

VehicleDetails.defaultProps = {
  vehicle: null,
  customer: null,
  dispatchCurrentVehicle: () => {},
  dispatchCurrentCustomer: () => {},
  defaultMake: null,
};

const mapStateToProps = (state) => {
  return {
    dataEditionModalSaving: schedulingCustomerSavingSelector(state),
    dataEditionModalSavingError: schedulingCustomerSavingErrorSelector(state),
    makeModelYearMap: dealershipMakeModelYearMapSelector(state),
    defaultMake: settingsDefaultMakeSelector(state),
  };
};

const actions = {
  dispatchCurrentVehicle: setCurrentVehicle,
  dispatchCurrentCustomer: setCurrentCustomer,
  updateVehicle: updateVehicleData,
  resetDataEditionModalState: resetDataEditionState,
};

export default connect(mapStateToProps, actions)(VehicleDetails);
