import React, { useCallback, useEffect, useState } from "react";
import {
  arrayOf, bool, func, number, objectOf, string,
} from "prop-types";
import {
  serviceAdvisorPropType,
} from "shared/prop-types";
import Switch from "react-switch";
import ClipLoader from "react-spinners/ClipLoader";

import { BOOKING_STEP_ADVISOR, BOOKING_STEP_TIMESLOT } from "shared/constants";

import Alert from "components/common/Alert";
import Button from "components/common/Button";
import LocationInput from "components/common/Remote/LocationInput";

import styles from "./styles.module.scss";

const ChooseRemoteService = ({
  setSchedulingStep,
  checksJobDistance,
  resetJobReachableData,
  schedulingPickUp,
  schedulingDropOff,
  schedulingMobileTechnician,
  schedulingDistanceLimitIgnored,
  setServiceLocation,
  makeAdvisorSelection,
  isJobReachableLoading,
  jobReachable,
  jobDropOffReachable,
  customerPickupAddress,
  customerPickupAddressData,
  customerDropoffAddress,
  customerDropoffAddressData,
  remoteJobsEnabled,
  mobileTechnicianEnabled,
  allServicesIsMobile,
  isPickUp,
  isDropOff,
  isMobileTechnician,
  teamServiceAdvisors,
  dealershipPosition,
  segmentsNumber,
  ignoreDistance,
  segmentsMobileTechnicianNumber,
  zone,
  dropOffZone,
  pickUpGeolocation,
  dropOffGeolocation,
  selectedAdvisor,
}) => {
  const [firstTimeAddressChecked, setFirstTimeAddressChecked] = useState(false);
  const [wasActive, setWasActive] = useState(false);
  const [mobileTechnician, setMobileTechnician] = useState(isMobileTechnician);
  const [pickUp, setPickUp] = useState(isPickUp);
  const [droppOff, setDroppOff] = useState(isDropOff);
  const [sameDroppOffAddress, setSameDroppOffAddress] = useState(
    customerPickupAddress === customerDropoffAddress,
  );
  const [emptyAddress, setEmptyAddress] = useState(false);
  const [emptyDropOffAddress, setEmptyDropOffAddress] = useState(false);
  const [addressIsNotComplete, setAddressIsNotComplete] = useState(false);
  const [addressDropOffIsNotComplete, setAddressDropOffIsNotComplete] = useState(false);
  const [ignoreDistanceLimit, setIgnoreDistanceLimit] = useState(ignoreDistance);
  const [jobReachableState, setJobReachableState] = useState({
    mobile_technician: isMobileTechnician ? true : null,
    pickup: isPickUp ? true : null,
    dropoff: isDropOff ? true : null,
  });
  const [jobReachablePreviusState, setJobReachablePreviusState] = useState(null);
  const [lastSyncType, setLastSyncType] = useState(null);

  const [additionalInfo, setAdditionalInfo] = useState({
    dropOff: {
      address: customerDropoffAddress || customerPickupAddress,
      distanceLimitIgnored: ignoreDistance,
      addressData: customerDropoffAddressData || customerPickupAddressData,
      geolocation: dropOffGeolocation,
      zone: dropOffZone,
    },
    pickUp: {
      address: customerPickupAddress,
      addressData: customerPickupAddressData,
      distanceLimitIgnored: ignoreDistance,
      geolocation: pickUpGeolocation,
      zone,
    },
  });

  const isRemote = pickUp || mobileTechnician;

  const isDropOffReachable = () => {
    if (pickUp && droppOff) {
      return jobReachableState.dropoff || sameDroppOffAddress;
    }

    return null;
  };

  useEffect(() => {
    if (jobReachable !== null) {
      if (jobReachable) {
        setServiceLocation(additionalInfo);
      }

      if (lastSyncType) {
        setJobReachableState({
          ...jobReachableState,
          [lastSyncType]: lastSyncType !== "dropoff" ? jobReachable : jobReachableState[lastSyncType],
          dropoff: lastSyncType === "dropoff" ? jobDropOffReachable : jobReachableState.dropoff,
        });
      }
    }
  }, [jobReachable, jobDropOffReachable]);

  useEffect(() => {
    if (!pickUp && !mobileTechnician) {
      resetJobReachableData(null, "pickup");
      resetJobReachableData(null, "dropoff");
      setJobReachableState({
        mobile_technician: null,
        pickup: null,
        dropoff: null,
      });
    }

    if (
      teamServiceAdvisors.length > 0
      && isRemote
      && (jobReachable || ignoreDistanceLimit)
      && (!droppOff || (droppOff && isDropOffReachable()))
    ) {
      const advisors = teamServiceAdvisors.filter((advisor) => !advisor.is_phantom);

      if (advisors.length > 0) {
        makeAdvisorSelection(advisors[0]);
      }
    } else if (wasActive) {
      makeAdvisorSelection({});
    }
  }, [isRemote, teamServiceAdvisors, selectedAdvisor, jobReachable, jobDropOffReachable]);

  useEffect(() => {
    setSameDroppOffAddress(additionalInfo.pickUp.address === additionalInfo.dropOff.address);
    schedulingDropOff(droppOff);

    if (!droppOff) {
      setJobReachableState({
        ...jobReachableState,
        dropoff: null,
      });
    }
  }, [droppOff]);

  const onChangeHandle = useCallback(
    (updates, type) => {
      setAdditionalInfo({
        ...additionalInfo,
        ...updates,
      });

      if (type === "dropoff") {
        setAddressDropOffIsNotComplete(false);
      } else {
        setAddressIsNotComplete(false);
      }

      const addressData = updates.pickUp
        ? updates.pickUp.addressData
        : updates.dropOff.addressData;

      const geolocation = updates.pickUp
        ? updates.pickUp.geolocation
        : updates.dropOff.geolocation;

      if (addressData) {
        if (type === "dropoff") {
          setEmptyDropOffAddress(false);
        } else {
          setEmptyAddress(false);
        }

        setLastSyncType(type);

        const {
          address_line1,
          address_line2,
          address_city,
          address_state,
          address_zipcode,
        } = addressData;

        if (
          address_line1
          && address_line2
          && address_city
          && address_state
          && address_zipcode
        ) {
          if (firstTimeAddressChecked) {
            setIgnoreDistanceLimit(false);
          }

          checksJobDistance(addressData, geolocation, type);
          setFirstTimeAddressChecked(true);
        } else {
          if (type === "dropoff") {
            setAddressDropOffIsNotComplete(true);
          } else {
            setAddressIsNotComplete(true);
          }

          makeAdvisorSelection({});
        }
      } else {
        if (type === "dropoff") {
          setEmptyDropOffAddress(true);
          resetJobReachableData(false, "dropoff");
        } else {
          setEmptyAddress(true);
          resetJobReachableData(false, "pickup");
        }

        makeAdvisorSelection({});
      }
    },
    [additionalInfo, firstTimeAddressChecked],
  );

  useEffect(() => {
    resetJobReachableData(ignoreDistanceLimit, "pickup");
    resetJobReachableData(ignoreDistanceLimit, "dropoff");
    schedulingDistanceLimitIgnored(ignoreDistanceLimit);

    if (firstTimeAddressChecked) {
      resetJobReachableData(ignoreDistanceLimit);
      setAdditionalInfo({
        dropOff: {
          ...additionalInfo.dropOff,
          distanceLimitIgnored: ignoreDistanceLimit,
        },
        pickUp: {
          ...additionalInfo.pickUp,
          distanceLimitIgnored: ignoreDistanceLimit,
        },
      });

      if (ignoreDistanceLimit) {
        setJobReachablePreviusState({ ...jobReachableState });
        setJobReachableState({
          mobile_technician: ignoreDistanceLimit,
          pickup: ignoreDistanceLimit,
          dropoff: ignoreDistanceLimit,
        });
      } else {
        setJobReachablePreviusState(null);

        if (jobReachablePreviusState) {
          setJobReachableState({ ...jobReachablePreviusState });
        }
      }
    }
  }, [ignoreDistanceLimit]);

  useEffect(() => {
    if (sameDroppOffAddress) {
      setJobReachableState({
        ...jobReachableState,
        dropoff: jobReachableState.pickup,
      });
      setAdditionalInfo({
        ...additionalInfo,
        dropOff: additionalInfo.pickUp,
      });
    }
  }, [sameDroppOffAddress]);

  const updateType = (type) => {
    if (wasActive) {
      setWasActive(true);
    }

    if (type === "pickUp") {
      schedulingPickUp(!pickUp);
      setPickUp(!pickUp);
      setDroppOff(false);
      setMobileTechnician(false);
      schedulingMobileTechnician(false);
    } else {
      schedulingMobileTechnician(!mobileTechnician);
      setMobileTechnician(!mobileTechnician);
      setPickUp(false);
      setDroppOff(false);
      schedulingPickUp(false);
    }
  };

  if (isMobileTechnician && !(mobileTechnicianEnabled && allServicesIsMobile)) {
    return (
      <Alert
        variant="error"
        text="Selected services can't be performed by mobile service teams."
      />
    );
  }

  const isReachable = () => {
    if (mobileTechnician) {
      return jobReachableState.mobile_technician;
    }

    if (pickUp) {
      return jobReachableState.pickup;
    }

    return null;
  };

  const errorLabel = () => {
    let message = "Location outside of the maximum distance limit for ";

    if (mobileTechnician) {
      message += "Mobile Technician service";
    } else if (jobReachableState.pickup === false && jobReachableState.dropoff === false) {
      message += "vehicle Pick-up and Drop-off";
    } else {
      message += "vehicle Pick-up";
    }

    return message;
  };

  return (
    <div className={styles.chooseRemoteService}>
      <div className={styles.buttonsSection}>
        {mobileTechnicianEnabled && (
          <div className={styles.switchContainer}>
            <label
              className={styles.switchLabel}
              htmlFor="mobile-technician-switch"
            >
              Mobile Technician
            </label>
            <Switch
              id="mobile-technician-switch"
              className={styles.switch}
              onChange={() => updateType("mobileTechnician")}
              checked={mobileTechnician}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="mobile-technician-switch-label"
            />
          </div>
        )}
        {remoteJobsEnabled && (
          <div className={styles.switchContainer}>
            <label
              className={styles.switchLabel}
              htmlFor="pick-up-switch"
            >
              Pick-up
            </label>
            <Switch
              id="pick-up-switch"
              className={styles.switch}
              onChange={() => updateType("pickUp")}
              checked={pickUp}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="pick-up-switch-label"
            />
          </div>
        )}
        {pickUp && (
          <div className={styles.switchContainer}>
            <label
              className={styles.switchLabel}
              htmlFor="drop-off-switch"
            >
              Drop-off
            </label>
            <Switch
              id="drop-off-switch"
              className={styles.switch}
              onChange={() => setDroppOff(!droppOff)}
              checked={droppOff}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="drop-off-switch-label"
            />
          </div>
        )}
      </div>
      {addressIsNotComplete && (
        <Alert
          variant="error"
          text="The address is not complete"
        />
      )}
      {isReachable() !== null && emptyAddress && !addressIsNotComplete && (
        <Alert
          variant="error"
          text="Address should be present"
        />
      )}
      {!isJobReachableLoading
      && isReachable() !== null
      && !emptyAddress
      && !addressIsNotComplete && (
        <Alert
          variant={(
            (isReachable() || ignoreDistanceLimit)
          )
            ? "success"
            : "error"}
          text={
            (isReachable() || ignoreDistanceLimit)
              ? `Distance in limit for vehicle ${mobileTechnician
                ? `Mobile Technician (Service zone ${additionalInfo.pickUp.zone})`
                : `${droppOff && sameDroppOffAddress ? "Pick-up and Drop-off" : "Pick-up"} (Service zone ${additionalInfo.pickUp.zone})`
              }`
              : errorLabel()
          }
        />
      )}
      {/* Not combine LocationInput in one !!! */}
      {pickUp && (
        <LocationInput
          isPickUp
          isSameLocation={sameDroppOffAddress}
          additionalInfo={additionalInfo}
          onChange={(updates) => onChangeHandle(updates, "pickup")}
          dealershipPosition={dealershipPosition}
          segmentsNumber={segmentsNumber}
        />
      )}
      {droppOff && (
        <div className={styles.ignorSwitchContainer}>
          <label
            className={styles.switchLabel}
            htmlFor="same-drop-off-address-switch"
          >
            Same Drop-off address
          </label>
          <Switch
            id="same-drop-off-address-switch"
            className={styles.switch}
            onChange={() => setSameDroppOffAddress(!sameDroppOffAddress)}
            checked={sameDroppOffAddress}
            onColor="#36af5e"
            offColor="#dedee0"
            activeBoxShadow="0 0 2px 3px #0bcaf9"
            aria-labelledby="same-drop-off-address-switch-label"
          />
        </div>
      )}
      {droppOff
      && !sameDroppOffAddress
      && addressDropOffIsNotComplete
      && (
        <Alert
          variant="error"
          text="The Drop-off address is not complete"
        />
      )}
      {droppOff
      && !sameDroppOffAddress
      && isDropOffReachable() !== null
      && emptyDropOffAddress
      && !addressDropOffIsNotComplete
      && (
        <Alert
          variant="error"
          text="Drop-off address should be present"
        />
      )}
      {droppOff
      && !sameDroppOffAddress
      && !isJobReachableLoading
      && isDropOffReachable() !== null
      && !emptyDropOffAddress
      && !addressDropOffIsNotComplete && (
        <Alert
          variant={(
            (isDropOffReachable() || ignoreDistanceLimit)
          )
            ? "success"
            : "error"}
          text={
            (isDropOffReachable() || ignoreDistanceLimit)
              ? `Distance in limit for vehicle Drop-off (Service zone ${additionalInfo.dropOff.zone})`
              : "Location outside of the maximum distance limit for vehicle Drop-off"
          }
        />
      )}
      {droppOff && !sameDroppOffAddress && (
        <LocationInput
          isDropOff
          isSameLocation={sameDroppOffAddress}
          additionalInfo={additionalInfo}
          onChange={(updates) => onChangeHandle(updates, "dropoff")}
          dealershipPosition={dealershipPosition}
          segmentsNumber={segmentsNumber}
        />
      )}
      {mobileTechnician && (
        <LocationInput
          additionalInfo={additionalInfo}
          onChange={(updates) => onChangeHandle(updates, "mobile_technician")}
          dealershipPosition={dealershipPosition}
          segmentsNumber={segmentsMobileTechnicianNumber}
        />
      )}
      {isRemote
      && (isReachable() === false || isDropOffReachable() === false || ignoreDistanceLimit)
      && (!emptyAddress || !emptyDropOffAddress)
      && (!addressIsNotComplete || !addressDropOffIsNotComplete)
      && (
        <div className={styles.ignorSwitchContainer}>
          <label
            className={styles.switchLabel}
            htmlFor="ignore-distance-limit-switch"
          >
            Allow service outside of the maximum distance limit
          </label>
          <Switch
            id="ignore-distance-limit-switch"
            className={styles.switch}
            onChange={() => setIgnoreDistanceLimit(!ignoreDistanceLimit)}
            checked={ignoreDistanceLimit}
            onColor="#36af5e"
            offColor="#dedee0"
            activeBoxShadow="0 0 2px 3px #0bcaf9"
            aria-labelledby="ignore-distance-limit-switch-label"
          />
        </div>
      )}
      <div className={styles.submit}>
        <Button
          variant="brand"
          onClick={() => setSchedulingStep(
            isRemote
              ? BOOKING_STEP_TIMESLOT
              : BOOKING_STEP_ADVISOR,
          )}
        >
          Done
        </Button>
      </div>
      {isJobReachableLoading && (
        <div className={styles.spinner}>
          <ClipLoader size={60} color="#0bcaf9" />
        </div>
      )}
    </div>
  );
};

ChooseRemoteService.propTypes = {
  setSchedulingStep: func.isRequired,
  checksJobDistance: func.isRequired,
  resetJobReachableData: func.isRequired,
  schedulingPickUp: func.isRequired,
  schedulingDropOff: func.isRequired,
  schedulingMobileTechnician: func.isRequired,
  schedulingDistanceLimitIgnored: func.isRequired,
  setServiceLocation: func.isRequired,
  makeAdvisorSelection: func.isRequired,
  isJobReachableLoading: bool.isRequired,
  jobReachable: bool,
  jobDropOffReachable: bool,
  customerPickupAddress: string,
  customerPickupAddressData: objectOf(string),
  customerDropoffAddress: string,
  customerDropoffAddressData: objectOf(string),
  remoteJobsEnabled: bool.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  allServicesIsMobile: bool.isRequired,
  isPickUp: bool.isRequired,
  isDropOff: bool.isRequired,
  isMobileTechnician: bool.isRequired,
  teamServiceAdvisors: arrayOf(serviceAdvisorPropType),
  dealershipPosition: {
    lat: number,
    lng: number,
  }.isRequired,
  segmentsNumber: number.isRequired,
  ignoreDistance: bool,
  segmentsMobileTechnicianNumber: number.isRequired,
  zone: string,
  dropOffZone: string,
  pickUpGeolocation: {
    lat: number,
    lng: number,
  },
  dropOffGeolocation: {
    lat: number,
    lng: number,
  },
  selectedAdvisor: serviceAdvisorPropType,
};

ChooseRemoteService.defaultProps = {
  customerPickupAddress: "",
  customerPickupAddressData: null,
  customerDropoffAddress: "",
  customerDropoffAddressData: null,
  jobReachable: null,
  jobDropOffReachable: null,
  teamServiceAdvisors: [],
  ignoreDistance: false,
  zone: null,
  dropOffZone: null,
  pickUpGeolocation: null,
  dropOffGeolocation: null,
  selectedAdvisor: null,
};

export default ChooseRemoteService;
