import React from "react";
import { arrayOf, string } from "prop-types";

import { servicePropType } from "shared/prop-types";
import { estimatedServiceTimeForBooking, estimatedTotalServiceTimeForBooking, totalCostForBooking } from "shared/utils/common";
import serviceIconsMap from "shared/serviceIconsMap";
import PackageItems from "../../PackageItems";

import styles from "../styles.module.scss";

const SelectedServicesAndTotalCost = ({ selectedServices, bufferTime }) => (
  <>
    <div className={styles.header}>
      Services
    </div>
    <div className={styles.services}>
      {selectedServices.map((service) => {
        const isRecall = typeof (service.vin_specific) === "boolean" && service.kind !== "other";
        if (isRecall) {
          const kind = service.vin_specific ? "vinSpecificRecall" : "recall";
          return {
            ...service,
            kind,
          };
        }
        return service;
      }).map((service) => (
        <div
          className={styles.service}
          key={service.name}
        >
          <div className={styles.body}>
            <img
              src={(
                serviceIconsMap[service.kind]
              )}
              className={styles.icon}
              alt=""
            />
            <div>
              <span className={styles.name}>
                {service.name}
              </span>
              {service.package_items && !!service.package_items.length && (
                <PackageItems
                  packageItems={service.package_items}
                  extras={service.extras}
                  premium_extras={service.premium_extras}
                />
              )}
              <div className={styles.price}>
                <span className={styles.priceLabel}>
                  Price:
                </span>
                <span className={styles.priceValue}>
                  {`$${Number(service.price || service.fee)}`}
                </span>
              </div>
            </div>
          </div>
          <div>
            {service.decisionTreeResult
              && Object.entries(service.decisionTreeResult).map(
                ([key, value]) => (
                  <section className={styles.additionalInfoRow}>
                    <span className={styles.additionalInfoKey}>
                      {key}
                    </span>
                    <span className={styles.additionalInfoValue}>
                      {value}
                    </span>
                  </section>
                ),
              )}
            {service.additionalInfo && (
              <div>
                <section className={styles.additionalInfoRow}>
                  <span className={styles.additionalInfoKey}>
                    Customer states:
                  </span>
                  <span className={styles.additionalInfoValue}>
                    {service.additionalInfo}
                  </span>
                </section>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
    <div className={styles.header}>
      Total
    </div>
    <div className={styles.totalSection}>
      <div className={styles.total}>
        {`$${totalCostForBooking(selectedServices)}`}
      </div>
    </div>
    <div className={styles.estimated}>
      <div className={styles.item}>
        <span className={styles.label}>Estimated service time: </span>
        <span className={styles.value}>
          {`${estimatedTotalServiceTimeForBooking(
            selectedServices,
            bufferTime,
          )} hours + upsell time`}
        </span>
      </div>
      {bufferTime !== "0.0" && (
        <div className={styles.container}>
          <span className={styles.value}>
            {`${estimatedServiceTimeForBooking(selectedServices)} hours estimated time`}
          </span>
          <span className={styles.value}>
            {`${bufferTime} hours estimated buffer time`}
          </span>
        </div>
      )}
    </div>
  </>
);

SelectedServicesAndTotalCost.propTypes = {
  selectedServices: arrayOf(servicePropType),
  bufferTime: string.isRequired,
};

SelectedServicesAndTotalCost.defaultProps = {
  selectedServices: [],
};

export default SelectedServicesAndTotalCost;
