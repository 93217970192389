import { connect } from "react-redux";

import {
  dealershipIdSelector,
} from "store/selectors/app-selectors";
import {
  clearTimeSlots,
  fetchAdvisors,
  setChosenTimeSlot,
  setCurrentStep,
  setServiceAdvisor,
} from "store/actions/scheduling-process-actions";
import {
  schedulingSelectedRecallsSelector,
  schedulingSelectedServicesSelector,
} from "store/selectors/scheduling-process-selectors";
import {
  settingsTeamServiceAdvisorsSelector,
} from "store/selectors/settings-selectors";

import ChooseAdvisor from "./index";

const mapStateToProps = (state) => {
  return {
    selectedRecalls: schedulingSelectedRecallsSelector(state),
    selectedServices: schedulingSelectedServicesSelector(state),
    teamServiceAdvisors: settingsTeamServiceAdvisorsSelector(state),
    dealershipId: dealershipIdSelector(state),
  };
};

const actions = {
  fetchTeamAdvisorsForServices: fetchAdvisors,
  setSchedulingStep: setCurrentStep,
  makeAdvisorSelection: setServiceAdvisor,
  setChosenQuarterSlot: setChosenTimeSlot,
  clearSlots: clearTimeSlots,
};

export default connect(mapStateToProps, actions)(ChooseAdvisor);
