import {
  createDecisionTree,
  createMenuItem,
  createService,
  createVehicleGroup,
  deleteDecisionTree,
  deleteVehicleGroup,
  destroyMenuItem,
  fetchMenuTemplate,
  fetchOpCodes,
  fetchServices,
  fetchVehicleSets,
  importOpCodesForDealership,
  reorderMenuItems,
  updateDecisionTree,
  updateMenuItem,
  updateMenuItemAdditionalSettings,
  updateMenuItemAvailable,
  updateMenuItemCutOff,
  updateMenuItemDefaultForMobileRO,
  updateMenuItemExtras,
  updateMenuItemPremiumExtras,
  updateMenuItemPublic,
  updateMenuItemTeamTags,
  updateService,
  updateVehicleGroup,
} from "shared/api";
import { authTokenSelector } from "store/selectors/auth-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";

export const MENU_TEMPLATE_FETCH_SERVICES_SUCCESS = "MENU_TEMPLATE_FETCH_SERVICES_SUCCESS";
export const MENU_TEMPLATE_FETCH_VEHICLE_SETS_SUCCESS = "MENU_TEMPLATE_FETCH_VEHICLE_SETS_SUCCESS";
export const MENU_TEMPLATE_UPDATE_VEHICLE_GROUP_SUCCESS = "MENU_TEMPLATE_UPDATE_VEHICLE_GROUP_SUCCESS";
export const MENU_TEMPLATE_CREATE_VEHICLE_GROUP_SUCCESS = "MENU_TEMPLATE_CREATE_VEHICLE_GROUP_SUCCESS";
export const MENU_TEMPLATE_DELETE_VEHICLE_GROUP_SUCCESS = "MENU_TEMPLATE_DELETE_VEHICLE_GROUP_SUCCESS";
export const MENU_TEMPLATE_ADD_NEW_VEHICLE_GROUP = "MENU_TEMPLATE_ADD_NEW_VEHICLE_GROUP";
export const MENU_TEMPLATE_UPDATE_DECISION_TREE_SUCCESS = "MENU_TEMPLATE_UPDATE_DECISION_TREE_SUCCESS";
export const MENU_TEMPLATE_CREATE_DECISION_TREE_SUCCESS = "MENU_TEMPLATE_CREATE_DECISION_TREE_SUCCESS";
export const MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER = "MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER";
export const MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS = "MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS";
export const MENU_TEMPLATE_UPDATE_SERVICE_SUCCESS = "MENU_TEMPLATE_UPDATE_SERVICE_SUCCESS";
export const MENU_TEMPLATE_FETCH_OP_CODES_SUCCESS = "MENU_TEMPLATE_FETCH_OP_CODES_SUCCESS";
export const MENU_TEMPLATE_ADD_SERVICE_SUCCESS = "MENU_TEMPLATE_ADD_SERVICE_SUCCESS";
export const MENU_TEMPLATE_ADD_MENU_ITEM_SUCCESS = "MENU_TEMPLATE_ADD_MENU_ITEM_SUCCESS";
export const MENU_TEMPLATE_DELETE_MENU_ITEM_SUCCESS = "MENU_TEMPLATE_DELETE_MENU_ITEM_SUCCESS";
export const MENU_TEMPLATE_REORDER_ITEMS_SUCCESS = "MENU_TEMPLATE_REORDER_ITEMS_SUCCESS";
export const MENU_TEMPLATE_SET_VEHICLE_GROUPS_CLIPBOARD = "MENU_TEMPLATE_SET_VEHICLE_GROUPS_CLIPBOARD";
export const MENU_TEMPLATE_SET_EXTRAS_CLIPBOARD = "MENU_TEMPLATE_SET_EXTRAS_CLIPBOARD";
export const MENU_TEMPLATE_FETCH_SUCCESS = "MENU_TEMPLATE_FETCH_SUCCESS";
export const MENU_TEMPLATE_SET_PREMIUM_EXTRAS_CLIPBOARD = "MENU_TEMPLATE_SET_PREMIUM_EXTRAS_CLIPBOARD";
export const MENU_TEMPLATE_IMPORT_OP_CODES = "MENU_TEMPLATE_IMPORT_OPCODES";

export const getServices = () => async (dispatch, getState) => {
  try {
    const services = await fetchServices(
      dealershipIdSelector(getState()),
      authTokenSelector(getState()),
    );
    dispatch({
      type: MENU_TEMPLATE_FETCH_SERVICES_SUCCESS,
      payload: { services },
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const getVehicleSets = (params) => async (dispatch) => {
  try {
    const vehicleSets = await fetchVehicleSets(params);
    dispatch({
      type: MENU_TEMPLATE_FETCH_VEHICLE_SETS_SUCCESS,
      payload: { vehicleSets },
    });
    return vehicleSets;
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const saveVehicleGroup = (menuItemId, vehicleGroup) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    if (vehicleGroup.id) {
      const updatedVehicleGroup = await updateVehicleGroup(
        dealershipId,
        vehicleGroup,
        authTokenSelector(getState()),
      );
      dispatch({
        type: MENU_TEMPLATE_UPDATE_VEHICLE_GROUP_SUCCESS,
        payload: updatedVehicleGroup,
      });
    } else {
      const createdVehicleGroup = await createVehicleGroup(
        dealershipId,
        menuItemId,
        vehicleGroup,
        authTokenSelector(getState()),
      );
      dispatch({
        type: MENU_TEMPLATE_CREATE_VEHICLE_GROUP_SUCCESS,
        payload: createdVehicleGroup,
      });
    }
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error: { message: error.response.data.error } },
    });
  }
};

export const removeVehicleGroup = (menuItemKind, menuItemId, vehicleGroupId) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    await deleteVehicleGroup(
      dealershipId,
      vehicleGroupId,
      authTokenSelector(getState()),
    );
    dispatch({
      type: MENU_TEMPLATE_DELETE_VEHICLE_GROUP_SUCCESS,
      payload: {
        menuItemKind,
        menuItemId,
        vehicleGroupId,
      },
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error: { message: error.response.data.error } },
    });
  }
};

export const addNewVehicleGroup = (menuItemId, kind) => {
  return {
    type: MENU_TEMPLATE_ADD_NEW_VEHICLE_GROUP,
    payload: {
      menuItemId,
      kind,
    },
  };
};

export const saveDecisionTree = (decisionTree) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    if (decisionTree.id) {
      const updatedDecisionTree = await updateDecisionTree(
        dealershipId,
        decisionTree,
        authTokenSelector(getState()),
      );
      dispatch({
        type: MENU_TEMPLATE_UPDATE_DECISION_TREE_SUCCESS,
        payload: updatedDecisionTree,
      });
    } else {
      const createdDecisionTree = await createDecisionTree(
        dealershipId,
        decisionTree,
        authTokenSelector(getState()),
      );
      dispatch({
        type: MENU_TEMPLATE_CREATE_DECISION_TREE_SUCCESS,
        payload: createdDecisionTree,
      });
    }
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const removeDecisionTree = (decisionTree) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    if (decisionTree.id) {
      const updatedDecisionTree = await deleteDecisionTree(
        dealershipId,
        decisionTree,
        authTokenSelector(getState()),
      );
      dispatch({
        type: MENU_TEMPLATE_UPDATE_DECISION_TREE_SUCCESS,
        payload: {
          ...updatedDecisionTree,
          menu_item: {
            ...updatedDecisionTree.menu_item,
            decision_tree: null,
          },
          intro: {},
          steps: [],
        },
      });
    }
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const toggleMenuItemDefaultMobileRO = (menuItemId, isDefaultMobileRO) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    const updatedMenuItem = await updateMenuItemDefaultForMobileRO(
      dealershipId,
      menuItemId,
      isDefaultMobileRO,
      authTokenSelector(getState()),
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
      payload: updatedMenuItem,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const toggleMenuItemPublic = (menuItemId, isDefaultPublic) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    const updatedMenuItem = await updateMenuItemPublic(
      dealershipId,
      menuItemId,
      isDefaultPublic,
      authTokenSelector(getState()),
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
      payload: updatedMenuItem,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const saveMenuItemCutOff = (menuItemId, cutoff_time) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    const updatedMenuItem = await updateMenuItemCutOff(
      dealershipId,
      menuItemId,
      cutoff_time,
      authTokenSelector(getState()),
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
      payload: updatedMenuItem,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const toggleMenuItemAvailableOnWeekends = (menuItemId, isDefaultAvailable) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    const updatedMenuItem = await updateMenuItemAvailable(
      dealershipId,
      menuItemId,
      isDefaultAvailable,
      authTokenSelector(getState()),
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
      payload: updatedMenuItem,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const saveMenuItemTeamTags = (menuItemId, teamTags) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());
  try {
    const updatedMenuItem = await updateMenuItemTeamTags(
      dealershipId,
      menuItemId,
      teamTags,
      authTokenSelector(getState()),
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
      payload: updatedMenuItem,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const savePackageItems = (menuItemId, packageItems) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    const updatedMenuItem = await updateMenuItem(
      dealershipId,
      menuItemId,
      packageItems.map((pi) => pi.id),
      authTokenSelector(getState()),
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
      payload: updatedMenuItem,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const saveMenuItemAdditionalSettings = (menuItemId, params) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  try {
    const updatedMenuItem = await updateMenuItemAdditionalSettings(
      dealershipId,
      menuItemId,
      params,
      token,
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
      payload: updatedMenuItem,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const updateExtras = (menuItemId, extras) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());

  try {
    const updatedMenuItemExtras = await updateMenuItemExtras(
      dealershipId,
      menuItemId,
      extras,
      authToken,
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
      payload: updatedMenuItemExtras,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const updatePremiumExtras = (menuItemId, premium_extras) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());

  try {
    const updatedMenuItemPremiumExtras = await updateMenuItemPremiumExtras(
      dealershipId,
      menuItemId,
      premium_extras,
      authToken,
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_MENU_ITEM_SUCCESS,
      payload: updatedMenuItemPremiumExtras,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const editService = (params) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());

  try {
    await updateService(
      dealershipId,
      authToken,
      params.id,
      params.name,
      params.opCodeId,
    );
    dispatch({
      type: MENU_TEMPLATE_UPDATE_SERVICE_SUCCESS,
      payload: params,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const getOpCodes = (params) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());

  try {
    const opCodes = await fetchOpCodes(dealershipId, params, authToken);
    dispatch({
      type: MENU_TEMPLATE_FETCH_OP_CODES_SUCCESS,
      payload: opCodes,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

const addService = async (dispatch, { dealershipId, service, token }) => {
  const newService = await createService(dealershipId, service, token);
  dispatch({
    type: MENU_TEMPLATE_ADD_SERVICE_SUCCESS,
    payload: newService,
  });
  return newService;
};

const duplicateMenuItemData = async (
  dispatch,
  {
    dealershipId, menuItemId, duplicate, token,
  },
) => {
  if (!duplicate) {
    return;
  }

  const newVehicleGroups = await Promise.all(
    duplicate.menu_item_targets.map((vehicleGroup) => (
      createVehicleGroup(dealershipId, menuItemId, vehicleGroup, token)
    )),
  );
  newVehicleGroups.forEach((vehicleGroup) => dispatch({
    type: MENU_TEMPLATE_CREATE_VEHICLE_GROUP_SUCCESS,
    payload: vehicleGroup,
  }));

  if (duplicate.decision_tree) {
    const decisionTree = await createDecisionTree(
      dealershipId,
      {
        menuItemId,
        ...duplicate.decision_tree,
      },
      token,
    );
    dispatch({
      type: MENU_TEMPLATE_CREATE_DECISION_TREE_SUCCESS,
      payload: decisionTree,
    });
  }
};

export const addMenuItem = ({ menuTemplateId, service, duplicate }) => async (
  dispatch,
  getState,
) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  try {
    const serviceId = service.id
      || (
        await addService(dispatch, {
          dealershipId,
          service,
          token,
        })
      ).id;

    const menuItem = await createMenuItem(
      dealershipId,
      {
        menuTemplateId,
        serviceId,
        extras: duplicate && duplicate.extras,
        premium_extras: duplicate && duplicate.premium_extras,
        packageItems:
          duplicate && duplicate.package_items.map((item) => item.id),
      },
      token,
    );
    dispatch({
      type: MENU_TEMPLATE_ADD_MENU_ITEM_SUCCESS,
      payload: {
        menuTemplateId,
        menuItem,
      },
    });

    await duplicateMenuItemData(dispatch, {
      dealershipId,
      menuItemId: menuItem.id,
      duplicate,
      token,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const deleteMenuItem = (menuTemplateId, id) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());

  try {
    await destroyMenuItem(dealershipId, id, authTokenSelector(getState()));
    dispatch({
      type: MENU_TEMPLATE_DELETE_MENU_ITEM_SUCCESS,
      payload: {
        menuTemplateId,
        id,
      },
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const reorderTemplateItems = (params) => async (dispatch, getState) => {
  const token = authTokenSelector(getState());

  try {
    await reorderMenuItems(params, token);
    dispatch({
      type: MENU_TEMPLATE_REORDER_ITEMS_SUCCESS,
      payload: { ...params },
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const setVehicleGroupsClipboard = (value) => {
  return {
    type: MENU_TEMPLATE_SET_VEHICLE_GROUPS_CLIPBOARD,
    payload: { value },
  };
};

export const setExtrasClipboard = (value) => {
  return {
    type: MENU_TEMPLATE_SET_EXTRAS_CLIPBOARD,
    payload: { value },
  };
};

export const setPremiumExtrasClipboard = (value) => {
  return {
    type: MENU_TEMPLATE_SET_PREMIUM_EXTRAS_CLIPBOARD,
    payload: { value },
  };
};

export const fetchTemplate = (id) => async (dispatch, getState) => {
  try {
    const dealershipId = dealershipIdSelector(getState());
    const token = authTokenSelector(getState());
    const menuTemplate = await fetchMenuTemplate(dealershipId, token, id);

    dispatch({
      type: MENU_TEMPLATE_FETCH_SUCCESS,
      payload: menuTemplate,
    });
  } catch (error) {
    dispatch({
      type: MENU_TEMPLATE_DEFAULT_SERVICES_ERROR_HANDLER,
      payload: { error },
    });
  }
};

export const importOpCodes = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealership_id = Number(dealershipIdSelector(getState()));

  importOpCodesForDealership(dealership_id, token);

  dispatch({ type: MENU_TEMPLATE_IMPORT_OP_CODES });
};
