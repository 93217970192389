import React from "react";

import ServiceAdvisorIcon from "assets/images/bookings/service_advisor.svg";
import JobConfirmedIcon from "assets/images/bookings/driverActivity/done.svg";
import DriverAssignedIcon from "assets/images/driverAssignedGreen.svg";
import EnRouteToCustomerIcon from "assets/images/departure.svg";
import ArrivedToLocationIcon from "assets/images/position.svg";
import EnRouteToDealershipIcon from "assets/images/arrive.svg";
import ArrivedIcon from "assets/images/bookings/status/arrived.svg";
import CheckedInIcon from "assets/images/bookings/status/checked-in.svg";
import RecallIcon from "assets/images/bookings/recall_icon.svg";
import CoffeeIcon from "assets/images/bookings/coffee.svg";
import CarIcon from "assets/images/bookings/car.svg";
import LabelIcon from "assets/images/bookings/label.svg";
import PickupIcon from "assets/images/bookings/pickup.svg";
import AppointmentsIcon from "assets/images/bookings/appointments.svg";
import AlternativeTransportIcon from "assets/images/bookings/transport.svg";
import LyftIcon from "assets/images/bookings/alternativeTransport/lyft.png";
import LoanerIcon from "assets/images/bookings/alternativeTransport/loaner.svg";
import UberIcon from "assets/images/bookings/alternativeTransport/uber.png";
import ShuttleIcon from "assets/images/bookings/alternativeTransport/shuttle1.png";

export const toFilterOption = (item, icon) => {
  return {
    key: item.id,
    icon,
    caption: item.name,
  };
};

export const filtersConfig = [
  {
    key: "service_advisor",
    icon: <ServiceAdvisorIcon />,
    options: [],
  },
  {
    key: "jobStatus",
    icon: <PickupIcon />,
    options: [
      {
        key: "assigned",
        icon: <DriverAssignedIcon />,
        caption: "Driver assigned",
        assetsClassName: "driverAssignedFilter",
      },
      {
        key: "en_route_to_customer",
        icon: <EnRouteToCustomerIcon />,
        caption: "En route to customer ",
        assetsClassName: "enRouteToCustomerFilter",
      },
      {
        key: "arrived_to_customer",
        icon: <ArrivedToLocationIcon />,
        caption: "Arrived to customer ",
        assetsClassName: "arrivedToCustomerFilter",
      },
      {
        key: "en_route_to_dealership",
        icon: <EnRouteToDealershipIcon />,
        caption: "En route to dealership ",
        assetsClassName: "enRouteToDealershipFilter",
      },
    ],
  },
  {
    key: "bookingStatus",
    icon: <JobConfirmedIcon />,
    options: [
      {
        key: "not_checked_in",
        icon: <JobConfirmedIcon />,
        caption: "Not checked-in yet",
      },
      {
        key: "checked_in",
        icon: <CheckedInIcon />,
        caption: "Checked-in online",
      },
      {
        key: "arrived",
        icon: <ArrivedIcon />,
        caption: "Arrived on site",
      },
    ],
  },
  {
    key: "transportationStatus",
    icon: <AlternativeTransportIcon />,
    options: [
      {
        key: "loaner",
        icon: <LoanerIcon />,
        caption: "Loaner",
      },
      {
        key: "lyft",
        icon: <LyftIcon />,
        caption: "Lyft",
      },
      {
        key: "uber",
        icon: <UberIcon />,
        caption: "Uber",
      },
      {
        key: "shuttle",
        icon: <ShuttleIcon />,
        caption: "Shuttle",
      },
    ],
  },
  {
    key: "client_waiting",
    icon: <CoffeeIcon />,
  },
  {
    key: "with_concern",
    icon: <CarIcon />,
  },
  {
    key: "appraisal_requested",
    icon: <LabelIcon />,
  },
  {
    key: "with_recall",
    icon: <RecallIcon />,
  },
  {
    key: "appointmentStatus",
    icon: <AppointmentsIcon />,
    options: [
      {
        key: "open",
        caption: "Open",
      },
      {
        key: "arrived",
        caption: "Arrived",
      },
      {
        key: "canceled",
        caption: "Canceled",
      },
      {
        key: "not_shown",
        caption: "Not Shown",
      },
    ],
  },
];
