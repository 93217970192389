import { TRANSPORT_INTEGRATIONS } from "shared/constants";

export const settingsLoadingStateSelector = (state) => state.settings.isLoading;
export const settingsDataSelector = (state) => state.settings.data;
export const settingsCapacityTimeBufferSelector = (state) => (
  state.settings.data.capacity_time_buffer
);
export const settingsDrivingTimeBufferSelector = (state) => (
  state.settings.data.driving_time_buffer
);

export const settingsEmploymentsSelector = (state) => (
  state.settings.data.employments.map((employment) => {
    return {
      ...employment,
      isPhantom: employment?.service_advisor_profile?.is_phantom || false,
    };
  })
);
export const settingsTeamTagsLoadingStateSelector = (state) => state.settings.isTeamTagsLoading;
export const settingsTeamTagsSelector = (state) => state.settings.teamTags;
export const settingsTextMessagesSelector = (state) => state.settings.textMessages;
export const settingsNonWorkingDaysSelector = (state) => state.settings.nonWorkingDays;
export const settingsNonWorkingDaysLoadingSelector = (state) => (
  state.settings.isNonWorkingDaysLoading
);
export const settingsTeamServiceAdvisorsSelector = (state) => state.settings.teamServiceAdvisors;
export const settingsKiosksSelector = (state) => state.settings.data.kiosks;
export const settingsTvScreensSelector = (state) => state.settings.data.tv_screens;
export const settingsArriveUsersSelector = (state) => state.settings.data.arrive_users;
export const settingsRemoteUsersSelector = (state) => state.settings.data.remote_users;
export const settingsTimezoneSelector = (state) => state.settings.data.time_zone;
export const settingsErrorSelector = (state) => state.settings.error;
export const dmsTypeSelector = (state) => state.settings.data.dms_type;
export const serviceAdvisorsSelector = (state) => state.settings.serviceAdvisors;
export const activeAdvisorsSelector = (state) => state.settings.activeAdvisors;
export const phantomAdvisorAvailableSelector = (state) => (
  state.settings.data.phantom_advisor_enabled
);
export const totalAdvisorsSelector = (state) => state.settings.serviceAdvisorsTotal;
export const settingsDealershipWorkingHoursSelector = (state) => state.settings.data.working_hours;
export const settingsIntegrationsSelector = (state) => state.settings.data.integrations;
export const settingsAvailableTransportsSelector = (state) => state.settings.data.integrations
  .map((i) => i.kind)
  .filter((i) => TRANSPORT_INTEGRATIONS.includes(i));
export const settingsMobileTechnicianIntegrationMaxDistanceSelector = (state) => {
  if (state.settings.data.integrations) {
    return state.settings.data.integrations.find(
      (integration) => integration.kind === "mobile_technician",
    )?.settings?.max_distance || 0;
  }
  return 0;
};
export const settingsMobileTechnicianIntegrationSegmentsNumberSelector = (state) => {
  if (state.settings.data.integrations) {
    return state.settings.data.integrations.find(
      (integration) => integration.kind === "mobile_technician",
    )?.settings?.segments_number || 8;
  }
  return 8;
};
export const settingsPickupIntegrationMaxDistanceSelector = (state) => {
  if (state.settings.data.integrations) {
    return state.settings.data.integrations.find(
      (integration) => integration.kind === "pickup",
    )?.settings?.max_distance || 0;
  }
  return 0;
};
export const settingsPickupIntegrationSegmentsNumberSelector = (state) => {
  if (state.settings.data.integrations) {
    return state.settings.data.integrations.find(
      (integration) => integration.kind === "pickup",
    )?.settings?.segments_number || 8;
  }
  return 8;
};
export const settingsDriversSelector = (state) => state.settings.drivers;
export const totalDriversSelector = (state) => state.settings.driversTotal;
export const resyncDriversSelector = (state) => state.settings.resyncDrivers;
export const settingsDispatchCodesSelector = (state) => state.settings.data.dispatch_codes;
export const settingsLaborTypesSelector = (state) => state.settings.data.labor_types;
export const settingsPrintersSelector = (state) => state.settings.data.printers;
export const settingsDefaultMakeSelector = (state) => (
  state.settings.data?.additional_settings?.default_make
);
export const settingsIsAutoGenerateROSelector = (state) => (
  state.settings.data?.repair_order_generation_on_arrival
);
export const settingsVacationError = (state) => state.settings.vacationError;
export const settingsEmployeeVacations = (state) => state.settings.employeeVacations;
export const settingsDriverVacations = (state) => state.settings.driverVacations;
export const settingsMobileTechniciansVacations = (state) => (
  state.settings.mobileTechniciansVacations
);
export const settingsDealershipLogoSelector = (state) => state.settings.data?.logo?.url;

export const settingsDealershipPublicIdSelector = (state) => state.settings.data.public_id;
export const settingsDealershipNameSelector = (state) => state.settings.data.name;
export const settingsDealershipRemoteJobsEnabledSelector = (state) => (
  state.settings.data.remote_enabled
);
export const settingsDealershipMobileTechnicianEnabledSelector = (state) => (
  state.settings.data.mobile_technician_enabled
);
export const settingsDealershipPickUpEnabledSelector = (state) => {
  if (state.settings.data.integrations) {
    return state.settings.data.integrations.find(
      (integration) => integration.kind === "pickup",
    )?.active || false;
  }
  return false;
};

export const mobileTechniciansSelector = (state) => state.settings.mobileTechnicians;
export const totalMobileTechniciansSelector = (state) => state.settings.mobileTechniciansTotal;

export const settingsDealershipLatitudeLongitudeSelector = (state) => {
  return {
    lat: state.settings.data.latitude,
    lng: state.settings.data.longitude,
  };
};

export const settingsDealershipShowCoDriver = () => false;

export const settingsPublicAlertSelector = (state) => (
  state.settings.data.public_alert
);

export const settingsResponsibleTeamTagSelector = (state) => (
  state.settings.responsibleTeamTag
);

export const settingsEmployeeErrorSelector = (state) => (
  state.settings.employeeError
);

export const settingsAdvisorsLoadingSelector = (state) => (
  state.settings.isAdvisorsLoading
);

export const settingsDriversLoadingSelector = (state) => (
  state.settings.isDriversLoading
);

export const settingsMobileTechniciansLoadingSelector = (state) => (
  state.settings.isMobileTechniciansLoading
);
