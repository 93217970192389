import React, { useState } from "react";
import { arrayOf, func, string } from "prop-types";
import { connect } from "react-redux";

import { menuItemPropType, menuTemplatePropType } from "shared/prop-types";
import {
  addNewVehicleGroup,
  removeDecisionTree,
  saveMenuItemCutOff,
  saveMenuItemTeamTags,
  toggleMenuItemAvailableOnWeekends,
  toggleMenuItemDefaultMobileRO,
} from "store/actions/menu-template-actions";
import { settingsTeamTagsSelector } from "store/selectors/settings-selectors";

import MultiTagSelect from "components/common/MultiTagSelect";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import AdditionalSettings from "./AdditionalSettings";
import VehicleGroup from "./VehicleGroup";
import DecisionTree from "./DecisionTree";
import PackageItem from "./PackageItem";
import Extras from "./Extras";

import "./styles.scss";

const MenuItemDetails = ({
  menuItem,
  menuTemplate,
  teamTags,
  onAddNew,
  onUpdateMenuItemTeamTags,
  onMenuItemCutOffSave,
  updateMenuItemDefaultForMobileRO,
  onToggleAvailable,
  deleteDecisionTree,
}) => {
  const [showDecisionTree, setShowDecisionTree] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const isMobileMenuItem = () => (
    menuItem.team_tags
      ? menuItem.team_tags.filter((team) => team.mobile === true).length > 0
      : false
  );

  return (
    <>
      {deleteModalOpen && (
        <Modal
          title="Wait. Are you sure?"
          text="Are you sure you want to delete this decision tree?"
          cancelButtonText="No"
          submitButtonText="Yes"
          submitButtonVariant="destructive"
          cancelButtonVariant="dark"
          size="small"
          onSubmit={() => {
            if (menuItem?.decision_tree?.id) {
              deleteDecisionTree(menuItem.decision_tree);
            }

            setShowDecisionTree(false);
            setDeleteModalOpen(false);
          }}
          onCancel={() => setDeleteModalOpen(false)}
        />
      )}
      {menuItem.kind === "concern" && (
        <section className="menuItemDetailsSection">
          <div className="menuItemDetailsSectionHeader">
            <span className="menuItemDetailsSectionLabel">Decision tree</span>
            {!menuItem.decision_tree && !showDecisionTree && (
              <Button
                variant="brand"
                onClick={() => setShowDecisionTree(true)}
              >
                Add decision tree
              </Button>
            )}
            {(menuItem.decision_tree || showDecisionTree) && (
              <Button
                variant="destructive"
                onClick={() => {
                  setDeleteModalOpen(true);
                }}
              >
                Delete decision tree
              </Button>
            )}
          </div>
          {(menuItem.decision_tree || showDecisionTree) && (
            <DecisionTree
              menuItemId={menuItem.id}
              decisionTree={menuItem.decision_tree}
            />
          )}
        </section>
      )}
      <section className="menuItemDetailsSection">
        <div className="menuItemDetailsSectionHeader">
          <span className="menuItemDetailsSectionLabel">Teams</span>
        </div>
        <MultiTagSelect
          options={teamTags}
          value={menuItem.team_tags}
          onChange={(selected) => {
            onUpdateMenuItemTeamTags(menuItem.id, selected.map(({ id }) => id));

            if (!selected.map((team) => team.mobile).includes(true)) {
              updateMenuItemDefaultForMobileRO(menuItem.id, false);
            }
          }}
        />
      </section>
      <section className="menuItemDetailsSection">
        <div className="menuItemDetailsSectionHeader">
          <span className="menuItemDetailsSectionLabel">Vehicle groups</span>
          <Button
            variant="brand"
            onClick={() => onAddNew(menuItem.id, menuItem.kind)}
          >
            <p>Add new group</p>
          </Button>
        </div>
        {menuItem.menu_item_targets.map((vehicleGroup) => (
          <VehicleGroup
            key={vehicleGroup.id}
            menuItemId={menuItem.id}
            menuItemKind={menuItem.kind}
            menuItemIsMobile={isMobileMenuItem()}
            forDefault={menuItem.default}
            vehicleGroup={vehicleGroup}
            hasPackageItems={menuItem.package_items.length > 0}
          />
        ))}
      </section>
      <section className="menuItemDetailsSection">
        {menuItem.kind === "maintenance" && (
          <>
            <div className="menuItemDetailsSectionHeader">
              <span className="menuItemDetailsSectionLabel">Package items</span>
            </div>
            <PackageItem
              key={menuItem.id}
              menuItem={menuItem}
              menuTemplate={menuTemplate}
            />
          </>
        )}
        {(menuItem.kind === "maintenance" && menuItem.package_items.length > 0) && (
          <div>
            <div className="menuItemDetailsSectionHeader">
              <span className="menuItemDetailsSectionLabel">Extras</span>
            </div>
            <div className="menuItemDetailsExtras">
              <div className="menuItemDetailsExtrasBlock">
                <Extras
                  menuItemId={menuItem.id}
                  currentExtras={menuItem.extras}
                  enabled={menuItem.package_items.length > 0}
                />
              </div>
              <div className="menuItemDetailsExtrasBlock">
                <Extras
                  isPremium
                  menuItemId={menuItem.id}
                  currentExtras={menuItem.premium_extras}
                  enabled={menuItem.package_items.length > 0}
                />
              </div>
            </div>
          </div>
        )}
        {(menuItem.kind === "concern" && menuItem.package_items.length > 0) && (
          <div>
            <div className="menuItemDetailsSectionHeader">
              <span className="menuItemDetailsSectionLabel">Extras</span>
            </div>
            <div className="menuItemDetailsExtras">
              <div className="menuItemDetailsExtrasBlock">
                <Extras
                  menuItemId={menuItem.id}
                  currentExtras={menuItem.extras}
                  enabled={menuItem.package_items.length > 0}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      {(menuItem.kind === "concern" || menuItem.kind === "maintenance") && (
        <section className="menuItemDetailsSection">
          <div className="menuItemDetailsSectionHeader">
            <span className="menuItemDetailsSectionLabel">Additional settings</span>
          </div>
          <AdditionalSettings
            menuItem={menuItem}
            onMenuItemCutOffSave={onMenuItemCutOffSave}
            onToggleAvailable={onToggleAvailable}
          />
        </section>
      )}
    </>
  );
};

MenuItemDetails.propTypes = {
  menuItem: menuItemPropType.isRequired,
  menuTemplate: menuTemplatePropType.isRequired,
  onAddNew: func.isRequired,
  teamTags: arrayOf(string),
  onUpdateMenuItemTeamTags: func,
  onMenuItemCutOffSave: menuItemPropType.isRequired,
  onToggleAvailable: menuItemPropType.isRequired,
  deleteDecisionTree: func.isRequired,
  updateMenuItemDefaultForMobileRO: func.isRequired,
};

MenuItemDetails.defaultProps = {
  onUpdateMenuItemTeamTags: () => {},
  teamTags: [],
};

const mapStateToProps = (state) => {
  return {
    teamTags: settingsTeamTagsSelector(state),
  };
};

const actions = {
  onAddNew: addNewVehicleGroup,
  onUpdateMenuItemTeamTags: saveMenuItemTeamTags,
  onMenuItemCutOffSave: saveMenuItemCutOff,
  onToggleAvailable: toggleMenuItemAvailableOnWeekends,
  deleteDecisionTree: removeDecisionTree,
  updateMenuItemDefaultForMobileRO: toggleMenuItemDefaultMobileRO,
};

const MenuItemDetailsContainer = connect(mapStateToProps, actions)(MenuItemDetails);

export default MenuItemDetailsContainer;
