import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import {
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types";
import { driverPropType, mobileTechnicianPropType } from "shared/prop-types";
import {
  fetchDriverJobs,
  retrieveDealershipDrivers,
  retrieveDealershipMobileTechnicians,
  updateDriverInfo,
  updateMobileTechnician,
} from "store/actions/remote-service-actions";
import {
  remoteServiceDriversSelector,
  remoteServiceIsLoading,
  remoteServiceJobsSelector,
  remoteServiceMobileTechniciansSelector,
} from "store/selectors/remote-service-selectors";
import {
  settingsDealershipLatitudeLongitudeSelector,
  settingsDealershipMobileTechnicianEnabledSelector,
  settingsDealershipRemoteJobsEnabledSelector,
  settingsMobileTechnicianIntegrationMaxDistanceSelector,
  settingsMobileTechnicianIntegrationSegmentsNumberSelector,
  settingsPickupIntegrationMaxDistanceSelector,
  settingsPickupIntegrationSegmentsNumberSelector,
  settingsTimezoneSelector,
} from "store/selectors/settings-selectors";
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfWeek,
} from "date-fns";

import {
  BOOKINGS_DATEPICKER_DATE_FORMAT,
  BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP,
  WEEK_DAYS,
  parseBookingsDatePickerDate,
} from "shared/utils/datetime";

import Button from "components/common/Button";
import DatePicker from "components/common/DatePicker";
import PageHeader from "components/common/PageHeader";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import Tabs from "components/common/Tabs";
import MobileTechnicianSelection from "components/common/Remote/ChooseMobileTechnician/MobileTechnicianSelection";
import DriversSelection from "components/common/Remote/ChooseDrivers/DriversSelection";

import Directions from "./Directions";
import JobCard from "./JobCard";
import Map from "./Map";
import Marker from "./Marker";
import Areas from "./Areas";

import {
  deliveryTypes, icons, periods, serviceZone,
} from "./helper";

import "./styles.scss";

const PageTitle = () => <h2>Remote Service</h2>;

const RemoteServicePage = ({
  timezone,
  jobs,
  getJobs,
  getActiveDrivers,
  getActiveTechnicians,
  dealershipPosition,
  remoteJobsEnabled,
  mobileTechnicianEnabled,
  technicians,
  updateTechnician,
  technicianMaxDistance,
  technicianSegmentsNumber,
  drivers,
  pickUpMaxDistance,
  pickUpSegmentsNumber,
  updateDriver,
  isLoading,
}) => {
  const mapContainerRef = useRef();

  const [init, setInit] = useState(false);
  const [remoteServiceTabsConfig, setRemoteServiceTabsConfig] = useState([]);
  const [remoteServiceSelectedItem, setRemoteServiceSelectedItem] = useState("");
  const [jobsTypeSelectedItem, setJobsTypeSelectedItem] = useState("upcoming");
  const [deliveryType, setDeliveryType] = useState(deliveryTypes[0]);
  const [period, setPeriod] = useState(periods[0]);
  const [collapseDetails, setCollapseDetails] = useState(false);
  const [filters, setFilters] = useState({
    assigned: false,
    en_route_to_customer: false,
    arrived_to_customer: false,
    en_route_to_dealership: false,
  });
  const [selectedJob, setSelectedJob] = useState(null);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(endOfMonth(new Date()));
  const [selectedTechnician, setSelectedTechnician] = useState({});
  const [selectedDriver, setSelectedDriver] = useState({});
  const [workingAreas, setWorkingAreas] = useState({});

  const maxDistance = remoteServiceSelectedItem === "mobile_technician_settings" ? technicianMaxDistance : pickUpMaxDistance;
  const segmentsNumber = remoteServiceSelectedItem === "mobile_technician_settings" ? technicianSegmentsNumber : pickUpSegmentsNumber;

  const retriveJobs = () => {
    if (period.value === "today") {
      getJobs({
        start_date: format(new Date(), "YYYY-MM-DD"),
        end_date: format(new Date(), "YYYY-MM-DD"),
      });
    } else if (period.value === "tomorrow") {
      const date = new Date();
      date.setDate(date.getDate() + 1);

      getJobs({
        start_date: format(date, "YYYY-MM-DD"),
        end_date: format(date, "YYYY-MM-DD"),
      });
    } else if (period.value === "week") {
      getJobs({
        start_date: format(startOfWeek(new Date()), "YYYY-MM-DD"),
        end_date: format(endOfWeek(new Date()), "YYYY-MM-DD"),
      });
    } else if (period.value === "other") {
      getJobs({
        start_date: format(from, "YYYY-MM-DD"),
        end_date: format(to, "YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    if (selectedJob?.id) {
      const filteredJobs = jobs.filter((job) => job.id === selectedJob.id);

      if (filteredJobs.length > 0) {
        setSelectedJob(filteredJobs[0]);
      }
    }
  }, [jobs]);

  useEffect(() => {
    getActiveDrivers();
    getActiveTechnicians();

    const onPageLoad = () => {
      setInit(true);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  useEffect(() => {
    const result = [];

    if (remoteJobsEnabled) {
      result.push(
        {
          label: "Pick-up and Drop-off",
          value: "pickup",
        },
      );
    }

    if (mobileTechnicianEnabled) {
      result.push(
        {
          label: "Mobile technician",
          value: "mobile_technician",
        },
      );
    }

    if (remoteJobsEnabled) {
      result.push(
        {
          label: "Pick-up and Drop-off settings",
          value: "pickup_settings",
        },
      );
    }

    if (mobileTechnicianEnabled) {
      result.push(
        {
          label: "Mobile technician settings",
          value: "mobile_technician_settings",
        },
      );
    }

    setRemoteServiceTabsConfig(result);

    if (result.length > 0) {
      setRemoteServiceSelectedItem(result[0].value);
    }
  }, [remoteJobsEnabled, mobileTechnicianEnabled]);

  useEffect(() => {
    retriveJobs();
  }, [period]);

  useEffect(() => {
    setSelectedJob(null);
    setSelectedTechnician({});
    setSelectedDriver({});
    setWorkingAreas({});
    setDeliveryType(deliveryTypes[0]);
  }, [remoteServiceSelectedItem]);

  useEffect(() => {
    if (Object.keys(selectedTechnician).length > 0) {
      const result = JSON.parse(JSON.stringify(serviceZone));

      Object.keys(selectedTechnician.working_areas).forEach((day) => {
        selectedTechnician.working_areas[day].forEach((area) => {
          result[day][area] = true;
        });
      });

      setWorkingAreas(result);
    } else {
      setWorkingAreas(serviceZone);
    }
  }, [selectedTechnician]);

  useEffect(() => {
    if (Object.keys(selectedDriver).length > 0) {
      const result = JSON.parse(JSON.stringify(serviceZone));

      Object.keys(selectedDriver.working_areas).forEach((day) => {
        selectedDriver.working_areas[day].forEach((area) => {
          result[day][area] = true;
        });
      });

      setWorkingAreas(result);
    } else {
      setWorkingAreas(serviceZone);
    }
  }, [selectedDriver]);

  const applyChnages = (start, end) => {
    setFrom(start);
    setTo(end);

    getJobs({
      start_date: format(start, "YYYY-MM-DD"),
      end_date: format(end, "YYYY-MM-DD"),
    });
  };

  const previousDates = () => {
    const rawTo = new Date(to);

    return {
      from: format(from, "YYYY-MM-DD"),
      to: format(to, "YYYY-MM-DD"),
      rawTo: format(rawTo.toISOString(), "YYYY-MM-DD"),
    };
  };

  const filteredUpcomingJobs = () => {
    const result = jobs.filter(
      (job) => job.aasm_state !== "confirmed"
      && job.aasm_state !== "arrived_to_dealership"
      && job.aasm_state !== "created"
      && job.aasm_state !== "cancelled"
      && job.aasm_state !== "driver_assigned"
      && (
        job.job_type === remoteServiceSelectedItem
        || (remoteServiceSelectedItem === "pickup" && job.job_type === "dropoff")
      )
      && (
        deliveryType.value === "all" || (job.job_type === deliveryType.value)
      ),
    );

    if (Object.values(filters).filter((item) => item).length === 0) {
      return result;
    }

    return result.filter((job) => filters[job.aasm_state]);
  };

  const filteredCompletedJobs = () => {
    const result = jobs.filter(
      (job) => job.aasm_state === "arrived_to_dealership"
      && (
        job.job_type === remoteServiceSelectedItem
        || (remoteServiceSelectedItem === "pickup" && job.job_type === "dropoff")
      )
      && (
        deliveryType.value === "all" || (job.job_type === deliveryType.value)
      ),
    );

    if (Object.values(filters).filter((item) => item).length === 0) {
      return result;
    }

    return result.filter((job) => filters[job.aasm_state]);
  };

  const jobsTypeTabsConfig = [
    {
      label: "Upcoming",
      value: "upcoming",
      sup: filteredUpcomingJobs().length,
    },
    {
      label: "Completed",
      value: "completed",
      sup: filteredCompletedJobs().length,
    },
  ];

  const markersData = () => {
    const result = {};

    if (selectedJob?.id) {
      const targetMarker = {
        id: "targetMarker",
        aasm_state: selectedJob.aasm_state,
        current_geolocation: selectedJob.aasm_state !== "en_route_to_dealership"
          ? selectedJob.target_geolocation
          : dealershipPosition,
        main_driver: {
          photo: {
            url: selectedJob.aasm_state !== "en_route_to_dealership"
              ? selectedJob.vehicle.image.url
              : "",
          },
        },
      };

      result.selectedJob = [selectedJob];
      result.targetMarker = [targetMarker];
    } else if (jobsTypeSelectedItem === "upcoming") {
      filteredUpcomingJobs().forEach((job) => {
        const key = `${job.current_geolocation.lat} / ${job.current_geolocation.lng}`;

        if (key in result) {
          result[key].push(job);
        } else {
          result[key] = [job];
        }
      });
    } else if (filteredCompletedJobs().length > 0) {
      result[`${dealershipPosition.lat} / ${dealershipPosition.lng}`] = filteredCompletedJobs();
    }

    return result;
  };

  const calculateGMapZoom = (pixels, coverage, latitude, distance, minZoomLevel, maxZoomLevel) => {
    const k = pixels * 156543.03392 * Math.cos((latitude * Math.PI) / 180);
    const zoom = (Math.round(Math.log((coverage * k) / (distance * 100)) / 0.6931471805599453)) - 1;

    if (zoom > maxZoomLevel) {
      return maxZoomLevel;
    }

    if (zoom < minZoomLevel) {
      return minZoomLevel;
    }

    return zoom;
  };

  const zoomLevel = () => {
    const container = mapContainerRef.current;

    if (
      container
      && dealershipPosition.lat
      && (
        remoteServiceSelectedItem === "mobile_technician_settings"
        || remoteServiceSelectedItem === "pickup_settings"
      )
    ) {
      const width = container.clientWidth;
      const height = container.clientHeight;
      const radiusKMeters = maxDistance * 1.609344;

      return calculateGMapZoom(
        Math.min(width, height),
        60,
        dealershipPosition.lat,
        radiusKMeters * 1000,
        1,
        20,
      );
    }

    return 12;
  };

  const saveWorkingAreas = () => {
    const result = {};

    Object.keys(workingAreas).forEach((day) => {
      result[day] = [];
      Object.keys(workingAreas[day]).slice(0, segmentsNumber).forEach((area) => {
        if (workingAreas[day][area]) {
          result[day].push(area);
        }
      });
    });

    if (remoteServiceSelectedItem === "mobile_technician_settings") {
      updateTechnician(selectedTechnician.id, { working_areas: result });
      setSelectedTechnician({});
    }

    if (remoteServiceSelectedItem === "pickup_settings") {
      updateDriver(selectedDriver.id, { working_areas: result });
      setSelectedDriver({});
    }

    setWorkingAreas({});
  };

  const infoSection = () => {
    if (remoteServiceSelectedItem === "pickup_settings") {
      return (
        <div className="infoSection">
          <p className="settingsMainLabel">
            Pick-up and Drop-off settings
          </p>
          <DriversSelection
            availableDrivers={drivers}
            selectedDriver={selectedDriver}
            setSelectedDriver={setSelectedDriver}
            showCoDriver={false}
            isFullForm={false}
          />
          <div className="settingsServiceZone">
            <p className="settingsServiceLabel">
              Service zones
            </p>
            <p className="settingsServiceLabel">
              {`Max distance ${maxDistance} miles.`}
            </p>
          </div>
          {Object.keys(selectedDriver).length > 0 && (
            <>
              <div className="settingsServiceSelectorContainer">
                {Object.keys(serviceZone).map((day) => (
                  <div className="settingsServiceSelector">
                    <p className="settingsServiceSelectorLabel">
                      {WEEK_DAYS[day]}
                    </p>
                    <div className="settingsServiceSelectorBtnContainer">
                      {Object.keys(serviceZone[day]).slice(0, segmentsNumber).map((zone) => (
                        <Button
                          variant={workingAreas[day][zone] ? "aqua" : "neutral"}
                          padding="small"
                          onClick={() => setWorkingAreas({
                            ...workingAreas,
                            [day]: {
                              ...workingAreas[day],
                              [zone]: !workingAreas[day][zone],
                            },
                          })}
                        >
                          {zone}
                        </Button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="settingsServiceConfirmation">
                <Button
                  variant="neutral"
                  onClick={() => setSelectedDriver({})}
                >
                  Cancel
                </Button>
                <Button
                  variant="brand"
                  onClick={saveWorkingAreas}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    if (remoteServiceSelectedItem === "mobile_technician_settings") {
      return (
        <div className="infoSection">
          <p className="settingsMainLabel">
            Mobile technician settings
          </p>
          <MobileTechnicianSelection
            availableMobileTechnicians={technicians}
            selectedMobileTechnician={selectedTechnician}
            setSelectedMobileTechnician={setSelectedTechnician}
            isFullForm={false}
          />
          <div className="settingsServiceZone">
            <p className="settingsServiceLabel">
              Service zones
            </p>
            <p className="settingsServiceLabel">
              {`Max distance ${maxDistance} miles.`}
            </p>
          </div>
          {Object.keys(selectedTechnician).length > 0 && (
            <>
              <div className="settingsServiceSelectorContainer">
                {Object.keys(serviceZone).map((day) => (
                  <div className="settingsServiceSelector">
                    <p className="settingsServiceSelectorLabel">
                      {WEEK_DAYS[day]}
                    </p>
                    <div className="settingsServiceSelectorBtnContainer">
                      {Object.keys(serviceZone[day])
                        .slice(0, segmentsNumber)
                        .map((zone) => (
                          <Button
                            variant={workingAreas[day][zone] ? "aqua" : "neutral"}
                            padding="small"
                            onClick={() => setWorkingAreas({
                              ...workingAreas,
                              [day]: {
                                ...workingAreas[day],
                                [zone]: !workingAreas[day][zone],
                              },
                            })}
                          >
                            {zone}
                          </Button>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="settingsServiceConfirmation">
                <Button
                  variant="neutral"
                  onClick={() => setSelectedTechnician({})}
                >
                  Cancel
                </Button>
                <Button
                  variant="brand"
                  onClick={saveWorkingAreas}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <>
        <div className="flexSpaceBetween marginBottom">
          <div className="conciergeRemoteServicePageDetailsLeftContainer">
            <Select
              className="conciergeRemoteServicePageDetailsLeftContainerSelect"
              options={periods}
              value={period}
              onChange={(value) => {
                setPeriod(value);
                setSelectedJob(null);
              }}
            />
            <Button
              className="conciergeRemoteServicePageDetailsLeftContainerResync"
              variant="base-grey"
              padding="medium"
              onClick={retriveJobs}
            >
              Refresh
            </Button>
          </div>
          <div className="conciergeRemoteServicePageDetailsFilters">
            <div className="vl" />
            {Object.keys(filters).map((type) => (
              <Button
                className={cx({ filterActive: filters[type] })}
                variant="base-grey"
                padding="medium"
                leftIcon={icons[type]}
                isSquare
                onClick={() => {
                  setFilters({
                    ...filters,
                    [type]: !filters[type],
                  });
                  setSelectedJob(null);
                }}
              />
            ))}
          </div>
        </div>
        {period.value === "other" && (
          <div className="conciergeRemoteServicePageDetailsCalendar">
            <DatePicker
              rangeMode
              format={BOOKINGS_DATEPICKER_DATE_FORMAT}
              separator="/"
              separatorPositions={[2, 5]}
              formatRegExp={BOOKINGS_DATEPICKER_DATE_FORMAT_REGEXP}
              parseDateInFormat={parseBookingsDatePickerDate}
              startDateLabel="From"
              endDateLabel="To"
              onApplyChanges={applyChnages}
              previousDates={previousDates()}
              previousDatesNaming={{
                startDate: "from",
                endDate: "to",
              }}
              timezone={timezone}
              hideResetButton
              darkMode
            />
          </div>
        )}
        <div className="conciergeRemoteServicePageDetailsFilterContainer">
          <Tabs
            config={jobsTypeTabsConfig}
            selectedItem={jobsTypeSelectedItem}
            selectTabAction={(value) => {
              setJobsTypeSelectedItem(value);
              setSelectedJob(null);
            }}
            isGeneral
          />
          {remoteServiceSelectedItem === "pickup" && (
            <Select
              className="conciergeRemoteServicePageDetailsLeftContainerSelect"
              options={deliveryTypes}
              value={deliveryType}
              onChange={(value) => {
                setDeliveryType(value);
                setSelectedJob(null);
              }}
            />
          )}
        </div>
        <div className="jobCards">
          {isLoading && (
            <section className="cardsLoading">
              <ClipLoader size={40} color="#0bcaf9" />
            </section>
          )}
          {!isLoading && (jobsTypeSelectedItem === "upcoming"
            ? filteredUpcomingJobs()
            : filteredCompletedJobs()
          ).map((job) => (
            <JobCard
              job={job}
              selectedJob={selectedJob}
              setSelectedJob={setSelectedJob}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <section className="conciergeRemoteServicePage">
      <PageHeader
        title={<PageTitle />}
      />
      <section className="conciergeRemoteServicePageNavigation">
        <Tabs
          config={remoteServiceTabsConfig}
          selectedItem={remoteServiceSelectedItem}
          selectTabAction={setRemoteServiceSelectedItem}
          isGeneral
        />
      </section>
      <section className="conciergeRemoteServicePageMain">
        <div className="conciergeRemoteServicePageContainer">
          <section
            ref={mapContainerRef}
            className="conciergeRemoteServicePageMap"
          >
            {init && (
              <Map
                center={dealershipPosition}
                zoom={zoomLevel()}
                minZoom={
                  remoteServiceSelectedItem === "mobile_technician_settings" || remoteServiceSelectedItem === "pickup_settings"
                    ? zoomLevel()
                    : null
                }
              >
                {remoteServiceSelectedItem === "pickup_settings" && (
                  <Areas
                    center={dealershipPosition}
                    miles={maxDistance}
                    segmentsNumber={segmentsNumber}
                  />
                )}
                {remoteServiceSelectedItem === "pickup_settings" && (
                  <>
                    {Object.values(markersData()).map((items) => (
                      <Marker
                        job={items[0]}
                        size={items.length}
                        dealershipPosition={dealershipPosition}
                      />
                    ))}
                    {selectedJob?.id && (
                      <Directions
                        selectedJob={selectedJob}
                        dealershipPosition={dealershipPosition}
                      />
                    )}
                  </>
                )}
                {remoteServiceSelectedItem === "mobile_technician_settings" && (
                  <Areas
                    center={dealershipPosition}
                    miles={maxDistance}
                    segmentsNumber={segmentsNumber}
                  />
                )}
                {remoteServiceSelectedItem !== "mobile_technician_settings" && remoteServiceSelectedItem !== "pickup_settings" && (
                  <>
                    {Object.values(markersData()).map((items) => (
                      <Marker
                        job={items[0]}
                        size={items.length}
                        dealershipPosition={dealershipPosition}
                      />
                    ))}
                    {selectedJob?.id && (
                      <Directions
                        selectedJob={selectedJob}
                        dealershipPosition={dealershipPosition}
                      />
                    )}
                  </>
                )}
              </Map>
            )}
          </section>
          <section
            className={cx("conciergeRemoteServicePageDetails", {
              conciergeRemoteServicePageDetailsCollapsed: collapseDetails,
            })}
          >
            <div className="conciergeRemoteServicePageDetailsCollaspeButtonContainer">
              <Button
                rightIcon={collapseDetails ? "leftArrow" : "rightArrow"}
                onClick={() => setCollapseDetails(!collapseDetails)}
              >
                {collapseDetails ? "" : "Collapse details"}
              </Button>
            </div>
            {!collapseDetails && infoSection()}
          </section>
        </div>
      </section>
    </section>
  );
};

RemoteServicePage.propTypes = {
  timezone: string,
  jobs: arrayOf(objectOf(oneOfType([number, bool, string])).isRequired),
  getJobs: func.isRequired,
  getActiveDrivers: func.isRequired,
  getActiveTechnicians: func.isRequired,
  dealershipPosition: {
    lat: number,
    lng: number,
  }.isRequired,
  remoteJobsEnabled: bool.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  technicians: arrayOf(mobileTechnicianPropType),
  updateTechnician: func.isRequired,
  technicianMaxDistance: number.isRequired,
  technicianSegmentsNumber: number.isRequired,
  drivers: arrayOf(driverPropType),
  pickUpMaxDistance: number.isRequired,
  pickUpSegmentsNumber: number.isRequired,
  updateDriver: func.isRequired,
  isLoading: bool.isRequired,
};

RemoteServicePage.defaultProps = {
  timezone: null,
  jobs: [],
  technicians: null,
  drivers: null,
};

const mapStateToProps = (state) => {
  return {
    jobs: remoteServiceJobsSelector(state),
    dealershipPosition: settingsDealershipLatitudeLongitudeSelector(state),
    timezone: settingsTimezoneSelector(state),
    remoteJobsEnabled: settingsDealershipRemoteJobsEnabledSelector(state),
    mobileTechnicianEnabled: settingsDealershipMobileTechnicianEnabledSelector(state),
    technicians: remoteServiceMobileTechniciansSelector(state),
    technicianMaxDistance: settingsMobileTechnicianIntegrationMaxDistanceSelector(state),
    technicianSegmentsNumber: settingsMobileTechnicianIntegrationSegmentsNumberSelector(state),
    drivers: remoteServiceDriversSelector(state),
    pickUpMaxDistance: settingsPickupIntegrationMaxDistanceSelector(state),
    pickUpSegmentsNumber: settingsPickupIntegrationSegmentsNumberSelector(state),
    isLoading: remoteServiceIsLoading(state),
  };
};

const actions = {
  getJobs: fetchDriverJobs,
  getActiveDrivers: retrieveDealershipDrivers,
  getActiveTechnicians: retrieveDealershipMobileTechnicians,
  updateTechnician: updateMobileTechnician,
  updateDriver: updateDriverInfo,
};

export default connect(mapStateToProps, actions)(RemoteServicePage);
