import React from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import QRCode from "react-qr-code";
import {
  settingsDealershipNameSelector, settingsDealershipPublicIdSelector,
} from "store/selectors/settings-selectors";
import serviceLogo from "assets/icons/carmen-service.svg";
import carmenLogo from "assets/icons/carmen_logo.svg";

import styles from "../styles.module.scss";

const QRPrint = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    className={styles.qrCodeContainer}
  >
    <span className={styles.name}>
      {props.dealershipName}
    </span>
    <div className={styles.body}>
      <img
        src={serviceLogo}
        className={styles.service}
        alt=""
      />
      <div className={styles.titles}>
        <span>BOOK YOUR NEXT SERVICE.</span>
        <span>VISIT ONLINE WITH THE</span>
        <span>CARMEN SERVICE APP.</span>
      </div>
    </div>
    <QRCode
      value={`https://reserve.getcarmen.com/carmenservice?dealershipId=${props.dealershipPublicId}`}
      size={400}
      bgColor="#FFFFFF"
    />
    <span className={styles.bottom}>
      FAST. EASY TO USE. INTELLIGENT.
    </span>
    <img
      src={carmenLogo}
      className={styles.logo}
      alt=""
    />
  </div>
));

QRPrint.propTypes = {
  dealershipName: string.isRequired,
  dealershipPublicId: string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    dealershipName: settingsDealershipNameSelector(state),
    dealershipPublicId: settingsDealershipPublicIdSelector(state),
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(QRPrint);
