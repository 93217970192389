import {
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";

import { SOURCE_CONCIERGE, SOURCE_USER } from "shared/constants/text-messages";

const GENERAL_KIND = "general";
const EXTENSION_KIND = "extension";
const MAINTENANCE_KIND = "maintenance";
const CONCERN_KIND = "concern";
const QUESTION_STEP = "question";
const TEXT_STEP = "text";

export const decisionTreePropType = shape({
  id: number,
  menu_item_id: number,
  intro: shape({
    text: string,
    goto: oneOfType([string, number]),
  }),
  steps: arrayOf(
    oneOfType([
      shape({
        type: oneOf([QUESTION_STEP]),
        text: string,
        answers: arrayOf(
          shape({
            text: string,
            goto: oneOfType([string, number]),
          }),
        ),
      }),
      shape({
        type: oneOf([TEXT_STEP]),
        text: string,
        goto: oneOfType([string, number]),
      }),
    ]),
  ),
});

export const vehicleSetPropType = shape({
  id: number.isRequired,
  make: string.isRequired,
  model: string.isRequired,
  model_year: number.isRequired,
});

export const menuItemTargetPropType = shape({
  id: number,
  price: number.isRequired,
  saving: number,
  mileage_range: arrayOf(number).isRequired,
  vehicle_sets: arrayOf(vehicleSetPropType).isRequired,
  labor_price: number.isRequired,
  parts_price: number.isRequired,
});

export const menuItemPropType = shape({
  id: number,
  name: string,
  kind: oneOf([EXTENSION_KIND, MAINTENANCE_KIND, CONCERN_KIND]),
  decision_tree: decisionTreePropType,
  menu_item_targets: arrayOf(menuItemTargetPropType),
  package_items: shape({
    id: number,
    name: string,
    kind: oneOf([EXTENSION_KIND, MAINTENANCE_KIND, CONCERN_KIND]),
  }),
});

export const menuTemplatePropType = shape({
  id: number,
  name: string,
  active: bool,
  kind: oneOf([GENERAL_KIND, EXTENSION_KIND]),
  created_at: string,
  updated_at: string,
  menu_items: shape({
    extension: arrayOf(menuItemPropType),
    maintenance: arrayOf(menuItemPropType),
    concern: arrayOf(menuItemPropType),
  }),
});

export const servicePropType = shape({
  id: number.isRequired,
  name: string.isRequired,
  kind: oneOf([EXTENSION_KIND, MAINTENANCE_KIND, CONCERN_KIND]),
});

export const recallPropType = shape({
  id: number.isRequired,
  name: string.isRequired,
});

export const textMessagePropType = shape({
  id: number.isRequired,
  kind: string.isRequired,
  translation_key: string.isRequired,
  content: string.isRequired,
});

export const teamTagPropType = shape({
  id: number.isRequired,
  name: string,
  parent_team_tag_id: number,
  seniority: number,
  expected_upsell: string,
  max_advisor_appointments_per_hour: number,
  max_advisor_appointments_per_day: number,
  max_pickups_per_hour: number,
  max_team_hours_per_weekday: number,
});

export const dealershipInfoPropType = shape({
  id: number,
  logo: shape({
    url: string,
  }),
  name: string,
  address: string,
  phone: string,
  email: string,
  manager_name: string,
  manager_phone: string,
  manager_email: string,
  sales_emails: arrayOf(string),
  created_at: string,
  updated_at: string,
  last_appointment_import_date: string,
  latitude: number,
  longitude: number,
  capacity: number,
  integrations: arrayOf(
    shape({
      id: number.isRequired,
      kind: string,
    }),
  ),
  employments: arrayOf(
    shape({
      id: number.isRequired,
      dealership_id: number.isRequired,
      name: string,
      username: string,
      email: string,
      created_at: string,
      updated_at: string,
    }),
  ),
  dms_type: string.isRequired,
  team_tags: arrayOf(teamTagPropType),
});

export const vehiclePropType = shape({
  id: number.isRequired,
  vin: string,
  plate_number: string,
  mileage: number.isRequired,
  vehicle_set: vehicleSetPropType.isRequired,
});

export const customerPropType = shape({
  id: number.isRequired,
  first_name: string,
  last_name: string.isRequired,
  phone_number: string.isRequired,
  email: string,
  vehicles: arrayOf(vehiclePropType).isRequired,
});

export const integrationPropType = shape({
  id: number.isRequired,
  kind: string.isRequired,
  settings: objectOf(oneOfType([number, string])),
  last_repair_order_import_date: string,
  last_appointment_import_date: string,
});

export const dealershipMenuItemsPropType = shape({
  maintenance: arrayOf(menuItemPropType),
  concern: arrayOf(menuItemPropType),
});

export const daySlotPropType = shape({
  full_date: string.isRequired,
  short_day_name: string.isRequired,
  day_name: string.isRequired,
  day_of_month: string.isRequired,
  slots: shape(node.isRequired),
});

export const chosenTimeSlotPropType = shape({
  quarterSlot: string,
  day: daySlotPropType,
});

export const customerFieldsPropType = shape({
  id: string,
  type: string,
  label: string,
});

export const opCodePropType = shape({
  id: number,
  operation_code: string,
  description: string,
});

export const employeeWorkingHoursPropType = shape({
  dayName: string,
  from: string,
  to: string,
  order: number,
});

export const serviceAdvisorPropType = shape({
  id: number,
  name: string,
  photo: shape({
    url: string,
  }),
  workingHours: arrayOf(employeeWorkingHoursPropType),
  writer_id: string,
});

export const mobileTechniciansPropType = shape({
  id: number,
  name: string,
  phone_number: string,
  employee_number: string,
  workingHours: arrayOf(employeeWorkingHoursPropType),
  active: bool,
  dealership_id: number,
  photo: shape({
    url: string,
  }),
});

export const workingHoursPropType = shape({
  mon: {
    from: string,
    to: string,
    order: number,
  },
  tue: {
    from: string,
    to: string,
    order: number,
  },
  wed: {
    from: string,
    to: string,
    order: number,
  },
  thu: {
    from: string,
    to: string,
    order: number,
  },
  fri: {
    from: string,
    to: string,
    order: number,
  },
  sat: {
    from: string,
    to: string,
    order: number,
  },
  sun: {
    from: string,
    to: string,
    order: number,
  },
});

export const photoPropType = shape({
  url: string,
});

export const settingsUserProptype = shape({
  username: string.isRequired,
  password: string.isRequired,
});

export const driverPropType = shape({
  id: number,
  name: string,
  phone_number: string,
  photo: shape({
    url: string,
  }),
  workingHours: arrayOf(employeeWorkingHoursPropType),
});

export const mobileTechnicianPropType = shape({
  id: number,
  name: string,
  phone_number: string,
  photo: shape({
    url: string,
  }),
  workingHours: arrayOf(employeeWorkingHoursPropType),
});

export const dispatchCodePropType = shape({
  id: number,
  code: string,
  description: string,
});

export const printerPropType = shape({
  id: number,
  printer_id: string,
  description: string,
});

export const servicesPropType = arrayOf(servicePropType);

export const availableServicesPropType = shape({
  maintenance: arrayOf(
    shape({
      id: number,
      name: string,
    }),
  ),
  concern: arrayOf(
    shape({
      id: number,
      name: string,
    }),
  ),
});

export const bookingPropType = shape({
  customer: string.isRequired,
  vehicle: string.isRequired,
  services: shape({
    maintenance: servicesPropType,
    concern: servicesPropType,
    extensions: servicesPropType,
    recalls: servicesPropType,
  }).isRequired,
  totalPrice: number.isRequired,
  totalTime: string.isRequired,
});

export const chatHistoryPropType = arrayOf(
  shape({
    id: number.isRequired,
    component: oneOfType([func, object]).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    props: object.isRequired,
  }),
);

export const decisionTreeResultPropType = shape({
  serviceId: number,
  conversation: arrayOf(
    shape({
      text: string,
      source: oneOf([SOURCE_CONCIERGE, SOURCE_USER]),
    }),
  ),
});

export const appointmentListItemPropType = shape({
  id: number,
  appointmentNumber: string,
  carPlateNumber: string,
  carRegion: string,
  carMake: string,
  carModel: string,
  carYear: number,
  appointmentDateTime: string,
});

export const uberPropType = shape({
  driverName: string,
  driverPicture: string,
  driverPhone: string,
  time: number,
  vehicle: string,
});

const dayWorkingHoursPropType = shape({
  from: string,
  to: string,
  order: number,
});

export const dealershipWorkingHoursPropType = shape({
  mon: dayWorkingHoursPropType,
  tue: dayWorkingHoursPropType,
  wed: dayWorkingHoursPropType,
  thu: dayWorkingHoursPropType,
  fri: dayWorkingHoursPropType,
  sat: dayWorkingHoursPropType,
  sun: dayWorkingHoursPropType,
});

export const appointmentSlotPropType = shape({
  full_date: string,
  short_day_name: string,
  day_name: string,
  day_of_month: string,
  slots: arrayOf(string),
});

export const timeSlotPropType = shape({
  label: string,
  value: number,
});

export const quarterSlotPropType = shape({
  id: string,
  label: string,
  value: string,
});

export const hourSlotPropType = shape({
  id: string,
  label: string,
  value: number,
  quarterSlots: arrayOf(quarterSlotPropType),
});

export const timeOfDaySlotPropType = shape({
  id: string,
  label: string,
  hourSlots: arrayOf(hourSlotPropType),
});

export const reportsPropType = arrayOf(shape({
  appointments_total: number,
  appointments_average_value: number,
  appointments_total_showed: number,
  appointments_average_value_showed: number,
  appointments_total_not_showed: number,
  appointments_average_value_not_showed: number,
  appointments_total_canceled: number,
  appointments_total_showed_with_premium: number,
  appointments_average_value_canceled: number,
  created_by_name: string,
  created_by_role: string,
}));

export const serviceLocationPropType = shape({
  pickUp: shape({
    address: string,
    addressData: shape({
      address_line1: string,
      address_line2: string,
      address_city: string,
      address_state: string,
      address_zipcode: string,
    }),
  }),
  dropOff: shape({
    address: string,
    addressData: shape({
      address_line1: string,
      address_line2: string,
      address_city: string,
      address_state: string,
      address_zipcode: string,
    }),
  }),
  isSameLocation: bool,
});
