import React, { useState } from "react";
import { connect } from "react-redux";
import {
  arrayOf, bool, func, oneOfType, shape,
} from "prop-types";
import {
  dispatchCodePropType,
  integrationPropType,
  printerPropType,
  serviceAdvisorPropType,
} from "shared/prop-types";
import {
  activeAdvisorsSelector,
  settingsDispatchCodesSelector,
  settingsLaborTypesSelector,
  settingsPrintersSelector,
} from "store/selectors/settings-selectors";
import { dealershipMakeModelYearMapLoadingSelector, dealershipMakeModelYearMapSelector } from "store/selectors/app-selectors";
import {
  convertArrayToMakeOptions,
  formatName,
} from "shared/utils/common";
import Input from "components/common/Input";
import Accordion from "components/common/Accordion";
import HeaderEdit from "components/common/HeaderEdit";
import Switch from "react-switch";
import MultiTagSelect from "components/common/MultiTagSelect";
import ImportDatesInputs from "../ImportDatesInputs";
import DefaultSettingDropdown from "./DefaultSettingDropdown";
import ActivitySwitch from "../ActivitySwitch";

import styles from "../styles.module.scss";

const KEYS_TO_SKIP = [
  "service_writer_id",
  "dispatch_code",
  "labor_type",
  "printer_id",
  "shared_account",
  "imported_makes",
];

const MAKES_TO_SKIP = [" make", "Unknown"];

const keysToDisplay = (settings) => Object.keys(settings).filter((keyName) => (
  !KEYS_TO_SKIP.includes(keyName)));

const DmsIntegration = ({
  integration,
  updateIntegrationHandler,
  serviceAdvisors,
  dispatchCodes,
  laborTypes,
  printers,
  isSuperAdmin,
  makeModelYearMap,
  isLoading,
}) => {
  const {
    settings,
    id,
    active,
    kind,
    last_repair_order_import_date: repairOrderImportDate,
    last_appointment_import_date: appointmentImportDate,
  } = integration;
  const [printerId, setPrinterId] = useState(settings.printer_id);
  const [isEditing, setIsEditing] = useState(false);
  const [isActive, setIsActive] = useState(active);
  const [integrationSettings, setIntegrationSettings] = useState(settings);
  const [importedMakes, setImportedMakes] = useState(
    convertArrayToMakeOptions(settings.imported_makes),
  );

  const makeOptions = () => convertArrayToMakeOptions(
    Object.keys(makeModelYearMap).filter((make) => (
      !MAKES_TO_SKIP.includes(make))),
  );

  const save = () => {
    const params = {
      active: isActive,
      settings: {
        ...integrationSettings,
        imported_makes: importedMakes.map((make) => make.name),
      },
    };

    updateIntegrationHandler(id, params);
    setIsEditing(false);
  };

  const updateServiceWriter = (selectedWriterOption) => setIntegrationSettings({
    ...integrationSettings,
    service_writer_id: selectedWriterOption.value,
  });

  const updateDispatchCode = (selectedCodeOption) => setIntegrationSettings({
    ...integrationSettings,
    dispatch_code: selectedCodeOption.value,
  });

  const updateLaborType = (selectedCodeOption) => setIntegrationSettings({
    ...integrationSettings,
    labor_type: selectedCodeOption.value,
  });

  const updatePrinterId = (selectedPrinterOption) => {
    setPrinterId(selectedPrinterOption.value);
  };

  const cancelChanges = () => {
    setPrinterId(settings.printer_id);
    setIntegrationSettings(settings);
    setImportedMakes(convertArrayToMakeOptions(settings.imported_makes));
    setIsEditing(false);
    setIsActive(active);
  };

  return (
    <Accordion
      title={formatName(kind)}
      customClass={styles.accordion}
    >
      {isLoading && (
        <span className={styles.loader}>
          Loading...
        </span>
      )}
      {!isLoading && (
        <>
          <section className={styles.container}>
            <ActivitySwitch
              isEditing={isEditing}
              active={isActive}
              onChange={() => setIsActive(!isActive)}
            />
            <div>
              <div className={styles.dropdownContainer}>
                {!isSuperAdmin && (
                  <DefaultSettingDropdown
                    collection={serviceAdvisors}
                    idColumn="writer_id"
                    nameColumn="name"
                    dropdownLabel="Service Writer ID"
                    dropdownKey="service_writer_id"
                    selectedValue={integrationSettings.service_writer_id}
                    updateHandler={updateServiceWriter}
                    isDisabled={!isEditing}
                  />
                )}
                {kind === "dealer_track" && !isSuperAdmin && (
                  <DefaultSettingDropdown
                    collection={printers}
                    idColumn="printer_id"
                    nameColumn="description"
                    dropdownLabel="Default Printer"
                    dropdownKey="printer_id"
                    selectedValue={printerId}
                    updateHandler={updatePrinterId}
                    isDisabled={!isEditing}
                  />
                )}
                {integration.kind === "cdk" && !isSuperAdmin && (
                  <>
                    <DefaultSettingDropdown
                      collection={dispatchCodes}
                      idColumn="code"
                      nameColumn="description"
                      dropdownLabel="Default Dispatch Code"
                      dropdownKey="dispatch_code"
                      selectedValue={integrationSettings.dispatch_code}
                      updateHandler={updateDispatchCode}
                      isDisabled={!isEditing}
                    />
                    <DefaultSettingDropdown
                      collection={laborTypes}
                      idColumn="code"
                      nameColumn="description"
                      dropdownLabel="Default Labor Type"
                      dropdownKey="labor_type"
                      selectedValue={integrationSettings.labor_type}
                      updateHandler={updateLaborType}
                      isDisabled={!isEditing}
                    />
                  </>
                )}
              </div>
              <ImportDatesInputs
                repairOrderImportDate={repairOrderImportDate}
                appointmentImportDate={appointmentImportDate}
              />
            </div>
            <section className={styles.inputsContainer}>
              {keysToDisplay(integrationSettings).map((keyName) => (
                <Input
                  label={formatName(keyName)}
                  value={integrationSettings[keyName]}
                  disabled={!isSuperAdmin || !isEditing}
                  onChange={(value) => setIntegrationSettings({
                    ...integrationSettings,
                    [keyName]: value,
                  })}
                  key={keyName}
                  inputClassName={styles.input}
                />
              ))}
            </section>
            {integrationSettings.shared_account && (
              <div className={styles.make}>
                <label
                  className={styles.label}
                  htmlFor="makes-selection"
                >
                  Makes
                </label>
                <MultiTagSelect
                  options={makeOptions()}
                  value={importedMakes}
                  onChange={(selected) => setImportedMakes(selected)}
                  disabled={!isEditing}
                  isMake
                />
              </div>
            )}
            <div className={styles.switch}>
              <label
                className={styles.label}
                htmlFor="shared-account-switch"
              >
                {formatName("shared_account")}
              </label>
              <Switch
                id="shared-account-switch"
                className={styles.toggle}
                onChange={() => setIntegrationSettings((prev) => {
                  return {
                    ...prev,
                    shared_account: !prev.shared_account,
                  };
                })}
                checked={integrationSettings.shared_account}
                disabled={!(isEditing && isSuperAdmin)}
                onColor="#36af5e"
                offColor="#dedee0"
                activeBoxShadow="0 0 2px 3px #0bcaf9"
                aria-labelledby="shared-account-label"
              />
            </div>
          </section>
          <HeaderEdit
            isEditing={isEditing}
            handleCancel={cancelChanges}
            handleSave={save}
            handleEdit={() => setIsEditing(true)}
          />
        </>
      )}
    </Accordion>
  );
};

DmsIntegration.propTypes = {
  integration: integrationPropType.isRequired,
  updateIntegrationHandler: func.isRequired,
  serviceAdvisors: arrayOf(serviceAdvisorPropType).isRequired,
  dispatchCodes: arrayOf(dispatchCodePropType).isRequired,
  laborTypes: arrayOf(dispatchCodePropType).isRequired,
  printers: arrayOf(printerPropType).isRequired,
  isSuperAdmin: bool,
  makeModelYearMap: oneOfType(shape({ make: func }), func).isRequired,
  isLoading: bool,
};

DmsIntegration.defaultProps = {
  isSuperAdmin: false,
  isLoading: false,
};

const mapStateToProps = (state) => {
  return {
    serviceAdvisors: activeAdvisorsSelector(state),
    dispatchCodes: settingsDispatchCodesSelector(state),
    laborTypes: settingsLaborTypesSelector(state),
    printers: settingsPrintersSelector(state),
    makeModelYearMap: dealershipMakeModelYearMapSelector(state),
    isLoading: dealershipMakeModelYearMapLoadingSelector(state),
  };
};

export default connect(mapStateToProps, null)(DmsIntegration);
