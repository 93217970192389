import { connect } from "react-redux";

import {
  retrieveDealershipMobileTechnicians,
  setCurrentStep,
  setRemoteJobData,
} from "store/actions/scheduling-process-actions";
import {
  schedulingAvailableJobEmployee,
  schedulingProcessMobileTechniciansSelector,
  schedulingProcessServiceLocationSelector,
} from "store/selectors/scheduling-process-selectors";

import ChooseMobileTechnician from "./index";

const mapStateToProps = (state) => {
  return {
    mobileTechnicians: schedulingProcessMobileTechniciansSelector(state),
    serviceLocation: schedulingProcessServiceLocationSelector(state),
    availableJobEmployee: schedulingAvailableJobEmployee(state),
  };
};

const actions = {
  retrieveMobileTechnicians: retrieveDealershipMobileTechnicians,
  storeRemoteJobData: setRemoteJobData,
  setSchedulingStep: setCurrentStep,
};

export default connect(mapStateToProps, actions)(ChooseMobileTechnician);
