import React from "react";
import { bool, func, string } from "prop-types";
import cx from "classnames";

import styles from "./styles.module.scss";

const AdditionalInfoFilterItem = ({
  assets, isActive, handleOnClick,
}) => (
  <div
    className={cx(styles.item, {
      [styles.itemActive]: isActive,
    })}
    onClick={handleOnClick}
  >
    <img
      src={assets || null}
      alt="img"
    />
  </div>
);

AdditionalInfoFilterItem.propTypes = {
  assets: string.isRequired,
  isActive: bool,
  handleOnClick: func.isRequired,
};

AdditionalInfoFilterItem.defaultProps = {
  isActive: false,
};

export default AdditionalInfoFilterItem;
