import React from "react";
import { format } from "date-fns";
import { shape } from "prop-types";
import { AMERICAN_DATETIME_FORMAT } from "shared/utils/datetime";
import Panel from "components/common/Panel";

import styles from "../styles.module.scss";

const LastAdvisor = ({ customer }) => (
  <Panel className={styles.panel}>
    <div>
      <div className={styles.itemContainer}>
        <div className={styles.item}>
          <span>Last visit</span>
        </div>
        <div className={styles.item}>
          <span className={styles.itemLabel}>Date: </span>
          <span className={styles.itemValue}>
            {format(customer.last_visited_at, AMERICAN_DATETIME_FORMAT)}
          </span>
        </div>
        <div className={styles.item}>
          <span className={styles.itemLabel}>Last service advisor: </span>
          <span className={styles.itemValue}>
            {customer.last_visit_advisor.name}
          </span>
        </div>
      </div>
    </div>
  </Panel>
);

LastAdvisor.propTypes = {
  customer: shape.isRequired,
};

export default LastAdvisor;
