import React from "react";
import { string } from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { authRoleSelector, authTokenSelector } from "store/selectors/auth-selectors";
import { settingsDealershipLogoSelector } from "store/selectors/settings-selectors";
import {
  ADMIN_ROLE, ADVISOR_ROLE, BDC_ROLE, MANAGER_ROLE,
} from "shared/constants";

import conciergeLogo from "assets/images/carmen_logo_white.svg";

import DealershipAdminNavigation from "./DealershipAdminNavigation";
import DealershipAdvisorNavigation from "./DealershipAdvisorNavigation";
import ConciergeAdminNavigation from "./ConciergeAdminNavigation";
import DealershipConsultantNavigation from "./DealershipConsultantNavigation";
import "./styles.scss";

const Header = ({ token, role, logo }) => {
  const navigation = () => {
    if (!token) {
      return null;
    }
    switch (role) {
      case ADMIN_ROLE: return <ConciergeAdminNavigation />;
      case MANAGER_ROLE: return <DealershipAdminNavigation />;
      case ADVISOR_ROLE: return <DealershipAdvisorNavigation />;
      case BDC_ROLE: return <DealershipConsultantNavigation />;
      default: return null;
    }
  };

  return (
    <header className="conciergeHeader">
      <div className="conciergeHeaderCentering">
        <img className="conciergeLogo" alt="concierge logo" src={logo || conciergeLogo} />
        {navigation()}
      </div>
    </header>
  );
};

Header.propTypes = {
  token: string,
  role: string,
  logo: string,
};

Header.defaultProps = {
  token: null,
  role: null,
  logo: null,
};

const mapStateToProps = (state) => {
  return {
    token: authTokenSelector(state),
    role: authRoleSelector(state),
    logo: settingsDealershipLogoSelector(state),
  };
};

export default withRouter(connect(mapStateToProps)(Header));
