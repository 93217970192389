import { connect } from "react-redux";
import { settingsDealershipMobileTechnicianEnabledSelector } from "store/selectors/settings-selectors";
import {
  schedulingAvailableRecallsSelector,
  schedulingCurrentStepSelector,
  schedulingDealershipMenuItemsSelector,
  schedulingDecisionTreeResultsSelector,
  schedulingIsPickUpSelector,
  schedulingMenuItemsLoadingErrorSelector,
  schedulingMenuItemsLoadingSelector,
  schedulingSelectedRecallsSelector,
  schedulingSelectedServicesSelector,
  schedulingisMobileTechnicianSelector,
} from "store/selectors/scheduling-process-selectors";
import {
  fetchMenuItems,
  fetchRecalls,
  setCurrentStep,
  setServiceAdvisor,
  setServiceSelection,
} from "store/actions/scheduling-process-actions";

import ChooseServices from "./index";

const mapStateToProps = (state) => {
  return {
    dealershipMenuItems: schedulingDealershipMenuItemsSelector(state),
    selectedServices: schedulingSelectedServicesSelector(state),
    menuItemsLoading: schedulingMenuItemsLoadingSelector(state),
    menuItemsLoadingError: schedulingMenuItemsLoadingErrorSelector(state),
    currentStep: schedulingCurrentStepSelector(state),
    mobileTechnicianEnabled: settingsDealershipMobileTechnicianEnabledSelector(state),
    isPickUp: schedulingIsPickUpSelector(state),
    isMobileTechnician: schedulingisMobileTechnicianSelector(state),
    availableRecalls: schedulingAvailableRecallsSelector(state),
    selectedRecalls: schedulingSelectedRecallsSelector(state),
    decisionTreeDetails: schedulingDecisionTreeResultsSelector(state),
  };
};

const actions = {
  makeServiceSelection: setServiceSelection,
  setSchedulingStep: setCurrentStep,
  retrieveDealershipMenuItems: fetchMenuItems,
  makeAdvisorSelection: setServiceAdvisor,
  retrieveDealershipRecalls: fetchRecalls,
};

export default connect(mapStateToProps, actions)(ChooseServices);
