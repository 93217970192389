import React from "react";
import cx from "classnames";
import { func, number, string } from "prop-types";

import "./styles.scss";

const Input = ({
  error,
  label,
  additionalInfo,
  inputClassName,
  maxLength,
  onChange,
  subtitle,
  ...props
}) => (
  <div className="conciergeInputWrapper">
    <div className="conciergeInputContainer">
      <span className="conciergeInputLabel">{label}</span>
      <span className="conciergeInputSubtitle">{subtitle}</span>
    </div>
    <input
      maxLength={maxLength}
      className={cx("conciergeInput", inputClassName)}
      onChange={(e) => onChange(e.target.value)}
      {...props}
    />
    <p className="conciergeInputAdditionalInfo">{additionalInfo}</p>
    <p className="conciergeInputError">{error}</p>
  </div>
);

Input.propTypes = {
  error: string,
  label: string,
  additionalInfo: string,
  maxLength: number,
  inputClassName: string,
  onChange: func,
  subtitle: string,
};

Input.defaultProps = {
  error: null,
  label: null,
  additionalInfo: null,
  maxLength: null,
  inputClassName: null,
  onChange: () => {},
  subtitle: null,
};

export default Input;
