import React from "react";
import {
  arrayOf,
  bool,
  node,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import WarningIcon from "assets/images/exclamation-triangle.svg";

import "./styles.scss";

export const CapitalisedCell = ({ value }) => (
  <div className="conciergeTableCapitalisedCell">
    {value}
  </div>
);

CapitalisedCell.propTypes = {
  value: string.isRequired,
};

export const StatusCell = ({ value }) => {
  const indicatorClasses = cx(
    "conciergeTableStatusIndicator",
    `conciergeTableStatusIndicator-${value}`,
  );
  return (
    <div className="conciergeTableStatusCell">
      <div className={indicatorClasses} />
      <div>{value}</div>
    </div>
  );
};

StatusCell.propTypes = {
  value: string.isRequired,
};

export const MenuTemplateStatusIconCell = ({ value }) => (
  <div className={cx("conciergeTableStatusIconCell", { isActive: value })}>
    <div className="conciergeTableStatusIcon__outer">
      <div className="conciergeTableStatusIcon__inner" />
    </div>
    <span>{value ? "Active" : "Inactive"}</span>
  </div>
);

export const AttributeStatusIconCell = ({ value }) => (
  <div className={cx("conciergeTableStatusIconCell", { enabled: value })}>
    <div className="conciergeTableStatusIcon__outer">
      <div className="conciergeTableStatusIcon__inner" />
    </div>
    <span>{value ? "Enabled" : "Disabled"}</span>
  </div>
);

MenuTemplateStatusIconCell.propTypes = {
  value: bool.isRequired,
};

AttributeStatusIconCell.propTypes = {
  value: bool.isRequired,
};

export const LinkCell = ({ value }) => (
  <div className="conciergeTableLinkCell">
    <Link to={value.href}>{value.text}</Link>
  </div>
);

LinkCell.propTypes = {
  value: shape({
    href: string.isRequired,
    text: string.isRequired,
  }).isRequired,
};

export const IdNumberCell = ({ value: { idNumber, withConcern } }) => {
  const indicatorClass = withConcern ? "conciergeTableIdNumberCellConcernIndicator" : "";
  return (
    <div className="conciergeTableIdNumberCell">
      <div>{idNumber}</div>
      <div className={indicatorClass} />
    </div>
  );
};

IdNumberCell.propTypes = {
  value: shape({
    idNumber: number.isRequired,
    withConcern: bool.isRequired,
  }).isRequired,
};

export const AppraisalRequestedCell = ({ value }) => {
  const appraisalRequestedText = value === true ? "Yes" : "-";
  const cellClassName = cx(
    "conciergeTableAppraisalRequestedCell",
    { conciergeTableAppraisalRequestedCellWithText: value },
  );

  return (
    <div className={cellClassName}>
      {appraisalRequestedText}
    </div>
  );
};

AppraisalRequestedCell.propTypes = {
  value: bool.isRequired,
};

export const CustomerCell = ({
  customer,
  vehicle: { make, model, model_year },
  vehicleImgUrl,
  currentStatus,
}) => (
  <div className="conciergeTableCustomerCell">
    <div>
      <div className={cx("customer", {
        canceled: currentStatus === "canceled",
      })}
      >
        {customer}
      </div>
      <div className="vehicle">
        {make ? `${model_year} ${make} ${model}` : "-"}
      </div>
    </div>
    {vehicleImgUrl && (
      <img
        src={vehicleImgUrl}
        alt="vehicle"
        className="vehicleImg"
      />
    )}
  </div>
);

CustomerCell.propTypes = {
  customer: string.isRequired,
  vehicle: shape({
    make: string.isRequired,
    model: string.isRequired,
    model_year: oneOfType([string, number]).isRequired,
  }).isRequired,
  vehicleImgUrl: string,
  currentStatus: string.isRequired,
};

CustomerCell.defaultProps = {
  vehicleImgUrl: "",
};

export const RepairOrderCell = ({
  repair_order_number,
  repair_order_tag,
  currentStatus,
}) => (
  <div className="conciergeTableRepairOrderCell">
    <div
      className={cx("repairOrder", {
        canceled: currentStatus === "canceled",
      })}
    >
      <div>
        {repair_order_number}
      </div>
      {repair_order_tag && (
        <div>
          Tag:
          {" "}
          {repair_order_tag}
        </div>
      )}
    </div>
  </div>
);

RepairOrderCell.propTypes = {
  repair_order_number: string,
  repair_order_tag: string,
  currentStatus: string.isRequired,
};

RepairOrderCell.defaultProps = {
  repair_order_number: null,
  repair_order_tag: null,
};

export const TeamCell = ({
  appointment_responsible_team,
  service_type,
  questionnaire_answers,
  currentStatus,
}) => (
  <div className={cx("conciergeTableTeamCell", {
    isMobileTechnician: service_type === "mobile_technician",
    questionnaireIsAnswered: questionnaire_answers && questionnaire_answers.length > 0,
    canceled: currentStatus === "canceled",
  })}
  >
    {
      appointment_responsible_team?.name
        ? appointment_responsible_team.name.toUpperCase()[0]
        : ""
    }
  </div>
);

TeamCell.propTypes = {
  appointment_responsible_team: string,
  service_type: string.isRequired,
  questionnaire_answers: arrayOf(node),
  currentStatus: string.isRequired,
};

TeamCell.defaultProps = {
  appointment_responsible_team: "",
  questionnaire_answers: null,
};

export const AdvisorCell = ({
  service_advisor_name, currentStatus,
}) => (
  <div className="conciergeTableAdvisorCell">
    <span className={cx("advisor", {
      canceled: currentStatus === "canceled",
    })}
    >
      {service_advisor_name}
    </span>
  </div>
);

AdvisorCell.propTypes = {
  service_advisor_name: string,
  currentStatus: string.isRequired,
};

AdvisorCell.defaultProps = {
  service_advisor_name: "",
};

export const MadeByCell = ({
  source,
  made_by_name,
  made_by_role,
  overcapacity,
  currentStatus,
}) => (
  <div className="conciergeTableMadeByCell">
    <div>
      {source && (
        <div className={cx({
          createdByNameOvercapacityLabel: overcapacity,
          source: true,
          canceled: currentStatus === "canceled",
        })}
        >
          <span>{source}</span>
        </div>
      )}
      {made_by_name && (
        <div className="createdByName">
          <div className={cx({
            createdByNameOvercapacityLabel: overcapacity,
            createdByNameCanceled: currentStatus === "canceled",
          })}
          >
            <span>{made_by_name}</span>
            {made_by_role && (
              <div className="createdByRole">
                <span>{made_by_role}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
);

MadeByCell.propTypes = {
  source: string,
  made_by_name: string,
  made_by_role: string,
  overcapacity: bool,
  currentStatus: string.isRequired,
};

MadeByCell.defaultProps = {
  source: null,
  made_by_name: null,
  made_by_role: string,
  overcapacity: false,
};

export const AppArrivedTimeCell = ({
  appTime,
  arrivedTime,
  hasWarning,
  isLate,
  currentStatus,
}) => (
  <div className="conciergeTableAppArrivedTimeCell">
    <div>
      <div className={cx("appTime", {
        error: isLate,
        canceled: currentStatus === "canceled",
      })}
      >
        {appTime}
      </div>
      <div className="arrivedTime">
        {arrivedTime}
      </div>
    </div>
    {hasWarning && (
      <img
        src={WarningIcon}
        alt=""
        className="conciergeTableAppArrivedTimeCellWarning"
      />
    )}
  </div>
);

AppArrivedTimeCell.propTypes = {
  appTime: oneOfType([string, number]).isRequired,
  isLate: bool.isRequired,
  hasWarning: bool.isRequired,
  arrivedTime: oneOfType([string, number]),
  currentStatus: string.isRequired,
};

AppArrivedTimeCell.defaultProps = {
  arrivedTime: "—",
};

export const ReportingssCell = ({
  total_formatted,
  total,
}) => (
  <div className="conciergeTableAppointmentsCell">
    <div>
      {`${total_formatted}%`}
    </div>
    <sup className="superscript">
      {total}
    </sup>
  </div>
);

ReportingssCell.propTypes = {
  total_formatted: number.isRequired,
  total: number.isRequired,
};
