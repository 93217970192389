import React from "react";
import Input from "components/common/Input";
import cx from "classnames";
import {
  bool, func, number, shape, string,
} from "prop-types";

import styles from "../styles.module.scss";

const daysOfWeek = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
};

const TeamCapacityInputs = ({
  isEditing,
  teamTag: {
    id,
    max_team_hours_per_weekday,
    mobile,
    max_waiter_appointments_per_day: waitersPerDay,
    max_waiter_appointments_per_hour: waitersPerHour,
  },
  onChange,
}) => {
  const hoursPerDayValue = (setting, value, day) => {
    return {
      ...setting,
      [day]: value,
    };
  };

  return (
    <>
      {Object.keys(daysOfWeek).map((item) => (
        <>
          <div className={styles.tableContent}>
            <span>{daysOfWeek[item]}</span>
          </div>
          <div className={styles.tableContent}>
            <Input
              inputClassName={cx(
                styles.itemInput,
                { [styles.invalid]: max_team_hours_per_weekday[item] === null },
              )}
              type="number"
              min="0"
              value={max_team_hours_per_weekday[item]}
              disabled={!isEditing}
              onChange={(value) => onChange(
                id,
                "max_team_hours_per_weekday",
                hoursPerDayValue(max_team_hours_per_weekday, value, item),
              )}
            />
          </div>
          <div className={styles.tableContent}>
            {!mobile && (
              <Input
                inputClassName={styles.itemInput}
                type="number"
                min="0"
                value={
                  waitersPerHour[item] || waitersPerHour[item] === 0 ? waitersPerHour[item] : ""
                }
                disabled={!isEditing}
                onChange={(value) => onChange(
                  id,
                  "max_waiter_appointments_per_hour",
                  hoursPerDayValue(waitersPerHour, value, item),
                  true,
                )}
              />
            )}
          </div>
          <div className={styles.tableContent}>
            {!mobile && (
              <Input
                inputClassName={styles.itemInput}
                type="number"
                min="0"
                value={
                  waitersPerDay[item] || waitersPerDay[item] === 0 ? waitersPerDay[item] : ""
                }
                disabled={!isEditing}
                onChange={(value) => onChange(
                  id,
                  "max_waiter_appointments_per_day",
                  hoursPerDayValue(waitersPerDay, value, item),
                  true,
                )}
              />
            )}
          </div>
        </>
      ))}
    </>
  );
};

TeamCapacityInputs.propTypes = {
  isEditing: bool,
  teamTag: shape({
    id: number,
    name: string,
    max_advisor_appointments_per_hour: number,
    max_advisor_appointments_per_day: number,
    max_team_hours_per_weekday: number,
  }),
  onChange: func,
};

TeamCapacityInputs.defaultProps = {
  isEditing: false,
  teamTag: null,
  onChange: () => {},
};

export default TeamCapacityInputs;
