import React from "react";
import { number, shape, string } from "prop-types";

import "./styles.scss";

const ClientDetailsBlock = ({ customer }) => (
  <section className="conciergeClientDetailsBlock">
    <div className="conciergeClientDetailsBlockGroup">
      <div className="conciergeClientDetailsBlockItem">
        <div className="conciergeClientDetailsBlockLabel">
          Customer
        </div>
        <div className="conciergeClientDetailsBlockValue">
          {customer?.first_name || customer?.last_name
            ? (`${customer.first_name || ""} ${customer.last_name || ""}`)
            : "-"}
        </div>
      </div>
      <div className="conciergeClientDetailsBlockItem">
        <div className="conciergeClientDetailsBlockLabel">
          Phone
        </div>
        <div className="conciergeClientDetailsBlockValue">
          {customer?.phone_number || "-"}
        </div>
      </div>
      <div className="conciergeClientDetailsBlockItem">
        <div className="conciergeClientDetailsBlockLabel">
          Email
        </div>
        <div className="conciergeClientDetailsBlockValue">
          {customer?.email || "-"}
        </div>
      </div>
    </div>
  </section>
);

ClientDetailsBlock.propTypes = {
  customer: shape({
    first_name: string,
    last_name: string,
    phone_number: number,
    email: string,
  }),
};

ClientDetailsBlock.defaultProps = {
  customer: null,
};

export default ClientDetailsBlock;
