import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import {
  arrayOf, bool, func, shape,
} from "prop-types";
import { isEmpty } from "ramda";
import { formatTimeInfoUS } from "shared/utils/datetime";
import {
  BOOKING_STEP_ADVISOR,
  BOOKING_STEP_DROP_OFF_DRIVER_CO_DRIVER,
  BOOKING_STEP_DROP_OFF_TIMESLOT,
  BOOKING_STEP_MOBILE_TECHNICIAN,
  BOOKING_STEP_PICK_UP_DRIVER_CO_DRIVER,
  BOOKING_STEP_REMOTE_SERVICE,
  BOOKING_STEP_SERVICES,
  BOOKING_STEP_TIMESLOT,
  BOOKING_STEP_TRANSPORT,
} from "shared/constants";
import {
  schedulingProcessJobReachable,
} from "store/selectors/scheduling-process-selectors";
import {
  settingsDataSelector,
  settingsDealershipMobileTechnicianEnabledSelector,
  settingsDealershipRemoteJobsEnabledSelector,
  settingsDealershipShowCoDriver,
} from "store/selectors/settings-selectors";
import {
  bookingDetailsChosenDropOffTimeSlotSelector,
  bookingDetailsChosenTimeSlotSelector,
  bookingDetailsDataSelector,
  bookingDetailsIsDropOffSelector,
  bookingDetailsIsPickUpSelector,
  bookingDetailsSelectedAdvisorSelector,
  bookingDetailsSelectedServicesSelector,
  bookingDetailsisMobileTechnicianSelector,
  bookingSelectedRecallsSelector,
} from "store/selectors/booking-details-selectors";
import { updateAppointment } from "store/actions/booking-details-actions";
import {
  countSelectedServicesByType,
} from "shared/utils/common";
import {
  chosenTimeSlotPropType,
  serviceAdvisorPropType,
  servicePropType,
} from "shared/prop-types";

import Accordion from "components/common/Accordion/BookingAccordion";
import AppointmentDetails from "components/common/AppointmentDetails/AppointmentDetailsBooking";
import ChooseServices from "components/common/BookingSteps/ChooseServices/ChooseServicesBooking";
import TimeOfArrival from "components/common/BookingSteps/TimeOfArrival/TimeOfArrivalBooking";
import ChooseAdvisor from "components/common/BookingSteps/ChooseAdvisor/ChooseAdvisorBooking";
import Transportation from "components/common/BookingSteps/Transportation/TransportationBooking";
import ChooseRemoteService from "components/common/Remote/ChooseRemoteService/ChooseRemoteServiceBooking";
import ChooseDrivers from "components/common/Remote/ChooseDrivers/ChooseDriversBooking";
import ChooseMobileTechnician from "components/common/Remote/ChooseMobileTechnician/ChooseMobileTechnicianBooking";
import LastAdvisor from "../BookingDetailsSummary/LastAdvisor";

import styles from "./styles.module.scss";

const ModifyBookingPage = ({
  selectedServices,
  selectedRecalls,
  chosenTimeSlot,
  chosenDropOffTimeSlot,
  selectedAdvisor,
  isPickUp,
  isDropOff,
  isMobileTechnician,
  jobReachable,
  remoteJobsEnabled,
  mobileTechnicianEnabled,
  showCoDriver,
  saveAppointment,
  booking: {
    customer,
    customerPickupAddress,
    jobs,
  },
  onClose,
  dealershipSettings,
}) => {
  const activeMobileTechnicianJob = jobs.find((item) => item.aasm_state !== "cancelled" && item.job_type === "mobile_technician") || {};
  const activePickUpJob = jobs.find((item) => item.aasm_state !== "cancelled" && item.job_type === "pickup") || {};
  const activeDropOffJob = jobs.find((item) => item.aasm_state !== "cancelled" && item.job_type === "dropoff") || {};

  const allServicesIsMobile = () => {
    let result = true;

    selectedServices.forEach((service) => {
      if (result) {
        result = service.team_tags
          ? service.team_tags.filter((team) => team.mobile).length > 0
          : false;
      }
    });

    selectedRecalls.forEach((recall) => {
      if (result) {
        result = recall.mobile_technician;
      }
    });

    return result;
  };

  const dynamicStep = () => {
    if (isPickUp) {
      return BOOKING_STEP_PICK_UP_DRIVER_CO_DRIVER;
    } if (isMobileTechnician) {
      return BOOKING_STEP_MOBILE_TECHNICIAN;
    }

    return BOOKING_STEP_TRANSPORT;
  };

  const customerDropoffAddressData = () => {
    const item = activeDropOffJob || activePickUpJob;

    return {
      address_city: item.address_city,
      address_line1: item.address_line1,
      address_line2: item.address_line2,
      address_state: item.address_state,
      address_zipcode: item.address_zipcode,
    };
  };

  const dynamicSection = () => {
    const isLoanerActive = () => dealershipSettings.integrations?.find((s) => s.kind === "loaner")?.active;
    if (isPickUp) {
      return (
        <>
          <Accordion
            title={`Pick-up Driver ${showCoDriver ? "/ Co-Driver" : ""}`}
            customClass={styles.accordion}
            step={BOOKING_STEP_PICK_UP_DRIVER_CO_DRIVER}
          >
            <ChooseDrivers
              selectedAdvisor={selectedAdvisor}
              chosenTimeSlot={chosenTimeSlot}
              jobId={activePickUpJob.id || null}
              driver={activePickUpJob.main_driver || {}}
              coDriver={activePickUpJob.co_driver || {}}
              prevNotes={activePickUpJob.notes || ""}
              nextStep={isDropOff ? BOOKING_STEP_DROP_OFF_TIMESLOT : BOOKING_STEP_TRANSPORT}
            />
          </Accordion>
          {isDropOff && (
            <>
              <Accordion
                title="Drop-off Time"
                customClass={styles.accordion}
                selectedValue={
                  !isEmpty(chosenDropOffTimeSlot) && formatTimeInfoUS(chosenDropOffTimeSlot)
                }
                step={BOOKING_STEP_DROP_OFF_TIMESLOT}
              >
                <TimeOfArrival
                  selectedAdvisor={selectedAdvisor}
                  nextStep={BOOKING_STEP_DROP_OFF_DRIVER_CO_DRIVER}
                  dropOffType
                />
              </Accordion>
              <Accordion
                title={`Drop-off Driver ${showCoDriver ? "/ Co-Driver" : ""}`}
                customClass={styles.accordion}
                step={BOOKING_STEP_DROP_OFF_DRIVER_CO_DRIVER}
              >
                <ChooseDrivers
                  selectedAdvisor={selectedAdvisor}
                  chosenTimeSlot={chosenDropOffTimeSlot}
                  jobId={activeDropOffJob.id || null}
                  driver={activeDropOffJob.main_driver || {}}
                  coDriver={activeDropOffJob.co_driver || {}}
                  prevNotes={activeDropOffJob.notes || ""}
                  dropOffType
                  nextStep={BOOKING_STEP_TRANSPORT}
                />
              </Accordion>
            </>
          )}
          {isLoanerActive() && (
            <Accordion
              title="Transportation"
              step={BOOKING_STEP_TRANSPORT}
            >
              <Transportation />
            </Accordion>
          )}
        </>
      );
    } if (isMobileTechnician) {
      return (
        <Accordion
          title="Mobile Technician"
          customClass={styles.accordion}
          step={BOOKING_STEP_MOBILE_TECHNICIAN}
        >
          <ChooseMobileTechnician
            selectedAdvisor={selectedAdvisor}
            chosenTimeSlot={chosenTimeSlot}
            mobileTechnician={activeMobileTechnicianJob.technician || {}}
            prevNotes={activeMobileTechnicianJob.notes || ""}
            jobId={activeMobileTechnicianJob.id || null}
          />
        </Accordion>
      );
    }

    return (
      <Accordion
        title="Transportation"
        step={BOOKING_STEP_TRANSPORT}
      >
        <Transportation />
      </Accordion>
    );
  };

  const customerPickUpAddressData = () => {
    const item = isPickUp ? activePickUpJob : activeMobileTechnicianJob;

    return {
      address_city: item.address_city,
      address_line1: item.address_line1,
      address_line2: item.address_line2,
      address_state: item.address_state,
      address_zipcode: item.address_zipcode,
    };
  };

  return (
    <section className={styles.container}>
      <section className={styles.choicePanel}>
        <Accordion
          title="Choose services"
          customClass={styles.accordion}
          concernCount={countSelectedServicesByType(
            "concern",
            selectedServices,
          )}
          maintenanceCount={countSelectedServicesByType(
            "maintenance",
            selectedServices,
          )}
          step={BOOKING_STEP_SERVICES}
        >
          <ChooseServices
            nextStep={(remoteJobsEnabled || mobileTechnicianEnabled)
              ? BOOKING_STEP_REMOTE_SERVICE
              : BOOKING_STEP_ADVISOR}
          />
        </Accordion>
        {(remoteJobsEnabled || mobileTechnicianEnabled) && (
          <Accordion
            title="Customer requests remote service"
            customClass={cx(styles.accordion, {
              [styles.accordionAlert]: (jobReachable !== null && !jobReachable)
                || (isMobileTechnician && !allServicesIsMobile()),
            })}
            step={BOOKING_STEP_REMOTE_SERVICE}
          >
            <ChooseRemoteService
              isPickUp={isPickUp}
              isDropOff={isDropOff}
              isMobileTechnician={isMobileTechnician}
              remoteJobsEnabled={remoteJobsEnabled}
              mobileTechnicianEnabled={mobileTechnicianEnabled}
              allServicesIsMobile={allServicesIsMobile()}
              customerPickupAddress={activePickUpJob.address
                  || activeMobileTechnicianJob.address
                  || customerPickupAddress}
              customerPickupAddressData={customerPickUpAddressData()}
              pickUpGeolocation={
                activePickUpJob.geolocation || activeMobileTechnicianJob.geolocation
              }
              customerDropoffAddress={
                activeDropOffJob.address || activePickUpJob.address || customerPickupAddress
              }
              customerDropoffAddressData={customerDropoffAddressData()}
              dropOffGeolocation={activeDropOffJob.geolocation || activePickUpJob.geolocation}
              zone={activeMobileTechnicianJob.location_area || activePickUpJob.location_area}
              dropOffZone={activeDropOffJob.location_area || null}
              ignoreDistance={
                activePickUpJob.distance_limit_ignored
                || activeDropOffJob.distance_limit_ignored
                || activeMobileTechnicianJob.distance_limit_ignored
              }
              selectedAdvisor={selectedAdvisor}
            />
          </Accordion>
        )}
        {!(isPickUp && isMobileTechnician) && (
          <Accordion
            title="Service Advisor"
            step={BOOKING_STEP_ADVISOR}
            selectedValue={selectedAdvisor?.is_phantom ? "First Available" : selectedAdvisor?.name}
            customClass={cx(styles.accordion, {
              [styles.accordionHide]: isPickUp || isMobileTechnician,
            })}
          >
            <ChooseAdvisor
              preselectedAdvisor={selectedAdvisor}
              isMobileTechnician={isMobileTechnician}
            />
          </Accordion>
        )}
        <Accordion
          title={`${isPickUp ? "Pick-up Time" : "Time of Arrival"}`}
          customClass={styles.accordion}
          selectedValue={!isEmpty(chosenTimeSlot) && formatTimeInfoUS(chosenTimeSlot)}
          step={BOOKING_STEP_TIMESLOT}
        >
          <TimeOfArrival
            selectedAdvisor={selectedAdvisor?.is_phantom ? null : selectedAdvisor}
            nextStep={dynamicStep()}
          />
        </Accordion>
        {dynamicSection()}
      </section>
      <div>
        <Accordion
          expandable={false}
          title={isPickUp ? "Pick-up details" : "Appointment details"}
          customClass={styles.summary}
        >
          <AppointmentDetails
            saveButtonLabel="Save changes"
            onSave={() => {
              saveAppointment();
              onClose();
            }}
          />
        </Accordion>
        <div className={styles.lastAdvisor}>
          {customer?.last_visit_advisor && customer?.last_visited_at && (
            <LastAdvisor customer={customer} />
          )}
        </div>
      </div>
    </section>
  );
};

ModifyBookingPage.propTypes = {
  saveAppointment: func.isRequired,
  selectedServices: arrayOf(servicePropType),
  chosenTimeSlot: chosenTimeSlotPropType.isRequired,
  chosenDropOffTimeSlot: chosenTimeSlotPropType,
  selectedAdvisor: serviceAdvisorPropType,
  isPickUp: bool.isRequired,
  isDropOff: bool.isRequired,
  isMobileTechnician: bool.isRequired,
  selectedRecalls: arrayOf(servicePropType),
  jobReachable: bool,
  remoteJobsEnabled: bool.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  booking: shape.isRequired,
  customer: shape.isRequired,
  customerPickupAddress: shape.isRequired,
  showCoDriver: bool.isRequired,
  onClose: func.isRequired,
  dealershipSettings: shape.isRequired,
};

ModifyBookingPage.defaultProps = {
  selectedServices: [],
  chosenDropOffTimeSlot: {},
  selectedAdvisor: {},
  selectedRecalls: [],
  jobReachable: null,
};

const mapStateToProps = (state) => {
  return {
    booking: bookingDetailsDataSelector(state),
    selectedRecalls: bookingSelectedRecallsSelector(state),
    selectedServices: bookingDetailsSelectedServicesSelector(state),
    chosenTimeSlot: bookingDetailsChosenTimeSlotSelector(state),
    chosenDropOffTimeSlot: bookingDetailsChosenDropOffTimeSlotSelector(state),
    selectedAdvisor: bookingDetailsSelectedAdvisorSelector(state),
    isPickUp: bookingDetailsIsPickUpSelector(state),
    isDropOff: bookingDetailsIsDropOffSelector(state),
    isMobileTechnician: bookingDetailsisMobileTechnicianSelector(state),
    jobReachable: schedulingProcessJobReachable(state),
    remoteJobsEnabled: settingsDealershipRemoteJobsEnabledSelector(state),
    mobileTechnicianEnabled: settingsDealershipMobileTechnicianEnabledSelector(state),
    showCoDriver: settingsDealershipShowCoDriver(),
    dealershipSettings: settingsDataSelector(state),
  };
};

const actions = {
  saveAppointment: updateAppointment,
};

export default connect(mapStateToProps, actions)(ModifyBookingPage);
