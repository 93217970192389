import React, { useState } from "react";
import {
  arrayOf, bool, func, number, oneOfType, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import InlineSVG from "react-inlinesvg";
import Carousel from "nuka-carousel";

import { nextDecisionTreeStep } from "store/actions/checkin-decision-trees-actions";
import Button from "components/common/checkin/Button";

import leftIcon from "assets/icons/left.svg";
import rightIcon from "assets/icons/right.svg";
import styles from "./styles.module.scss";

const DecisionTreeStepInput = ({
  serviceId, answers, onSubmitAnswer, isComplete,
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const canSlideLeft = () => carouselIndex > 0;

  const canSlideRight = () => carouselIndex < answers.length - 1;

  const slideLeft = () => {
    if (canSlideLeft()) {
      setCarouselIndex(carouselIndex - 1);
    }
  };

  const slideRight = () => {
    if (canSlideRight()) {
      setCarouselIndex(carouselIndex + 1);
    }
  };

  if (isComplete || !answers) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <button
            type="button"
            onClick={slideLeft}
            className={cx(styles.navigationButton, {
              [styles.disabled]: !canSlideLeft(),
            })}
          >
            <InlineSVG src={leftIcon} />
          </button>
          <button
            type="button"
            onClick={slideRight}
            className={cx(styles.navigationButton, {
              [styles.disabled]: !canSlideRight(),
            })}
          >
            <InlineSVG src={rightIcon} />
          </button>
        </div>
        <Carousel
          className={styles.carousel}
          slideIndex={carouselIndex}
          afterSlide={(newIndex) => setCarouselIndex(newIndex)}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          renderBottomCenterControls={null}
          slideWidth="150px"
          framePadding="15px 0"
          cellSpacing={10}
          height="auto"
        >
          {answers.map((answer) => (
            <div style={{ padding: "4px 0" }}>
              <Button
                key={answer.text + answer.goto}
                caption={answer.text}
                onClick={() => {
                  onSubmitAnswer(serviceId, answer.goto, answer.text);
                  setCarouselIndex(0);
                }}
                isSecondary
                isWide
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

DecisionTreeStepInput.propTypes = {
  serviceId: number,
  answers: arrayOf(
    shape({
      text: string,
      goto: oneOfType([string, number]),
    }),
  ),
  onSubmitAnswer: func.isRequired,
  isComplete: bool,
};

DecisionTreeStepInput.defaultProps = {
  serviceId: null,
  answers: null,
  isComplete: false,
};

const actions = {
  onSubmitAnswer: nextDecisionTreeStep,
};

const DecisionTreeStepInputContainer = connect(null, actions)(DecisionTreeStepInput);

export default DecisionTreeStepInputContainer;
