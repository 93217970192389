import React, { useState } from "react";
import { arrayOf } from "prop-types";
import ReactTable from "react-table-6";

import { settingsUserProptype } from "shared/prop-types";
import Panel from "components/common/Panel";
import Button from "components/common/Button";
import DealershipMaintainersActionCell from "components/common/ReactTableElements/cellRenderers/DealershipMaintainersActionCell";
import AddMaintainerModal from "./AddMaintainerModal";

import styles from "../styles.module.scss";

const DealershipMaintainersPanel = ({
  maintainers,
}) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [openedPopupId, setOpenedPopupId] = useState(null);

  const updatePopupId = (popupId) => {
    setOpenedPopupId(openedPopupId === popupId ? null : popupId);
  };

  const isDeleteActionDisabled = maintainers.length === 1;

  const idCell = (props) => {
    const updatedProps = {
      ...props,
      openPopup: updatePopupId,
      closePopup: () => setOpenedPopupId(null),
      openedPopupId,
      deleteDisabled: isDeleteActionDisabled,
    };
    return <DealershipMaintainersActionCell {...updatedProps} />;
  };

  const columns = [
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "",
      accessor: "id",
      Cell: idCell,
    },
  ];

  return (
    <Panel
      header="Dealership Maintainers"
      className={styles.maintainers}
    >
      <ReactTable
        columns={columns}
        data={maintainers}
        showPagination={false}
        minRows={1}
      />
      <div className={styles.buttons}>
        <Button
          variant="dark"
          onClick={() => setCreateModalOpen(true)}
        >
          + Add new maintainer
        </Button>
      </div>
      {createModalOpen && (
        <AddMaintainerModal onClose={() => setCreateModalOpen(false)} />
      )}
    </Panel>
  );
};

DealershipMaintainersPanel.propTypes = {
  maintainers: arrayOf(settingsUserProptype),
};

DealershipMaintainersPanel.defaultProps = {
  maintainers: [],
};

export default DealershipMaintainersPanel;
