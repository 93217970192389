import { createDealership, fetchDealerships } from "shared/api";
import { SET_TABLE_OPTIONS } from "store/reducers/table-options-reducer";
import { authTokenSelector } from "store/selectors/auth-selectors";

export const DEALERSHIPS_SET_ADDING_INITIAL_STATE = "DEALERSHIPS_SET_ADDING_INITIAL_STATE";
export const DEALERSHIPS_SET_LOADING_STATE = "DEALERSHIPS_SET_LOADING_STATE";
export const DEALERSHIPS_SET_ADDING_LOADING_STATE = "DEALERSHIPS_SET_ADDING_LOADING_STATE";
export const DEALERSHIPS_FETCH_SUCCESS = "DEALERSHIPS_FETCH_SUCCESS";
export const DEALERSHIPS_FETCH_FAILURE = "DEALERSHIPS_FETCH_FAILURE";
export const DEALERSHIPS_ADD_SUCCESS = "DEALERSHIPS_ADD_SUCCESS";
export const DEALERSHIPS_ADD_FAILURE = "DEALERSHIPS_ADD_FAILURE";

const setLoadingState = () => {
  return {
    type: DEALERSHIPS_SET_LOADING_STATE,
    payload: {},
  };
};

export const retrieveDealerships = (token, params = {}) => (dispatch) => {
  dispatch(setLoadingState());

  return fetchDealerships(token, params)
    .then((response) => {
      dispatch({
        type: SET_TABLE_OPTIONS,
        payload: Object.assign(response.headers, params),
      });
      dispatch({
        type: DEALERSHIPS_FETCH_SUCCESS,
        payload: response.data,
      });
    })
    .catch(({ errors }) => dispatch({
      type: DEALERSHIPS_FETCH_FAILURE,
      payload: { error: errors },
    }));
};

export const setAddingInitialState = () => (dispatch) => {
  dispatch({
    type: DEALERSHIPS_SET_ADDING_INITIAL_STATE,
    payload: {},
  });
};

export const addDealership = (dealershipData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  dispatch({
    type: DEALERSHIPS_SET_ADDING_LOADING_STATE,
    payload: {},
  });

  return createDealership(dealershipData, token)
    .then((response) => (
      dispatch({
        type: DEALERSHIPS_ADD_SUCCESS,
        payload: response,
      })
    ))
    .catch((error) => (
      dispatch({
        type: DEALERSHIPS_ADD_FAILURE,
        payload: { error },
      })
    ));
};
