import {
  APP_FETCH_CDK_MODEL_CODE_BY_VIN_SUCCESS,
  APP_FETCH_MAKE_MODEL_YEAR_MAP,
  APP_FETCH_MAKE_MODEL_YEAR_MAP_FAILURE,
  APP_FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS,
  APP_FETCH_VEHICLE_SET_BY_VIN,
  APP_FETCH_VEHICLE_SET_BY_VIN_FAILURE,
  APP_FETCH_VEHICLE_SET_BY_VIN_SUCCESS,
  APP_RESET_VEHICLE_SET_BY_VIN,
  APP_SWITCH_SHOW_QR,
  APP_SWITCH_SHOW_REPORTS,
} from "store/actions/app-actions";
import { AUTH_AUTHENTICATION_SUCCESS, AUTH_LOG_OUT } from "store/actions/auth-actions";
import { MENU_TEMPLATE_FETCH_OP_CODES_SUCCESS } from "store/actions/menu-template-actions";

const initialState = {
  dealershipId: sessionStorage.getItem("CONCIERGE_DEALERSHIP"),
  makeModelYearMap: null,
  makeModelYearMapLoading: false,
  makeModelYearMapError: null,
  vehicleSetByVin: null,
  vehicleSetByVinLoading: false,
  vehicleSetByVinError: null,
  cdkModelCodeByVin: null,
  opCodeMap: null,
  showReports: false,
  showQR: false,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        dealershipId: action.payload.dealershipId,
      };
    case APP_FETCH_MAKE_MODEL_YEAR_MAP:
      return {
        ...state,
        makeModelYearMapLoading: true,
        makeModelYearMapError: null,
      };
    case APP_FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS:
      return {
        ...state,
        makeModelYearMap: action.payload,
        makeModelYearMapLoading: false,
      };
    case APP_FETCH_MAKE_MODEL_YEAR_MAP_FAILURE:
      return {
        ...state,
        makeModelYearMapLoading: false,
        makeModelYearMapError: action.payload,
      };
    case MENU_TEMPLATE_FETCH_OP_CODES_SUCCESS:
      return {
        ...state,
        opCodeMap: action.payload,
        opCodeMapLoading: false,
      };
    case APP_FETCH_VEHICLE_SET_BY_VIN:
      return {
        ...state,
        vehicleSetByVinLoading: true,
        vehicleSetByVinError: null,
      };
    case APP_RESET_VEHICLE_SET_BY_VIN:
      return {
        ...state,
        cdkModelCodeByVin: null,
        vehicleSetByVin: null,
        vehicleSetByVinLoading: false,
        vehicleSetByVinError: null,
      };
    case APP_FETCH_VEHICLE_SET_BY_VIN_SUCCESS:
      return {
        ...state,
        vehicleSetByVin: action.payload,
        vehicleSetByVinLoading: false,
      };
    case APP_FETCH_VEHICLE_SET_BY_VIN_FAILURE:
      return {
        ...state,
        vehicleSetByVinLoading: false,
        vehicleSetByVinError: action.payload,
      };
    case APP_FETCH_CDK_MODEL_CODE_BY_VIN_SUCCESS: {
      return {
        ...state,
        cdkModelCodeByVin: action.payload?.model_code,
      };
    }
    case AUTH_LOG_OUT:
      return {
        ...state,
        dealershipId: null,
      };
    case APP_SWITCH_SHOW_REPORTS:
      return {
        ...state,
        showReports: !state.showReports,
        showQR: !state.showReports ? false : state.showQR,
      };
    case APP_SWITCH_SHOW_QR:
      return {
        ...state,
        showQR: !state.showQR,
        showReports: !state.showQR ? false : state.showReports,
      };
    default:
      return state;
  }
};

export default app;
