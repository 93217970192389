import {
  BOOKINGS_CLEAR_FETCH_INTERVAL,
  BOOKINGS_FETCH_DEALERSHIP_CAPACITY,
  BOOKINGS_FETCH_DEALERSHIP_CAPACITY_FAILURE,
  BOOKINGS_FETCH_DEALERSHIP_CAPACITY_SUCCESS,
  BOOKINGS_FETCH_FAILURE,
  BOOKINGS_FETCH_SUCCESS,
  BOOKINGS_FETCH_TEAM_TAGS_CAPACITY_SUCCESS,
  BOOKINGS_LOCK_DAY_FAILURE,
  BOOKINGS_LOCK_DAY_SUCCESS,
  BOOKINGS_RESET_TIMELINE_FILTERS,
  BOOKINGS_SET_ADDITIONAL_INFO_FILTER,
  BOOKINGS_SET_FETCH_INTERVAL,
  BOOKINGS_SET_LOADING_STATE,
  BOOKINGS_SET_PERIOD_AND_TIMELINE_FILTERS,
  BOOKINGS_SET_PERIOD_FILTER,
  BOOKINGS_SET_SEARCH_STRING,
  BOOKINGS_SET_TIMELINE_FILTER,
  BOOKINGS_UNLOCK_DAY_SUCCESS,
  BOOKINGS_UNLOCK_DAY__FAILURE,
} from "store/actions/bookings-actions";

const initialState = {
  isLoading: false,
  requestId: 0,
  intervalTimerId: null,
  data: [],
  error: null,
  timelineFilters: {
    today: true,
    past: false,
  },
  additionalInfoFilters: {
    jobStatus: {
      assigned: null,
      en_route_to_customer: null,
      arrived_to_customer: null,
      en_route_to_dealership: null,
    },
    bookingStatus: {
      not_checked_in: null,
      checked_in: null,
      arrived: null,
    },
    transportationStatus: {
      loaner: null,
      lyft: null,
      uber: null,
      shuttle: null,
    },
    client_waiting: null,
    with_concern: null,
    appraisal_requested: null,
    with_recall: null,
    service_advisor: {},
    appointmentStatus: {
      open: null,
      arrived: null,
      canceled: null,
      not_shown: null,
    },
  },
  periodFilters: {
    from: null,
    to: null,
  },
  free_text_search: "",
  dealershipCapacity: {
    max_actual_labor_time: 0,
    allocated_capacity: 0,
    max_overcapacity_time: "0.0",
  },
  teamTagsCapacity: [],
  dealershipHoursCapacity: [],
  dealershipAppointmentsCapacity: [],
  allAdvisorsCapacity: [],
  isDealershipCapacityLoading: true,
  isTeamTagsCapacityLoading: true,
};

const bookings = (state = initialState, action) => {
  switch (action.type) {
    case BOOKINGS_SET_LOADING_STATE:
      return {
        ...state,
        requestId: action.meta.requestId,
        isLoading: true,
      };
    case BOOKINGS_SET_FETCH_INTERVAL: {
      return {
        ...state,
        intervalTimerId: action.payload,
      };
    }
    case BOOKINGS_CLEAR_FETCH_INTERVAL: {
      return {
        ...state,
        intervalTimerId: null,
      };
    }
    case BOOKINGS_SET_TIMELINE_FILTER:
      return {
        ...state,
        periodFilters: {
          from: null,
          to: null,
        },
        timelineFilters: {
          ...state.timelineFilters,
          ...action.payload,
        },
      };
    case BOOKINGS_FETCH_DEALERSHIP_CAPACITY:
      return {
        ...state,
        isDealershipCapacityLoading: true,
      };
    case BOOKINGS_FETCH_DEALERSHIP_CAPACITY_SUCCESS: {
      const {
        advisors,
        dealershipHoursCapacity,
        dealershipAppointmentsCapacity,
      } = action.payload;

      return {
        ...state,
        dealershipHoursCapacity,
        dealershipAppointmentsCapacity,
        allAdvisorsCapacity: advisors,
        isDealershipCapacityLoading: false,
      };
    }
    case BOOKINGS_FETCH_DEALERSHIP_CAPACITY_FAILURE:
      return {
        ...state,
        isDealershipCapacityLoading: false,
      };
    case BOOKINGS_FETCH_TEAM_TAGS_CAPACITY_SUCCESS:
      return {
        ...state,
        teamTagsCapacity: action.payload,
        isTeamTagsCapacityLoading: false,
      };
    case BOOKINGS_SET_ADDITIONAL_INFO_FILTER:
      return {
        ...state,
        additionalInfoFilters: {
          ...state.additionalInfoFilters,
          ...action.payload,
        },
      };
    case BOOKINGS_RESET_TIMELINE_FILTERS:
      return {
        ...state,
        timelineFilters: {
          current: false,
          past: false,
        },
      };
    case BOOKINGS_SET_PERIOD_FILTER:
      return {
        ...state,
        periodFilters: {
          ...state.periodFilters,
          ...action.payload,
        },
      };
    case BOOKINGS_SET_PERIOD_AND_TIMELINE_FILTERS:
      return {
        ...state,
        periodFilters: {
          from: action.payload.from,
          to: action.payload.to,
          rawTo: action.payload.rawTo,
        },
        timelineFilters: {
          ...state.timelineFilters,
          ...action.payload.timelineFilters,
        },
      };
    case BOOKINGS_SET_SEARCH_STRING:
      return {
        ...state,
        free_text_search: action.payload,
      };
    case BOOKINGS_FETCH_SUCCESS:
      if (action.meta.requestId !== state.requestId) {
        return state;
      }
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };

    case BOOKINGS_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload.error.response.data,
        isLoading: false,
      };
    case BOOKINGS_LOCK_DAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teamTagsCapacity: state.teamTagsCapacity.map((item) => {
          if (item.team_tag_id === action.payload.team_tag_id) {
            return {
              ...item,
              locked: true,
            };
          }

          return item;
        }),
      };
    case BOOKINGS_LOCK_DAY_FAILURE:
      return {
        ...state,
        error: action.payload.error.response.data,
        isLoading: false,
      };
    case BOOKINGS_UNLOCK_DAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teamTagsCapacity: state.teamTagsCapacity.map((item) => {
          if (item.team_tag_id === action.payload.team_tag_id) {
            return {
              ...item,
              locked: false,
            };
          }

          return item;
        }),
      };
    case BOOKINGS_UNLOCK_DAY__FAILURE:
      return {
        ...state,
        error: action.payload.error.response.data,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default bookings;
