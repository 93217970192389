import React, { useEffect, useState } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";
import Switch from "react-switch";
import { formatName } from "shared/utils/common";
import {
  integrationPropType,
} from "shared/prop-types";
import {
  activeAdvisorsSelector,
  settingsDispatchCodesSelector,
} from "store/selectors/settings-selectors";
import { updateLoanerIntegrationOptions } from "store/actions/settings-actions";
import HeaderEdit from "components/common/HeaderEdit";
import Input from "components/common/Input";
import Accordion from "components/common/Accordion";
import ActivitySwitch from "../ActivitySwitch";

import styles from "../styles.module.scss";

const LoanerIntegration = ({
  integration: {
    active,
    settings,
    id,
    kind,
  },
  updateIntegrationHandler,
}) => {
  const [loanerSettings, setLoanerSettings] = useState({});
  const [isActive, setIsActive] = useState(active);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setLoanerSettings(settings);
  }, [settings]);

  const save = () => {
    const params = {
      settings: loanerSettings,
      active: isActive,
    };

    updateIntegrationHandler(id, params);
    setIsEditing(false);
  };

  const cancelChanges = () => {
    setLoanerSettings({ ...settings });
    setIsActive(active);
    setIsEditing(false);
  };

  return (
    <Accordion
      title={formatName(kind)}
      customClass={styles.accordion}
    >
      <section className={styles.container}>
        <ActivitySwitch
          isEditing={isEditing}
          active={isActive}
          onChange={() => setIsActive(!isActive)}
        />
        <section className={styles.inputsContainer}>
          {Object.keys(settings).map((keyName) => (
            keyName !== "both_conditions_required"
          ) && (
            <Input
              label={
                keyName === "min_appointment_duration"
                  ? "Minimum appointment duration (minutes)"
                  : formatName(keyName)
              }
              value={loanerSettings[keyName]}
              onChange={(value) => setLoanerSettings((prev) => {
                return {
                  ...prev,
                  [keyName]: value,
                };
              })}
              key={keyName}
              type="number"
              min={0}
              disabled={!isEditing}
            />
          ))}
          <div className={styles.switch}>
            <label
              className={styles.label}
              htmlFor="remote-switch"
            >
              {formatName("both_conditions_required")}
            </label>
            <Switch
              id="remote-switch"
              className={styles.toggle}
              onChange={() => setLoanerSettings((prev) => {
                return {
                  ...prev,
                  both_conditions_required: !prev.both_conditions_required,
                };
              })}
              checked={loanerSettings.both_conditions_required}
              disabled={!isEditing}
              onColor="#36af5e"
              offColor="#dedee0"
              activeBoxShadow="0 0 2px 3px #0bcaf9"
              aria-labelledby="loaner-requirements-label"
            />
          </div>
        </section>
      </section>
      <HeaderEdit
        isEditing={isEditing}
        handleCancel={cancelChanges}
        handleSave={save}
        handleEdit={() => setIsEditing(true)}
      />
    </Accordion>
  );
};

LoanerIntegration.propTypes = {
  integration: integrationPropType.isRequired,
  updateIntegrationHandler: func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    serviceAdvisors: activeAdvisorsSelector(state),
    dispatchCodes: settingsDispatchCodesSelector(state),
  };
};

const actions = {
  updateIntegrationHandler: updateLoanerIntegrationOptions,
};

export default connect(mapStateToProps, actions)(LoanerIntegration);
