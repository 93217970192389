import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, node, number, oneOf, oneOfType, string,
} from "prop-types";
import { photoPropType, workingHoursPropType } from "shared/prop-types";
import menuIcon from "assets/images/menu.svg";
import editIcon from "assets/images/edit.svg";
import vacation from "assets/images/vacation.svg";
import deleteIcon from "assets/images/delete.svg";
import deactivateIcon from "assets/images/deactivate.svg";
import { DEFAULT_WORKING_AREAS } from "shared/constants";

import Modal from "components/common/Modal";
import VacationsModalAdvisors from "components/common/VacationsModal/VacationsModalAdvisors";
import VacationsModalDrivers from "components/common/VacationsModal/VacationsModalDrivers";
import VacationsModalMobileTechnicians from "components/common/VacationsModal/VacationsModalMobileTechnicians";
import EditEmployeeModal from "./EditEmployeeModal";

const EmployeeSettingsActionCell = ({
  type,
  id,
  isActive,
  name,
  availability,
  photo,
  phoneNumber,
  employeeNumber,
  employmentId,
  workingHours,
  employeeTeamTags,
  printerNumber,
  printerCopies,
  updateEmployee,
  closePopup,
  openedPopupId,
  openPopup,
  removeEmployee,
}) => {
  const [label, setLabel] = useState("");
  const [editEmployeeModalOpen, setEditEmployeeModalOpen] = useState(false);
  const [deleteEmployeeModalOpen, setDeleteEmployeeModalOpen] = useState(false);
  const [vacationsModalOpen, setVacationsModalOpen] = useState(false);

  useEffect(() => {
    if (type === "advisor") {
      setLabel("Advisor");
    }

    if (type === "driver") {
      setLabel("Driver");
    }

    if (type === "mobile") {
      setLabel("Mobile Technicians");
    }
  }, [type]);

  const toggleActive = () => {
    updateEmployee(id, {
      active: !isActive,
      working_areas: DEFAULT_WORKING_AREAS,
    });
    closePopup();
  };

  const editEmployee = () => {
    setEditEmployeeModalOpen(true);
    closePopup();
  };

  const deleteEmployeeModal = () => {
    setDeleteEmployeeModalOpen(true);
    closePopup();
  };

  const editVacations = () => {
    setVacationsModalOpen(true);
    closePopup();
  };

  const updateEmployeeData = (
    employeeName,
    employeePhoneNumber,
    newPhoto,
    shouldDeletePhoto,
    updatedHours,
    employeeAvailability,
    employeeTeamTagsValue,
    printerNumberValue,
    printerCopiesValue,
    email,
  ) => {
    const dataToSend = new FormData();

    if (type === "advisor") {
      dataToSend.append("availability", employeeAvailability);
      dataToSend.append("team_tag_ids", employeeTeamTagsValue);

      if (printerNumberValue !== null) {
        dataToSend.append("printer_number", printerNumberValue);
      }
      if (printerCopiesValue !== null) {
        dataToSend.append("printer_copies", printerCopiesValue);
      }
    }

    if (type === "driver" || type === "mobile") {
      dataToSend.append("name", employeeName);
      dataToSend.append("phone_number", employeePhoneNumber);
    }

    dataToSend.append("working_hours", JSON.stringify(updatedHours));

    if (newPhoto) {
      dataToSend.append("photo", newPhoto);
    } else if (shouldDeletePhoto) {
      dataToSend.append("remove_photo", true);
    }

    dataToSend.append("user_id", email?.value || "");

    updateEmployee(id, dataToSend);
  };

  const deleteEmployee = () => {
    removeEmployee(id);
    setDeleteEmployeeModalOpen(false);
  };

  const vacationModal = () => {
    if (type === "advisor") {
      return (
        <VacationsModalAdvisors
          key={id}
          name={name}
          employeeNumber={employeeNumber}
          employeeId={employmentId}
          onClose={() => setVacationsModalOpen(false)}
        />
      );
    }

    if (type === "driver") {
      return (
        <VacationsModalDrivers
          key={id}
          name={name}
          employeeNumber={employeeNumber}
          employeeId={employmentId}
          onClose={() => setVacationsModalOpen(false)}
        />
      );
    }

    if (type === "mobile") {
      return (
        <VacationsModalMobileTechnicians
          key={id}
          name={name}
          employeeNumber={employeeNumber}
          employeeId={employmentId}
          onClose={() => setVacationsModalOpen(false)}
        />
      );
    }
  };

  return (
    <div className="conciergeTableActionsCell">
      <button
        type="button"
        className="conciergeTableActionsCellButton"
        onClick={() => openPopup(id)}
      >
        <img alt="actions" src={menuIcon} />
      </button>
      {openedPopupId === id && (
        <>
          <div className="conciergeTableActionsCellPopup">
            <button
              type="button"
              className="conciergeTableActionsCellAction"
              onClick={() => editEmployee()}
            >
              <img alt="edit" src={editIcon} />
              Edit
            </button>
            {((type === "advisor" && employmentId) || type !== "advisor") && (
              <button
                type="button"
                className="conciergeTableActionsCellAction"
                onClick={() => editVacations()}
              >
                <img alt="edit" src={vacation} />
                Vacations
              </button>
            )}
            <button
              type="button"
              className="conciergeTableActionsCellAction"
              onClick={toggleActive}
            >
              <img alt="deactivate" src={deactivateIcon} />
              {isActive ? "Deactivate" : "Activate"}
            </button>
            {type === "driver" && (
              <button
                type="button"
                className="conciergeTableActionsCellAction conciergeTableActionsCellDelete"
                onClick={deleteEmployeeModal}
              >
                <img alt="delete" src={deleteIcon} />
                Delete
              </button>
            )}
          </div>
          <button
            type="button"
            className="conciergeTableActionsCellOutsideOverlay"
            onClick={closePopup}
          />
        </>
      )}
      {editEmployeeModalOpen && (
        <EditEmployeeModal
          type={type}
          name={name}
          availability={availability}
          photo={photo}
          employeeNumber={employeeNumber}
          phoneNumber={phoneNumber}
          workingHours={workingHours}
          employeeTeamTags={employeeTeamTags}
          printerNumber={printerNumber}
          printerCopies={printerCopies}
          onSubmit={updateEmployeeData}
          onClose={() => setEditEmployeeModalOpen(false)}
        />
      )}
      {deleteEmployeeModalOpen && (
        <Modal
          title="Wait. Are you sure?"
          text={`
                  Deleting ${label} means that all the accesses will be taken away.
                  Please note that this process cannot be undone.
                `}
          cancelButtonText="No"
          submitButtonText="Yes"
          submitButtonVariant="destructive"
          cancelButtonVariant="dark"
          size="small"
          onSubmit={deleteEmployee}
          onCancel={() => setDeleteEmployeeModalOpen(false)}
        />
      )}
      {vacationsModalOpen && vacationModal()}
    </div>
  );
};

EmployeeSettingsActionCell.propTypes = {
  type: oneOf([
    "advisor",
    "driver",
    "mobile",
  ]).isRequired,
  id: number.isRequired,
  isActive: bool.isRequired,
  name: string.isRequired,
  availability: string,
  photo: photoPropType.isRequired,
  phoneNumber: string,
  employeeNumber: oneOfType([string, number]),
  employmentId: oneOfType([string, number]),
  workingHours: workingHoursPropType.isRequired,
  employeeTeamTags: arrayOf(node),
  printerNumber: string,
  printerCopies: string,
  updateEmployee: func.isRequired,
  openPopup: func.isRequired,
  closePopup: func.isRequired,
  openedPopupId: number,
  removeEmployee: func,
};

EmployeeSettingsActionCell.defaultProps = {
  availability: "",
  employeeNumber: "",
  employmentId: "",
  phoneNumber: "",
  openedPopupId: null,
  employeeTeamTags: [],
  printerNumber: null,
  printerCopies: null,
  removeEmployee: () => {},
};

export default EmployeeSettingsActionCell;
