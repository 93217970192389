import * as moment from "moment-timezone";
import { isNil } from "ramda";

import {
  checksJobDistance,
  fetchAppointmentSlots,
  fetchAvailableLoaners,
  fetchAvailableTransport,
  fetchDealershipDrivers,
  fetchDealershipMenuItems,
  fetchDealershipMobileTechnicians,
  fetchDecisionTrees,
  fetchJobSlots,
  fetchTecnhiciansJobSlots,
  fetchVehicleRecalls,
  setAppointment,
  validateWaiterPossibility,
} from "shared/api";
import { authChannelKeySelector, authTokenSelector } from "store/selectors/auth-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import { settingsDataSelector, settingsResponsibleTeamTagSelector, settingsTimezoneSelector } from "store/selectors/settings-selectors";
import {
  schedulingChosenTimeSlotSelector,
  schedulingCurrentVehicleSelector,
  schedulingIsPickUpSelector,
  schedulingProcessSelector,
  schedulingProcessServiceLocationSelector,
  schedulingRemoteJobDataSelector,
  schedulingSelectedRecallsSelector,
  schedulingSelectedServicesSelector,
  schedulingisMobileTechnicianSelector,
} from "store/selectors/scheduling-process-selectors";
import { formatInput, totalCost, totalServiceTime } from "shared/utils/common";
import { SOURCE_CONCIERGE, SOURCE_USER } from "shared/constants/text-messages";
import { fetchTeamAdvisorsByServices } from "./settings-actions";

export const SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_VEHICLE = "SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_VEHICLE";
export const SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_CUSTOMER = "SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_CUSTOMER";
export const SCHEDULING_PROCESS_SET_INTERNAL_NOTE = "SCHEDULING_PROCESS_SET_INTERNAL_NOTE";
export const SCHEDULING_PROCESS_SET_SERVICE_SELECTION = "SCHEDULING_PROCESS_SET_SERVICE_SELECTION";
export const SCHEDULING_PROCESS_SET_RECALLS_SELECTION = "SCHEDULING_PROCESS_SET_RECALLS_SELECTION";
export const SCHEDULING_PROCESS_SET_CHOSEN_TIMESLOT = "SCHEDULING_PROCESS_SET_CHOSEN_TIMESLOT";
export const SCHEDULING_PROCESS_SET_CHOSEN_DROP_OFF_TIMESLOT = "SCHEDULING_PROCESS_SET_CHOSEN_DROP_OFF_TIMESLOT";
export const SCHEDULING_PROCESS_SET_CLIENT_WAITING = "SCHEDULING_PROCESS_SET_CLIENT_WAITING";
export const SCHEDULING_PROCESS_SET_CLIENT_TRANSPORT = "SCHEDULING_PROCESS_SET_CLIENT_TRANSPORT";
export const SCHEDULING_PROCESS_SET_SERVICE_ADVISOR = "SCHEDULING_PROCESS_SET_SERVICE_ADVISOR";
export const SCHEDULING_PROCESS_SET_SEND_SMS = "SCHEDULING_PROCESS_SET_SEND_SMS";
export const SCHEDULING_PROCESS_SET_COMMUNICATION = "SCHEDULING_PROCESS_SET_COMMUNICATION";
export const SCHEDULING_PROCESS_SET_CURRENT_STEP = "SCHEDULING_PROCESS_SET_CURRENT_STEP";
export const SCHEDULING_PROCESS_SET_IS_PICK_UP = "SCHEDULING_PROCESS_SET_IS_PICK_UP";
export const SCHEDULING_PROCESS_SET_IS_DROP_OFF = "SCHEDULING_PROCESS_SET_IS_DROP_OFF";
export const SCHEDULING_PROCESS_SET_REMOTE_JOB_DATA = "SCHEDULING_PROCESS_SET_REMOTE_JOB_DATA";
export const SCHEDULING_PROCESS_SET_IS_MOBILE_TECHNICIAN = "SCHEDULING_PROCESS_SET_IS_MOBILE_TECHNICIAN";
export const SCHEDULING_PROCESS_SET_IS_DISTANCE_LIMIT_IGNORED = "SCHEDULING_PROCESS_SET_IS_DISTANCE_LIMIT_IGNORED";

export const SCHEDULING_PROCESS_FETCH_DAYS = "SCHEDULING_PROCESS_FETCH_DAYS";
export const SCHEDULING_PROCESS_FETCH_DAYS_SUCCESS = "SCHEDULING_PROCESS_FETCH_DAYS_SUCCESS";
export const SCHEDULING_PROCESS_FETCH_DAYS_ERROR = "SCHEDULING_PROCESS_FETCH_DAYS_ERROR";
export const SCHEDULING_PROCESS_FETCH_JOB_SLOTS_SUCCESS = "SCHEDULING_PROCESS_FETCH_JOB_SLOTS_SUCCESS";
export const SCHEDULING_PROCESS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS = "SCHEDULING_PROCESS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS";

export const SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS = "SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS";
export const SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS = "SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS";
export const SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_ERROR = "SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_ERROR";
export const SCHEDULING_PROCESS_FETCH_DECISION_TREES_SUCCESS = "SCHEDULING_PROCESS_FETCH_DECISION_TREES_SUCCESS";

export const SCHEDULING_PROCESS_FETCH_RECALLS = "SCHEDULING_PROCESS_FETCH_RECALLS";
export const SCHEDULING_PROCESS_FETCH_RECALLS_SUCCESS = "SCHEDULING_PROCESS_FETCH_RECALLS_SUCCESS";
export const SCHEDULING_PROCESS_FETCH_RECALLS_ERROR = "SCHEDULING_PROCESS_FETCH_RECALLS_ERROR";

export const SCHEDULING_PROCESS_SAVE_APPOINTMENT = "SCHEDULING_PROCESS_SAVE_APPOINTMENT";
export const SCHEDULING_PROCESS_SAVE_APPOINTMENT_SUCCESS = "SCHEDULING_PROCESS_SAVE_APPOINTMENT_SUCCESS";
export const SCHEDULING_PROCESS_SAVE_APPOINTMENT_FAILURE = "SCHEDULING_PROCESS_SAVE_APPOINTMENT_FAILURE";

export const SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT = "SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT";
export const SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_SUCCESS = "SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_SUCCESS";
export const SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_FAILURE = "SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_FAILURE";

export const SCHEDULING_PROCESS_SET_INITIAL_STATE = "SCHEDULING_PROCESS_SET_INITIAL_STATE";

export const SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED = "SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED";
export const SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED_FAILURE = "SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED_FAILURE";

export const SCHEDULING_PROCESS_UPDATE_AVAILABLE_LOANERS = "SCHEDULING_PROCESS_UPDATE_AVAILABLE_LOANERS";

export const SCHEDULING_PROCESS_JOB_REACHABLE = "SCHEDULING_PROCESS_JOB_REACHABLE";
export const SCHEDULING_PROCESS_JOB_REACHABLE_LOADING = "SCHEDULING_PROCESS_JOB_REACHABLE_LOADING";

export const SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_SUCCESS = "SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_SUCCESS";
export const SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_FAILURE = "SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_FAILURE";

export const SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS = "SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS";
export const SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE = "SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE";

export const SCHEDULING_PROCESS_SET_REMOTE_SERVICE_LOCATION = "SCHEDULING_PROCESS_SET_REMOTE_SERVICE_LOCATION";

export const setCurrentVehicle = (vehicle) => {
  return {
    type: SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_VEHICLE,
    payload: vehicle,
  };
};

export const setNote = (note) => {
  return {
    type: SCHEDULING_PROCESS_SET_INTERNAL_NOTE,
    payload: note,
  };
};

export const setCurrentCustomer = (customer) => {
  return {
    type: SCHEDULING_PROCESS_SET_CURRENT_SCHEDULING_CUSTOMER,
    payload: customer,
  };
};

const setMenuItemsLoadingState = () => {
  return {
    type: SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS,
  };
};

export const fetchMenuItems = () => async (dispatch, getState) => {
  const vehicle = schedulingCurrentVehicleSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  const { vehicle_set, mileage } = vehicle;

  const params = {
    "vehicle_set[make]": vehicle_set.make,
    "vehicle_set[model]": vehicle_set.model,
    "vehicle_set[model_year]": vehicle_set.model_year,
    mileage,
  };
  const token = authTokenSelector(getState());

  dispatch(setMenuItemsLoadingState());
  try {
    const menuItems = await fetchDealershipMenuItems(dealershipId, params, token);
    dispatch({
      type: SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_SUCCESS,
      payload: menuItems,
    });

    const { data: decisionTrees } = await fetchDecisionTrees(menuItems.concern.map(
      (item) => item.id,
    ), token);

    dispatch({
      type: SCHEDULING_PROCESS_FETCH_DECISION_TREES_SUCCESS,
      payload: decisionTrees,
    });
  } catch (error) {
    dispatch({
      type: SCHEDULING_PROCESS_FETCH_DEALERSHIP_MENU_ITEMS_ERROR,
      payload: error,
    });
  }
};

export const setServiceSelection = (payload) => {
  const decisionTreeResults = payload
    .filter((service) => service.decisionTreeResult)
    .map(({ id, decisionTreeResult, decision_tree }) => {
      const details = [{
        source: SOURCE_CONCIERGE,
        text: decision_tree.intro.text,
      }];

      Object.entries(decisionTreeResult).forEach(([key, value]) => {
        details.push({
          source: SOURCE_CONCIERGE,
          text: key,
        });
        details.push({
          source: SOURCE_USER,
          text: value,
        });
      });

      return {
        menu_item_id: id,
        details,
      };
    });

  return {
    type: SCHEDULING_PROCESS_SET_SERVICE_SELECTION,
    payload: {
      data: payload,
      decisionTreeResults,
    },
  };
};

export const fetchRecalls = () => (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  const params = {
    vin: schedulingCurrentVehicleSelector(getState()).vin,
    channel_key: authChannelKeySelector(getState()),
  };

  dispatch({ type: SCHEDULING_PROCESS_FETCH_RECALLS });

  fetchVehicleRecalls(dealershipId, params, token);
};

export const setRecallsSelection = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_RECALLS_SELECTION,
    payload,
  };
};

export const fetchAdvisors = () => (dispatch, getState) => {
  const services = schedulingSelectedServicesSelector(getState());
  const recalls = schedulingSelectedRecallsSelector(getState());
  const vehicle = schedulingCurrentVehicleSelector(getState());
  const isMobileTechnician = schedulingisMobileTechnicianSelector(getState());

  dispatch(fetchTeamAdvisorsByServices(services, recalls, vehicle, isMobileTechnician));
};

const setDaysLoadingState = () => {
  return {
    type: SCHEDULING_PROCESS_FETCH_DAYS,
  };
};

export const waiterPossibility = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const timeSlot = schedulingChosenTimeSlotSelector(getState());
  const teamTag = settingsResponsibleTeamTagSelector(getState());

  if (timeSlot.day) {
    const {
      quarterSlot,
      day: {
        full_date,
      },
    } = timeSlot;
    const dealershipTimezone = settingsTimezoneSelector(getState());
    const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";

    const appointmentDatetime = moment.tz(
      `${full_date} ${quarterSlot}`,
      datetimeFormat,
      dealershipTimezone,
    ).format();

    validateWaiterPossibility(
      dealershipId,
      {
        date_time: appointmentDatetime,
        team_tag_id: teamTag.id,
      },
      token,
    ).then((response) => {
      dispatch({
        type: SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED,
        payload: response.waiter_allowed,
      });
    }).catch((error) => {
      dispatch({
        type: SCHEDULING_PROCESS_UPDATE_WAITER_ALLOWED_FAILURE,
        payload: error.message,
      });
    });
  }
};

export const fetchAvailableDays = (
  advisorId,
  month,
  year,
  // eslint-disable-next-line no-unused-vars
  firstInit = true,
  isDropOff = false,
) => async (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());
  const vehicle = schedulingCurrentVehicleSelector(getState());
  const selectedServices = schedulingSelectedServicesSelector(getState());
  const selectedRecalls = schedulingSelectedRecallsSelector(getState());
  const isPickUp = schedulingIsPickUpSelector(getState());
  const isMobileTechnicians = schedulingisMobileTechnicianSelector(getState());
  const serviceLocation = schedulingProcessServiceLocationSelector(getState());

  dispatch(setDaysLoadingState());

  try {
    if (isPickUp) {
      const jobSlots = await fetchJobSlots(dealershipId, {
        menu_items_ids: selectedServices.map(({ id }) => id),
        recalls_ids: selectedRecalls.map(({ id }) => id),
        vehicle_id: vehicle && vehicle.id,
        month,
        year,
        working_area: isDropOff ? serviceLocation.dropOff.zone : serviceLocation.pickUp.zone,
      }, token);

      dispatch({
        type: SCHEDULING_PROCESS_FETCH_JOB_SLOTS_SUCCESS,
        payload: jobSlots,
      });
    }

    if (isMobileTechnicians) {
      const tecnhiciansJobSlots = await fetchTecnhiciansJobSlots(dealershipId, {
        menu_items_ids: selectedServices.map(({ id }) => id),
        recalls_ids: selectedRecalls.map(({ id }) => id),
        vehicle_id: vehicle && vehicle.id,
        month,
        year,
        working_area: serviceLocation.pickUp.zone,
      }, token);

      dispatch({
        type: SCHEDULING_PROCESS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS,
        payload: tecnhiciansJobSlots,
      });
    }

    if (!isPickUp && !isMobileTechnicians && advisorId) {
      const response = await fetchAppointmentSlots(dealershipId, {
        service_advisors_ids: Array.isArray(advisorId) ? advisorId : [advisorId],
        menu_items_ids: selectedServices.map(({ id }) => id),
        recalls_ids: selectedRecalls.map(({ id }) => id),
        vehicle_id: vehicle && vehicle.id,
        month,
        year,
      }, token);

      dispatch({
        type: SCHEDULING_PROCESS_FETCH_DAYS_SUCCESS,
        payload: response,
      });

      dispatch(waiterPossibility());
    }
  } catch (error) {
    dispatch({
      type: SCHEDULING_PROCESS_FETCH_DAYS_ERROR,
      payload: error,
    });
  }
};

export const clearTimeSlots = () => (dispatch) => {
  dispatch({
    type: SCHEDULING_PROCESS_FETCH_DAYS_SUCCESS,
    payload: [],
  });
  dispatch({
    type: SCHEDULING_PROCESS_FETCH_JOB_SLOTS_SUCCESS,
    payload: [],
  });
  dispatch({
    type: SCHEDULING_PROCESS_FETCH_TECHICIANS_JOB_SLOTS_SUCCESS,
    payload: [],
  });
};

export const setServiceAdvisor = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_SERVICE_ADVISOR,
    payload,
  };
};

export const setChosenTimeSlot = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_CHOSEN_TIMESLOT,
    payload,
  };
};

export const setChosenDropOffTimeSlot = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_CHOSEN_DROP_OFF_TIMESLOT,
    payload,
  };
};

export const setClientWaiting = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_CLIENT_WAITING,
    payload,
  };
};

export const setSendSms = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_SEND_SMS,
    payload,
  };
};

export const setCommunication = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_COMMUNICATION,
    payload,
  };
};

export const setIsPickUp = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_IS_PICK_UP,
    payload,
  };
};

export const setIsDropOff = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_IS_DROP_OFF,
    payload,
  };
};

export const setIsDistanceLimitIgnored = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_IS_DISTANCE_LIMIT_IGNORED,
    payload,
  };
};

export const setIsMobileTechnician = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_IS_MOBILE_TECHNICIAN,
    payload,
  };
};

export const setRemoteJobData = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_REMOTE_JOB_DATA,
    payload,
  };
};

export const fetchTransport = () => (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const services = schedulingSelectedServicesSelector(getState());
  const timeSlot = schedulingChosenTimeSlotSelector(getState());
  const dealershipTimezone = settingsTimezoneSelector(getState());
  const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";

  if (timeSlot.day) {
    const {
      quarterSlot,
      day: {
        full_date,
      },
    } = timeSlot;
    const appointmentDatetime = moment.tz(
      `${full_date} ${quarterSlot}`,
      datetimeFormat,
      dealershipTimezone,
    ).format();
    const params = {
      money_spent: totalCost(services),
      appointment_duration: totalServiceTime(services),
      appointment_datetime: appointmentDatetime,
    };
    const token = authTokenSelector(getState());

    dispatch({ type: SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT });
    return fetchAvailableTransport(dealershipId, params, token)
      .then((response) => dispatch({
        type: SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_SUCCESS,
        payload: response,
      }))
      .catch((error) => dispatch({
        type: SCHEDULING_PROCESS_FETCH_AVAILABLE_TRANSPORT_FAILURE,
        payload: error,
      }));
  }
};

export const setClientTransport = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_CLIENT_TRANSPORT,
    payload,
  };
};

export const setCurrentStep = (payload) => {
  return {
    type: SCHEDULING_PROCESS_SET_CURRENT_STEP,
    payload,
  };
};

const setAppointmentLoadingState = () => {
  return {
    type: SCHEDULING_PROCESS_SAVE_APPOINTMENT,
  };
};

export const bookAppointment = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealership_id = Number(dealershipIdSelector(getState()));
  const channel_key = authChannelKeySelector(getState());
  const {
    internalNote,
    clientWaiting: client_waiting,
    currentCustomer: {
      phone_number: customerPhoneNumber,
      id: customer_id,
    },
    currentVehicle: {
      id: vehicle_id,
    },
    selectedServices,
    selectedRecalls,
    firstAvailable,
    selectedAdvisor,
    chosenTimeSlot: {
      quarterSlot,
      day: {
        full_date,
      },
    },
    sendSms: send_sms,
    contactNumber,
    overcapacity,
    walkin,
    selectedTransport: transport_kind,
  } = schedulingProcessSelector(getState());

  const dealershipTimezone = settingsTimezoneSelector(getState());
  const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";
  const isPickUp = schedulingIsPickUpSelector(getState());
  const isMobileTechnician = schedulingisMobileTechnicianSelector(getState());
  const jobsData = schedulingRemoteJobDataSelector(getState());

  const appointmentDatetime = moment.tz(
    `${full_date} ${quarterSlot}`,
    datetimeFormat,
    dealershipTimezone,
  ).format();

  const menu_item_ids = selectedServices.map((service) => service.id);
  const menu_item_comments = selectedServices
    .filter((service) => service.additionalInfo)
    .map(({ id, additionalInfo }) => {
      return {
        id,
        comment: formatInput(additionalInfo),
      };
    });
  const decision_tree_results = selectedServices
    .filter((service) => service.decisionTreeResult)
    .map(({ id, decisionTreeResult, decision_tree }) => {
      const details = [{
        source: SOURCE_CONCIERGE,
        text: decision_tree.intro.text,
      }];

      Object.entries(decisionTreeResult).forEach(([key, value]) => {
        details.push({
          source: SOURCE_CONCIERGE,
          text: key,
        });
        details.push({
          source: SOURCE_USER,
          text: value,
        });
      });

      return {
        menu_item_id: id,
        details,
      };
    });
  const recall_ids = (selectedRecalls || []).map((recall) => recall.id);
  const phone_number = [contactNumber, customerPhoneNumber].find((el) => !isNil(el));

  let serviceType = "inhouse";

  if (isPickUp) {
    serviceType = "pickup";
  } else if (isMobileTechnician) {
    serviceType = "mobile_technician";
  }

  const params = {
    internal_note: internalNote,
    client_waiting: !isPickUp && !isMobileTechnician && client_waiting,
    transport_kind,
    phone_number,
    vehicle_id,
    customer_id,
    appointment_datetime: appointmentDatetime,
    menu_item_ids,
    menu_item_comments,
    decision_tree_results,
    recall_ids,
    channel_key,
    dealership_id,
    first_available_advisor: firstAvailable,
    service_advisor_id: selectedAdvisor.service_advisor_id || selectedAdvisor.id,
    appraisal_requested: false,
    send_sms,
    overcapacity,
    walkin,
    service_type: serviceType,
    pick_up_address_data: jobsData?.pickUp?.addressData,
    drop_off_address_data: jobsData?.dropOff?.addressData,
    mobile_technician_address_data: jobsData?.mobileTechnician?.addressData,
  };

  dispatch(setAppointmentLoadingState());

  return setAppointment(dealership_id, params, token)
    .then((response) => response)
    .catch((error) => (
      dispatch({
        type: SCHEDULING_PROCESS_SAVE_APPOINTMENT_FAILURE,
        payload: error,
      })
    ));
};

export const resetSchedulingProcessState = () => (dispatch) => dispatch({
  type: SCHEDULING_PROCESS_SET_INITIAL_STATE,
});

export const getAvailableLoaners = () => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const timeSlot = schedulingChosenTimeSlotSelector(getState());
  const integrationId = settingsDataSelector(getState()).integrations.find((s) => s.kind === "loaner").id;
  if (timeSlot.day) {
    const {
      quarterSlot,
      day: {
        full_date,
      },
    } = timeSlot;
    const dealershipTimezone = settingsTimezoneSelector(getState());
    const datetimeFormat = "YYYY-MM-DD";
    const appointmentDatetime = moment.tz(
      `${full_date} ${quarterSlot}`,
      datetimeFormat,
      dealershipTimezone,
    ).format();
    fetchAvailableLoaners(
      dealershipId,
      integrationId,
      {
        date: appointmentDatetime,
      },
      token,
    ).then((result) => {
      dispatch({
        type: SCHEDULING_PROCESS_UPDATE_AVAILABLE_LOANERS,
        payload: result,
      });
    });
  }
};

export const initChecksJobDistance = (addressData, geolocation, jobType) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: SCHEDULING_PROCESS_JOB_REACHABLE_LOADING,
    payload: jobType,
  });

  const authToken = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const channelKey = authChannelKeySelector(getState());

  const params = {
    channel_key: channelKey,
    ...addressData,
    lat: geolocation.lat,
    lng: geolocation.lng,
    job_type: jobType,
  };

  return checksJobDistance(dealershipId, params, authToken);
};

export const resetJobReachable = (reachable, jobType) => (
  dispatch,
) => {
  dispatch({
    type: SCHEDULING_PROCESS_JOB_REACHABLE,
    payload: {
      reachable,
      jobType,
    },
  });
};

export const retrieveDealershipDrivers = () => async (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  try {
    const response = await fetchDealershipDrivers(
      dealershipId,
      {
        active: true,
      },
      token,
    );

    dispatch({
      type: SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_SUCCESS,
      payload: {
        drivers: response.data,
        driversTotal: Number(response.headers.total),
      },
    });
  } catch (error) {
    dispatch({
      type: SCHEDULING_PROCESS_FETCH_DRIVERS_INFO_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const setRemoteServiceLocation = (serviceLocation) => async (dispatch) => {
  dispatch({
    type: SCHEDULING_PROCESS_SET_REMOTE_SERVICE_LOCATION,
    payload: serviceLocation,
  });
};

export const retrieveDealershipMobileTechnicians = () => async (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  try {
    const response = await fetchDealershipMobileTechnicians(
      dealershipId,
      {
        active: true,
      },
      token,
    );

    dispatch({
      type: SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS,
      payload: {
        technicians: response.data,
        techniciansTotal: Number(response.headers.total),
      },
    });
  } catch (error) {
    dispatch({
      type: SCHEDULING_PROCESS_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};
