import React from "react";
import {
  arrayOf, bool, func, string,
} from "prop-types";
import { driverPropType } from "shared/prop-types";

import EmployeeSelectionCarousel from "components/common/EmployeeSelectionCarousel";
import Input from "components/common/Input";

import "./styles.scss";

const DriversSelection = ({
  availableDrivers,
  selectedDriver,
  setSelectedDriver,
  availableCoDrivers,
  selectedCoDriver,
  setSelectedCoDriver,
  notes,
  setNotes,
  showCoDriver,
  isFullForm,
}) => (
  <div className="driversSelectionContainer">
    {availableDrivers.length > 0 && (
      <>
        {isFullForm && <div className="driversSelectionLabel">Driver</div>}
        <EmployeeSelectionCarousel
          employees={availableDrivers}
          selectedEmployee={selectedDriver || {}}
          onClickHandler={(item) => {
            if (item.id === selectedDriver?.id) {
              setSelectedDriver({});
            } else {
              setSelectedDriver(item);
            }
          }}
        />
      </>
    )}
    {showCoDriver && availableCoDrivers.length > 0 && (
      <>
        <div className="driversSelectionLabel">Co-Driver</div>
        <EmployeeSelectionCarousel
          employees={availableCoDrivers}
          selectedEmployee={selectedCoDriver || {}}
          onClickHandler={(item) => {
            if (item.id === selectedCoDriver?.id) {
              setSelectedCoDriver({});
            } else {
              setSelectedCoDriver(item);
            }
          }}
        />
      </>
    )}
    {availableDrivers.length > 0 && isFullForm && (
      <>
        <div className="driversSelectionLabel">Notes</div>
        <Input
          placeholder="Additional information"
          type="text"
          inputClassName=""
          value={notes}
          onClick={(e) => e.stopPropagation(e)}
          onChange={setNotes}
        />
      </>
    )}
  </div>
);

DriversSelection.propTypes = {
  availableDrivers: arrayOf(driverPropType),
  selectedDriver: driverPropType,
  setSelectedDriver: func.isRequired,
  availableCoDrivers: arrayOf(driverPropType),
  selectedCoDriver: driverPropType,
  setSelectedCoDriver: func.isRequired,
  notes: string,
  setNotes: func.isRequired,
  showCoDriver: bool.isRequired,
  isFullForm: bool,
};

DriversSelection.defaultProps = {
  availableDrivers: [],
  selectedDriver: null,
  availableCoDrivers: [],
  selectedCoDriver: null,
  notes: "",
  isFullForm: true,
};

export default DriversSelection;
