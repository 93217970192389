import axios from "axios";
import CryptoJS from "crypto-js";

const uri = "https://arrive.getcarmen.com";

const GET = "GET";
const POST = "POST";
const PATCH = "PATCH";

const signatureKey = (pathURI, Method, xDate) => {
  const signData = `GZ4mkdzTwL\n${Method}\n${xDate}\n${pathURI}`;
  const hmac = CryptoJS.algo.HMAC
    .create(CryptoJS.algo.SHA256, process.env.REACT_APP_ARRIVE_GETCARMEN_PRIVATE_KEY);

  return hmac.update(signData)
    .finalize().toString(CryptoJS.enc.Base64);
};

const xDate = Math.floor(Date.now() / 1000);

const apiCarmenPath = "/api/carmen";
const getVideosPath = `${apiCarmenPath}/get`;

const axiosInstance = axios.create({
  baseURL: uri,
});

const apiCall = (
  path,
  params = {},
  method = GET,
  headers = {},
) => axiosInstance.request({
  method,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    authorization: headers.auth,
    "X-DATE": headers.xDate,
  },
  url: path,
  params: method === GET ? params : undefined,
  data: (method === POST || method === PATCH) ? params : undefined,
}).then((response) => response.data);

export const fetchVideos = ({ dealershipId, vin, dates }) => {
  const auth = signatureKey(getVideosPath, GET, xDate);

  return apiCall(getVideosPath, {
    dealershipId,
    vin,
    dates,
  }, GET, {
    xDate,
    auth,
  });
};
