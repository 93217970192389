import React, { useEffect, useRef, useState } from "react";
import {
  arrayOf, bool, func, node, number, objectOf, string,
} from "prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Switch from "react-switch";

import { authTokenSelector } from "store/selectors/auth-selectors";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import {
  currentPageSelector,
  perPageSelector,
  totalRecordsSelector,
} from "store/selectors/table-options-selectors";
import { settingsTeamTagsSelector } from "store/selectors/settings-selectors";
import {
  menuTemplatesErrorSelector,
  menuTemplatesLoadingStateSelector,
  menuTemplatesRecallsSelector,
  menuTemplatesSelector,
  menuTemplatesTotalRecallsSelector,
  menuTemplatesUpdateErrorSelector,
} from "store/selectors/menu-templates-selectors";
import { menuTemplatePropType, teamTagPropType } from "shared/prop-types";
import { datesSortingMethod, formatMenuTemplateDate } from "shared/utils/datetime";
import { getDealershipRecalls, getMenuTemplates, updateDealershipRecall } from "store/actions/menu-templates-actions";
import ReactTooltip from "react-tooltip";
import Panel from "components/common/Panel";
import PageHeader from "components/common/PageHeader";
import Pagination from "components/common/ReactTableElements/Pagination";
import { AttributeStatusIconCell, LinkCell, MenuTemplateStatusIconCell } from "components/common/ReactTableElements/cellRenderers";
import ActionsCell from "components/common/ReactTableElements/cellRenderers/ActionsCell";
import Button from "components/common/Button";
import Tabs from "components/common/Tabs";
import Modal from "components/common/Modal";
import Input from "components/common/Input";
import MultiTagSelect from "components/common/MultiTagSelect";
import searchIcon from "assets/images/search.svg";
import vinSpecificRecallIcon from "assets/images/bookings/recallVin.svg";
import arrivedIcon from "assets/images/bookings/status/arrived.svg";
import {
  ASC,
  DEFAULT_SORT_COLUMN_DEALERSHIP,
  DEFAULT_SORT_DIRECTION_MENU_TEMPLATES,
  DESC,
} from "shared/constants";
import OpCodesSelect from "components/common/OpCodesSelect";
import AddTemplateModal from "./AddTemplateModal";

import styles from "./styles.module.scss";

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_SORT = `${DEFAULT_SORT_COLUMN_DEALERSHIP} ${DEFAULT_SORT_DIRECTION_MENU_TEMPLATES}`;

const tabsConfig = [
  {
    label: "General",
    value: "general",
  },
  {
    label: "Extensions",
    value: "extensions",
  },
  {
    label: "Recalls",
    value: "recalls",
  },
];

const MenuTemplatesPage = ({
  currentPage,
  perPage,
  menuTemplates,
  isLoading,
  total,
  recallsTotal,
  team_tags,
  recalls,
  error,
  fetchMenuTemplates,
  fetchRecalls,
  updateRecall,
  updateTemplatesError,
}) => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openedPopupId, setOpenedPopupId] = useState(null);
  const [selectedItem, setSelectedItem] = useState("general");
  const [newPage, setNewPage] = useState(currentPage);
  const [pageSize, setPageSize] = useState(perPage);
  const [currentItem, setCurrentItem] = useState(null);
  const [newSort, setNewSort] = useState(DEFAULT_SORT);
  const [showTooltip, setShowTooltip] = useState(false);

  const modalWindowRef = useRef();

  useEffect(() => {
    setNewPage(1);
  }, [selectedItem]);

  useEffect(() => {
    fetchMenuTemplates(
      {
        page: newPage,
        per_page: pageSize,
        sort: newSort,
      },
    );
  }, []);

  useEffect(() => {
    if (selectedItem !== "recalls") {
      fetchMenuTemplates(
        {
          sort: newSort,
          page: newPage,
          per_page: pageSize,
          searchPhrase,
          extensions: selectedItem === "extensions",
        },
      );
    } else {
      fetchRecalls(
        {
          sort: newSort,
          page: newPage,
          per_page: pageSize,
        },
      );
    }
  }, [selectedItem, newPage, pageSize, searchPhrase, newSort, total]);

  useEffect(() => {
    if (updateTemplatesError) {
      Object.keys(updateTemplatesError.data).forEach((key) => {
        NotificationManager.error(
          `${key} ${updateTemplatesError.data[key]}`,
          "Error",
          3000,
        );
      });
    }
  }, [updateTemplatesError]);

  const onPageSizeChange = (value) => {
    setPageSize(value);
    setNewPage(1);
  };

  const onSortedChange = (newSorted) => {
    const column = newSorted[0].id;
    const direction = newSorted[0].desc ? DESC : ASC;
    setNewSort(`${column} ${direction}`);
  };

  const updatePopupId = (popupId) => {
    if (openedPopupId !== popupId) {
      setOpenedPopupId(popupId);
    } else {
      setOpenedPopupId(null);
    }
  };

  const searchTemplates = (event) => {
    event.preventDefault();
  };

  const changeRecall = (item) => {
    updateRecall(
      item.id,
      item.vin_specific,
      item.allocated_labor_time,
      item.actual_labor_time,
      item.op_code_id,
      item.availability,
      item.team_tag_ids,
      item.available_on_weekends,
    );
  };

  const setDropDownPosition = () => {
    const modalWindowRect = modalWindowRef.current.getBoundingClientRect();
    const modalBody = modalWindowRef.current.querySelector(".conciergeModalBody");
    const inputWrappers = modalWindowRef.current.querySelectorAll(".multiTagListInner");

    inputWrappers.forEach((inputWrapper) => {
      const inputWrapperRect = inputWrapper.getBoundingClientRect();
      const element = inputWrapper.querySelector(".multiTagSelectMenu");

      if (
        (inputWrapperRect.bottom + 180) < window.innerHeight
        && modalBody.scrollHeight === modalBody.clientHeight
      ) {
        element.style.position = "fixed";
        element.style.removeProperty("top");
        element.style.removeProperty("bottom");
      } else if ((inputWrapperRect.bottom + 180) < modalWindowRect.bottom) {
        element.style.position = "absolute";
        element.style.top = "35px";
        element.style.removeProperty("bottom");
      } else {
        element.style.position = "absolute";
        element.style.bottom = "35px";
        element.style.removeProperty("top");
      }
    });
  };

  const sizeChangeHandler = (value) => {
    setDropDownPosition();

    if (value) {
      modalWindowRef.current.querySelector(".conciergeModalBody")
        .addEventListener("scroll", setDropDownPosition);
      window.addEventListener("resize", setDropDownPosition);
    } else {
      modalWindowRef.current.querySelector(".conciergeModalBody")
        .removeEventListener("scroll", setDropDownPosition);
      window.removeEventListener("resize", setDropDownPosition);
    }
  };

  const actionsCell = (props) => {
    const updatedProps = {
      ...props,
      openPopup: updatePopupId,
      closePopup: setOpenedPopupId(null),
      openedPopupId,
    };

    return <ActionsCell {...updatedProps} />;
  };

  const actionsRecallCell = (items) => (
    <Button
      variant="brand"
      leftIcon="editWhite"
      padding="small"
      onClick={() => setCurrentItem(items.original)}
    />
  );

  const descriptionCell = ({ value }) => (
    <div
      className={styles.withDescription}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div
        className={styles.ellipsis}
        data-for="recall-description"
        data-tip={value}
      >
        {value || "-"}
      </div>
    </div>
  );

  const mobileTechCell = ({ value }) => {
    if (value) {
      return (
        <img
          src={arrivedIcon}
          alt="Mobile Technician icon"
        />
      );
    }

    return null;
  };

  const availabilityCell = ({ value }) => (
    <div>{value === "publicly_available" ? "Public" : "Internal"}</div>
  );

  const vinSpecificCell = ({ value }) => {
    if (value) {
      return (
        <img
          src={vinSpecificRecallIcon}
          alt="vinSpecificRecall"
        />
      );
    }
    return null;
  };

  const columns = [
    {
      Header: "Template Name",
      accessor: "link",
      minWidth: 80,
      maxWidth: 200,
      Cell: LinkCell,
      className: "alignment",
    },
    {
      Header: "Added On",
      accessor: "created_at",
      maxWidth: 160,
      sortMethod: datesSortingMethod,
    },
    {
      Header: "Last Edited On",
      maxWidth: 160,
      accessor: "updated_at",
      sortMethod: datesSortingMethod,
    },
    {
      Header: "Status",
      maxWidth: 160,
      accessor: "active",
      Cell: MenuTemplateStatusIconCell,
    },
    {
      Header: "Ignore mileage constraint",
      accessor: "ignore_vehicle_mileage",
      Cell: AttributeStatusIconCell,
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      maxWidth: 60,
      Cell: actionsCell,
    },
  ];

  const recallsColumns = [
    {
      Header: "MFR",
      accessor: "mfr_number",
      width: 75,
    },
    {
      Header: "NHTSA",
      accessor: "nhtsa_number",
      width: 100,
    },
    {
      Header: "Operation code",
      accessor: "operation_code",
      width: 120,
      className: "ellipsis",
    },
    {
      Header: "Name",
      accessor: "name",
      width: 160,
      Cell: descriptionCell,
    },
    {
      Header: "Description",
      accessor: "description",
      width: 160,
      Cell: descriptionCell,
    },
    {
      Header: "Vin Specific",
      accessor: "vin_specific",
      width: 110,
      Cell: vinSpecificCell,
    },
    {
      Header: "Mobile",
      accessor: "mobile_technician",
      width: 95,
      Cell: mobileTechCell,
    },
    {
      Header: "Availability",
      accessor: "availability",
      width: 130,
      Cell: availabilityCell,
    },
    {
      Header: "Allocated time",
      accessor: "allocated_labor_time",
      width: 120,
    },
    {
      Header: "Flag time",
      accessor: "actual_labor_time",
      width: 80,
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      width: 68,
      Cell: actionsRecallCell,
    },
  ];

  const [sortColumn, sortDirection] = newSort.split(" ");

  return (
    <section className={styles.menuTemplatesPage}>
      <PageHeader
        title={<h2>Menu Templates</h2>}
        rightSideContent={(
          <form
            className={styles.search}
            onSubmit={searchTemplates}
          >
            <input
              className={styles.searchInput}
              placeholder="Search for templates..."
              value={searchPhrase}
              onChange={(event) => setSearchPhrase(event.target.value)}
            />
            <img
              alt="search"
              className={styles.searchIcon}
              src={searchIcon}
            />
          </form>
        )}
      />
      <NotificationContainer />
      <section className={styles.main}>
        {error && (
          <Panel className={styles.loadingPanel}>{error?.data?.errors}</Panel>
        )}
        {!error && (
          <div>
            <section className={styles.navbar}>
              <Tabs
                config={tabsConfig}
                selectedItem={selectedItem}
                selectTabAction={(value) => setSelectedItem(value)}
                isGeneral
              />
              {selectedItem !== "recalls" && (
                <Button
                  variant="brand"
                  onClick={() => setIsModalOpen(true)}
                >
                  Add new template
                </Button>
              )}
            </section>
            <ReactTable
              data={selectedItem === "recalls" ? recalls : menuTemplates}
              total={selectedItem === "recalls" ? recallsTotal : total}
              resolveData={(data) => {
                if (selectedItem === "recalls") {
                  return data;
                }

                return data.map((template) => {
                  return {
                    ...template,
                    created_at: formatMenuTemplateDate(template.created_at),
                    updated_at: formatMenuTemplateDate(template.updated_at),
                    link: {
                      href: `/templates/${template.id}`,
                      text: template.name,
                    },
                    actions: {
                      id: template.id,
                      name: template.name,
                      isActive: template.active,
                      ignoreVehicleMileage: template.ignore_vehicle_mileage,
                    },
                  };
                });
              }}
              loading={isLoading}
              columns={selectedItem === "recalls" ? recallsColumns : columns}
              defaultPageSize={perPage}
              PaginationComponent={Pagination}
              onPageChange={setNewPage}
              onPageSizeChange={onPageSizeChange}
              onSortedChange={onSortedChange}
              page={newPage}
              manual
              minRows={1}
              defaultSorted={[
                {
                  id: sortColumn,
                  desc: sortDirection === DESC,
                },
              ]}
              resizable={false}
              className={styles.table}
              noDataText={
                `No ${selectedItem === "general" ? "menu templates" : selectedItem} found`
              }
            />
          </div>
        )}
      </section>
      {isModalOpen && (
        <AddTemplateModal
          isExtension={selectedItem === "extensions"}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {currentItem && (
        <Modal
          ref={modalWindowRef}
          className={styles.editRecallModal}
          title={`Edit recall id: ${currentItem.id}`}
          size="medium"
          cancelButtonText="Cancel"
          submitButtonText="Save"
          onCancel={() => setCurrentItem(null)}
          onSubmit={() => {
            changeRecall(currentItem);
            setCurrentItem(null);
          }}
        >
          <label
            htmlFor="name"
            id="name"
            key="name"
            className={styles.fieldBlock}
          >
            <span>Name</span>
            <p>{currentItem.name}</p>
          </label>
          <label
            htmlFor="description"
            id="description"
            key="description"
            className={styles.fieldBlock}
          >
            <span>Description</span>
            <p>{currentItem.description}</p>
          </label>
          <label
            htmlFor="fallbackOpCode"
            id="fallbackOpCode"
            key="fallbackOpCode"
            className={styles.fieldBlock}
          >
            <span>OP code</span>
            <OpCodesSelect
              opCode={currentItem.operation_code}
              onChange={(item) => {
                setCurrentItem({
                  ...currentItem,
                  op_code_id: item ? item.value : null,
                  operation_code: item ? item.code : null,
                });
              }}
              showAssigned
            />
          </label>
          <label
            htmlFor="mfrNumber"
            id="mfrNumber"
            key="mfrNumber"
            className={styles.fieldBlock}
          >
            <span>OEM Number</span>
            <p>{currentItem.mfr_number || "-"}</p>
          </label>
          <label
            htmlFor="nhtsaNumber"
            id="nhtsaNumber"
            key="nhtsaNumber"
            className={styles.fieldBlock}
          >
            <span>NHTSA Number</span>
            <p>{currentItem.nhtsa_number || "-"}</p>
          </label>
          <div className={styles.fieldBlock}>
            <Input
              type="checkbox"
              className={styles.conciergeModaInputCheckbox}
              label="Vin Specific"
              checked={currentItem.vin_specific}
              onChange={() => {
                setCurrentItem({
                  ...currentItem,
                  vin_specific: !currentItem.vin_specific,
                });
              }}
            />
          </div>
          <div className={styles.fieldBlock}>
            <Input
              type="number"
              step="0.1"
              min="0"
              label="Allocated time"
              value={currentItem.allocated_labor_time}
              onChange={(value) => {
                setCurrentItem({
                  ...currentItem,
                  allocated_labor_time: +value >= 0 ? value : "",
                });
              }}
            />
          </div>
          <div className={styles.availability}>
            <div className={styles.title}>Availability</div>
            <div className={styles.container}>
              <Button
                variant={currentItem.availability === "publicly_available" ? "aqua" : "neutral"}
                className={styles.left}
                onClick={() => {
                  setCurrentItem({
                    ...currentItem,
                    availability: "publicly_available",
                  });
                }}
              >
                Public
              </Button>
              <Button
                variant={currentItem.availability === "internally_available" ? "aqua" : "neutral"}
                className={styles.right}
                onClick={() => {
                  setCurrentItem({
                    ...currentItem,
                    availability: "internally_available",
                  });
                }}
              >
                Internal Only
              </Button>
              <div className={styles.switch}>
                <div className={styles.label}>
                  Available on weekends
                </div>
                <Switch
                  id="weekends-availability-switch"
                  className={styles.toggle}
                  onChange={() => {
                    setCurrentItem({
                      ...currentItem,
                      available_on_weekends: !currentItem.available_on_weekends,
                    });
                  }}
                  checked={currentItem.available_on_weekends}
                  onColor="#36af5e"
                  offColor="#dedee0"
                  activeBoxShadow="0 0 2px 3px #0bcaf9"
                  aria-labelledby="weekends-availability-label"
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldBlock}>
            <Input
              type="number"
              step="0.1"
              min="0"
              label="Flag time"
              value={currentItem.actual_labor_time}
              onChange={(value) => {
                setCurrentItem({
                  ...currentItem,
                  actual_labor_time: +value >= 0 ? value : "",
                });
              }}
            />
          </div>
          <div className={styles.fieldBlock}>
            <span>Teams</span>
            <MultiTagSelect
              options={team_tags}
              value={currentItem.team_tags}
              onChange={(selected) => {
                setCurrentItem({
                  ...currentItem,
                  team_tag_ids: selected.map(({ id }) => id),
                });
              }}
              changeVisibilityAction={sizeChangeHandler}
            />
          </div>
        </Modal>
      )}
      {showTooltip && (
        <ReactTooltip
          id="recall-description"
          place="bottom"
          className={styles.tooltip}
          effect="float"
          delayShow={100}
          type="light"
          border
          borderColor="#dedee0"
        />
      )}
    </section>
  );
};

MenuTemplatesPage.propTypes = {
  menuTemplates: arrayOf(menuTemplatePropType),
  isLoading: bool,
  fetchMenuTemplates: func,
  fetchRecalls: func,
  updateRecall: func,
  token: string.isRequired,
  dealershipId: string,
  currentPage: number,
  perPage: number,
  error: objectOf(node),
  updateTemplatesError: objectOf(node),
  total: number.isRequired,
  recallsTotal: number.isRequired,
  team_tags: arrayOf(teamTagPropType),
  recalls: arrayOf(node),
};

MenuTemplatesPage.defaultProps = {
  menuTemplates: [],
  team_tags: [],
  isLoading: false,
  fetchMenuTemplates: () => {},
  fetchRecalls: () => {},
  updateRecall: () => {},
  dealershipId: null,
  currentPage: 1,
  perPage: DEFAULT_PAGE_SIZE,
  error: null,
  updateTemplatesError: null,
  recalls: [],
};

const mapStateToProps = (state) => {
  return {
    menuTemplates: menuTemplatesSelector(state),
    isLoading: menuTemplatesLoadingStateSelector(state),
    token: authTokenSelector(state),
    dealershipId: dealershipIdSelector(state),
    currentPage: currentPageSelector(state),
    perPage: perPageSelector(state, DEFAULT_PAGE_SIZE),
    error: menuTemplatesErrorSelector(state),
    total: totalRecordsSelector(state),
    recalls: menuTemplatesRecallsSelector(state),
    recallsTotal: menuTemplatesTotalRecallsSelector(state),
    team_tags: settingsTeamTagsSelector(state),
    updateTemplatesError: menuTemplatesUpdateErrorSelector(state),
  };
};

const actions = {
  fetchMenuTemplates: getMenuTemplates,
  fetchRecalls: getDealershipRecalls,
  updateRecall: updateDealershipRecall,
};

export default connect(mapStateToProps, actions)(MenuTemplatesPage);
