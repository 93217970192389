import * as moment from "moment-timezone";
import {
  fetchDealershipDrivers,
  fetchDealershipMobileTechnicians,
  fetchDriverJobsForTimePeriod,
  updateDriverData,
  updateDriverJob,
  updateMobileTechnicianJob,
  updateMobileTechnicians,
} from "shared/api";
import {
  authChannelKeySelector,
  authTokenSelector,
} from "store/selectors/auth-selectors";
import { settingsTimezoneSelector } from "store/selectors/settings-selectors";

import { dealershipIdSelector } from "store/selectors/app-selectors";

export const REMOTE_SERVICE_LOADING_STATE = "REMOTE_SERVICE_LOADING_STATE";

export const REMOTE_SERVICE_UPDATE_JOB = "REMOTE_SERVICE_UPDATE_JOB";
export const REMOTE_SERVICE_UPDATE_JOB_SUCCESS = "REMOTE_SERVICE_UPDATE_JOB_SUCCESS";
export const REMOTE_SERVICE_UPDATE_JOB_FAILURE = "REMOTE_SERVICE_UPDATE_JOB_FAILURE";
export const REMOTE_SERVICE_FETCH_DRIVER_JOBS_SUCCESS = "REMOTE_SERVICE_FETCH_DRIVER_JOBS_SUCCESS";
export const REMOTE_SERVICE_FETCH_DRIVER_JOBS_FAILURE = "REMOTE_SERVICE_FETCH_DRIVER_JOBS_FAILURE";
export const REMOTE_SERVICE_FETCH_DRIVERS_INFO_SUCCESS = "REMOTE_SERVICE_FETCH_DRIVERS_INFO_SUCCESS";
export const REMOTE_SERVICE_FETCH_DRIVERS_INFO_FAILURE = "REMOTE_SERVICE_FETCH_DRIVERS_INFO_FAILURE";
export const REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS = "REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS";
export const REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE = "REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE";
export const REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_SUCCESS = "REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_SUCCESS";
export const REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_FAILURE = "REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_FAILURE";
export const REMOTE_SERVICE_UPDATE_DRIVER_SUCCESS = "REMOTE_SERVICE_UPDATE_DRIVER_SUCCESS";
export const REMOTE_SERVICE_UPDATE_DRIVER_FAILURE = "REMOTE_SERVICE_UPDATE_DRIVER_FAILURE";

const updateDriverJobState = () => {
  return {
    type: REMOTE_SERVICE_UPDATE_JOB,
    payload: {
      isLoading: true,
    },
  };
};

const setLoadingState = () => {
  return {
    type: REMOTE_SERVICE_LOADING_STATE,
    payload: {},
  };
};

export const fetchDriverJobs = (params) => (dispatch, getState) => {
  const dealershipId = dealershipIdSelector(getState());
  const authToken = authTokenSelector(getState());

  dispatch(setLoadingState());

  return fetchDriverJobsForTimePeriod(dealershipId, params, authToken)
    .then((response) => dispatch({
      type: REMOTE_SERVICE_FETCH_DRIVER_JOBS_SUCCESS,
      payload: response,
    }))
    .catch((error) => dispatch({
      type: REMOTE_SERVICE_FETCH_DRIVER_JOBS_FAILURE,
      payload: error,
    }));
};

export const retrieveDealershipDrivers = () => async (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  try {
    const response = await fetchDealershipDrivers(
      dealershipId,
      {
        active: true,
      },
      token,
    );

    dispatch({
      type: REMOTE_SERVICE_FETCH_DRIVERS_INFO_SUCCESS,
      payload: {
        drivers: response.data,
        driversTotal: Number(response.headers.total),
      },
    });
  } catch (error) {
    dispatch({
      type: REMOTE_SERVICE_FETCH_DRIVERS_INFO_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const retrieveDealershipMobileTechnicians = () => async (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  try {
    const response = await fetchDealershipMobileTechnicians(
      dealershipId,
      {
        active: true,
      },
      token,
    );

    dispatch({
      type: REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_SUCCESS,
      payload: {
        technicians: response.data,
        techniciansTotal: Number(response.headers.total),
      },
    });
  } catch (error) {
    dispatch({
      type: REMOTE_SERVICE_FETCH_MOBILE_TECHNICIANS_INFO_FAILURE,
      payload: { error: error.errors || error },
    });
  }
};

export const updateJob = (job, isPickUp = true) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());
  const channelKey = authChannelKeySelector(getState());

  const {
    id,
    appointment_id,
    collection_time,
    main_driver,
    co_driver,
    technician,
    notes,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zipcode,
    location_area,
    job_type,
    geolocation,
    distance,
  } = job;

  const dealershipTimezone = settingsTimezoneSelector(getState());
  const datetimeFormat = "YYYY-MM-DD[T]HH:mm:ssZ";
  const collectionTime = moment.tz(
    collection_time,
    datetimeFormat,
    dealershipTimezone,
  ).format();

  let params = {
    appointment_id,
    channel_key: channelKey,
    collection_time: collectionTime,
    notes,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zipcode,
    job_type,
    geolocation,
    location_area,
    distance_in_miles: distance,
  };

  if (isPickUp) {
    params = {
      ...params,
      main_driver_id: main_driver.id,
      co_driver_id: co_driver?.id || null,
    };
  } else {
    params = {
      ...params,
      technician_id: technician.id,
    };
  }

  dispatch(updateDriverJobState());

  if (isPickUp) {
    updateDriverJob(
      dealershipId,
      main_driver.id,
      id,
      params,
      token,
    );
  } else {
    updateMobileTechnicianJob(
      dealershipId,
      technician.id,
      id,
      params,
      token,
    );
  }
};

export const updateMobileTechnician = (
  mobileTechnicianId,
  mobileTechnicianData,
) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateMobileTechnicians(
    dealershipId,
    mobileTechnicianId,
    mobileTechnicianData,
    token,
  )
    .then((response) => (
      dispatch({
        type: REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_SUCCESS,
        payload: response,
      })
    )).catch((error) => (
      dispatch({
        type: REMOTE_SERVICE_UPDATE_MOBILE_TECHNICIANS_FAILURE,
        payload: { data: Object.values(error.response.data)[0] },
      })
    ));
};

export const updateDriverInfo = (driverId, driverData) => (dispatch, getState) => {
  const token = authTokenSelector(getState());
  const dealershipId = dealershipIdSelector(getState());

  return updateDriverData(dealershipId, driverId, driverData, token)
    .then((response) => (
      dispatch({
        type: REMOTE_SERVICE_UPDATE_DRIVER_SUCCESS,
        payload: response,
      })
    )).catch((error) => (
      dispatch({
        type: REMOTE_SERVICE_UPDATE_DRIVER_FAILURE,
        payload: { error: error.errors || error },
      })
    ));
};
