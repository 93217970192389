import React, { useEffect, useState } from "react";
import { bool, func, string } from "prop-types";
import { connect } from "react-redux";

import { describeDecisionTree } from "store/actions/checkin-decision-trees-actions";
import sendIcon from "assets/icons/send.svg";
import { DELAY_1000 } from "shared/constants/delays";
import animationWrapper from "components/common/checkin/animationWrapper";

import styles from "./styles.module.scss";

const DecisionTreeDescriptionStepInput = ({ description, isComplete, onSubmit }) => {
  const [descriptionMessage, setDescriptionMessage] = useState("");

  useEffect(() => {
    if (description) {
      setDescriptionMessage(description);
    }
  }, [description]);

  const handleChange = (event) => {
    setDescriptionMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(descriptionMessage || "No");
    setDescriptionMessage("");
  };

  if (isComplete) {
    return null;
  }

  return (
    <div className={styles.container}>
      <form
        className={styles.form}
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          className={styles.input}
          value={descriptionMessage}
          onChange={handleChange}
          maxLength={50}
        />
        <button
          type="button"
          className={styles.submit}
          onClick={handleSubmit}
        >
          <img
            alt="send"
            className={styles.icon}
            src={sendIcon}
          />
        </button>
      </form>
    </div>
  );
};

DecisionTreeDescriptionStepInput.propTypes = {
  onSubmit: func.isRequired,
  description: string,
  isComplete: bool,
};

DecisionTreeDescriptionStepInput.defaultProps = {
  description: null,
  isComplete: false,
};

const actions = {
  onSubmit: describeDecisionTree,
};

const DecisionTreeDescriptionStepInputContainer = connect(
  null,
  actions,
)(DecisionTreeDescriptionStepInput);

export default animationWrapper(DecisionTreeDescriptionStepInputContainer, DELAY_1000);
