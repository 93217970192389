import React, { useEffect, useRef, useState } from "react";
import {
  arrayOf, bool, func, string,
} from "prop-types";
import { isEmpty } from "ramda";
import { connect } from "react-redux";

import {
  schedulingCustomerSavingErrorSelector,
  schedulingCustomerSavingSelector,
  schedulingCustomersSelector,
  schedulingErrorSelector,
  schedulingLoadingStateSelector,
  schedulingSavedWithoutDmsNotificationSelector,
  schedulingSearchStringSelector,
} from "store/selectors/scheduling-selectors";

import { dmsTypeSelector } from "store/selectors/settings-selectors";
import {
  resetDataEditionState,
  retrieveCustomers,
  sendCustomerData,
} from "store/actions/scheduling-actions";
import { resetSchedulingProcessState } from "store/actions/scheduling-process-actions";
import { customerPropType } from "shared/prop-types";
import { addCustomerFieldsCDK, customerFieldsDT } from "shared/fields";
import {
  determineSearchType,
  extractEmailFromString,
  extractFullNameFromString,
  extractPhoneNumberFromString,
  extractVinFromString,
} from "shared/utils/common";
import { phoneNumberLengthValidator } from "shared/validators";
import { NotificationContainer, NotificationManager } from "react-notifications";

import PageHeader from "components/common/PageHeader";
import Panel from "components/common/Panel";
import SearchField from "components/common/SearchField";
import DataEditionModal from "components/common/DataEditionModal";
import Button from "components/common/Button";
import CustomerDetails from "./CustomerDetails";

import styles from "./styles.module.scss";

const SchedulingPage = ({
  customers,
  error,
  fetchCustomers,
  isLoading,
  saveCustomerData,
  dataEditionModalSaving,
  dataEditionModalSavingError,
  resetDataEditionModalState,
  dmsType,
  savedWithoutDmsNotification,
  setSchedulingProcessInitialState,
  searchPhrase,
}) => {
  const [searchString, setSearchString] = useState(searchPhrase);
  const [errorMessage, setErrorMessage] = useState(error);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchType, setSearchType] = useState(null);
  const searchInput = useRef(null);

  useEffect(() => {
    setSchedulingProcessInitialState();
  }, [setSchedulingProcessInitialState]);

  useEffect(() => {
    if (searchString && searchType && !errorMessage) {
      fetchCustomers(searchString, searchType);
      setSearchType(null);
    }
  }, [searchType, searchString]);

  useEffect(() => {
    if (savedWithoutDmsNotification) {
      NotificationManager.warning(
        savedWithoutDmsNotification,
        "Warning",
      );
      if (searchString && searchType && !errorMessage) {
        fetchCustomers(searchString, searchType);
        setSearchType(null);
      }
    }
  }, [searchType, searchString, savedWithoutDmsNotification]);

  const validate = () => {
    setErrorMessage(null);

    if (isEmpty(searchString)) {
      setErrorMessage("Search phrase can't be blank.");
      return;
    }

    const definedSearchType = determineSearchType(searchString);
    setSearchType(definedSearchType);

    if (definedSearchType === "phone_number") {
      const phoneNumber = extractPhoneNumberFromString(searchString);
      const isValid = phoneNumberLengthValidator(phoneNumber, dmsType);

      if (isValid) {
        setSearchString(phoneNumber);
      } else {
        setErrorMessage("Invalid search phrase given.");
      }
    } else if (definedSearchType === "email") {
      const email = extractEmailFromString(searchString);
      setSearchString(email);
    } else if (definedSearchType === "full_name") {
      const fullName = extractFullNameFromString(searchString);
      setSearchString(fullName);
    } else if (definedSearchType === "vin") {
      const vin = extractVinFromString(searchString);
      setSearchString(vin);
    } else {
      setErrorMessage("Invalid search phrase given.");
    }
  };

  const handleCustomerWindow = (boolean) => {
    resetDataEditionModalState();
    setIsModalOpen(boolean);
  };

  const renderSearchResults = () => {
    const focusOnInputField = () => {
      searchInput.current.searchField.current.focus();
      window.scrollTo(0, searchInput.current.searchField.current.offsetTop);
    };
    const customersList = (customers || []).map(
      (c) => (
        <CustomerDetails
          customer={c}
          key={c.id}
          focusOnInputField={focusOnInputField}
        />
      ),
    );
    const resultString = customers.length === 1 ? "result" : "results";

    if (isLoading) {
      return (
        <Panel className={styles.loadingPanel}>
          Loading...
        </Panel>
      );
    }
    if (errorMessage || error) {
      return (
        <Panel className={styles.loadingPanel}>
          {errorMessage || error}
        </Panel>
      );
    }
    if (customersList.length) {
      return (
        <section>
          {searchPhrase && (
            <p className={styles.resultsMsg}>
              {`${customersList.length} ${resultString} found`}
            </p>
          )}
          {customersList}
        </section>
      );
    }
    return null;
  };

  return (
    <section className={styles.container}>
      <section className={styles.header}>
        <PageHeader title={<h2>Scheduling</h2>} />
      </section>
      <NotificationContainer />
      <section className={styles.containerMain}>
        <section className={styles.addButton}>
          <Button
            variant="brand"
            onClick={() => handleCustomerWindow(true)}
          >
            Add new customer
          </Button>
        </section>
        <Panel
          header="Search for customer"
          className={styles.panel}
        >
          <SearchField
            fullWidth
            ref={searchInput}
            placeholder="Phone number, full name, email or VIN"
            value={searchString}
            tooltipContent="Please press Enter to perform search"
            onEnter={validate}
            onChange={setSearchString}
          />
        </Panel>
        {renderSearchResults()}
        {
          !!isModalOpen && (
            <DataEditionModal
              onClose={() => handleCustomerWindow(false)}
              title="New customer"
              initialData={{ customer_type: "individual" }}
              fields={dmsType === "dealer_track" ? customerFieldsDT : addCustomerFieldsCDK}
              onSubmit={(data) => saveCustomerData(data)}
              loading={dataEditionModalSaving}
              error={dataEditionModalSavingError}
            />
          )
        }
      </section>
    </section>
  );
};

SchedulingPage.propTypes = {
  customers: arrayOf(customerPropType),
  searchPhrase: string.isRequired,
  error: string,
  fetchCustomers: func.isRequired,
  isLoading: bool,
  saveCustomerData: func.isRequired,
  dataEditionModalSaving: bool.isRequired,
  dataEditionModalSavingError: string.isRequired,
  resetDataEditionModalState: func.isRequired,
  dmsType: string,
  setSchedulingProcessInitialState: func.isRequired,
  savedWithoutDmsNotification: string,
};

SchedulingPage.defaultProps = {
  customers: [],
  error: null,
  isLoading: false,
  dmsType: "",
  savedWithoutDmsNotification: "",
};

const mapStateToProps = (state) => {
  return {
    customers: schedulingCustomersSelector(state),
    searchPhrase: schedulingSearchStringSelector(state),
    error: schedulingErrorSelector(state),
    isLoading: schedulingLoadingStateSelector(state),
    dataEditionModalSaving: schedulingCustomerSavingSelector(state),
    dataEditionModalSavingError: schedulingCustomerSavingErrorSelector(state),
    savedWithoutDmsNotification: schedulingSavedWithoutDmsNotificationSelector(state),
    dmsType: dmsTypeSelector(state),
  };
};

const actions = {
  fetchCustomers: retrieveCustomers,
  saveCustomerData: sendCustomerData,
  resetDataEditionModalState: resetDataEditionState,
  setSchedulingProcessInitialState: resetSchedulingProcessState,
};

export default connect(mapStateToProps, actions)(SchedulingPage);
