import {
  fetchEmployeeReports,
  fetchReportsCustomersActivity,
  fetchReportsEfficiency,
  fetchReportsGeneral,
} from "shared/api";
import { dealershipIdSelector } from "store/selectors/app-selectors";
import { authTokenSelector } from "store/selectors/auth-selectors";

export const REPORTING_FETCH_REPORTS_EFFICIENCY_SUCCESS = "REPORTING_FETCH_REPORTS_EFFICIENCY_SUCCESS";
export const REPORTING_FETCH_REPORTS_GENERAL_SUCCESS = "REPORTING_FETCH_REPORTS_GENERAL_SUCCESS";
export const REPORTING_FETCH_REPORTS_CUSTOMER_ACTIVITY_SUCCESS = "REPORTING_FETCH_REPORTS_CUSTOMER_ACTIVITY_SUCCESS";
export const REPORTING_FETCH_EMPLOYEE_REPORTING_SUCCESS = "REPORTING_FETCH_EMPLOYEE_REPORTING_SUCCESS";
export const REPORTING_FETCH_EMPLOYEE_REPORTING_FAILURE = "REPORTING_FETCH_EMPLOYEE_REPORTING_FAILURE";
export const REPORTING_SET_LOADING_STATE = "REPORTING_SET_LOADING_STATE";

const setLoadingState = () => {
  return {
    type: REPORTING_SET_LOADING_STATE,
  };
};

export const retrieveReportsEfficiency = (startDate, endDate) => (dispatch, getState) => {
  const dealership_id = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  fetchReportsEfficiency(
    dealership_id,
    {
      start_date: startDate,
      end_date: endDate,
    },
    token,
  ).then((result) => {
    dispatch({
      type: REPORTING_FETCH_REPORTS_EFFICIENCY_SUCCESS,
      payload: result,
    });
  });
};

export const retrieveReportsGeneral = (startDate, endDate) => (dispatch, getState) => {
  const dealership_id = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  fetchReportsGeneral(
    dealership_id,
    {
      start_date: startDate,
      end_date: endDate,
    },
    token,
  ).then((result) => {
    dispatch({
      type: REPORTING_FETCH_REPORTS_GENERAL_SUCCESS,
      payload: result,
    });
  });
};

export const retrieveReportsCustomersActivity = (startDate, endDate) => (dispatch, getState) => {
  const dealership_id = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  fetchReportsCustomersActivity(
    dealership_id,
    {
      start_date: startDate,
      end_date: endDate,
    },
    token,
  ).then((result) => {
    dispatch({
      type: REPORTING_FETCH_REPORTS_CUSTOMER_ACTIVITY_SUCCESS,
      payload: result,
    });
  });
};

export const retrieveEmployeeReports = (startDate, endDate) => (dispatch, getState) => {
  dispatch(setLoadingState());

  const dealership_id = dealershipIdSelector(getState());
  const token = authTokenSelector(getState());

  fetchEmployeeReports(
    dealership_id,
    {
      start_date: startDate,
      end_date: endDate,
    },
    token,
  ).then((result) => {
    dispatch({
      type: REPORTING_FETCH_EMPLOYEE_REPORTING_SUCCESS,
      payload: result,
    });
  }).catch((error) => {
    dispatch({
      type: REPORTING_FETCH_EMPLOYEE_REPORTING_FAILURE,
      payload: error,
    });
  });
};
