import React, { useState } from "react";
import { connect } from "react-redux";
import {
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types";
import { mobileTechnicianPropType } from "shared/prop-types";

import { updateJob } from "store/actions/remote-service-actions";
import { remoteServiceMobileTechniciansSelector } from "store/selectors/remote-service-selectors";

import Modal from "components/common/Modal";
import MobileTechnicianSelection from "components/common/Remote/ChooseMobileTechnician/MobileTechnicianSelection";

const EditMobileTechnicianJobModal = ({
  job,
  technicians,
  onClose,
  save,
}) => {
  const [selectedTechnician, setSelectedTechnician] = useState(job.technician);
  const [notes, setNotes] = useState(job.notes);

  const submit = () => {
    save(
      {
        ...job,
        technician: selectedTechnician,
        notes,
      },
      false,
    );

    onClose();
  };

  return (
    <Modal
      title="Edit Job"
      cancelButtonText="Cancel"
      submitButtonText="Save"
      size="medium"
      onCancel={onClose}
      onSubmit={submit}
      submitDisabled={
        !selectedTechnician
        || Object.keys(selectedTechnician).length === 0
      }
    >
      <MobileTechnicianSelection
        availableMobileTechnicians={technicians}
        selectedMobileTechnician={selectedTechnician}
        setSelectedMobileTechnician={setSelectedTechnician}
        notes={notes}
        setNotes={setNotes}
      />
    </Modal>
  );
};

EditMobileTechnicianJobModal.propTypes = {
  job: objectOf(oneOfType([number, bool, string])).isRequired,
  technicians: arrayOf(mobileTechnicianPropType),
  onClose: func.isRequired,
  save: func.isRequired,
};

EditMobileTechnicianJobModal.defaultProps = {
  technicians: null,
};

const mapStateToProps = (state) => {
  return {
    technicians: remoteServiceMobileTechniciansSelector(state),
  };
};

const actions = {
  save: updateJob,
};

export default connect(mapStateToProps, actions)(EditMobileTechnicianJobModal);
