import React, { useEffect, useState } from "react";
import {
  arrayOf, bool, func, node, objectOf, string,
} from "prop-types";
import { textMessagePropType } from "shared/prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import { NotificationContainer, NotificationManager } from "react-notifications";

import { updateDmsIntegrationOptions } from "store/actions/settings-actions";
import {
  settingsDataSelector,
  settingsEmployeeErrorSelector,
  settingsErrorSelector,
  settingsLoadingStateSelector,
  settingsTextMessagesSelector,
} from "store/selectors/settings-selectors";
import Panel from "components/common/Panel";
import PageHeader from "components/common/PageHeader";
import GeneralTab from "./GeneralTab";
import CapacityTab from "./CapacityTab";
import ColorSchemeTab from "./ColorSchemeTab";
import TeamTab from "./TeamTab";

import "./styles.scss";

const TAB_GENERAL = "TAB_GENERAL";
const TAB_CAPACITY = "TAB_CAPACITY";
const TAB_COLOR_SCHEME = "TAB_COLOR_SCHEME";
const TAB_TEAM = "TAB_TEAM";

const TABS = [
  {
    id: TAB_GENERAL,
    name: "General",
  },
  {
    id: TAB_CAPACITY,
    name: "Capacity",
  },
  {
    id: TAB_COLOR_SCHEME,
    name: "Color Scheme",
  },
  {
    id: TAB_TEAM,
    name: "Team",
  },
];

const SettingsPage = ({
  dealershipInfo,
  textMessages,
  isLoading,
  error,
  employeeError,
  updateDmsIntegration,
}) => {
  const [tab, setTab] = useState(TAB_GENERAL);

  useEffect(() => {
    if (employeeError) {
      NotificationManager.error(
        employeeError?.data?.errors || employeeError,
        "Error",
        5000,
      );
    }
  }, [employeeError]);

  return (
    <section className="conciergeSettingsPage">
      <PageHeader
        title={<h2>Settings</h2>}
      />
      <NotificationContainer />
      <section className="conciergeSettingsPageMain">
        {isLoading && (
          <Panel className="conciergeSettingsPageLoadingPanel">
            Loading...
          </Panel>
        )}
        {error && (
          <Panel className="conciergeSettingsPageLoadingPanel">
            {error?.data?.errors || error?.data?.error || error?.data || error}
          </Panel>
        )}
        {!isLoading && !error && (
          <>
            <section className="conciergeSettingsPageNavbar">
              <div>
                {TABS.map(({ id, name }) => (
                  <button
                    key={id}
                    type="button"
                    className={cx("conciergeSettingsPageNavbarTab", { active: tab === id })}
                    onClick={() => setTab(id)}
                  >
                    {name}
                  </button>
                ))}
              </div>
            </section>
            <section className="conciergeSettingsPageBody">
              {tab === TAB_GENERAL && (
                <GeneralTab
                  dealershipInfo={dealershipInfo}
                  textMessages={textMessages}
                  updateDmsIntegration={updateDmsIntegration}
                />
              )}
              {tab === TAB_CAPACITY && (
                <CapacityTab
                  capacitySettings={dealershipInfo.capacity_settings}
                />
              )}
              {tab === TAB_COLOR_SCHEME && (
                <ColorSchemeTab
                  dealershipColorScheme={dealershipInfo.stylesheet}
                />
              )}
              {tab === TAB_TEAM && (
                <TeamTab
                  dealershipInfo={dealershipInfo}
                />
              )}
            </section>
          </>
        )}
      </section>
    </section>
  );
};

SettingsPage.propTypes = {
  isLoading: bool,
  dealershipInfo: objectOf(string),
  textMessages: arrayOf(textMessagePropType).isRequired,
  error: objectOf(node),
  employeeError: objectOf(node),
  updateDmsIntegration: func.isRequired,
};

SettingsPage.defaultProps = {
  isLoading: false,
  dealershipInfo: null,
  error: null,
  employeeError: null,
};

const mapStateToProps = (state) => {
  return {
    dealershipInfo: settingsDataSelector(state),
    textMessages: settingsTextMessagesSelector(state),
    isLoading: settingsLoadingStateSelector(state),
    error: settingsErrorSelector(state),
    employeeError: settingsEmployeeErrorSelector(state),
  };
};

const actions = {
  updateDmsIntegration: updateDmsIntegrationOptions,
};

export default connect(mapStateToProps, actions)(SettingsPage);
