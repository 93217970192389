import scrollIntoView from "scroll-into-view-if-needed";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";

// Safari doesnt support smooth scrolling, so to make it work
// we use polyfill for that, however we want to use
// native smooth option where available
const scrollIntoViewSmoothly = "scrollBehavior" in document.documentElement.style
  ? scrollIntoView
  : smoothScrollIntoView;

export default scrollIntoViewSmoothly;
