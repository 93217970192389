import React, { useEffect } from "react";
import {
  arrayOf, bool, func, number, oneOfType, shape, string,
} from "prop-types";
import { connect } from "react-redux";
import {
  retrieveVideos,
} from "store/actions/booking-details-actions";
import Accordion from "components/common/Accordion";
import {
  bookingDetailsVideosDropOffSelector,
  bookingDetailsVideosLoadingStateSelector,
  bookingDetailsVideosMobileTechnicianSelector,
  bookingDetailsVideosPickUpSelector,
  bookingDetailsVideosWalkaroundSelector,
} from "store/selectors/booking-details-selectors";

import RemotePanelVideos from "./RemotePanelVideos";

import styles from "../styles.module.scss";

const VideosPanel = ({
  booking: {
    arrived_at,
    jobs,
    vehicle,
    id,
    kind,
  },
  fetchVideos,
  isLoadingVideos,
  availablePickUpVideos,
  availableDropOffVideos,
  availableWalkaroundVideos,
  availableMobileTechnicianVideos,
}) => {
  useEffect(() => {
    if (vehicle && vehicle.vin) {
      const dates = jobs && jobs.reduce((acc, job) => {
        if (job.activity && job.activity.arrived_to_customer_at) {
          acc.push(job.activity.arrived_to_customer_at);
        }
        return acc;
      }, []);

      if ((dates.length || arrived_at) && kind !== "RepairOrder") {
        fetchVideos({
          vin: vehicle.vin,
          dates: dates.length ? dates : [arrived_at],
          appointmentId: id,
        });
      }
    }
  }, [vehicle]);

  const videosCount = (value) => (
    <span className={styles.selectedOption}>
      {value}
    </span>
  );

  if (!availablePickUpVideos.length
      && !availableDropOffVideos.length
      && !availableWalkaroundVideos.length
      && !availableMobileTechnicianVideos.length
  ) {
    return null;
  }

  return (
    <>
      {availablePickUpVideos.length > 0 && (
        <Accordion
          title="Pick-up Videos"
          count={videosCount(availablePickUpVideos.length)}
          className={styles.videosPanel}
        >
          {isLoadingVideos && <div>Loading ...</div>}
          {availablePickUpVideos.length > 0 && (
            <RemotePanelVideos
              videos={availablePickUpVideos}
            />
          )}
        </Accordion>
      )}
      {availableDropOffVideos.length > 0 && (
        <Accordion
          title="Drop-off Videos"
          count={videosCount(availableDropOffVideos.length)}
          className={styles.videosPanel}
        >
          {isLoadingVideos && <div>Loading ...</div>}
          {availableDropOffVideos.length > 0 && (
            <RemotePanelVideos
              videos={availableDropOffVideos}
            />
          )}
        </Accordion>
      )}
      {availableMobileTechnicianVideos.length > 0 && (
        <Accordion
          title="Mobile technician Videos"
          count={videosCount(availableMobileTechnicianVideos.length)}
          className={styles.videosPanel}
        >
          {isLoadingVideos && <div>Loading ...</div>}
          {availableMobileTechnicianVideos.length > 0 && (
            <RemotePanelVideos
              videos={availableMobileTechnicianVideos}
            />
          )}

        </Accordion>
      )}
      {availableWalkaroundVideos.length > 0 && (
        <Accordion
          title="Drive walkaround Videos"
          count={videosCount(availableWalkaroundVideos.length)}
          className={styles.videosPanel}
        >
          {isLoadingVideos && <div>Loading ...</div>}
          {availableWalkaroundVideos.length > 0 && (
            <RemotePanelVideos
              videos={availableWalkaroundVideos}
            />
          )}
        </Accordion>
      )}
    </>
  );
};

const videoShape = shape({
  vin: string,
  videoURL: string,
  mileage: number,
  tagNumber: number,
  jobType: string,
  date: string,
});

VideosPanel.propTypes = {
  booking: shape({
    arrived_at: string,
    jobs: arrayOf(shape({
      aasm_state: string,
      activity: shape({
        driver_assigned_at: string,
        en_route_to_customer: string,
        arrived_to_customer: string,
        en_route_to_dealership: string,
        arrived_to_dealership: string,
      }),
      co_driver: shape({
        dealership_id: oneOfType([string, number]),
        id: oneOfType([string, number]),
        name: string,
      }),
      collection_time: string,
      id: oneOfType([string, number]),
      job_type: string,
      location: string,
      main_driver: shape({
        dealership_id: oneOfType([string, number]),
        id: oneOfType([string, number]),
        name: string,
      }),
      notes: "",
    })),
    id: oneOfType([string, number]),
    vehicle: shape({
      vin: string,
    }),
  }),
  fetchVideos: func.isRequired,
  isLoadingVideos: bool,
  availablePickUpVideos: arrayOf(videoShape),
  availableDropOffVideos: arrayOf(videoShape),
  availableWalkaroundVideos: arrayOf(videoShape),
  availableMobileTechnicianVideos: arrayOf(videoShape),
};

VideosPanel.defaultProps = {
  booking: {
    jobs: [],
    id: null,
    vehicle: {},
    job_status: null,
  },
  isLoadingVideos: false,
  availablePickUpVideos: [],
  availableDropOffVideos: [],
  availableWalkaroundVideos: [],
  availableMobileTechnicianVideos: [],
};

const mapStateToProps = (state) => {
  return {
    isLoadingVideos: bookingDetailsVideosLoadingStateSelector(state),
    availablePickUpVideos: bookingDetailsVideosPickUpSelector(state),
    availableDropOffVideos: bookingDetailsVideosDropOffSelector(state),
    availableWalkaroundVideos: bookingDetailsVideosWalkaroundSelector(state),
    availableMobileTechnicianVideos: bookingDetailsVideosMobileTechnicianSelector(state),
  };
};

const actions = {
  fetchVideos: retrieveVideos,
};

export default connect(mapStateToProps, actions)(VideosPanel);
