import React, { useState } from "react";
import { connect } from "react-redux";
import {
  arrayOf, bool, func, number, string,
} from "prop-types";
import { equals } from "ramda";
import uniqid from "uniqid";

import {
  setExtrasClipboard, setPremiumExtrasClipboard, updateExtras, updatePremiumExtras,
} from "store/actions/menu-template-actions";
import { servicesExtrasClipboardSelector, servicesPremiumExtrasClipboardSelector } from "store/selectors/menu-template-selectors";
import Input from "components/common/Input";
import Button from "components/common/Button";

import styles from "./styles.module.scss";

const Extras = ({
  menuItemId,
  enabled,
  currentExtras,
  saveExtras,
  savePremiumExtras,
  extrasClipboardContent,
  premiumExtrasClipboardContent,
  copyExtras,
  copyPremiumExtras,
  isPremium,
}) => {
  const [extras, setExtras] = useState(currentExtras);
  const [newExtra, setNewExtra] = useState("");

  const isCopied = () => {
    if (isPremium) {
      return !!premiumExtrasClipboardContent.length;
    }
    return !!extrasClipboardContent.length;
  };

  const onCopy = () => {
    if (isPremium) {
      copyPremiumExtras(extras);
    } else {
      copyExtras(extras);
    }
  };

  const addNewExtra = (event) => {
    event.preventDefault();
    setExtras([...extras, newExtra]);
    setNewExtra("");
  };

  const removeExtra = (extra) => () => setExtras(extras.filter((e) => e !== extra));

  const validateNewExtra = /[a-zA-Z]/.test(newExtra) || /\d/.test(newExtra);

  const onSave = () => {
    if (isPremium) {
      savePremiumExtras(menuItemId, extras);
    } else {
      saveExtras(menuItemId, extras);
    }
  };

  return enabled && (
    <div>
      <span className={styles.label}>
        {isPremium ? "Premium extras" : "Extras"}
      </span>
      <form className={styles.form} onSubmit={addNewExtra}>
        <Input
          value={newExtra}
          onChange={setNewExtra}
        />
        <Button
          className={styles.addButton}
          onClick={addNewExtra}
          disabled={!validateNewExtra}
        >
          Add
        </Button>
      </form>
      <div className={styles.item}>
        {extras.map((extra) => (
          <div
            key={uniqid()}
            className={styles.container}
          >
            <Button
              className={styles.remove}
              onClick={removeExtra(extra)}
            >
              x
            </Button>
            <span className={styles.extra}>{extra}</span>
          </div>
        ))}
      </div>
      <div className={styles.buttonsContainer}>
        {!!extras.length && (
          <Button
            className={styles.button}
            onClick={() => onCopy()}
          >
            Copy
          </Button>
        )}
        {isCopied() && (
          <Button
            className={styles.button}
            onClick={() => setExtras(
              isPremium ? premiumExtrasClipboardContent : extrasClipboardContent,
            )}
          >
            Paste
          </Button>
        )}
        {!equals(extras, currentExtras) && (
          <Button
            className={styles.button}
            onClick={() => onSave()}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

Extras.propTypes = {
  menuItemId: number.isRequired,
  enabled: bool,
  isPremium: bool,
  currentExtras: arrayOf(string),
  saveExtras: func.isRequired,
  savePremiumExtras: func.isRequired,
  extrasClipboardContent: arrayOf(string),
  premiumExtrasClipboardContent: arrayOf(string),
  copyExtras: func.isRequired,
  copyPremiumExtras: func.isRequired,
};

Extras.defaultProps = {
  enabled: false,
  isPremium: false,
  currentExtras: [],
  extrasClipboardContent: [],
  premiumExtrasClipboardContent: [],
};

const mapStateToProps = (state) => {
  return {
    extrasClipboardContent: servicesExtrasClipboardSelector(state),
    premiumExtrasClipboardContent: servicesPremiumExtrasClipboardSelector(state),
  };
};

const actions = {
  saveExtras: updateExtras,
  savePremiumExtras: updatePremiumExtras,
  copyExtras: setExtrasClipboard,
  copyPremiumExtras: setPremiumExtrasClipboard,
};

export default connect(mapStateToProps, actions)(Extras);
