import { connect } from "react-redux";
import {
  mobileTechniciansSelector,
  settingsMobileTechniciansLoadingSelector,
  totalMobileTechniciansSelector,
} from "store/selectors/settings-selectors";
import { retrieveMobileTechnicians } from "store/actions/settings-actions";
import EmployeesPanel from "./index";

const mapStateToProps = (state) => {
  return {
    employees: mobileTechniciansSelector(state),
    total: totalMobileTechniciansSelector(state),
    isLoading: settingsMobileTechniciansLoadingSelector(state),
  };
};

const actions = {
  fetchEmployees: retrieveMobileTechnicians,
};

export default connect(mapStateToProps, actions)(EmployeesPanel);
