import React from "react";

import { bool, number } from "prop-types";
import { customerPropType, vehiclePropType } from "shared/prop-types";

import styles from "../styles.module.scss";

const OwnerInformation = ({
  customer,
  vehicle: { vehicle_set, image, mileage },
  sendSms,
  contactNumber,
}) => (
  <div className={styles.owner}>
    {image?.url && (
      <img
        src={image.url}
        alt=""
        className={styles.carImg}
      />
    )}
    <div>
      <span className={styles.ownerInfo}>
        {
          customer?.first_name || customer?.last_name
            ? `${customer?.first_name || ""} ${customer?.last_name || ""}`
            : "—"
        }
      </span>
      <span className={styles.carInfo}>
        {`${vehicle_set?.model_year}, ${vehicle_set?.make} ${vehicle_set?.model}`}
      </span>
      <span className={styles.carInfo}>
        {`Mileage: ${mileage}`}
      </span>
      {contactNumber && (
        <span className={styles.carInfo}>
          {sendSms === true ? `Phone: ${contactNumber}` : "No SMS"}
        </span>
      )}

    </div>
  </div>
);

OwnerInformation.propTypes = {
  vehicle: vehiclePropType,
  customer: customerPropType,
  sendSms: bool.isRequired,
  contactNumber: number.isRequired,
};

OwnerInformation.defaultProps = {
  vehicle: {},
  customer: {},
};

export default OwnerInformation;
