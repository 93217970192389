import React from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
import {
  arrayOf, bool, func, instanceOf, number, oneOfType, shape, string,
} from "prop-types";
import { imageUrl } from "shared/utils/common";
import { updateVehicleFields } from "shared/fields";
import { APPOINTMENT_DATETIME_FORMAT } from "shared/utils/datetime";
import {
  bookingDetailsDataModalSavingErrorSelector,
  bookingDetailsDataModalSavingSelector,
} from "store/selectors/booking-details-selectors";
import { dealershipMakeModelYearMapSelector } from "store/selectors/app-selectors";
import { dmsTypeSelector, settingsDefaultMakeSelector } from "store/selectors/settings-selectors";
import {
  updateVehicleSetData,
} from "store/actions/booking-details-actions";
import EditVehicleModal from "components/common/DataEditionModal/EditVehicleModal";

import styles from "./styles.module.scss";

const VehicleDetailsBlock = ({
  booking,
  isEditing,
  handleFieldEdit,
  saveVehicleData,
  makeModelYearMap,
  dataEditionModalSaving,
  dataEditionModalSavingError,
  defaultMake,
  dmsType,
}) => (
  <section className={styles.block}>
    {booking?.vehicle?.image?.url ? (
      <img
        className={styles.image}
        src={imageUrl(booking.vehicle.image)}
        alt=""
      />
    ) : (
      <p className={styles.noImageText}>
        No vehicle image
      </p>
    )}
    <div className={styles.group}>
      <div>
        <div className={styles.modelName}>
          {`${booking?.vehicle_set?.model_year} ${booking?.vehicle_set?.make} ${booking?.vehicle_set?.model}`}
        </div>
        <div className={styles.bookingDetails}>
          {format(booking?.appointment_datetime, APPOINTMENT_DATETIME_FORMAT)}
        </div>

      </div>
      {dmsType === "cdk" && booking?.vehicle?.external_model_code && (
        <div>
          <div className={styles.label}>Model code:</div>
          <div className={styles.name}>
            {booking?.vehicle?.external_model_code}
          </div>
        </div>
      )}
    </div>
    <div className={styles.group}>
      <div>
        <div className={styles.label}>VIN</div>
        <div className={styles.value}>
          {booking?.vehicle?.vin || "-"}
        </div>
      </div>
      <div>
        <div className={styles.label}>Mileage</div>
        <div className={styles.value}>
          {booking?.vehicle?.mileage || "-"}
        </div>
      </div>
      <div>
        <div className={styles.label}>Plate</div>
        <div className={styles.value}>
          {booking?.vehicle?.plate_number || "-"}
        </div>
      </div>
    </div>
    {isEditing && (
      <EditVehicleModal
        onClose={() => handleFieldEdit(false)}
        title="Add vehicle data"
        initialData={{
          ...booking?.vehicle_set,
          ...booking?.vehicle,
          id: booking?.vehicle?.id,
        }}
        fields={updateVehicleFields}
        onSubmit={(data) => saveVehicleData(data)}
        submitButtonText="Update"
        loading={dataEditionModalSaving}
        error={dataEditionModalSavingError}
        makeModelYearMap={makeModelYearMap}
        defaultMake={defaultMake}
      />
    )}
  </section>
);

VehicleDetailsBlock.propTypes = {
  booking: shape({
    vehicle: shape({
      image: shape({
        url: string,
      }),
      vin: string,
      mileage: number,
      id: oneOfType(string, number),
      external_model_code: oneOfType(string, number),
    }),
    vehicle_set: shape({
      make: func,
      model: string,
      model_year: number,
    }),
    appointment_datetime: instanceOf(Date),
  }),
  isEditing: bool,
  defaultMake: string,
  handleFieldEdit: func,
  saveVehicleData: func,
  dmsType: string,
  makeModelYearMap: func.isRequired,
  dataEditionModalSaving: bool.isRequired,
  dataEditionModalSavingError: oneOfType([string, arrayOf(string)]).isRequired,
};

VehicleDetailsBlock.defaultProps = {
  defaultMake: "",
  booking: null,
  isEditing: false,
  handleFieldEdit: () => {},
  saveVehicleData: () => {},
  dmsType: "",
};

const mapStateToProps = (state) => {
  return {
    dataEditionModalSaving: bookingDetailsDataModalSavingSelector(state),
    dataEditionModalSavingError: bookingDetailsDataModalSavingErrorSelector(
      state,
    ),
    makeModelYearMap: dealershipMakeModelYearMapSelector(state),
    dmsType: dmsTypeSelector(state),
    defaultMake: settingsDefaultMakeSelector(state),
  };
};

export default connect(mapStateToProps, {
  saveVehicleData: updateVehicleSetData,
})(VehicleDetailsBlock);
